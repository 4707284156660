import React from "react";
import Modal from "react-bootstrap/Modal";

const NewProjectStep5 = ({
  iState,
  UpdateState,
  setShow,
  NewProjectStep5Modal,
  SetNewProjectStep3Modal,
  SetNewProjectStep1Modal,
  SetNewProjectStep2Modal,
  SetNewProjectStep4Modal,
  SetNewProjectStep5Modal,
  SetNewProjectStep6Modal,
}) => {
  const handleNewProjectStep5ModalClose = () => {SetNewProjectStep5Modal(false)
    setShow(true)
  }
  const handleNewProjectStep5ModalShow = () => {
    SetNewProjectStep5Modal(true);
  };
  const {profileCategories, errorMsg} =iState;


  const handleChange = (e) => {
    const { name,checked } = e.target;
  
    UpdateState({
      ...iState,
      profileCategories:{...profileCategories, [name]:checked}
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let formIsValid = true
  
    if (formIsValid) {
      SetNewProjectStep5Modal(false);
      SetNewProjectStep6Modal(true);
    }
  };
  

  return (
    <Modal show={NewProjectStep5Modal} className="ModalBox LargeModal">
      <div class="modal-content BgCreate">
        <a
          href="javascript:void(0);"
          class="CloseModal"
          onClick={handleNewProjectStep5ModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Project</h4>
          </div>
          <div class="StepBox">
            <ul>
                <li class="active">
                    <span class="Icon">
                        <img src={require("../../assets/images/step_1.png")} onClick={()=> {SetNewProjectStep1Modal(true) 
                      SetNewProjectStep5Modal(false)}} />
                    </span>
                    <span class="Text">Details</span>
                </li>
                <li class="active" onClick={()=> {SetNewProjectStep2Modal(true)
                   SetNewProjectStep5Modal(false)}}>
                    <span class="Icon FolderPermissionId " >
                        <img src={require("../../assets/images/step_2.png")}/>
                    </span>
                    <span class="Text">Address</span>
                </li>
                <li class="active">
                  <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep3Modal(true)
                    SetNewProjectStep5Modal(false)}} >
                      <img src={require("../../assets/images/step_8.png")}/>
                  </span>
                  <span class="Text">Type&amp; <br /> Accounting </span>
              </li>
                <li className="active">  
                    <span class="Icon FolderPermissionId " data-toggle="modal"  onClick={()=>{ SetNewProjectStep4Modal(true)
                    SetNewProjectStep5Modal(false)}}>
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job <br /> Numbering</span>
                </li>
                <li className="active">
                    <span class="Icon FolderPermissionId " data-toggle="modal" >
                        <img src={require("../../assets/images/step_6.png")} />
                    </span>
                    <span class="Text">Cost <br /> Profiles</span>
                </li>
                <li>
                    <span class="Icon FolderPermissionId " data-toggle="modal" onClick={()=> {SetNewProjectStep6Modal(true)
                    SetNewProjectStep5Modal(false)}}>
                        <img src={require("../../assets/images/step_7.png")} />
                    </span>
                    <span class="Text">Confirmation</span>
                </li> 
            </ul>
          </div>
          <div class="FolderForm">
          <form>
                    <div class="FolderFormCreateArea">
                      <div class="FieldsMandatory"><h6>Fields with <span class="required">*</span> are mandatory</h6></div>
                     <div class="row">
                         
                      
                        
                        <div class="col-md-6">
                          <h6>Job Pattern Definition</h6>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Land Costs
                              <input onChange={handleChange} name="landCosts" type="checkbox" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Architects &amp; Consultants Fees
                              <input type="checkbox" name="archConsultantsFees" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Engineers Fees
                              <input type="checkbox" name='engineersFees' />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Site Land Development Servicing Costs
                              <input type="checkbox" name="siteLandDevServCosts" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Site Preparation &amp; Tem Office Costs
                              <input type="checkbox" name='sitePrepTemOfficeCosts' />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Site Landscaping, Retaining Walls, RPR's
                              <input type="checkbox" name='siteLanRetaiWallsRPR' />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> New Home Warranty &amp; Insurance Fees
                              <input type="checkbox" name='newHomeWarInsuranceFees' />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Condominium Plan Corporation Costs
                              <input type="checkbox" name="condPlanCorpoCosts" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Marketing &amp; Sales Costs
                              <input type="checkbox" name="markSalesCosts" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Use Company's Pattern
                              <input type="checkbox"  />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group mb-2 ">
                            <label class="switch"> Management, Legal, Financing Fees
                              <input type="checkbox" name="managLegFinFees" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                        </div>
                        <div class="col-md-6">
                           <h6>Include Land Development Shared Costs</h6>
                           <div class="form-group my-4 text-center">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="slider"></span> 
                          </label>
                          </div>
                          <div class="form-group">
                            <div class="CommonSelectBox">
                              <select>
                                <option selected="">Select Land Development Job</option>
                                <option>Job 1</option>
                                <option>Job 2</option>alia
                              </select>
                              <span><img src="images/down.png" /></span>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>  
              
                  <div class="Button d-flex justify-content-between align-items-center px-5">
                    <button class="Outline" onClick={()=> {
                    SetNewProjectStep5Modal(false)
                    SetNewProjectStep4Modal(true)
                  }}>Back</button>
                    <button class="Create" data-toggle="modal" onClick={handleSubmit}>Next</button>
                  </div>  
                  </form>
                
              </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewProjectStep5;
