import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSelectedRequestItems,
  getSelectedWarrantyRequestItems,
  getWarrantySettingDiscussion,
  updateWarrantySettingDiscussion,
} from "../redux/action/warrantyAction";
import { AddDiscussionAction, AddReplyAction, discussionListAction } from "../redux/action/constructionsAction";

const initialState = {
  DiscussionSettingsModal: false,
  discussionData: [],
  visibleInClientView: false,
  visibleInTradeView: false,
  visibleInInvestorView: false,
  jobId:"",
};
const WarrantyRequestDetails = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    DiscussionSettingsModal,
    discussionData,
    visibleInClientView,
    visibleInTradeView,
    visibleInInvestorView,
    jobId,
  } = iState;
  const { selectedWarrantyRequestItems } = useSelector(
    (state) => state.warrantyReducer
  );

  //   console.log("selectedWarrantyRequestItems",selectedWarrantyRequestItems);
  const {discussionList } =
  useSelector((state) => state.constructionReducer);
  const { state } = useLocation();
    console.log("state ", state);
  useEffect(() => {
    if (state?.item?.id != null) {
      dispatch(getSelectedWarrantyRequestItems(state?.item?.id));
    }
  }, [dispatch, state?.item?.id]);

  useEffect(() => {
    if(DiscussionSettingsModal){
    dispatch(getWarrantySettingDiscussion("Warranty",state?.item?.id)).then((res) => {
      if (res.status === 200) {
        updateState({
          ...iState,
          discussionData: res.data,
          visibleInTradeView: res.data.settings?.visibleInTradeView,
          visibleInInvestorView: res.data.settings?.visibleInInvestorView,
          visibleInClientView: res.data.settings?.visibleInClientView,
        });
      }
    });}
  }, [DiscussionSettingsModal]);

  const handleDiscussionSettingsModalShow = () => {
    updateState({ ...iState, DiscussionSettingsModal: true });
  };
  const handleDiscussionSettingsModalClose = () => {
    updateState({ ...iState, DiscussionSettingsModal: false });
  };

  const handleDiscussionCheckBox = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        visibleInClientView: !visibleInClientView,
      });
    } else if (type === "trade") {
      updateState({
        ...iState,
        visibleInTradeView: !visibleInTradeView,
      });
    } else {
      updateState({
        ...iState,
        visibleInInvestorView: !visibleInInvestorView,
      });
    }
  };

  const handleDiscussionSettingUpdate = () => {
    const dataa = {
      visibleInClientView,
      visibleInTradeView,
      visibleInInvestorView,
      discussableId:discussionData?.settings?.id,
      discussableType: "Warranty",
      
    };
    dispatch(
      updateWarrantySettingDiscussion(dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getWarrantySettingDiscussion("Warranty",state?.item?.id))
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: true,
        });
      }
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });

  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction
        ({ message: discussionState.message, jobId: jobId })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


 useEffect(()=>{
  dispatch(discussionListAction({ jobId: state?.item?.jobId }));
},[])



  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Warranty Request</h2>
            </div>
            <div className="TodosButtons">
              <a href="javascript:void(0);">
                <h6 data-toggle="modal" data-target="#NoteAdd">
                  <img src="images/add-notes-1.png" />
                  Add Notes
                </h6>{" "}
                <span data-toggle="modal" data-target="#NotesList2">
                  3
                </span>
              </a>
              <a href="javascript:void(0);">
                <h6 data-toggle="modal" data-target="#AddNewSupportingDocs">
                  <img src="images/add-docuement-1.png" />
                  Add Supporting documents
                </h6>
                <span data-toggle="modal" data-target="#SupportingDocumentList">
                  3
                </span>
              </a>
            </div>
          </div>
          <div className="TitleBox">
            <h4 className="Title">Warranty Request #{state?.id}</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">{state?.jobNumber ?? "N/A"}</h6>
                <span>{state?.jobType ?? "N/A"}</span>
            
              </div>
              <a href="#">
                <img src="images/icon-61.png" />
              </a>
              <a href="#" className="mx-3">
                <img src="images/icon-62.png" />
              </a>
              <a href="#" onclick="openSettingsWarranty()">
                <img src="images/Icon-4.png" />
              </a>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Description <span className="Required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Write here ....."
                        value={state?.item?.description ?? "N/A"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
          <div className="StatusArea">
            <div className="TodosButtons">
              <a href="Warranty_Request.html">
                <img src="images/new-warranty-request.png" />
                New Warranty Request
              </a>
              <p>
                <span>Status</span>: Item(s) Still O/S
              </p>
            </div>
          </div>
        </div> */}
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <h4>Client Details</h4>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={moment(state?.item?.requestDate).format(
                          "YYYY-MM-DD"
                        )}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <div className="UrgencyArea">
            <div className="form-group">
              <label>
                Urgency<span className="required">*</span>
              </label>
              <div className="Categories Categories2">
                <div className="Categories_all Categories_all2">
                  <span>Select an Option</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>
                    <span className="AddNew">+</span>Add New
                  </li>
                  <li>Standard - Earliest Convenience</li>
                  <li>Not Urgent</li>
                  <li>As Soon as Possible (ASAP)</li>
                  <li>Urgent - Immediate Attention</li>
                </ul>
              </div>
            </div>
          </div> */}
                {/* <div className="StatusArea">
            <div className="TodosButtons">
              <a href="javascript:void(0);">
                <h6
                  data-toggle="modal"
                  data-target="#InspectionDateConfirmationRequest"
                >
                  <img src="images/new-warranty-request.png" /> Schedule
                  Inspection
                </h6>
                <span
                  data-toggle="modal"
                  data-target="#InspectionSelectionTable"
                >
                  3
                </span>
              </a>
            </div>
          </div> */}
              </div>
            </div>
          </div>
          <div className="EditableByClient mt-5">
            <div className="form-group">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider" />
              </label>
              <h6>Editable by client</h6>
            </div>
          </div>
          <div className="TableList NewTableList TableListHeader TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Inspector</th>
                  <th>Date Created</th>
                  <th>Date Inspected</th>
                  <th>Service Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedWarrantyRequestItems?.data?.length > 0 ? (
                  selectedWarrantyRequestItems?.data?.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <td>
                        <label className="CheckBox">
                          <input type="checkbox"
                           checked={item.workOrderStatus===true?true:false}
                           disabled
                //   onChange={() => handleItemCheckboxChange(item.id)} 
                  />
                          <span className="checkmark" />
                        </label>
                      </td> */}
                        <td>{item.id}</td>
                        <td>{item.description}</td>
                        <td>{item.inspectorUser?.name ?? "N/A"}</td>
                        <td>{moment(item?.createdAt).format("YYYY-MM-DD")}</td>
                        <td>
                          {moment(item?.dateInspected).format("YYYY-MM-DD")}
                        </td>
                        <td>{item.serviceTypeOption?.name ?? "N/A"}</td>
                        <td>
                          <div className="Actions">
                            <a>
                              <img src="images/icon-63.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center">
                      No data found.
                    </td>
                  </tr>
                )}

                {/* <tr>
            <td colSpan={9}>
              <div className="TableButtons">
                <button className="BrandBtn">Add Item</button>
                <button className="BrandBtn">Clear Table</button>
              </div>
            </td>
          </tr> */}
              </tbody>
            </table>
          </div>
          {/* <div className="d-flex justify-content-between mt-3 pb-5">
      <button className="BrandBtn">Cencel</button>
      <div className="d-flex ">
        <button className="BrandBtnGreen">Save</button>
        <div className="dropdown ml-3">
          <button
            className="BrandBtnGreen"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Save and <img src="images/down-arrow-white.png" />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">
              Save and Print
            </a>
            <a className="dropdown-item" href="#">
              Save and E-mail
            </a>
          </div>
        </div>
      </div>
    </div> */}
            <div class="DiscussionArea">
            <div class="DiscussionTitle">
            <h4>Discussion {" "}
              <span className="borderOranges">{discussionList?.length??0}</span>
              </h4>
              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a>
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
                <a onClick={() => handleDiscussionSettingsModalShow()}>
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div class="DiscussionHideArea" style={{ display: "block" }}>
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                    <span>CO</span>
                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src={require("../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={DiscussionSettingsModal}
        onHide={handleDiscussionSettingsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDiscussionSettingsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Discussion Settings</h4>
            </div>
            <div className="FolderForm">
              <div
                className="FolderFormCreateArea p-0"
                style={{ overflow: "initial" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="ModalMediumheading">
                      <h3>
                        <span>Linked to: </span>Warranty Request #
                        {state?.item?.id}
                      </h3>
                    </div>
                    <h6>Visible in :</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-2 mb-4 ">
                      <label className="switch">
                        Client View
                        <input
                          type="checkbox"
                          checked={visibleInClientView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("client")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Trade View
                        <input
                          type="checkbox"
                          checked={visibleInTradeView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("trade")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Investor View
                        <input
                          type="checkbox"
                          checked={
                            visibleInInvestorView === true ? true : false
                          }
                          onChange={() => handleDiscussionCheckBox("invest")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center">
                <button
                  className="Create"
                  onClick={handleDiscussionSettingUpdate}
                >
                  Save Discussion Settings
                </button>
                <button
                  className="Outline"
                  onClick={handleDiscussionSettingsModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarrantyRequestDetails;
