import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { updateProjectss } from "../redux/action/companyAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getEstimationSettingCostCategory,
  getEstimationSettingCostCode,
  updateEstimationSettingCostCategory,
  updateEstimationSettingCostCode,
} from "../redux/action/estimationAction";

const initialState = {
  costCategoryData:{},
  costCodeData:{},
};
const GearSettingIconEstimation = ({
  GearIconSettingModalShow,
  SetGearIconSettingModalShow,
  state
}) => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { costCategoryData, costCodeData } = iState;
  const handleGearIconSettingModalClose = () => {
    SetGearIconSettingModalShow(false);
  };

  useEffect(() => {
    if (GearIconSettingModalShow)
      dispatch(getEstimationSettingCostCode()).then((res) => {
        if (res.status === 200) {
          updateState({ ...iState, costCodeData: res?.data });
        }
      });
    dispatch(getEstimationSettingCostCategory()).then((res) => {
      if (res.status === 200) {
        updateState({ ...iState, costCategoryData: res?.data });
      }
    });
  }, [GearIconSettingModalShow]);

  const handleCostCodeChange=(e)=>{
    const {name,checked}=e.target;
    updateState({...iState,costCodeData:{
        ...costCodeData,
        [name]:checked?true:false
    }})
  }

  const handleCostCodeChangeUpdate=()=>{
    const datta = {
        costCodeData
    };
    dispatch(updateEstimationSettingCostCode(datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getEstimationSettingCostCode()).then((res) => {
            if (res.status === 200) {
              updateState({ ...iState, costCodeData: res?.data });
            }
          });
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        ;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }

  const handleCostCodeCategory=(e)=>{
    const {name,checked}=e.target;
    updateState({...iState,costCategoryData:{
        ...costCategoryData,
        [name]:checked?true:false
    }})
  }

  const handleCostCategoryChangeUpdate=()=>{
    const datta = {
        costCategoryData
    };
    dispatch(updateEstimationSettingCostCategory(datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getEstimationSettingCostCategory()).then((res) => {
            if (res.status === 200) {
              updateState({ ...iState, costCategoryData: res?.data });
            }
          });
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        ;
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }


  return (
    <>
      <Modal
        show={GearIconSettingModalShow}
        onHide={handleGearIconSettingModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleGearIconSettingModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Budget Details Settings</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalMediumheading mb-1">
                <h3>
                  <span>Job:{state?.jobNumber??"N/A"} </span>
                </h3>
              </div>
              <div className="ModalMediumheading mb-1">
                <h3>
                  <span>Cost Category Table</span>
                </h3>
              </div>
              <div className="BudgetDetailsSettingsTable">
                <table>
                  <thead>
                    <tr>
                      <th>Cost Category Table</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(costCategoryData).
                    filter(([key, value]) => value===true||value===false)
                    .map(([key, value], i) => (
                      <tr key={i}>
                        <td>{key}</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox"
                            name={key}
                             checked={value} 
                             onChange={(e)=>handleCostCodeCategory(e)}
                            />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create m-1" onClick={handleCostCategoryChangeUpdate}>Update Cost Category Details</button>
                <button className="Outline" onClick={handleGearIconSettingModalClose}>Cancel</button>
              </div>
              <div className="ModalMediumheading mb-1">
                <h3>
                  <span>Cost Code Table</span>
                </h3>
              </div>
              <div className="BudgetDetailsSettingsTable">
                <table>
                  <tbody>
                    {Object.entries(costCodeData).
                    filter(([key, value]) => value===true||value===false)
                    .map(([key, value], i) => (
                      <tr key={i}>
                        <td>{key}</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox"   
                             name={key}
                             checked={value} 
                             onChange={(e)=>handleCostCodeChange(e)} />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="Button d-flex justify-content-between mt-4">
                <button className="Create" onClick={handleCostCodeChangeUpdate}>Update Cost Code Details</button>
                <button className="Outline" onClick={handleGearIconSettingModalClose}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GearSettingIconEstimation;
