import React from 'react'
import Header from './Header'
import SideNav from './SideNav'

const FileManagerWarranty = () => {
  return (
    <>
    <Header/>
    <SideNav/>
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="TitleBox">
          <h4 className="Title">Warranty File Manager</h4>
          <div className="TitleBox2">
            <div className="dropdown">
              <h6 data-toggle="dropdown">
                <span>Job: </span>U2-CHI-2B-307
                <img src="images/refresh.png" />
              </h6>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#">
                  Select a job
                </a>
                <a className="dropdown-item" href="#">
                  <input type="text" placeholder="-Search" />
                  <span>
                    <img src="images/search.png" />
                  </span>
                </a>
                <a className="dropdown-item" href="#">
                  List of jobs loaded from the system
                </a>
              </div>
            </div>
            <a href="#" data-toggle="modal" data-target="#folderPermissionsModal">
              <img src="images/Icon-4.png" />
            </a>
          </div>
        </div>
        <div className="">
          <div className="row">
            <div className="col-sm-4">
              <div className="DashboardLeft">
                <div className="ShowList">
                  <h4>Show :</h4>
                  <div className="show-carousel owl-carousel owl-theme">
                    <div className="item">
                      <h6 className="active">System Templates</h6>
                    </div>
                    <div className="item">
                      <h6>Documents</h6>
                    </div>
                    <div className="item">
                      <h6>Pictures</h6>
                    </div>
                    <div className="item">
                      <h6>letters</h6>
                    </div>
                  </div>
                  <article>
                    <button data-toggle="modal" data-target="#uploadFileModal">
                      Upload File
                    </button>
                  </article>
                  <aside>
                    <h5>Folder</h5>
                    <div>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#folderCreateModal2"
                      >
                        <img src="images/Plus.png" />
                      </a>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#permissionModal3"
                      >
                        <img src="images/Folder.png" />
                      </a>
                      <a data-toggle="modal" data-target="#folderActionModal3">
                        <img src="images/Icon-4.png" />
                      </a>
                    </div>
                  </aside>
                  <div className="Search">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <span>
                      <img src="images/search.png" />
                    </span>
                  </div>
                </div>
                <div className="FolderList">
                  <ul>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>Warranty Letters</label>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#permissionModal"
                      >
                        <img src="images/Lock.png" />
                      </a>
                    </li>
                    <li>
                      <span className="Icon">
                        <img src="images/Folder.png" />
                      </span>
                      <label>User created Folder</label>
                      <a href="javascript:void(0);">
                        <img src="images/Cross.png" className="cross" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="TagsList">
                  <h4>TAGS</h4>
                  <ul>
                    <li>
                      <span>Family</span>
                    </li>
                    <li>
                      <span>Children</span>
                    </li>
                    <li>
                      <span>Home</span>
                    </li>
                    <li>
                      <span>Holiday</span>
                    </li>
                    <li>
                      <span>Work</span>
                    </li>
                    <li>
                      <span>Home</span>
                    </li>
                    <li>
                      <span>Holiday</span>
                    </li>
                    <li>
                      <span>Work</span>
                    </li>
                    <li>
                      <span>Home</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="EventArea">
                <h4>Pending File Events</h4>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Modified by</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Change_order_summary.pdf</td>
                        <td>John Doe</td>
                        <td>Sent for client approval</td>
                        <td>02/05/2023</td>
                        <td>
                          <div className="Actions">
                            <a>
                              <img src="images/Action-1.png" />
                            </a>
                            <a>
                              <img src="images/Action-2.png" />
                            </a>
                            <a>
                              <img src="images/Action-3.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Change_order_summary.pdf</td>
                        <td>John Doe</td>
                        <td>Sent for client approval</td>
                        <td>02/05/2023</td>
                        <td>
                          <div className="Actions">
                            <a>
                              <img src="images/Action-1.png" />
                            </a>
                            <a>
                              <img src="images/Action-2.png" />
                            </a>
                            <a>
                              <img src="images/Action-3.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Change_order_summary.pdf</td>
                        <td>John Doe</td>
                        <td>Sent for client approval</td>
                        <td>02/05/2023</td>
                        <td>
                          <div className="Actions">
                            <a>
                              <img src="images/Action-1.png" />
                            </a>
                            <a>
                              <img src="images/Action-2.png" />
                            </a>
                            <a>
                              <img src="images/Action-3.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Change_order_summary.pdf</td>
                        <td>John Doe</td>
                        <td>Sent for client approval</td>
                        <td>02/05/2023</td>
                        <td>
                          <div className="Actions">
                            <a>
                              <img src="images/Action-1.png" />
                            </a>
                            <a>
                              <img src="images/Action-2.png" />
                            </a>
                            <a>
                              <img src="images/Action-3.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="FilesArea">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Files</h4>
                    <aside>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </aside>
                  </div>
                  <div className="FilesRight">
                    <a href="javascript:void(0);">
                      <img src="images/Icon-5.png" />
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#uploadFileModal"
                    >
                      <img src="images/Icon-6.png" />
                    </a>
                  </div>
                </div>
                <div className="FilesBody">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-1.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-2.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-3.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuUserflow">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-4.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-5.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-6.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuUserflow">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-7.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-8.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="FilesBox">
                        <figure>
                          <img src="images/Card-9.png" />
                        </figure>
                        <figcaption>
                          <div>
                            <h4>Documents.docx</h4>
                            <p>Added: 20/05/2023</p>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuUserflow">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-view.png" />
                                    View
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-edit.png" />
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/document-e-sign.png" />
                                    E-sign
                                  </a>
                                </li>
                              </ol>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                data-toggle="modal"
                                data-target="#fileHistory"
                              >
                                <img src="images/timer.png" />
                              </a>
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ModalBox">
      <div className="ModalBox">
        <div className="modal fade SmallModal" id="folderCreateModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-toggle="modal"
                data-target="#uploadFileModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create Folder</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name *</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new folder name"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Folder Permissions</h6>
                      <div className="PermissionsName">
                        <h5
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#permissionModal"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/Folder.png" />
                          </span>{" "}
                          Folder Permissions
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Folder Actions</h6>
                      <div className="PermissionsName">
                        <h5
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#folderActionModal"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/Icon-4.png" />
                          </span>{" "}
                          Folder Actions
                        </h5>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button
                        className="Create FolderPermissionId"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#uploadFileModal"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade LargeModal" id="folderActionModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-toggle="modal"
                data-target="#folderCreateModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Actions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <h6>Folder Name *</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loaded from the system"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="AssignTable">
                      <h4>Assign Document Types</h4>
                      <article>
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th colSpan={10}>Action</th>
                            </tr>
                            <tr>
                              <th>Group</th>
                              <th>View File</th>
                              <th>Edit File</th>
                              <th>Create File</th>
                              <th>Delete File</th>
                              <th>E-sign File</th>
                              <th>Collect E-sign</th>
                              <th>Send for Approval</th>
                              <th>Approve</th>
                              <th>Send to client</th>
                              <th>All</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Construction</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </article>
                    </div>
                    <div className="Button">
                      <button
                        className="Create"
                        data-toggle="modal"
                        data-target="#folderCreateModal"
                        data-dismiss="modal"
                      >
                        Set Documents Types
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade LargeModal" id="folderPermissionsModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>File Manager Permissions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="AssignTable">
                      <h4>Assign Document Types</h4>
                      <article>
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th colSpan={7}>Document Type</th>
                            </tr>
                            <tr>
                              <th>Group</th>
                              <th>System Template</th>
                              <th>Documents</th>
                              <th>Letters</th>
                              <th>Sale Contract</th>
                              <th>Pictures</th>
                              <th>Videos</th>
                              <th>All</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Construction</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </article>
                      <div className="Button">
                        <button className="Create">Set Documents Types</button>
                      </div>
                    </div>
                    <div className="AssignTable">
                      <h4>Assign Directory Types</h4>
                      <article style={{ width: "75%" }}>
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th colSpan={5}>Directory Actions</th>
                            </tr>
                            <tr>
                              <th>Group</th>
                              <th>View</th>
                              <th>Edit</th>
                              <th>Create</th>
                              <th>Delete</th>
                              <th>All</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Construction</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </article>
                      <div className="Button">
                        <button className="Create">Set Directory Actions</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="uploadFileModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Upload Files</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select a folder to insert the file</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input type="text" placeholder="-Search" />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          <li
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#folderCreateModal"
                            data-dismiss="modal"
                          >
                            - New Folder
                          </li>
                          <li>- Full list of folders loaded from the system</li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input type="file" />
                      </div>
                    </div>
                    <div className="Button mb-4">
                      <button className="Create">Add</button>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                    <div className="form-group">
                      <h4>Files</h4>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <button className="Outline">Save as Draft</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="permissionModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-toggle="modal"
                data-target="#folderCreateModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Permissions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Loaded from the system"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Access Group(s)</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select roles to acess folder's content</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            <label className="CheckBox">
                              Admin
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Owner Group(s)</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Select roles to be owners of the folder</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button
                        className="Create"
                        data-toggle="modal"
                        data-target="#folderCreateModal"
                        data-dismiss="modal"
                      >
                        Set Permissions
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="fileHistory">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Files History</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>
                    <span>Name: </span>Document_2024.doc
                  </h2>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>Modified by</th>
                        <th>Created by</th>
                        <th>Date</th>
                        <th>Activity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>02/05/2023</td>
                        <td>File Created</td>
                      </tr>
                      <tr>
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>02/05/2023</td>
                        <td>File Created</td>
                      </tr>
                      <tr>
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>02/05/2023</td>
                        <td>File Created</td>
                      </tr>
                      <tr>
                        <td>John Doe</td>
                        <td>John Doe</td>
                        <td>02/05/2023</td>
                        <td>File Created</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="folderCreateModal2">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create Folder</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name *</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert new folder name"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Folder Permissions</h6>
                      <div className="PermissionsName">
                        <h5
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#permissionModal2"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/Folder.png" />
                          </span>{" "}
                          Folder Permissions
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Folder Actions</h6>
                      <div className="PermissionsName">
                        <h5
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#folderActionModal2"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/Icon-4.png" />
                          </span>{" "}
                          Folder Actions
                        </h5>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button
                        className="Create FolderPermissionId"
                        data-dismiss="modal"
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="permissionModal2">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-toggle="modal"
                data-target="#folderCreateModal2"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Permissions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Loaded from the system"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Access Group(s)</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select roles to acess folder's content</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            <label className="CheckBox">
                              Admin
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Owner Group(s)</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Select roles to be owners of the folder</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button
                        className="Create"
                        data-toggle="modal"
                        data-target="#folderCreateModal2"
                        data-dismiss="modal"
                      >
                        Set Permissions
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade LargeModal" id="folderActionModal2">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-toggle="modal"
                data-target="#folderCreateModal2"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Actions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <h6>Folder Name *</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loaded from the system"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="AssignTable">
                      <h4>Assign Document Types</h4>
                      <article>
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th colSpan={10}>Action</th>
                            </tr>
                            <tr>
                              <th>Group</th>
                              <th>View File</th>
                              <th>Edit File</th>
                              <th>Create File</th>
                              <th>Delete File</th>
                              <th>E-sign File</th>
                              <th>Collect E-sign</th>
                              <th>Send for Approval</th>
                              <th>Approve</th>
                              <th>Send to client</th>
                              <th>All</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Construction</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </article>
                    </div>
                    <div className="Button">
                      <button
                        className="Create"
                        data-toggle="modal"
                        data-target="#folderCreateModal2"
                        data-dismiss="modal"
                      >
                        Set Documents Types
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="permissionModal3">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Permissions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="form-group">
                      <h6>Folder Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Loaded from the system"
                      />
                    </div>
                    <div className="form-group">
                      <h6>Access Group(s)</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1">
                          <span>Select roles to acess folder's content</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            <label className="CheckBox">
                              Admin
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Owner Group(s)</h6>
                      <div className="Categories Categories2">
                        <div className="Categories_all Categories_all2">
                          <span>Select roles to be owners of the folder</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <label className="CheckBox">
                              All
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </li>
                          <li>
                            List of user groups that have access type of file
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="Button text-center">
                      <button className="Create" data-dismiss="modal">
                        Set Permissions
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade LargeModal" id="folderActionModal3">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal ClosePermissionId"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Folder Actions</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <h6>Folder Name *</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Loaded from the system"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="AssignTable">
                      <h4>Assign Document Types</h4>
                      <article>
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th colSpan={10}>Action</th>
                            </tr>
                            <tr>
                              <th>Group</th>
                              <th>View File</th>
                              <th>Edit File</th>
                              <th>Create File</th>
                              <th>Delete File</th>
                              <th>E-sign File</th>
                              <th>Collect E-sign</th>
                              <th>Send for Approval</th>
                              <th>Approve</th>
                              <th>Send to client</th>
                              <th>All</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                            <tr>
                              <td>Construction</td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <label className="CheckBox">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </article>
                    </div>
                    <div className="Button">
                      <button className="Create" data-dismiss="modal">
                        Set Documents Types
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="initialModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Initial Lot inspection</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="QuestionsArea">
                      <div className="QuestionRow">
                        <p>Is the Water Service Valve (curb stop) visible?</p>
                        <p>
                          <button>Yes</button>
                          <button>No</button>
                        </p>
                      </div>
                      <div className="QuestionRow">
                        <p>How does the Water Service Valve (curb stop) look?</p>
                        <p>
                          <button>Too Low</button>
                          <button>Good</button>
                          <button>Too High</button>
                        </p>
                      </div>
                      <div className="QuestionRow">
                        <p>Distance From Back of Curb/Sidewalk</p>
                        <p>
                          <input type="text" />
                        </p>
                      </div>
                      <div className="QuestionRow">
                        <p>Rough Grade of Lot - By Developer</p>
                        <p>
                          <button>Too Low</button>
                          <button>Good</button>
                          <button>Too High</button>
                        </p>
                      </div>
                    </div>
                    <div className="ChecklistTable mt-4">
                      <table>
                        <thead>
                          <tr>
                            <th>Checklist Questions</th>
                            <th>Grade</th>
                            <th>Upload</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Sidewalk - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <div className="FilesAddedBoth">
                                <div className="FilesAdded">
                                  <label>File_name.docs</label>
                                  <span>
                                    <img src="images/fileview.png" />
                                  </span>
                                  <span>
                                    <img
                                      src="images/Cross.png"
                                      className="cross"
                                    />
                                  </span>
                                </div>
                                <div className="FilesAdded">
                                  <label>File_name.docs</label>
                                  <span>
                                    <img src="images/fileview.png" />
                                  </span>
                                  <span>
                                    <img
                                      src="images/Cross.png"
                                      className="cross"
                                    />
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Standard Curb &amp; Gutter - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Low Profile Curb &amp; Gutter - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Driveway Apron - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Concrete Drainage Swales - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group mt-4">
                      <h6>Additional Comments</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ...."
                      />
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <div className="dropdown">
                        <button
                          className="btn Outline"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Save and <img src="images/red-down.png" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href="#">
                            Save and Print
                          </a>
                          <a className="dropdown-item" href="#">
                            Save and E-mail
                          </a>
                          <a className="dropdown-item" href="#">
                            Save and Lock
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="TableList my-4 TableHeightScroll">
                      <table>
                        <thead>
                          <tr>
                            <th>Modified by</th>
                            <th>Activity</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved draft from</td>
                            <td>02/05/2023</td>
                            <td />
                          </tr>
                          <tr>
                            <td>John williams</td>
                            <td>Saved and print form</td>
                            <td>02/05/2023</td>
                            <td>
                              <img src="images/pdf.png" />
                            </td>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved and email form</td>
                            <td>02/05/2023</td>
                            <td>
                              <img src="images/Action-2.png" />
                            </td>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved and email form</td>
                            <td>02/05/2023</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div className="DiscussionArea">
                      <div className="DiscussionTitle">
                        <h4>Discussion</h4>
                        <div className="DiscussionChat">
                          <img src="images/down.png" className="DiscussionIcon" />
                          <a href="#">
                            <img src="images/pdf2.png" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="DiscussionHideArea"
                        style={{ display: "block" }}
                      >
                        <div className="DiscussionBox">
                          <span>FM</span>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add a Comment ....."
                            />
                            <div className="DiscussionOverlay">
                              <a href="" className="CancelChat">
                                <img src="images/plus-cancel1.png" />
                              </a>
                              <a href="" className="SendChat">
                                <img src="images/forwardbutton.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                        <div className="ReplyArea">
                          <a href="javascript:void(0);">Reply</a>
                          <div className="DiscussionBox">
                            <span>TH</span>
                            <div className="DiscussionRight">
                              <h6>Inspections are delayed</h6>
                              <small>04/19/2023, at 02:47 pm</small>
                            </div>
                          </div>
                          <div className="DiscussionBox">
                            <span>TH</span>
                            <div className="DiscussionRight">
                              <h6>Inspections are delayed</h6>
                              <small>04/19/2023, at 02:47 pm</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade SmallModal" id="finalinspectionModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <a
                href="javascript:void(0);"
                className="CloseModal"
                data-dismiss="modal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Final Lot inspection</h4>
                </div>
                <div className="FolderForm">
                  <form>
                    <div className="QuestionsArea">
                      <div className="QuestionRow">
                        <p>Is the Water Service Valve (curb stop) visible?</p>
                        <p>
                          <button>Yes</button>
                          <button>No</button>
                        </p>
                      </div>
                      <div className="QuestionRow">
                        <p>How does the Water Service Valve (curb stop) look?</p>
                        <p>
                          <button>Too Low</button>
                          <button>Good</button>
                          <button>Too High</button>
                        </p>
                      </div>
                    </div>
                    <div className="ChecklistTable mt-4">
                      <table>
                        <thead>
                          <tr>
                            <th>Checklist Questions</th>
                            <th>Grade</th>
                            <th>Upload</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Sidewalk - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Standard Curb &amp; Gutter - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Low Profile Curb &amp; Gutter - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Driveway Apron - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Concrete Drainage Swales - By Developer</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Front Elevation Complete as per Plan</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Final Lot Grading &amp; Top Soil Complete</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Soil Erosion</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Driveway &amp; Sidewalks Complete</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Exterior Painting Complete</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Parging Complete</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Window Wells Installed (If Applicable)</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>Gravel Parking Pad (If Applicable)</td>
                            <td>
                              <label className="CheckBox">
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Icon-6.png" />
                              </a>
                            </td>
                            <td>
                              <input type="text" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group mt-4">
                      <h6>Additional Comments</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ...."
                      />
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button className="Create">Save</button>
                      <div className="dropdown">
                        <button
                          className="btn Outline"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Save and <img src="images/red-down.png" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a className="dropdown-item" href="#">
                            Save and Print
                          </a>
                          <a className="dropdown-item" href="#">
                            Save and E-mail
                          </a>
                          <a className="dropdown-item" href="#">
                            Save and Lock
                          </a>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="TableList my-4 TableHeightScroll">
                      <table>
                        <thead>
                          <tr>
                            <th>Modified by</th>
                            <th>Activity</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved draft from</td>
                            <td>02/05/2023</td>
                            <td />
                          </tr>
                          <tr>
                            <td>John williams</td>
                            <td>Saved and print form</td>
                            <td>02/05/2023</td>
                            <td>
                              <img src="images/pdf.png" />
                            </td>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved and email form</td>
                            <td>02/05/2023</td>
                            <td>
                              <img src="images/Action-2.png" />
                            </td>
                          </tr>
                          <tr>
                            <td>John Doe</td>
                            <td>Saved and email form</td>
                            <td>02/05/2023</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    <div className="DiscussionArea">
                      <div className="DiscussionTitle">
                        <h4>Discussion</h4>
                        <div className="DiscussionChat">
                          <img src="images/down.png" className="DiscussionIcon" />
                          <a href="#">
                            <img src="images/pdf2.png" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="DiscussionHideArea"
                        style={{ display: "block" }}
                      >
                        <div className="DiscussionBox">
                          <span>FM</span>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add a Comment ....."
                            />
                            <div className="DiscussionOverlay">
                              <a href="" className="CancelChat">
                                <img src="images/plus-cancel1.png" />
                              </a>
                              <a href="" className="SendChat">
                                <img src="images/forwardbutton.png" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                        <div className="ReplyArea">
                          <a href="javascript:void(0);">Reply</a>
                          <div className="DiscussionBox">
                            <span>TH</span>
                            <div className="DiscussionRight">
                              <h6>Inspections are delayed</h6>
                              <small>04/19/2023, at 02:47 pm</small>
                            </div>
                          </div>
                          <div className="DiscussionBox">
                            <span>TH</span>
                            <div className="DiscussionRight">
                              <h6>Inspections are delayed</h6>
                              <small>04/19/2023, at 02:47 pm</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default FileManagerWarranty