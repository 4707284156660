import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonAreaStep1 } from "../../../redux/action/proformaAction";

const CommonAreaUnitcomp = ({
  handleMultiStorieschange,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonAreaUnit, setCommonAreaUnit] = useState([]);
  useEffect(() => {
    dispatch(getCommonAreaStep1()).then((res) => {
      setCommonAreaUnit(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div>
        <div className="ModalBigheading pl-4 mb-3">
          <h3 className="mb-0">
            <span>Common Areas Input</span>
          </h3>
        </div>
        <div className="ProformaAccordion">
          <div className="accordion" id="accordionExample">
            {commonAreaUnit?.length > 0 &&
              commonAreaUnit?.map((item, index) => {
                return (
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#BlockA"
                          aria-expanded="true"
                          aria-controls="BlockA"
                        >
                          {item.blockDescription}
                        </button>
                      </h2>
                      <a href="javascript:void(0);">
                        <img
                          src={require("../../../assets/images/book-search.png")}
                        />
                      </a>
                    </div>
                    <div
                      id="BlockA"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body py-0">
                        <div className="EnterProjectTable">
                          <table>
                            <thead>
                              <tr>
                                <th />
                                <th>Sq/Ft</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.MultiStories?.length > 0 &&
                                item?.MultiStories?.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>Story {i + 1}</td>
                                      <td>
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control width-250"
                                            placeholder="Insert Sq/Ft"
                                            name="value"
                                            value={data.value}
                                            onChange={(e) =>
                                              handleMultiStorieschange(
                                                e,
                                                i,
                                                index
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("units")}
        >
          Cancel
        </button>
        <button
          onClick={() => handleShowComponents("commonSummeryPreview")}
          className="Create bg-success border border-success"
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default CommonAreaUnitcomp;
