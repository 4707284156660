import React, { useState } from 'react'
import '../App.css';
import sidenav1 from "../assets/images/sidenav-1.png";
import sidenav2 from "../assets/images/sidenav-2.png";
import sidenav3 from "../assets/images/sidenav-3.png";
import sidenav4 from "../assets/images/sidenav-4.png";
import sidenav5 from "../assets/images/sidenav-5.png";
import sidenav6 from "../assets/images/sidenav-6.png";
import sidenav7 from "../assets/images/sidenav-7.png";
import sidenav8 from "../assets/images/sidenav-8.png";
import sidenav9 from "../assets/images/sidenav-9.png";
import sidenav10 from "../assets/images/sidenav-10.png";
import sidenav11 from "../assets/images/sidenav-11.png";
import sidenav12 from "../assets/images/sidenav-11.png";
import theme8 from "../assets/images/theme-8.png";
import profile from "../assets/images/profile.png";
import contacts from "../assets/images/contacts.png";
import my_task from "../assets/images/my_task.png";
import signout from "../assets/images/signout.png";
import sales from "../assets/images/sales_red.png";
import { Link, useLocation } from 'react-router-dom';



const InvestorViewSidenav = () => {

    const investorViewData=JSON.parse(window.localStorage.getItem("investorViewLogin"));
  
    const location = useLocation();
    const isActive = (path) => {
      return  location.pathname === path ||(location.pathname !== "/" && location.pathname.startsWith(path + "/"))
    };
  
  
  return (
    <>

{
investorViewData?.user?.role==="Investor View" &&
<div className="SidenavBarClient">
  <ul>
  <li>
    <a className="TextRed">
      Investor View
    </a>
  </li>
    {/* <li>
      <a href="javascript:void(0);" className="Black">
        Menu
      </a>
    </li> */}
    <li className={isActive("/investor-view-home")?"active" : ""}>
      <Link to="/investor-view-home" className="Home">
        Home
      </Link>
    </li>
    <li className={isActive("/fileManager-investor-view")?"active" : ""}>
      <Link to="/fileManager-investor-view">
      System File Manager <span className="Number">3</span>
      </Link>
    </li>
    <li>
      <a href="reports_investor_view.html">
        Reports <span className="Number">3</span>
      </a>
    </li>
  </ul>
</div>



}

</>
  )
}

export default InvestorViewSidenav