import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  deletePerforma,
  getActiveInactiveProforma,
  getAffordableRentalRevenue,
  getCreateProformaProjectAndJobs,
  getGrossMarginPerunit,
  getPerformaReport,
  getProformaFinancialReport,
  getProformaHistory,
  getProformaList,
  getProformaUnitPrice,
  getProjectAnnualAffordableRentalRevenue,
  getProjectAnnualRentalRevenue,
  getProjectSalesRevenue,
  getRegularRentalRevenue,
  getUnitAffordableRevenue,
  getUnitCostAnalysis,
  getUnitMonthlyRental,
  getUnitRentalTable,
  performaReportMarkUp,
  projectSalesRevenue,
  updateUnitAffordableRevenue,
  updateUnitMonthlyRental,
  updateUnitRental,
} from "../redux/action/projectProformaAction";
import Loader from "./Loader";
import moment from "moment";
import ProformaModal from "./ProformaModal";
import MultifamilyJobStep1 from "./MultifamilyJob/MultifamilyJobStep1";
import UpdatePerforma from "./updatePerforma/UpdatePerforma";

const initialState = {
  ProformaHistoryModal: false,
  ProformaReportModal: false,
  UnitPricingModal: false,
  proformaId: "",
  FinancialReportModal: false,
  perFormaStatus: "",
  softCostMarkupPercent: "",
  hardCostMarkupPercent: "",
  proformaName: "",
  proformaCreatedAt: "",
  proformaupdatedAt: "",

  commisionFeesPercent: "",
  legalFees: "",
  cashInvestments: "",
  interestRatePercent: "",
  unitData: [],
  unitRentalTableData: [],
  unitMonthlyRentalTableData: [],
  unitAffordableRevenueTableData: [],
  projectAnnualRentalRevenueData: [],
};

const ProjectProforma = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    ProformaHistoryModal,
    ProformaReportModal,
    UnitPricingModal,
    proformaId,
    FinancialReportModal,
    softCostMarkupPercent,
    hardCostMarkupPercent,
    perFormaStatus,
    proformaName,
    proformaCreatedAt,
    proformaupdatedAt,

    commisionFeesPercent,
    legalFees,
    cashInvestments,
    interestRatePercent,
    unitData,
    unitRentalTableData,
    unitMonthlyRentalTableData,
    unitAffordableRevenueTableData,
    projectAnnualRentalRevenueData,
  } = iState;

  const {
    projectProformaList,
    loader,
    unitPriceList,
    proformaHistoryList,
    performaFinancialReportData,
    affordabaleRentalRevenueData,
    projectAnnualAffordabaleRentalRevenueData,
    projectSaleRevenueData,
    unitCostData,
    grossMarginData,
    regularRentalRevenueData,
    proformaReport,
  } = useSelector((state) => state.projectProformaReducer);

  console.log("proformaReport", proformaReport);

  useEffect(() => {
    dispatch(getProformaList());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const [MultifamilyJobStep1Modal, SetMultifamilyJobStep1Modal] =
    useState(false);

  const [perFormaId, setPerFomaId] = useState("");

  const handleProformaHistoryModalShow = (id) => {
    updateState({ ...iState, ProformaHistoryModal: true, proformaId: id });
  };

  useEffect(() => {
    if (ProformaHistoryModal) {
      dispatch(getProformaHistory(proformaId));
    }
  }, [ProformaHistoryModal]);

  const handleProformaHistoryModalClose = () => {
    updateState({ ...iState, ProformaHistoryModal: false });
  };

  const handleProformaReportModalShow = (id, name, createdAt, updatedAt) => {
    updateState({
      ...iState,
      ProformaReportModal: true,
      proformaId: id,
      proformaName: name,
      proformaCreatedAt: createdAt,
      proformaupdatedAt: updatedAt,
    });
  };

  useEffect(() => {
    if (ProformaReportModal) {
      dispatch(getPerformaReport(proformaId));
    }
  }, [ProformaReportModal]);

  const handlePerformMarkUp = () => {
    if (!softCostMarkupPercent || !hardCostMarkupPercent) {
      toast.error(
        "Please provide values for both soft cost and hard cost markup percent.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      const dataa = { softCostMarkupPercent, hardCostMarkupPercent };
      dispatch(performaReportMarkUp(perFormaId, dataa)).then((res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            perFormaId: "",
            ProformaReportModal: false,
          });
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, perFormaId: "", ProformaReportModal: true });
        }
      });
    }
  };

  const handleProformaReportModalClose = () => {
    updateState({ ...iState, ProformaReportModal: false, proformaId: "" });
  };

  const handleUnitPricingModalShow = (id) => {
    updateState({
      ...iState,
      UnitPricingModal: true,
      proformaId: id,
    });
  };

  useEffect(() => {
    if (UnitPricingModal) {
      dispatch(getProformaUnitPrice(proformaId)).then((res) => {
        updateState({ ...iState, unitData: unitPriceList?.data?.unitTypes });
      });
      dispatch(getAffordableRentalRevenue(proformaId));
      dispatch(getProjectAnnualAffordableRentalRevenue(proformaId));
      dispatch(getProjectSalesRevenue(proformaId));
      dispatch(getUnitCostAnalysis(proformaId));
      dispatch(getGrossMarginPerunit(proformaId));
      dispatch(getUnitRentalTable(proformaId)).then((res) => {
        updateState({ ...iState, unitRentalTableData: res?.data?.data });
      });
      dispatch(getUnitMonthlyRental(proformaId)).then((res) => {
        updateState({ ...iState, unitMonthlyRentalTableData: res?.data?.data });
      });
      dispatch(getUnitAffordableRevenue(proformaId)).then((res) => {
        updateState({
          ...iState,
          unitAffordableRevenueTableData: res?.data?.data,
        });
      });
      dispatch(getRegularRentalRevenue(proformaId));

      dispatch(getProjectAnnualRentalRevenue(proformaId)).then((res) => {
        updateState({
          ...iState,
          projectAnnualRentalRevenueData: res?.data?.data,
        });
      });
    }
  }, [UnitPricingModal]);

  const handleUnitChange = (e, row) => {
    const { name, value } = e.target;
    let tempUnitData = [...unitData];
    if (row == "1") {
      tempUnitData[row] = { ...tempUnitData[row], designatedSales: value };
    }
    if (row == "2") {
      tempUnitData[row] = {
        ...tempUnitData[row],
        designatedRegularRental: value,
      };
    }
    if (row == "3") {
      tempUnitData[row] = {
        ...tempUnitData[row],
        designatedAffordableHousingRental: value,
      };
    }
    updateState((prev) => ({
      ...prev,
      unitData: tempUnitData,
    }));
  };

  const handleUnitPricingModalClose = () => {
    updateState({ ...iState, UnitPricingModal: false });
  };
  const [proformaModal, setProformaModal] = useState(false);
  const [show, setShow] = useState(false);
  let count;
  const proformaShowFunc = () => {
    count=0
    setProformaModal(true);
    setShow(false);
    
  };

  const handleProfomaActiveInactive = (id) => {
    dispatch(getActiveInactiveProforma(id)).then((res) => {
      if (res.status === 200) {
        setPerFomaId(res?.data?.id);
        SetMultifamilyJobStep1Modal(true);
        dispatch(getProformaList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleCreateProjectJobs = (id) => {
    dispatch(getCreateProformaProjectAndJobs(id)).then((res) => {
      if (res.status === 200) {
        dispatch(getProformaList());
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleFinancialReportModalShow = (id, status) => {
    updateState({
      ...iState,
      FinancialReportModal: true,
      proformaId: id,
      perFormaStatus: status,
    });
  };

  useEffect(() => {
    if (FinancialReportModal) {
      dispatch(getProformaFinancialReport(proformaId));
    }
  }, [FinancialReportModal]);

  const handleFinancialReportModalClose = () => {
    updateState({ ...iState, FinancialReportModal: false });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSalesRevenue = () => {
    const dataa = {
      commisionFeesPercent,
      legalFees,
      cashInvestments,
      interestRatePercent,
    };
    dispatch(projectSalesRevenue(proformaId, dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getProjectSalesRevenue(proformaId));
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          commisionFeesPercent: "",
          legalFees: "",
          cashInvestments: "",
          interestRatePercent: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUnitRentalTableChange = (e, ind, i) => {
    const { name, value } = e.target;
    const dummyQuantity = [...unitRentalTableData];
    dummyQuantity[ind].MultiUnits[i] = {
      ...dummyQuantity[ind].MultiUnits[i],
      [name]: value,
    };
    updateState({ ...iState, unitRentalTableData: dummyQuantity });
  };

  const updateUnitRentalData = () => {
    const dataa = {
      unitRentalTableData: unitRentalTableData?.map((item) => ({
        unitTypeId: item?.id ?? 0,
        revenuePerUnit: item?.revenuePerUnit ?? 0,
        units: item?.MultiUnits?.map((data) => ({
          multiUnitId: data?.id ?? 0,
          description: data?.description ?? "N/A",
          unitRevenuePrice: data?.totalRevenuePrice ?? 0,
        })),
      })),
    };

    dispatch(updateUnitRental(proformaId, dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getUnitRentalTable(proformaId));
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          unitRentalTableData: [],
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUnitMonthlyRentalTableChange = (e, ind, i) => {
    const { name, value } = e.target;
    const dummyQuantity = [...unitMonthlyRentalTableData];
    dummyQuantity[ind].MultiUnits[i] = {
      ...dummyQuantity[ind].MultiUnits[i],
      [name]: value,
    };
    updateState({ ...iState, unitMonthlyRentalTableData: dummyQuantity });
  };

  const updateUnitMonthlyRentalData = () => {
    const dataa = {
      unitMonthlyRentalTableData: unitMonthlyRentalTableData?.map((item) => ({
        unitTypeId: item?.id ?? 0,
        revenuePerUnit: item?.revenuePerUnit ?? 0,
        units: item?.MultiUnits?.map((data) => ({
          multiUnitId: data?.id ?? 0,
          description: data?.description ?? "N/A",
          unitRevenuePrice: data?.monthlyRental ?? 0,
        })),
      })),
    };

    dispatch(updateUnitMonthlyRental(proformaId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getUnitMonthlyRental(proformaId)).then((res) => {
          updateState({
            ...iState,
            unitMonthlyRentalTableData: res?.data?.data,
          });
        });
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUnitAffordableTableChange = (e, ind, i) => {
    const { name, value } = e.target;
    const dummyQuantity = [...unitAffordableRevenueTableData];
    dummyQuantity[ind].MultiUnits[i] = {
      ...dummyQuantity[ind].MultiUnits[i],
      [name]: value,
    };
    updateState({ ...iState, unitAffordableRevenueTableData: dummyQuantity });
  };

  const updateUnitAffordableData = () => {
    const dataa = {
      unitAffordableRevenueTableData: unitAffordableRevenueTableData?.map(
        (item) => ({
          unitTypeId: item?.id ?? 0,
          revenuePerUnit: item?.revenuePerUnit ?? 0,
          units: item?.MultiUnits?.map((data) => ({
            multiUnitId: data?.id ?? 0,
            description: data?.description ?? "N/A",
            unitRevenuePrice: data?.totalRevenuePrice ?? 0,
          })),
        })
      ),
    };

    dispatch(updateUnitAffordableRevenue(proformaId, dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getUnitMonthlyRental(proformaId)).then((res) => {
          updateState({
            ...iState,
            unitAffordableRevenueTableData: res?.data?.data,
          });
        });
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleProjectAnnualChange = (e, row) => {
    const { name, value } = e.target;
    let tempUnitData = [...projectAnnualRentalRevenueData];
    if (row == "1") {
      tempUnitData[row] = {
        ...tempUnitData[row],
        TotalRentalRevenuePerMonth: value == null ? 0 : value,
      };
    }
    updateState((prev) => ({
      ...prev,
      projectAnnualRentalRevenueData: tempUnitData,
    }));
  };
 
  const [updateId,setUpdateId]=useState("")
  const [UpdateProformaModal, setUpdateProformaModal] = useState(false);
  const handleEdit=(id)=>{
    setUpdateId(id)
    setUpdateProformaModal(true)
    window.localStorage.setItem(
      "profomaId",
      JSON.stringify(id))
  }

  const handleDelete = async (id) => {
    try {
      const response = await dispatch(deletePerforma(id));
      if (response.status === 200) {
        dispatch(getProformaList());
        toast.success("Delete performa Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });   
      }
    } catch (error) {
      toast.error(error?.data?.message, {
        position: toast?.POSITION?.TOP_RIGHT,
      });
      
    }
  };

  
  return (
    <div>
      <Header />
      <SideNav />
        {
          proformaModal && 

      <ProformaModal
        proformaModal={proformaModal}
        setProformaModal={setProformaModal}
        setShow={setShow}
        show={show}
        count={count}
      />
        }

      {/* <UpdatePerforma
       UpdateProformaModal={UpdateProformaModal}
       setUpdateProformaModal={setUpdateProformaModal}
       setShow={setShow}
       updateId={updateId}
      /> */}
      <div className="WrapperArea">
        <div className="WrapperBox">
          
          <div className="TodosArea">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="TitleBox bg-none border-0 p-0 mb-0">
                  <h4 className="Title">Proforma</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="FilesArea">
            <div className="FilesHead">
              <div className="FilesLeft">
                <h4 className="mr-4">Proformas</h4>
              </div>
              <div className="d-flex align-items-start">
                <div className="TodosButtons mt-0">
                  <a onClick={proformaShowFunc}>
                    <h6 data-toggle="modal" data-target="#NoteAdd">
                      <img
                        src={require("../assets/images/create-new-proforma.png")}
                      />
                      Create New Proforma
                    </h6>
                  </a>
                </div>
                <div className="NewActions">
                  <a href="javascript:void(0);">
                    <img src="images/icon-53.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="TableList TableListDecoration CommonHeading">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Home Type</th>
                  <th>State/Province</th>
                  <th>City</th>
                  <th>Date Created</th>
                  <th>Created By</th>
                  <th>Status</th>
                  <th>Project Total Cost</th>
                  <th># Units for Rental</th>
                  <th># Units for Sale</th>
                  <th># Units Affordable Housing Rental</th>
                  <th>Total Number of Units</th>
                  <th>Mark-Up %</th>
                  <th>Net Income</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loader ? (
                  projectProformaList?.data?.length > 0 ? (
                    projectProformaList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.proformaTypeId ?? "N/A"}</td>
                          <td>{item.HomeType?.name ?? "N/A"}</td>
                          <td>{item.stateCode}</td>
                          <td>{item.cityCode}</td>
                          <td>
                            {moment(item.createdAt).format(
                              "YYYY-MM-DD & HH:MM A"
                            )}
                          </td>
                          <td>{item.createdBy}</td>
                          <td>{item.status}</td>
                          <td>${item.projectTotalCOst ?? 0}</td>
                          <td>{item.totaldesignatedRegularRental ?? 0}</td>
                          <td>{item.totaldesignatedSales ?? 0}</td>
                          <td>
                            {item.totaldesignatedAffordableHousingRental ?? 0}
                          </td>
                          <td>{item.noOfUnits ?? 0}</td>
                          <td>{item.markUp ?? 0}</td>
                          <td>${item.noOfUnits ?? 0}</td>
                          <td>
                            <div className="TableThreeDots TableSlider">
                              <ul className="">
                                <li className="dropdown show">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-expanded="true"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth2">
                                    <li onClick={()=>handleEdit(item.id)}>
                                      <a href="javascript:void(0);">
                                        <img
                                          src={require("../assets/images/modify-user.png")}
                                        />
                                        Edit Proforma
                                      </a>
                                    </li>
                                    <li onClick={()=>handleDelete(item.id)}>
                                      <a href="javascript:void(0);">
                                        <img
                                          src={require("../assets/images/dropdown-delete.png")}
                                        />
                                        Delete Proforma
                                      </a>
                                    </li>

                                    <li>
                                      <li>
                                        <a
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleProfomaActiveInactive(
                                              item.id
                                            );
                                          }}
                                        >
                                          <label className="switch">
                                            Activate Proforma
                                            <input
                                              type="checkbox"
                                              checked={item.status}
                                              onChange={() => {}}
                                            />
                                            <span className="slider" />
                                          </label>
                                        </a>
                                      </li>
                                    </li>

                                    <li>
                                      <a
                                        onClick={() =>
                                          handleCreateProjectJobs(item.id)
                                        }
                                      >
                                        <img
                                          src={require("../assets/images/modify-user.png")}
                                        />
                                        Create Project and Jobs
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleProformaReportModalShow(
                                            item.id,
                                            item.name,
                                            item.createdAt,
                                            item.updatedAt
                                          )
                                        }
                                      >
                                        <img
                                          src={require("../assets/images/proforma-report.png")}
                                        />
                                        Proforma Report
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleUnitPricingModalShow(item.id)
                                        }
                                      >
                                        <img
                                          src={require("../assets/images/unit-pricing.png")}
                                        />
                                        Unit Pricing
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleFinancialReportModalShow(
                                            item.id,
                                            item.status
                                          )
                                        }
                                      >
                                        <img
                                          src={require("../assets/images/general-financing.png")}
                                        />
                                        Generate Financial Report
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleProformaHistoryModalShow(
                                            item.id,
                                          )
                                        }
                                      >
                                        <img src="images/timer.png" />
                                        History
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    "No data found"
                  )
                ) : (
                  <Loader />
                )}
              </tbody>
            </table>
          </div>
          <div className="PaginationArea">
            <h6>Showing 1 to 10 of 27 entries</h6>
            <h6>
              <img src="images/leftarrow.png" /> <span>01</span>{" "}
              <img src="images/rightarrow.png" /> Out 10
            </h6>
          </div>
        </div>
      </div>
      <Modal
        show={ProformaHistoryModal}
        onHide={handleProformaHistoryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleProformaHistoryModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Proforma History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Proforma :</span> Proforma 1
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {proformaHistoryList?.events?.length > 0 &&
                    proformaHistoryList?.events?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.userDetails?.name ?? "N/A"}</td>
                          <td>{item.userDetails?.name ?? "N/A"}</td>
                          <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                          <td>{item.activity}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ProformaReportModal}
        onHide={handleProformaReportModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleProformaReportModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Proforma Report</h4>
            </div>
            <div className="ModalMediumheading d-flex justify-content-between">
              <h3>
                <span>Proforma: </span>
                {proformaName}
              </h3>
              <h3>
                <span>Creation Date: </span>
                {moment(proformaCreatedAt).format("YYYY-MM-DD")}
              </h3>
              <h3>
                <span>Last Updated: </span>
                {moment(proformaupdatedAt).format("YYYY-MM-DD")}
              </h3>
            </div>
            <div className="UnitPricingBorder">
              <div className="ProformaAccordion HeaderWidth">
                <div className="accordion" id="accordionExample5">
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingOne4"
                      style={{ width: "50%" }}
                    >
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne4"
                          aria-expanded="false"
                          aria-controls="collapseOne4"
                        >
                          Project Land, Design, Development, Admin Costs
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne4"
                      className="collapse"
                      aria-labelledby="headingOne4"
                      data-parent="#accordionExample5"
                      style={{}}
                    >
                      <div className="card-body">
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div
                            className="TableList TableInput"
                            style={{ width: "50%" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>
                                      Update All Cost Codes in this area by %
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="%"
                                        min={0}
                                        max={100}
                                        name="softCostMarkupPercent"
                                        value={softCostMarkupPercent}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          <table>
                            <thead>
                              <tr>
                                <th>Major Cost Areas</th>
                                <th>
                                  Design, Development <br />
                                  Admin Soft Costs
                                </th>
                                <th>
                                  % of It’s Own
                                  <br />
                                  Section
                                </th>
                                <th>
                                  % of Project Total <br />
                                  Costs
                                </th>
                                <th>
                                  Project Job #’s Created <br />
                                  When Project Is Created
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {proformaReport?.data?.softCostTable?.data
                                ?.length > 0
                                ? proformaReport?.data?.softCostTable?.data?.map(
                                    (item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td className="text-left">
                                            {item.name}
                                          </td>
                                          <td>
                                            ${" "}
                                            {Number(
                                              item.percOfTotalCost ?? 0
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            {Number(
                                              item.perceOfSection ?? 0
                                            ).toFixed(2)}{" "}
                                            %
                                          </td>
                                          <td>
                                            {Number(
                                              item.totalCostSum ?? 0
                                            ).toFixed(2)}
                                            %
                                          </td>
                                          <td></td>
                                        </tr>
                                      );
                                    }
                                  )
                                : "No Data Found"}
                            </tbody>
                            <tfoot>
                              <tr className="FadeyellowCopy">
                                <td className="text-left">
                                  Total Project Construction Costs :
                                </td>
                                <td>
                                  ${" "}
                                  {Number(
                                    proformaReport?.data?.softCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.percOfTotalCost ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {Number(
                                    proformaReport?.data?.softCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.perceOfSection ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {Number(
                                    proformaReport?.data?.softCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.percOfTotalCost ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingOne5"
                      style={{ width: "50%" }}
                    >
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne5"
                          aria-expanded="false"
                          aria-controls="collapseOne5"
                        >
                          Project Construction Costs
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne5"
                      className="collapse"
                      aria-labelledby="headingOne5"
                      data-parent="#accordionExample5"
                      style={{}}
                    >
                      <div className="card-body">
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div
                            className="TableList TableInput"
                            style={{ width: "50%" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>
                                      Update All Cost Codes in this area by %
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="%"
                                        min={0}
                                        max={100}
                                        name="hardCostMarkupPercent"
                                        value={hardCostMarkupPercent}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-left">Major Cost Areas</th>
                                <th>
                                  Construction Hard <br />
                                  Costs
                                </th>
                                <th>
                                  % of It’s Own <br />
                                  Section
                                </th>
                                <th>
                                  % of Project Total <br />
                                  Costs
                                </th>
                                <th>
                                  Project Job #’s Created <br />
                                  When Project Is Created
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {proformaReport?.data?.hardCostTable?.data
                                ?.length > 0
                                ? proformaReport?.data?.hardCostTable?.data?.map(
                                    (item, i) => {
                                      return (
                                        <tr key={i}>
                                          <td className="text-left">
                                            {item.name}
                                          </td>
                                          <td>
                                            ${" "}
                                            {Number(
                                              item.percOfTotalCost ?? 0
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            {Number(
                                              item.perceOfSection ?? 0
                                            ).toFixed(2)}{" "}
                                            %
                                          </td>
                                          <td>
                                            {Number(
                                              item.totalCostSum ?? 0
                                            ).toFixed(2)}
                                            %
                                          </td>
                                          <td></td>
                                        </tr>
                                      );
                                    }
                                  )
                                : "No Data Found"}
                            </tbody>
                            <tfoot>
                              <tr className="FadeyellowCopy">
                                <td className="text-left">
                                  Total Project Construction Costs :
                                </td>
                                <td>
                                  ${" "}
                                  {Number(
                                    proformaReport?.data?.hardCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.percOfTotalCost ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {Number(
                                    proformaReport?.data?.hardCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.perceOfSection ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td>
                                  {Number(
                                    proformaReport?.data?.hardCostTable?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.percOfTotalCost ?? 0)
                                        );
                                      },
                                      0
                                    )
                                  ).toFixed(2)}
                                  %
                                </td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          <table>
                            <thead>
                              <tr>
                                <th />
                                <th>
                                  Design Development, <br />
                                  Admin Soft Costs
                                </th>
                                <th>
                                  Construction Hard <br />
                                  Costs
                                </th>
                                <th>
                                  Project Total <br />
                                  Forecast Costs
                                </th>
                                <th>
                                  Project Job #’s Created <br />
                                  When Project Is Created
                                </th>
                              </tr>
                            </thead>
                            <tfoot>
                              <tr className="FadeyellowCopy">
                                <td className="text-left">
                                  Total Project Construction Costs :
                                </td>
                                <td>$ 105,159</td>
                                <td>$ 105,159</td>
                                <td>$ 105,159</td>
                                <td>126</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingOne6"
                      style={{ width: "50%" }}
                    >
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne6"
                          aria-expanded="false"
                          aria-controls="collapseOne6"
                        >
                          Revenue Estimates Information
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne6"
                      className="collapse"
                      aria-labelledby="headingOne6"
                      style={{}}
                    >
                      <div className="card-body">
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div
                            className="TableList TableInput"
                            style={{ width: "50%" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>
                                      Update All Cost Codes in this area by %
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="$ Unit Pricing"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          b
                          <table>
                            <thead>
                              <tr>
                                <th className="text-center" colSpan={8}>
                                  Unit Information Summary
                                </th>
                              </tr>
                              <tr>
                                <th className="text-left">Unit Types</th>
                                <th>1-1</th>
                                <th>2-1</th>
                                <th>2-1.5</th>
                                <th>3-2</th>
                                <th>3-2.5</th>
                                <th>4-2.5</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="text-left">
                                  No. of Each Type Per This Project
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  Sq/Ft Per Each Unit Type
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  Total Sq/Ft Per Type
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  Each Unit Type % of Project Total Costs
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  No. of Unit Types Designated For Sales
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  No. of Unit Types Designated As Regular
                                  Rentals
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                              <tr>
                                <td className="text-left">
                                  No. of Units Types Designated As Affordable
                                  Housing Rentals
                                </td>
                                <td>40</td>
                                <td>40</td>
                                <td>19</td>
                                <td>19</td>
                                <td>15</td>
                                <td>15</td>
                                <td className="YellowTd255">148</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="ModalMediumheading d-flex justify-content-between py-4">
                          <h3>
                            <span>Per Unit Mark-Up %: </span>20%
                          </h3>
                          <h3>
                            <span>Commission Fees %: </span> 2.5 %
                          </h3>
                          <h3>
                            <span>Legal Fees %: </span> 1.0%
                          </h3>
                          <h3>
                            <span>Cash Investment: </span> $1.000.000.00
                          </h3>
                          <h3>
                            <span>Interest Rate %: </span> 4.0%
                          </h3>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Sales Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Sale Revenues - Per Unit Type
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 5,218,159</td>
                                <td>$ 2,218,159</td>
                                <td>$ 3,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 4,218,159</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Total Cost - Per Unit Type
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 5,218,159</td>
                                <td>$ 2,218,159</td>
                                <td>$ 3,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>
                                    <div className="TextRed">$ 4,218,159</div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Gross Margin $’s - Per Unit Type
                                  </div>
                                </td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 5,218,159</td>
                                <td className="YellowTd255">$ 2,218,159</td>
                                <td className="YellowTd255">$ 3,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">
                                  <strong>$ 4,218,159</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Gross Margin % - Per Unit Type
                                  </div>
                                </td>
                                <td>16.7%</td>
                                <td>16.7%</td>
                                <td>16.7%</td>
                                <td>16.7%</td>
                                <td>16.7%</td>
                                <td>16.7%</td>
                                <td>
                                  <strong>16.7%</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Commissions Fees
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 5,218,159</td>
                                <td>$ 2,218,159</td>
                                <td>$ 3,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>
                                    <div className="TextRed">$ 4,218,159</div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">Legal Fees</div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 5,218,159</td>
                                <td>$ 2,218,159</td>
                                <td>$ 3,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>
                                    <div className="TextRed">$ 4,218,159</div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income Prier To Financing
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <strong>$ 4,218,159</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Cash Investment
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <strong>$ 4,218,159</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Financing Interest
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <strong>
                                    <div className="TextRed">$ 4,218,159</div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Financing
                                  </div>
                                </td>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <strong>$ 4,218,159</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Annual Rental Revenues From Units
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Month
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Total Regular Rental Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Month
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Monthly Property Management Expenses
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Expenses
                                  </div>
                                </td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Annual Rental Revenues From Units
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Month
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control MinWidth60"
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                                <td>$ 12, 159</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Total Affordable Rental Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Type Per
                                    Month
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Type Per
                                    Year
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Monthly Property Management Expenses
                                  </div>
                                </td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>$ 4,218,159</td>
                                <td>
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Expenses
                                  </div>
                                </td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">$ 4,218,159</td>
                                <td className="YellowTd255">
                                  <strong>$ 50,100</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="BlockInputs border">
                          <table>
                            <tfoot>
                              <tr>
                                <td>Total Project Net Income</td>
                                <td>$0</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center p-5">
              <button className="Create" onClick={handlePerformMarkUp}>
                Update Proforma
              </button>
              <button
                className="Create FolderPermissionId"
                onClick={() => handleFinancialReportModalShow(proformaId)}
              >
                Generate Financial Report
              </button>
              <button
                className="Outline"
                onClick={handleProformaReportModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UnitPricingModal}
        onHide={handleUnitPricingModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleUnitPricingModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Unit Pricing</h4>
            </div>
            <div className="ModalMediumheading">
              <h3>
                <span>Proforma: </span>
                {unitPriceList?.data?.proforma?.projectName ?? "N/A"}
              </h3>
              <h3>
                <span>
                  Project Total Cost: $
                  {Number(unitPriceList?.data?.projectTotal ?? 0).toFixed(2)}
                </span>
              </h3>
            </div>
            <div className="UnitPricingBorder">
              <div className="CommonGreenTable mb-4 TfootBg">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <div className="text-left TextRedColor">
                          Unit Stats By Project Unit Types
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>Unit Types</th>
                      <th>1-1</th>
                      <th>2-1</th>
                      <th>2-1.5</th>
                      <th>3-2</th>
                      <th>3-2.5</th>
                      <th>4-2.5</th>
                      <th />
                      <th />
                    </tr>
                    <tr>
                      <th />
                      <th>1 Bed 1 Bath</th>
                      <th>2 Bed 1 Bath</th>
                      <th>2 Bed 1.5 Bath</th>
                      <th>3 Bed 2 Bath</th>
                      <th>3 Bed 2.5 Bath</th>
                      <th>4 Bed 2.5 Bath</th>
                      <th>Project Totals</th>
                      <th>Designated Units %</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="text-left">
                          No. of Each Type Per This Project
                        </div>
                      </td>
                      <td>{unitData?.[0]?.totalUnits ?? 0}</td>
                      <td>{unitData?.[1]?.totalUnits ?? 0}</td>
                      <td>{unitData?.[2]?.totalUnits ?? 0}</td>
                      <td>{unitData?.[3]?.totalUnits ?? 0}</td>
                      <td>{unitData?.[4]?.totalUnits ?? 0}</td>
                      <td>{unitData?.[5]?.totalUnits ?? 0}</td>

                      <td className="YellowTd255">
                        {" "}
                        <td>
                          {unitData?.reduce((acc, item) => {
                            return acc + (item?.totalUnits ?? 0);
                          }, 0)}
                        </td>
                      </td>
                      <td>{"N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">
                          Sq/Ft Per Each Unit Type
                        </div>
                      </td>
                      <td>{unitData?.[0]?.sqFtPerUnitType ?? 0}</td>
                      <td>{unitData?.[1]?.sqFtPerUnitType ?? 0}</td>
                      <td>{unitData?.[2]?.sqFtPerUnitType ?? 0}</td>
                      <td>{unitData?.[3]?.sqFtPerUnitType ?? 0}</td>
                      <td>{unitData?.[4]?.sqFtPerUnitType ?? 0}</td>
                      <td>{unitData?.[5]?.sqFtPerUnitType ?? 0}</td>

                      <td className="YellowTd255">
                        {" "}
                        <td>
                          {unitData?.reduce((acc, item) => {
                            return acc + (item?.sqFtPerUnitType ?? 0);
                          }, 0)}
                        </td>
                      </td>
                      <td>{"N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">Total Sq/Ft Per Type</div>
                      </td>
                      <td>{unitData?.[0]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[1]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[2]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[3]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[4]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[5]?.totalSqFt ?? 0}</td>

                      <td className="YellowTd255">
                        {" "}
                        <td>
                          {unitData?.reduce((acc, item) => {
                            return acc + (item?.totalSqFt ?? 0);
                          }, 0)}
                        </td>
                      </td>
                      <td>{"N/A"}</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">
                          Each Unit Type % of Project Total Costs
                        </div>
                      </td>
                      <td>{unitData?.[0]?.unitTypePer ?? 0}</td>
                      <td>{unitData?.[1]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[2]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[3]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[4]?.totalSqFt ?? 0}</td>
                      <td>{unitData?.[5]?.totalSqFt ?? 0}</td>

                      <td className="YellowTd255">
                        {" "}
                        <td>
                          {unitData?.reduce((acc, item) => {
                            return acc + (item?.totalSqFt ?? 0);
                          }, 0)}
                        </td>
                      </td>
                      <td>{"N/A"}</td>
                    </tr>

                    {/* *************************************ROW-1*************************************** */}

                    <tr>
                      <td>
                        <div className="text-left">
                          No. of Unit Types Designated For Sales
                        </div>
                      </td>

                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[0]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[1]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[2]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[3]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[4]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="1"
                          value={unitData?.[5]?.designatedSales ?? 0}
                          onChange={(e) => handleUnitChange(e, "1")}
                        />
                      </td>
                      <td className="YellowTd255">148</td>
                      <td>54%</td>
                    </tr>

                    {/* *************************************ROW-2*************************************** */}

                    <tr>
                      <td>
                        <div className="text-left">
                          No. of Unit Types Designated As Regular Rentals
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[0]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[1]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[2]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[3]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[4]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="2"
                          value={unitData?.[4]?.designatedRegularRental ?? 0}
                          onChange={(e) => handleUnitChange(e, "2")}
                        />
                      </td>
                      <td className="YellowTd255">148</td>
                      <td>54%</td>
                    </tr>

                    {/* *************************************ROW-3*************************************** */}
                    <tr>
                      <td>
                        <div className="text-left">
                          No. of Units Types Designated As Affordable Housing
                          Rentals
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[0]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[1]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[2]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[3]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[4]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control MinWidth60"
                          placeholder=""
                          name="3"
                          value={
                            unitData?.[5]?.designatedAffordableHousingRental ??
                            0
                          }
                          onChange={(e) => handleUnitChange(e, "3")}
                        />
                      </td>
                      <td className="YellowTd255">100%</td>
                      <td>54%</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>Total %</td>
                      <td>100 %</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div
                className="JobTableCommon JobTableCommon TableCollapse"
                style={{ display: "block", width: "60%" }}
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th>Unit Type</th>
                      <th className="text-center">
                        Total Revenue Per Unit Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {unitRentalTableData?.length > 0 &&
                      unitRentalTableData?.map((item, ind) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <div className="card-header" id="headingOne">
                                  <h2 className="mb-0">
                                    <button
                                      className="btn btn-link btn-block text-left"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      {item.type}
                                    </button>
                                  </h2>
                                </div>
                              </td>
                              <td>$ {item.revenuePerUnit ?? 0}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <div
                                  id="collapseOne"
                                  className="collapse"
                                  aria-labelledby="headingOne"
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body p-0">
                                    <div
                                      className="EnterProjectTable"
                                      style={{ overflowX: "scroll" }}
                                    >
                                      <div className="TableList TableListHeader TableInput">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Unit Number</th>
                                              <th>Description</th>
                                              <th>Proforma Cost</th>
                                              <th>Revenue Price</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item?.MultiUnits?.length > 0 &&
                                              item?.MultiUnits?.map(
                                                (data, i) => {
                                                  return (
                                                    <tr>
                                                      <td>101</td>
                                                      <td>
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Corner Unit"
                                                            name="unitsSqFt"
                                                            value={
                                                              data.unitsSqFt
                                                            }
                                                            onChange={(e) =>
                                                              handleUnitRentalTableChange(
                                                                e,
                                                                ind,
                                                                i
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>15</td>
                                                      <td>
                                                        <div className="form-group">
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            name="unitTypeId"
                                                            value={
                                                              data.unitTypeId
                                                            }
                                                            onChange={(e) =>
                                                              handleUnitRentalTableChange(
                                                                e,
                                                                ind,
                                                                i
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="Button mb-3">
                <button className="Create" onClick={updateUnitRentalData}>
                  Save
                </button>
              </div>

              <div className="CommonGreenTable mb-4 Width80">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={7}>
                        <div className="text-center">
                          Project Units Cost Analysis
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="text-left">
                          Total Cost of Each Unit Type
                        </div>
                      </td>
                      <td>
                        $ {unitCostData?.data?.[0]?.costofEachUnitType ?? 0}
                      </td>
                      <td>
                        $ {unitCostData?.data?.[1]?.costofEachUnitType ?? 0}
                      </td>
                      <td>
                        $ {unitCostData?.data?.[2]?.costofEachUnitType ?? 0}
                      </td>
                      <td>
                        $ {unitCostData?.data?.[3]?.costofEachUnitType ?? 0}
                      </td>
                      <td>
                        $ {unitCostData?.data?.[4]?.costofEachUnitType ?? 0}
                      </td>
                      <td>
                        $ {unitCostData?.data?.[5]?.costofEachUnitType ?? 0}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">
                          Cost of One(1) Unit - Per Each Unit Type
                        </div>
                      </td>
                      <td>$ {unitCostData?.data?.[0]?.perUnitCost ?? 0}</td>
                      <td>$ {unitCostData?.data?.[1]?.perUnitCost ?? 0}</td>
                      <td>$ {unitCostData?.data?.[2]?.perUnitCost ?? 0}</td>
                      <td>$ {unitCostData?.data?.[3]?.perUnitCost ?? 0}</td>
                      <td>$ {unitCostData?.data?.[4]?.perUnitCost ?? 0}</td>
                      <td>$ {unitCostData?.data?.[5]?.perUnitCost ?? 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <label className="CheckBox">
                {" "}
                Per Unit Mark-Up %
                <input type="checkbox" />
                <span className="checkmark" />
              </label>
              <div className="CommonGreenTable my-4 Width80">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={7}>
                        <div className="text-center">Gross Margin Per Unit</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="text-left">
                          Revenue : Per Unit Enter a Mark Up%
                        </div>
                      </td>
                      <td>$ 4,218,159</td>
                      <td>$ 5,218,159</td>
                      <td>$ 2,218,159</td>
                      <td>$ 3,218,159</td>
                      <td>$ 4,218,159</td>
                      <td>$ 4,218,159</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">Revenue : Per Unit</div>
                      </td>
                      <td>$ 4,218,159</td>
                      <td>$ 5,218,159</td>
                      <td>$ 2,218,159</td>
                      <td>$ 3,218,159</td>
                      <td>$ 4,218,159</td>
                      <td>$ 4,218,159</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">Less :Cost Per Unit</div>
                      </td>
                      <td>$ 4,218,159</td>
                      <td>$ 5,218,159</td>
                      <td>$ 2,218,159</td>
                      <td>$ 3,218,159</td>
                      <td>$ 4,218,159</td>
                      <td>$ 4,218,159</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">
                          Gross Margin $: Per Unit
                        </div>
                      </td>
                      <td className="YellowTd255">$ 4,218,159</td>
                      <td className="YellowTd255">$ 5,218,159</td>
                      <td className="YellowTd255">$ 2,218,159</td>
                      <td className="YellowTd255">$ 3,218,159</td>
                      <td className="YellowTd255">$ 4,218,159</td>
                      <td className="YellowTd255">$ 4,218,159</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="text-left">Gross Margin %:Per Unit</div>
                      </td>
                      <td className="YellowTd255">$ 4,218,159</td>
                      <td className="YellowTd255">$ 5,218,159</td>
                      <td className="YellowTd255">$ 2,218,159</td>
                      <td className="YellowTd255">$ 3,218,159</td>
                      <td className="YellowTd255">$ 4,218,159</td>
                      <td className="YellowTd255">$ 4,218,159</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="ProformaAccordion">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header HeaderWidth" id="headingOne17">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne17"
                          aria-expanded="true"
                          aria-controls="collapseOne17"
                        >
                          Project Sales Revenues
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne17"
                      className="collapse show"
                      aria-labelledby="headingOne17"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="EnterProjectTable">
                          <div
                            className="TableList TableInput"
                            style={{ width: "40%" }}
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td>Commission Fees %</td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control "
                                        min={0}
                                        name="commisionFeesPercent"
                                        value={commisionFeesPercent}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Legal Fees %</td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control "
                                        min={0}
                                        name="legalFees"
                                        value={legalFees}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cash Investment</td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control "
                                        min={0}
                                        name="cashInvestments"
                                        value={cashInvestments}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Interest Rate %</td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="number"
                                        className="form-control "
                                        min={0}
                                        name="interestRatePercent"
                                        value={interestRatePercent}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="CommonGreenTable mb-4">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Sales Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Sale Revenues - Per Unit Type
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.TotalSalesRevenue ?? 0}
                                </td>

                                <td>
                                  <strong>
                                    ${" "}
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.TotalSalesRevenue ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Total Cost - Per Unit Type
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    <div className="TextRed">
                                      ${" "}
                                      {projectSaleRevenueData?.data?.reduce(
                                        (acc, item) => {
                                          return acc + (item?.LegalFees ?? 0);
                                        },
                                        0
                                      )}
                                    </div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Gross Margin $’s - Per Unit Type
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.TotalGrossMarginPerce ?? 0}
                                </td>
                                <td className="YellowTd255">
                                  <strong>
                                    ${" "}
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalGrossMarginPerce ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Gross Margin % - Per Unit Type
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.TotalGrossMargin ?? 0}
                                  %
                                </td>
                                <td>
                                  <strong>
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.TotalGrossMargin ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                    %
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Commissions Fees
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.CommissionFees ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    <div className="TextRed">
                                      ${" "}
                                      {projectSaleRevenueData?.data?.reduce(
                                        (acc, item) => {
                                          return (
                                            acc + (item?.CommissionFees ?? 0)
                                          );
                                        },
                                        0
                                      )}
                                    </div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">Legal Fees</div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.LegalFees ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.LegalFees ?? 0}
                                </td>

                                <td>
                                  <strong>
                                    <div className="TextRed">
                                      ${" "}
                                      {projectSaleRevenueData?.data?.reduce(
                                        (acc, item) => {
                                          return acc + (item?.LegalFees ?? 0);
                                        },
                                        0
                                      )}
                                    </div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income Prier To Financing
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.NetIncomePriorToFinancing ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.NetIncomePriorToFinancing ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Cash Investment
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.CashInvestment ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.CashInvestment ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Financing Interest
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.FinancingInterest ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    <div className="TextRed">
                                      ${" "}
                                      {projectSaleRevenueData?.data?.reduce(
                                        (acc, item) => {
                                          return (
                                            acc + (item?.FinancingInterest ?? 0)
                                          );
                                        },
                                        0
                                      )}
                                    </div>
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Financing
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[0]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[1]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[2]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[3]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[4]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectSaleRevenueData?.data?.[5]
                                    ?.NetIncomeAfterFinancing ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {projectSaleRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.NetIncomeAfterFinancing ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="Button mb-3">
                          <button
                            className="Create"
                            onClick={handleSalesRevenue}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProformaAccordion">
                <div className="accordion" id="accordionExample2">
                  <div className="card">
                    <div className="card-header HeaderWidth" id="headingOne1">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne1"
                          aria-expanded="true"
                          aria-controls="collapseOne1"
                        >
                          Project Rental Revenues
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne1"
                      className="collapse"
                      aria-labelledby="headingOne1"
                      data-parent="#accordionExample2"
                    >
                      <div className="card-body">
                        <div
                          className="JobTableCommon JobTableCommon TableCollapse"
                          style={{ display: "block", width: "50%" }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Unit Type</th>
                                <th>Avg Monthly Rental Per Unit Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unitMonthlyRentalTableData?.length > 0 &&
                                unitMonthlyRentalTableData?.map((item, ind) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div
                                            className="card-header"
                                            id="headingOne18"
                                          >
                                            <h2 className="mb-0">
                                              <button
                                                className="btn btn-link btn-block text-left"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#collapseOne18"
                                                aria-expanded="true"
                                                aria-controls="collapseOne18"
                                              >
                                                {item.type}
                                              </button>
                                            </h2>
                                          </div>
                                        </td>
                                        <td>$ {item.revenuePerUnit ?? 0}</td>
                                      </tr>
                                      <tr>
                                        <td colSpan={3}>
                                          <div
                                            id="collapseOne18"
                                            className="collapse"
                                            aria-labelledby="headingOne18"
                                            data-parent="#accordionExample"
                                          >
                                            <div className="card-body p-0">
                                              <div
                                                className="EnterProjectTable"
                                                style={{ overflowX: "scroll" }}
                                              >
                                                <div className="TableList TableListHeader TableInput">
                                                  <table>
                                                    <thead>
                                                      <tr>
                                                        <th>Unit Number</th>
                                                        <th>Description</th>
                                                        <th>
                                                          Monthly Rental Price
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {item?.MultiUnits
                                                        ?.length > 0 &&
                                                        item?.MultiUnits?.map(
                                                          (data, i) => {
                                                            return (
                                                              <tr>
                                                                <td>
                                                                  {data.unitNumber ??
                                                                    1}
                                                                </td>
                                                                <td>
                                                                  <div className="form-group">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Corner Unit Second Floor"
                                                                      name="description"
                                                                      value={
                                                                        data.description
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleUnitMonthlyRentalTableChange(
                                                                          e,
                                                                          ind,
                                                                          i
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  <div className="form-group">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      name="monthlyRental"
                                                                      value={
                                                                        data.monthlyRental
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleUnitMonthlyRentalTableChange(
                                                                          e,
                                                                          ind,
                                                                          i
                                                                        )
                                                                      }
                                                                    />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="Button mb-3">
                          <button
                            className="Create"
                            onClick={updateUnitMonthlyRentalData}
                          >
                            Save
                          </button>
                        </div>
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div className="TableList TableInput">
                            <table>
                              <thead>
                                <tr>
                                  <th>Monthly Expenses</th>
                                  <th colSpan={7}>Amount</th>
                                </tr>
                                <tr>
                                  <th>Name</th>
                                  <th>Unit Type</th>
                                  <th>1-1</th>
                                  <th>2-1</th>
                                  <th>2-1.5</th>
                                  <th>3-2</th>
                                  <th>3-2.5</th>
                                  <th>4-2.5</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert Expense Name"
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </td>
                                  <td />
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Expense"
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProformaAccordion">
                <div className="accordion" id="accordionExample3">
                  <div className="card">
                    <div className="card-header HeaderWidth" id="headingOne3">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne3"
                          aria-expanded="false"
                          aria-controls="collapseOne3"
                        >
                          Rental Expenses
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne3"
                      className="collapse"
                      aria-labelledby="headingOne3"
                      data-parent="#accordionExample3"
                      style={{}}
                    >
                      <div className="card-body">
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div className="TableList">
                            <table>
                              <thead>
                                <tr>
                                  <th className="pl-2">
                                    <strong>Expense Name</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 1-1</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 2-1</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 2-1.5</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 3-2</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 3-2.5</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 4-2.5</strong>
                                  </th>
                                  <th>
                                    <strong>Action</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="pl-2">
                                    Property Management Expenses
                                  </td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            {/* <li><a href="Warranty_Work_Order1.html"><img src="images/work-order-black.png">Create Work Order</a></li> */}
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea px-3">
                            <h6>Showing 1 to 10 of 27 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Annual Rental Revenues From Units
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Month
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[0]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[1]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[2]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[3]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[4]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control MinWidth60"
                                      name="1"
                                      value={
                                        projectAnnualRentalRevenueData?.[5]
                                          ?.TotalRentalRevenuePerMonth ?? 0
                                      }
                                      onChange={(e) =>
                                        handleProjectAnnualChange(e, "1")
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[0]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[1]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[2]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[3]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[4]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualRentalRevenueData?.[5]
                                    ?.TotalRentalRevenuePerYear ?? "0"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Total Regular Rental Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Month
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerMonth ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {regularRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerMonth ??
                                            0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {regularRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerYear ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Monthly Property Management Expenses
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[0]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[1]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[2]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[3]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[4]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[5]
                                    ?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {regularRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc + (item?.monthlyExpanse ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Expenses
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[0]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[1]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[2]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[3]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[4]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {regularRentalRevenueData?.data?.[5]
                                    ?.NetIncomeAfterExpenses ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {regularRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.NetIncomeAfterExpenses ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProformaAccordion">
                <div className="accordion" id="accordionExample25">
                  <div className="card">
                    <div className="card-header HeaderWidth" id="headingOne31">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne31"
                          aria-expanded="true"
                          aria-controls="collapseOne31"
                        >
                          Project Addordable Housing Rentals Revenue
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne31"
                      className="collapse"
                      aria-labelledby="headingOne31"
                      data-parent="#accordionExample25"
                    >
                      <div className="card-body">
                        <div
                          className="JobTableCommon JobTableCommon TableCollapse"
                          style={{ display: "block", width: "50%" }}
                        >
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Unit Type</th>
                                <th>Avg Revenue Per Unit Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unitAffordableRevenueTableData?.length > 0 &&
                                unitAffordableRevenueTableData?.map(
                                  (item, ind) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            <div
                                              className="card-header"
                                              id="headingOne32"
                                            >
                                              <h2 className="mb-0">
                                                <button
                                                  className="btn btn-link btn-block text-left"
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target="#collapseOne32"
                                                  aria-expanded="true"
                                                  aria-controls="collapseOne32"
                                                >
                                                  {item.type}
                                                </button>
                                              </h2>
                                            </div>
                                          </td>
                                          <td>$ {item.revenuePerUnit ?? 0}</td>
                                        </tr>
                                        <tr>
                                          <td colSpan={3}>
                                            <div
                                              id="collapseOne32"
                                              className="collapse"
                                              aria-labelledby="headingOne32"
                                              data-parent="#accordionExample"
                                            >
                                              <div className="card-body p-0">
                                                <div
                                                  className="EnterProjectTable"
                                                  style={{
                                                    overflowX: "scroll",
                                                  }}
                                                >
                                                  <div className="TableList TableListHeader TableInput">
                                                    <table>
                                                      <thead>
                                                        <tr>
                                                          <th>Unit Number</th>
                                                          <th>Description</th>
                                                          <th>Revenue Price</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {item?.MultiUnits
                                                          ?.length > 0 &&
                                                          item?.MultiUnits?.map(
                                                            (data, i) => {
                                                              return (
                                                                <tr>
                                                                  <td>
                                                                    {" "}
                                                                    {data.unitNumber ??
                                                                      1}
                                                                  </td>
                                                                  <td>
                                                                    <div className="form-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        // placeholder="Corner Unit"
                                                                        name="description"
                                                                        value={
                                                                          data.description
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleUnitAffordableTableChange(
                                                                            e,
                                                                            ind,
                                                                            i
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                  <td>
                                                                    <div className="form-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="totalRevenuePrice"
                                                                        value={
                                                                          data.totalRevenuePrice
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleUnitAffordableTableChange(
                                                                            e,
                                                                            ind,
                                                                            i
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            }
                                                          )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                        <div className="Button mb-3">
                          <button
                            className="Create"
                            onClick={updateUnitAffordableData}
                          >
                            Save
                          </button>
                        </div>
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div className="TableList TableInput">
                            <table>
                              <thead>
                                <tr>
                                  <th>Monthly Expenses</th>
                                  <th colSpan={7} className="text-center">
                                    <strong>Amount</strong>
                                  </th>
                                </tr>
                                <tr>
                                  <th>Name</th>
                                  <th>Unit Type</th>
                                  <th>1-1</th>
                                  <th>2-1</th>
                                  <th>2-1.5</th>
                                  <th>3-2</th>
                                  <th>3-2.5</th>
                                  <th>4-2.5</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Insert Expense Name"
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </td>
                                  <td />
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Expense"
                                        style={{ width: 200 }}
                                      />
                                    </div>
                                  </td>
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                  <td />
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ProformaAccordion">
                <div className="accordion" id="accordionExample41">
                  <div className="card">
                    <div className="card-header HeaderWidth" id="headingOne41">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne41"
                          aria-expanded="false"
                          aria-controls="collapseOne41"
                        >
                          Rental Expenses
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne41"
                      className="collapse"
                      aria-labelledby="headingOne41"
                      data-parent="#accordionExample41"
                      style={{}}
                    >
                      <div className="card-body">
                        <div
                          className="EnterProjectTable"
                          style={{ overflowX: "scroll" }}
                        >
                          <div className="TableList">
                            <table>
                              <thead>
                                <tr>
                                  <th className="pl-2">
                                    <strong>Expense Name</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 1-1</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 2-1</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 2-1.5</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 3-2</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 3-2.5</strong>
                                  </th>
                                  <th>
                                    <strong>Unit Type 4-2.5</strong>
                                  </th>
                                  <th>
                                    <strong>Action</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="pl-2">
                                    Property Management Expenses
                                  </td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>$ 105,159</td>
                                  <td>
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a href="Warranty_Work_Order1.html">
                                                <img src="images/Action-1.png" />
                                                Edit Expense
                                              </a>
                                            </li>
                                            <li>
                                              <a href="Warranty_Work_Order1.html">
                                                <img src="images/dropdown-delete.png" />
                                                Delete Expense
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="PaginationArea px-3">
                            <h6>Showing 1 to 10 of 27 entries</h6>
                            <h6>
                              <img src="images/leftarrow.png" /> 01{" "}
                              <img src="images/rightarrow.png" /> Out 10
                            </h6>
                          </div>
                        </div>
                        <div className="CommonGreenTable my-4 Width80">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Project Annual Affordable Rental Revenues
                                    From Units
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Type Per
                                    Month
                                  </div>
                                </td>

                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[0]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[1]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[2]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[3]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[4]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[5]?.TotalRentalRevenuePerMonth ??
                                    0}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Per Year
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[0]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[1]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[2]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[3]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[4]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[5]?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Monthly Property Management Expenses
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[0]?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[1]?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[2]?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[3]?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[4]?.monthlyExpanse ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {projectAnnualAffordabaleRentalRevenueData
                                    ?.data?.[5]?.monthlyExpanse ?? 0}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="CommonGreenTable my-4">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={7}>
                                  <div className="text-center">
                                    Total Affordable Rental Revenues
                                  </div>
                                </th>
                                <th>Project Totals</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Type Per
                                    Month
                                  </div>
                                </td>

                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {affordabaleRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerYear ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Total Rental Revenues - Per Unit Type Per
                                    Year
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>

                                <td>
                                  <strong>
                                    ${" "}
                                    {affordabaleRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerYear ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Less : Monthly Property Management Expenses
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  <strong>
                                    ${" "}
                                    {affordabaleRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerYear ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="text-left">
                                    Net Income After Expenses
                                  </div>
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[0]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[1]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[2]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[3]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[4]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td>
                                  ${" "}
                                  {affordabaleRentalRevenueData?.data?.[5]
                                    ?.TotalRentalRevenuePerYear ?? 0}
                                </td>
                                <td className="YellowTd255">
                                  <strong>
                                    ${" "}
                                    {affordabaleRentalRevenueData?.data?.reduce(
                                      (acc, item) => {
                                        return (
                                          acc +
                                          (item?.TotalRentalRevenuePerYear ?? 0)
                                        );
                                      },
                                      0
                                    )}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="BlockInputs border">
                <table>
                  <tfoot>
                    <tr>
                      <td>Total Project Net Income</td>
                      <td>
                        $
                        {Number(unitPriceList?.data?.projectTotal ?? 0).toFixed(
                          2
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="Button d-flex justify-content-between align-items-center p-5">
              <button className="Create" data-dismiss="modal">
                Save
              </button>
              <button className="Outline" onClick={handleUnitPricingModalClose}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={FinancialReportModal}
        onHide={handleFinancialReportModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFinancialReportModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Financial Report</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Status: </span>
                  {perFormaStatus ?? "N/A"}
                </h3>
              </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div>
                </div>
                <div className="StatementAdjustmentArea px-4">
                  <h6>FINANCIAL REPORT</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Project Name</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.financialReport
                              ?.projectName ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Type</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.financialReport
                              ?.proformaTypeId ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Home Type</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.financialReport
                              ?.homeTypeId ?? "N/A"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Report Date</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={moment(
                            performaFinancialReportData?.data?.financialReport
                              ?.createdAt
                          ).format("YYYY-MM-DD")}
                        />
                      </div>
                      <div className="form-group">
                        <label>State/Provice</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.financialReport
                              ?.stateCode ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.financialReport
                              ?.projectName ?? "N/A"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">PROJECT DETAILS</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Blocks</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.projectDetails
                              ?.blocks ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Stories</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.projectDetails
                              ?.stories ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Units</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.projectDetails
                              ?.units ?? "N/A"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Common Garages</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.projectDetails
                              ?.commonGarages ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Common Buildings</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={
                            performaFinancialReportData?.data?.projectDetails
                              ?.commonBuilding ?? "N/A"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">PROJECT COSTS</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>Land Acquisition Costs</label>
                        <p className="LightGreen">
                          $
                          {performaFinancialReportData?.data?.projectCosts
                            ?.landAcquisition ?? "0"}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Planning, Design &amp; Approvals</label>
                        <p className="LightGreen">
                          $
                          {performaFinancialReportData?.data?.projectCosts
                            ?.planning ?? "0"}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Amenities, Off-Site Costs</label>
                        <p className="LightGreen">
                          $
                          {performaFinancialReportData?.data?.projectCosts
                            ?.amenties ?? "0"}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Management &amp; Overhead</label>
                        <p className="LightGreen">
                          $
                          {performaFinancialReportData?.data?.projectCosts
                            ?.managementOverhead ?? "0"}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Site Works</label>
                        <p className="LightGreen">
                          $
                          {Number(
                            performaFinancialReportData?.data?.projectCosts
                              ?.siteWorks?? "0"
                          ).toFixed(2) }
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Project Costs</label>
                        <p className="BlueNew">
                          $
                          {Number(
                            performaFinancialReportData?.data?.projectCosts
                              ?.total?? "0"
                          ).toFixed(2) }
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">SALES REVENUE FORECAST</h6>
                      <div className="StatementAdjustmentBottom">
                        <label />
                        <label>Number of Units</label>
                        <label>
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.totalUnit ?? 0}
                        </label>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Unit Sales (20% Markup)</label>
                        <p className="FadeBlueNew">
                          $
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.sales ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Units Regular Rental</label>
                        <p className="FadeBlueNew">
                          $
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.regularRental ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Units Affordable Rental</label>
                        <p className="FadeBlueNew">
                          $
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.affordableRentalCost ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Revenue</label>
                        <p className="FadeBlueNew2">
                          $
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.totalRevenue ?? 0}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">Less: Fees &amp; Expenses</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>Sales Comission Fees (2.5%)</label>
                        <p className="LightPink">
                          $
                          {performaFinancialReportData?.data?.fees
                            ?.commissionFees ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Sales Legal Fees (1%)</label>
                        <p className="LightPink">
                          $
                          {performaFinancialReportData?.data?.fees?.legalFees ??
                            0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Regular Rental Expenses</label>
                        <p className="LightPink">
                          $
                          {performaFinancialReportData?.data?.fees
                            ?.rentalExpenses ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Affordable Rental Expenses</label>
                        <p className="LightPink">
                          $
                          {performaFinancialReportData?.data?.fees
                            ?.affordableRentalExpenses ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Fees &amp; Expenses</label>
                        <p className="DarkRed">
                          $
                          {performaFinancialReportData?.data?.fees
                            ?.totalExpenses ?? 0}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="StatementAdjustmentTable">
                        <table>
                          <thead>
                            <tr>
                              <th colSpan={3} className="text-center">
                                Cash Investment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Financing Interest (x%)</strong>
                              </td>
                              <td>
                                <strong>
                                  $
                                  {performaFinancialReportData?.data?.fees
                                    ?.financingInterest ?? 0}
                                </strong>
                              </td>
                              <td>
                                <div className="BgFadeRed">
                                  <strong>
                                    $
                                    {performaFinancialReportData?.data
                                      ?.financialReport?.cashInvestments ?? 0}
                                  </strong>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">SUMMARY</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Revenue</label>
                        <p className="MultiBlue">
                          $
                          {performaFinancialReportData?.data
                            ?.totalSalesRevenue?.[0]?.totalRevenue ?? 0}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Project Costs</label>
                        <p className="MultiRed">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.projectCosts
                              ?? 0).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Fees &amp; Expenses</label>
                        <p className="MultiRed">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary?.fees?? 0).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Net Cash Flow Before Financing</label>
                        <p className="MultiBlue2">
                          $
                          {Number(performaFinancialReportData?.data?.summary?.cashFlow ?? 0).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Financing Interest</label>
                        <p className="MultiRed">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.financeInterest ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Net Cash Flow to Developer</label>
                        <p className="MultiGreen">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.cashFlowToDeveloper ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Cash Investment</label>
                        <p className="MultiOrange">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.cashInvestment ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Total Cash-On-Cash Return</label>
                        <p className="MultiGreen">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.cashOnReturn ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Annualized Cash-On-Cash Return</label>
                        <p className="MultiDarkBlue">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.annualCashOnReturn ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                      <div className="StatementAdjustmentBottom">
                        <label>Internal Rate of Return</label>
                        <p className="MultiDarkBlue">
                          $
                          {Number(
                            performaFinancialReportData?.data?.summary
                              ?.interestRateOnReturn ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create"
                  type="button"
                  onClick={() => handlePrint()}
                >
                  Print
                </button>
                <button
                  className="Create"
                  type="button"
                  onClick={() => handlePrint()}
                >
                  Download
                </button>
                <button
                  className="Outline"
                  onClick={handleFinancialReportModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <MultifamilyJobStep1
        perFormaId={perFormaId}
        setShow={setShow}
        MultifamilyJobStep1Modal={MultifamilyJobStep1Modal}
      />
      <UpdatePerforma
       UpdateProformaModal={UpdateProformaModal}
       setUpdateProformaModal={setUpdateProformaModal}
       setShow={setShow}
       updateId={updateId}
      />
    </div>
  );
};

export default ProjectProforma;
