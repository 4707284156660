import { fileManagerAction } from "../actionTypes";
const initialState={
    allFolderList:[],
    searchFolderList:[],
    documentationList:[],
    searchDocumentationList:[],
    viewDocumentation:[],
    pendingFileList:[],
    tagList:[],
    fileHistoryList:[],
    folderHistoryList:[],
    loader:false,

    allDocumentKey:[],
    manageSignatureList:[],
    allVendorList:[],
    allComapnyClientList:[],
    fileMangerEventHistoryList:[],
}


export default function fileManagerData(state = initialState, {type, payload}) {
    
    switch(type) {
      case fileManagerAction.ALL_FOLDER_LIST_INITIATE: {
        return {
          ...state, loader:true, allFolderList:[]
        }
      }
      case fileManagerAction.ALL_FOLDER_LIST_SUCCESS: {
        return {
          ...state, loader:false, allFolderList:payload
        }
      }
      case fileManagerAction.ALL_FOLDER_LIST_FAILURE: {
        return {
          ...state, loader:false,allFolderList:[]
        }
      }

      case fileManagerAction.ALL_FOLDER_HISTORY_INITIATE: {
        return {
          ...state, loader:true, folderHistoryList:[]
        }
      }
      case fileManagerAction.ALL_FOLDER_HISTORY_SUCCESS: {
        return {
          ...state, loader:false, folderHistoryList:payload
        }
      }
      case fileManagerAction.ALL_FOLDER_HISTORY_FAILURE: {
        return {
          ...state, loader:false,folderHistoryList:[]
        }
      }

      case fileManagerAction.SEARCH_FOLDER_BYNAME_INITIATE: {
        return {
          ...state, loader:true, searchFolderList:[]
        }
      }
      case fileManagerAction.SEARCH_FOLDER_BYNAME_SUCCESS: {
        return {
          ...state, loader:false, searchFolderList:payload
        }
      }
      case fileManagerAction.SEARCH_FOLDER_BYNAME_FAILURE: {
        return {
          ...state, loader:false,searchFolderList:[]
        }
      }

      case fileManagerAction.LIST_DOCUMENTATION_INITIATE: {
        return {
          ...state, loader:true, documentationList:[]
        }
      }
      case fileManagerAction.LIST_DOCUMENTATION_SUCCESS: {
        return {
          ...state, loader:false, documentationList:payload
        }
      }
      case fileManagerAction.LIST_DOCUMENTATION_FAILURE: {
        return {
          ...state, loader:false,documentationList:[]
        }
      }

      case fileManagerAction.SEARCH_LIST_DOCUMENTATION_INITIATE: {
        return {
          ...state, loader:true, searchDocumentationList:[]
        }
      }
      case fileManagerAction.SEARCH_LIST_DOCUMENTATION_SUCCESS: {
        return {
          ...state, loader:false, searchDocumentationList:payload
        }
      }
      case fileManagerAction.SEARCH_LIST_DOCUMENTATION_FAILURE: {
        return {
          ...state, loader:false,searchDocumentationList:[]
        }
      }

      case fileManagerAction.VIEW_DOCUMENTATION_INITIATE: {
        return {
          ...state, loader:true, viewDocumentation:[]
        }
      }
      case fileManagerAction.VIEW_DOCUMENTATION_SUCCESS: {
        return {
          ...state, loader:false, viewDocumentation:payload
        }
      }
      case fileManagerAction.VIEW_DOCUMENTATION_FAILURE: {
        return {
          ...state, loader:false,viewDocumentation:[]
        }
      }


      case fileManagerAction.PENDING_FILE_EVENT_LIST_INITIATE: {
        return {
          ...state, loader:true, pendingFileList:[]
        }
      }
      case fileManagerAction.PENDING_FILE_EVENT_LIST_SUCCESS: {
        return {
          ...state, loader:false, pendingFileList:payload
        }
      }
      case fileManagerAction.PENDING_FILE_EVENT_LIST_FAILURE: {
        return {
          ...state, loader:false,pendingFileList:[]
        }
      }

      case fileManagerAction.GET_ALL_TAGS_LIST_INITIATE: {
        return {
          ...state, loader:true, tagList:[]
        }
      }
      case fileManagerAction.GET_ALL_TAGS_LIST_SUCCESS: {
        return {
          ...state, loader:false, tagList:payload
        }
      }
      case fileManagerAction.GET_ALL_TAGS_LIST_FAILURE: {
        return {
          ...state, loader:false,tagList:[]
        }
      }

      case fileManagerAction.FILE_HISTORY_LIST_INITIATE: {
        return {
          ...state, loader:true, fileHistoryList:[]
        }
      }
      case fileManagerAction.FILE_HISTORY_LIST_SUCCESS: {
        return {
          ...state, loader:false, fileHistoryList:payload
        }
      }
      case fileManagerAction.FILE_HISTORY_LIST_FAILURE: {
        return {
          ...state, loader:false,fileHistoryList:[]
        }
      }
   
      case fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_INITIATE: {
        return {...state, loader:true, allDocumentKey:[] }
      }
      case fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_SUCCESS: {
        return {...state, loader:false, allDocumentKey:payload }
      }
      case fileManagerAction.GET_ALL_DOCUMENT_KEY_LIST_FAILURE: {
        return {...state, loader:false,allDocumentKey:[]}
      }
      
      case fileManagerAction.GET_MANAGE_SIGNATURE_INITIATE: {
        return {...state,  manageSignatureList:[] }
      }
      case fileManagerAction.GET_MANAGE_SIGNATURE_SUCCESS: {
        return {...state, manageSignatureList:payload }
      }
      case fileManagerAction.GET_MANAGE_SIGNATURE_FAILURE: {
        return {...state,manageSignatureList:[]}
      }

      case fileManagerAction.GET_ALL_VENDORS_INITIATE: {
        return {...state,  allVendorList:[] }
      }
      case fileManagerAction.GET_ALL_VENDORS_SUCCESS: {
        return {...state, allVendorList:payload }
      }
      case fileManagerAction.GET_ALL_VENDORS_FAILURE: {
        return {...state,allVendorList:[]}
      }

      case fileManagerAction.GET_ALL_COMPANY_CLIENT_INITIATE: {
        return {...state,  allComapnyClientList:[] }
      }
      case fileManagerAction.GET_ALL_COMPANY_CLIENT_SUCCESS: {
        return {...state, allComapnyClientList:payload }
      }
      case fileManagerAction.GET_ALL_COMPANY_CLIENT_FAILURE: {
        return {...state,allComapnyClientList:[]}
      }
      case fileManagerAction.GET_FILE_MANGER_EVENT_HISTORY_INITIATE: {
        return {...state,  fileMangerEventHistoryList:[] }
      }
      case fileManagerAction.GET_FILE_MANGER_EVENT_HISTORY_SUCCESS: {
        return {...state, fileMangerEventHistoryList:payload }
      }
      case fileManagerAction.GET_FILE_MANGER_EVENT_HISTORY_FAILURE: {
        return {...state,fileMangerEventHistoryList:[]}
      }
      default: 
      return state
  }
}