import React from "react";
import { useDispatch } from "react-redux";
import { checkArray } from "../../../utils/CheckType";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";


import { getAllJobListAction } from "../../../redux/action/jobAction";
import {
  statsBudgePoChanageOrderAction,
  statsFpoAnalysisAction,
  statsFpoAnalysisSummaryAction,
  statsPurchasingReportAction,
  statsSalesAction,
  statsWarrantyAction,
} from "../../../redux/action/reportsAction";
import { useSelector } from "react-redux";

const Statistical = ({ activeTab, setActiveTab, iState, updateState }) => {
  const {
    reportName,
    ProjectId,
    allJob,
    JobIds,
    JobType,
    AvaiInvestorView,
    toDateChecked,
    fromDate,
    toDate,
  } = iState;
  const dispatch = useDispatch();

  const { projectListData, jobListData } = useSelector(
    (state) => state.jobReducer
  );

  const {  statsPurchasingReport,
    statsBudgePoChanageOrder,
    statsFpoAnalysis,
    statsFpoAnalysisSummary,
    statsSales,
    statsWarranty,
    statsWarrantyChartFirst,
    statsWarrantyChartSecond,
    statsWarrantyChartThird} = useSelector((state)=>state.reportsReducer)

  const handleSelectJob = (e) => {
    updateState({ ...iState, JobIds: [e.target.value] });
  };

  const handleSelectProject = (e) => {
    updateState({ ...iState, ProjectId: e.target.value });
    dispatch(getAllJobListAction(e.target.value));
  };

  const handleSubmitStatical = () => {
    let formIsValid = true;
    if (formIsValid) {
      const data = {
        reportName: activeTab,
        ProjectId,
        allJob,
        JobIds,
        JobType: "MultiUnit",
        AvaiInvestorView,
        toDateChecked,
        fromDate: fromDate,
        toDate: toDate,
      };

      if (activeTab == "Purchasing Statistics") {
        dispatch(statsPurchasingReportAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Purchasing Statistics",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (
        activeTab ==
        "Budget PO's, Change Order PO's & Field Variance PO's (FPO's) Summary"
      ) {
        dispatch(statsBudgePoChanageOrderAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (activeTab == "Revenues & Gross Margin Analysis Summary") {
        dispatch(statsFpoAnalysisAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (activeTab == "Revenues & Gross Margin Analysis Summary") {
        dispatch(statsFpoAnalysisSummaryAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (
        activeTab ==
          "Field Variance Purchase Orders (FPO's) Detail By Reason" ||
        activeTab == "Field Variance Purchase Orders (FPO's) Detail By Vendor"
      ) {
        dispatch(statsFpoAnalysisAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (
        activeTab ==
        "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date"
      ) {
        dispatch(statsSalesAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      } else if (
        activeTab == "Warranty Service Requests & Trade Work Orders Stats"
      ) {
        dispatch(statsWarrantyAction(data))
          .then((res) => {
            if (res.status === 200) {
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              updateState({
                reportName: "Sales & Cash Deposits Status",
                ProjectId: "",
                allJob: false,
                JobIds: [],
                JobType: "",
                AvaiInvestorView: false,
                toDateChecked: false,
                fromDate: "",
                toDate: "",
              });
            }
          })
          .catch((error) => {
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            // updateState({
            //   reportName: "Sales & Cash Deposits Status",
            //   ProjectId: "",
            //   allJob: false,
            //   JobIds: [],
            //   JobType: "",
            //   AvaiInvestorView: false,
            //   toDateChecked: false,
            //   fromDate: "",
            //   toDate: "",
            // });
          });
      }
    }
  };


  
  const chartState = {
    series: [{
        name: '# of SR Issused by customer',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
        name: '# of Wo Issued to',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      }],
 
    
    options: {
        
    
      chart:  {
        type: 'bar',
        height: 250,
        width :"20%"
      },
      fill: {
        opacity: 1
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      }, tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      },


      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '35%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },

      title: {
        text: "Customers",
        align: "left",
      },
   
      xaxis: {
        categories: ['Mark', 'Devine', 'Jack', 'Lucky', 'Naomi', 'Robin', 'Barry', 'Jack', 'Mark'],
      },
      yaxis: {
        title: {
          text: '$ (# of SR Issused by customer)'
        }
      }
    },
  };

  const pieState={
         
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    },
  }

  return (
    <div class="tab-pane fade" id="SatisticalTab">
      <div
        class="SalesDocumentArea"
        style={{ display: activeTab == "" ? "block" : "none" }}
      >
        <div class="row">
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/sales-report.png")}
                    />{" "}
                    &nbsp; Sales
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select
                      class="myselect"
                      onChange={(e) => setActiveTab(e.target.value)}
                    >
                      <option value="">Select a Vendor Type</option>
                      <option value="Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date">
                        Project Consolidated Sales Stats Month-To-Date,
                        Year-To-Date & Project-To-Date
                      </option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/design-report.png")}
                    />{" "}
                    &nbsp; Design
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/estimation-report.png")}
                    />{" "}
                    &nbsp; Estimation
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option value="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/estimation-report.png")}
                    />{" "}
                    &nbsp; Purchasing
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select
                      class="myselect"
                      onChange={(e) => setActiveTab(e.target.value)}
                    >
                      <option value="">Select a Vendor Type</option>
                      <option value="Purchasing Statistics">
                        Purchasing Statistics
                      </option>
                      <option value="Budget PO's, Change Order PO's & Field Variance PO's (FPO's) Summary">
                        Budget PO's, Change Order PO's & Field Variance PO's
                        (FPO's) Summary
                      </option>
                      <option value="Revenues & Gross Margin Analysis Summary">
                        Revenues & Gross Margin Analysis Summary
                      </option>
                      <option value="Field Variance Purchase Orders (FPO's) Detail By Reason">
                        Field Variance Purchase Orders (FPO's) Detail By Reason
                      </option>
                      <option value="Field Variance Purchase Orders (FPO's) Detail By Vendor">
                        Field Variance Purchase Orders (FPO's) Detail By Vendor
                      </option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/construction_apps.png")}
                    />{" "}
                    &nbsp; Constrcution
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/warranty-reports.png")}
                    />{" "}
                    &nbsp; Warranty
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select
                      class="myselect"
                      onChange={(e) => setActiveTab(e.target.value)}
                    >
                      <option value="">Select a Vendor Type</option>
                      <option value="Warranty Service Requests & Trade Work Orders Stats">
                        Warranty Service Requests & Trade Work Orders Stats
                      </option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/accounting-report.png")}
                    />{" "}
                    &nbsp; Accounting
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/accounting-report.png")}
                    />{" "}
                    &nbsp; Proforma
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/electronic-signing.png")}
                    />{" "}
                    &nbsp; Electronic Signing
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/job-icon.png")}
                    />{" "}
                    &nbsp; Job
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="FilesBox2 Safety">
              <figcaption>
                <div>
                  <h4>
                    {" "}
                    <img
                      src={require("../../../assets/images/vendor-icon.png")}
                    />{" "}
                    &nbsp; Vendors
                  </h4>
                </div>
              </figcaption>

              <div class="FilesBoxCenterSafety">
                <div class="form-group">
                  <div class="CommonSelectBox">
                    <select class="myselect">
                      <option selected="">Select a Vendor Type</option>
                    </select>
                    <span>
                      <img src={require("../../../assets/images/down.png")} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="FinalcialReportArea"
        style={{
          display:
            activeTab == "Purchasing Statistics" ||
            activeTab ==
              "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date" ||
            activeTab ==
              "Budget PO's, Change Order PO's & Field Variance PO's (FPO's) Summary" ||
            activeTab == "Revenues & Gross Margin Analysis Summary" ||
            activeTab ==
              "Field Variance Purchase Orders (FPO's) Detail By Reason" ||
            activeTab ==
              "Field Variance Purchase Orders (FPO's) Detail By Vendor" ||
            activeTab ==
              "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date" ||
            activeTab == "Warranty Service Requests & Trade Work Orders Stats"
              ? "block"
              : "none",
        }}
      >
        <div class="FraHeader pb-0">
          <ul>
            <li>
              <a onClick={() => setActiveTab("")} class="BackReportBtn">
                <img
                  src={require("../../../assets/images/left-arrow-red.png")}
                />
                Back Report List
              </a>
            </li>
          </ul>
          <div class="FraHeaderLeft">
            <h5>
              <img src={require("../../../assets/images/sales-icon.png")} />
              Sales
            </h5>
            <h6>{activeTab}</h6>
          </div>
          <div class="Filter">
            <div class="form-group">
              <h6>Project Name:</h6>
              <select onChange={handleSelectProject}>
                <option>Select Project Name</option>
                {checkArray(projectListData) &&
                  projectListData?.map((ele, i) => {
                    return (
                      <>
                        <option value={ele?.id}>{ele?.projectName}</option>
                      </>
                    );
                  })}{" "}
              </select>
            </div>
            <div class="form-group ml-5">
              <h6>Job(s) #:</h6>
              <select class="form-group" onChange={handleSelectJob}>
                <option>Select Job Id</option>
                {checkArray(jobListData?.jobs) &&
                  jobListData?.jobs?.map((ele, i) => {
                    return (
                      <>
                        <option value={ele?.id}>{ele?.jobNumber}</option>
                      </>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <div class="FraHeader">
          <div class="form-group d-flex align-items-center">
            <h6>Available in Investor View</h6>
            <label class="switch ml-4">
              <input
                type="checkbox"
                onChange={(e) =>
                  updateState({
                    ...iState,
                    AvaiInvestorView: e.target.checked,
                  })
                }
              />
              <span class="slider"></span>
            </label>
          </div>
          <div class="Filter">
            <div class="form-group">
              <h6>To-Date</h6>
              <label class="CheckBox">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    updateState({
                      ...iState,
                      toDateChecked: e.target.checked,
                    })
                  }
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="form-group">
              <h6>Or</h6>
              <input
                type="date"
                class="form-control"
                onChange={(e) =>
                  updateState({ ...iState, fromDate: e.target.value })
                }
              />
            </div>
            <div class="form-group">
              <h6>To</h6>
              <input
                type="date"
                class="form-control"
                onChange={(e) =>
                  updateState({ ...iState, toDate: e.target.value })
                }
              />
            </div>
            <a class="RunReportBtn" onClick={handleSubmitStatical}>
              Run Report
            </a>
          </div>
        </div>
        <div class="FinalcialReportBox">
          <div class="FinalcialReportBoxHeader">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" href="#ReportTab" data-toggle="tab">
                  {" "}
                  <span>
                    <img
                      src={require("../../../assets/images/report-red.png")}
                    />
                  </span>{" "}
                  Report{" "}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#ChartsTab" data-toggle="tab">
                  {" "}
                  <span>
                    <img
                      src={require("../../../assets/images/chart-img.png")}
                    />
                  </span>{" "}
                  Charts{" "}
                </a>
              </li>
            </ul>
          </div>
          <div class="tab-content">
            <div class="tab-pane fade active show" id="ReportTab">
              <div class="FinalcialReportBoxCenter">
                <div class="SortByArea">
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-2">
                      <img
                        src={require("../../../assets/images/sort-ascending.png")}
                      />
                      &nbsp; Sort
                    </h6>
                    <h6
                      class="mb-2 CursorPointer"
                      data-toggle="modal"
                      data-target="#CreateCustomReport"
                    >
                      <img
                        src={require("../../../assets/images/create-custom-reports.png")}
                      />
                      &nbsp; Create Custom Report
                    </h6>
                  </div>
                  <div class="SortByBox">
                    <h6 class="mb-3">Sort By</h6>
                    <select class="form-control">
                      <option selected="">Column name</option>
                    </select>
                    <div class="form-group mt-3">
                      <h6 class="mb-3">Sort in</h6>
                      <label class="Radio" checked="">
                        {" "}
                        Ascending order
                        <input type="radio" name="Ascending" checked="" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="Radio">
                        {" "}
                        Descending order
                        <input type="radio" name="Ascending" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="FrbcRight">
                  <div class="Actions">
                    <a
                      href="javscript:void(0);"
                      class="CursorPointer"
                      data-toggle="modal"
                      data-target="ShareReport"
                    >
                      <img
                        src={require("../../../assets/images/Action-2.png")}
                      />
                    </a>
                    <a href="javscript:void(0);">
                      <img src={require("../../../assets/images/pdf.png")} />
                    </a>
                    <span class="dropdown">
                      <a
                        href="javscript:void(0);"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../../../assets/images/download.png")}
                        />
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </span>
                    <a href="javscript:void(0);">
                      <img
                        src={require("../../../assets/images/setting.png")}
                      />
                    </a>
                  </div>
                  <figure>
                    <img
                      src={require("../../../assets/images/large-logo.png")}
                    />
                  </figure>
                  <h6 class="text-right">January 1 - October 7, 2023</h6>
                </div>
              </div>
              <div class="FinalcialReportBoxBottom">
                <div class="FinalcialReportBoxBottomTable">
                  <table>
                    <thead>
                      <tr>
                        <th>Column 1</th>
                        <th>Column 2</th>
                        <th>Column 3</th>
                        <th>Column 4</th>
                        <th>Column 5</th>
                        <th>Column 6</th>
                        <th>Column 7</th>
                      </tr>
                    </thead>
                  </table>
                  <h6>Report</h6>
                </div>
              </div>
              <div class="FinalcialReportBoxCenter">
                <div class="SortByArea">
                  <div class="d-flex justify-content-between">
                    <h6>
                      <img
                        src={require("../../../assets/images/sort-ascending.png")}
                      />
                      &nbsp; Sort
                    </h6>
                    <h6
                      class="ml-5 CursorPointer"
                      data-toggle="modal"
                      data-target="#CreateCustomReport"
                    >
                      <img
                        src={require("../../../assets/images/create-custom-reports.png")}
                      />
                      &nbsp; Create Custom Report
                    </h6>
                  </div>
                </div>
                <div class="FrbcRight">
                  <div class="Actions">
                    <a
                      href="javscript:void(0);"
                      data-toggle="modal"
                      data-target="#ShareReport"
                    >
                      <img
                        src={require("../../../assets/images/Action-2.png")}
                      />
                    </a>
                    <a href="javscript:void(0);">
                      <img src={require("../../../assets/images/pdf.png")} />
                    </a>
                    <span class="dropdown">
                      <a
                        href="javscript:void(0);"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src={require("../../../assets/images/download.png")}
                        />
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </span>
                    <a href="javscript:void(0);">
                      <img
                        src={require("../../../assets/images/setting.png")}
                      />
                    </a>
                  </div>
                  <figure>
                    <img
                      src={require("../../../assets/images/large-logo.png")}
                    />
                  </figure>
                  <h6 class="text-right">January 1 - October 7, 2023</h6>
                </div>
              </div>
        
              {activeTab ==
                "Budget PO's, Change Order PO's & Field Variance PO's (FPO's) Summary" && (
                <div class="PurchasingReport1 Project2 ProjectSelectBox">
                  <div
                    class="CommonGreenTable mb-5 mx-5"
                    style={{ width: "70%" }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th colspan="6">
                            Project Name &amp; Project # or Job Name &amp; Job #
                          </th>
                        </tr>
                        <tr>
                          <th colspan="6">
                            Budget PO’s, change Order PO’s &amp; Field Variance
                            PO’s (FPO’s) Summary
                          </th>
                        </tr>
                        <tr>
                          <th class="bg-white"></th>
                          <th class="bg-white text-left">Budget PO’s Totals</th>
                          <th class="bg-white">55</th>
                          <th class="bg-white">$ 275,000.00</th>
                          <th class="bg-white">100%</th>
                          <th class="bg-white">92%</th>
                        </tr>
                        <tr>
                          <th class="bg-white"></th>
                          <th class="bg-white text-left">
                            Change Order PO,s Totals
                          </th>
                          <th class="bg-white">12</th>
                          <th class="bg-white">$ 6,500.00</th>
                          <th class="bg-white">100%</th>
                          <th class="bg-white">2%</th>
                        </tr>
                        <tr>
                          <th rowspan="2">Reason No.</th>
                          <th colspan="4" class="BorderBottom">
                            FPO’s Analysis Summary
                          </th>
                          <th></th>
                        </tr>
                        <tr>
                          <th>Reason FPO’s Issued</th>
                          <th>Total No. of FPO’s Issued</th>
                          <th>Total $’s of FPO’s Issued</th>
                          <th>% of Total FPO $’s Issued</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>

                        {statsBudgePoChanageOrder?.map((item)=>
                           <tr>
                           <td>1</td>
                           <td class="text-left">Basement Development</td>
                           <td>1</td>
                           <td>800.00</td>
                           <td>4.3%</td>
                           <td></td>
                         </tr>
                        )}
                     

                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td>
                            <div class="text-left">Grand Totals</div>
                          </td>
                          <td>104</td>
                          <td>$ 300,300.00</td>
                          <td>$ 300,300.00</td>
                          <td>100%</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
              
              <div class="ConsolidatedSalesStatsMtdYtd Project10 ProjectSelectBox">
                <div class="TitleBox py-4 mx-4">
                  <h4 class="Title fw-16">Outstanding Orders</h4>
                </div>
                {activeTab ==
                  "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date" && (
                  <div class="CommonGreenTable">
                    <table>
                      <thead>
                        <tr class="BorderBottom">
                          <th colspan="22" class="pt-4">
                            Consolidated Sales Stats MTD &amp; YTD for a List
                            Projects or Entities
                          </th>
                        </tr>
                        <tr>
                          <th rowspan="2">Projects</th>
                          <th rowspan="2">Total No. of Blocks</th>
                          <th rowspan="2">Total No. of Units</th>
                          <th class="BorderBottom">Conditional</th>
                          <th class="BorderBottom">Deals</th>
                          <th class="BorderBottom">Unconditional</th>
                          <th class="BorderBottom"></th>
                          <th class="BorderBottom">Sales</th>
                          <th rowspan="2">
                            Total Spec <br /> Units
                          </th>
                          <th colspan="3" class="BorderBottom">
                            Possessions
                          </th>
                          <th rowspan="2">
                            Units Under <br /> Warranty
                          </th>
                          <th colspan="3" class="BorderBottom">
                            Total Cancelled Deals
                          </th>
                        </tr>
                        <tr>
                          <th>MTD</th>
                          <th>YTD</th>
                          <th>MTD</th>
                          <th>YTD</th>
                          <th>PTD</th>
                          <th>MTD</th>
                          <th>YTD</th>
                          <th>PTD</th>
                          <th>YTD</th>
                          <th>PTD</th>
                        </tr>
                      </thead>
                      <tbody>
                 
                        <tr>
                          <td>
                            <div class="text-left">Manor Townhouse Project</div>
                          </td>
                          <td>5</td>
                          <td>25</td>
                          <td>8</td>
                          <td>8</td>
                          <td>12</td>
                          <td></td>
                          <td>12</td>
                          <td>13</td>
                          <td>3</td>
                          <td></td>
                          <td>16</td>
                          <td>2</td>
                          <td></td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>
                            <div class="text-left">Seton Townhouse Project</div>
                          </td>
                          <td>5</td>
                          <td>25</td>
                          <td>8</td>
                          <td>8</td>
                          <td>12</td>
                          <td></td>
                          <td>12</td>
                          <td>13</td>
                          <td>3</td>
                          <td></td>
                          <td>16</td>
                          <td>2</td>
                          <td></td>
                          <td>2</td>
                        </tr>
                  
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Project Totals :</td>
                          <td>40</td>
                          <td>200</td>
                          <td>64</td>
                          <td>64</td>
                          <td>96</td>
                          <td>0</td>
                          <td>96</td>
                          <td>104</td>
                          <td>24</td>
                          <td>0</td>
                          <td>128</td>
                          <td>16</td>
                          <td>0</td>
                          <td>16</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
              <div
                class="SalesStatsByPostalCode Project11 ProjectSelectBox"
                style={{ width: "70%" }}
              >
                <div class="CommonGreenTable">
                  <table>
                    <thead>
                      <tr class="BorderBottom">
                        <th colspan="22" class="pt-4">
                          Project Name &amp; # or Job Name &amp; # <br /> Sales
                          Stats By Postal Code{" "}
                        </th>
                      </tr>
                      <tr>
                        <th>Area Name</th>
                        <th>Postal Code</th>
                        <th>Conditional Deals</th>
                        <th>Unconditional Sales</th>
                        <th>Cancelled Deals</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>T1X 0L3</td>
                        <td>T1X 0L3</td>
                        <td>4</td>
                        <td>5</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>T1X 0L3</td>
                        <td>T1X 0L3</td>
                        <td>4</td>
                        <td>5</td>
                        <td></td>
                      </tr>
                      
                      <tr>
                        <td>T1X 0L3</td>
                        <td>T1X 0L3</td>
                        <td>4</td>
                        <td>5</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>T1X 0L3</td>
                        <td>T1X 0L3</td>
                        <td>4</td>
                        <td>5</td>
                        <td></td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Totals :</td>
                        <td></td>
                        <td>49</td>
                        <td>34</td>
                        <td>8</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div
                class="SalesStatsByPostalCodeConsolidated Project12 ProjectSelectBox"
                style={{ width: "70%" }}
              >
                {" "}
                {activeTab ==
                  "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date" && (
                  <div class="CommonGreenTable">
                    <table>
                      <thead>
                        <tr class="BorderBottom">
                          <th colspan="5" class="pt-4">
                            Project Name &amp; # or Job Name &amp; # <br />{" "}
                            Sales Stats By Postal Code{" "}
                          </th>
                        </tr>
                        <tr class="BorderBottom">
                          <th colspan="5">
                            Consolidated Sales Stat Report for a List of Project
                            or Entities
                          </th>
                        </tr>
                        <tr>
                          <th>Area Name</th>
                          <th>Postal Code</th>
                          <th>Conditional Deals</th>
                          <th>Unconditional Sales</th>
                          <th>Cancelled Deals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>T1X 0L3</td>
                          <td>T1X 0L3</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Totals :</td>
                          <td></td>
                          <td>49</td>
                          <td>34</td>
                          <td>8</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                )}
              </div>
          
              {activeTab ==
                "Project Consolidated Sales Stats Month-To-Date, Year-To-Date & Project-To-Date" && (
                <div class="SalesStatsByAgeGroupPurchaseTypeConsolidated Project14 ProjectSelectBox">
                  <div class="CommonGreenTable">
                    <table>
                      <thead>
                        <tr class="BorderBottom">
                          <th colspan="8" class="py-3">
                            Project Name &amp; # or Job Name &amp; # <br />{" "}
                            Sales Stats By Age Group &amp; Purchase Type{" "}
                          </th>
                        </tr>
                        <tr class="BorderBottom">
                          <th colspan="8" class="py-3">
                            Consolidated Sales Stat Report for a List of Project
                            or Entities
                          </th>
                        </tr>
                        <tr>
                          <th>Age Group</th>
                          <th>Conditional Deals</th>
                          <th>Unconditional Sales</th>
                          <th>Cancelled Deals</th>
                          <th>Purchase Type</th>
                          <th>Conditional Deals</th>
                          <th>Unconditional Sales</th>
                          <th>Cancelled Deals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                        <tr>
                          <td>20 - 29</td>
                          <td>4</td>
                          <td>5</td>
                          <td></td>
                          <td>Single - M</td>
                          <td>8</td>
                          <td>5</td>
                          <td>1</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>Totals :</td>
                          <td>49</td>
                          <td>34</td>
                          <td>8</td>
                          <td>Totals:</td>
                          <td>49</td>
                          <td>34</td>
                          <td>8</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}
        
            
              {activeTab ==
                "Warranty Service Requests & Trade Work Orders Stats" && (
                <>
                  <div class="WarrantyServiceRequests&amp;TradeWorkOrdersAnalysis Project19 ProjectSelectBox">
                    <div class="CommonGreenTable">
                      <table>
                        <thead>
                          <tr>
                            <th colspan="22">
                              Project Name &amp; # or Job Name &amp; # <br />{" "}
                              Warranty Service Request &amp; Trade Work Order
                              Analysis{" "}
                            </th>
                          </tr>
                          <tr>
                            <th rowspan="2">Area</th>
                            <th rowspan="2">
                              Warranty <br /> Type
                            </th>
                            <th rowspan="2">Total No. of Units</th>
                            <th>Conditional</th>
                            <th>Deals</th>
                            <th>Unconditional</th>
                            <th></th>
                            <th>Sales</th>
                            <th rowspan="2">
                              Total Spec <br /> Units
                            </th>
                            <th colspan="3">Possessions</th>
                            <th rowspan="2">
                              Units Under <br /> Warranty
                            </th>
                            <th colspan="3">Total Cancelled Deals</th>
                          </tr>
                          <tr>
                            <th>MTD</th>
                            <th>YTD</th>
                            <th>MTD</th>
                            <th>YTD</th>
                            <th>PTD</th>
                            <th>MTD</th>
                            <th>YTD</th>
                            <th>PTD</th>
                            <th>YTD</th>
                            <th>PTD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Block - 1</td>
                            <td>5</td>
                            <td>25</td>
                            <td>8</td>
                            <td>8</td>
                            <td>12</td>
                            <td></td>
                            <td>12</td>
                            <td>13</td>
                            <td>3</td>
                            <td></td>
                            <td>16</td>
                            <td>2</td>
                            <td></td>
                            <td>2</td>
                          </tr>
           
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>Project Totals :</td>
                            <td>40</td>
                            <td>200</td>
                            <td>64</td>
                            <td>64</td>
                            <td>96</td>
                            <td>0</td>
                            <td>96</td>
                            <td>104</td>
                            <td>24</td>
                            <td>0</td>
                            <td>128</td>
                            <td>16</td>
                            <td>0</td>
                            <td>16</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="BigGreenTable Project20 ProjectSelectBox">
                    <table>
                      <thead>
                        <tr>
                          <th class="middleclass">
                            Project Units Cost Analysis
                          </th>
                          <th class="middleclass">Actuals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Cost of Each Unit Type</td>
                          <td>$ 4,218,159</td>
                          <td>$ 4,218,159</td>
                          <td>$ 4,218,159</td>
                          <td>$ 4,218,159</td>
                          <td>$ 4,218,159</td>
                          <td class="MiddleYellow">$ 50,100</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="OutstandingOrders Project21 ProjectSelectBox">
                    <div class="TitleBox py-4 mx-4">
                      <h4 class="Title fw-16">
                        Accepted Orders By Job and Cost Code
                      </h4>
                    </div>
                    <div class="TableList CommonHeading mx-4">
                      <table>
                        <thead>
                          <tr>
                            <th>Job Number</th>
                            <th>Account Display</th>
                            <th>Cost Category</th>
                            <th>Cost Category</th>
                            <th>Document Id</th>
                            <th>Reason Code</th>
                            <th>Company Name</th>
                            <th>Company Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>SET-3C-100-U517</td>
                            <td>1-310</td>
                            <td>Land Acquistion Costs</td>
                            <td>Plumbing</td>
                            <td>3C-1-1-310</td>
                            <td>Price Increase</td>
                            <td>Canyon Plumbing &amp; Heating Ltd.</td>
                            <td>kris.biobalance@gmail.com</td>
                          </tr>
                     
                    
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="Graph1 text-center Project22 ProjectSelectBox">
                    <figure>
                    <Chart
                    options={chartState.options}
                    series={chartState.series}
                    type="bar"
                    />
                    </figure>
                  </div>
                  <div class="Graph2 text-center Project23 ProjectSelectBox">
               <figure>
               <Chart
                    options={chartState.options}
                    series={chartState.series}
                    type="bar"
                    />
               </figure>
                  </div>
                  <div class="Graph3 text-center Project24 ProjectSelectBox">
                    <figure>
                    <Chart
                    options={chartState.options}
                    series={chartState.series}
                    type="bar"
                    />
                    </figure>
                    {/* <Chart
                    options={pieState.options}
                    series={pieState.series}
                    type="pie"
                    /> */}
                  </div>
                  <div class="CostSummaryReport Project25 ProjectSelectBox">
                    <div class="ConfigurationBox">
                      <div class="TitleBox">
                        <h3>Configuration</h3>
                      </div>
                      <p>
                        This only affects the Current job SET-3C-20-U102. Each
                        Job in the job family must be marked up individually if
                        there is more than one.
                      </p>
                      <aside>
                        <p>
                          <label>Markup (%)</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="20"
                          />
                        </p>
                        <p>
                          <label>Set Markup By Price :</label>
                          <span>
                            <button>Set Markup</button>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Calculate Markup From Price"
                            />
                          </span>
                        </p>
                        <p>
                          <label>Set Markup By Price :</label>
                          <button>$ 314,922.48 (-1,089.57 %)</button>
                        </p>
                      </aside>
                    </div>
             
                  </div>
                </>
              )}
       
      
       
             
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistical;
