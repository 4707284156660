import { iBuildAdministrationActionTypes } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
const clientViewLogin = JSON.parse(localStorage.getItem("clientViewLogin"));

// console.log("clientViewLoginclientViewLogin",clientViewLogin)

//---------------------------------taskCreate----------------------------------------------------
export const addHelpAndSupportInitiate = () => ({
    type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_INITIATE,
  });
  
  export const addHelpAndSupportSuccess = (data) => ({
    type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_SUCCESS,
    payload: data,
  });
  
  export const addHelpAndSupportFailure = (data) => ({
    type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_FAILURE,
    payload: data,
  });
  
  export const addHelpAndSupportAction = (payload) => async (dispatch) => {
    // const updatedData = multiPartData({  ...payload });
    // console.log("paylaoddddd" , payload);
    const updatedData = multiPartData({ supportingDocFiles: payload.supportingDocFiles, ...payload })
      try {
        const token = isLoggedIn("adminData1");
        dispatch(addHelpAndSupportInitiate(payload));
        const response = await axios.post(`${Url}help/createHelpAndSupport`,updatedData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(addHelpAndSupportSuccess(response.data));
        } else {
          dispatch(addHelpAndSupportFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(addHelpAndSupportFailure(err));
        return err.response
      }
    };
//---------------------------------tasklISTjOB----------------------------------------------------
export const helpListInitiate = () => ({
    type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_INITIATE,
  });
  
  export const helpListSuccess = (data) => ({
    type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_SUCCESS,
    payload: data,
  });
  
  export const helpListFailure = (data) => ({
    type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_FAILURE,
    payload: data,
  });
  
  export const helpListAction = (status) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        console.log("token", token);
        dispatch(helpListInitiate());
        const response = await axios.get(`${Url}help/getHelpList?status=${status ? status :""}`, {
          headers: { Authorization: `Bearer ${token}` },
        //   ?jobType=ResidentialJob
      
        });
        if (response.status == 200 || response.status == 201) {
          dispatch(helpListSuccess(response.data));
        } else {
          dispatch(helpListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(helpListFailure(err));
        return err.response
      }
    };

 
    export const historyListAction = (status) => async (dispatch) => {
        try {
          const token = isLoggedIn("adminData1");
          
          const response = await axios.get(`${Url}help/getHelpList`, {
            headers: { Authorization: `Bearer ${token}` },
          //   ?jobType=ResidentialJob
        
          });
          if (response.status == 200 || response.status == 201) {
         
          } else {
            
          }
          return response;
        } catch (err) {
        
          return err.response
        }
      };
  






    export const addSupportingDocsInitiate = () => ({
      type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_INITIATE,
    });
    
    export const addSupportingDocsSuccess = (data) => ({
      type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_SUCCESS,
      payload: data,
    });
    
    export const addSupportingDocsFailure = (data) => ({
      type: iBuildAdministrationActionTypes.ADD_SUPPORT_REQUEST_FAILURE,
      payload: data,
    });
    
    export const addSupportingDocsAction = (payload) => async (dispatch) => {
      const updatedData = multiPartData({  ...payload });
        try {
          const token = isLoggedIn("adminData1");
          dispatch(addSupportingDocsInitiate(payload));
          const response = await axios.post(`${Url}help/addOtherDocument`,updatedData, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status == 201) {
            dispatch(addSupportingDocsSuccess(response.data));
          } else {
            dispatch(addSupportingDocsFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(addSupportingDocsFailure(err));
          return err.response
        }
      };
  //---------------------------------tasklISTjOB----------------------------------------------------
  export const supportingDocsListInitiate = () => ({
      type: iBuildAdministrationActionTypes.SUPPORTING_DOCS_LIST_INITIATE,
    });
    
    export const supportingDocsListSuccess = (data) => ({
      type: iBuildAdministrationActionTypes.SUPPORTING_DOCS_LIST_SUCCESS,
      payload: data,
    });
    
    export const supportingDocsListFailure = (data) => ({
      type: iBuildAdministrationActionTypes.SUPPORTING_DOCS_LIST_FAILURE,
      payload: data,
    });
    
    export const supportingDocsListAction = (status) => async (dispatch) => {
        try {
          const token = isLoggedIn("adminData1");
          dispatch(supportingDocsListInitiate());
          const response = await axios.get(`${Url}help/getDocumentList`, {
            headers: { Authorization: `Bearer ${token}` },
         
          });
          if (response.status == 200 || response.status == 201) {
            dispatch(supportingDocsListSuccess(response.data));
          } else {
            dispatch(supportingDocsListFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(supportingDocsListFailure(err));
          return err.response
        }
      };
  


      
    export const removeSupportingDocsInitiate = () => ({
      type: iBuildAdministrationActionTypes.REMOVE_SUPPORTING_DOCS_INITIATE,
    });
    
    export const removeSupportingDocsSuccess = (data) => ({
      type: iBuildAdministrationActionTypes.REMOVE_SUPPORTING_DOCS_SUCCESS,
      payload: data,
    });
    
    export const removeSupportingDocsFailure = (data) => ({
      type: iBuildAdministrationActionTypes.REMOVE_SUPPORTING_DOCS_FAILURE,
      payload: data,
    });
    
    export const removeSupportingDocsAction = (payload) => async (dispatch) => {
        try {
          const token = isLoggedIn("adminData1");
          dispatch(removeSupportingDocsInitiate(payload));
          const response = await axios.get(`${Url}help/deleteSupprtDocument?id=${payload}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status == 201) {
            dispatch(removeSupportingDocsSuccess(response.data));
          } else {
            dispatch(removeSupportingDocsFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(removeSupportingDocsFailure(err));
          return err.response
        }
      };

      


      export const helpDetailsInitiate = () => ({
        type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_INITIATE,
      });
      
      export const helpDetailsSuccess = (data) => ({
        type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_SUCCESS,
        payload: data,
      });
      
      export const helpDetailsFailure = (data) => ({
        type: iBuildAdministrationActionTypes.GET_HELP_REQUEST_DETAILS_FAILURE,
        payload: data,
      });
      
      export const helpDetailsAction = (status) => async (dispatch) => {
          try {
            const token = isLoggedIn("adminData1");
            dispatch(helpDetailsInitiate());
            const response = await axios.get(`${Url}help/getHelpDetail?id=${status ? status :""}`, {
              headers: { Authorization: `Bearer ${token}` },
            //   ?jobType=ResidentialJob
          
            });
            if (response.status == 200 || response.status == 201) {
              dispatch(helpDetailsSuccess(response.data));
            } else {
              dispatch(helpDetailsFailure(response.data));
            }
            return response;
          } catch (err) {
            dispatch(helpDetailsFailure(err));
            return err.response
          }
        };
    
    

        
   
    
    export const updateHelpSupportAction = (payload) => async (dispatch) => {
      // const updatedData = multiPartData({  ...payload });
        try {
          const token = isLoggedIn("adminData1");
          dispatch(addSupportingDocsInitiate(payload));
          const response = await axios.post(`${Url}help/editHelp`,payload, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.status == 201) {
            dispatch(addSupportingDocsSuccess(response.data));
          } else {
            dispatch(addSupportingDocsFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(addSupportingDocsFailure(err));
          return err.response
        }
      };


      export const helpTabListInitiate = () => ({
        type: iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_INITIATE,
      });
      
      export const helpTabListSuccess = (data) => ({
        type: iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_SUCCESS,
        payload: data,
      });
      
      export const helpTabListFailure = (data) => ({
        type: iBuildAdministrationActionTypes.GET_HELP_TAB_REQUEST_FAILURE,
        payload: data,
      });
      

      
  export const helpTabListAction = (status) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(helpTabListInitiate());
      const response = await axios.get(`${Url}help/getHelpMessageList`, {
        headers: { Authorization: `Bearer ${token}` },
      //   ?jobType=ResidentialJob
      // ?status=${status ? status :""}
    
      });
      if (response.status == 200 || response.status == 201) {
        dispatch(helpTabListSuccess(response.data));
      } else {
        dispatch(helpTabListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(helpTabListFailure(err));
      return err.response
    }
  };



  
  export const addHelpTabAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const response = await axios.post(`${Url}help/createHelpMessage`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
        } else {
        }
        return response;
      } catch (err) {
      
        return err.response
      }
    };



    export const changeHelpTabAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const response = await axios.post(`${Url}help/disableHelpMessage`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
        } else {
        }
        return response;
      } catch (err) {
      
        return err.response
      }
    };


    export const removeHelpTabAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const response = await axios.get(`${Url}help/deleteHelpMessage?id=${payload}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
        } else {
        }
        return response;
      } catch (err) {
      
        return err.response
      }
    };



    
    export const helpTabDetailsAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const response = await axios.get(`${Url}help/deleteHelpMessage?id=${payload}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
        } else {
        }
        return response;
      } catch (err) {
      
        return err.response
      }
    };



     
    export const updateHelpTabAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        const response = await axios.post(`${Url}help/editHelpMessage`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
        } else {
        }
        return response;
      } catch (err) {      
        return err.response
      }
    };




    
    export const getHeaderHelpListInitiate = () => ({
      type: iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_INITIATE,
    });
    
    export const getHeaderHelpListSuccess = (data) => ({
      type: iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_SUCCESS,
      payload: data,
    });
    
    export const getHeaderHelpListFailure = (data) => ({
      type: iBuildAdministrationActionTypes.GET_HEADER_HELP_REQUEST_FAILURE,
      payload: data,
    });
    

    
export const getHeaderHelpListAction = (status) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    dispatch(getHeaderHelpListInitiate());
    const response = await axios.get(`${Url}help/helpDataIcon?pageType=${status ? status :""}`, {
      headers: { Authorization: `Bearer ${token}` },
    //   ?jobType=ResidentialJob
    // ?status=${status ? status :""}
  
    });
    if (response.status == 200 || response.status == 201) {
      dispatch(getHeaderHelpListSuccess(response.data));
    } else {
      dispatch(getHeaderHelpListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getHeaderHelpListFailure(err));
    return err.response
  }
};



export function addHelpSupportingDocsAction(payload, id) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}help/addOtherDocument`,
          updatedData,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export const getCustomerDemoRequestListInitiate = () => ({
  type: iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_INITIATE,
});

export const getCustomerDemoRequestListSuccess = (data) => ({
  type: iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_SUCCESS,
  payload: data,
});

export const getCustomerDemoRequestListFailure = (data) => ({
  type: iBuildAdministrationActionTypes.GET_CUSTOMER_DEMO_REQUEST_FAILURE,
  payload: data,
});


export const getCustomerDemoRequestListAction = (status) => async (dispatch) => {
try {
const token = isLoggedIn("adminData1");
dispatch(getCustomerDemoRequestListInitiate());
const response = await axios.get(`${Url}customer-contact`, {
  headers: { Authorization: `Bearer ${token}` },
});
if (response.status == 200 || response.status == 201) {
  dispatch(getCustomerDemoRequestListSuccess(response.data));
} else {
  dispatch(getCustomerDemoRequestListFailure(response.data));
}
return response;
} catch (err) {
dispatch(getCustomerDemoRequestListFailure(err));
return err.response
}
};




export const removeCustomerDemoAction = (id) => async (dispatch) => {
  try {
  const token = isLoggedIn("adminData1");
  dispatch(getCustomerDemoRequestListInitiate());
  const response = await axios.delete(`${Url}customer-contact/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  //   ?jobType=ResidentialJob
  // ?status=${status ? status :""}
  
  });
  if (response.status == 200 || response.status == 201) {
    dispatch(getCustomerDemoRequestListSuccess(response.data));
  } else {
    dispatch(getCustomerDemoRequestListFailure(response.data));
  }
  return response;
  } catch (err) {
  dispatch(getCustomerDemoRequestListFailure(err));
  return err.response
  }
  };
  

  
export const schdeuleCustomerDemoAction = (id) => async (dispatch) => {
  try {
  const token = isLoggedIn("adminData1");
  dispatch(getCustomerDemoRequestListInitiate());
  const response = await axios.patch(`${Url}customer-contact/demo`, id, {
    headers: { Authorization: `Bearer ${token}` },
  //   ?jobType=ResidentialJob
  // ?status=${status ? status :""}
  
  });
  if (response.status == 200 || response.status == 201) {
    dispatch(getCustomerDemoRequestListSuccess(response.data));
  } else {
    dispatch(getCustomerDemoRequestListFailure(response.data));
  }
  return response;
  } catch (err) {
  dispatch(getCustomerDemoRequestListFailure(err));
  return err.response
  }
  };
  


  
export const customerListInitiate = () => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_LIST_INITIATE,
});

export const customerListSuccess = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_LIST_SUCCESS,
  payload: data,
});

export const customerListFailure = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_LIST_FAILURE,
  payload: data,
});


export const customerListAction = (status) => async (dispatch) => {
try {
const token = isLoggedIn("adminData1");
dispatch(customerListInitiate());
const response = await axios.get(`${Url}subscribed_companies`, {
  headers: { Authorization: `Bearer ${token}` },
});
if (response.status == 200 || response.status == 201) {
  dispatch(customerListSuccess(response.data));
} else {
  dispatch(customerListFailure(response.data));
}
return response;
} catch (err) {
dispatch(customerListFailure(err));
return err.response
}
};




export function addCustomerCode(payload, id) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}create_contract_code`,
          payload,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}




export function blockCustomerAction(payload, id) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}block_company`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export function deleteCustomerAction( id) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}delete_company?companyId=${id}`,        
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}







export function updateCustomerCodeAction(payload, id) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .put(`${Url}update_contract_code`, payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export function addCustomerRequestAction(payload, id) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${Url}create_contract_request`,
          payload,
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}




export const customerRequestListInitiate = () => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_INITIATE,
});

export const customerRequestListSuccess = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_SUCCESS,
  payload: data,
});

export const customerRequestListFailure = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_REQUEST_LIST_FAILURE,
  payload: data,
});


export const customerRequestListAction = (status) => async (dispatch) => {
try {
const token = isLoggedIn("adminData1");
dispatch(customerRequestListInitiate());
const response = await axios.get(`${Url}get_all_contract_request`, {
  headers: { Authorization: `Bearer ${token}` },
});
if (response.status == 200 || response.status == 201) {
  dispatch(customerRequestListSuccess(response.data));
} else {
  dispatch(customerRequestListFailure(response.data));
}
return response;
} catch (err) {
dispatch(customerRequestListFailure(err));
return err.response
}
};




export function remvoeCustomerRequestAction(payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}delete_contract_request?companyId=${payload}`,
          
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}




export function sendForSignatureAction(contractId, companyId) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ files: payload.files, ...payload })
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}send_contract_email_to_client?contractRequestId=${contractId}&companyId=${companyId}`,
          
          {
            headers: { Authorization: ` Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 200) {           
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}













export const customerCodeListInitiate = () => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_INITIATE,
});

export const customerCodeListSuccess = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_SUCCESS,
  payload: data,
});

export const customerCodeListFailure = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_CODE_LIST_FAILURE,
  payload: data,
});


export const customerCodeListAction = (id=4) => async (dispatch) => {
try {
const token = isLoggedIn("adminData1");
dispatch(customerCodeListInitiate());
const response = await axios.get(`${Url}get_contract_code?companyId=${id}`, {
  headers: { Authorization: `Bearer ${token}` },
});
if (response.status == 200 || response.status == 201) {
  dispatch(customerCodeListSuccess(response.data));
} else {
  dispatch(customerCodeListFailure(response.data));
}
return response;
} catch (err) {
dispatch(customerCodeListFailure(err));
return err.response
}
};





export const customerHistoryListInitiate = () => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_INITIATE,
});

export const customerHistoryListSuccess = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_SUCCESS,
  payload: data,
});

export const customerHistoryListFailure = (data) => ({
  type: iBuildAdministrationActionTypes.CUSTOMER_HISTORY_LIST_FAILURE,
  payload: data,
});


export const subscriptionHistoryListAction = (id) => async (dispatch) => {
try {
const token = isLoggedIn("adminData1");
dispatch(customerHistoryListInitiate());
const response = await axios.get(`${Url}get_aggrement_history?contractRequestId=${id}`, {
  headers: { Authorization: `Bearer ${token}` },
});
if (response.status == 200 || response.status == 201) {
  dispatch(customerHistoryListSuccess(response.data));
} else {
  dispatch(customerHistoryListFailure(response.data));
}
return response;
} catch (err) {
dispatch(customerHistoryListFailure(err));
return err.response
}
};
