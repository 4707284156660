import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  getDesignByCategoryInterior,
  getJobByProjectList,
  getProjectByBuilder,
} from "../redux/action/tradeViewAuthAction";

import TradeViewSidenav from "./trade-view-sidenav";
import { Link } from "react-router-dom";
import TradeHeader from "./TradeHeader";

const initialState = {
  companyId: "",
  projectId: "",
  jobId: "",
};

const DesignSelectionTradeView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const { companyId, projectId, jobId } = iState;
  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  const { projectByBuilderData, jobByProjectData, desginCategoryInterior } =
    useSelector((state) => state.tradeViewLogin);
  console.log("desginCategoryInterior", desginCategoryInterior);

  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      const selectedJob = jobByProjectData?.data?.find(
        (data) => data.id == value
      );
      if (selectedJob) {
        const { id, jobType } = selectedJob;
        dispatch(
          getDesignByCategoryInterior(builderIdd, projectId, jobType, id)
        );
        updateState({
          ...iState,
          [name]: value,
        });
      }
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };
  return (
    <div>
      {/* <Header /> */}
      <TradeHeader />
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          {/* 
   <div class="d-flex py-5">
     <h6 class="CursorPointer" data-toggle="modal" data-target="#DeisgnDocuments" data-dismiss="modal" >Deisgn Documents</h6>
   </div> */}
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Design Selections</h2>
            </div>
          </div>
          <div className="TitleBox">
            <div className="BuilderBox">
              <h4>Project</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="projectId"
                  value={projectId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Project</option>
                  {projectByBuilderData?.data?.length > 0 &&
                    projectByBuilderData?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.projectName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="BuilderBox">
              <h4>Job</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="jobId"
                  value={jobId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Jobs</option>
                  {jobByProjectData?.data?.length > 0 &&
                    jobByProjectData?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.jobType}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active show fade" id="BuilderTab">
              <div className="Tableheader">
                <h6>Selections</h6>
                <div className="NewActions">
                  <a title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                  <a title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList TableListHeader NewTableList TableHeightScroll TableListDecoration">
                <table>
                  <thead>
                    <tr>
                      <th>Job Id</th>
                      <th>Project Name</th>
                      <th>Selection Type</th>
                      <th>Category</th>
                      <th>Sent By</th>
                      {/* <th>Address</th> */}
                      <th>Creation Date</th>
                      <th>Status</th>
                      <th>Selection Deadline</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {desginCategoryInterior?.data?.length > 0 ? (
                      desginCategoryInterior?.data?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.jobId}</td>
                            <td>{item.projectName ?? "N/A"}</td>
                            <td>{item.selectionType}</td>
                            <td>
                              {item.DesignCategoriesJunctions?.map((x, i) => {
                                const categoryName =
                                  x.DesignCostCodeCategory?.categoryName;
                                if (categoryName && categoryName !== "N/A") {
                                  return (
                                    <div key={i}>{`${
                                      i + 1
                                    }.${categoryName}`}</div>
                                  );
                                }
                                return null;
                              })}
                            </td>

                            <td>Builder</td>
                            {/* <td>
                              <div className="Action">
                                N/A
                                <img src="images/location.png" />
                              </div>
                            </td> */}
                            <td>
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.selectionStatus}</td>
                            <td>
                              {moment(item.updatedAt).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              <Link
                                to={`/design-selections-trade-view-details`}
                                state={{ item }}
                              >
                                <img src="images/view-po.png" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 4 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> <span>01</span>{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignSelectionTradeView;
