import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { saveAs } from 'file-saver';
import {
  AddressListAction,
  BulkUploadTemplateAction,
  ContactListAction,
  PrefferedCostListAction,
  VendorListAction,
  VendorSearchAction,
  VendorTypesAction,
  addAddressAction,
  addBulkVendorAction,
  addContactAction,
  addPrefferedCostCodeAction,
  addVendorAction,
  contactDetailsAction,
  prefferedCostDetailsAction,
  removeContactAction,
  removePrefferedCostAction,
  removeVendor,
  setPrefferedCostCodeAction,
  updateContactAction,
  updatePrefferedCostAction,
  updateVendorAction,
  vendorDetailsAction,
} from "../redux/action/vendorAction";
import { checkArray } from "../utils/CheckType";
import { errorToast, sucessToast } from "../utils/toast";
import NoDataFound from "./Nodatafound";
import Loader from "./Loader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as XLSX from 'xlsx';
import {
  getAllCostCodeAction,
  getAllCostcategroyAction,
  getCitiesList,
  getCountriesList,
  getCountryListFailure,
  getStateList,
} from "../redux/action/authAction";
import { useRef } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
// import { getListProjectJobs } from "../redux/action/warrantyAction";
import {
  getListProjectJobs,
  getWarrantyJobs,
  showInWarranty,
} from "../redux/action/warrantyAction";
const init = {
  AddVendorModal2: false,
  AddVendorModal1: false,
  EditVendorModal1: false,
  SystemVendorModal: false,
  setVendorPrefferedCostListModal: false,
  AssignMemberModal:false,
  vendorName: "",
  phone: "",
  email: "",
  city: "",
  country: "",
  province: "",
  vendorType: "",
  addressLine1: "",
  addressLine2: "",
  editId: "",
  vendorSearchData: [],
  errors: {},
  vendorAddress: [],
  firstName: "",
  lastName: "",
  title: "",
  contactvendorType: "",
  contactemail: "",
  officePhone: "",
  cellPhone: "",
  address: "",
  contactError: {},
  addContactModal: false,
  ContactListModal: false,
  systemErrors: {},
  prefferedCostModal: false,
  costCode: "",
  costType: "",
  prefferedCostError: {},
  setVendorPrefferedCostModal: false,
  editContactModal: false,
  contactDetailsData: [],
  secondaryId: "",
  preferredVendorName: "",
  prefferedEditCostModal: false,
  prefeeredVendorDetailsData: [],
  file:"",
  BulkError:{},
  xmlResponse:[],
  setPrefferedError:{},
  memberDetails:[],
  fileNameStatic:"",
  costCategory:"",
  costCode:"",
  zipCode:"",
    // costCodes: [
    //     {
    //         vendorName: "",
    //         costCategory:"",
    //         costCode: ""
    //     },
    // ]
};
const addressInit = {
  extraaddressName: "",
  extraaddressLine1: "",
  extraaddressLine2: "",
  extracity: "",
  extrazipCode: "",
  extracountry: "",
  extraprovince: "",
  extraaddressError: {},
  addAddressModal: false,
  ProjectId:""
};
const IndivisualComponentsPartVendorList = () => {
  const [iState, updateState] = useState(init);
  const[phonecode, setPhoneCode] = useState("")
  const[cellPhoneCode, setCellPhoneCode] = useState("")
  const vendorListRef = useRef(null);
  const contactListRef = useRef(null);
  const prefeeredVendorRef = useRef(null);
  const [costCodes, updateSetCostCodes] = useState([]);
  const [insertingCostCodes, updateInsertingSetCostCodes] = useState({
    vendorName: "",
    costCategory:"",
    costCode: ""
  })
  const [addressState, updateAddressState] = useState(addressInit);
  const {
    vendorTypesData,
    loader,
    vendorListData,
    vendorDetailsData,
    contactListData,
    addressListData,
    prefferedListData,
  } = useSelector((state) => state.constructionVendor);
  const { countryData, cityData, stateData, getAllCostCodeListData,getAllCostCategoryListData} = useSelector(
    (state) => state.authReducer
  );
 
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const {
    AddVendorModal2,
    AddVendorModal1,
    EditVendorModal1,
    editId,
    vendorName,
    phone,
    zipCode,
    // costCodes,
    email,
    city,
    country,
    province,
    vendorType,
    addressLine1,
    addressLine2,
    errors,
    SystemVendorModal,
    vendorSearchData,
    vendorAddress,
    firstName,
    lastName,
    title,
    contactvendorType,
    contactemail,
    officePhone,
    cellPhone,
    address,
    addContactModal,
    contactError,
    ContactListModal,
    systemErrors,
    prefferedCostModal,
    setVendorPrefferedCostModal,
    costCode,
    costCategory,
    costType,
    prefferedCostError,
    editContactModal,
    contactDetailsData,
    secondaryId,
    preferredVendorName,
    setVendorPrefferedCostListModal,
    prefferedEditCostModal,
    prefeeredVendorDetailsData,
    BulkError,
    xmlResponse,
    file,
    setPrefferedError,
    memberDetails,
    AssignMemberModal,
    ProjectId,
    fileNameStatic,
    
  } = iState;
  const handlePhoneInputChange = (value,country) => {  
    setPhoneCode(`+${country.dialCode}`)
    
  };
  const handleCellPhoneInputChange = (value,country) => {
    setCellPhoneCode(`+${country.dialCode}`)
    
  };
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
 
  const {
    extraaddressName,
    extraaddressLine1,
    extraaddressLine2,
    extracity,
    extrazipCode,
    extracountry,
    extraprovince,
    extraaddressError,
    addAddressModal,
  } = addressState;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(VendorTypesAction());
    dispatch(VendorListAction());
    dispatch(getCountriesList());
    dispatch(getAllCostcategroyAction())
   
  }, []);

  useEffect(() =>{
    if(costCategory)
    dispatch(getAllCostCodeAction(costCategory))
  },[costCategory] )
 
  useEffect(() => {
    if(country)
    dispatch(
      getStateList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode
      )
    );
  }, [country]);
  useEffect(() => {
    dispatch(
      getCitiesList(
        countryData?.countries?.find((x) => x.name == country)?.isoCode,
        stateData?.states?.find((x) => x.name == province)?.isoCode
      )
    );
  }, [province]);
   
  useEffect(() => {
    setData1(vendorTypesData?.vendorType);
    setData2(vendorTypesData?.vendorType);
   
  }, [SystemVendorModal,vendorTypesData, AddVendorModal1, prefferedCostModal,setVendorPrefferedCostModal]);
    useEffect(() =>{
      dispatch(VendorSearchAction("",vendorType)).then((res) => {
        updateState({ ...iState, vendorSearchData: res.vendors });
      });
    },[vendorType])
  useEffect(() => {
    dispatch(vendorDetailsAction(editId));
  }, [editId]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    try {
      if (value == "") {
        setData1(data2);
      } else {
        let temp = data1.filter((item) => {
          return item.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        setData1(temp);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    updateState({
      ...iState,
      vendorName: vendorDetailsData?.vendorName,
      phone: vendorDetailsData?.phone,
      email: vendorDetailsData?.email,
      city: vendorDetailsData?.city,
      country: vendorDetailsData?.country,
      province: vendorDetailsData?.province,
      vendorType: vendorDetailsData?.vendorType,
      addressLine1: vendorDetailsData?.addressLine1,
      addressLine2: vendorDetailsData?.addressLine2,
    });
  }, [vendorDetailsData]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    // console.log("====>", e)
    updateState({ ...iState, [name]: value });
    updateAddressState({ ...addressState, [name]: value });
  };
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateState({ ...iState, [name]: result });
    updateAddressState({ ...addressState, [name]: result });
  };

  const handleInputSearchCity = (e) => {
    const { name, value } = e.target;
    dispatch(VendorSearchAction(value,vendorType)).then((res) => {
      updateState({ ...iState, vendorSearchData: res.vendors });
    });
  };

  const handleAddVendorModal2Close = () => {
    updateState(init);
  };

  const handleAddVendorModal2Show = (data) => {
    updateState({ ...iState, AddVendorModal2: true , AddVendorModal1:false});
  };

  // ADDVENDORFUNC

  const handleAddVendorModal1Close = () => {
    updateState(init);
  };

  const handleAddVendorModal1Show = (data) => {
    updateState({ ...iState, AddVendorModal1: true , AddVendorModal2:false});
  };

  const handleValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!vendorName) {
      error.vendorNameError = "*  Vendor name can't be empty";
      formIsValid = false;
    }
    if (!phone) {
      error.phoneError = "*  Mobile number can't be empty";
      formIsValid = false;
    }
    if (phone) {
      if (!/^[1-9][0-9]{9,12}$/.test(phone)) {
        error.phoneError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }

    if (!email) {
      error.emailError = "* Email address can't be empty";
      formIsValid = false;
    }

    if (email) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        error.emailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    if (!addressLine1) {
      error.addressLine1Error = "* Street address can't be empty";
      formIsValid = false;
    }
    

    if (!zipCode) {
      error.zipCodeError = "* Zip code can't be empty";
      formIsValid = false;
    }
   

    if (!city?.trim("")) {
      error.cityError = "! City can't be empty";
      formIsValid = false;
    }

    if (!country) {
      error.countryError = "* Country can't be empty";
      formIsValid = false;
    }

    if (!province) {
      error.provinceError = "* Province can't be empty";
      formIsValid = false;
    }
    if (!vendorType) {
      error.vendorTypeError = "* Vendor Type can't be empty";
      formIsValid = false;
    }

    updateState({ ...iState, errors: error });
    return formIsValid;
  };

  const handleBulkValidation = () => {
    let error = {};
    let formIsValid = true;
    if(!file){
      error.fileNameError = "* File name can't be empty";
      formIsValid = false
    }
     updateState({...iState, BulkError:error})
    return formIsValid
  }
  

    
  const handleInputFile = (e) => {
    const file = e.target.files[0];
    console.log("------------->", file)
    if (file) {
      updateState({
        ...iState,
        file: file,
        fileNameStatic:file.name
      });
      // console.log("bulkFile", bulkFile);
    }
    e.target.files = null;
  
  } 

  const handleBulkVendor = (e) => {
    e.preventDefault()
    let formIsValid = handleBulkValidation();
    
    if(formIsValid){
      dispatch(addBulkVendorAction({file})).then((res) => {
        console.log("+====ree", res)
        sucessToast("Bulk vendor created successfully")
        handleAddVendorModal2Close()
      }).catch((err) => {
        console.log("errr----->", err)
      })
    }
  }

  const handleTemplateBulk = (e) => {
    e.preventDefault()
    dispatch(BulkUploadTemplateAction()).then((res) => {
      console.log("rs=======>", res.data)
      updateState({...iState, xmlResponse:res.data})
      dispatch(VendorSearchAction())
      handleConvertToExcel(res.data.headersData)
    }).catch((err) => {
      console.log("err---->", err)
    })
  }


 
    const handleConvertToExcel = (data, fileName = 'vendor') => {
      if (!Array.isArray(data)) {
        console.error('Data is not an array:', data);
        return;
      }
  
      if (data.length === 0) {
        console.warn('Data array is empty.');
        return;
      }
  
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `${fileName}.xlsx`);
    };
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    };
    
  const addVendor = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      let data = {
        vendorName,
        phone,     
        email,phonecode,
        city,
        country,
        province,
        vendorType,
        addressLine1,
        addressLine2,
        postalCode: zipCode,
        vendorAddress,
      };
      dispatch(addVendorAction(data))
        .then((res) => {
          sucessToast("Vendor created successfully");
          dispatch(VendorListAction());
          handleAddVendorModal1Close();
        })
        .catch((err) => {});
    }
  };

  // EDITVENDORFUNC

  const handleEditVendorModal1Close = () => {
    updateState({ ...iState, EditVendorModal1: false });
  };

  const handleEditVendorModal1Show = (data) => {
    updateState({ ...iState, EditVendorModal1: true, editId: data });
  };

  const EditVendorFun = (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      let data = {
        vendorName,
        phone,
        email,
        phonecode,
        city,
        country,
        province,
        addressLine1,
        addressLine2,
        province,
        vendorType,
        vendorAddress,
        postalCode: zipCode,
      };
      dispatch(updateVendorAction(data, editId))
        .then((res) => {
          sucessToast("Vendor updated successfully");
          handleEditVendorModal1Close();
          dispatch(VendorListAction());
        })
        .catch((err) => {
          console.log("err=========", err);
        });
    }
  };
  // DELETEVENDORFUNC
  const deleteVendorFun = (id) => {
    dispatch(removeVendor(id))
      .then((res) => {
        sucessToast("Vendor removed successfully");
        dispatch(VendorListAction());
      })
      .catch((err) => {
        errorToast("error");
      });
  };

 
  // ADDRESSFUNC

  const handleAddAddressModalClose = () => {
    //updateAddressState(init);
    updateAddressState({ ...addressState, addAddressModal: false });
    updateState({ ...iState, AddVendorModal1: true });
  };



  

  const handleAddAddressModalShow = () => {
    updateAddressState({ ...addressState, addAddressModal: true });
    updateState({ ...iState, AddVendorModal1: false });
  };

  const handleAddressValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!extraaddressName) {
      error.addressNameError = "*  Address name can't be empty";
      formIsValid = false;
    }

    if (!extraaddressLine1) {
      error.addressLine1Error = "* Street address can't be empty";
      formIsValid = false;
    }
   

    if (!extrazipCode) {
      error.zipCodeError = "* Zip code can't be empty";
      formIsValid = false;
    }
   

    if (!extracity?.trim("")) {
      error.cityError = "! City can't be empty";
      formIsValid = false;
    }

    if (!extracountry) {
      error.countryError = "* Country can't be empty";
      formIsValid = false;
    }

    if (!extraprovince) {
      error.provinceError = "* Province can't be empty";
      formIsValid = false;
    }

    updateAddressState({ ...addressState, extraaddressError: error });
    return formIsValid;
  };

  const addAddressFun = (e) => {
    e.preventDefault();
    let formIsValid = handleAddressValidation();
    if (formIsValid) {
      const data = [
        {
          addressName: extraaddressName,
          addressLine1: extraaddressLine1,
          addressLine2: extraaddressLine2,
          city: extracity,
          zipCode: extrazipCode,
          country: extracountry,
          province: extraprovince,
        },
      ];
      handleAddAddressModalClose();
      updateState({ ...iState, vendorAddress: data, AddVendorModal1: true });
      //  dispatch(addAddressAction(data)).then((res) => {
      //  }).catch((err) => {
      //  })
    }
  };

  // SYSTEMFUNC

  const handleSystemVendorModalClose = () => {
    updateState({ ...iState, SystemVendorModal: false, AddVendorModal1: true });
  };

  const handleSystemVendorModalShow = (data) => {
    updateState({
      ...iState,
      SystemVendorModal: true,
      AddVendorModal1: false,
      vendorName: "",
      vendorType: "",
    });
  };

  const handleValidationSystem = () => {
    let error = {};
    let formIsValid = true;

    if (!vendorName) {
      error.vendorNameError = "*  Vendor name can't be empty";
      formIsValid = false;
    }

    if (!vendorType) {
      error.vendorTypeError = "* Vendor Type can't be empty";
      formIsValid = false;
    }

    updateState({ ...iState, systemErrors: error });
    return formIsValid;
  };

  const handleSystemVendor = (e) => {
    e.preventDefault();
    let formIsValid = handleValidationSystem();
    // updateState({...iState, name:})
    if (formIsValid) {
      handleSystemVendorModalClose();
      handleAddVendorModal1Show();
    }
  };

  // CONTACTFUNC VENDOR
  const handleContactListModalClose = () => {
    updateState(init);
  };

  const handleContactListModalShow = (id) => {
    dispatch(ContactListAction(id));
    dispatch(AddressListAction(id));
    updateState({ ...iState, ContactListModal: true, editId: id });
  };

  const handleAddContactVendorModalClose = () => {
    updateState({
      ...iState,
      addContactModal: false,
      firstName: "",
      lastName: "",
      title: "",
      contactvendorType: "",
      contactemail: "",
      officePhone: "",
      cellPhone: "",
      address: "",
      contactError: {},
      // ContactListModal: true
    });
  };

  const handleAddContactVendorModalShow = (id) => {
    dispatch(AddressListAction(id));
    updateState({ ...iState, addContactModal: true, editId: id });
  };

  const handleAddContactListVendorModalShow = (id) => {
    updateState({ ...iState, addContactModal: true });
  };

  const handleEditContactVendorModalClose = () => {
    updateState({
      ...iState,
      editContactModal: false,
      firstName: "",
      lastName: "",
      title: "",
      contactvendorType: "",
      contactemail: "",
      officePhone: "",
      cellPhone: "",
      address: "",
      contactError: {},
      ContactListModal: true,
    });
  };

  const handleEditContactVendorModalShow = (id) => {
    dispatch(contactDetailsAction(editId, id)).then((res) => {
      updateState({
        ...iState,
        contactDetailsData: res?.vendorContact,
        editContactModal: true,
        contactError: {},
        ContactListModal: false,
        secondaryId: id,
      });
    });
    // updateState({
    //   ...iState,

    // });
  };

  useEffect(() => {
    updateState({
      ...iState,
      firstName: contactDetailsData?.firstName,
      lastName: contactDetailsData?.lastName,
      title: contactDetailsData?.title,
      contactvendorType: contactDetailsData?.vendorType,
      contactemail: contactDetailsData?.email,
      officePhone: contactDetailsData?.officePhone,
      cellPhone: contactDetailsData?.cellPhone,
      address: contactDetailsData?.address,
    });
  }, [contactDetailsData]);

  const handleContactValidation = (e) => {
    let error = {};
    let formIsValid = true;

    if (!firstName) {
      error.firstNameError = "*  First name can't be empty";
      formIsValid = false;
    }

    if (!lastName) {
      error.lastNameError = "* Last name can't be empty";
      formIsValid = false;
    }
    if (!contactemail) {
      error.contactemailError = "*Email can't be empty";
      formIsValid = false;
    }

    if (contactemail) {
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          contactemail
        )
      ) {
        error.contactemailError = "Please enter a valid email";
        formIsValid = false;
      }
    }

    if (!contactvendorType) {
      error.contactvendorTypeError = "* Contact type can't be empty";
      formIsValid = false;
    }

    if (!title) {
      error.titleError = "! Title can't be empty";
      formIsValid = false;
    }

    if (!officePhone) {
      error.officePhoneError = "* Mobile number can't be empty";
      formIsValid = false;
    }
    if (officePhone) {
      if (!/^[1-9][0-9]{9,12}$/.test(officePhone)) {
        error.officePhoneError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }

    if (!cellPhone) {
      error.cellPhoneError = "*  Mobile number can't be empty";
      formIsValid = false;
    }
    if (cellPhone) {
      if (!/^[1-9][0-9]{9,12}$/.test(cellPhone)) {
        error.cellPhoneError = "Please enter a valid phone number";
        formIsValid = false;
      }
    }

    if (!address) {
      error.addressError = "* Address can't be empty";
      formIsValid = false;
    }

    updateState({ ...iState, contactError: error });
    return formIsValid;
  };

  const handleCreateContactFun = (e, id) => {
    e.preventDefault();
    let formIsValid = handleContactValidation();
    if (formIsValid) {
      let data = {
        firstName,
        lastName,
        title,
        vendorType: contactvendorType,
        email: contactemail,
        officePhone,
        cellPhoneCode,
        cellPhone,
        address,phonecode
      };
      dispatch(addContactAction(data, editId))
        .then((res) => {
          handleAddContactVendorModalClose();
          dispatch(ContactListAction(editId));
          sucessToast( "Contact added successfully"  )
        })
        .catch((err) => {
          console.log("err------>", err);
        });
    }
  };

  const handleUpdateContactFun = (e) => {
    e.preventDefault();
    let formIsValid = handleContactValidation();
    if (formIsValid) {
      let data = {
        firstName,
        lastName,
        title,
        phonecode,
        vendorType: contactvendorType,
        email: contactemail,
        officePhone,
        cellPhone,
        address,
      };
      dispatch(updateContactAction(data, editId, secondaryId))
        .then((res) => {
          dispatch(ContactListAction(editId));
          sucessToast("Contact updated successfully")
          handleEditContactVendorModalClose()
        })
        .catch((err) => {
          console.log("errr====>", err);
        });
    }
  };

  const deleteContactFun = (id) => {
    dispatch(removeContactAction(editId, id))
      .then((res) => {
        sucessToast("Vendor removed successfully");
        dispatch(ContactListAction(editId));
      })
      .catch((err) => {
        errorToast("error");
      });
  };

  //  PREFFEREDFUNC
  const handlePrefferedCostClose = () => {
    updateState({
      ...iState,
      setVendorPrefferedCostListModal: true,
      prefferedCostModal: false,
      errors: {},
      costCode: "",
      costType: "",
      preferredVendorName: "",
    });
  };
  const handlePrefferedCostShow = (id) => {
    updateState({
      ...iState,
      // setVendorPrefferedCostListModal: false,
      prefferedCostModal: true,
      editId: id,
      errors: {},
    });
  };

  const handleAddPrefferedCostShow = () => {
    updateState({
      ...iState,
      setVendorPrefferedCostListModal: false,
      prefferedCostModal: true,
      // editId: id,
      errors: {},
    });
  };

  const handlePrefferedEditCostClose = () => {
    updateState({
      ...iState,
      prefferedEditCostModal: false,
      setVendorPrefferedCostListModal: true,
      errors: {},
    });
  };

  const handlePrefferedEditCostShow = (id) => {
    dispatch(VendorSearchAction()).then((res) => {
      updateState({
        ...iState,
        vendorSearchData: res.vendors,
        secondaryId: id,
        setVendorPrefferedCostListModal: false,
      });
    });
    dispatch(prefferedCostDetailsAction(editId, id)).then((res) => {
      updateState({
        ...iState,
        prefeeredVendorDetailsData: res.vendor,
        setVendorPrefferedCostListModal: false,
        prefferedEditCostModal: true,
      });
    });
  };

  const handleSetVendorPrefferedCostListClose = () => {
    updateState({ ...iState, setVendorPrefferedCostListModal: false });
  };

  const handleSetVendorPrefferedCostListShow = (id) => {
    dispatch(PrefferedCostListAction(id));
    updateState({
      ...iState,
      setVendorPrefferedCostListModal: true,
      editId: id,
    });
  };

  const handlePrefferedCostValidationSystem = () => {
    let error = {};
    let formIsValid = true;

    if (!preferredVendorName) {
      error.vendorNameError = "*  Vendor name can't be empty";
      formIsValid = false;
    }

    if (!costCode) {
      error.costCodeError = "* Cost code can't be empty";
      formIsValid = false;
    }
    if (!costType) {
      error.costTypeError = "* Cost type can't be empty";
      formIsValid = false;
    }

    updateState({ ...iState, prefferedCostError: error });
    return formIsValid;
  };

  const addPrefferedCostFun = (e) => {
    e.preventDefault();
    let formIsValid = handlePrefferedCostValidationSystem();
    if (formIsValid) {
      const data = {
        vendorName: preferredVendorName,
        costCode,
        costType,
      };
      dispatch(addPrefferedCostCodeAction(editId, data)).then((res) => {
        dispatch(PrefferedCostListAction(editId));
        sucessToast("Preferred cost code added successfully")
        handlePrefferedCostClose();
      });
    }
  };

  const deletePrefferedCostFun = (id) => {
    dispatch(removePrefferedCostAction(editId, id))
      .then((res) => {
        sucessToast("Preffered vendor removed successfully");
        dispatch(PrefferedCostListAction(editId));
      })
      .catch((err) => {
        errorToast("error");
      });
  };

  useEffect(() => {
    updateState({
      ...iState,
      preferredVendorName: prefeeredVendorDetailsData?.vendorName,
      costCode: prefeeredVendorDetailsData?.costCode,
      costType: prefeeredVendorDetailsData?.costType,
    });
  }, [prefeeredVendorDetailsData]);

  const updatePrefferedCostFun = (id) => {
    let formIsValid = handlePrefferedCostValidationSystem();
    if (formIsValid) {
      const data = {
        vendorName: preferredVendorName,
        costCode,
        costType,
      };
      dispatch(updatePrefferedCostAction(data, editId, secondaryId)).then(
        (res) => {
          console.log("========res.", res);
          dispatch(PrefferedCostListAction(editId));
          sucessToast("Preffered updated successfully")
          handlePrefferedEditCostClose();
        }
      );
    }
  };

  const handleSetVendorPrefferedCostClose = () => {
    updateState(init);
    updateSetCostCodes([])
    updateInsertingSetCostCodes({})
  };

  const handleSetVendorPrefferedCostShow = (id) => {
    updateState({ ...iState, setVendorPrefferedCostModal: true, editId:id });
  };
   
  const handleInputChange = (e) => {
    const { name, value } = e.target;
     updateInsertingSetCostCodes({
      ...insertingCostCodes,
      [name]: value,
    });
  };

  const handleSetPrefferedDataValidation = () =>{
    let errors = {};
    let formIsValid = true

      // if (insertingCostCodes?.some((x) => x?.costCode?.toString()?.trim() == undefined || "")) {
      //   error.costCodeError = "* Cost code can't be empty";
      //   formIsValid = false;
      // }
    
      // if (insertingCostCodes?.some((x) => x?.costCategory?.toString()?.trim() == undefined || "")) {
      //   error.costCategoryError = "* Cost category name can't be empty";
      //   formIsValid = false;
      // }
      // if (insertingCostCodes?.some((x) => x?.vendorName?.toString()?.trim() == undefined || "")) {
      //   error.vendorNameError = "*Vendor name can't be empty";
      //   formIsValid = false;
      // }
  
      if (!insertingCostCodes?.costCode?.trim()) {
        errors.costCodeError = "* Cost code can't be empty";
        formIsValid = false;
      }
    
      if (!insertingCostCodes?.costCategory?.trim()) {
        errors.costCategoryError = "* Cost category name can't be empty";
        formIsValid = false;
      }
    
      if (!insertingCostCodes?.vendorName?.trim()) {
        errors.vendorNameError = "* Vendor name can't be empty";
        formIsValid = false;
      }
   
    updateState({...iState, setPrefferedError:errors})
  return formIsValid

  }

  const removeCostCode = (indexToRemove) => {
    const updatedCostCodes = [...costCodes];
    updatedCostCodes.splice(indexToRemove, 1);
    updateSetCostCodes(updatedCostCodes);
  };
  
  const addPrefferedDataList = (e) => {
    e.preventDefault()
    let formIsValid = handleSetPrefferedDataValidation();
    if(formIsValid){
     
      const newCode = {
      vendorName: insertingCostCodes.vendorName,
      costCategory: insertingCostCodes.costCategory,
      costCode: insertingCostCodes.costCode,
    };
      updateSetCostCodes([...costCodes, newCode]);
      updateInsertingSetCostCodes({
        vendorName: "",
        costCategory: "",
        costCode: "",
      });
      updateState({...iState, prefferedCostError:{}})
    }
   
  }

  
  const setPrefferedCostFun = (e) => {
    e.preventDefault();
    // let formIsValid = handleSetPrefferedDataValidation();
    if (costCodes?.length > 0) {
      dispatch(setPrefferedCostCodeAction( {costCodes :costCodes}, editId)).then((res) => {
        dispatch(PrefferedCostListAction(editId));
        handleSetVendorPrefferedCostClose();
        sucessToast("Preffered added successfully")
      });
    }else {
      errorToast("Please add at least one data")
    }
  };


  const handleAssignMemberModalShow = (item) => {

    updateState({ ...iState, AssignMemberModal: true ,memberDetails:item});
  };

  const handleAssignMemberModalClose = () => {
    updateState({ ...iState, AssignMemberModal: false });
  };
  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId));
    updateState({ ...iState, ProjectId: data });
  };


  const[downloadDataExcel, setDownloadDataDownload] = useState([])

  const downloadCSV = async () => {
    try {
  
      dispatch(VendorListAction()).then((res) => {
        console.log("---------->", res)
        setDownloadDataDownload(res)
           setTimeout(() =>{
            var table_elt = document.getElementById("my-table-id");

            var workbook = XLSX.utils.table_to_book(table_elt);
            // const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(downloadDataExcel);
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Vendors');
            XLSX.writeFile(workbook, 'vendors.xlsx');
            setDownloadDataDownload([])
           }, 1000)
       


      })
     
      
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };
  return (
    <>
      <Header sendDataToParent={handleChildData}  />
      <SideNav />
      <>
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TodosArea">
              <div className="TodosHeader">
                <h6>Vendor List</h6>
                {/* <h6>
                  <img src="images/setting.png" />
                </h6> */}
              </div>
              <div className="VendorList">
                <a
                  href="javasciprt:void(0);"
                  className=""
                  onClick={handleAddVendorModal1Show}
                >
                  <img src="images/add-vendor.png" />
                  Add Vendor
                </a>
                <a
                  href="javasciprt:void(0);"
                  className=""
                  data-toggle="modal"
                  data-target="#SetPreferredCostCodeVendor2"
                >
                  <img src="images/preferred-vendor.png" />
                  Set Cost Code's Preferred Vendors
                </a>
                {/* <a
            href="javasciprt:void(0);"
            className=""
            data-toggle="modal"
            data-target="#CreateContact3"
          >
            <img src="images/add-contact.png" />
            Create Contact
          </a> */}
              </div>
            </div>  
            <div className="FilesArea">
            <div class="FilesHead">
                    <div class="FilesLeft">
                      <h4>Vendors</h4>
                      <aside>
                        <input type="text" class="form-control" placeholder="Search" onChange={(e) => dispatch(VendorListAction(encodeURIComponent(e.target.value)))} />
                        <span><img src="images/search.png"/></span>
                      </aside>
                    </div>
                    <div class="FilesRight">
                    <div className="NewActions">
                <a href="jacascript:void(0);">
                  <img src="images/icon-64.png" />
                </a>
                <a href="jacascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                {/* <DownloadTableExcel
                filename="Vendor List"
                sheet="Vendors"
                currentTableRef={vendorListRef.current}
              > */}
                <button onClick={downloadCSV} href="jacascript:void(0);">
                  <img src="images/download.png" />
                </button>
              
              </div>
                    </div>
            </div>
            </div>
            <div className="TableList TableListHeader NewTableList TableHeightScroll">
              <table  ref={vendorListRef}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    {/* <th>Primary Contact’s Name</th> */}
                    <th>Primary Email</th>
                    <th>Primary Phone No.</th>
                    <th>Vendor City</th>
                    <th>Preferred</th>
                    <th>Contact List</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(vendorListData?.VendorList) ? (
                        vendorListData?.VendorList?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div onClick={() =>handleAssignMemberModalShow(ele)}  className="TextGreen CursorPointer">  {ele?.vendorName}</div>
                                 </td>
                                <td>{ele?.vendorType}</td>
                                <td>{ele?.email}</td>
                                <td>{ele?.phone}</td>
                                <td>{ele?.city}</td>
                                {/* <td>{ele?.phone}</td> */}
                                <td>
                                  <label className="CheckBox mb-0">
                                    {" "}
                                    <span
                                      onClick={() =>
                                        handleSetVendorPrefferedCostShow(
                                          ele?.id
                                        )
                                      }
                                      className="Count"
                                    >
                                      {ele?.preferredCostCodeCount}
                                    </span>
                                    {/* <input type="checkbox" />
                                    <span className="checkmark" /> */}
                                  </label>
                                </td>
                                <td>
                                  <div className="ContactListHover">   {ele?.contactCount}</div>
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol class="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleAddContactVendorModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/contact-list.png" />
                                                Add contact
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleContactListModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/contact-list.png" />
                                                Contact List
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deleteVendorFun(ele?.id)
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Vendor
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleEditVendorModal1Show(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Vendor
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleSetVendorPrefferedCostListShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/preferred-list.png" />
                                                Preferred List
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  handlePrefferedCostShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/request_vendor.png" />
                                                Set Cost Code Preferred vendors
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#RequestVendorContacts"
                                              >
                                                <img src="images/request_vendor.png" />
                                                Request Vendor Contacts
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="ModalBox">
          {/* system task modal start  */}
          <div
            className="modal fade ExtraSmallModal show"
            id="VendorInformationModal"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="FolderForm">
                    <form>
                      <div className="ContactTopBox ContactTopBoxIdArea">
                        <span>MB</span>
                        <div className="ContactTopBoxIdName">
                          <h4>John Doe</h4>
                        </div>
                      </div>
                      <div className="SendEmailText">
                        <p>
                          <img src="images/send-email.png" />
                          Send E-mail
                        </p>
                        <p className="SendText SendText1">
                          <img src="images/send-text.png" />
                          Send Text
                        </p>
                        <div className="SendTextSearch SendTextSearch1">
                          <input type="text" />
                          <button>Apply</button>
                        </div>
                      </div>
                      <div className="MolalContactForm">
                        <h6>Contact</h6>
                        <p>
                          <img src="images/send-email.png" />
                          johndoe@gmail.com
                        </p>
                        <p>
                          <img src="images/send-text.png" />
                          +91 990000000
                        </p>
                        <a href="javascript:void(0);">Show more</a>
                      </div>
                      <div className="ContactTopBox ContactTopBoxIdArea">
                        <span>MB</span>
                        <div className="ContactTopBoxIdName">
                          <h4>Dane Doe</h4>
                        </div>
                      </div>
                      <div className="SendEmailText">
                        <p>
                          <img src="images/send-email.png" />
                          Send E-mail
                        </p>
                        <p className="SendText SendText2">
                          <img src="images/send-text.png" />
                          Send Text
                        </p>
                        <div className="SendTextSearch SendTextSearch2">
                          <input type="text" />
                          <button>Apply</button>
                        </div>
                      </div>
                      <div className="MolalContactForm">
                        <h6>Contact</h6>
                        <p>
                          <img src="images/send-email.png" />
                          johndoe@gmail.com
                        </p>
                        <p>
                          <img src="images/send-text.png" />
                          +91 990000000
                        </p>
                        <a href="javascript:void(0);">Show more</a>
                      </div>
                      <div className="ContactTopBox ContactTopBoxIdArea">
                        <span>MB</span>
                        <div className="ContactTopBoxIdName">
                          <h4>Janet Jan</h4>
                        </div>
                      </div>
                      <div className="SendEmailText">
                        <p>
                          <img src="images/send-email.png" />
                          Send E-mail
                        </p>
                        <p className="SendText SendText3">
                          <img src="images/send-text.png" />
                          Send Text
                        </p>
                        <div className="SendTextSearch SendTextSearch3">
                          <input type="text" />
                          <button>Apply</button>
                        </div>
                      </div>
                      <div className="MolalContactForm">
                        <h6>Contact</h6>
                        <p>
                          <img src="images/send-email.png" />
                          johndoe@gmail.com
                        </p>
                        <p>
                          <img src="images/send-text.png" />
                          +91 990000000
                        </p>
                        <a href="javascript:void(0);">Show more</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="SetPreferredCostCodeVendor"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#VendorPreferredCostCodesList"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Set Preferred Cost Code's Vendor</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Cost Code</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Reduced List with cost codes similar to vendor
                              type -Full List of cost codes
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Cost Type</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of cost types loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories3 mb-4">
                          <h6>Vendor Name</h6>
                          <div className="Categories_all Categories_all3">
                            <span>Vendor Name</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of vendors
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between">
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#VendorPreferredCostCodesList"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#VendorPreferredCostCodesList"
                          data-dismiss="modal"
                        >
                          Assign
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade SmallModal show"
            id="SetPreferredCostCodeVendor3"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#VendorPreferredCostCodesList2"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Set Preferred Cost Code's Vendor</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Cost Code</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Reduced List with cost codes similar to vendor
                              type -Full List of cost codes
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Cost Type</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of cost types loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories3 mb-4">
                          <h6>Vendor Name</h6>
                          <div className="Categories_all Categories_all3">
                            <span>Vendor Name</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of vendors
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between">
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#VendorPreferredCostCodesList2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#VendorPreferredCostCodesList2"
                          data-dismiss="modal"
                        >
                          Assign
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="SetPreferredCostCodeVendorRefresh"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Set Preferred Cost Code's Vendor</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Cost Code</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Reduced List with cost codes similar to vendor
                              type -Full List of cost codes
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Cost Type</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select a Cost Code</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of cost types loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories3 mb-4">
                          <h6>Vendor Name</h6>
                          <div className="d-flex align-items-center">
                            <div className="Categories_all Categories_all3">
                              <span>Air Towne</span>
                              <img src="images/down.png" />
                            </div>
                            <a href="javascript:void(0);" className="ml-4">
                              <img src="images/refresh.png" />
                            </a>
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of vendors
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button d-flex justify-content-between">
                        <button className="Outline">Cancel</button>
                        <button className="Create">Assign</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade LargeModal show" id="ContactList">
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#CreateVendorMenu"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Contact List</h4>
                  </div>
                  <div className="Tableheader2">
                    <h6 className="mb-0">
                      <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                      Drywall
                    </h6>
                    <div className="VendorCoastCode">
                      <h6
                        className="mb-0 CursorPointer"
                        data-toggle="modal"
                        data-target="#CreateContact"
                        data-dismiss="modal"
                      >
                        <img src="images/add-contact.png" />
                        &nbsp;Add Contact
                      </h6>
                    </div>
                    <div className="NewActions ml-3">
                      <a href="">
                        <img src="images/icon-64.png" />
                      </a>
                      <a href="">
                        <img src="images/icon-53.png" />
                      </a>
                      <a href="">
                        <img src="images/download.png" />
                      </a>
                      {/* <a href="">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="TableList CommonHeading TableHeightScroll">
                    <table>
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Title</th>
                          <th>Email</th>
                          <th>Office Number</th>
                          <th>Cell Phone</th>
                          <th>Address</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>John</td>
                          <td>John</td>
                          <td>President</td>
                          <td>john@drywall.com</td>
                          <td>(403)111-1111</td>
                          <td>(587)555-5555</td>
                          <td>Main address</td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      id="navbarDropdown"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#ContactDetails"
                                          data-dismiss="modal"
                                        >
                                          <img src="images/Action-1.png" />
                                          Edit Contact
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/dropdown-delete.png" />
                                          Delete Contact
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Jane</td>
                          <td>Williams</td>
                          <td>Sales Manager</td>
                          <td>john@drywall.com</td>
                          <td>(403)111-1111</td>
                          <td>(587)555-5555</td>
                          <td>BC Office</td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      id="navbarDropdown"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#ContactDetails"
                                          data-dismiss="modal"
                                        >
                                          <img src="images/Action-1.png" />
                                          Edit Contact
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/dropdown-delete.png" />
                                          Delete Contact
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Dan</td>
                          <td>Doe</td>
                          <td>Warranty Manager</td>
                          <td>john@drywall.com</td>
                          <td>(403)111-1111</td>
                          <td>(587)555-5555</td>
                          <td>Main address</td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      id="navbarDropdown"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="modal"
                                          data-target="#ContactDetails"
                                          data-dismiss="modal"
                                        >
                                          <img src="images/Action-1.png" />
                                          Edit Contact
                                        </a>
                                      </li>
                                      <li>
                                        <a href="javascript:void(0);">
                                          <img src="images/dropdown-delete.png" />
                                          Delete Contact
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade SmallModal show"
            id="CreateVendorMenu"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal ClosePermissionId"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Vendor Menu</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="SelectAnOptionArea">
                        <h6>Select an Option</h6>
                        <div className="Button">
                          <button className="Create">Single Vendor</button>
                          <button
                            className="Outline FolderPermissionId"
                            data-toggle="modal"
                            data-target="#CreateVendorMenuSmall"
                            data-dismiss="modal"
                          >
                            Batch Upload
                          </button>
                        </div>
                      </div>
                      <hr />
                      <div className="VendorDetailsArea">
                        <h6>Enter New Vendor Details</h6>
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#SystemVendorList"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/systemlist.png" />
                                </figure>{" "}
                                Select from System List
                              </h5>
                            </div>
                          </div>
                        </div>
                        <h6>Company General Information</h6>
                      </div>
                      <div className="form-group">
                        <h6>
                          Vendor Name <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Vendor Name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's main email address"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>
                            Vendor Type<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of vendor types loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="SelectAnOptionArea">
                        <h6>Company Main Address</h6>
                      </div>
                      <div className="form-group">
                        <h6>
                          Street Address<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's street address"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Street Address Line 2</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's street address line 2 if applicable"
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          City<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert city information"
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Country<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert zip code"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>
                            Vendor Type<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - List of countries loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories3 mb-4">
                          <h6>
                            State/Province<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all3">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - List of states /provinces loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="CommonModalAreaMain">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Add Additional Address</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AdditionalAddressCreation"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/share.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Add Additional
                                Address
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Contact List</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#ContactList"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/access-control.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Contact List
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="CommonModalAreaMain">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Preferred Cost Codes List</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#VendorPreferredCostCodesList"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/requests.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Preferred Cost
                                Codes List
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories4 mb-4">
                          <h6>
                            Vendor Contract
                          </h6>
                          <div className="Categories_all Categories_all4">
                            <span>Select Contract</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">+ Add New</li>
                            <li className="CursorPointer">
                              - Supplier-Trade Partner Contract
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button className="Outline" data-dismiss="modal">
                          Cancel
                        </button>
                        <button className="Create" data-dismiss="modal">
                          Create Vendor
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="VendorDetails"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal ClosePermissionId"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Vendor Details</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="SelectAnOptionArea">
                        <h6>Select an Option</h6>
                        <div className="Button">
                          <button className="Create">Single Vendor</button>
                          <button className="Outline">Batch Upload</button>
                        </div>
                      </div>
                      <div className="VendorDetailsArea pt-5">
                        <h6>Company General Information</h6>
                        <h6 className="text-right">Last Updated: 05/15/2023</h6>
                      </div>
                      <div className="form-group">
                        <h6>
                          Vendor Name <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Vendor Name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's main email address"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>
                            Vendor Type<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - Full list of vendor types loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>
                          Tax Registration
                          <span className="required">&nbsp;</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Vendor Name"
                        />
                      </div>
                      <div className="SelectAnOptionArea">
                        <h6>Company Main Address</h6>
                      </div>
                      <div className="form-group">
                        <h6>
                          Street Address<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's street address"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Street Address Line 2</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert company's street address line 2 if applicable"
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          City<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert city information"
                        />
                      </div>
                      <div className="form-group">
                        <h6>
                          Country<span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert zip code"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>
                            Vendor Type<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - List of countries loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories3 mb-4">
                          <h6>
                            State/Province<span className="required">*</span>
                          </h6>
                          <div className="Categories_all Categories_all3">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                            <li className="CursorPointer">
                              - List of states /provinces loaded from the system
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="CommonModalAreaMain">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Add Additional Address</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#AdditionalAddressCreation2"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/share.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Add Additional
                                Address
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Contact List</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#ContactList2"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/access-control.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Contact List
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="CommonModalAreaMain">
                        <div className="CommonModalArea">
                          <div className="form-group">
                            <h5>Preferred Cost Codes List</h5>
                            <div className="CommonModalBox">
                              <h5
                                className="CursorPointer FolderPermissionId"
                                data-toggle="modal"
                                data-target="#VendorPreferredCostCodesList2"
                                data-dismiss="modal"
                              >
                                <figure>
                                  <img src="images/requests.png" />
                                </figure>{" "}
                                <span className="Count">3</span> Preferred Cost
                                Codes List
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories4 mb-4">
                          <h6>
                            Vendor Contract
                          </h6>
                          <div className="Categories_all Categories_all4">
                            <span>Select Contract</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">+ Add New</li>
                            <li className="CursorPointer">
                              - Supplier-Trade Partner Contract
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button className="Outline" data-dismiss="modal">
                          Cancel
                        </button>
                        <button className="Create" data-dismiss="modal">
                          Update Information
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade SmallModal show"
            id="CreateContact3"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Contact</h4>
                  </div>
                  <div className="Tableheader2">
                    <h6 className="mb-0">
                      <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                      Drywall
                    </h6>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <h6>First Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's first name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Last Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's last name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Title</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's title"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Type</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Primary
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Sales
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Design
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Estimation
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Purchasing
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Construction
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Warranty
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's email"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Office Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Cell Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Address</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select contact's work address</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              - List of address registered for company
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button className="Outline" data-dismiss="modal">
                          Cancel
                        </button>
                        <button className="Create" data-dismiss="modal">
                          Create Contact
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="CreateContact2"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#ContactList2"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Contact</h4>
                  </div>
                  <div className="Tableheader2">
                    <h6 className="mb-0">
                      <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                      Drywall
                    </h6>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <h6>First Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's first name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Last Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's last name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Title</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's title"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Type</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Primary
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Sales
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Design
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Estimation
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Purchasing
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Construction
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Warranty
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's email"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Office Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Cell Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Address</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Select contact's work address</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              - List of address registered for company
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#ContactList2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#ContactList2"
                          data-dismiss="modal"
                        >
                          Create Contact
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="ContactDetails"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#ContactList"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Contact Details</h4>
                  </div>
                  <div className="Tableheader2">
                    <h6 className="mb-0">
                      <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                      Drywall
                    </h6>
                  </div>
                  <div className="text-right">
                    <h6>Last Updated: 05/15/2023</h6>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <h6>First Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's first name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Last Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's last name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Title</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's title"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Type</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Primary
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Sales
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Design
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Estimation
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Purchasing
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Construction
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Warranty
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's email"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Office Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Cell Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Address</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Main Address</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              - List of address registered for company
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#ContactList"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#ContactList"
                          data-dismiss="modal"
                        >
                          Update Contact
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show"
            id="ContactDetails2"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#ContactList2"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Contact Details</h4>
                  </div>
                  <div className="Tableheader2">
                    <h6 className="mb-0">
                      <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                      Drywall
                    </h6>
                  </div>
                  <div className="text-right">
                    <h6>Last Updated: 05/15/2023</h6>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="form-group">
                        <h6>First Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's first name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Last Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's last name"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Title</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's title"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories1 mb-4">
                          <h6>Type</h6>
                          <div className="Categories_all Categories_all1">
                            <span>Select a Vendor Type</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Primary
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Sales
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Design
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Estimation
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Purchasing
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Construction
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                            <li className="CursorPointer">
                              <label className="CheckBox">
                                {" "}
                                Warranty
                                <input type="checkbox" />
                                <span className="checkmark" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Email</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's email"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Office Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <h6>Cell Phone</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert contact's office phone"
                        />
                      </div>
                      <div className="form-group">
                        <div className="Categories Categories2 mb-4">
                          <h6>Address</h6>
                          <div className="Categories_all Categories_all2">
                            <span>Main Address</span>
                            <img src="images/down.png" />
                          </div>
                          <ul>
                            <li className="CursorPointer">
                              - List of address registered for company
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="Button mt-5 d-flex justify-content-between">
                        <button
                          className="Outline"
                          data-toggle="modal"
                          data-target="#ContactList2"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#ContactList2"
                          data-dismiss="modal"
                        >
                          Update Contact
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
       

     
          <div
            className="modal fade ExtraLargeModal show"
            id="TradesDaySelection8"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="CalendarSection">
                            <h6 className="mb-5">Select a Date and Time</h6>
                            <div className="ModalCalendarTable">
                              <div className="">
                                <table>
                                  <thead>
                                    <tr></tr>
                                    <tr>
                                      <th colSpan={7}>
                                        <div className="TableHeader">
                                          <h6 className="MonthName">
                                            <img src="images/calendar-left-arrow.png" />
                                            August
                                          </h6>
                                          <h6 className="MonthName">
                                            2022
                                            <img src="images/calendar-right-arrow.png" />
                                          </h6>
                                        </div>
                                      </th>
                                    </tr>
                                    <tr>
                                      <th>Sunday</th>
                                      <th>Monday</th>
                                      <th>Tuesday</th>
                                      <th>Wednesday</th>
                                      <th>Thursday</th>
                                      <th>Friday</th>
                                      <th>Saturday</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="TdBgColor">29</div>
                                      </td>
                                      <td>30</td>
                                      <td>1</td>
                                      <td>2</td>
                                      <td>3</td>
                                      <td>4</td>
                                      <td>
                                        <div className="LightBlue">5</div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>7</td>
                                      <td>
                                        <div className="LightBlue">8</div>
                                      </td>
                                      <td>9</td>
                                      <td>
                                        <div className="LightBlue">
                                          10
                                          <div className="DateOverflowBlue" />
                                        </div>
                                      </td>
                                      <td>11</td>
                                      <td>
                                        <div className="LightBlue">12</div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>13</td>
                                      <td>14</td>
                                      <td>
                                        <div className="LightBlue">15</div>
                                      </td>
                                      <td>16</td>
                                      <td>
                                        <div className="LightGreen">
                                          17
                                          <div className="DateOverflowGreen" />
                                        </div>
                                      </td>
                                      <td>18</td>
                                      <td>
                                        <div className="LightBlue">19</div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>20</td>
                                      <td>21</td>
                                      <td>22</td>
                                      <td>
                                        <div className="LightBlue">23</div>
                                      </td>
                                      <td>24</td>
                                      <td>
                                        <div className="DarkGreen">
                                          25
                                          <div className="DateOverflowGreen" />
                                        </div>
                                      </td>
                                      <td>26</td>
                                    </tr>
                                    <tr>
                                      <td>27</td>
                                      <td>28</td>
                                      <td>29</td>
                                      <td>30</td>
                                      <td>31</td>
                                      <td>
                                        <div className="TdBgColor">1</div>
                                      </td>
                                      <td>
                                        <div className="TdBgColor">2</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <div className="TableFooter">
                                  <h6>Time Zone</h6>
                                  <p>
                                    <img src="images/earth.png" />
                                    Mountain time - US &amp; Canada (08:08 am){" "}
                                    <img src="images/black-down-arrow.png" />
                                  </p>
                                </div> */}
                              </div>
                              <div className="CalendarRight CalendarRightGreen">
                                <h6>Thursday, August 10</h6>
                                <button>10:00 am - 12:00 pm</button>
                                <button>11:00 am - 01:00 pm</button>
                                <button>01:00 am - 03:00 pm</button>
                                <div className="d-flex justify-content-between">
                                  <button className="GreenBrandBtn">
                                    02:30 am
                                  </button>
                                  <button className="GreenBrandBtn">
                                    Next
                                  </button>
                                </div>
                                <button className="GreenBrandBtn">
                                  Finish Selection
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade ExtraLargeModal show"
            id="TradesDaySelection11"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="InspectionConfirmationArea">
                            <h6>
                              Please select one of the dates bellow or suggest
                              new dates for inspection
                            </h6>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaBlueHeading">
                                Item #1 - Leaking Sink
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnReject">
                                  Reject
                                </button>
                                <button className="ChangeBtn ChangeBtnConfirm ml-5">
                                  Confirm
                                </button>
                              </div>
                            </div>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaGreenHeading">
                                Item #2 - Faucet Problem
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnReject">
                                  Rejected
                                </button>
                                <button className="ChangeBtn ChangeBtnConfirm ml-5">
                                  Confrim
                                </button>
                              </div>
                            </div>
                            <div className="Button">
                              <button
                                className="Create FolderPermissionId"
                                data-toggle="modal"
                                data-target="#TradesDaySelectionSend"
                                data-dismiss="modal"
                              >
                                Suggest New Dates for Rejected Items
                              </button>
                              <button className="Respond mt-2">
                                Respond to Builder
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade LargeModal" id="TradesDaySelectionSend">
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#TradesDaySelection11"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <form action="">
                      <div className="row">
                        <div className="col-lg-8 ml-auto">
                          <div className="ModalFilterAreaBox mb-3">
                            <h4 className="mb-4">Suggested Dates</h4>
                            <div className="RadioArea d-flex  flex-column">
                              <div className="form-group mb-3">
                                <label className="Radio">
                                  Same date for all items
                                  <input type="radio" name="Cuisine" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="form-group">
                                <label className="Radio">
                                  Individual dates for each item
                                  <input type="radio" name="Cuisine" />
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="ModalFilterAreaBox">
                            <h4>Item # 1 - Leaking Sink</h4>
                            <p> Inspector - John Doe</p>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="ModalFilterAreaBox">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="ModalFilterAreaBox">
                                  <h4>Item # 1 - Leaking Sink</h4>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Date Option 1</label>
                                  <input type="date" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>From</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>To</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Date Option 1</label>
                                  <input type="date" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>From</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>To</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="Button mb-5">
                                  <button className="Create">Add Date</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="ModalFilterAreaBox">
                            <h4>Item # 2 - Leaking Sink</h4>
                            <p> Inspector - John Doe</p>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="ModalFilterAreaBox">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="ModalFilterAreaBox">
                                  <h4>Item #2 - Faucet Problem</h4>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Date Option 1</label>
                                  <input type="date" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>From</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>To</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>Date Option 1</label>
                                  <input type="date" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>From</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="form-group">
                                  <label>To</label>
                                  <input type="time" className="form-control" />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="Button mb-5">
                                  <button className="Create">Add Date</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="CommonModalArea">
                            <div className="form-group">
                              <h5>Set Reminder</h5>
                              <div className="CommonModalBox">
                                <h5
                                  className="FolderPermissionId CursorPointer"
                                  data-toggle="modal"
                                  data-target="#ReminderModalTradesSend"
                                  data-dismiss="modal"
                                >
                                  <figure>
                                    <img src="images/timer-red.png" />
                                  </figure>{" "}
                                  Reminder
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="Button mt-5">
                            <button
                              className="Create"
                              data-toggle="modal"
                              data-target="#TradesDaySelection11"
                              data-dismiss="modal"
                            >
                              Send New Dates
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade SmallModal" id="ReminderModalTradesSend">
            <div className="modal-dialog">
              <div className="modal-content">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-toggle="modal"
                  data-target="#TradesDaySelectionSend"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Reminder</h4>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="Categories Categories2">
                        <h6>Date</h6>
                        <div className="Categories_all Categories_all2">
                          <span>Select aumont of time for reminder</span>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>-01</li>
                          <li>-02</li>
                          <li>-03</li>
                        </ul>
                      </div>
                      <div className="Button text-center mt-5">
                        <button
                          className="Create mr-4"
                          data-toggle="modal"
                          data-target="#TradesDaySelectionSend"
                          data-dismiss="modal"
                        >
                          Set Reminder
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade LargeModal show"
            id="TradesDaySelection12"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="InspectionConfirmationArea InspectionConfirmationArea2">
                            <h6>Confirmation</h6>
                            <p className="mb-0">
                              Client and inspector have agreed on the inspection
                              dates.{" "}
                            </p>
                            <p className="mb-4">
                              Please see the details bellow
                            </p>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p className="text-black underline-black">
                                    #1 - Leaking Sink
                                  </p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p className="text-black underline-black">
                                    #2 - Faucet Problem
                                  </p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="Button">
                              <button className="Create">
                                Update Warranty Request and Send Confirmation
                                Email
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade MediumModal show"
            id="TradesDaySelection13"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBoxBrand">
                    <h4>Trades Day Confirmation</h4>
                    <a href="">
                      <img src="images/logo-white.png" />
                    </a>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <h3>Trades Day Confirmation</h3>
                          <div className="ClientsDetails">
                            <p>Client Details</p>
                            <h6 className="ClientName">Luke Anderson</h6>
                            <p className="ClientEmail">
                              linden@ibuildapplications.com
                            </p>
                            <p className="ClientNumber">4034005933</p>
                          </div>
                          <div className="ClientLocation">
                            <p>
                              Location <img src="images/location.png" />
                            </p>
                            <p className="ClientAddress">
                              #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                              Calgary
                            </p>
                          </div>
                          <div className="ClientsDetails">
                            <p>Vendor Details</p>
                            <h6 className="ClientName">Bigfoot Drywall</h6>
                            <p className="ClientEmail">
                              linden@ibuildapplications.com
                            </p>
                            <p className="ClientNumber">4034005933</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h3>Items to be inspected</h3>
                          <div className="ItemAreaBox ItemAreaBoxUnderline mb-5">
                            <h6>#1 - Leaking Sink</h6>
                            <h6>#2 Faucet Problem</h6>
                          </div>
                          <div className="ClientsDetails">
                            <h6 className="ClientName">Technician</h6>
                            <p className="ClientEmail">John Doe</p>
                            <p className="ClientNumber">
                              Contact: (403)555-555
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="DatesBoxAreaMain">
                            <h3>Dates</h3>
                            <div className="DatesBoxArea">
                              <h6>#1 - Leaking Sink</h6>
                              <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                              <p className="mb-3">
                                Mountain Time - US &amp; Canada
                              </p>
                              <div className="AddToSocialArea">
                                <h6>Add to</h6>
                                <a href="javascript:void(0);">
                                  <img src="images/google-calendar.png" />{" "}
                                  Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calender
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/yahoo-calendar.png" />
                                  Yahoo Calender
                                </a>
                              </div>
                            </div>
                            <div className="DatesBoxArea">
                              <h6>#2 - Faucet Problem</h6>
                              <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                              <p className="mb-3">
                                Mountain Time - US &amp; Canada
                              </p>
                              <div className="AddToSocialArea">
                                <h6>Add to</h6>
                                <a href="javascript:void(0);">
                                  <img src="images/google-calendar.png" />{" "}
                                  Calendar
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/outlook-calendar.png" />
                                  Outlook Calender
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/yahoo-calendar.png" />
                                  Yahoo Calender
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade LargeModal show"
            id="TradesDaySelection14"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="InspectionConfirmationArea">
                            <h6>Confirmation</h6>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaBlueHeading">
                                Item #1 - Leaking Sink
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Change
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Change
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnBlue">
                                  Add Date
                                </button>
                                <button className="ChangeBtn ChangeBtnBlue ml-5">
                                  Confrim
                                </button>
                              </div>
                            </div>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaGreenHeading">
                                Item #2 - Faucet Problem
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Change
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Change
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnGreen">
                                  Add Date
                                </button>
                                <button className="ChangeBtn ChangeBtnGreen ml-5">
                                  Confrim
                                </button>
                              </div>
                            </div>
                            <div className="Button">
                              <button className="Create">
                                Add Technicians
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade ExtraLargeModal show"
            id="TradesDaySelection15"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="InspectionConfirmationArea">
                            <h6>Confirmation</h6>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Enter Technician details</h6>
                              <div className="TechnicianDetailsArea">
                                <h6 className="IcaBlueHeading">
                                  Item #1 - Leaking Sink
                                </h6>
                                <div className="form-group col-md-6 pl-0">
                                  <h6>Name</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert name"
                                  />
                                </div>
                                <div className="form-group col-md-6 pl-0">
                                  <h6>Contact</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Contact number"
                                  />
                                </div>
                                <button className="ChangeBtn ChangeBtnBlue">
                                  Add Date
                                </button>
                                <div className="TechnicianNameContact">
                                  <p>
                                    <span>Name</span> - John Doe{" "}
                                    <img src="images/Cross.png" />
                                  </p>
                                  <p>
                                    <span>Contact</span> - (403)555-555
                                  </p>
                                </div>
                              </div>
                              <div className="TechnicianDetailsArea">
                                <h6 className="IcaGreenHeading">
                                  Item #2 - Faucet Problem
                                </h6>
                                <div className="form-group col-md-6 pl-0">
                                  <h6>Name</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert name"
                                  />
                                </div>
                                <div className="form-group col-md-6 pl-0">
                                  <h6>Contact</h6>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insert Contact number"
                                  />
                                </div>
                                <button className="ChangeBtn ChangeBtnGreen">
                                  Add Date
                                </button>
                                <div className="TechnicianNameContact">
                                  <p>
                                    <span>Name</span> - John Doe{" "}
                                    <img src="images/Cross.png" />
                                  </p>
                                  <p>
                                    <span>Contact</span> - (403)555-555
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="Button">
                              <button className="Create">
                                Respond to Builder
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade ExtraLargeModal show"
            id="TradesDaySelection16"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Trades Day Selection</h4>
                  </div>
                  <div className="FolderForm">
                    <h3>Work Order #158</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="CalendarLeft">
                            <h6 className="Heading">Trades Day</h6>
                            <div className="ClientsDetails">
                              <p>Client Details</p>
                              <h6 className="ClientName">Luke Anderson</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ClientLocation">
                              <p>
                                Location <img src="images/location.png" />
                              </p>
                              <p className="ClientAddress">
                                #Unit 2 312 Weddenburn Rd SE <br /> Chinook
                                Gate, Calgary
                              </p>
                            </div>
                            <div className="ClientsDetails">
                              <p>Vendor Details</p>
                              <h6 className="ClientName">Bigfoot Drywall</h6>
                              <p className="ClientEmail">
                                linden@ibuildapplications.com
                              </p>
                              <p className="ClientNumber">4034005933</p>
                            </div>
                            <div className="ItemArea">
                              <h6>Items</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ItemArea mb-0">
                              <h6>Selected Dates</h6>
                              <div className="ItemAreaBox ItemAreaBoxBlue">
                                <h6>#1 - Leaking Sink</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ItemArea">
                              <div className="ItemAreaBox ItemAreaBoxGreen">
                                <h6>#2 - Faucet Problem</h6>
                              </div>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                <br /> Mountain Time - US &amp; Canada
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="InspectionConfirmationArea">
                            <h6>
                              Please select one of the dates bellow or suggest
                              new dates for inspection
                            </h6>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaBlueHeading">
                                Item #1 - Leaking Sink
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnBlue">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnReject">
                                  Reject
                                </button>
                                <button className="ChangeBtn ChangeBtnConfirm ml-5">
                                  Confirm
                                </button>
                              </div>
                            </div>
                            <div className="InspectionConfirmationAreaBox">
                              <h6>Selected Dates</h6>
                              <h6 className="IcaGreenHeading">
                                Item #2 - Faucet Problem
                              </h6>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 1</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <div className="InspectionConfirmationAreaAddress">
                                <div className="ClientLocation">
                                  <p>Option 2</p>
                                  <p className="ClientAddress">
                                    02:30pm - 04:30pm. Wednesday, May 17, 2023{" "}
                                    <br /> Mountain Time - US &amp; Canada
                                  </p>
                                </div>
                                <div>
                                  <button className="ChangeBtn ChangeBtnGreen">
                                    Select
                                  </button>
                                </div>
                              </div>
                              <p className="IcaBluePara">
                                Confirmation Selection dates and respond to
                                builder or add another option ?
                              </p>
                              <div className="d-flex mt-4">
                                <button className="ChangeBtn ChangeBtnReject">
                                  Rejected
                                </button>
                                <button className="ChangeBtn ChangeBtnConfirm ml-5">
                                  Confrim
                                </button>
                              </div>
                            </div>
                            <div className="Button">
                              <button className="Create">
                                Suggest New Dates for Rejected Items
                              </button>
                              <button className="Respond mt-2">
                                Add Technicians
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show NewTaskAssigned1Background"
            id="NewTaskAssigned1"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBoxBrand">
                    <h4>New Task Assigned</h4>
                    <a href="">
                      <img src="images/logo-white.png" />
                    </a>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="NewTaskAssignModalArea">
                        <h6>Vendor</h6>
                        <p>
                          A new task has been assigned to you by Builder. Please
                          <br />
                          confirm execution date
                        </p>
                        <div className="NewTaskAssignModalAreaBox">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div>
                                    Location: <img src="images/location.png" />
                                  </div>
                                </td>
                                <td>26th Zalda Cr, Calgary</td>
                              </tr>
                              <tr>
                                <td>Client:</td>
                                <td>Luke Anderson</td>
                              </tr>
                              <tr>
                                <td>Date Suggested:</td>
                                <td>May 16, 2023 </td>
                              </tr>
                              <tr>
                                <td>Duration:</td>
                                <td>1 day</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between mt-3 pb-5">
                          <div className="dropdown modalButtonDropdown">
                            <button
                              className="BrandBtnGreen2"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Approve
                              <span>
                                <img src="images/down-arrow-white.png" />
                              </span>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/view-po.png" />
                                  Print
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Add to &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/yahoo-calendar.png" />
                                      Yahoo Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </div>
                          </div>
                          <div className="Button">
                            <button className="Create">Reject</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade SmallModal show NewTaskAssigned1Background"
            id="NewTaskAssigned2"
            aria-modal="true"
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content pb-0">
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  data-dismiss="modal"
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBoxBrand">
                    <h4>New Task Assigned</h4>
                    <a href="">
                      <img src="images/logo-white.png" />
                    </a>
                  </div>
                  <div className="FolderForm">
                    <form>
                      <div className="NewTaskAssignModalArea">
                        <h6>Vendor</h6>
                        <p>
                          A new task has been assigned to you by Builder. Please
                          <br />
                          confirm execution date
                        </p>
                        <div className="NewTaskAssignModalAreaBox">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div>
                                    Location: <img src="images/location.png" />
                                  </div>
                                </td>
                                <td>26th Zalda Cr, Calgary</td>
                              </tr>
                              <tr>
                                <td>Client:</td>
                                <td>Luke Anderson</td>
                              </tr>
                              <tr>
                                <td>Date Suggested:</td>
                                <td>
                                  <input type="date" />
                                </td>
                              </tr>
                              <tr>
                                <td>Duration:</td>
                                <td>
                                  <input type="text" placeholder="1 day" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between mt-3 pb-5">
                          <div className="dropdown modalButtonDropdown">
                            <button
                              className="BrandBtnGreen2"
                              type="button"
                              data-toggle="dropdown"
                            >
                              Approve
                              <span>
                                <img src="images/down-arrow-white.png" />
                              </span>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/view-po.png" />
                                  Print
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Add to &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/yahoo-calendar.png" />
                                      Yahoo Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </div>
                          </div>
                          <div className="Button">
                            <button className="Create">Reject</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="mySidenav" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onclick="closeNav()"
          >
            ×
          </a>
          <div className="sidenavTop">
            <span>MB</span>
            <h6>Man Mohan</h6>
            <h6>IT Consultant</h6>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <ul className="">
              <li>
                <a href="">
                  <img src="images/profile.png" />
                  Profile
                </a>
              </li>
              <li>
                <a href="">
                  <img src="images/contacts.png" />
                  Contacts
                </a>
              </li>
              <li>
                <a href="">
                  {" "}
                  <img src="images/my_task.png" />
                  My Tasks
                </a>
              </li>
            </ul>
            <ul className="bottomUl">
              <li>
                <a href="">
                  <img src="images/signout.png" />
                  Sign Out
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="myHelp" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onclick="closeHelp()"
          >
            ×
          </a>
          <div className="SideNavTopBox">
            <h1>Help</h1>
          </div>
          <div className="SideNavBottom">
            <div className="SideNavSettingBox">
              <h6>Contrary to popular</h6>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
            <div className="SideNavSettingBox">
              <h6>Contrary to popular</h6>
              <p>
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
            <div className="SideNavSettingBox">
              <h6>Standard chunk</h6>
              <ol>
                <li>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable.
                </li>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
                <li>
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div id="myTask" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onclick="closeTask()"
          >
            ×
          </a>
          <div className="SideNavTopBox">
            <h1>My Task</h1>
          </div>
          <div className="SideNavBottom">
            <div className="SideTaskBoxTitle">
              <h6>Today</h6>
            </div>
            <div className="SideTaskBox">
              <h6>Task 1</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
              <div className="TaskButtonArea">
                <button className="View">View</button>
                <button className="Start">Start</button>
              </div>
            </div>
            <div className="SideTaskBox">
              <h6>Task 2</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
              <div className="TaskButtonArea">
                <button className="View">View</button>
                <button className="Start">Start</button>
              </div>
            </div>
            <div className="SideTaskBox">
              <h6>Task 3</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
              <div className="TaskButtonArea">
                <button className="View">View</button>
                <button className="Completed">Completed</button>
              </div>
            </div>
            <div className="SideTaskBox">
              <h6>Task 4</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
              <div className="TaskButtonArea">
                <button className="View">View</button>
                <button className="Ongoing">Ongoing</button>
              </div>
            </div>
          </div>
        </div>
        <div id="myNotification" className="sidenav">
          <a
            href="javascript:void(0)"
            className="closebtn"
            onclick="closeNotification()"
          >
            ×
          </a>
          <div className="SideNavTopBox">
            <h1>Notification</h1>
          </div>
          <div className="SideNavBottom">
            <div className="SideTaskBoxTitle">
              <h6>Today</h6>
            </div>
            <div className="SideNotificationBox">
              <h6>Lorem Ipsum</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
            </div>
            <div className="text-right">
              <button className="DeleteBtn">Delete</button>
            </div>
            <div className="SideNotificationBox">
              <h6>Lorem Ipsum</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
            </div>
            <div className="text-right">
              <button className="DeleteBtn">Delete</button>
            </div>
            <div className="SideNotificationBox">
              <h6>Lorem Ipsum</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
            </div>
            <div className="text-right">
              <button className="DeleteBtn">Delete</button>
            </div>
            <div className="SideTaskBoxTitle">
              <h6>Yesterday</h6>
            </div>
            <div className="SideNotificationBox">
              <h6>Lorem Ipsum</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
            </div>
            <div className="text-right">
              <button className="DeleteBtn">Delete</button>
            </div>
            <div className="SideNotificationBox">
              <h6>Lorem Ipsum</h6>
              <p>There are many variations of passages Lorem Ipsum available</p>
            </div>
            <div className="text-right">
              <button className="DeleteBtn">Delete</button>
            </div>
          </div>
        </div>
        <div id="mySettings" className="sidenav">
          <a className="editbtn" id="editbtn">
            <img src="images/edit2.png" />
          </a>
          <a className="closebtn" onclick="closeSettings()">
            ×
          </a>
          <div className="SideNavTopBox">
            <h1>Settings</h1>
          </div>
          <div className="SideNavBottom">
            <div className="SettingThemeArea">
              <div className="SideTaskBoxTitle">
                <h6>Theme</h6>
              </div>
              <div className="SideNavHelpBox">
                <ul>
                  <li>
                    <img src="images/theme-1.png" />
                  </li>
                  <li>
                    <img src="images/theme-2.png" />
                  </li>
                  <li>
                    <img src="images/theme-3.png" />
                  </li>
                  <li>
                    <img src="images/theme-4.png" />
                  </li>
                  <li>
                    <img src="images/theme-5.png" />
                  </li>
                  <li>
                    <img src="images/theme-6.png" />
                  </li>
                  <li>
                    <img src="images/theme-7.png" />
                  </li>
                  <li>
                    <img src="images/theme-8.png" />
                  </li>
                </ul>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="form-group">
                  <label className="Radio">
                    English
                    <input type="radio" name="Cuisine" />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    French
                    <input type="radio" name="Cuisine" />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
              <div className="form-group mt-3 d-flex align-items-center justify-content-between">
                <label className="Dark-mode mb-0">Dark Mode</label>
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider" />
                </label>
              </div>
              <div className="form-group">
                <label className="Dark-mode d-block">Password</label>
                <label href="#" className="text-red">
                  Change Password
                </label>
              </div>
            </div>
            <div className="SettingsEditArea">
              <div className="SettingsEdit">
                <h6>Theme</h6>
                <div className="settingsEditBox">
                  <h6>Add</h6>
                  <p>Remove</p>
                </div>
              </div>
              <div className="SettingsEdit">
                <h6>Language</h6>
                <div className="settingsEditBox">
                  <h6>Add</h6>
                  <p>Remove</p>
                </div>
              </div>
              <div className="SettingsEdit">
                <h6>Dark Mode</h6>
                <div className="settingsEditBox">
                  <h6>Add</h6>
                  <p>Remove</p>
                </div>
              </div>
              <div className="SettingsEdit">
                <h6>Password</h6>
                <div className="settingsEditBox">
                  <h6>Add</h6>
                  <p>Remove</p>
                </div>
              </div>
              <div className="text-center">
                <button className="Save SaveBtn">Save</button>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL */}

        <Modal
          show={AddVendorModal2}
          onHide={handleAddVendorModal2Close}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  href="javascript:void(0);"
                  className="CloseModal"
                  onClick={handleAddVendorModal2Close}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Vendor </h4>
                  </div>
                  <div className="FolderForm">
                      <div className="SelectAnOptionArea">
                        <h6>Select an Option</h6>
                        <div className="Button">
                          <button
                            className="Outline"
                            onClick={handleAddVendorModal1Show}
                          >
                            Single Vendor
                          </button>
                          <button onClick={handleAddVendorModal2Show} className="Create">Batch Upload</button>
                        </div>
                      </div>
                      <hr />
                      <div className="CommonModalArea py-4">
                        <div className="form-group">
                          <div className="CommonModalBox">
                            <h5  onClick={handleTemplateBulk} className="CursorPointer">
                              <figure>
                                <img src="images/download-template.png" />
                              </figure>{" "}
                              Download Template
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Select Files</h6>
                        <div className="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input type="file"  name="file" onChange={handleInputFile}/>                      
                        </div>
                        <span style={{fontSize:"14px" , color:"red"}} >{BulkError?.fileNameError}</span>
                      </div>
                      <div className="form-group">
                        <div className="FilesAdded">
                          <label>{ fileNameStatic}</label>
                         
                        </div>
                      </div>
                      {/* <label className="switch">
                        {" "}
                        Validate File Only
                        <input type="checkbox" />
                        <span className="slider" />
                      </label> */}
                      <div className="Button mt-4 d-flex justify-content-between">
                        <button
                          className="Outline"
                          onClick={handleAddVendorModal2Close}
                        >
                          Cancel
                        </button>
                        <button
                          className="Create"
                          onClick = {handleBulkVendor}
                        >
                          Upload File
                        </button>
                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* ADDVENDORMODAL */}
        <Modal
          show={AddVendorModal1}
          onHide={handleAddVendorModal1Close}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal ClosePermissionId"
                  onClick={handleAddVendorModal1Close}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Create Vendor </h4>
                  </div>
                  <div className="FolderForm">
                    <div className="SelectAnOptionArea">
                      <h6>Select an Option</h6>
                      <div className="Button">
                        <button  onClick={handleAddVendorModal1Show} className="Create">Single Vendor</button>
                        <button
                          className="Outline FolderPermissionId"
                         onClick={handleAddVendorModal2Show}
                        >
                          Batch Upload
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="VendorDetailsArea">
                      <h6>Enter New Vendor Details</h6>
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={handleSystemVendorModalShow}
                            >
                              <figure>
                                <img src="images/systemlist.png" />
                              </figure>{" "}
                              Select from System List
                            </h5>
                          </div>
                        </div>
                      </div>
                      <h6>Company General Information</h6>
                    </div>
                    <div className="form-group">
                      <h6>
                        Vendor Name <span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Vendor Name"
                        name="vendorName"
                        value={vendorName}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.vendorNameError}
                      </span>
                    </div>
                    <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput "
                      countryCodeEditable={false}
                      enableSearch
                      
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                    <div className="form-group">
                      <h6>Phone</h6>
                      
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert company's Phone Number"
                        name="phone"
                        value={phone}
                        onChange={handleInputNumber}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.phoneError}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert company's main email address"
                        name="email"
                        value={email}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.emailError}
                      </span>
                    </div>

                    <div className="SelectAnOptionArea">
                      <h6>Company Main Address</h6>
                    </div>
                    <div className="form-group">
                      <h6>
                        Street Address<span className="required">*</span>
                      </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert company's street address"
                        name="addressLine1"
                        value={addressLine1}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.addressLine1Error}
                      </span>
                    </div>
                    <div className="form-group">
                      <h6>Street Address Line 2</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert company's street address line 2 if applicable"
                        name="addressLine2"
                        value={addressLine2}
                        onChange={handleInput}
                      />
                      
                    </div>

                    <div className="form-group">
                      <h6>Zip/Postal Code</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert zip/postal code"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.zipCodeError}
                      </span>
                      
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>
                          Country<span className="required">*</span>
                        </h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="country"
                            value={country}
                            onChange={handleInput}
                          >
                            <option>Select Country</option>
                            {checkArray(countryData?.countries) ? (
                              <>
                                {countryData?.countries?.map((ele, i) => {
                                  return (
                                    <option value={ele?.name}>
                                      {ele?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>
                          Province<span className="required">*</span>
                        </h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="province"
                            value={province}
                            onChange={handleInput}
                          >
                            <option>Select Province</option>
                            {checkArray(stateData?.states) ? (
                              <>
                                {stateData?.states?.map((ele, i) => {
                                  return (
                                    <option value={ele?.name}>
                                      {ele?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.provinceError}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>
                          City<span className="required">*</span>
                        </h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="city"
                            value={city}
                            onChange={handleInput}
                          >
                            <option>Select City</option>
                            {checkArray(stateData?.states) ? (
                              <>
                                {cityData?.cities?.map((ele, i) => {
                                  return (
                                    <option value={ele?.name}>
                                      {ele?.name}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.cityError}
                        </span>
                        {/* <ul>
                          <li>
                            <input type="text" placeholder="Search" />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          <li className="CursorPointer">
                            - List of countries loaded from the system
                          </li>
                        </ul> */}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories1 mb-4">
                        <h6>Vendor Type</h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="vendorType"
                            value={vendorType}
                            onChange={handleInput}
                          >
                            <option>Select a Vendor Type</option>
                            {checkArray(data1) ? (
                              <>
                                {data1?.map((ele, i) => {
                                  return <option value={ele}>{ele}</option>;
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              onChange={(e) => onChangeHandler(e)}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                        </ul>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.vendorTypeError}
                      </span>
                    </div>

                    <div className="CommonModalAreaMain">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Add Additional Address</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={handleAddAddressModalShow}
                            >
                              {" "}
                               Add Additional
                              Address
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                
                    <div className="Button mt-5 d-flex justify-content-between">
                      <button
                        className="Outline"
                        onClick={handleAddVendorModal1Close}
                      >
                        Cancel
                      </button>
                      <button className="Create" onClick={addVendor}>
                        Create Vendor
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
      {/* EDIT VENDOR  */}
      <Modal
        show={EditVendorModal1}
        onHide={handleEditVendorModal1Close}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleEditVendorModal1Close}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit Vendor </h4>
                </div>
                <div className="FolderForm">
                  <div className="SelectAnOptionArea">
                    <h6>Select an Option</h6>
                    <div className="Button">
                      <button className="Create">Single Vendor</button>
                      <button
                        className="Outline FolderPermissionId"
                        data-toggle="modal"
                        data-target="#CreateVendorMenuSmall"
                        data-dismiss="modal"
                      >
                        Batch Upload
                      </button>
                    </div>
                  </div>
                
                  <div className="VendorDetailsArea">
                    <h6>Enter New Vendor Details</h6>
                    <div className="CommonModalArea">
                      <div className="form-group">
                      
                      </div>
                    </div>
                    <h6>Company General Information</h6>
                  </div>
                  <div className="form-group">
                    <h6>
                      Vendor Name <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert Vendor Name"
                      name="vendorName"
                      value={vendorName}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.vendorNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput "
                      countryCodeEditable={false}
                      enableSearch
                     
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                  <div className="form-group">
                    <h6>Phone</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's Phone Number"
                      name="phone"
                      value={phone}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.phoneError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's main email address"
                      name="email"
                      value={email}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.emailError}
                    </span>
                  </div>

                  <div className="SelectAnOptionArea">
                    <h6>Company Main Address</h6>
                  </div>
                  <div className="form-group">
                    <h6>
                      Street Address<span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's street address"
                      name="addressLine1"
                      value={addressLine1}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.addressLine1Error}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Street Address Line 2</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's street address line 2 if applicable"
                      name="addressLine2"
                      value={addressLine2}
                      onChange={handleInput}
                    />
                   
                  </div>
                  <div className="form-group">
                      <h6>Zip/Postal Code</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert zip/postal code"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleInput}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.zipCodeError}
                      </span>
                      </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Country<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="country"
                          value={country}
                          onChange={handleInput}
                        >
                          <option>Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.countryError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Province<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="province"
                          value={province}
                          onChange={handleInput}
                        >
                          <option>Select Province</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {stateData?.states?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.provinceError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        City<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select name="city" value={city} onChange={handleInput}>
                          <option>Select City</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {cityData?.cities?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors?.cityError}
                      </span>
                    
                       
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Type</option>
                          {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.vendorTypeError}
                    </span>
                  </div>

                  {/* <div className="form-group">
                    <div className="Categories Categories3 mb-4">
                      <h6>
                        State/Province<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all3">
                        <span>Select a Vendor Type</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input type="text" placeholder="-Search" />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        <li className="CursorPointer">
                          - List of states /provinces loaded from the system
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="CommonModalAreaMain">
                    {/* <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Add Additional Address</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#AdditionalAddressCreation"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/share.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Add Additional
                            Address
                          </h5>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Contact List</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                             
                          >
                            <figure>
                              <img src="images/access-control.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Contact List
                          </h5>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="CommonModalAreaMain">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Preferred Cost Codes List</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            data-toggle="modal"
                            data-target="#VendorPreferredCostCodesList"
                            data-dismiss="modal"
                          >
                            <figure>
                              <img src="images/requests.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Preferred Cost
                            Codes List
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
               
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleEditVendorModal1Close}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={EditVendorFun}>
                      Update Vendor
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* SYSTEMVENDORMODAL */}
      <Modal
        show={SystemVendorModal}
        onHide={handleSystemVendorModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleSystemVendorModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Vendor List</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Vendor Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorType"
                          value={vendorType}
                          onChange={handleInput}
                        >
                          <option value="">Select a Vendor Type</option>
                          {checkArray(data1) ? (
                            <>
                              {data1?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="-Search"
                            onChange={(e) => onChangeHandler(e)}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of vendor types loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorTypeError}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="vendorName"
                          value={vendorName}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.vendorName}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {systemErrors?.vendorNameError}
                      </span>
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleSystemVendorModalClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={handleSystemVendor}>
                      Confirm Selection
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*ADD ADDRESSMODAL */}
      <Modal
        show={addAddressModal}
        onHide={handleAddAddressModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleAddAddressModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Additional Address Creation</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                  <div className="form-group">
                    <h6>Address Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's address name"
                      name="extraaddressName"
                      value={extraaddressName}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {extraaddressError?.addressNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>
                      Street Address<span className="required"> *</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's street address"
                      name="extraaddressLine1"
                      value={extraaddressLine1}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {extraaddressError?.addressLine1Error}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Street Address Line 2</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert company's street address line 2 if applicable"
                      name="extraaddressLine2"
                      value={extraaddressLine2}
                      onChange={handleInput}
                    />
                  
                  </div>

                  <div className="form-group">
                    <h6>
                      Zip / Postal Code<span className="required"> *</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert zip code"
                      name="extrazipCode"
                      value={extrazipCode}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {extraaddressError?.zipCodeError}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Country<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="extracountry"
                          value={extracountry}
                          onChange={handleInput}
                        >
                          <option>Select Country</option>
                          {checkArray(countryData?.countries) ? (
                            <>
                              {countryData?.countries?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {extraaddressError?.countryError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Province<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="extraprovince"
                          value={extraprovince}
                          onChange={handleInput}
                        >
                          <option>Select Province</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {stateData?.states?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {extraaddressError?.provinceError}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        City<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="extracity"
                          value={extracity}
                          onChange={handleInput}
                        >
                          <option>Select City</option>
                          {checkArray(stateData?.states) ? (
                            <>
                              {cityData?.cities?.map((ele, i) => {
                                return (
                                  <option value={ele?.name}>{ele?.name}</option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {extraaddressError?.cityError}
                      </span>
                      {/* <ul>
                          <li>
                            <input type="text" placeholder="Search" />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                          <li className="CursorPointer">
                            - List of countries loaded from the system
                          </li>
                        </ul> */}
                    </div>
                  </div>

                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleAddAddressModalClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={addAddressFun}>
                      Add Address
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADD CONTACTMODAL */}
      <Modal
        show={addContactModal}
        onHide={handleAddContactVendorModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleAddContactVendorModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Create Contact</h4>
                </div>
                <div className="Tableheader2">
                  {/* <h6 className="mb-0">
                <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot Drywall
              </h6> */}
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>First Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's first name"
                      name="firstName"
                      value={firstName}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.firstNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Last Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's last name"
                      name="lastName"
                      value={lastName}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.lastNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Title</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's title"
                      name="title"
                      value={title}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.titleError}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="contactvendorType"
                          value={contactvendorType}
                          onChange={handleInput}
                        >
                          <option>Select VendorType</option>
                          <option value="Primary">Primary</option>
                          <option value="Sales">Select VendorType</option>
                          <option value="Design">Design</option>
                          <option value="Estimation">Estimation</option>
                          <option value="Purchasing">Purchasing</option>
                          <option value="Construction">Construction</option>
                          <option value="Warranty">Warranty</option>
                        
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {contactError?.contactvendorTypeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Primary
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Sales
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Design
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Estimation
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Purchasing
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Construction
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Warranty
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's email"
                      name="contactemail"
                      value={contactemail}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.contactemailError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput "
                      countryCodeEditable={false}
                      enableSearch
                     
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                  <div className="form-group">
                    <h6>Office Phone</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's office phone"
                      name="officePhone"
                      value={officePhone}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.officePhoneError}
                    </span>
                  </div>
                  
                  <div className="form-group">
                    <h6>Cell Phone Code</h6>
                    <PhoneInput inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={cellPhoneCode}
                      onChange={handleCellPhoneInputChange}
                    />
                                 
                  </div>
                  <div className="form-group">
                    <h6>Cell Phone</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's office phone"
                      name="cellPhone"
                      value={cellPhone}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.cellPhoneError}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Address</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="address"
                          value={address}
                          onChange={handleInput}
                        >
                          <option>Select Address</option>
                          <option value="Calgary">Calgary</option>
                          <option value="Ottawa">Ottawa</option>
                          <option value="Toronto">Toronto</option>

                          {checkArray(addressListData?.itemList) ? (
                            <>
                              {addressListData?.itemList?.map((ele, i) => {
                                return (
                                  <option value={ele?.addressName}>
                                    {ele?.addressName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {contactError?.addressError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleAddContactVendorModalClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={handleCreateContactFun}>
                      Create Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDIT CONTACTMODAL */}

      <Modal
        show={editContactModal}
        onHide={handleEditContactVendorModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleEditContactVendorModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Update Contact</h4>
                </div>
                <div className="Tableheader2">
                  {/* <h6 className="mb-0">
                <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot Drywall
              </h6> */}
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>First Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's first name"
                      name="firstName"
                      value={firstName}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.firstNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Last Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's last name"
                      name="lastName"
                      value={lastName}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.lastNameError}
                    </span>
                  </div>
                  <div className="form-group">
                    <h6>Title</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's title"
                      name="title"
                      value={title}
                      onChange={handleInput}
                    />
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {contactError?.titleError}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories1 mb-4">
                      <h6>VendorType</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="contactvendorType"
                          value={contactvendorType}
                          onChange={handleInput}
                        >
                          <option>Select VendorType</option>
                          {checkArray(vendorTypesData?.vendorType) ? (
                            <>
                              {vendorTypesData?.vendorType?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {contactError?.contactvendorTypeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Primary
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Sales
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Design
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Estimation
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Purchasing
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Construction
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                      <li className="CursorPointer">
                        <label className="CheckBox">
                          {" "}
                          Warranty
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </li>
                    </ul> */}
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's email"
                      name="contactemail"
                      value={contactemail}
                      onChange={handleInput}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.contactemailError}
                    </span>
                  </div>
                  
                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                  <div className="form-group">
                    <h6>Office Phone</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's office phone"
                      name="officePhone"
                      value={officePhone}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.officePhoneError}
                    </span>
                  </div>
                  
                  <div className="form-group">
                    <h6>Country Code</h6>
                    <PhoneInput inputClass="CountryCodeInput"
                      countryCodeEditable={false}
                      enableSearch
                      value={phonecode}
                      onChange={handlePhoneInputChange}
                    />
                                 
                  </div>
                  <div className="form-group">
                    <h6>Cell Phone</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert contact's office phone"
                      name="cellPhone"
                      value={cellPhone}
                      onChange={handleInputNumber}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {contactError?.cellPhoneError}
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Address</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="address"
                          value={address}
                          onChange={handleInput}
                        >
                          <option>Select Address</option>

                          {checkArray(addressListData?.itemList) ? (
                            <>
                              {addressListData?.itemList?.map((ele, i) => {
                                return (
                                  <option value={ele?.addressName}>
                                    {ele?.addressName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {contactError?.addressError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>
                  <div className="Button mt-5 d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handleEditContactVendorModalClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={handleUpdateContactFun}>
                      Update Contact
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CONTACTLISTMODAL */}
      <Modal
        show={ContactListModal}
        onHide={handleContactListModalClose}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleContactListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Contact List</h4>
                </div>
                <div className="Tableheader2">
                  {/* <h6 className="mb-0">
                <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot Drywall
              </h6> */}
                  <div className="VendorCoastCode">
                    {/* <h6
                      className="mb-0 CursorPointer"
                      // onClick={handleAddContactListVendorModalShow}
                    >
                      <img src="images/add-contact.png" />
                      &nbsp;Add Contact 
                    </h6>*/}
                  </div>
                  <div className="NewActions ml-3">
                    <a href="">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                filename="Contact List"
                sheet="Contacts"
                currentTableRef={contactListRef.current}
              >
                <button href="jacascript:void(0);">
                  <img src="images/download.png" />
                </button>
              </DownloadTableExcel>
                    {/* <a href="">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
                <div className="TableList CommonHeading TableHeightScroll">
                  <table ref={contactListRef}>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Office Number</th>
                        <th>Cell Phone</th>
                        <th>Address</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(contactListData?.itemList) ? (
                        contactListData?.itemList?.map((ele, i) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.firstName}</td>
                                <td>{ele?.lastName}</td>
                                <td>{ele?.title}</td>
                                <td>{ele?.email}</td>
                                <td>{ele?.officePhone}</td>
                                <td>{ele?.cellPhone}</td>
                                <td>{ele?.address}</td>
                                <td>
                                  <div className="DetailsDots">
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  handleEditContactVendorModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Contact
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deleteContactFun(ele?.id)
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Contact
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <NoDataFound />
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={prefferedCostModal}
        onHide={handlePrefferedCostClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handlePrefferedCostClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Set Preferred Cost Code's Vendor</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Cost Category</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="costCategory"
                          defaultValue={costCategory}
                          onChange={handleInput}
                        >
                          <option>Select Cost Category</option>
                          {checkArray(getAllCostCategoryListData?.data) ? (
                              <>
                                {getAllCostCategoryListData?.data?.map((ele, i) => {
                                  return <option value={ele?.id}>{ele?.name}</option>;
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.costCodeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Cost Code</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="costCode"
                          value={costCode}
                          onChange={handleInput}
                        >
                          <option>Select Cost Code</option>
                          {checkArray(getAllCostCodeListData?.data) ? (
                              <>
                                {getAllCostCodeListData?.data?.map((ele, i) => {
                                  return <option value={ele?.id}>{ele?.name}</option>;
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.costCodeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="preferredVendorName"
                          value={preferredVendorName}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.vendorName}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.vendorNameError}
                      </span>
                    </div>
                  </div>

                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handlePrefferedCostClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={addPrefferedCostFun}>
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================PREFEEREDMODAL */}

      <Modal
        show={prefferedEditCostModal}
        onHide={handlePrefferedEditCostClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handlePrefferedEditCostClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Update Preferred Cost Code's Vendor</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Cost Code</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="costCode"
                          value={costCode}
                          onChange={handleInput}
                        >
                          <option>Select Cost Code</option>
                          {checkArray(vendorTypesData?.vendorType) ? (
                            <>
                              {vendorTypesData?.vendorType?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.costCodeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Cost Type</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="costType"
                          value={costType}
                          onChange={handleInput}
                        >
                          <option>Select Cost Type</option>
                          {checkArray(vendorTypesData?.vendorType) ? (
                            <>
                              {vendorTypesData?.vendorType?.map((ele, i) => {
                                return <option value={ele}>{ele}</option>;
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.costTypeError}
                      </span>
                      {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Vendor Name</h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="preferredVendorName"
                          value={preferredVendorName}
                          onChange={handleInput}
                        >
                          <option>Select a Vendor Name</option>
                          {checkArray(vendorSearchData) ? (
                            <>
                              {vendorSearchData?.map((ele, i) => {
                                return (
                                  <option value={ele?.vendorName}>
                                    {ele.vendorName}
                                  </option>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleInputSearchCity}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                      </ul>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {prefferedCostError?.vendorNameError}
                      </span>
                    </div>
                  </div>

                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Outline"
                      onClick={handlePrefferedEditCostClose}
                    >
                      Cancel
                    </button>
                    <button className="Create" onClick={updatePrefferedCostFun}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={setVendorPrefferedCostModal}
        onHide={handleSetVendorPrefferedCostClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                onClick={handleSetVendorPrefferedCostClose}
                className="CloseModal"
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Set Vendor as Preferred to Cost Code</h4>
                </div>
                <div className="FolderForm">
                  {/* <form> */}
                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>Vendor Name</h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="vendorName"
                            value={insertingCostCodes?.vendorName}
                            onChange={handleInputChange}
                          >
                            <option>Select a Vendor Name</option>
                            {checkArray(vendorSearchData) ? (
                              <>
                                {vendorSearchData?.map((ele, i) => {
                                  return (
                                    <option value={ele?.vendorName}>
                                      {ele.vendorName}
                                    </option>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <ul>
                          <li>
                            <input
                              type="text"
                              placeholder="Search"
                              onChange={handleInputSearchCity}
                            />
                            <span className="Search">
                              <img src="images/search.png" />
                            </span>
                          </li>
                         
                        </ul>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {setPrefferedError?.vendorNameError}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>Cost Category</h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="costCategory"
                            value={costCategory}
                            onChange={handleInputChange}
                          >
                            <option>Select Cost Category</option>
                            {checkArray(getAllCostCategoryListData?.data) ? (
                              <>
                                {getAllCostCategoryListData?.data?.map((ele, i) => {
                                  return <option value={ele?.id}>{ele?.name}</option>;
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {setPrefferedError?.costCategoryError}
                        </span>
                        {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="Categories Categories2 mb-4">
                        <h6>Cost Code</h6>
                        <div className="Categories_all Categories_all2">
                          <select
                            name="costCode"
                            value={costCode}
                            onChange={handleInputChange}
                          >
                            <option>Select Cost Code</option>
                            {checkArray(vendorTypesData?.vendorType) ? (
                              <>
                                {vendorTypesData?.vendorType?.map((ele, i) => {
                                  return <option value={ele}>{ele}</option>;
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </select>
                          <img src="images/down.png" />
                        </div>
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {setPrefferedError?.costCodeError}
                        </span>
                        {/* <ul>
                      <li className="CursorPointer">
                        - List of address registered for company
                      </li>
                    </ul> */}
                      </div>
                    </div>
                    <div className="Button my-4">
                      <button className="Create" onClick={addPrefferedDataList}>
                        Add
                      </button>
                    </div>
                    <div className="form-group">
                      <h6>Selected Cost Codes:</h6>
                      {checkArray(costCodes) && costCodes?.map((ele, index) => {
                        return (
                          <div className="FilesAdded">
                          <label>
                          {ele?.costCategory} &amp; {ele?.costCode}
                          </label>
                          <span onClick={() =>removeCostCode(index)} className="cross">
                            <img src="images/Cross.png" />
                          </span>
                        </div>
                        )
                      })}
                       </div>
                    
                       <div class="Button mt-4 d-flex justify-content-between">
              <button class="Outline" onClick={handleSetVendorPrefferedCostClose}>Cancel</button>
              <button class="Create" disabled={costCodes?.length == 0} onClick={setPrefferedCostFun}>Assign</button>
            </div>
                  
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={setVendorPrefferedCostListModal}
        onHide={handleSetVendorPrefferedCostListClose}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                href="javascript:void(0);"
                className="CloseModal"
                onClick={handleSetVendorPrefferedCostListClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Vendor’s Preferred Cost Codes List</h4>
                </div>
                <div className="Tableheader2 flex-md-row flex-column align-items-md-center align-items-start">
                  {/* <h6 className="mb-0">
                    <span>Vendor:</span> 1049623 Alberta Ltd. o/a Bigfoot
                    Drywall
                  </h6> */}
                  <div className="VendorCoastCode">
                    <h6
                      className="mb-0 CursorPointer"
                      onClick={() =>handleAddPrefferedCostShow()}
                    >
                      {/* <img src="images/setting.png" /> */}
                      &nbsp;Set vendor to new coast code
                    </h6>
                  </div>
                  <div className="NewActions ml-3">
                    <a href="">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="">
                      <img src="images/icon-53.png" />
                    </a>
                    <DownloadTableExcel
                filename="Contact List"
                sheet="Contacts"
                currentTableRef={prefeeredVendorRef .current}
              >
                <button href="jacascript:void(0);">
                  <img src="images/download.png" />
                </button>
              </DownloadTableExcel>
                    {/* <a href="">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
                <div className="TableList CommonHeading TableHeightScroll">
                  <table ref={prefeeredVendorRef}>
                    <thead>
                      <tr>
                        <th>Cost Code</th>
                        <th>Cost Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(prefferedListData?.itemList) ? (
                        prefferedListData?.itemList?.map((ele, i) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.costCode}</td>
                                <td>{ele?.costType}</td>
                                <td>
                                  <div className="DetailsDots">
                                    <div className="TableThreeDots">
                                      <ul className="">
                                        <li className="dropdown">
                                          <a
                                            className=""
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  handlePrefferedEditCostShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Cost code preference
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deletePrefferedCostFun(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Remove Vendor
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <NoDataFound />
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Modal
        show={AssignMemberModal}
        onHide={handleAssignMemberModalClose}
        className="ModalBox modal fade ExtraSmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className=" closebtn CloseModal"
            onClick={handleAssignMemberModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ContactTopBox">
              <div className="SalesPersonarea">
                <span>{memberDetails?.vendorName?.charAt(0) +memberDetails?.vendorName?.charAt(1) }</span>

            <h6>{memberDetails?.vendorName}</h6> 
              </div>
            </div>
            <div className="FolderForm">
              <div className="SendEmailText">
                <p>
                  <img src="images/send-email.png" />
                  Send E-mail
                </p>
                <p className="SendText SendText1">
                  <img src="images/send-text.png" />
                  Send Text
                </p>
                <div
                  className="SendTextSearch SendTextSearch1"
                  style={{ display: "none" }}
                >
                  <input type="text" />
                  <button>Apply</button>
                </div>
              </div>
              <div className="MolalContactForm mb-4">
                <h6>Contact</h6>
                <p>
                  <img src="images/send-email.png" />
                  {memberDetails?.email}
                </p>
                <p className="mb-4">
                  <img src="images/send-text.png" />
                  {memberDetails?.phone??"N/A"}
                </p>
                {/* <a href="javascript:void(0);" className="ShowMoreBtn">
                  Show more
                </a>
                <a href="javascript:void(0);" className="ShowLessBtn">
                  Show less
                </a> */}
              </div>
              <div className="form-group mt-3">
                <div className="ContactModalAddressArea">
                  <h6>Address</h6>
                  <p>
                    {memberDetails?.addressLine1 ?? "N/A"}{" "}
                    <br />
                    {memberDetails?.addressLine2 ??
                      "N/A"}
                    <img src="images/location.png" />
                  </p>
                </div>
              </div>
              <div className="DiscussionHide">
                <div className="form-group mb-0">
                  <h6>Discussion</h6>
                  <input
                    type="text"
                    placeholder="Write here ....."
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="TableList TableListHeader NewTableList TableHeightScroll">
              <table style={{display:"none"}}  id="my-table-id">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    {/* <th>Primary Contact’s Name</th> */}
                    <th>Primary Email</th>
                    <th>Primary Phone No.</th>
                    <th>Vendor City</th>
                    <th>Preferred</th>
                    <th>Contact List</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(vendorListData?.VendorList) ? (
                        vendorListData?.VendorList?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div onClick={() =>handleAssignMemberModalShow(ele)}  className="TextGreen CursorPointer">  {ele?.vendorName}</div>
                                 </td>
                                <td>{ele?.vendorType}</td>
                                <td>{ele?.email}</td>
                                <td>{ele?.phone}</td>
                                <td>{ele?.city}</td>
                                {/* <td>{ele?.phone}</td> */}
                                <td>
                                  <label className="CheckBox mb-0">
                                    {" "}
                                    <span
                                      onClick={() =>
                                        handleSetVendorPrefferedCostShow(
                                          ele?.id
                                        )
                                      }
                                      className="Count"
                                    >
                                      {ele?.preferredCostCodeCount}
                                    </span>
                                    {/* <input type="checkbox" />
                                    <span className="checkmark" /> */}
                                  </label>
                                </td>
                                <td>
                                  <div className="ContactListHover">   {ele?.contactCount}</div>
                                </td>
                                {/* <td>
                                  <div className="TableThreeDots">
                                    <div class="TableThreeDots">
                                      <ul class="">
                                        <li class="dropdown">
                                          <a
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol class="dropdown-menu dropdown-menuwidth2">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleAddContactVendorModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/contact-list.png" />
                                                Add contact
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleContactListModalShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/contact-list.png" />
                                                Contact List
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deleteVendorFun(ele?.id)
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Vendor
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleEditVendorModal1Show(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Vendor
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleSetVendorPrefferedCostListShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/preferred-list.png" />
                                                Preferred List
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  handlePrefferedCostShow(
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/request_vendor.png" />
                                                Set Cost Code Preferred vendors
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#RequestVendorContacts"
                                              >
                                                <img src="images/request_vendor.png" />
                                                Request Vendor Contacts
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </td> */}
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
    </>
  );
};

export default IndivisualComponentsPartVendorList;
