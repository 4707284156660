import { projectProformaAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//---------------------------------proformaList----------------------------------------------------
export const proformaListInitiate = () => ({
    type: projectProformaAction.PROFORMA_LIST_INITIATE,
  });
  
  export const proformaListSuccess = (data) => ({
    type: projectProformaAction.PROFORMA_LIST_SUCCESS,
    payload: data,
  });
  
  export const proformaListFailure = (data) => ({
    type: projectProformaAction.PROFORMA_LIST_FAILURE,
    payload: data,
  });
  
  export const getProformaList = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(proformaListInitiate(payload));
        const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(proformaListSuccess(response.data));
        } else {
          dispatch(proformaListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(proformaListFailure(err));
        return err.response
      }
    };
//===========================active-inactive-proforma=================================

export const activeInactiveProformaInitiate = () => ({
    type: projectProformaAction.ACTIVE_INACTIVE_PROFORMA_INITIATE,
  });
  
  export const activeInactiveProformaSuccess = (data) => ({
    type: projectProformaAction.ACTIVE_INACTIVE_PROFORMA_SUCCESS,
    payload: data,
  });
  
  export const activeInactiveProformaFailure = (data) => ({
    type: projectProformaAction.ACTIVE_INACTIVE_PROFORMA_FAILURE,
    payload: data,
  });
  
  export const getActiveInactiveProforma = (proformaId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(activeInactiveProformaInitiate(payload));
        const response = await axios.patch(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/activate`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(activeInactiveProformaSuccess(response.data));
        } else {
          dispatch(activeInactiveProformaFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(activeInactiveProformaFailure(err));
        return err.response
      }
    };
//=======================================create-project-jobs============================

export const createProformaProjectAndJobsInitiate = () => ({
    type: projectProformaAction.CREATED_PROFORMA_PROJECT_AND_JOBS_INITIATE,
  });
  
  export const createProformaProjectAndJobsSuccess = (data) => ({
    type: projectProformaAction.CREATED_PROFORMA_PROJECT_AND_JOBS_SUCCESS,
    payload: data,
  });
  
  export const createProformaProjectAndJobsFailure = (data) => ({
    type: projectProformaAction.CREATED_PROFORMA_PROJECT_AND_JOBS_FAILURE,
    payload: data,
  });
  
  export const getCreateProformaProjectAndJobs= (proformaId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createProformaProjectAndJobsInitiate(payload));
        const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectJobs`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(createProformaProjectAndJobsSuccess(response.data));
        } else {
          dispatch(createProformaProjectAndJobsFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createProformaProjectAndJobsFailure(err));
        return err.response
      }
    };

//============================getProformaUnitPricing======================================

export const getProformaUnitPriceInitiate = () => ({
  type: projectProformaAction.GET_PROFORMA_UNIT_PRICE_INITIATE,
});

export const getProformaUnitPriceSuccess = (data) => ({
  type: projectProformaAction.GET_PROFORMA_UNIT_PRICE_SUCCESS,
  payload: data,
});

export const getProformaUnitPriceFailure = (data) => ({
  type: projectProformaAction.GET_PROFORMA_UNIT_PRICE_FAILURE,
  payload: data,
});

export const getProformaUnitPrice = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaUnitPriceInitiate(proformaId));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unit`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaUnitPriceSuccess(response.data));
      } else {
        dispatch(getProformaUnitPriceFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaUnitPriceFailure(err));
      return err.response
    }
  };

//======================================get-proforma-history=========================


export const getProformaHistoryInitiate = () => ({
  type: projectProformaAction.GET_PROFORMA_HISTORY_INITIATE,
});

export const getProformaHistorySuccess = (data) => ({
  type: projectProformaAction.GET_PROFORMA_HISTORY_SUCCESS,
  payload: data,
});

export const getProformaHistoryFailure = (data) => ({
  type: projectProformaAction.GET_PROFORMA_HISTORY_FAILURE,
  payload: data,
});

export const getProformaHistory = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaHistoryInitiate(proformaId));
      const response = await axios.get(`${Url}events?entityType=Proforma&entityId=${proformaId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaHistorySuccess(response.data));
      } else {
        dispatch(getProformaHistoryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaHistoryFailure(err));
      return err.response
    }
  };

//=================================================================GET-PERFORMA-financial--report========================



export const getProformaFinancialReportInitiate = () => ({
  type: projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_INITIATE,
});

export const getProformaFinancialReportSuccess = (data) => ({
  type: projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_SUCCESS,
  payload: data,
});

export const getProformaFinancialReportFailure = (data) => ({
  type: projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_FAILURE,
  payload: data,
});

export const getProformaFinancialReport = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProformaFinancialReportInitiate(proformaId));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/financialReport`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProformaFinancialReportSuccess(response.data));
      } else {
        dispatch(getProformaFinancialReportFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProformaFinancialReportFailure(err));
      return err.response
    }
  };

//===============================================GET-pERPROMA REPORT--------------------

export const performaReportInitiate = () => ({
  type: projectProformaAction.PERFORMA_REPORT_INITIATE,
});

export const performaReportSuccess = (data) => ({
  type: projectProformaAction.PERFORMA_REPORT_SUCCESS,
  payload: data,
});

export const performaReportFailure = (data) => ({
  type: projectProformaAction.PERFORMA_REPORT_FAILURE,
  payload: data,
});

export const getPerformaReport = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(performaReportInitiate(proformaId));
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/report`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(performaReportSuccess(response.data));
      } else {
        dispatch(performaReportFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(performaReportFailure(err));
      return err.response
    }
  };


//==================================PERforma-report-mark-up========================================


export const performaReportMarkUpInitiate = () => ({
  type: projectProformaAction.PERFORMA_REPORT_MARK_UP_INITIATE,
});

export const performaReportMarkUpSuccess = (data) => ({
  type: projectProformaAction.PERFORMA_REPORT_MARK_UP_SUCCESS,
  payload: data,
});

export const performaReportMarkUpFailure = (data) => ({
  type: projectProformaAction.PERFORMA_REPORT_MARK_UP_FAILURE,
  payload: data,
});

export const performaReportMarkUp = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(performaReportMarkUpInitiate(proformaId),payload);
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}?currentStep=1`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(performaReportMarkUpSuccess(response.data));
      } else {
        dispatch(performaReportMarkUpFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(performaReportMarkUpFailure(err));
      return err.response
    }
  };

//================================get--affordable-rental- revneue========================


export const getAffordableRentalRevenueInitiate = () => ({
  type: projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_INITIATE,
});

export const getAffordableRentalRevenueSuccess = (data) => ({
  type: projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_SUCCESS,
  payload: data,
});

export const getAffordableRentalRevenueFailure = (data) => ({
  type: projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_FAILURE,
  payload: data,
});

export const getAffordableRentalRevenue = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAffordableRentalRevenueInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/totalAffordableRentalRevenues`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAffordableRentalRevenueSuccess(response.data));
      } else {
        dispatch(getAffordableRentalRevenueFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAffordableRentalRevenueFailure(err));
      return err.response
    }
  };

//=============================================get-project-rental-revenue=========================


  export const projectAnnualAffordableRentalRevenueInitiate = () => ({
    type: projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_INITIATE,
  });
  
  export const projectAnnualAffordableRentalRevenueSuccess = (data) => ({
    type: projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_SUCCESS,
    payload: data,
  });
  
  export const projectAnnualAffordableRentalRevenueFailure = (data) => ({
    type: projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_FAILURE,
    payload: data,
  });
  
  export const getProjectAnnualAffordableRentalRevenue = (proformaId) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(projectAnnualAffordableRentalRevenueInitiate(proformaId))
        const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectAnnualAffordableRentalRevenue`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 201) {
          dispatch(projectAnnualAffordableRentalRevenueSuccess(response.data));
        } else {
          dispatch(projectAnnualAffordableRentalRevenueFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(projectAnnualAffordableRentalRevenueFailure(err));
        return err.response
      }
    };

//=============================================get-project-sales-revenue=======================================

export const getProjectSalesRevenueInitiate = () => ({
  type: projectProformaAction.GET_PROJECT_SALES_REVENUE_INITIATE,
});

export const getProjectSalesRevenueSuccess = (data) => ({
  type: projectProformaAction.GET_PROJECT_SALES_REVENUE_SUCCESS,
  payload: data,
});

export const getProjectSalesRevenueFailure = (data) => ({
  type: projectProformaAction.GET_PROJECT_SALES_REVENUE_FAILURE,
  payload: data,
});

export const getProjectSalesRevenue = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProjectSalesRevenueInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectSalesRevenue`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getProjectSalesRevenueSuccess(response.data));
      } else {
        dispatch(getProjectSalesRevenueFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProjectSalesRevenueFailure(err));
      return err.response
    }
  };

//=====================================PROJECT-sales-revenue===================================

export const projectSalesRevenueInitiate = () => ({
  type: projectProformaAction.PROJECT_SALES_REVENUE_INITIATE,
});

export const projectSalesRevenueSuccess = (data) => ({
  type: projectProformaAction.PROJECT_SALES_REVENUE_SUCCESS,
  payload: data,
});

export const projectSalesRevenueFailure = (data) => ({
  type: projectProformaAction.PROJECT_SALES_REVENUE_FAILURE,
  payload: data,
});

export const projectSalesRevenue = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(projectSalesRevenueInitiate(proformaId,payload))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectSalesRevenue`, payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(projectSalesRevenueSuccess(response.data));
      } else {
        dispatch(projectSalesRevenueFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(projectSalesRevenueFailure(err));
      return err.response
    }
  };

//========================UNIT--COST-ANALYSISIS====================================


export const getUnitCostAnalysisInitiate = () => ({
  type: projectProformaAction.GET_UNIT_COST_ANALYSIS_INITIATE,
});

export const getUnitCostAnalysisSuccess = (data) => ({
  type: projectProformaAction.GET_UNIT_COST_ANALYSIS_SUCCESS,
  payload: data,
});

export const getUnitCostAnalysisFailure = (data) => ({
  type: projectProformaAction.GET_UNIT_COST_ANALYSIS_FAILURE,
  payload: data,
});

export const getUnitCostAnalysis = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitCostAnalysisInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitProjectAnalysis`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitCostAnalysisSuccess(response.data));
      } else {
        dispatch(getUnitCostAnalysisFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitCostAnalysisFailure(err));
      return err.response
    }
  };

//==========================get-gross-margin===========================
export const getGrossMarginPerunitInitiate = () => ({
  type: projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_INITIATE,
});

export const getGrossMarginPerunitSuccess = (data) => ({
  type: projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_SUCCESS,
  payload: data,
});

export const getGrossMarginPerunitFailure = (data) => ({
  type: projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_FAILURE,
  payload: data,
});

export const getGrossMarginPerunit = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getGrossMarginPerunitInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/grossMarginPerUnit`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getGrossMarginPerunitSuccess(response.data));
      } else {
        dispatch(getGrossMarginPerunitFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getGrossMarginPerunitFailure(err));
      return err.response
    }
  };
//==================================CREATE-UNIT-TYPES======================

export const createUnitTypesInitiate = () => ({
  type: projectProformaAction.CREATE_UNIT_TYPES_INITIATE,
});

export const createUnitTypesSuccess = (data) => ({
  type: projectProformaAction.CREATE_UNIT_TYPES_SUCCESS,
  payload: data,
});

export const createUnitTypesFailure = (data) => ({
  type: projectProformaAction.CREATE_UNIT_TYPES_FAILURE,
  payload: data,
});

export const createUnitTypes = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createUnitTypesInitiate(proformaId,payload))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitTypes`,payload,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createUnitTypesSuccess(response.data));
      } else {
        dispatch(createUnitTypesFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createUnitTypesFailure(err));
      return err.response
    }
  };

//================================================GET---UNIT--RENTAL ----TABLE===============
export const getUnitRentalTableInitiate = () => ({
  type: projectProformaAction.GET_UNIT_RENTAL_TABLE_INITIATE,
});

export const getUnitRentalTableSuccess = (data) => ({
  type: projectProformaAction.GET_UNIT_RENTAL_TABLE_SUCCESS,
  payload: data,
});

export const getUnitRentalTableFailure = (data) => ({
  type: projectProformaAction.GET_UNIT_RENTAL_TABLE_FAILURE,
  payload: data,
});

export const getUnitRentalTable = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitRentalTableInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitRentalTable`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitRentalTableSuccess(response.data));
      } else {
        dispatch(getUnitRentalTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitRentalTableFailure(err));
      return err.response
    }
  };
//==========================================UPDATE--UNIT---RENTAL================
export const updateUnitRentalTableInitiate = () => ({
  type: projectProformaAction.UPDATE_UNIT_RENTAL_TABLE_INITIATE,
});

export const updateUnitRentalTableSuccess = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_RENTAL_TABLE_SUCCESS,
  payload: data,
});

export const updateUnitRentalTableFailure = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_RENTAL_TABLE_FAILURE,
  payload: data,
});

export const updateUnitRental = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateUnitRentalTableInitiate(proformaId,payload))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitRentalTable`,payload.unitRentalTableData,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(updateUnitRentalTableSuccess(response.data));
      } else {
        dispatch(updateUnitRentalTableSuccess(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateUnitRentalTableSuccess(err));
      return err.response
    }
  };

//=========================================UNIT-monthly----rental--table============

export const getUnitMonthlyRentalTableInitiate = () => ({
  type: projectProformaAction.GET_UNIT_MONTHLY_RENTAL_TABLE_INITIATE,
});

export const getUnitMonthlyRentalTableSuccess = (data) => ({
  type: projectProformaAction.GET_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS,
  payload: data,
});

export const getUnitMonthlyRentalTableFailure = (data) => ({
  type: projectProformaAction.GET_UNIT_MONTHLY_RENTAL_TABLE_FAILURE,
  payload: data,
});

export const getUnitMonthlyRental = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitMonthlyRentalTableInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitMonthlyRentalTable`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitMonthlyRentalTableSuccess(response.data));
      } else {
        dispatch(getUnitMonthlyRentalTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitMonthlyRentalTableFailure(err));
      return err.response
    }
  };

//======================================update--monthly--rental---table===================================

export const updateUnitMonthlyRentalTableInitiate = () => ({
  type: projectProformaAction.UPDATE_UNIT_MONTHLY_RENTAL_TABLE_INITIATE,
});

export const updateUnitMonthlyRentalTableSuccess = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_MONTHLY_RENTAL_TABLE_SUCCESS,
  payload: data,
});

export const updateUnitMonthlyRentalTableFailure = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_MONTHLY_RENTAL_TABLE_FAILURE,
  payload: data,
});

export const updateUnitMonthlyRental = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateUnitMonthlyRentalTableInitiate(proformaId,payload))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitMonthlyRentalTable`,payload.unitMonthlyRentalTableData,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateUnitMonthlyRentalTableSuccess(response.data));
      } else {
        dispatch(updateUnitMonthlyRentalTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateUnitMonthlyRentalTableFailure(err));
      return err.response
    }
  };
//=========================================get-unitAffordableRevenueTable=================================

export const getUnitAffordableRevenueTableInitiate = () => ({
  type: projectProformaAction.GET_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE,
});

export const getUnitAffordableRevenueTableSuccess = (data) => ({
  type: projectProformaAction.GET_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS,
  payload: data,
});

export const getUnitAffordableRevenueTableFailure = (data) => ({
  type: projectProformaAction.GET_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE,
  payload: data,
});

export const getUnitAffordableRevenue = (proformaId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getUnitAffordableRevenueTableInitiate(proformaId))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitAffordableRevenueTable`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getUnitAffordableRevenueTableSuccess(response.data));
      } else {
        dispatch(getUnitAffordableRevenueTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getUnitAffordableRevenueTableFailure(err));
      return err.response
    }
  };

//==========================================================update----unitAffordableRevenueTable================================

export const updateUnitAffordableRevenueTableInitiate = () => ({
  type: projectProformaAction.UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_INITIATE,
});

export const updateUnitAffordableRevenueTableSuccess = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_SUCCESS,
  payload: data,
});

export const updateUnitAffordableRevenueTableFailure = (data) => ({
  type: projectProformaAction.UPDATE_UNIT_AFFORDABLE_REVENUE_TABLE_FAILURE,
  payload: data,
});

export const updateUnitAffordableRevenue = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateUnitAffordableRevenueTableInitiate(proformaId,payload))
      const response = await axios.post(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/unitAffordableRevenueTable`,payload.unitAffordableRevenueTableData,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateUnitAffordableRevenueTableSuccess(response.data));
      } else {
        dispatch(updateUnitAffordableRevenueTableFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateUnitAffordableRevenueTableFailure(err));
      return err.response
    }
  };

//=========================================TOTAL--regular---rental------revenue=================


export const getRegularRentalRevenueInitiate = () => ({
  type: projectProformaAction.GET_REGULAR_RENTAL_REVENUE_INITIATE,
});

export const getRegularRentalRevenueSuccess = (data) => ({
  type: projectProformaAction.GET_REGULAR_RENTAL_REVENUE_SUCCESS,
  payload: data,
});

export const getRegularRentalRevenueFailure = (data) => ({
  type: projectProformaAction.GET_REGULAR_RENTAL_REVENUE_FAILURE,
  payload: data,
});

export const getRegularRentalRevenue = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getRegularRentalRevenueInitiate(proformaId,payload))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/totalRegularRentalRevenues`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getRegularRentalRevenueSuccess(response.data));
      } else {
        dispatch(getRegularRentalRevenueFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getRegularRentalRevenueFailure(err));
      return err.response
    }
  };

//===============================================GET-PROJECT-annual---rental------------revenue==============

export const getProjectAnnualRentalRevenueInitiate = () => ({
  type: projectProformaAction.GET_PROJECT_ANNUAL_RENTAL_REVENUE_INITIATE,
});

export const getProjectAnnualRentalRevenueSuccess = (data) => ({
  type: projectProformaAction.GET_PROJECT_ANNUAL_RENTAL_REVENUE_SUCCESS,
  payload: data,
});

export const getProjectAnnualRentalRevenueFailure = (data) => ({
  type: projectProformaAction.GET_PROJECT_ANNUAL_RENTAL_REVENUE_FAILURE,
  payload: data,
});

export const getProjectAnnualRentalRevenue = (proformaId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getProjectAnnualRentalRevenueInitiate(proformaId,payload))
      const response = await axios.get(`${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${proformaId}/projectAnnualRentalRevenue`,{
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(getProjectAnnualRentalRevenueSuccess(response.data));
      } else {
        dispatch(getProjectAnnualRentalRevenueFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getProjectAnnualRentalRevenueFailure(err));
      return err.response
    }
  };

//================================delete-performa=====================

export const deletePerformaInitiate = () => ({
  type: projectProformaAction.DELETE_PERFORMA_INITIATE,
});

export const deletePerformaSuccess = (data) => ({
  type: projectProformaAction.DELETE_PERFORMA_SUCCESS,
  payload: data,
});

export const deletePerformaFailure = (data) => ({
  type: projectProformaAction.DELETE_PERFORMA_FAILURE,
  payload: data,
});

export const deletePerforma = (id) => async (dispatch) => {
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
   
    const response = await axios.delete(
      `${Url}company/${iBuildLocalData?.user?.CompanyId}/proforma/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status == 200) {
      dispatch(deletePerformaSuccess(response.data));
    } else {
      dispatch(deletePerformaFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(deletePerformaFailure(err));
    return err.response;
  }
};