import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMultiLevelStep1 } from "../../../redux/action/proformaAction";

const Input = ({
  noOfMultiParkade,
  handleMultiParkadeChange,
  handleMultiParkadeFieldChange,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [parkades, setParkades] = useState([]);
  useEffect(() => {
    dispatch(getMultiLevelStep1()).then((res) => {
      console.log({ res });
      setParkades(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading2 d-flex align-items-center mb-3">
        <h3 className="mb-0">Enter Number of levels in the Parkade (max 6)</h3>
        <input
          className="ModalBigheadingBox"
          type="number"
          min={1}
          max={6}
          value={noOfMultiParkade}
          onChange={handleMultiParkadeChange}
        />
      </div>
      <div className="EnterProjectTable" style={{ overflowX: "scroll" }}>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Level</th>
              <th>Foundation/Level Total Sq/Ft</th>
              <th>Foundation/Footings Total L in/Ft</th>
              <th>Footings &amp; Grade Beams Total Cubic/ Yards Concrete</th>
              <th>Walls Total Cubic/Yards Concrete</th>
              <th>Slab/Floor Total Cubic/Yards Concrete</th>
              <th>Parkade - Grade Level Covered Heated Parking</th>
            </tr>
          </thead>
          <tbody>
            {parkades?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>Level{index + 1}</td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert Sq/Ft"
                        value={item.footingTotalSqFt}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "footingTotalSqFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert Lin/Ft"
                        value={item.footingTotalLinFt}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "footingTotalLinFt"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert cubic/yards"
                        value={item.footingYardsConcrete}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "footingYardsConcrete"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert cubic/yards"
                        value={item.wallYardsConcrete}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "wallYardsConcrete"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        placeholder="Insert cubic/yards"
                        value={item.slabYardsConcrete}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "slabYardsConcrete"
                          )
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div className="CommonSelectBox width-250">
                      <select
                        value={item.heatedParking}
                        onChange={(e) =>
                          handleMultiParkadeFieldChange(
                            e,
                            index,
                            "heatedParking"
                          )
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="PaginationArea px-3">
          <h6>Showing 2 of 2 common garages</h6>
          <h6>
            <img src="images/leftarrow.png" /> <span>01</span>{" "}
            <img src="images/rightarrow.png" /> Out 10
          </h6>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("commonGarage")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("Quanties")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Input;
