import React, { useState } from "react";
import Input from "./input";
import Quantities from "./quantities";
import Preview from "./preview";

const MultiLevelParkades = ({
  multiLevelParkade,
  handleRadioChange,
  noOfMultiParkade,
  handleMultiParkadeChange,
  parkades,
  handleMultiParkadeFieldChange,
  MulitileveleParkadeQuantiesData,
  handleMultiLevelParkadeChange,
  multiLevelStep3List,
  handleShowComponent,
}) => {
  const [show, setShow] = useState({
    Input: true,
    Quanties: false,
    Preview: false,
  });

  const handleShowComponents = (component) => {
    setShow({
      Input: component === "Input",
      Quanties: component === "Quanties",
      Preview: component === "Preview",
    });
  };

  return (
    <div className="FolderFormCreateArea Proforma">
      <div className="StepBox Green">
        <ul>
          <li
            className={show.Input ? "active" : ""}
            onClick={() => handleShowComponents("Input")}
          >
            <span className="Icon">
              <img src={require("../../../assets/images/block_input.png")} />
            </span>
            <span className="Text">
              Multi-Level <br /> Parkade <br /> Input
            </span>
          </li>
          <li
            className={show.Quanties ? "active" : ""}
            onClick={() => handleShowComponents("Quanties")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaMultiLevelParkadeQuantities"
              data-dismiss="modal"
            >
              <img
                src={require("../../../assets/images/block_quantities.png")}
              />
            </span>
            <span className="Text">
              Multi-Level <br /> Parkade <br /> Quantities
            </span>
          </li>
          <li
            className={show.Preview ? "active" : ""}
            onClick={() => handleShowComponents("Preview")}
          >
            <span
              className="Icon FolderPermissionId"
              data-toggle="modal"
              data-target="#CreateProformaMultiLevelParkadeSummaryPreview"
              data-dismiss="modal"
            >
              <img src={require("../../../assets/images/block_summary.png")} />
            </span>
            <span className="Text">
              Multi-Level <br /> Parkade <br /> Summary <br /> Preview
            </span>
          </li>
        </ul>
      </div>
      <div className="FieldsMandatory">
        <h6>
          Fields with <span className="required">*</span> are mandatory
        </h6>
      </div>
      <div className="row mb-5">
        <div className="col-md-6">
          <div className="d-flex justify-content-between">
            <h6>Project will have Multi-Level Parkade ?</h6>
            <div className="form-group">
              <label className="Radio">
                Yes
                <input
                  type="radio"
                  name="Cuisine"
                  checked={multiLevelParkade} // Set checked based on state
                  onChange={() => handleRadioChange(true)} // Handle onChange event
                />
                <span className="checkmark" />
              </label>
            </div>
            <div className="form-group">
              <label className="Radio">
                No
                <input
                  type="radio"
                  name="Cuisine"
                  checked={!multiLevelParkade} // Set checked based on state
                  onChange={() => handleRadioChange(false)} // Handle onChange event
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
        </div>
      </div>
      {show.Input && (
        <Input
          noOfMultiParkade={noOfMultiParkade}
          handleMultiParkadeChange={handleMultiParkadeChange}
          parkades={parkades}
          handleMultiParkadeFieldChange={handleMultiParkadeFieldChange}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.Quanties && (
        <Quantities
          MulitileveleParkadeQuantiesData={MulitileveleParkadeQuantiesData}
          handleMultiLevelParkadeChange={handleMultiLevelParkadeChange}
          handleShowComponents={handleShowComponents}
        />
      )}
      {show.Preview && (
        <Preview
          multiLevelStep3List={multiLevelStep3List}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
        />
      )}
    </div>
  );
};

export default MultiLevelParkades;
