import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getAccountSystemPostingAction,
  getTransactionAccountAction,
  updateAccountSalesPostingAction,
  updateAccountSettingAction,
  updateAccountSettingPostingAction,
  updateSystemPostingAction,
  updateTransactionSettingAction,
} from "../../redux/action/adminAccountingAction";

const initialState = {
  dontUseSharingCosts: true,
  sendPOManuallyAccSys: false,
  allowSplitPOPayment: false,
  shareAdminProjectCosts: true,
  enableWarrantyJobAcc: true,
  enableProviCompleteJobAcc: true,
};

const AdminAccounting = ({ adminAcccountingStatus }) => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);

  const { transactionAccountSetting, accountPosting } = useSelector(
    (state) => state.adminAccountingReducer
  );

  const [SalePosting, setSalePosting] = useState([]);

  const [SystemPosting, setSystemPosting] = useState([]);

  const [AccountPosting, setAccountPosting] = useState([]);
  const [editTransactionSetting, setEditTransactionSetting] = useState(false);
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const [editAccountPositionSetting, setEditAccountPositionSetting] =
    useState(false);
  const [editAccountSoftwareSetting, setEditAccountSoftwareSetting] =
    useState(false);

  useEffect(() => {
    dispatch(getTransactionAccountAction());
    dispatch(getAccountSystemPostingAction());
  }, [adminAcccountingStatus]);

  useEffect(() => {
    let tempSystem = accountPosting?.filter((item) => item?.type == "System");

    let tempSale = accountPosting?.filter((item) => item?.type == "Sales");

    let tempAccount = accountPosting?.filter(
      (item) => item?.type == "Accounting"
    );

    if (tempSystem) {
      setSystemPosting(tempSystem);
    }

    if (tempSale) {
      setSalePosting(tempSale);
    }

    if (tempAccount) {
      setAccountPosting(tempAccount);
    }
  }, [accountPosting]);

  useEffect(() => {
    updateState({
      allowSplitPOPayment: transactionAccountSetting?.allowSplitPOPayment,
      dontUseSharingCosts: transactionAccountSetting?.dontUseSharingCosts,
      enableProviCompleteJobAcc:
        transactionAccountSetting?.enableProviCompleteJobAcc,
      enableWarrantyJobAcc: transactionAccountSetting?.enableWarrantyJobAcc,
      sendPOManuallyAccSys: transactionAccountSetting?.sendPOManuallyAccSys,
      shareAdminProjectCosts: transactionAccountSetting?.shareAdminProjectCosts,
    });
  }, [transactionAccountSetting]);

  const handleChangeTransactionAccount = (e) => {
    const { name, checked } = e.target;

    updateState({ ...iState, [name]: checked });
  };

  const handleUpdateAccount = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (formIsValid) {
      dispatch(
        updateAccountSettingAction(transactionAccountSetting?.id, {
          enableWarrantyJobAcc: iState.enableWarrantyJobAcc,
          enableProviCompleteJobAcc: iState.enableProviCompleteJobAcc,
        })
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Update Account Settings Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            dispatch(getTransactionAccountAction());

            setEditAccountSetting(false);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleUpdateTransaction = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (formIsValid) {
      dispatch(
        updateTransactionSettingAction(transactionAccountSetting?.id, {
          dontUseSharingCosts: iState.dontUseSharingCosts,
          sendPOManuallyAccSys: iState.sendPOManuallyAccSys,
          allowSplitPOPayment: iState.allowSplitPOPayment,
          shareAdminProjectCosts: iState.shareAdminProjectCosts,
        })
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Update Transaction Settings Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            dispatch(getTransactionAccountAction());

            setEditTransactionSetting(false);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error(error.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleSalesChanges = (e, i) => {
    const { name, value, checked } = e.target;
    let temp = [...SalePosting];

    let obj;

    if (name == "debitSubAccount" || name == "creditSubAccount") {
      obj = { ...temp[i], [name]: checked };
    } else {
      obj = { ...temp[i], [name]: value };
    }

    temp[i] = obj;
    setSalePosting(temp);
  };

  const handleAccountingChanges = (e, i) => {
    const { name, value, checked } = e.target;
    let temp = [...AccountPosting];

    let obj;

    if (name == "debitSubAccount" || name == "creditSubAccount") {
      obj = { ...temp[i], [name]: checked };
    } else {
      obj = { ...temp[i], [name]: value };
    }

    temp[i] = obj;
    setAccountPosting(temp);
  };

  const handleSystemChanges = (e, i) => {
    const { name, value, checked } = e.target;
    let temp = [...SystemPosting];

    let obj;

    if (name == "debitSubAccount" || name == "creditSubAccount") {
      obj = { ...temp[i], [name]: checked };
    } else {
      obj = { ...temp[i], [name]: value };
    }

    temp[i] = obj;
    setSystemPosting(temp);
  };

  const handleUpdateSalesPosting = (e) => {
    e.preventDefault();
    let formIsValid = true;

    let tempSale = SalePosting?.map((item) => {
      return {
        id: item?.id,
        debitId: item?.debitId,
        debitSubAccount: item?.debitSubAccount,
        creditId: item?.creditId,
        creditSubAccount: item?.creditSubAccount,
      };
    });

    let tempAccount = AccountPosting?.map((item) => {
      return {
        id: item?.id,
        debitId: item?.debitId,
        debitSubAccount: item?.debitSubAccount,
        creditId: item?.creditId,
        creditSubAccount: item?.creditSubAccount,
      };
    });

    let tempSystem = SystemPosting?.map((item) => {
      return {
        id: item?.id,
        debitId: item?.debitId,
        debitSubAccount: item?.debitSubAccount,
        creditId: item?.creditId,
        creditSubAccount: item?.creditSubAccount,
      };
    });

    if (formIsValid) {
      dispatch(
        updateAccountSalesPostingAction({
          accountPosting: tempSale,
        })
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success("Account Sale Settings Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            dispatch(getAccountSystemPostingAction());

            editAccountPositionSetting(false);
          } else {
            // toast.error(res.data.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          }
        })
        .catch((error) => {

         
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });

      dispatch(
        updateAccountSettingPostingAction({
          accountPosting: tempAccount,
        })
      );

      dispatch(
        updateSystemPostingAction({
          accountPosting: tempSystem,
        })
      );
    }
  };


  return (
    <div className="tab-pane fade " id="AccountingSetup">
      <div className="ResidentialJobArea ViewConfigArea">
        <div className="ModalAccordian">
          <div id="accordion">
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#TransactionSettingsTabs">
                  <span>
                    <img src="images/add_transactions.png" />
                  </span>{" "}
                  Transaction Settings
                </h4>
                <a
                  onClick={() =>
                    setEditTransactionSetting(
                      editTransactionSetting ? false : true
                    )
                  }
                  className="Edit EditViewConfigBodyBtn"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="TransactionSettingsTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div
                    className="ViewConfigBodyMain"
                    style={{
                      display: editTransactionSetting ? "none" : "block",
                    }}
                  >
                    <div class="ViewConfigBody">
                      <aside style={{ width: "calc(75% - 10px)" }}>
                        <div class="form-group">
                          <h6>Don't use Sharing Costs</h6>
                          <p>
                            {transactionAccountSetting?.dontUseSharingCosts
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Send PO Manually to Accounting System</h6>
                          <p>
                            {transactionAccountSetting?.sendPOManuallyAccSys
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Allow Split PO Payment</h6>
                          <p>
                            {transactionAccountSetting?.allowSplitPOPayment
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Share Admin Project Costs</h6>
                          <p>
                            {transactionAccountSetting?.shareAdminProjectCosts
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div
                    className="ViewConfigBodyHideMain"
                    style={{
                      display: editTransactionSetting ? "block" : "none",
                    }}
                  >
                    <div className="ViewConfigBodyHide">
                      <aside style={{ width: "calc(75% - 10px)" }}>
                        <div class="form-group">
                          <h6>Don't use Sharing Costs</h6>
                          <label class="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.dontUseSharingCosts}
                              name="dontUseSharingCosts"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                        <div class="form-group">
                          <h6>Send PO Manually to Accounting System</h6>
                          <label class="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.sendPOManuallyAccSys}
                              name="sendPOManuallyAccSys"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                        <div class="form-group">
                          <h6>Allow Split PO Payment</h6>
                          <label class="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.allowSplitPOPayment}
                              name="allowSplitPOPayment"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                        <div class="form-group">
                          <h6>Share Admin Project Costs</h6>
                          <label class="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.shareAdminProjectCosts}
                              name="shareAdminProjectCosts"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                      </aside>
                    </div>
                    <div className="Button mt-4 d-flex justify-content-center">
                      <button
                        onClick={() => setEditTransactionSetting(false)}
                        className="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateTransaction}
                        className="Respond ml-5 SaveViewConfigBodyBtn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#AccountSettingsTabs">
                  <span>
                    <img src="images/notification.png" />
                  </span>{" "}
                  Account Settings
                </h4>
                <a
                  onClick={() =>
                    setEditAccountSetting(editAccountSetting ? false : true)
                  }
                  className="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="AccountSettingsTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div
                    className="ViewConfigBodyMain"
                    style={{ display: editAccountSetting ? "none" : "block" }}
                  >
                    <div class="ViewConfigBody">
                      <aside>
                        <div class="form-group mb-5">
                          <h6>Enable Warranty Job Account</h6>
                          <p>
                            {transactionAccountSetting?.enableWarrantyJobAcc
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                        <div class="form-group">
                          <h6>Enable Provision to Complete Job Account</h6>
                          <p>
                            {transactionAccountSetting?.enableProviCompleteJobAcc
                              ? "On"
                              : "Off"}
                          </p>
                        </div>
                      </aside>
                    </div>
                  </div>
                  <div
                    className="ViewConfigBodyHideMain"
                    style={{ display: editAccountSetting ? "block" : "none" }}
                  >
                    <div className="ViewConfigBodyHide">
                      <aside>
                        <div className="form-group">
                          <h6>Enable Warranty Job Account</h6>
                          <label className="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.enableWarrantyJobAcc}
                              name="enableWarrantyJobAcc"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                        <div className="form-group">
                          <h6>Enable Provision to Complete Job Account</h6>
                          <label className="switch mb-0">
                            <input
                              type="checkbox"
                              checked={iState?.enableProviCompleteJobAcc}
                              name="enableProviCompleteJobAcc"
                              onChange={handleChangeTransactionAccount}
                            />
                            <span className="slider" style={{ left: 0 }} />
                          </label>
                        </div>
                      </aside>
                    </div>
                    <div className="Button d-flex justify-content-center">
                      <button
                        onClick={() => setEditAccountSetting(false)}
                        className="OutlineBlack mr-5 SaveViewConfigBodyBtn"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateAccount}
                        className="Respond ml-5 SaveViewConfigBodyBtn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4
                  data-toggle="collapse"
                  href="#AccountPostingDefaultSettingsTabs"
                >
                  <span>
                    <img src="images/notification.png" />
                  </span>{" "}
                  Account Posting Default Settings
                </h4>
                <a
                  onClick={() =>
                    setEditAccountPositionSetting(
                      editAccountPositionSetting ? false : true
                    )
                  }
                  className="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="AccountPostingDefaultSettingsTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div
                    className="ViewConfigBodyMain"
                    style={{
                      display: editAccountPositionSetting ? "none" : "block",
                    }}
                  >
                    <div class="ModalBigheading mb-3 mt-4">
                      <h3>
                        <span class="underline-grey">Sales</span>
                      </h3>
                    </div>
                    <div class="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountPosting
                            ?.filter((item) => item?.type == "Sales")
                            ?.map((sales) => (
                              <tr>
                                <td>{sales?.name}</td>
                                <td>Other Miscellaneous Expense</td>
                                <td>{sales?.debitSubAccount ? "On" : "Off"}</td>
                                <td>Other Miscellaneous Expense</td>
                                <td>
                                  {sales?.creditSubAccount ? "On" : "Off"}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="ModalBigheading mb-3 mt-5">
                      <h3>
                        <span class="underline-grey">Accounting</span>
                      </h3>
                    </div>
                    <div class="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountPosting
                            ?.filter((item) => item?.type == "Accounting")
                            ?.map((accounting) => (
                              <tr>
                                <td>{accounting?.name}</td>
                                <td>Other Miscellaneous Expense</td>
                                <td>
                                  {accounting?.debitSubAccount ? "On" : "Off"}
                                </td>
                                <td>Other Miscellaneous Expense</td>
                                <td>
                                  {accounting?.creditSubAccount ? "On" : "Off"}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div class="ModalBigheading mb-3 mt-5">
                      <h3>
                        <span class="underline-grey">System</span>
                      </h3>
                    </div>
                    <div class="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {accountPosting
                            ?.filter((item) => item?.type == "System")
                            ?.map((system) => (
                              <tr>
                                <td>{system?.name}</td>
                                <td>Other Miscellaneous Expense</td>
                                <td>
                                  {" "}
                                  {system?.debitSubAccount ? "On" : "Off"}
                                </td>
                                <td>Sales of Product Income</td>
                                <td>
                                  {system?.creditSubAccount ? "On" : "Off"}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    className="ViewConfigBodyHideMain"
                    style={{
                      display: editAccountPositionSetting ? "block" : "none",
                    }}
                  >
                    <div className="ModalBigheading mb-3 mt-4">
                      <h3>
                        <span className="underline-grey">Sales</span>
                      </h3>
                    </div>
                    <div className="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th />
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {SalePosting?.map((item, i) => (
                            <tr>
                              <td>{item?.name}</td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="debitId"
                                    value={item?.debitId}
                                    onChange={(e) => handleSalesChanges(e, i)}
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Other Miscellaneous Expense
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.debitSubAccount}
                                    name="debitSubAccount"
                                    onChange={(e) => handleSalesChanges(e, i)}
                                  />
                                  <span className="slider" />
                                </label>
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="creditId"
                                    value={item?.creditId}
                                    onChange={(e) => handleSalesChanges(e, i)}
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Other Miscellaneous Expense
                                    </option>
                                  </select>{" "}
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.creditSubAccount}
                                    name="creditSubAccount"
                                    onChange={(e) => handleSalesChanges(e, i)}
                                  />
                                  <span className="slider" />
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="ModalBigheading mb-3 mt-5">
                      <h3>
                        <span className="underline-grey">Accounting</span>
                      </h3>
                    </div>
                    <div className="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th />
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AccountPosting?.map((item, i) => (
                            <tr>
                              <td>{item?.name}</td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="debitId"
                                    value={item?.debitId}
                                    onChange={(e) =>
                                      handleAccountingChanges(e, i)
                                    }
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Other Miscellaneous Expense
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.debitSubAccount}
                                    name="debitSubAccount"
                                    onChange={(e) =>
                                      handleAccountingChanges(e, i)
                                    }
                                  />
                                  <span className="slider" />
                                </label>
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="creditId"
                                    value={item?.creditId}
                                    onChange={(e) =>
                                      handleAccountingChanges(e, i)
                                    }
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Other Miscellaneous Expense
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.creditSubAccount}
                                    name="creditSubAccount"
                                    onChange={(e) =>
                                      handleAccountingChanges(e, i)
                                    }
                                  />{" "}
                                  <span className="slider" />
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="ModalBigheading mb-3 mt-5">
                      <h3>
                        <span className="underline-grey">System</span>
                      </h3>
                    </div>
                    <div className="AccountPostingTable">
                      <table>
                        <thead>
                          <tr>
                            <th />
                            <th>Debit</th>
                            <th>Debit Sub-Account</th>
                            <th>Credit</th>
                            <th>Credit Sub-Account</th>
                          </tr>
                        </thead>
                        <tbody>
                          {SystemPosting?.map((item, i) => (
                            <tr>
                              <td>{item?.name}</td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="debitId"
                                    value={item?.debitId}
                                    onChange={(e) => handleSystemChanges(e, i)}
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Sales of Product Income
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <input
                                  type="checkbox"
                                  checked={item?.debitSubAccount}
                                  name="debitSubAccount"
                                  onChange={(e) => handleSystemChanges(e, i)}
                                />{" "}
                              </td>
                              <td>
                                <div className="CommonSelectBox">
                                  <select
                                    name="creditId"
                                    value={item?.creditId}
                                    onChange={(e) => handleSystemChanges(e, i)}
                                  >
                                    <option value={""}>Select</option>
                                    <option value={1}>
                                      Sales of Product Income
                                    </option>
                                  </select>
                                  <span>
                                    <img src="images/down.png" />
                                  </span>
                                </div>
                              </td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.creditSubAccount}
                                    name="creditSubAccount"
                                    onChange={(e) => handleSystemChanges(e, i)}
                                  />{" "}
                                  <span className="slider" />
                                </label>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="Button d-flex justify-content-center mt-4">
                      <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button
                        onClick={handleUpdateSalesPosting}
                        className="Respond ml-5 SaveViewConfigBodyBtn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#AccountingSoftwareSyncTabs">
                  <span>
                    <img src="images/accounting_sync.png" />
                  </span>{" "}
                  Accounting Software Sync
                </h4>
                <a
                  onClick={() =>
                    setEditAccountSoftwareSetting(
                      editAccountSoftwareSetting ? false : true
                    )
                  }
                  className="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="AccountingSoftwareSyncTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  <div
                    className="ViewConfigBodyMain"
                    style={{
                      display: editAccountSoftwareSetting ? "none" : "block",
                    }}
                  >
                    <div className="AccounitingCollapseArea">
                      <h6 className="mb-3">
                        Recommended Accounting Configuration Procedures Before
                        Integration
                      </h6>
                      <p>
                        - You must first purchase the{" "}
                        <span>QuickBooks Online Plus</span> version as the{" "}
                        <span>Plus</span> version allows class usage
                      </p>
                      <p>
                        - Second, ensure the{" "}
                        <span>Chart of Accounts (COA)</span> has an{" "}
                        <span>Accounts Payables (A/P)</span> account already
                        set-up, if not, set one up.
                      </p>
                      <p>
                        - In your QuickBooks Online Plus version you must first
                        Turn-On the <span>Purchase Order</span> Option, the{" "}
                        <span>Enable account numbers</span>
                        option, then the the <span>Class</span> Option as
                        follows:
                      </p>
                      <ul>
                        <li>
                          --Click on the{" "}
                          <span>
                            Gear Icon in the Upper Right-Hand Corner of on your
                            Home Dashboard screen.
                          </span>
                        </li>
                        <li>
                          --Click on <span>Account and Settings</span>
                        </li>
                        <li>
                          --Click on the <span>Account and Settings</span>{" "}
                          option.{" "}
                        </li>
                        <li>
                          --Click on the <span>Expenses</span> option.{" "}
                        </li>
                        <li>
                          --Click on the box in front of the{" "}
                          <span>Use Purchase Orders</span> option.
                        </li>
                        <li>
                          --Click the green <span>Save</span> button.
                        </li>
                        <li>
                          --Click on the <span>Advanced</span> option.
                        </li>
                        <li>
                          --Click on the <span>Chart of Accounts</span> option.
                        </li>
                        <li>
                          --Click on the box in front of{" "}
                          <span>Enable Account Numbers</span>{" "}
                        </li>
                        <li>
                          --Click the green <span>Save</span> button.
                        </li>
                        <li>
                          --Click the box in front of the{" "}
                          <span>Track Classes</span> option.
                        </li>
                        <li>
                          --Click the green <span>Save</span> button
                        </li>
                      </ul>
                      <p>
                        -Return to this page and click on the Green{" "}
                        <span>Connect to QuickBooks </span>button.
                      </p>
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Software</h6>
                          <img src="images/quickbooks.png" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Software</h6>
                          <h6 className="fw-500">Not Syncd</h6>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Fiscal Year End</h6>
                          <p>March</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="ViewConfigBodyHideMain"
                    style={{
                      display: editAccountSoftwareSetting ? "block" : "none",
                    }}
                  >
                    <div className="AccounitingCollapseArea">
                      <h6 className="mb-3">
                        Recommended Accounting Configuration Procedures Before
                        Integration
                      </h6>
                      <p>
                        - You must first purchase the{" "}
                        <span>QuickBooks Online Plus</span> version as the{" "}
                        <span>Plus</span> version allows class usage
                      </p>
                      <p>
                        - Second, ensure the{" "}
                        <span>Chart of Accounts (COA)</span> has an{" "}
                        <span>Accounts Payables (A/P)</span> account already
                        set-up, if not, set one up.
                      </p>
                      <p>
                        - In your QuickBooks Online Plus version you must first
                        Turn-On the <span>Purchase Order</span> Option, the{" "}
                        <span>Enable account numbers</span>
                        option, then the the <span>Class</span> Option as
                        follows:
                      </p>
                      <ul>
                        <li>
                          --Click on the{" "}
                          <span>
                            Gear Icon in the Upper Right-Hand Corner of on your
                            Home Dashboard screen.
                          </span>
                        </li>
                        <li>
                          --Click on <span>Account and Settings</span>
                        </li>
                        <li>
                          --Click on the <span>Account and Settings</span>{" "}
                          option.{" "}
                        </li>
                        <li>
                          --Click on the <span>Expenses</span> option.{" "}
                        </li>
                        <li>
                          --Click on the box in front of the{" "}
                          <span>Use Purchase Orders</span> option.
                        </li>
                        <li>
                          --Click the green <span>Save</span> button.
                        </li>
                        <li>
                          --Click on the <span>Advanced</span> option.
                        </li>
                        <li>
                          --Click on the <span>Chart of Accounts</span> option.
                        </li>
                        <li>
                          --Click on the box in front of{" "}
                          <span>Enable Account Numbers</span>{" "}
                        </li>
                        <li>
                          --Click the green <span>Save</span> button.
                        </li>
                        <li>
                          --Click the box in front of the{" "}
                          <span>Track Classes</span> option.
                        </li>
                        <li>
                          --Click the green <span>Save</span> button
                        </li>
                      </ul>
                      <p>
                        -Return to this page and click on the Green{" "}
                        <span>Connect to QuickBooks </span>button.
                      </p>
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Software</h6>
                          <img src="images/quickbooks.png" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex">
                          <div className="form-group">
                            <h6>Software</h6>
                            <h6 className="fw-500">Not Syncd</h6>
                          </div>
                          <div className="Button ml-2">
                            <button className="Respond p-1">
                              Connect to <br /> Quickbooks
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <h6>Fiscal Year End</h6>
                          <div className="CommonSelectBox">
                            <select>
                              <option selected="">March</option>
                            </select>
                            <span>
                              <img src="images/down.png" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button className="AlterBtn">Alter</button>
                      </div>
                    </div>
                    <div className="Button d-flex justify-content-center mt-4">
                      <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                        Cancel
                      </button>
                      <button className="Respond ml-5 SaveViewConfigBodyBtn">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAccounting;
