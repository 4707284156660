import { itineraryAction } from "../actionTypes";
const initialState = {
  loader: false,
  itineraryList: [],
  listAccessRequests:[],
  managerAccessMyList:[],
  searchGiveMyItineraryUser:[],
  giveMyItineraryUser:[],
  managerAccessUserList:[],
  getSharedItinerary:[],




};

export default function itineraryReducer(state = initialState,{ type, payload }) {
  switch (type) {
    case itineraryAction.ITINERTY_LIST_INITIATE: {
      return {
        ...state, loader: true, itineraryList: [],
      };
    }
    case itineraryAction.ITINERTY_LIST_SUCCESS: {
      return {
        ...state,loader: false, itineraryList: payload,
      };
    }
    case itineraryAction.ITINERTY_LIST_FAILURE: {
      return {
        ...state,  loader: false, itineraryList: payload,
      };
    }



    case itineraryAction.LIST_REQUEST_ACCESS_INITIATE: {
      return {
        ...state, loader: true, listAccessRequests: [],
      };
    }
    case itineraryAction.LIST_REQUEST_ACCESS_SUCCESS: {
      return {
        ...state,loader: false, listAccessRequests: payload,
      };
    }
    case itineraryAction.LIST_REQUEST_ACCESS_FAILURE: {
      return {
        ...state,  loader: false, listAccessRequests: payload,
      };
    }


    case itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_INITIATE: {
      return {
        ...state, loader: true, managerAccessMyList: [],
      };
    }
    case itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_SUCCESS: {
      return {
        ...state,loader: false, managerAccessMyList: payload,
      };
    }
    case itineraryAction.MANAGE_ACCESS_TO_MY_ITINERARY_LIST_FAILURE: {
      return {
        ...state,  loader: false, managerAccessMyList: payload,
      };
    }


    case itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_INITIATE: {
      return {
        ...state, loader: true, managerAccessUserList: [],
      };
    }
    case itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_SUCCESS: {
      return {
        ...state,loader: false, managerAccessUserList: payload,
      };
    }
    case itineraryAction.MANAGE_ACCESS_TO_USER_ITINERARY_LIST_FAILURE: {
      return {
        ...state,  loader: false, managerAccessUserList: payload,
      };
    }


    case itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_INITIATE: {
      return {
        ...state, loader: true, searchGiveMyItineraryUser: [],
      };
    }
    case itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_SUCCESS: {
      return {
        ...state,loader: false, searchGiveMyItineraryUser: payload,
      };
    }
    case itineraryAction.SEARCH_GIVE_ACCESS_TO_ITINERARY_USER_FAILURE: {
      return {
        ...state,  loader: false, searchGiveMyItineraryUser: payload,
      };
    }



    case itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_INITIATE: {
      return {
        ...state, loader: true, giveMyItineraryUser: [],
      };
    }
    case itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_SUCCESS: {
      return {
        ...state,loader: false, giveMyItineraryUser: payload,
      };
    }
    case itineraryAction.GIVE_ACCESS_TO_ITINERARY_USER_LIST_FAILURE: {
      return {
        ...state,  loader: false, giveMyItineraryUser: payload,
      };
    }



    case itineraryAction.GET_SHARED_ITINERARY_INITIATE: {
      return {
        ...state, loader: true, getSharedItinerary: [],
      };
    }
    case itineraryAction.GET_SHARED_ITINERARY_SUCCESS: {
      return {
        ...state,loader: false, getSharedItinerary: payload,
      };
    }
    case itineraryAction.GET_SHARED_ITINERARY_FAILURE: {
      return {
        ...state,  loader: false, getSharedItinerary: payload,
      };
    }





    default:
      return state;
  }
}
