
import axios from "axios";
import { adminPlanConstant } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));








export const planListInitate = () => ({
    type: adminPlanConstant.PLAN_LIST_INITIATE,
  });
  
  export const planListSuccess = (data) => ({
    type: adminPlanConstant.PLAN_LIST_SUCCESS,
    payload: data,
  });
  
  export const planListFailure = (data) => ({
    type: adminPlanConstant.PLAN_LIST_FAILURE,
    payload: data,  
  });
  
  export function planListAction() {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(planListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/plans`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(planListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(planListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(planListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(planListFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  



  export const createPlanInitate = () => ({
    type: adminPlanConstant.CREATE_PLAN_INITIATE,
  });
  
  export const createPlanSuccess = (data) => ({
    type: adminPlanConstant.CREATE_PLAN_SUCCESS,
    payload: data,
  });
  
  export const createPlanFailure = (data) => ({
    type: adminPlanConstant.CREATE_PLAN_FAILURE,
    payload: data,
  });
  
  export function CreatePlanAction(payload) {
    const token = isLoggedIn("adminData1");
    const updatedData = multiPartData({ image: payload.image, ...payload })
    return (dispatch) => {
      dispatch(createPlanInitate());
      return new Promise((resolve, reject) => {
        axios
        // companies/2/Category
          .post(`${Url}admin/plans`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(createPlanSuccess(data.data));
              resolve(data);
            } else {
              dispatch(createPlanFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(createPlanFailure(err));
            reject(err);
          });
      });
    };
  }



  export const planDetailsInitate = () => ({
    type: adminPlanConstant.PLAN_LIST_INITIATE,
  });
  
  export const planDetailsSuccess = (data) => ({
    type: adminPlanConstant.PLAN_LIST_SUCCESS,
    payload: data,
  });
  
  export const planDetailsFailure = (data) => ({
    type: adminPlanConstant.PLAN_LIST_FAILURE,
    payload: data,  
  });
  
  export function planDetailsAction( payload) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(planDetailsInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/plans`, {
            headers: { Authorization: `Bearer ${token}` },
            params:payload
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200 ||  data.status ==  201) {
              dispatch(planDetailsSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(planDetailsFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(planDetailsSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(planDetailsFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  



  export const updatePlanInitate = () => ({
    type: adminPlanConstant.UPDATE_PLAN_INITIATE,
  });
  
  export const updatePlanSuccess = (data) => ({
    type: adminPlanConstant.UPDATE_PLAN_SUCCESS,
    payload: data,
  });
  
  export const updatePlanFailure = (data) => ({
    type: adminPlanConstant.UPDATE_PLAN_FAILURE,
    payload: data,  
  });
  
  export function updatePlanAction( payload) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(updatePlanInitate());
      return new Promise((resolve, reject) => {
        axios
          .put(`${Url}admin/plans`,payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            console.log("search-assembly?name", data);
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(updatePlanSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(updatePlanFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(updatePlanSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(updatePlanFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  



  export const removePlanInitate = () => ({
    type: adminPlanConstant.DELETE_PLAN_INITIATE,
  });
  
  export const removePlanSuccess = (data) => ({
    type: adminPlanConstant.DELETE_PLAN_SUCCESS,
    payload: data,
  });
  
  export const removePlanFailure = (data) => ({
    type: adminPlanConstant.DELETE_PLAN_FAILURE ,
    payload: data,
  });
  
  export function removePlanAction( id) {
    const token = isLoggedIn("adminData1");
    return (dispatch) => {
      dispatch(removePlanInitate());
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${Url}admin/plans?id=${
              id
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            const data = response.data;
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(removePlanSuccess(data));
              resolve(data);
            } else {
              dispatch(removePlanFailure(data));
              reject(data);
            }
          })
          .catch((err) => {
            dispatch(removePlanFailure(err));
            reject(err);
          });
      });
    };
  }
  



  
export const featureListInitate = () => ({
    type: adminPlanConstant.GET_FEATURE_LIST_INITIATE,
  });
  
  export const featureListSuccess = (data) => ({
    type: adminPlanConstant.GET_FEATURE_LIST_SUCCESS,
    payload: data,
  });
  
  export const featureListFailure = (data) => ({
    type: adminPlanConstant.GET_FEATURE_LIST_FAILURE,
    payload: data,  
  });
  
  export function featureListAction( id, name, tags) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(featureListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}feature/list`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            console.log("search-assembly?name", data);
            if (data && data.status == 200 ||  data.status ==  201) {
              dispatch(featureListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(featureListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(featureListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(featureListFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  




  export const activatePlanInitate = () => ({
    type: adminPlanConstant.ACTIVATE_PLAN_INITIATE,
  });
  
  export const activatePlanSuccess = (data) => ({
    type: adminPlanConstant.ACTIVATE_PLAN_SUCCESS,
    payload: data,
  });
  
  export const activatePlanFailure = (data) => ({
    type: adminPlanConstant.ACTIVATE_PLAN_FAILURE ,
    payload: data,  
  });
  
  export function activatePlanAction( payload) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(activatePlanInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/activatePlanAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
            params: payload
          })
          .then((response) => {
            const data = response;
            console.log("search-assembly?name", data);
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(activatePlanSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(activatePlanFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(activatePlanSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(activatePlanFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  


  export const deactivatePlanInitate = () => ({
    type: adminPlanConstant.DEACTIVATE_PLAN_INITIATE,
  });
  
  export const deactivatePlanSuccess = (data) => ({
    type: adminPlanConstant.DEACTIVATE_PLAN_SUCCESS,
    payload: data,
  });
  
  export const deactivatePlanFailure = (data) => ({
    type: adminPlanConstant.DEACTIVATE_PLAN_FAILURE ,
    payload: data,  
  });
  
  export function deactivatePlanAction( payload) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(deactivatePlanInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}admin/deactivatePlanAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
            params: payload
          })
          .then((response) => {
            const data = response;
            if (data && data.status >= 200 ||  data.status <=  300) {
              dispatch(deactivatePlanSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(deactivatePlanFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(deactivatePlanSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(deactivatePlanFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }
  
