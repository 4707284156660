import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addFollowIternary,
  addSupportingDocsInTransaction,
  cancelEstimationDocument,
  changeTransactioStatusToVoid,
  createSchedulePayment,
  createTransaction,
  deleteFollowUps,
  getAllContractList,
  getAllPrimaryClients,
  getAllSupportingDocsTransactions,
  getEstimationDocumnetList,
  getFollowUpsList,
  getSchedulePayment,
  getSoaDetails,
  getTransactionList,
  removeSchedulePayment,
  sendInvitation,
  updateFollowMembers,
  updateFollowUpsReminder,
  updatePostalTransaction,
  updateSchedulePayment,
} from "../redux/action/accountingAction";
import Loader from "./Loader";
import moment from "moment";
import {
  getEmailCalendar,
  getGoogleCalendar,
  getOutlookCalendar,
  getTaskListJob,
  getTaskMemberList,
} from "../redux/action/taskAction";
import { Link, useLocation } from "react-router-dom";
import { calculateDateDifference } from "../utils/uploadFile";
import { getAllProjectList } from "../redux/action/vendorViewConfigAction";

const initialState = {
  TransactionsModal: false,
  contractId: "",
  tranDate: "",
  payMethod: "",
  tranType: "",
  total: "",
  notes: "",
  search: "",
  AddNewSupportingDocsModal: false,
  files: null,
  tranId: "",
  AssignMemberModal: false,
  ScheduledPaymentsModal: false,

  scheOfPayDate: "",
  transactionType: "",
  totalSchedule: "",
  DeleteModal: false,
  schdelId: "",
  EditSchedulePaymentModal: false,
  SOAHistoryModal: false,
  FollowUpActivityModal: false,
  leadId: "",
  CancelActivityModal: false,
  followId: "",
  FollowReminderModal: false,
  setReminder: "",
  assignStaffId: "",
  UpdateAssignStaffMemberModal: false,
  ViewConsilatedDocumentModal: false,
  ViewSoaModal: false,
  estimationDocumentSearch: "",

  AddItineraryModal: false,
  subject: "",
  descriptions: "",
  location: "",
  date: "",
  fromTime: "",
  toTime: "",
  type: "",
  reminderDates: "",
  jobType: "",
  jobId: "",
  relatedToTask: "",
  relatedToJob: "",
  TaskId: "",
  assignStaffss: "",
  jobSearch: "",
  types: "",
  AssignStaffMemberModalInt: false,
  ReminderModalInt: false,
  day: "",
  week: "",
  ScheduleNewPaymentModal: false,
  AddNewTransactionsModal: false,
  PostTransactionModal: false,
  SupportingDocumentsListModal: false,

  debitSubAccountPosting: false,
  debit: "",
  debitAccountId: "",
  creditSubAccountPosting: "",
  credit: "",
  creditAccountId: "",
  isPostal: "",
  operation: "",
  NavigateModal: false,
};
const TransactionsManager = () => {
  const dispatch = useDispatch();
  const {
    contractList,
    contractLoader,
    transactionLoader,
    transactionList,
    primaryClientsDetails,
    schedulePaymentList,
    followUpList,
    estimationDocumentList,
    soaDetails,
    allSupportDocsList,
  } = useSelector((state) => state.accountingReducer);

  console.log("transactionList", transactionList);

  const { memberList, jobList } = useSelector((state) => state.taskReducer);

  const [iState, updateState] = useState(initialState);
  const {
    TransactionsModal,
    contractId,
    tranDate,
    payMethod,
    tranType,
    total,
    notes,
    search,
    AddNewSupportingDocsModal,
    files,
    transId,
    AssignMemberModal,
    ScheduledPaymentsModal,

    scheOfPayDate,
    transactionType,
    totalSchedule,
    DeleteModal,
    schdelId,
    EditSchedulePaymentModal,
    SOAHistoryModal,
    FollowUpActivityModal,
    leadId,
    CancelActivityModal,
    followId,
    FollowReminderModal,
    setReminder,
    assignStaffId,
    UpdateAssignStaffMemberModal,
    ViewConsilatedDocumentModal,
    ViewSoaModal,
    estimationDocumentSearch,

    AddItineraryModal,
    subject,
    descriptions,
    location,
    date,
    fromTime,
    toTime,
    type,
    reminderDates,
    jobType,
    jobId,
    relatedToTask,
    relatedToJob,
    TaskId,
    assignStaffss,
    jobSearch,
    types,
    AssignStaffMemberModalInt,
    ReminderModalInt,
    day,
    week,
    ScheduleNewPaymentModal,
    AddNewTransactionsModal,
    PostTransactionModal,
    SupportingDocumentsListModal,

    debitSubAccountPosting,
    debit,
    debitAccountId,
    creditSubAccountPosting,
    credit,
    creditAccountId,
    isPostal,
    operation,
    NavigateModal,
  } = iState;

  const { state,pathname } = useLocation();
  console.log("state", state);

  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  },[])

  useEffect(() => {
    dispatch(getAllContractList(state?.id, state?.jobType)).then((res) => {
      dispatch(getTransactionList(res?.data?.contracts?.[0]?.id));
      updateState({ ...iState, contractId: res?.data?.contracts?.[0]?.id });
    });
    dispatch(getTaskMemberList());
    dispatch(getTaskListJob());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleTransactionsModalShow = (id) => {
    updateState({ ...iState, TransactionsModal: true, contractId: id });
  };

  useEffect(() => {
    if (TransactionsModal) {
      dispatch(getTransactionList(contractId));
    }
  }, [TransactionsModal]);

  const handleTransactionsModalClose = () => {
    updateState({ ...iState, TransactionsModal: false });
  };

  const handleCreateContract = () => {
    const datta = {
      tranDate,
      payMethod,
      tranType,
      total: Number(total),
      notes,
    };
    dispatch(createTransaction(contractId, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getTransactionList(contractId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          tranDate: "",
          payMethod: "",
          tranType: "",
          total: "",
          notes: "",
          AddNewTransactionsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewTransactionsModal: true,
        });
      }
    });
  };

  const handleChangeStatusToVoid = (id) => {
    const datta = { tranStatus: "Void" };
    dispatch(changeTransactioStatusToVoid(contractId, id, datta)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getTransactionList(contractId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    // if (type === "notes") {
    //   updateState({ ...iState, supportingDocFiles: file });
    // }
    if (type === "supportDocs") {
      updateState({ ...iState, files: file });
    }
    // else {
    //   updateState({ ...iState, supportingDocFiless: file });
    // }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      transId: id,
      files: null,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      files: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSupportDocs = () => {
    let dataa = { files };
    dispatch(addSupportingDocsInTransaction(transId, dataa)).then((res) => {
      if (res.status === 201) {
        dispatch(getTransactionList(contractId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleAssignMemberModalShow = (id) => {
    updateState({
      ...iState,
      AssignMemberModal: true,
      contractId: id,
    });
  };

  useEffect(() => {
    if (AssignMemberModal) {
      dispatch(getAllPrimaryClients(contractId));
    }
  }, [AssignMemberModal]);
  const handleAssignMemberModalClose = () => {
    updateState({
      ...iState,
      AssignMemberModal: false,
    });
  };

  const handleScheduledPaymentsModalShow = (id) => {
    updateState({
      ...iState,
      ScheduledPaymentsModal: true,
      contractId: id,
    });
  };

  // useEffect(() => {
  //   if (ScheduledPaymentsModal) {
  //     dispatch(getSchedulePayment(contractId));
  //   }
  // }, [ScheduledPaymentsModal]);

  const handleScheduledPaymentsModalClose = () => {
    updateState({
      ...iState,
      ScheduledPaymentsModal: false,
      contractId: "",
    });
  };

  const handleSchedulePayment = () => {
    const datta = {
      scheOfPayDate,
      transactionType,
      total: Number(totalSchedule),
    };
    dispatch(createSchedulePayment(contractId, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getSchedulePayment(contractId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          scheOfPayDate: "",
          transactionType: "",
          totalSchedule: "",
          // ScheduleNewPaymentModal:false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // updateState({...iState,ScheduleNewPaymentModal:true})
      }
    });
  };

  const handleDeleteShow = (id) => {
    updateState({
      ...iState,
      DeleteModal: true,
      schdelId: id,
      ScheduleNewPaymentModal: false,
    });
  };

  const handleDeleteClose = () => {
    updateState({
      ...iState,
      DeleteModal: false,
      ScheduleNewPaymentModal: true,
    });
  };

  const handleDeleteAssign = () => {
    dispatch(removeSchedulePayment(contractId, schdelId)).then((res) => {
      if (res.status === 200) {
        dispatch(getSchedulePayment(contractId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DeleteModal: false,
          ScheduleNewPaymentModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DeleteModal: true,
        });
      }
    });
  };

  const handleEditSchedulePaymentModalShow = (item) => {
    updateState({
      ...iState,
      EditSchedulePaymentModal: true,
      scheOfPayDate: moment(item.scheOfPayDate).format("YYYY-MM-DD"),
      transactionType: item.transactionType,
      totalSchedule: Number(item.total),
      schdelId: item.id,
      ScheduleNewPaymentModal: false,
    });
  };

  const handleEditSchedulePaymentModalClose = () => {
    updateState({
      ...iState,
      EditSchedulePaymentModal: false,
      ScheduleNewPaymentModal: true,
    });
  };

  const handleUpdateSchedulePayment = () => {
    const datta = {
      scheOfPayDate,
      transactionType,
      total: Number(totalSchedule),
    };
    dispatch(updateSchedulePayment(contractId, schdelId, datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          scheOfPayDate: "",
          transactionType: "",
          totalSchedule: "",
          schdelId: "",
          EditSchedulePaymentModal: false,
          ScheduleNewPaymentModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSOAHistoryModalShow = () => {
    updateState({ ...iState, SOAHistoryModal: true });
  };

  const handleSOAHistoryModalClose = () => {
    updateState({ ...iState, SOAHistoryModal: false });
  };

  const handleFollowUpActivityModalShow = (id) => {
    updateState({ ...iState, FollowUpActivityModal: true, leadId: id });
  };

  useEffect(() => {
    if (FollowUpActivityModal) {
      dispatch(getFollowUpsList(leadId));
    }
  }, [FollowUpActivityModal]);

  const handleFollowUpActivityModalClose = () => {
    updateState({ ...iState, FollowUpActivityModal: false, leadId: "" });
  };

  const handleCancelActivityModalShow = (ledId, folId) => {
    updateState({
      ...iState,
      CancelActivityModal: true,
      leadId: ledId,
      followId: folId,
      FollowUpActivityModal: false,
    });
  };

  const handleCancelActivityModalClose = () => {
    updateState({
      ...iState,
      CancelActivityModal: false,
      FollowUpActivityModal: true,
    });
  };

  const handleActivityDelete = () => {
    dispatch(deleteFollowUps(leadId, followId)).then((res) => {
      if (res.status === 200) {
        dispatch(getFollowUpsList(leadId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CancelActivityModal: false,
          FollowUpActivityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CancelActivityModal: true,
        });
      }
    });
  };

  const handleSendInvitation = (leadId, followId) => {
    dispatch(sendInvitation(leadId, followId)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          leadId: "",
          followId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      setReminderDate: item.setReminderDate,
      location: "Sample Location",
    };
    dispatch(getGoogleCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.google, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleOutLookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      setReminderDate: item.setReminderDate,
      location: "Sample Location",
    };
    dispatch(getOutlookCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.outlook, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleEmailData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      setReminderDate: item.setReminderDate,
      location: "Sample Location",
    };
    dispatch(getEmailCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.yahoo, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleFollowReminderModalShow = (ledId, folId) => {
    updateState({
      ...iState,
      FollowReminderModal: true,
      leadId: ledId,
      followId: folId,
      FollowUpActivityModal: false,
      setReminder: "",
    });
  };

  const handleFollowReminderModalClose = () => {
    updateState({
      ...iState,
      FollowReminderModal: false,
      FollowUpActivityModal: true,
      setReminder: "",
    });
  };

  const handleFollowReminder = () => {
    const datta = { setReminder: Number(setReminder) };
    dispatch(updateFollowUpsReminder(leadId, followId, datta)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          FollowReminderModal: false,
          FollowUpActivityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, FollowReminderModal: false });
      }
    });
  };

  const handleMinuteClick = (minutes) => {
    updateState({ ...iState, setReminder: minutes });
  };

  const handleUpdateAssignStaffMemberShow = (ledId, folId) => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: true,
      leadId: ledId,
      followId: folId,
      FollowUpActivityModal: false,
      assignStaffId: "",
    });
  };

  const handleUpdateAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: false,
      FollowUpActivityModal: true,
      assignStaffId: "",
    });
  };

  const handleMemberClick = (id) => {
    updateState({ ...iState, assignStaffId: id });
  };

  const handleAssignStaffMember = () => {
    const datta = { assignStaffId: [{ UserId: Number(assignStaffId) }] };
    dispatch(updateFollowMembers(leadId, followId, datta)).then((res) => {
      if (res.status === 200) {
        toast.success("Assign Staff Member Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UpdateAssignStaffMemberModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateAssignStaffMemberModal: true,
        });
      }
    });
  };

  const [selectedItems, setSelectedItems] = useState([]);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add the selected item to the array
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      // Remove the item from the array
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems?.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const handleViewConsilatedDocumentModalShow = () => {
    updateState({
      ...iState,
      ViewConsilatedDocumentModal: true,
    });
    setSelectedItems([]);
  };

  useEffect(() => {
    if (ViewConsilatedDocumentModal) {
      dispatch(getEstimationDocumnetList(state?.ProjectId));
    }
  }, [ViewConsilatedDocumentModal]);
  const handleViewConsilatedDocumentModalClose = () => {
    updateState({
      ...iState,
      ViewConsilatedDocumentModal: false,
    });
    setSelectedItems([]);
  };

  const handleCancelSelected = () => {
    const datta = {
      selectedItems: selectedItems?.map((data) => ({
        id: data.id,
        documentType: data.documentType,
      })),
    };
    dispatch(cancelEstimationDocument(state?.ProjectId, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getEstimationDocumnetList(state?.ProjectId));
        toast.success("Cancelled Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleViewSoaModalShow = (id) => {
    updateState({
      ...iState,
      ViewSoaModal: true,
      contractId: id,
    });
  };

  useEffect(() => {
    if (ViewSoaModal) {
      dispatch(getSoaDetails(contractId));
    }
  }, [ViewSoaModal]);
  const handleViewSoaModalClose = () => {
    updateState({
      ...iState,
      ViewSoaModal: false,
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleAddItineraryModalShow = (ledId, folId) => {
    updateState({
      ...iState,
      AddItineraryModal: true,
      FollowUpActivityModal: false,
      leadId: ledId,
      followId: folId,
    });
  };

  const handleAddItineraryModalClose = () => {
    updateState({
      ...iState,
      AddItineraryModal: false,
      FollowUpActivityModal: true,
    });
  };

  const handleJobTaskChecked = (e, type) => {
    const { checked } = e.target;
    if (type == "job") {
      updateState({ ...iState, relatedToJob: checked });
    } else {
      updateState({ ...iState, relatedToTask: checked });
    }
  };
  const handleJobIternaryItemClick = (item) => {
    updateState({ ...iState, jobId: item.id, jobType: item.jobType });
  };

  const removeAssignIntMember = () => {
    updateState({ ...iState, assignStaffss: "" });
  };

  const handleAssignStaffMemberIntShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: true,
    });
  };

  const handleAssignStaffMemberIntClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: false,
    });
  };

  const handleAssignIn = () => {
    updateState({ ...iState, AssignStaffMemberModalInt: false });
  };

  const handleReminderIntShow = () => {
    updateState({
      ...iState,
      ReminderModalInt: true,
    });
  };

  const handleReminderIntClose = () => {
    updateState({
      ...iState,
      ReminderModalInt: false,
    });
  };

  const handleReminderInt = () => {
    updateState({ ...iState, ReminderModalInt: false, day: day, week: week });
  };

  const handleAddItinerary = () => {
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    const datta = {
      // subject,
      description: descriptions,
      location,
      date,
      fromTime: `${fromTime}:00`,
      toTime: `${toTime}:00`,
      type: types,
      jobId,
      jobType,
      reminderDate: reminderDates,
      relatedToTask: relatedToTask == true ? true : false,
      relatedToJob: relatedToJob == true ? true : false,
      assignStaffs: [{ UserId: Number(assignStaffss) }],
    };
    dispatch(addFollowIternary(leadId, followId, datta))
      .then((res) => {
        if (res.status === 201) {
          toast.success("Add Internity Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            subject: "",
            descriptions: "",
            location: "",
            date: "",
            fromTime: "",
            toTime: "",
            type: "",
            reminderDates: "",
            jobType: "",
            jobId: "",
            relatedToTask: "",
            relatedToJob: "",
            TaskId: "",
            assignStaffss: "",
            AddItineraryModal: false,
            day: "",
            week: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, AddItineraryModal: false });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AddItineraryModal: false });
      });
  };

  const handleScheduleNewPaymentModalShow = () => {
    updateState({
      ...iState,
      ScheduleNewPaymentModal: true,
    });
  };

  useEffect(() => {
    if (ScheduleNewPaymentModal) {
      dispatch(getSchedulePayment(contractId));
    }
  }, [ScheduleNewPaymentModal]);

  const handleScheduleNewPaymentModalClose = () => {
    updateState({
      ...iState,
      ScheduleNewPaymentModal: false,
    });
  };

  const handleAddNewTransactionsModalShow = () => {
    updateState({
      ...iState,
      AddNewTransactionsModal: true,
      ScheduleNewPaymentModal: false,
    });
  };

  const handleAddNewTransactionsModalClose = () => {
    updateState({
      ...iState,
      AddNewTransactionsModal: false,
    });
  };

  const handlePostTransactionModalShow = (item) => {
    updateState({
      ...iState,
      PostTransactionModal: true,
      transId:item.id,
      credit:item.credit,
      debit:item.debit,
    });
  };

  const handlePostTransactionModalClose = () => {
    updateState({
      ...iState,
      PostTransactionModal: false,
    });
  };

  const handleOperationChange = (e) => {
    updateState({ ...iState, operation: e.target.value });
  };

  const handlePostalCheckboxChange = (e, types) => {
    if (types === "debit") {
      updateState({
        ...iState,
        debitSubAccountPosting: !debitSubAccountPosting,
      });
    } else {
      updateState({
        ...iState,
        creditSubAccountPosting: !creditSubAccountPosting,
      });
    }
  };

  const handlePostalTransaction = (type) => {
    const datta = {
      operation,
      credit,
      creditSubAccountPosting,
      creditAccountId,
      debit,
      debitSubAccountPosting,
      debitAccountId,
      isPostal: type === "postal" ? true : false,
    };
    dispatch(updatePostalTransaction(contractId,transId, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getTransactionList(contractId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({...iState,PostTransactionModal:true})
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({...iState,PostTransactionModal:false})
      }
    });
  };
  const handleSupportingDocumentsListModalShow = (id) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
      transId: id,
    });
  };

  useEffect(() => {
    if (SupportingDocumentsListModal) {
      dispatch(getAllSupportingDocsTransactions(transId));
    }
  }, [SupportingDocumentsListModal]);
  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  
  const handlePrints = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location.reload();
  };
  
  
  const handleDownload = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    const blob = new Blob([printContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'agreement_view.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const [JobMenuModal, setJobMenuModal] = useState(false);
  const handleJobMenuModalClose = () => setJobMenuModal(false);
  const handleJobMenuModalShow = () => setJobMenuModal(true);

  const handleNavigateModalShow = () => {
    updateState({ ...iState, NavigateModal: true });
  };
  const handleNavigateModalClose = () => {
    updateState({ ...iState, NavigateModal: false });
  };
  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TodosArea">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="TitleBox bg-none border-0 p-0">
                  <h4 className="Title">Accounting</h4>
                </div>
                <div className="CommonTabs mt-3">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="ClientContractsTab"
                        data-toggle="tab"
                        href="#ClientContracts"
                      >
                        <span>
                          <img src="images/client_contracts.png" />
                        </span>
                        Sales Client Information
                      </a>
                      <span className="Count">3</span>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className="nav-link"
                        id="NewTransactionQueueTab"
                        data-toggle="tab"
                        href="#NewTransactionQueue"
                      >
                        <span>
                          <img src="images/new_transaction_queue.png" />
                        </span>
                        Transaction Manager
                      </a>
                      <span className="Count">3</span>
                    </li> */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="ClientTransactionsTab"
                        data-toggle="tab"
                        href="#ClientTransactions"
                      >
                        <span>
                          <img src="images/new_transaction_queue.png" />
                        </span>
                        Client Transactions
                      </a>
                      <span className="Count">3</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{
                textAlign: "center"
              }}>
              <div className="TodosButtons mt-0">
                <a href="javascript:void(0)" id="PajetBtn">
                  <img src="images/create_new_lead.png" />
                  Post All Journal Entry Transactions{" "}
                  <span className="Count">3</span>
                </a>
                <a
                  onClick={() => handleViewConsilatedDocumentModalShow()}
                  id="ConsolidateEstimationBtn"
                >
                  View Consolidated Estimation Documents
                </a>
              </div>
              <div className="TitleBoxNavigate">
              
              <div className="NavigateButton gap-2">
                <button className="greenBtn" onClick={handleJobMenuModalShow}>Job Menu</button>
                <button className="ml-2 greenBtn" onClick={handleNavigateModalShow}>
                  Navigate
                </button>
              </div>
            </div>
              
              </div>
            </div>
          </div>
       
          <div className="tab-content">
            <div className="tab-pane fade show active" id="ClientContracts">
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Sales Client Information</h4>
                  </div>
                  <div className="NewActions">
                    <a href="Javascript:void(0);">
                      <img src="images/icon-64.png" />
                    </a>
                    <a onClick={handlePrints} title="print">
                    <img src="images/icon-53.png" />
                  </a>
                  <a onClick={handleDownload} title="download">
                    <img src="images/download.png" />
                  </a>
                    {/* <a href="Javascript:void(0);">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="AgreementViewArea" id="agreement-view-area">
                <div className="AgreementViewTop">
                  {/* <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a> */}
                  {/* <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div> */}
                </div>
                {/* <aside>
                  <p>
                    <label>Table :</label>
                    <span>Warranty Request</span>
                  </p>
                  <p>
                    <label>Project :</label>
                    <span>{state?.ProjectId}</span>
                  </p>
                  <p>
                    <label>Job :</label>
                    <span>{state?.id} </span>
                  </p>
                </aside> */}
              <div className="TableList CommonHeading TableListDecoration TableMinimumHeight">
                <table>
                  <thead>
                    <tr>
                      <th>Client Contact</th>
                      <th>Date Created</th>
                      <th>Sale Type</th>
                      <th>Client Assigned Unit</th>
                      <th>Client Purchase Price</th>
                      <th>Status</th>
                      <th>Parking Options</th>
                      <th>Change Orders</th>
                      {/* <th>Scheduled Payments</th>
                      <th>Transactions</th> */}
                      <th>SOA Status</th>
                      <th>View SOA</th>
                      <th>Next Scheduled Transaction</th>
                      <th>Assigned to</th>
                      <th>Discussion</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!contractLoader ? (
                      contractList?.contracts?.length > 0 ? (
                        contractList?.contracts?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                <div
                                  className="SalesPersonHoverName TextBlue"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Client Details"
                                >
                                  {item?.clients?.[0]?.legalName ?? "N/A"}
                                </div>
                              </td>
                              <td>
                                {" "}
                                {moment(item?.clients?.[0]?.createdAt).format(
                                  "YYYY-MM-DD"
                                )}
                              </td>
                              <td>{"N/A"}</td>
                              <td>
                                <div className="TextBlue">{"N/A"}</div>
                              </td>
                              <td>${item.clientPurchasePrice ?? 0}</td>
                              <td>{item.contractStatus ?? "N/A"}</td>
                              <td>$0</td>
                              <td>$0</td>
                              {/* <td>
                                <div
                                  className="CursorPointer"
                                  onClick={() =>
                                    handleScheduledPaymentsModalShow(item.id)
                                  }
                                >
                                  <img src="images/schedule_payment.png" />
                                </div>
                              </td> */}
                              {/* <td>
                                <div
                                  className="CursorPointer"
                                  onClick={() =>
                                    handleTransactionsModalShow(item.id)
                                  }
                                >
                                  <img src="images/add_transactions.png" />
                                </div>
                              </td> */}
                              <td>
                                <div className="TextRed">
                                  {item?.ContractScheduOfPays?.[0]
                                    ?.transactionType ?? "N/A"}
                                </div>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    onClick={() =>
                                      handleViewSoaModalShow(item.id)
                                    }
                                  >
                                    <img src="images/visa_icon.png" />
                                  </a>
                                  <a
                                    onClick={() =>
                                      handleSOAHistoryModalShow(item.id)
                                    }
                                  >
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                {moment(
                                  item.ContractScheduOfPays?.[0]?.scheOfPayDate
                                ).format("YYYY-MM-DD")}
                              </td>
                              <td>
                                <div className="Action text-center">
                                  <a
                                    onClick={() =>
                                      handleAssignMemberModalShow(item.id)
                                    }
                                    data-original-title="staff member profile"
                                  >
                                    <img src="images/icon-32.png" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="text-center"
                                  onClick={() =>
                                    handleFollowUpActivityModalShow(item.LeadId)
                                  }
                                >
                                  <a>
                                    <img src="images/discussion_icon.png" />
                                  </a>
                                  <span
                                    className="Count"
                                    data-toggle="modal"
                                    data-target="#DiscussionModal"
                                  >
                                    3
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol className="dropdown-menu dropdown-menuwidth2">
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#CreateNewContract"
                                          >
                                            <img src="images/client_contracts_details.png" />{" "}
                                            Client Contract Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#EditContactInformation"
                                          >
                                            <img src="images/edit_contact.png" />
                                            Edit Contact Information
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#LandDevelopmentDetailsAdministration"
                                          >
                                            <img src="images/view_update.png" />
                                            View/Update job details
                                          </a>
                                        </li>
                                        <li>
                                          {/* <a
                                            onClick={() =>
                                              handleScheduledPaymentsModalShow(
                                                item.id
                                              )
                                            }
                                          >
                                            <img src="images/schedule_payment.png" />{" "}
                                            Scheduled Payments
                                          </a> */}
                                        </li>
                                        {/* <li>
                                          <a
                                            onClick={() =>
                                              handleTransactionsModalShow(
                                                item.id
                                              )
                                            }
                                          >
                                            <img src="images/add_transactions.png" />{" "}
                                            Transactions
                                          </a>
                                        </li> */}
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleViewSoaModalShow(item.id)
                                            }
                                          >
                                            <img src="images/visa_icon.png" />
                                            View SOA
                                          </a>
                                        </li>
                                        {/* <li>
                                          <a href="javascript:void(0);">
                                            <img src="images/cancel_qr.png" />
                                            Decline/Revert SOA
                                          </a>
                                        </li> */}
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )
                    ) : (
                      <Loader />
                    )}
                  </tbody>
                </table>
              </div>
              </div>
            </div>
            <div className="tab-pane fade" id="NewTransactionQueue">
              <div className="TableHeadingArea mt-5">
                <div className="FilesHead">
                  <div className="FilesLeft">
                    <h4>Transactions</h4>
                  </div>
                  <div className="NewActions">
                    <a href="Javascript:void(0);">
                      <img src="images/icon-64.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/icon-53.png" />
                    </a>
                    <a href="Javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    {/* <a href="Javascript:void(0);">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="TableList CommonHeading TableMinimumHeight TableCheckBox">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </th>
                      <th>Client Contact</th>
                      <th>Job Assigned</th>
                      <th>Transaction ID#</th>
                      <th>Transaction/ Adjustment Date</th>
                      <th>Payment Method</th>
                      <th>Transaction Type</th>
                      <th>Total</th>
                      <th>Notes</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Status</th>
                      <th>Supporting Documents</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>
                        <div className="TextBlue">John Contact</div>
                      </td>
                      <td>U38-ARB-1B-243</td>
                      <td>DP-ARB-1B-243-1</td>
                      <td>06/14/20 23</td>
                      <td>Mastercard</td>
                      <td>DP: Down Payment Deposit</td>
                      <td>$1,000.00</td>
                      <td>Initial deposit</td>
                      <td />
                      <td />
                      <td>
                        <button className="Commited">Commited</button>
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs2"
                          >
                            <img src="images/icon-63.png" />
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            <span className="Count">3</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="modal"
                                    data-target="#PostTransactionTable"
                                  >
                                    <img src="images/Action-1.png" />
                                    Postal Transaction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/contact-list.png" />
                                    Void Transaction
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="tab-pane fade" id="ClientTransactions">
              {/* <div className="CommonSelectBoxForm">
                <div className="form-group">
                  <h6>Job</h6>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: 5,
                      marginLeft: 15,
                    }}
                  >
                    <div className="CommonSelectBox">
                      <select>
                        <option selected="">Select Job</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
              {contractList?.contracts?.length > 0 ? (
                <>
                  <div className="ModalMediumheading">
                    <h3 style={{ marginBottom: "0px!important" }}>
                      <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
                    </h3>
                  </div>
                  <div className="TableHeadingArea">
                    <div className="FilesHead">
                      <div className="FilesLeft">
                        <h4>Transactions</h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="TodosButtons">
                          <a
                            onClick={() => handleScheduleNewPaymentModalShow()}
                            className="ml-0"
                          >
                            <h6>
                              <img src="images/show-create-purchase.png" />
                              Schedule New Payment
                            </h6>
                          </a>
                          <a
                            onClick={() => handleAddNewTransactionsModalShow()}
                            className="ml-0"
                          >
                            <h6>
                              <img src="images/show-create-purchase.png" />
                              Add New Transaction
                            </h6>
                          </a>
                        </div>
                        <div className="NewActions">
                          <a href="Javascript:void(0);">
                            <img src="images/icon-64.png" />
                          </a>
                          <a href="Javascript:void(0);">
                            <img src="images/icon-53.png" />
                          </a>
                          <a href="Javascript:void(0);">
                            <img src="images/download.png" />
                          </a>
                          {/* <a href="Javascript:void(0);">
                            <img src="images/setting.png" />
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="TableList CommonHeading TableMinimumHeight">
                    <table>
                      <thead>
                        <tr>
                          {/* <th>Job Assigned</th> */}
                          <th>Transaction ID#</th>
                          <th>Transaction/ Adjustment Date</th>
                          <th>Payment Method</th>
                          <th>Transaction Type</th>
                          <th>Total</th>
                          <th>Notes</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Supporting Documents</th>
                          <th>Status</th>
                          <th>Ready for Posting</th>
                          <th>Select for Posting</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionList?.length > 0 ? (
                          transactionList?.map((item, i) => {
                            return (
                              <tr key={i}>
                                {/* <td>
                        <div className="TextBlue">
                          ADRJ: Revenue Adjustments
                        </div>
                      </td> */}
                                <td>{item.tranId}</td>
                                <td>
                                  {moment(item.tranDate).format("YYYY-MM-DD")}
                                </td>
                                <td>{item.payMethod}</td>
                                <td>{item.tranType}</td>
                                <td>${Number(item.total ?? 0).toFixed(2)}</td>
                                <td>{item.notes}</td>
                                <td />
                                <td />
                                <td>
                                  <div className="Actions">
                                    <a
                                      onClick={() =>
                                        handleAddNewSupportingDocsModalShow(
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/icon-63.png" />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSupportingDocumentsListModalShow(
                                          item.id
                                        )
                                      }
                                    >
                                      <span className="Count">3</span>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    className={`${
                                      item.tranStatus === "Committed"
                                        ? "Commited"
                                        : item.tranStatus === "Void"
                                        ? "Void"
                                        : item.tranStatus === "Posted"
                                        ? "Posted"
                                        : ""
                                    }`}
                                  >
                                    {item.tranStatus}
                                  </button>
                                </td>
                                <td>
                                  <div className="Actions TextYellow"></div>
                                </td>
                                <td>
                                  <label className="CheckBox">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                  </label>
                                </td>
                                <td>
                                  <div className="TableThreeDots">
                                    <ul className="">
                                      <li className="dropdown">
                                        <a
                                          className=""
                                          role="button"
                                          data-toggle="dropdown"
                                        >
                                          <img src="images/dots.png" />
                                        </a>
                                        <ol className="dropdown-menu dropdown-menuwidth">
                                          <li>
                                            <a
                                              onClick={() =>
                                                handlePostTransactionModalShow(
                                                  item
                                                )
                                              }
                                            >
                                              <img src="images/Action-1.png" />
                                              Postal Transaction
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleChangeStatusToVoid(
                                                  item.id
                                                )
                                              }
                                            >
                                              <img src="images/contact-list.png" />
                                              Void Transaction
                                            </a>
                                          </li>
                                        </ol>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="Button d-flex justify-content-between mt-4">
                <button className="Create">Save</button>
                <button className="Create">
                  Post all Selected Transactions
                </button>
                <button className="Outline">Cancel</button>
              </div> */}
                </>
              ) : (
                <h1>No Contract Is Availabe</h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={TransactionsModal}
        onHide={handleTransactionsModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleTransactionsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Transactions</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
              </h3>
              <h3>
                <span>Job Assigned:</span>{state?.id??"N/A"}
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Create New Transaction</h6>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Supporting Documents</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          name="tranDate"
                          value={tranDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="payMethod"
                          value={payMethod}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Payment Method</option>
                          <option value="Adjustment">Adjustment</option>
                          <option value="American Express">
                            American Express
                          </option>
                          <option value="Bank Draft">Bank Draft</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="Direct Deposit">Direct Deposit</option>
                          <option value="Journal Entry">Journal Entry</option>
                          <option value="MasterCard">MasterCard</option>
                          <option value="Visa">Visa</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="tranType"
                          value={tranType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Transaction Type</option>
                          <option value="DP">DP</option>
                          <option value="CO">CO</option>
                          <option value="OP">OP</option>
                          <option value="ADJ">ADJ</option>
                          <option value="ADJR">ADJR</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="total"
                          value={total}
                          min={0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Notes"
                          name="notes"
                          value={notes}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs16"
                          data-dismiss="modal"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList16"
                          data-dismiss="modal"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <div
                        className="TableButtons"
                        onClick={handleCreateContract}
                      >
                        <button className="BrandBtn">
                          Create New Transaction
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Transaction</h4>
                </div>
                <div className="d-flex align-items-center">
                  <div className="Search">
                    <span>
                      <img src="images/search.png" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      name="search"
                      value={search}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="FilesRight">
                    <a href="#">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="#">
                      <img src="images/download.png" />
                    </a>
                    {/* <a href="#">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableListLarge">
              <table>
                <thead>
                  <tr>
                    <th>Job Assigned</th>
                    <th>Transaction ID#</th>
                    <th>Transaction/Adjustment Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Supporting Documents</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!transactionLoader ? (
                    transactionList?.length > 0 ? (
                      transactionList
                        ?.filter((data) =>
                          search.toLowerCase() === ""
                            ? true
                            : data.notes &&
                              data.notes
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        )

                        .map((item, i) => {
                          return (
                            <tr key={item.tranId}>
                              <td>
                                <div className="TextBlue">====</div>
                              </td>
                              <td>{item.tranId}</td>
                              <td>
                                {moment(item.tranDate).format("YYYY-MM-DD")}
                              </td>
                              <td>{item.payMethod}</td>
                              <td>{item.tranType}</td>
                              <td>${item.total ?? 0}</td>
                              <td>{item.notes}</td>
                              <td />
                              <td />
                              <td>
                                <div className="Actions">
                                  <a
                                    onClick={() =>
                                      handleAddNewSupportingDocsModalShow(
                                        item.id
                                      )
                                    }
                                    className="FolderPermissionId"
                                  >
                                    <img src="images/icon-63.png" alt="icon" />
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    className="FolderPermissionId"
                                    data-toggle="modal"
                                    data-target="#SupportingDocumentList16"
                                    data-dismiss="modal"
                                  >
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>
                                <button className="Commited">
                                  {item.tranStatus}
                                </button>
                              </td>
                              <td>
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className="javascript:void(0);"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" alt="dots" />
                                      </a>
                                      <ol className="dropdown-menu" style={{}}>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            className="FolderPermissionId"
                                            data-toggle="modal"
                                            data-target="#PostTransactionDesktop"
                                            data-dismiss="modal"
                                          >
                                            <img
                                              src="images/post_transaction.png"
                                              alt="post"
                                            />
                                            Post Transaction
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleChangeStatusToVoid(item.id)
                                            }
                                          >
                                            <img
                                              src="images/void_transaction.png"
                                              alt="void"
                                            />
                                            Void Transaction
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>

            <div className="Button mt-5 d-flex justify-content-between">
              <button className="Create" onClick={handleCreateContract}>
                Save
              </button>
              <button
                className="Outline"
                onClick={handleTransactionsModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={(e) => handleFileChange(e, "supportDocs")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {files && (
                <div className="FilesAdded">
                  <label>{files.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveClientFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" onClick={handleSupportDocs}>
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AssignMemberModal}
        onHide={handleAssignMemberModalClose}
        className="ModalBox modal fade ExtraSmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAssignMemberModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ContactTopBox">
              <div className="SalesPersonarea">
                <span>MB</span>
                <h6>{primaryClientsDetails?.clientDetails?.[0]?.legalName}</h6>
              </div>
            </div>
            <div className="FolderForm">
              <div className="SendEmailText">
                <p>
                  <img src="images/send-email.png" />
                  Send E-mail
                </p>
                <p className="SendText SendText1">
                  <img src="images/send-text.png" />
                  Send Text
                </p>
                <div
                  className="SendTextSearch SendTextSearch1"
                  style={{ display: "none" }}
                >
                  <input type="text" />
                  <button>Apply</button>
                </div>
              </div>
              <div className="MolalContactForm mb-4">
                <h6>Contact</h6>
                <p>
                  <img src="images/send-email.png" />
                  {primaryClientsDetails?.clientDetails?.[0]?.email}
                </p>
                <p className="mb-4">
                  <img src="images/send-text.png" />
                  {primaryClientsDetails?.clientDetails?.[0]?.primaryPhoneNum}
                </p>
                {/* <a href="javascript:void(0);" className="ShowMoreBtn">
                  Show more
                </a>
                <a href="javascript:void(0);" className="ShowLessBtn">
                  Show less
                </a> */}
              </div>
              <div className="form-group mt-3">
                <div className="ContactModalAddressArea">
                  <h6>Address</h6>
                  <p>
                    {primaryClientsDetails?.clientDetails?.[0]
                      ?.addressLineTwo ?? ""}{" "}
                    <br />
                    {primaryClientsDetails?.clientDetails?.[0]?.streetAddress ??
                      ""}
                    <img src="images/location.png" />
                  </p>
                </div>
              </div>
              <div className="DiscussionHide">
                <div className="form-group mb-0">
                  <h6>Discussion</h6>
                  <input
                    type="text"
                    placeholder="Write here ....."
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ScheduledPaymentsModal}
        onHide={handleScheduledPaymentsModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleScheduledPaymentsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Scheduled Payments</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
              </h3>
              <h3>
                <span>Job Assigned:</span>{state?.id??"N/A"}
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Schedule of Payments</h6>
              <div className="Actions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Scheduled Payment Date</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {schedulePaymentList?.length > 0 ? (
                    schedulePaymentList?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {moment(data.scheOfPayDate).format("YYYY-MM-DD")}
                          </td>
                          <td>{data.transactionType}</td>
                          <td>${data.total}</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className="javascript:void(0);"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol
                                    className="dropdown-menu dropdown-menuwidth2"
                                    style={{}}
                                  >
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="FolderPermissionId"
                                        onClick={() =>
                                          handleEditSchedulePaymentModalShow(
                                            data
                                          )
                                        }
                                      >
                                        <img src="images/Action-1.png" />
                                        Edit Scheduled Payment
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDeleteShow(data.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Scheduled Payment
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="FolderPermissionId"
                                        data-toggle="modal"
                                        data-target="#Transactions"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/add_transactions.png" />
                                        Create New Transaction Entry
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="FolderPermissionId"
                                        data-toggle="modal"
                                        data-target="#SetReminderModalSchedule"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/timer.png" />
                                        Set Payment Reminder
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          name="scheOfPayDate"
                          value={scheOfPayDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="transactionType"
                          value={transactionType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Transaction Type</option>
                          <option value="ADJ: Other Adjustments">
                            ADJ: Other Adjustments
                          </option>
                          <option value="ADJR: Revenue Adjustments">
                            ADJR: Revenue Adjustments
                          </option>
                          <option value="CO: Change Order Deposit">
                            CO: Change Order Deposit
                          </option>
                          <option value="DP: Down Payment Deposit">
                            {" "}
                            DP: Down Payment Deposit
                          </option>
                          <option value="OP: Offer to Purchase">
                            OP: Offer to Purchase
                          </option>
                          <option value="Balance Due on Closing">
                            Balance Due on Closing
                          </option>
                          <option value="Closing Transactions">
                            Closing Transactions
                          </option>
                          <option value="Revenue - Change Order">
                            Revenue - Change Order
                          </option>
                          <option value="Revenue - Contract Price (Pre-Tax)">
                            Revenue - Contract Price (Pre-Tax)
                          </option>
                          <option value="Revenue - Parking">
                            Revenue - Parking
                          </option>
                          <option value="Tax - Base">Tax - Base</option>
                          <option value="Tax - Rebate">Tax - Rebate</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="totalSchedule"
                          value={totalSchedule}
                          min={0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      {/* <div className="TableThreeDots">
              <ul className="">
                <li className="dropdown">
                  <a
                    className="javascript:void(0);"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/dots.png" />
                  </a>
                  <ol className="dropdown-menu dropdown-menuwidth2" style={{}}>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#ScheduledPaymentsEdit"
                        data-dismiss="modal"
                      >
                        <img src="images/Action-1.png" />
                        Edit Scheduled Payment
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/dropdown-delete.png" />
                        Remove Scheduled Payment
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#Transactions"
                        data-dismiss="modal"
                      >
                        <img src="images/add_transactions.png" />
                        Create New Transaction Entry
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#SetReminderModalSchedule"
                        data-dismiss="modal"
                      >
                        <img src="images/timer.png" />
                        Set Payment Reminder
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="TableButtons"
                        onClick={handleSchedulePayment}
                      >
                        <button className="BrandBtn">
                          Schedule New Payment
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              {/* <button className="Create" data-dismiss="modal">
                Save
              </button> */}
              <button
                className="Outline"
                onClick={handleScheduledPaymentsModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={DeleteModal}
        onHide={handleDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleDeleteAssign}>
                      Delete
                    </button>
                    <button className="Outline" onClick={handleDeleteClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EditSchedulePaymentModal}
        onHide={handleEditSchedulePaymentModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleEditSchedulePaymentModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit Scheduled Payments</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
              </h3>
              <h3>
                <span>Job Assigned:</span>{state?.id??"N/A"}
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Schedule of Payments</h6>
              <div className="Actions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Scheduled Payment Date</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          name="scheOfPayDate"
                          value={scheOfPayDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="transactionType"
                          value={transactionType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Transaction Type</option>
                          <option value="ADJ: Other Adjustments">
                            ADJ: Other Adjustments
                          </option>
                          <option value="ADJR: Revenue Adjustments">
                            ADJR: Revenue Adjustments
                          </option>
                          <option value="CO: Change Order Deposit">
                            CO: Change Order Deposit
                          </option>
                          <option value="DP: Down Payment Deposit">
                            {" "}
                            DP: Down Payment Deposit
                          </option>
                          <option value="OP: Offer to Purchase">
                            OP: Offer to Purchase
                          </option>
                          <option value="Balance Due on Closing">
                            Balance Due on Closing
                          </option>
                          <option value="Closing Transactions">
                            Closing Transactions
                          </option>
                          <option value="Revenue - Change Order">
                            Revenue - Change Order
                          </option>
                          <option value="Revenue - Contract Price (Pre-Tax)">
                            Revenue - Contract Price (Pre-Tax)
                          </option>
                          <option value="Revenue - Parking">
                            Revenue - Parking
                          </option>
                          <option value="Tax - Base">Tax - Base</option>
                          <option value="Tax - Rebate">Tax - Rebate</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="totalSchedule"
                          value={totalSchedule}
                          min={0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                  </tr>

                  {/* <tr>
          <td colSpan={4}>
            <div className="TableButtons">
              <button className="BrandBtn">Add Line</button>
            </div>
          </td>
        </tr> */}
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button className="Create" onClick={handleUpdateSchedulePayment}>
                Update
              </button>
              <button
                className="Outline"
                onClick={handleEditSchedulePaymentModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SOAHistoryModal}
        onHide={handleSOAHistoryModalClose}
        className="ModalBox modal fade MediumModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSOAHistoryModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>SOA History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Job Id:</span>&nbsp;U231-CHI-2B-243
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll TableLeft">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td>Jane Williams</td>
                    <td>04/25/2023</td>
                    <td>Sent to Lawyer LL laywers</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={FollowUpActivityModal}
        onHide={handleFollowUpActivityModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFollowUpActivityModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Follow Up Activity</h4>
            </div>
            <div className="FolderForm">
              <div className="TableList CommonHeading mt-4 TableHeight500">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>Type</th>
                      <th>Sent By</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {followUpList?.listFollowUps?.length > 0 ? (
                      followUpList?.listFollowUps?.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <div className="ActionsWidth">
                                <img src="images/meeting_icon.png" />
                              </div>
                            </td>
                            <td>{item.sendType}</td>
                            <td>{item.sendBy}</td>
                            <td>
                              {moment(item.activityDate).format(
                                "YYYY-MM-DD & A"
                              )}
                            </td>
                            <td>{item.scheduleStatus}</td>
                            <td>
                              <div className="dropdown CursorPointer show">
                                <p data-toggle="dropdown" aria-expanded="true">
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      href="javascript:void(0);"
                                      data-toggle="modal"
                                      data-target="#EditFollowUpActivityDiscussion"
                                      data-dismiss="modal"
                                    >
                                      <img src="images/icon-15.png" />
                                      Edit Activity Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleCancelActivityModalShow(
                                          item.LeadId,
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/dropdown-delete.png" />
                                      Cancel Activity
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      onClick={() =>
                                        handleAddItineraryModalShow(
                                          item.LeadId,
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/send_to_itinary.png" />
                                      Send to itinery
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu submenuLead">
                                      <li>
                                        <a
                                          onClick={() => handleGoogleData(item)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() =>
                                            handleOutLookData(item)
                                          }
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          onClick={() => handleEmailData(item)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>

                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleFollowReminderModalShow(
                                          item.LeadId,
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleSendInvitation(
                                          item.LeadId,
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/send_invitaiton.png" />
                                      Send Invitation
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item FolderPermissionId"
                                      onClick={() =>
                                        handleUpdateAssignStaffMemberShow(
                                          item.LeadId,
                                          item.id
                                        )
                                      }
                                    >
                                      <img src="images/assign_icon.png" />
                                      Assign Staff member
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="PaginationArea">
                <h6>Showing 1 to 10 of 27 entries</h6>
                <h6>
                  <img src="images/leftarrow.png" /> 01{" "}
                  <img src="images/rightarrow.png" /> Out 10
                </h6>
              </div>
              <div className="Button text-center">
                <button
                  className="Outline ClosePermissionId"
                  onClick={handleFollowUpActivityModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={CancelActivityModal}
        onHide={handleCancelActivityModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleCancelActivityModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this Activity?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleActivityDelete}>
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleCancelActivityModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={FollowReminderModal}
        onHide={handleFollowReminderModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleFollowReminderModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Reminder</h4>
            </div>
            <div className="FolderForm">
              <h6>Date</h6>
              <div className="Categories Categories1">
                <div className="Categories_all Categories_all1">
                  <span>Select aumont of time for setReminder</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li
                    style={{ color: setReminder === 0 ? "red" : "black" }}
                    onClick={() => handleMinuteClick(0)}
                  >
                    -0 minutes
                  </li>
                  <li
                    style={{ color: setReminder === 5 ? "red" : "black" }}
                    onClick={() => handleMinuteClick(5)}
                  >
                    -5 minutes
                  </li>
                  <li
                    style={{ color: setReminder === 15 ? "red" : "black" }}
                    onClick={() => handleMinuteClick(15)}
                  >
                    -15 minutes
                  </li>
                </ul>
              </div>
              <div className="Button text-center mt-5">
                <button className="Create mr-4" onClick={handleFollowReminder}>
                  Set Reminder
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UpdateAssignStaffMemberModal}
        onHide={handleUpdateAssignStaffMemberClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleUpdateAssignStaffMemberClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Update Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3> Staff Member Information</h3>
                  <h6>Name</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      <span>Select user role</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      {memberList?.staff?.length > 0 &&
                        memberList?.staff?.map((item, i) => {
                          return (
                            <li
                              // value={item.id}
                              key={i}
                              onClick={() => handleMemberClick(item.id)}
                              style={{
                                backgroundColor:
                                  assignStaffId === item.id
                                    ? "#e71e09"
                                    : "transparent",
                              }}
                            >
                              {item.email}
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssignStaffMember}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewConsilatedDocumentModal}
        onHide={handleViewConsilatedDocumentModalClose}
        className="ModalBox modal fade LargeModal LeftBorder show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewConsilatedDocumentModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Consolidated Estimation Documents List</h4>
            </div>
            <div className="ModalMediumheading">
              <h3>
                <span>Project:{state?.ProjectId}</span>
              </h3>
            </div>
            <div className="TableHeadingArea">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Estimation Documents</h4>
                </div>
                <div className="d-flex align-items-center">
                  <div className="Search">
                    <span data-toggle="modal" data-target="#NotesList2">
                      <img src="images/search.png" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      name="estimationDocumentSearch"
                      value={estimationDocumentSearch}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="FilesRight">
                    <a href="javascript:void(0);" title="pdf">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);" title="download">
                      <img src="images/download.png" />
                    </a>
                    {/* <a href="javascript:void(0);" title="settings">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>Job id</th>
                    <th>Doc Type</th>
                    <th>#</th>
                    <th>Vendor</th>
                    <th>Pre-Tax Total</th>
                    <th>Date Posted</th>
                    <th>Account Description</th>
                    <th>Posted By</th>
                    <th>Status</th>
                    <th>QBO Link</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(estimationDocumentList?.data) &&
                  estimationDocumentList?.data?.length > 0 ? (
                    estimationDocumentList?.data
                      ?.filter((data) =>
                        estimationDocumentSearch?.toLowerCase() === ""
                          ? true
                          : data?.documentType
                              ?.toLowerCase()
                              ?.includes(
                                estimationDocumentSearch?.toLowerCase()
                              )
                      )
                      ?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <label className="CheckBox">
                                <input
                                  type="checkbox"
                                  checked={selectedItems.includes(item)}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, item)
                                  }
                                />
                                <span className="checkmark" />
                              </label>
                            </td>
                            <td className="BorderCyan">{item.job?.id??"N/A"}</td>
                            <td>{item.documentType}</td>
                            <td>{item.documentId}</td>
                            <td>{item?.Vendor?.vendorName ?? "N/A"}</td>
                            <td>${item.subtotal??0}</td>
                            <td>
                              {moment(item.createdAt).format(
                                "YYYY-MM-DD & hh:mm a"
                              )}
                            </td>
                            <td>{item?.CostCode?.name ?? "N/A"}</td>
                            <td>{"N/A"}</td>
                            <td>{item.status}</td>
                            <td>
                              {
                                item?.qboLink&&
                                <a href={item.qboLink} target="_blank">
                                {item.qboLink}
                              </a>
                              }
                             
                            </td>

                            <td>
                              <div className="DetailsDots">
                                <div className="TableThreeDots">
                                  <ul className="">
                                    <li className="dropdown">
                                      <a
                                        className=""
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src="images/dots.png" />
                                      </a>
                                      <ol
                                        className="dropdown-menu dropdown-menuwidth"
                                        style={{}}
                                      >
                                        <li>
                                          <a>
                                            <img src="images/view-po.png" />
                                            View Document
                                          </a>
                                        </li>
                                        <li>
                                          <a onClick={handleCancelSelected}>
                                            <img src="images/add.png" />
                                            Cancel Document
                                          </a>
                                        </li>
                                      </ol>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button className="Create" onClick={handleCancelSelected}>
                Cancel Selected
              </button>
              {/* <button className="Outline ml-3" data-dismiss="modal">
      View Selected
    </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewSoaModal}
        onHide={handleViewSoaModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleViewSoaModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Statement of Adjustments</h4>
            </div>
            <div className="FolderForm">
              <div className="ModalBigheading">
                <h3>
                  <span>Status: Pending Accountant Release</span>
                </h3>
              </div>
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a>
                  <div className="AgreementViewTopRight">
                    <h3>iBuild</h3>
                    <p>312 Weddenburn Rd SE</p>
                    <p>Calgary, AB CA, T2J 1J1</p>
                    <p>
                      4034005933 www.ibuildapplications.com
                      linden@ibuildapplications.com
                    </p>
                    <p>
                      “The bitterness of poor quality remains long after the
                      sweetness of meeting
                      <br />
                      the schedule has been gorgotten”
                    </p>
                  </div>
                </div>
                <div className="StatementAdjustmentArea px-4">
                  <h6>Statement of Adjustments</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.legalName ??
                            "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]
                              ?.addressLineTwo ?? "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.city ?? "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>State/Provice</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.province ??
                            "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>Possession Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={moment(
                            soaDetails?.contract?.clients?.[0]?.createdAt ??
                              "N/A"
                          ).format("YYY-MM-DD")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date Prepared</label>
                        <input
                          type="text"
                          className="form-control"
                          value={moment(
                            soaDetails?.contract?.clients?.[0]?.createdAt ??
                              "N/A"
                          ).format("YYY-MM-DD")}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>Job Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.jobId ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Sales Consultant</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.consult ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Model</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.model ?? "N/A"
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Contract Date</label>
                        <input
                          type="text"
                          className="form-control"
                          value={moment(
                            soaDetails?.contract?.clients?.[0]?.createdAt
                          ).format("YYY-MM-DD")}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">JOB DETAILS</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]
                              ?.addressLineTwo ?? "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.city ?? "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>State/Provice</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.clients?.[0]?.province ??
                            "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <label>Subdivision</label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            soaDetails?.contract?.contract?.subDivision ?? "N/A"
                          }
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Lot</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>Block</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>Plan</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label>Sale Type</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <h6 className="Title">Sale Revenues</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>
                          Contract Purchase Agreement Price (Pre-Tax)
                        </label>
                        <p className="LightGreen">$500,000.00</p>
                      </div>
                      <h6 className="Title">ADD: Tax</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>ADD: Tax</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="StatementAdjustmentBottom">
                        <label>Applied Tax</label>
                        <p className="LightGrey">5.00%</p>
                        <p className="LightRed">$25,000.00</p>
                      </div>
                      <h6 className="Title">Less: Deposit</h6>
                      <div className="StatementAdjustmentTable">
                        <table>
                          <thead>
                            <tr>
                              <th>Notes</th>
                              <th>Transaction ID#</th>
                              <th>Date</th>
                              <th>Tx</th>
                              <th>Tx Type</th>
                              <th>
                                Supporting <br /> Documents
                              </th>
                              <th>Status</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Condition Removal Deposit</td>
                              <td>OP-CHI-2B-243-1</td>
                              <td>03/30/20 21</td>
                              <td>CH</td>
                              <td>OP</td>
                              <td>
                                <div className="Actions">
                                  <a href="javascript:void(0);">
                                    <img src="images/icon-63.png" />
                                  </a>
                                  <a href="javascript:void(0)">
                                    <span className="Count">3</span>
                                  </a>
                                </div>
                              </td>
                              <td>Commited</td>
                              <td>
                                <div className="BgBlue">$35,000.00</div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={6}>Down Payment Amount Required</td>
                              <td colSpan={2}>
                                <div className="BgBlue">$0.00</div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={6}>Total Deposit</td>
                              <td colSpan={2}>
                                <div className="BgBlue">$55,000.00</div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <h6 className="Title">Summary</h6>
                      <div className="StatementAdjustmentBottom">
                        <label>
                          Contract Purchase Agreement Price (Pre-Tax)
                        </label>
                        <p className="LightGreen">
                          $
                          {Number(
                            soaDetails?.contract?.clientPurchasePrice ?? 0
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ChangeOrderCreatArea mt-5">
                  <div className="row">
                    <div className="col-lg-12"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          {/* <div class="InsertSignature">
                            </div> */}
                          <label>John Doe</label>
                          <label>Company Accountant</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>John Doe</label>
                          <label>Company Accountant</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={handlePrint}>
                  Print
                </button>
                {/* <button class="Create FolderPermissionId" data-toggle="modal" data-target="#StatementOfAdjustmentsSaveSignature" data-dismiss="modal" >Save</button> */}
                <div className="dropdown ml-3">
                  <button className="Create">
                    <span
                      className="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#StatementOfAdjustmentsSaveSignature"
                      data-dismiss="modal"
                    >
                      Complete &amp; Sign
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="images/down-arrow-white.png" />
                    </span>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {/* <a class="dropdown-item FolderPermissionId" href="javascript:void(0);"  data-toggle="modal" data-target="#StatementOfAdjustmentsSaveSignature2" data-dismiss="modal" ></a> */}
                      <a
                        className="dropdown-item FolderPermissionId"
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#StatementOfAdjustmentsSaveSignature3"
                        data-dismiss="modal"
                      >
                        Save Signature
                      </a>
                      <a
                        className="dropdown-item FolderPermissionId"
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#StatementOfAdjustmentsDecline4"
                        data-dismiss="modal"
                      >
                        Decline / Revert SOA
                      </a>
                      <a
                        className="dropdown-item FolderPermissionId"
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#SendSoaToLawyer2"
                        data-dismiss="modal"
                      >
                        Send to Lawyer
                      </a>
                    </div>
                  </button>
                </div>
                <button
                  className="Create FolderPermissionId"
                  onClick={() => handleSOAHistoryModalShow(contractId)}
                >
                  <img src="images/history_icon.png" />
                  History
                </button>
                <button className="Outline" onClick={handleViewSoaModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AddItineraryModal}
        onHide={handleAddItineraryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleAddItineraryModalClose}
              >
                ×
              </a>

              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Itinerary Item</h4>
                </div>
                <div className="FolderForm">
                  <h6>Item #12</h6>

                  <div className="form-group">
                    <h6>Subject</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Executive Meeting"
                      name="subject"
                      value={subject}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Description</h6>
                    <textarea
                      rows="5"
                      className="form-control"
                      name="descriptions"
                      value={descriptions}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Room 1"
                      name="location"
                      value={location}
                      onChange={handleInputChange}
                    />
                  </div>
                  <h6>Select New Date</h6>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Date Option 1</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={date}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>From</label>
                      <input
                        type="time"
                        className="form-control"
                        name="fromTime"
                        value={fromTime}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>To</label>
                      <input
                        type="time"
                        className="form-control"
                        name="toTime"
                        value={toTime}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Type</label>
                      <select
                        className="form-control"
                        name="types"
                        value={types}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Phone Call">Phone Call</option>
                        <option value="Text">Text</option>
                        <option value="Task">Task</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="CommonModalArea ml-auto col-4">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleReminderIntShow}
                          >
                            <figure>
                              <img src="images/timer-red.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>Itinerary is related to Job or Schedule's Task?</h6>
                  <div className="row">
                    <div className="form-group col-6">
                      <div className="d-flex justify-content-between">
                        <label className="CheckBox">
                          Job
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "job")}
                            checked={relatedToJob}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          Schedule
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "sch")}
                            checked={relatedToTask}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Categories Categories1 mb-3">
                    <div className="Categories_all Categories_all1">
                      <span>Select a folder to insert the file</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <div className="Categories Categories2">
                    <h6>Job</h6>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Search"
                          name="jobSearch"
                          value={jobSearch}
                          onChange={handleInputChange}
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      {jobList?.jobs?.length > 0 &&
                        jobList?.jobs
                          ?.filter((data) =>
                            jobSearch.toLowerCase() === ""
                              ? true
                              : data.jobType
                                  .toLowerCase()
                                  .includes(jobSearch.toLowerCase())
                          )
                          .map((item, i) => {
                            return (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleJobIternaryItemClick(item)}
                                style={{
                                  backgroundColor:
                                    jobId === item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.jobType}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                  <div className="StaffMemberArea">
                    <div className="row">
                      <div className="col-4">
                        <h6>Assign Staff Member</h6>
                        <div className="StaffName">
                          <img src="images/member.png" />
                          <div className="Count">3</div>
                          <h6 onClick={handleAssignStaffMemberIntShow}>
                            Assign
                          </h6>
                        </div>
                      </div>
                      <div className="col-4 ml-auto">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>{assignStaffss}</p>
                            <img
                              src="images/Cross.png"
                              onClick={removeAssignIntMember}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      onClick={handleAddItinerary}
                    >
                      Create new Itinerary Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AssignStaffMemberModalInt}
        onHide={handleAssignStaffMemberIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleAssignStaffMemberIntClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3>Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control"
                        name="assignStaffss"
                        value={assignStaffss}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <h6>View Shared Task List</h6>
                    <div className="PermissionsName">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={handleViewSharedTaskModalShow}
                      >
                        <span>
                          <img src="images/share.png" />
                        </span>
                        <div className="Count">3</div>View Shared Task List
                      </h5>
                    </div>
                  </div> */}
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssignIn}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ReminderModalInt}
        onHide={handleReminderIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleReminderIntClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderInt}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ScheduleNewPaymentModal}
        onHide={handleScheduleNewPaymentModalClose}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleScheduleNewPaymentModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Scheduled Payments</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
              </h3>
              <h3>
                <span>Job Assigned:</span>{state?.id??"N/A"}
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Schedule of Payments</h6>
              <div className="Actions">
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
                {/* <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a> */}
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Scheduled Payment Date</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {schedulePaymentList?.length > 0 ? (
                    schedulePaymentList?.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {moment(data.scheOfPayDate).format("YYYY-MM-DD")}
                          </td>
                          <td>{data.transactionType}</td>
                          <td>${data.total}</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className="javascript:void(0);"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol
                                    className="dropdown-menu dropdown-menuwidth2"
                                    style={{}}
                                  >
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="FolderPermissionId"
                                        onClick={() =>
                                          handleEditSchedulePaymentModalShow(
                                            data
                                          )
                                        }
                                      >
                                        <img src="images/Action-1.png" />
                                        Edit Scheduled Payment
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleDeleteShow(data.id)
                                        }
                                      >
                                        <img src="images/dropdown-delete.png" />
                                        Remove Scheduled Payment
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() =>
                                          handleAddNewTransactionsModalShow()
                                        }
                                        className="FolderPermissionId"
                                      >
                                        <img src="images/add_transactions.png" />
                                        Create New Transaction Entry
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="FolderPermissionId"
                                        data-toggle="modal"
                                        data-target="#SetReminderModalSchedule"
                                        data-dismiss="modal"
                                      >
                                        <img src="images/timer.png" />
                                        Set Payment Reminder
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          name="scheOfPayDate"
                          value={scheOfPayDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="transactionType"
                          value={transactionType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Transaction Type</option>
                          <option value="ADJ: Other Adjustments">
                            ADJ: Other Adjustments
                          </option>
                          <option value="ADJR: Revenue Adjustments">
                            ADJR: Revenue Adjustments
                          </option>
                          <option value="CO: Change Order Deposit">
                            CO: Change Order Deposit
                          </option>
                          <option value="DP: Down Payment Deposit">
                            {" "}
                            DP: Down Payment Deposit
                          </option>
                          <option value="OP: Offer to Purchase">
                            OP: Offer to Purchase
                          </option>
                          <option value="Balance Due on Closing">
                            Balance Due on Closing
                          </option>
                          <option value="Closing Transactions">
                            Closing Transactions
                          </option>
                          <option value="Revenue - Change Order">
                            Revenue - Change Order
                          </option>
                          <option value="Revenue - Contract Price (Pre-Tax)">
                            Revenue - Contract Price (Pre-Tax)
                          </option>
                          <option value="Revenue - Parking">
                            Revenue - Parking
                          </option>
                          <option value="Tax - Base">Tax - Base</option>
                          <option value="Tax - Rebate">Tax - Rebate</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="totalSchedule"
                          value={totalSchedule}
                          min={0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      {/* <div className="TableThreeDots">
              <ul className="">
                <li className="dropdown">
                  <a
                    className="javascript:void(0);"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="images/dots.png" />
                  </a>
                  <ol className="dropdown-menu dropdown-menuwidth2" style={{}}>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#ScheduledPaymentsEdit"
                        data-dismiss="modal"
                      >
                        <img src="images/Action-1.png" />
                        Edit Scheduled Payment
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src="images/dropdown-delete.png" />
                        Remove Scheduled Payment
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#Transactions"
                        data-dismiss="modal"
                      >
                        <img src="images/add_transactions.png" />
                        Create New Transaction Entry
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0);"
                        className="FolderPermissionId"
                        data-toggle="modal"
                        data-target="#SetReminderModalSchedule"
                        data-dismiss="modal"
                      >
                        <img src="images/timer.png" />
                        Set Payment Reminder
                      </a>
                    </li>
                  </ol>
                </li>
              </ul>
            </div> */}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <div
                        className="TableButtons"
                        onClick={handleSchedulePayment}
                      >
                        <button className="BrandBtn">
                          Schedule New Payment
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              {/* <button className="Create" data-dismiss="modal">
                Save
              </button> */}
              <button
                className="Outline"
                onClick={handleScheduledPaymentsModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddNewTransactionsModal}
        onHide={handleAddNewTransactionsModalClose}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAddNewTransactionsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Transactions</h4>
            </div>
            <div className="ModalBigheading mb-5">
              <h3>
                <span>Client:</span>{state?.TaxProfile?.name??"N/A"}
              </h3>
              <h3>
                <span>Job Assigned:</span>{state?.id??"N/A"}
              </h3>
            </div>
            <div className="Tableheader">
              <h6 className="mb-0">Create New Transaction</h6>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Transaction Date</th>
                    <th>Payment Method</th>
                    <th>Transaction Type</th>
                    <th>Total</th>
                    <th>Notes</th>
                    {/* <th>Supporting Documents</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="TableInput">
                        <input
                          type="date"
                          className="form-control"
                          name="tranDate"
                          value={tranDate}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="payMethod"
                          value={payMethod}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Payment Method</option>
                          <option value="Adjustment">Adjustment</option>
                          <option value="American Express">
                            American Express
                          </option>
                          <option value="Bank Draft">Bank Draft</option>
                          <option value="Cash">Cash</option>
                          <option value="Cheque">Cheque</option>
                          <option value="Direct Deposit">Direct Deposit</option>
                          <option value="Journal Entry">Journal Entry</option>
                          <option value="MasterCard">MasterCard</option>
                          <option value="Visa">Visa</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="CommonSelectBox">
                        <select
                          name="tranType"
                          value={tranType}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Transaction Type</option>
                          <option value="DP">DP</option>
                          <option value="CO">CO</option>
                          <option value="OP">OP</option>
                          <option value="ADJ">ADJ</option>
                          <option value="ADJR">ADJR</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Insert Amount"
                          name="total"
                          value={total}
                          min={0}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="TableInput">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insert Notes"
                          name="notes"
                          value={notes}
                          onChange={handleInputChange}
                        />
                      </div>
                    </td>
                    {/* <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs16"
                          data-dismiss="modal"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList16"
                          data-dismiss="modal"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td> */}
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <div
                        className="TableButtons"
                        onClick={handleCreateContract}
                      >
                        <button className="BrandBtn">
                          Create New Transaction
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="Button mt-5 d-flex justify-content-between">
              <button className="Create">Save</button>
              <button
                className="Outline"
                onClick={handleAddNewTransactionsModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={PostTransactionModal}
        onHide={handlePostTransactionModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlePostTransactionModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Post Transaction</h4>
            </div>
            <div className="ModalBigheading mb-0">
              <h3>
                <span>Transaction ID#</span>DP-ARB-1B-243-2
              </h3>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>DR/CR</th>
                    <th>Account</th>
                    <th>Client</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$ 45,000.00</td>
                    <td>DP-ARB-1B-243-2-Cheque number #123</td>
                    <td>{state?.TaxProfile?.name??"N/A"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="ModalFilterAreaBox mt-5 mb-3">
              <h4 className="mb-4">Operation</h4>
              <div className="RadioArea d-flex  flex-column">
                <div className="form-group mb-3">
                  <label className="Radio">
                    Payment/Adjustment
                    <input
                      type="radio"
                      name="operation"
                      value="Payment/Adjustment"
                      checked={operation === "Payment/Adjustment"}
                      onChange={handleOperationChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    Refund
                    <input
                      type="radio"
                      name="operation"
                      value="Refund"
                      checked={operation === "Refund"}
                      onChange={handleOperationChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>TX.Entry</th>
                    <th>Account</th>
                    <th>Sub-Account Posting</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Debit</td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option selected="">Select State or Province</option>
                          <option>India</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input
                          type="checkbox"
                          checked={debitSubAccountPosting}
                          onChange={(e) =>
                            handlePostalCheckboxChange(e, "debit")
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Credit</td>
                    <td>
                      <div className="CommonSelectBox">
                        <select>
                          <option value="">Select State or Province</option>
                          <option>India</option>
                        </select>
                        <span>
                          <img src="images/down.png" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <label className="CheckBox">
                        <input
                          type="checkbox"
                          checked={creditSubAccountPosting}
                          onChange={(e) =>
                            handlePostalCheckboxChange(e, "credit")
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="Button mt-5 d-flex justify-content-between">
              <button
                className="Create"
                onClick={() => handlePostalTransaction("postal")}
              >
                Post Transaction
              </button>
              <button
                className="Create"
                onClick={() => handlePostalTransaction("nopostal")}
              >
                Save Without Posting
              </button>
              <button
                className="Outline"
                onClick={handlePostTransactionModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="ModalBox fade LargeModal show"
        show={SupportingDocumentsListModal}
        onHide={handleSupportingDocumentsListModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleSupportingDocumentsListModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Documents List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <div>
                    <a
                    // onClick={() =>
                    //   handleAddNewSupportingDocsModalShow(taskId)
                    // }
                    >
                      <img src="images/notebook.png" />
                    </a>
                  </div>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="NotesAreaMain">
                <div className="NotesArea mt-4">
                  <h6>Supporting Documents List</h6>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    {/* <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSupportDocsList?.supportDocs?.length > 0 ? (
                        allSupportDocsList.supportDocs.map((data, ind) => (
                          <tr key={ind}>
                            <td>{ind + 1}</td>
                            <td>{data.sendBy ?? "N/A"}</td>
                            <td>
                              {moment(data.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td className="Inspection">
                              {data.notes ?? "N/A"}
                            </td>
                            <td>
                              <div className="Actions">
                                <a
                                // onClick={()=>handleUpdateNewSupportingDocsModalShow(data.parenttableId)}
                                >
                                  <img
                                    src="images/Action-1.png"
                                    alt="Action 1"
                                  />
                                </a>
                                <a
                                // onClick={() =>
                                //   handleSupportDocumentDeleteModalHideShow(
                                //     "show",
                                //     data.parenttableId
                                //   )
                                // }
                                >
                                  <img src="images/delete.png" alt="Delete" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  // onClick={() => handleAddNewSupportingDocsModalShow(taskId)}
                >
                  Add New Supporting Docs
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
          show={JobMenuModal}
          onHide={handleJobMenuModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleJobMenuModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <div className="JobMenuListing">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/add_build.png" />
                        </figure>
                        Add to/Edit Build
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <figure>
                          <img src="images/details.png" />
                        </figure>
                        Details
                      </a>
                    </li>
                    <li className="JobMenuListing_All">
                      <Link to="/change-order-list" state={state}>
                        <figure>
                          <img src="images/change_order.png" />
                        </figure>
                        Change Order
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                      <ul className="JobMenuListingUnder">
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change orders List
                          </Link>
                        </li>
                        <li>
                          <Link to="/change-order-list" state={state}>
                            Change to Contract (CTC) master
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/warranty-dashboard" state={state}>
                        <figure>
                          <img src="images/design.png" />
                        </figure>
                        Warranty
                      </Link>
                    </li>
                    <li>
                      <Link to="/sales" state={state}>
                        <figure>
                          <img src="images/sales.png" />
                        </figure>
                        Sales
                      </Link>
                    </li>
                    <li>
                      <Link to="/estimation" state={state}>
                        <figure>
                          <img src="images/estimation.png" />
                        </figure>
                        Estimation
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="/construction" state={state}>
                        <figure>
                          <img src="images/construction_icon.png" />
                        </figure>
                        Construction
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                    {pathname !== "/transactions_manager" && (
                      <li>
                        <Link to="/transactions_manager" state={state}>
                          <figure>
                            <img src="images/warranty.png" />
                          </figure>
                          Accounting
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/reports" state={state}>
                        <figure>
                          <img src="images/reports.png" />
                        </figure>
                        Reports
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>

                    <li>
                      <Link to="/client-view-config" state={state}>
                        <figure>
                          <img src="images/view_config.png" />
                        </figure>
                        Views Config
                        <img
                          src="images/rightarrow2.png"
                          className="ListingOverflow"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={NavigateModal}
          onHide={handleNavigateModalClose}
          className="ModalBox modal fade SmallModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleNavigateModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <div className="NaviagateProjectArea">
                  <ul>
                    {allProjectList?.projects?.length > 0 &&
                      allProjectList?.projects.map((item, index) => {
                        return (
                          <>
                            <li className="DarkBlueList AvalonTownsTab">
                              <a href="javascript:void(0);">
                                {item.projectName}
                              </a>
                            </li>
                            <li className="LightBlueList AdministrationTab">
                              <a href="javascript:void(0);">
                                {item.jobPattern}
                              </a>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu dropdown-menuwidth DropDownMenuZindex">
                                      <li>
                                        <a>
                                          <img src="images/add_build.png" />
                                          Add to/Edit Build
                                        </a>
                                      </li>
                                      <li>
                                        <a>
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li className="border-0">
                                        <Link
                                          to="/change-order-list"
                                          state={state}
                                          className="dropdown-item"
                                          style={{
                                            border:
                                              "1px solid transparent!important",
                                          }}
                                        >
                                          <img src="images/change_order.png" />
                                          Change Order &nbsp;&nbsp;
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        <ul className="dropdown-menu submenu">
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change orders List
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to="/change-order-list"
                                              state={state}
                                              className="dropdown-item"
                                            >
                                              Change to Contract (CTC) master
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link
                                          to="/design-selections"
                                          state={state}
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/sales" state={state}>
                                          <img src="images/sales.png" />
                                          Sales
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/estimation" state={state}>
                                          <img src="images/estimation.png" />
                                          Estimation
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/construction" state={state}>
                                          <img src="images/construction_icon.png" />
                                          Construction
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/warranty-dashboard"
                                          state={state}
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/reports" state={state}>
                                          <img src="images/reports.png" />
                                          Reports
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/client-view-config"
                                          state={state}
                                        >
                                          <img src="images/view_config.png" />
                                          Views Config
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </div>
  );
};

export default TransactionsManager;
