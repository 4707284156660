
import { designSelectionsAction } from "../actionTypes";
const initialState = {
    interiorCategoryList:[],
    interiorCreateCategoryList:[],
    pendingAppointmentLoader:false,
    pendingAppointmentList:[],
    approvedAppointmentList:[],
    appointmentCategoryList:[],
    appointmentDesignerList:[],
    approvedAppointmentLoader:false,
    assembleList:[],
    vendorList:[],
    folderLoader:false ,
    folderList: [],
    pendingFileLoader:false ,
    pendingFileeEventList: [],
    documentLoader:false ,
    documentList: [],
    documentHistoryLoader:false,
    documentHistoryList:[],
    primaryList:[],
    secondaryList:[],
    exteriorCreateCategoryList:[],
    getCostCodeList:[],
    getMeasureList:[],
    getOptionByCategoryList:[],
    selectionHistoryList:[],
};

export default function designSelectionsReducers(state = initialState, { type, payload }) {
  switch (type) {
    case designSelectionsAction.INTERIOR_CATEGORY_INITIATE: {
        return {...state,interiorCategoryList: []        };
      }
      case designSelectionsAction.INTERIOR_CATEGORY_SUCCESS: {
        return {...state,interiorCategoryList: payload       };
      }
      case designSelectionsAction.INTERIOR_CATEGORY_FAILURE: {
        return {...state,interiorCategoryList: payload       };
      }

      case designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_INITIATE: {
        return {...state,interiorCreateCategoryList: []        };
      }
      case designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_SUCCESS: {
        return {...state,interiorCreateCategoryList: payload       };
      }
      case designSelectionsAction.INTERIOR_CREATE_CATEGORY_LIST_FAILURE: {
        return {...state,interiorCreateCategoryList: payload       };
      }


      case designSelectionsAction.GET_PENDING_APPOINTMENT_INITIATE: {
        return {...state,pendingAppointmentLoader:true,pendingAppointmentList: []};
      }
      case designSelectionsAction.GET_PENDING_APPOINTMENT_SUCCESS: {
        return {...state,pendingAppointmentLoader:false,pendingAppointmentList: payload};
      }
      case designSelectionsAction.GET_PENDING_APPOINTMENT_FAILURE: {
        return {...state,pendingAppointmentLoader:false,pendingAppointmentList: payload};
      }
 
      case designSelectionsAction.GET_APPOINTMENT_CATEGORY_INITIATE: {
        return {...state,appointmentCategoryList: []};
      }
      case designSelectionsAction.GET_APPOINTMENT_CATEGORY_SUCCESS: {
        return {...state,appointmentCategoryList: payload};
      }
      case designSelectionsAction.GET_APPOINTMENT_CATEGORY_FAILURE: {
        return {...state,appointmentCategoryList: payload};
      }


      case designSelectionsAction.GET_DESIGNER_VENDOR_INITIATE: {
        return {...state,appointmentDesignerList: []};
      }
      case designSelectionsAction.GET_DESIGNER_VENDOR_SUCCESS: {
        return {...state,appointmentDesignerList: payload};
      }
      case designSelectionsAction.GET_DESIGNER_VENDOR_FAILURE: {
        return {...state,appointmentDesignerList: payload};
      }
      

      case designSelectionsAction.GET_APPROVED_APPOINTMENT_INITIATE: {
        return {...state,approvedAppointmentLoader:true,approvedAppointmentList: []};
      }
      case designSelectionsAction.GET_APPROVED_APPOINTMENT_SUCCESS: {
        return {...state,approvedAppointmentLoader:false,approvedAppointmentList: payload};
      }
      case designSelectionsAction.GET_APPROVED_APPOINTMENT_FAILURE: {
        return {...state,approvedAppointmentLoader:false,approvedAppointmentList: payload};
      }


      case designSelectionsAction.GET_PRICE_BOOK_CATEGORY_INITIATE: {
        return {...state,priceBookCategoryList: []};
      }
      case designSelectionsAction.GET_PRICE_BOOK_CATEGORY_SUCCESS: {
        return {...state,priceBookCategoryList: payload};
      }
      case designSelectionsAction.GET_PRICE_BOOK_CATEGORY_FAILURE: {
        return {...state,priceBookCategoryList: payload};
      }

      case designSelectionsAction.GET_SELECT_ITEM_INITIATE: {
        return {...state,assembleList: []};
      }
      case designSelectionsAction.GET_SELECT_ITEM_SUCCESS: {
        return {...state,assembleList: payload};
      }
      case designSelectionsAction.GET_SELECT_ITEM_FAILURE: {
        return {...state,assembleList: payload};
      }

      case designSelectionsAction.GET_VENDOR_LIST_INITIATE: {
        return {...state,vendorList: []};
      }
      case designSelectionsAction.GET_VENDOR_LIST_SUCCESS: {
        return {...state,vendorList: payload};
      }
      case designSelectionsAction.GET_VENDOR_LIST_FAILURE: {
        return {...state,vendorList: []};
      }

      case designSelectionsAction.FOLDER_LIST_INITIATE: {
        return {...state,folderLoader:true ,folderList: []};
      }
      case designSelectionsAction.FOLDER_LIST_SUCCESS: {
        return {...state,folderLoader:false ,folderList: payload};
      }
      case designSelectionsAction.FOLDER_LIST_FAILURE: {
        return {...state,folderLoader:false ,folderList: []};
      }

      case designSelectionsAction.PENDING_FILE_EVENT_LIST_INITIATE: {
        return {...state,pendingFileLoader:true ,pendingFileeEventList: []};
      }
      case designSelectionsAction.PENDING_FILE_EVENT_LIST_SUCCESS: {
        return {...state,pendingFileLoader:false ,pendingFileeEventList: payload};
      }
      case designSelectionsAction.PENDING_FILE_EVENT_LIST_FAILURE: {
        return {...state,pendingFileLoader:false ,pendingFileeEventList: []};
      }

      case designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_INITIATE: {
        return {...state,documentLoader:true ,documentList: []};
      }
      case designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_SUCCESS: {
        return {...state,documentLoader:false ,documentList: payload};
      }
      case designSelectionsAction.GET_LIST_DESIGN_DOCUMENT_FAILURE: {
        return {...state,documentLoader:false ,documentList: []};
      }

      case designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_INITIATE: {
        return {...state,documentHistoryLoader:true ,documentHistoryList: []};
      }
      case designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_SUCCESS: {
        return {...state,documentHistoryLoader:false ,documentHistoryList: payload};
      }
      case designSelectionsAction.GET_DESIGN_DOCUMENT_HISTORY_FAILURE: {
        return {...state,documentHistoryLoader:false ,documentHistoryList: []};
      }

      case designSelectionsAction.GET_PRIMARY_CLIENT_INITIATE: {
        return {...state,primaryList: []};
      }
      case designSelectionsAction.GET_PRIMARY_CLIENT_SUCCESS: {
        return {...state,primaryList: payload};
      }
      case designSelectionsAction.GET_PRIMARY_CLIENT_FAILURE: {
        return {...state,primaryList: []};
      }

      case designSelectionsAction.GET_SECONDARY_CLIENT_INITIATE: {
        return {...state,secondaryList: []};
      }
      case designSelectionsAction.GET_SECONDARY_CLIENT_SUCCESS: {
        return {...state,secondaryList: payload};
      }
      case designSelectionsAction.GET_SECONDARY_CLIENT_FAILURE: {
        return {...state,secondaryList: []};
      }

      case designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_INITIATE: {
        return {...state,exteriorCreateCategoryList: []};
      }
      case designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_SUCCESS: {
        return {...state,exteriorCreateCategoryList: payload};
      }
      case designSelectionsAction.EXTERIOR_CREATE_CATEGORY_LIST_FAILURE: {
        return {...state,exteriorCreateCategoryList: []};
      }


      case designSelectionsAction.GET_COST_CODE_DROPDOWN_INITIATE: {
        return {...state,getCostCodeList: []};
      }
      case designSelectionsAction.GET_COST_CODE_DROPDOWN_SUCCESS: {
        return {...state,getCostCodeList: payload};
      }
      case designSelectionsAction.GET_COST_CODE_DROPDOWN_FAILURE: {
        return {...state,getCostCodeList: []};
      }

      case designSelectionsAction.GET_MEASURE_DROPDOWN_INITIATE: {
        return {...state,getMeasureList: []};
      }
      case designSelectionsAction.GET_MEASURE_DROPDOWN_SUCCESS: {
        return {...state,getMeasureList: payload};
      }
      case designSelectionsAction.GET_MEASURE_DROPDOWN_FAILURE: {
        return {...state,getMeasureList: []};
      }

      case designSelectionsAction.GET_OPTION_BY_CATEGORYID_INITIATE: {
        return {...state,getOptionByCategoryList: []};
      }
      case designSelectionsAction.GET_OPTION_BY_CATEGORYID_SUCCESS: {
        return {...state,getOptionByCategoryList: payload};
      }
      case designSelectionsAction.GET_OPTION_BY_CATEGORYID_FAILURE: {
        return {...state,getOptionByCategoryList: []};
      }

      case designSelectionsAction.GET_SELECTION_HISTORY_INITIATE: {
        return {...state,selectionHistoryList: []};
      }
      case designSelectionsAction.GET_SELECTION_HISTORY_SUCCESS: {
        return {...state,selectionHistoryList: payload};
      }
      case designSelectionsAction.GET_SELECTION_HISTORY_FAILURE: {
        return {...state,selectionHistoryList: []};
      }

    default:
      return state;
  }
}
