import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLandDevelopmentStep1 } from "../../../redux/action/proformaAction";

const Input = ({
  handleAcresChange,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [acres, setAcres] = useState([]);
  useEffect(() => {
    dispatch(getLandDevelopmentStep1()).then((res) => {
      setAcres(res?.data?.data?.acres);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading2 d-flex align-items-center mb-3">
        <h3 className="mb-0">Enter Number of Acres in the Project</h3>
        <div className="form-group mb-0 ml-4">
          <input
            type="text"
            className="form-control"
            placeholder="Insert number of Acres"
            value={acres}
            name="acres"
            onChange={handleAcresChange}
          />
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("multiLevelParakade")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponents("Quanties")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default Input;
