import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SignaturePad from "react-signature-canvas";

import {
  actionRequriedSaleDocumentList,
  craeteTradeViewSignatures,
  getFolderHistoryList,
  getFoldernFileList,
  getHtmlFiletTradeView,
  getJobByProjectList,
  getProjectByBuilder,
  getTradeView,
} from "../redux/action/tradeViewAuthAction";
import TradeViewSidenav from "./trade-view-sidenav";
import { getHtmlFile } from "../redux/action/fileManagerAction";
import TradeHeader from "./TradeHeader";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";

const initialState = {
  signature: "",
  initial: "",
  errors: {},
  ManageSignatueModal: false,
  DocumentHistoryModal: false,

  companyId: "",
  projectId: "",
  jobId: "",
  viewId: "",
  folderId: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
};

const FileManagerTradeView = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    signature,
    initial,
    errors,
    ManageSignatueModal,
    DocumentHistoryModal,
    companyId,
    projectId,
    jobId,
    viewId,
    folderId,
    ViewDocumentationModal,
    viewDocumentData,
  } = iState;

  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));

  const {
    projectByBuilderData,
    jobByProjectData,
    folderHistoryList,
    folderNFileList,
    actionRequriedSalesList,
  } = useSelector((state) => state.tradeViewLogin);

  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
    dispatch(getTradeView(compId?.user?.CompanyId)).then((res) => {
      if (res.status == 200) {
        updateState({ ...iState, viewId: res?.data?.ViewSetting?.id });
      }
    });
  }, []);

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      signature: "",
      initial: "",
      errors: {},
    });
  };

  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
      initial: "",
      errors: {},
    });
  };
  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "sign1") {
      updateState({ ...iState, signature: file });
    } else if (type === "sign2") {
      updateState({ ...iState, initial: file });
    } else if (type === "template") {
      updateState({ ...iState, templateFile: file });
    } else {
      updateState({ ...iState, clientFile: file });
    }
  };

  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

    if (!initial) {
      error.initialError = "* Initial can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };

  const addSignatureFun = () => {
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      const signatureBlob = dataURLtoBlob(signature);
      const initialBlob = dataURLtoBlob(initial);
      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
      const formData = new FormData();
      formData.append("signature", validSignatureBlob, "signature.png");
      formData.append("initial", validInitialBlob, "initial.png");
      formData.append("imageOwner", "test");
      dispatch(craeteTradeViewSignatures(builderIdd, formData))
        .then((res) => {
          if (res.status === 201) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ManageSignatueModal: false,
              signature: null,
              initial: null,
            });
          } else {
            toast.error("Failed to upload signature", {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({ ...iState, ManageSignatueModal: true });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        });
    }
  };

  const handleDocumentHistoryModalShow = (foldId) => {
    updateState({ ...iState, DocumentHistoryModal: true, folderId: foldId });
  };

  const handleDocumentHistoryModalClose = () => {
    updateState({ ...iState, DocumentHistoryModal: false });
  };

  useEffect(() => {
    if (DocumentHistoryModal) {
      dispatch(getFolderHistoryList(builderIdd, folderId));
    }
  }, [DocumentHistoryModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
      dispatch(actionRequriedSaleDocumentList(builderIdd));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      dispatch(getFoldernFileList(builderIdd, viewId));
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const mergedData = folderNFileList?.data?.files?.concat(
    folderNFileList?.data?.folders
  );

  // Filter the merged data based on the search term
  const filteredData = mergedData?.filter((item) => {
    return (
      item.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      item.folderName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  });

  const handleViewDocumentationShow = async (documentUrl) => {
    updateState({
      ...iState,
      ViewDocumentationModal: true,
    });

    try {
      const urlWithoutQueryString = documentUrl?.split("?")[0];

      const htmlData = await dispatch(
        getHtmlFiletTradeView({ url: urlWithoutQueryString })
      );
      // console.log("Html", htmlData);

      if (documentUrl) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data,
          ViewDocumentationModal: true,
        });
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      viewDocumentData: "",
    });
  };

  const configObject = {
    // CKEditor 5 configuration options
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "undo",
      "redo",
    ],
    // Add more configuration options as needed
  };

  return (
    <div>
      <>
        {/* <Header /> */}
        <TradeHeader />
        <TradeViewSidenav />
        <div className="WrapperAreaClient">
          <div className="WrapperBoxClient">
            <div className="MainTitleBox">
              <div className="MainTitle">
                <h2>File Manager</h2>
              </div>
            </div>
            <div className="TitleBox d-block">
              <div className="row">
                  <div className="col-md-4">
                  <div className="BuilderBox w-100">
                <h4>Project</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="projectId"
                    value={projectId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    {projectByBuilderData?.data?.length > 0 &&
                      projectByBuilderData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
                  </div>
                  <div className="col-md-4 ml-auto">

                  <div className="BuilderBox w-100 mr-0">
                <h4>Job</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="jobId"
                    value={jobId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Jobs</option>
                    {jobByProjectData?.data?.length > 0 &&
                      jobByProjectData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.jobType}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
                  </div>
              
              
              </div>
            </div>
            <div className="Tableheader mt-5">
              <h6>Action Required</h6>
            </div>
            <div
              className="TableList TableList50 NewTableList TableHeightScroll"
            >
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Modified by</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {actionRequriedSalesList?.data?.length > 0 ? (
                    actionRequriedSalesList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.name ?? "N/A"}</td>
                          <td>{item.modifiedBy ?? "N/A"}</td>
                          <td>{item.status}</td>
                          <td>{moment(item.updatedAt).format("YYYY-MM-DD")}</td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol className="dropdown-menu dropdown-menuwidth">
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/view-po.png" />
                                        View Document
                                      </a>
                                    </li>
                                    <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/sign_document.png" />
                                        Sign Document
                                      </a>
                                    </li>
                                    <li>
                                      <a href={item.docUrl} target="_self">
                                        <img
                                          src="images/download.png"
                                          alt="download"
                                        />
                                        Download documents
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="FilesArea mt-4">
              <div className="FilesHead">
                <div className="TodosButtons mt-0">
                  <a onClick={handleManageSignatueShow} className="ml-0">
                    <h6>
                      <img src="images/white_manage_signatures.png" />
                      Manage Signatures
                    </h6>
                  </a>
                </div>
                <div className="FilesLeft">
                  <aside>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span>
                      <img src="images/search.png" />
                    </span>
                  </aside>
                </div>
              </div>
            </div>
            <div className="SalesDocumentArea">
              <div className="row">
                {filteredData?.map((item, i) => {
                  const isFile = !!item.name; // Check if it's a file (true) or folder (false)
                  return (
                    <div className="col-md-3" key={i}>
                      <div className="FilesBox2 Safety">
                        <figcaption>
                          <div>
                            <h4>{isFile ? item.name : item.folderName}</h4>
                          </div>
                          <ul>
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" alt="dots" />
                              </a>
                              <ol className="dropdown-menu DropdownMenuSafety">
                                <li>
                                  <a
                                    onClick={() =>
                                      handleViewDocumentationShow(item.docUrl)
                                    }
                                  >
                                    <img
                                      src="images/view_jobs_details.png"
                                      alt="view details"
                                    />
                                    View Document
                                  </a>
                                </li>
                                {isFile && (
                                  <li>
                                    <a href={item.docUrl} target="_self">
                                      <img
                                        src="images/download.png"
                                        alt="download"
                                      />
                                      Download documents
                                    </a>
                                  </li>
                                )}
                              </ol>
                            </li>
                          </ul>
                        </figcaption>
                        <div className="FilesBoxCenterSafety">
                          <img src="images/file.png" alt="file" />{" "}
                          <h6>{isFile ? "File" : "Folder"}</h6>{" "}
                          <img src="images/red_esc.png" alt="esc" />
                        </div>
                        <figcaption>
                          <div>
                            <p className="CursorPointer">
                              Last update{" "}
                              {moment(item.updatedAt).format("YYYY-MM-DD")}
                            </p>
                          </div>
                          <ul>
                            {isFile && (
                              <li>
                                <a
                                  onClick={() =>
                                    handleDocumentHistoryModalShow(
                                      item.FolderId
                                    )
                                  }
                                  data-target="#DocumentHistory"
                                >
                                  <img src="images/timer.png" alt="timer" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </figcaption>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                      <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.signatureError}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new Initials
                      </h5>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={intialCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "intialCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            initial: intialCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearInitials}>
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.initialError}
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={DocumentHistoryModal}
          onHide={handleDocumentHistoryModalClose}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleDocumentHistoryModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Document History</h4>
              </div>
              <div className="JobTitleArea mb-2">
                <div className="JobBox">
                  <h6>
                    <span>Document:</span>&nbsp;Statement of Adjusments.pdf
                  </h6>
                </div>
              </div>
              <div className="TableList CommonHeading TableHeightScroll">
                <table>
                  <thead>
                    <tr>
                      <th>Modified By</th>
                      <th>Created By</th>
                      <th>Date</th>
                      <th>Activity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {folderHistoryList?.folderHistories?.length > 0 ? (
                      folderHistoryList?.folderHistories?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.modifiedBy ?? "N/A"}</td>
                            <td>{item.createdBy ?? "N/A"}</td>
                            <td>
                              {moment(item.createdAt).format("YYYY-MM-DD")}
                            </td>
                            <td>{item.activity}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">No data found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={ViewDocumentationModal}
          onHide={handleViewDocumentationClose}
          className="ModalBox LargeModal GreyModalContent"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleViewDocumentationClose}
                >
                  ×
                </a>

                <div className="FormArea">
                  {viewDocumentData && (
                    <CKEditor
                      editor={ClassicEditor}
                      config={configObject}
                      readOnly={true}
                      // disabled
                      data={viewDocumentData}
                    />
                  )}
                  <div className="FolderForm">
                    <div className="Button d-flex justify-content-between mt-5">
                      <button
                        className="Outline"
                        onClick={handleViewDocumentationClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default FileManagerTradeView;
