import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { acceptSCs, getSCById, rejectSCs } from "../redux/action/tradeViewAuthAction";

const TradeViewSupplierCredit = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const urlParams = new URLSearchParams(
      location?.search?.split("?")[1].split("#")[0]
    );
    const companyId = urlParams?.get("companyId");
    const documentId = urlParams?.get("documentId");
    const token = urlParams?.get("token");

    const [viewScData, setViewScData] = useState([]);
    useEffect(() => {
        dispatch(getSCById(companyId, documentId, token)).then((res) => {
          if (res.status === 200) {
            const dummyData = res?.data?.data;
            setViewScData(dummyData);
          }
        });
      

    }, []);

    const handleAcceptRejectPO = (type) => {
      if (type === "accept") {
        dispatch(acceptSCs(companyId, documentId, token)).then((res) => {
          if (res.status === 200) {
            dispatch(getSCById(companyId, documentId,token)).then((res) => {
              if (res.status === 200) {
                const dummyData = res?.data?.data;
                setViewScData(dummyData);
              }
            });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } else {
        dispatch(rejectSCs(companyId, documentId,token)).then((res) => {
          if (res.status === 200) {
            dispatch(getSCById(companyId, documentId,token)).then((res) => {
              if (res.status === 200) {
                const dummyData = res?.data?.data;
                setViewScData(dummyData);
              }
            });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      }
    };

    const handlePrint=()=>{
      window.print();
    }
  return (
    <>
    <div className="FormArea p-5">
    <div className="ModalTitleBox ModalTitleBoxRed">
          <h4>View Supplier Credit</h4>
        </div>
  <div className="FolderForm">
    
      <div className="BlueTextArea RedTextArea">
        <div className="row">
          <div className="col-md-6">
            <div className="Heading">
              <h3> View Supplier Credit Request # {viewScData?.documentCode}</h3>
            </div>
         
            <div className="row">
              <div className="form-group col-md-9">
                <h6>Reason</h6>
                <p className="ml-0 text-black">
                {" "}
                {viewScData?.Reason?.description ?? "N/A"}
              </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="Heading">
              {/* <h3>Rivers Edge Unit 366(RIV-2B-366)</h3> */}
              <h3>{viewScData?.job?.jobNumber ?? "N/A"}</h3>
              <div className="d-flex align-items-center">
              <h3>
                      Cost Code:{" "}
                      <span>{`${viewScData?.CostCode?.number ?? ""}-${
                        viewScData?.CostCode?.name ?? ""
                      }`}</span>
                    </h3>{" "}<br></br>
                    <h6>
                      Status:<span> {viewScData?.status}</span>
                    </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ModalFilterAreaBox">
              <h4 className="mb-4">Vendor</h4>
              <div className="CommonModalBox">
                    <h5>
                    <p  className="ml-0">
                    {viewScData?.Vendor?.vendorName??"N/A"}
                  </p>
                    </h5>
                  </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <h6>Date Sent</h6>
              <p className="ml-0">    {" "}
                    {viewScData?.sendAt !== null
                      ? moment(viewScData?.sendAt).format("YYYY-MM-DD")
                      : "N/A"}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="CommonModalArea">
              <div className="form-group">
                <h5 className="RedText">Reminder</h5>
                <div className="CommonModalBox">
                <p>
                    {viewScData?.reminder!==null?
                    moment(viewScData?.reminder).format(
                      "YYYY-MM-DD"
                    ):"N/A"}
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="CommonModalArea">
              <div className="form-group">
                <div className="CommonModalBox">
                  <h5 className="text-black">Vendor 3</h5>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12">
          <div className="ShippingAddressArea">
                  <div className="ShippingAddressAreaBox">
                    <h6>Mailing Address</h6>
                    <p>{`${viewScData?.Vendor?.addressLine1 ?? ""}
                                ${viewScData?.Vendor?.addressLine2 ?? ""}`}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Email</h6>
                    <p>{viewScData?.Vendor?.email}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Phone</h6>
                    <p>{viewScData?.Vendor?.phone}</p>
                  </div>
                  <div className="ShippingAddressAreaBox">
                    <h6>Shipping Address</h6>
                    <p>{`${viewScData?.Vendor?.addressLine1 ?? ""}
                                ${viewScData?.Vendor?.addressLine2 ?? ""}`}</p>
                  </div>
                </div>
          </div>
          {/* <div className="col-12">
            <div className="form-group">
              <div className="CommonModalArea">
                <h5 className="RedText">Back Charge Vendor</h5>
                <div className="CommonModalBox">
                  <figure
                    className="CursorPointer FolderPermissionId"
                    data-toggle="modal"
                    data-target="#LeftBudget1SelectVendorRed"
                    data-dismiss="modal"
                  >
                    <img src="images/vendor.png" />
                  </figure>
                  <h5 className="text-black">Vendor 3</h5>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-7">
            <div className="form-group">
              <h6>Messsage</h6>
              <p>{viewScData?.message ?? "N/A"}</p>
            </div>
          </div>
          <div className="col-12">
            <div className="ItemDetails">
              <h6>Item Details</h6>
            </div>
          </div>
          <div className="col-12">
            <div className="Tableheader mb-3">
              <h6 className="mb-0">Item</h6>
              <div className="Actions">
                <a onClick={handlePrint}>
                  <img src="images/icon-53.png" />
                </a>
                <a onClick={handlePrint}>
                  <img src="images/download.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div className="SchedulePaymentsTable TransactionTableShow mb-4">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <div className="text-center">
                        Material Description
                        <br />
                        (i.e: Modal/Type/Style/manuf part#/Dimensions, etc.)
                      </div>
                    </th>
                    <th>Color</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Price/Unit</th>
                    <th>Total</th>
                 
                  </tr>
                </thead>
                <tbody>
                {viewScData?.Items?.length > 0 ? (
                        viewScData?.Items?.map((item, ind) => {
                          return (
                            <>
                              <tr key={ind}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.color}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unitId}</td>
                                <td>$ {item.price}</td>
                                <td>$ {item.total}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="12">
                            <p>No Data found.</p>
                          </td>
                        </tr>
                      )}
                 
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="col-md-4 ml-auto">
              <div className="GstTable">
                      <table>
                        <tbody>
                          <tr>
                            <td>Profile</td>
                            <td>{viewScData?.TaxProfile?.name ?? "N/A"}</td>
                          </tr>
                          <tr>
                            <td>Subtotal</td>
                            <td>${viewScData?.subtotal || 0}</td>
                          </tr>
                          {
                            viewScData?.otherTaxes?.length>0&&
                          <tr>
                            <td>
                              {" "}
                              {viewScData?.otherTaxes?.[0]?.taxName}(
                              {viewScData?.otherTaxes?.[0]?.taxPercentage||0}
                              %)
                            </td>
                            <td>
                              $
                              {viewScData?.otherTaxes?.[0]?.taxAmount||0}
                            </td>
                          </tr>
                          }
                          <tr>
                            <td>
                              {" "}
                              GST(
                              {Number(
                                viewScData?.TaxProfile?.taxRows?.[0]
                                  ?.taxPercent
                              ) || 0}
                              %)
                            </td>
                            <td>
                              $
                              {(Number(viewScData?.subtotal) *
                                (Number(
                                  viewScData?.TaxProfile?.taxRows?.[0]
                                    ?.taxPercent
                                ) || 0)) /
                                100}
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Grand Total</td>
                            <td>
                              $
                              {Number(viewScData?.grandTotal||0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
            <div className="mb-4">
              <div className="Heading">
                <h6>Attachments</h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="CommonModalArea">
              <div className="form-group">
                <h5 className="RedText">Scope of Work</h5>
                <div className="CommonModalBox">
                  <h5
                    className="CursorPointer FolderPermissionId RedText"
                    
                  >
                    <figure>
                      <img src="images/scopeofwork.png" />
                    </figure>{" "}
                    Scope of Work
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="CommonModalArea">
              <div className="form-group">
                <h5 className="RedText">Construction Documents</h5>
                <div className="CommonModalBox">
                  <h5
                    className="CursorPointer FolderPermissionId RedText"
                   
                  >
                    <figure>
                      <img src="images/construction_documents.png" />
                    </figure>{" "}
                    Construction Documents
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="CommonModalArea">
              <div className="form-group">
                <h5 className="RedText">External Quote</h5>
                <div className="CommonModalBox">
                  <h5
                    className="CursorPointer FolderPermissionId RedText"
                   
                  >
                    <figure>
                      <img src="images/extermal_quote.png" />
                    </figure>{" "}
                    External Quote &nbsp; <span className="Count">3</span>
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      
        {viewScData?.status === "SEND" ? (
              <div className="Button d-flex justify-content-between mt-4 ">
                <button
                  className="Create"
                  onClick={() => handleAcceptRejectPO("accept")}
                >
                  Accept
                </button>
                <button
                  className="Create"
                  onClick={() => handleAcceptRejectPO("reject")}
                >
                  Reject
                </button>
              </div>
            ) : (
              <p>An Action has been already performed</p>
            )}
      </div>
  
  </div>
</div>

    </>
  )
}

export default TradeViewSupplierCredit