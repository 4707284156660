import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  CreatePlanAction,
  activatePlanAction,
  deactivatePlanAction,
  featureListAction,
  planListAction,
  removePlanAction,
  updatePlanAction,
} from "../../redux/action/planAction";

import { toast } from "react-toastify";
import moment from "moment/moment";

const initialState = {
  planName: "",
  price: "",
  YealyDiscount: "",
};
const IbuldAdminSubscription = () => {
  const [iState, updateState] = useState(initialState);

  const [featureState, setFeatureState] = useState([]);
  const [planId, setPlanId] = useState("");

  const { planName, price, YealyDiscount } = iState;

  const [createPlanModal, updateCreatePlanModal] = useState(false);
  const handleShowCreatePlan = () => {
    updateCreatePlanModal(true);
  }
  const handleCloseCreatePlan = () => {
    updateCreatePlanModal(false);
  };



  const [editPlanModal, updateEditPlanModal] = useState(false);
  const handleCloseEditPlan = () => {
    updateEditPlanModal(false);
  };

  const handleShowEditPlan = (data) => {
    updateEditPlanModal(true);
    updateState({
      ...iState,
      planName: data?.planName,
      price: data?.price,
      YealyDiscount: data?.YealyDiscount,
    });
    setPlanId(data?.id);
    setFeatureState(data?.Features);
  };

  const dispatch = useDispatch();

  const { featureList, planList } = useSelector(
    (state) => state.adminPlanReducer
  );

  useEffect(() => {
    dispatch(featureListAction());
    dispatch(planListAction());
  }, []);

  const handleCheck = (e, obj) => {
    if (e.target.checked) {
      setFeatureState([...featureState, obj]);
    } else {
      let temp = [...featureState];
      let index = temp.findIndex((item) => item.name == obj.name);
      temp.splice(index, 1);
      setFeatureState(temp);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;

    let data = {
      planName: planName,
      price: price,
      YealyDiscount: YealyDiscount,
      featureList: featureState,
    };
    if (formIsValid) {
      dispatch(CreatePlanAction(data))
        .then((res) => {
          toast.success("Create Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateCreatePlanModal(false);
          dispatch(planListAction());
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleActive = (id) => {
    dispatch(activatePlanAction({ id: id }))
      .then((res) => {
        toast.success("Activate Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(planListAction());
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDeActive = (id) => {
    dispatch(deactivatePlanAction({ id: id }))
      .then((res) => {
        toast.success("Deactivate Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(planListAction());
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDelete = (id) => {
    dispatch(removePlanAction(id))
      .then((res) => {
        toast.success("Delete Sucessfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(planListAction());
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let formIsValid = true;

    let data = {
      planName: planName,
      price: price,
      YealyDiscount: YealyDiscount,
      featureList: featureState,
      id: planId,
    };
    if (formIsValid) {
      dispatch(updatePlanAction(data))
        .then((res) => {
          toast.success("Update Sucessfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateEditPlanModal(false);
          dispatch(planListAction());
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <>
      <div class="tab-pane fade show" id="SubscriptionPlansTab">
        <div class="Tableheader">
          <div class="d-flex align-items-center">
            <h6>Plans</h6>
          </div>
          <div class="d-flex">
            <div class="TodosButtons mt-0 d-flex">
              <a onClick={() => handleShowCreatePlan()}>
                <img src="images/create_new_plan.png" />
                Create New Plan
              </a>
            </div>
            <div class="NewActions">
              <a href="javascript:void(0);">
                <img src="images/icon-53.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/download.png" />
              </a>
              <a href="javascript:void(0);">
                <img src="images/setting.png" />
              </a>
            </div>
          </div>
        </div>

        <div class="TableList NewTableList TableListHeader TableHeightScroll">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Projects and Jobs</th>
                <th>Modules</th>
                <th>Views</th>
                <th>Last Update</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Monthly price</th>
                <th>Yearly Discount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {planList?.map((item) => (
                <tr
                  className={
                    item?.status == "Draft" ? "PendingConfirmation" : ""
                  }
                >
                  <td>{item?.planName}</td>
                  <td>Regular Projects, Residential Jobs</td>
                  <td>
                    Accounting, Archive, Change Order, <br />
                    Construction, Design Selections, Reports, <br />
                    Sales (Contract Only), Vendor/Supplier, Warranty
                  </td>
                  <td>
                    <div class="text-black">Client and Trade</div>
                  </td>

                  <td>{moment(item?.updatedAt)?.format("ll")}</td>
                  <td>Sysadmin 1</td>
                  <td>
                    <div class={item?.status == "Draft" ? "" : "TextGreen"}>
                      {item?.status}
                    </div>
                  </td>
                  <td>$ {item?.price}</td>
                  <td>{item?.YealyDiscount} %</td>
                  <td>
                    <div class="TableThreeDots text-center">
                      <ul class="">
                        <li class="dropdown">
                          <a
                            class=""
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img src="images/dots.png" />
                          </a>
                          <ol class="dropdown-menu dropdown-menuwidth">
                            <li>
                              <a onClick={() => handleShowEditPlan(item)}>
                                <img src="images/work-order-black.png" />
                                Edit Plan
                              </a>
                            </li>
                            <li>
                              <a onClick={() => handleDelete(item?.id)}>
                                <img src="images/Action-1.png" />
                                Delete Plan
                              </a>
                            </li>
                            <li>
                              <a onClick={() => handleActive(item?.id)}>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                Activate Plan
                              </a>
                            </li>
                            <li>
                              <a onClick={() => handleDeActive(item?.id)}>
                                <label class="switch">
                                  <input type="checkbox" />
                                  <span class="slider"></span>
                                </label>
                                Deactivate Plan
                              </a>
                            </li>
                          </ol>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div class="PaginationArea">
          {/* <h6>Showing 1 to 10 of 27 entries</h6>
          <h6>
            <img src="images/leftarrow.png" /> <span>01</span>{" "}
            <img src="images/rightarrow.png" /> Out 10
          </h6> */}
        </div>
      </div>

      <Modal show={createPlanModal} className="ModalBox LargeModal BgClass">
        <a class="CloseModal ClosePermissionId" onClick={handleCloseCreatePlan}>
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Plan</h4>
          </div>
          <div class="FolderForm">
              <div class="row">
                <div class="form-group col-md-4">
                  <h6>Name</h6>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="planName"
                    value={planName}
                    class="form-control"
                    placeholder="Insert Plan"
                  />
                </div>
              </div>

              <div class="ModalToggleArea">
                <h5>Projects</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Project")
                      )
                    ]?.Project?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Jobs</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Jobs")
                      )
                    ]?.Jobs?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Modules</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(0, 5)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(5, 10)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(10, 15)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Views</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Views")
                      )
                    ]?.Views?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Pricing</h5>
                <div class="row">
                  <div class="form-group col-md-4">
                    <h6>Monthly Price</h6>
                    <input
                      type="text"
                      class="form-control"
                      onChange={handleInputChange}
                      name="price"
                      value={price}
                      placeholder="$"
                    />
                  </div>
                  <div class="form-group col-md-4 ml-5">
                    <h6>Yearly Discount</h6>
                    <input
                      type="text"
                      class="form-control"
                      onChange={handleInputChange}
                      name="YealyDiscount"
                      value={YealyDiscount}
                      placeholder="%"
                    />
                  </div>
                </div>
              </div>
              <div class="Button dropdown d-flex justify-content-between px-5">
                <button
                  class="Create"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Create Plan <img src="images/down-arrow-white.png" />
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" onClick={handleSubmit}>
                    Save and Print
                  </a>
                  <a class="dropdown-item">
                    Save and E-mail
                  </a>
                </div>
                <button class="Outline" onClick={handleCloseCreatePlan}>Cancel</button>
              </div>
          </div>
        </div>
      </Modal>

      <Modal show={editPlanModal} className="ModalBox LargeModal BgClass">
        <a class="CloseModal ClosePermissionId" onClick={handleCloseEditPlan}>
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Create New Plan</h4>
          </div>
          <div class="FolderForm">

              <div class="row">
                <div class="form-group col-md-4">
                  <h6>Name</h6>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    name="planName"
                    value={planName}
                    class="form-control"
                    placeholder="Insert Plan"
                  />
                </div>
              </div>

              <div class="ModalToggleArea">
                <h5>Projects</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Project")
                      )
                    ]?.Project?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Jobs</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Jobs")
                      )
                    ]?.Jobs?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Modules</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(0, 5)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(5, 10)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Modules")
                      )
                    ]?.Modules?.slice(10, 15)?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Views</h5>
                <div class="ToggleBody">
                  <aside>
                    {featureList?.[
                      featureList?.findIndex((item) =>
                        item?.hasOwnProperty("Views")
                      )
                    ]?.Views?.map((ele) => (
                      <div class="ToggleBodyBox">
                        <h5>{ele?.name}</h5>
                        <label class="switch">
                          <input
                            checked={featureState?.some(
                              (feature) => feature?.name == ele?.name
                            )}
                            type="checkbox"
                            onChange={(e) => handleCheck(e, ele)}
                          />
                          <span class="slider"></span>
                        </label>
                      </div>
                    ))}
                  </aside>
                </div>
              </div>
              <div class="ModalToggleArea">
                <h5>Pricing</h5>
                <div class="row">
                  <div class="form-group col-md-4">
                    <h6>Monthly Price</h6>
                    <input
                      type="text"
                      class="form-control"
                      onChange={handleInputChange}
                      name="price"
                      value={price}
                      placeholder="$"
                    />
                  </div>
                  <div class="form-group col-md-4 ml-5">
                    <h6>Yearly Discount</h6>
                    <input
                      type="text"
                      class="form-control"
                      onChange={handleInputChange}
                      name="YealyDiscount"
                      value={YealyDiscount}
                      placeholder="%"
                    />
                  </div>
                </div>
              </div>
              <div class="Button dropdown d-flex justify-content-between px-5">
                <button
                  class="Create"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Create Plan <img src="images/down-arrow-white.png" />
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" onClick={handleUpdate}>
                    Save and Print
                  </a>
                  <a class="dropdown-item">
                    Save and E-mail
                  </a>
                </div>
                <button class="Outline" onClick={handleCloseEditPlan}>
                  Cancel
                </button>
              </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IbuldAdminSubscription;
