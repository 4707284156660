import { projectProformaAction } from "../actionTypes";
const initialState = {
loader: false,
projectProformaList:[],
unitPriceList:[],
proformaHistoryList:[],
performaFinancialReportData:[],
proformaReport:[],
affordabaleRentalRevenueData:[],
projectAnnualAffordabaleRentalRevenueData:[],
projectSaleRevenueData:[],
unitCostData:[],
grossMarginData:[],
regularRentalRevenueData:[],
};

export default function projectProformaReducer(state = initialState, { type, payload }) {
  switch (type) {
    case projectProformaAction.PROFORMA_LIST_INITIATE: {
        return {...state,projectProformaList: [],loader:true};
      }
      case projectProformaAction.PROFORMA_LIST_SUCCESS: {
        return {  ...state,projectProformaList: payload,loader:false};
      }
      case projectProformaAction.PROFORMA_LIST_FAILURE: {
        return { ...state,projectProformaList:[]};
      }

      case projectProformaAction.GET_PROFORMA_UNIT_PRICE_INITIATE: {
        return {...state,unitPriceList: []};
      }
      case projectProformaAction.GET_PROFORMA_UNIT_PRICE_SUCCESS: {
        return {  ...state,unitPriceList: payload};
      }
      case projectProformaAction.GET_PROFORMA_UNIT_PRICE_FAILURE: {
        return { ...state,unitPriceList:[]};
      }


      case projectProformaAction.GET_PROFORMA_HISTORY_INITIATE: {
        return {...state,proformaHistoryList: []};
      }
      case projectProformaAction.GET_PROFORMA_HISTORY_SUCCESS: {
        return {  ...state,proformaHistoryList: payload};
      }
      case projectProformaAction.GET_PROFORMA_HISTORY_FAILURE: {
        return { ...state,proformaHistoryList:[]};
      }

      case projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_INITIATE: {
        return {...state,performaFinancialReportData: []};
      }
      case projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_SUCCESS: {
        return {  ...state,performaFinancialReportData: payload};
      }
      case projectProformaAction.GET_PROFORMA_FINANCIAL_REPORT_FAILURE: {
        return { ...state,performaFinancialReportData:[]};
      }

      case projectProformaAction.PERFORMA_REPORT_INITIATE: {
        return {...state,proformaReport: []};
      }
      case projectProformaAction.PERFORMA_REPORT_SUCCESS: {
        return {  ...state,proformaReport: payload};
      }
      case projectProformaAction.PERFORMA_REPORT_FAILURE: {
        return { ...state,proformaReport:[]};
      }
      

      case projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_INITIATE: {
        return {...state,affordabaleRentalRevenueData: []};
      }
      case projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_SUCCESS: {
        return {  ...state,affordabaleRentalRevenueData: payload};
      }
      case projectProformaAction.GET_AFFORDABLE_RENTAL_REVENUE_FAILURE: {
        return { ...state,affordabaleRentalRevenueData:[]};
      }
      

      case projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_INITIATE: {
        return {...state,projectAnnualAffordabaleRentalRevenueData: []};
      }
      case projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_SUCCESS: {
        return {  ...state,projectAnnualAffordabaleRentalRevenueData: payload};
      }
      case projectProformaAction.PROJECT_ANNUAL_AFFORDABLE_RENTAL_REVENUE_FAILURE: {
        return { ...state,projectAnnualAffordabaleRentalRevenueData:[]};
      }

      case projectProformaAction.GET_PROJECT_SALES_REVENUE_INITIATE: {
        return {...state,projectSaleRevenueData: []};
      }
      case projectProformaAction.GET_PROJECT_SALES_REVENUE_SUCCESS: {
        return {  ...state,projectSaleRevenueData: payload};
      }
      case projectProformaAction.GET_PROJECT_SALES_REVENUE_FAILURE: {
        return { ...state,projectSaleRevenueData:[]};
      }

      case projectProformaAction.GET_UNIT_COST_ANALYSIS_INITIATE: {
        return {...state,unitCostData: []};
      }
      case projectProformaAction.GET_UNIT_COST_ANALYSIS_SUCCESS: {
        return {  ...state,unitCostData: payload};
      }
      case projectProformaAction.GET_UNIT_COST_ANALYSIS_FAILURE: {
        return { ...state,unitCostData:[]};
      }


      case projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_INITIATE: {
        return {...state,grossMarginData: []};
      }
      case projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_SUCCESS: {
        return {  ...state,grossMarginData: payload};
      }
      case projectProformaAction.GET_GROSS_MARGIN_PER_UNIT_FAILURE: {
        return { ...state,grossMarginData:[]};
      }

      case projectProformaAction.GET_REGULAR_RENTAL_REVENUE_INITIATE: {
        return {...state,regularRentalRevenueData: []};
      }
      case projectProformaAction.GET_REGULAR_RENTAL_REVENUE_SUCCESS: {
        return {  ...state,regularRentalRevenueData: payload};
      }
      case projectProformaAction.GET_REGULAR_RENTAL_REVENUE_FAILURE: {
        return { ...state,regularRentalRevenueData:[]};
      }
    default:
      return state;
  }
}
