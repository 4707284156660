import { ibuildAdminActionTypes, itineraryAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));


  
  export function addIbuildRoleAction(payload) {
    const token = isLoggedIn("adminData1");
    // const updatedData = multiPartData({ image: payload.image, ...payload })
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        axios
        // companies/2/Category
          .post(`${Url}ibuild-admin-roles`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }



  
  export function addIbuildUserAction(payload) {
    const token = isLoggedIn("adminData1");
    // const updatedData = multiPartData({ image: payload.image, ...payload })
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${Url}ibuild-admin-user`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status == 201) {
              resolve(data);
            } else {
              reject(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }


  
  
  export function updateIbuildUserAction(id,payload) {
    const token = isLoggedIn("adminData1");
    // const updatedData = multiPartData({ image: payload.image, ...payload })
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        axios
        // companies/2/Category
          .put(`${Url}ibuild-admin-user/${id}`, payload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", response);
            if (data && data.status == 200) {
              resolve(data);
            } else {
              reject(data);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  }


  
export const getAllRoleListInitate = () => ({
  type: ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_INITIATE,
});

export const getAllRoleListSuccess = (data) => ({
  type: ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_SUCCESS,
  payload: data,
});

export const getAllRoleListFailure = (data) => ({
  type: ibuildAdminActionTypes.IBUILD_ADMIN_ROLE_LIST_FAILURE,
  payload: data,
});

export function getAllIbuildAdminRoleListAction(sid, name, ) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllRoleListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}ibuild-admin-roles`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          console.log("data", data);
          if (data && data.status == 200 ) {
            dispatch(getAllRoleListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllRoleListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getAllRoleListSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getAllRoleListFailure(err));
            reject(err)
          }
        
        });
    });
  };
}



  
export const getAllUserListInitate = () => ({
    type: ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_INITIATE,
  });
  
  export const getAllUserListSuccess = (data) => ({
    type: ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllUserListFailure = (data) => ({
    type: ibuildAdminActionTypes.IBUILDADMIN_USER_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllIbuildAdminUserListAction(sid, name, ) {
    const token = isLoggedIn("adminData1");
  
    return (dispatch) => {
      dispatch(getAllUserListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}ibuild-admin-user`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            console.log("data", data);
            if (data && data.status == 200 || 201) {
              dispatch(getAllUserListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllUserListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllUserListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllUserListFailure(err));
              reject(err)
            }
          
          });
      });
    };
  }

  


export function removeIbuildAdminUserAction( id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}ibuild-admin-user/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}





export function lockIbuildAdminUserRoleAction( id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}users/${id}/lock`,{},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}



export function UnlockIbuildAdminUserRoleAction( id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}users/${id}/active`,{},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200)) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}





export function resetIbuildAdminUserPasswordAction( id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Url}ibuild-user/send-reset`, id,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) ) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}





export function userActivityDataAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .get(
          `${Url}users/${payload}/user-activities`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}


