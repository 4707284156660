import { tradeViewAuthAction } from '../actionTypes'

const initialState = {
  unitList:[],
  tradeViewData: {},
  loader: false,
  loginStatus: false,
  vendorTradeData:[],
  tradeCommentList:[],
  tradeSubCommentList:[],
  constructionScheduleList:[],
  constructionScheduleLoader:false,
  constructionSupportDocsList:[],
  constructionSupportListLoader:false,
  calendarData:[],
  memberList:[],
  taskLoader:false ,
  todoTaskList:[],
  todoViewTaskDetails:[],
  todoViewTaskHistoryList:[],
  warrantyWorkOrderLoader:false,
  todoWarrantyWorkOrderList:[],
  todoBuildersList:[],
  projectList:[],
  projectJobList:[],
  warrantSupportODcsListLoader:false,
  todoWarrantySupportDocsList:[],
  calendarTaskList:[],
  warrantyWorkOrderDetails:[],
  quoteRequestLoader:false ,
  quoteRequestList:[],
  purchaseOrderLoader:false ,
  purchaseOrderList:[],
  changeOrderLoader:false ,
  changeOrderCheckList:[],
  desginCategoryInterior:[],
  desginCategoryExterior:[],
  builderList:[],
  projectByBuilderData:[],
  jobByProjectData:[],
  allAppointmentList:[],
  folderHistoryList:[],
  pendingDateSelectionList:[],
  folderNFileList:[],
  designCategoryIdData:[],
  actionRequriedSalesList:[],
  jobList:[],
  membersList:[],
  roleList:[],
  allProjectList:[],
  discussionList:[],
  unitListQr:[],
}

export default function tradeViewLogin(state = initialState, {type, payload}) {
  switch(type) {
    case tradeViewAuthAction.TRADE_VIEW_LOGIN_INITIATE: {
      return {
        ...state, loader: true, tradeViewData: {}
      }
    }
    case tradeViewAuthAction.TRADE_VIEW_LOGIN_SUCCESS: {
				// 	delete payload.data.password
				// delete payload.data.plainPassword
      // console.log("payload",payload);
       window.localStorage.setItem('tradeViewLogin', JSON.stringify(payload))
       
      return {
        ...state, loader: false, loginStatus: true, tradeViewData: payload.data
      }
    }
    case tradeViewAuthAction.TRADE_VIEW_LOGIN_FAILURE: {
      return {
        ...state, loader: false,tradeViewData: payload
      }
    }

    case tradeViewAuthAction.UNIT_LIST_INITIATE: {
      return {...state,  unitList:[]}
    }
    case tradeViewAuthAction.UNIT_LIST_SUCCESS: {
      return {...state,  unitList:payload}
    }
    case tradeViewAuthAction.UNIT_LIST_FAILURE: {
      return {...state, unitList:[]}
    }

    case tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_INITIATE: {
      return {...state,  vendorTradeData:[]}
    }
    case tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_SUCCESS: {
      return {...state,  vendorTradeData:payload}
    }
    case tradeViewAuthAction.GET_TRADE_VENDOR_BY_USER_ID_FAILURE: {
      return {...state, vendorTradeData:[]}
    }

    case tradeViewAuthAction.GET_TRADE_COMMENT_INITIATE: {
      return {...state,  tradeCommentList:[]}
    }
    case tradeViewAuthAction.GET_TRADE_COMMENT_SUCCESS: {
      return {...state,  tradeCommentList:payload}
    }
    case tradeViewAuthAction.GET_TRADE_COMMENT_FAILURE: {
      return {...state, tradeCommentList:[]}
    }

    case tradeViewAuthAction.GET_SUB_TRADE_COMMENT_INITIATE: {
      return {...state,  tradeSubCommentList:[]}
    }
    case tradeViewAuthAction.GET_SUB_TRADE_COMMENT_SUCCESS: {
      return {...state,  tradeSubCommentList:payload}
    }
    case tradeViewAuthAction.GET_SUB_TRADE_COMMENT_FAILURE: {
      return {...state, tradeSubCommentList:[]}
    }

    case tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_INITIATE: {
      return {...state, constructionScheduleLoader:true, constructionScheduleList:[]}
    }
    case tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_SUCCESS: {
      return {...state, constructionScheduleLoader:false, constructionScheduleList:payload}
    }
    case tradeViewAuthAction.CONSTRUCTION_SCHEDULE_LIST_FAILURE: {
      return {...state, constructionScheduleLoader:false,constructionScheduleList:[]}
    }

    case tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_INITIATE: {
      return {...state, constructionSupportListLoader:true, constructionSupportDocsList:[]}
    }
    case tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_SUCCESS: {
      return {...state, constructionSupportListLoader:false, constructionSupportDocsList:payload}
    }
    case tradeViewAuthAction.CONSTRUCTION_SUPPORT_DOCS_LIST_FAILURE: {
      return {...state, constructionSupportListLoader:false,constructionSupportDocsList:[]}
    }

    case tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_INITIATE: {
      return {...state, calendarData:[]}
    }
    case tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_SUCCESS: {
      return {...state, calendarData:payload}
    }
    case tradeViewAuthAction.CONSTRUCTION_CALENDAR_DATA_FAILURE: {
      return {...state,calendarData:[]}
    }


    case tradeViewAuthAction.TODO_JOB_LIST_INITIATE: {
      return {...state, jobList:[]}
    }
    case tradeViewAuthAction.TODO_JOB_LIST_SUCCESS: {
      return {...state, jobList:payload}
    }
    case tradeViewAuthAction.TODO_JOB_LIST_FAILURE: {
      return {...state,jobList:[]}
    }

    case tradeViewAuthAction.TODO_VENDOR_LIST_INITIATE: {
      return {...state, vendorList:[]}
    }
    case tradeViewAuthAction.TODO_VENDOR_LIST_SUCCESS: {
      return {...state, vendorList:payload}
    }
    case tradeViewAuthAction.TODO_VENDOR_LIST_FAILURE: {
      return {...state,vendorList:[]}
    }

    case tradeViewAuthAction.TODO_MEMBER_LIST_INITIATE: {
      return {...state, memberList:[]}
    }
    case tradeViewAuthAction.TODO_MEMBER_LIST_SUCCESS: {
      return {...state, memberList:payload}
    }
    case tradeViewAuthAction.TODO_MEMBER_LIST_FAILURE: {
      return {...state,memberList:[]}
    }

    case tradeViewAuthAction.TODO_TASK_LIST_INITIATE: {
      return {...state, taskLoader:true ,todoTaskList:[]}
    }
    case tradeViewAuthAction.TODO_TASK_LIST_SUCCESS: {
      return {...state,taskLoader:false , todoTaskList:payload}
    }
    case tradeViewAuthAction.TODO_TASK_LIST_FAILURE: {
      return {...state,taskLoader:false ,todoTaskList:[]}
    }

    case tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_INITIATE: {
      return {...state,todoViewTaskDetails:[]}
    }
    case tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_SUCCESS: {
      return {...state, todoViewTaskDetails:payload}
    }
    case tradeViewAuthAction.TODO_VIEW_TASK_DETAILS_FAILURE: {
      return {...state,todoViewTaskDetails:[]}
    }

    case tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_INITIATE: {
      return {...state,todoViewTaskHistoryList:[]}
    }
    case tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_SUCCESS: {
      return {...state, todoViewTaskHistoryList:payload}
    }
    case tradeViewAuthAction.TODO_VIEW_TASK_HISTORY_FAILURE: {
      return {...state,todoViewTaskHistoryList:[]}
    }

    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_INITIATE: {
      return {...state,warrantyWorkOrderLoader:true,todoWarrantyWorkOrderList:[]}
    }
    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_SUCCESS: {
      return {...state, warrantyWorkOrderLoader:false,todoWarrantyWorkOrderList:payload}
    }
    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_LIST_FAILURE: {
      return {...state,warrantyWorkOrderLoader:false,todoWarrantyWorkOrderList:[]}
    }

    case tradeViewAuthAction.TODO_BUILDER_LIST_INITIATE: {
      return {...state,todoBuildersList:[]}
    }
    case tradeViewAuthAction.TODO_BUILDER_LIST_SUCCESS: {
      return {...state, todoBuildersList:payload}
    }
    case tradeViewAuthAction.TODO_BUILDER_LIST_FAILURE: {
      return {...state,todoBuildersList:[]}
    }

    case tradeViewAuthAction.TODO_PORJECT_LIST_INITIATE: {
      return {...state,projectList:[]}
    }
    case tradeViewAuthAction.TODO_PORJECT_LIST_SUCCESS: {
      return {...state, projectList:payload}
    }
    case tradeViewAuthAction.TODO_PORJECT_LIST_FAILURE: {
      return {...state,projectList:[]}
    }
    

    case tradeViewAuthAction.TODO_ALL_JOB_LIST_INITIATE: {
      return {...state,projectJobList:[]}
    }
    case tradeViewAuthAction.TODO_ALL_JOB_LIST_SUCCESS: {
      return {...state, projectJobList:payload}
    }
    case tradeViewAuthAction.TODO_ALL_JOB_LIST_FAILURE: {
      return {...state,projectJobList:[]}
    }

    case tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_INITIATE: {
      return {...state,warrantSupportODcsListLoader:true,todoWarrantySupportDocsList:[]}
    }
    case tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_SUCCESS: {
      return {...state, warrantSupportODcsListLoader:false,todoWarrantySupportDocsList:payload}
    }
    case tradeViewAuthAction.TODO_WARRANTY_SUPPORT_DOCS_LIST_FAILURE: {
      return {...state,warrantSupportODcsListLoader:false,todoWarrantySupportDocsList:[]}
    }

    case tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_INITIATE: {
      return {...state,calendarTaskList:[]}
    }
    case tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_SUCCESS: {
      return {...state, calendarTaskList:payload}
    }
    case tradeViewAuthAction.TODO_CALENDAR_TASK_LIST_FAILURE: {
      return {...state,calendarTaskList:[]}
    }

    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_INITIATE: {
      return {...state,warrantyWorkOrderDetails:[]}
    }
    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_SUCCESS: {
      return {...state, warrantyWorkOrderDetails:payload}
    }
    case tradeViewAuthAction.TODO_WARRANTY_WORK_ORDER_DETAILS_FAILURE: {
      return {...state,warrantyWorkOrderDetails:[]}
    }

    case tradeViewAuthAction.GET_QUOTE_REQUEST_INITIATE: {
      return {...state,quoteRequestLoader:true ,quoteRequestList:[]}
    }
    case tradeViewAuthAction.GET_QUOTE_REQUEST_SUCCESS: {
      return {...state,quoteRequestLoader:false , quoteRequestList:payload}
    }
    case tradeViewAuthAction.GET_QUOTE_REQUEST_FAILURE: {
      return {...state,quoteRequestLoader:false ,quoteRequestList:[]}
    }

    case tradeViewAuthAction.GET_PURCHASE_ORDER_INITIATE: {
      return {...state,purchaseOrderLoader:true ,purchaseOrderList:[]}
    }
    case tradeViewAuthAction.GET_PURCHASE_ORDER_SUCCESS: {
      return {...state,purchaseOrderLoader:false , purchaseOrderList:payload}
    }
    case tradeViewAuthAction.GET_PURCHASE_ORDER_FAILURE: {
      return {...state,purchaseOrderLoader:false ,purchaseOrderList:[]}
    }

    case tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_INITIATE: {
      return {...state,changeOrderLoader:true ,changeOrderCheckList:[]}
    }
    case tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_SUCCESS: {
      return {...state,changeOrderLoader:false , changeOrderCheckList:payload}
    }
    case tradeViewAuthAction.CHANGE_ORDER_CHECKLIST_LIST_FAILURE: {
      return {...state,changeOrderLoader:false ,changeOrderCheckList:[]}
    }


    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_INITIATE: {
      return {...state,desginCategoryInterior:[]}
    }
    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_SUCCESS: {
      return {...state, desginCategoryInterior:payload}
    }
    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_INTERIOR_FAILURE: {
      return {...state,desginCategoryInterior:[]}
    }

    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_INITIATE: {
      return {...state,desginCategoryExterior:[]}
    }
    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_SUCCESS: {
      return {...state, desginCategoryExterior:payload}
    }
    case tradeViewAuthAction.GET_DESIGN_BY_CATEGORY_EXTERIOR_FAILURE: {
      return {...state,desginCategoryExterior:[]}
    }

    case tradeViewAuthAction.BUILDER_LIST_INITIATE: {
      return {...state,builderList:[]}
    }
    case tradeViewAuthAction.BUILDER_LIST_SUCCESS: {
      return {...state, builderList:payload}
    }
    case tradeViewAuthAction.BUILDER_LIST_FAILURE: {
      return {...state,builderList:[]}
    }

    case tradeViewAuthAction.GET_PROJECT_BY_BUILDER_INITIATE: {
      return {...state,projectByBuilderData:[]}
    }
    case tradeViewAuthAction.GET_PROJECT_BY_BUILDER_SUCCESS: {
      return {...state, projectByBuilderData:payload}
    }
    case tradeViewAuthAction.GET_PROJECT_BY_BUILDER_FAILURE: {
      return {...state,projectByBuilderData:[]}
    }

    case tradeViewAuthAction.GET_JOB_BY_PROJECT_INITIATE: {
      return {...state,jobByProjectData:[]}
    }
    case tradeViewAuthAction.GET_JOB_BY_PROJECT_SUCCESS: {
      return {...state, jobByProjectData:payload}
    }
    case tradeViewAuthAction.GET_JOB_BY_PROJECT_FAILURE: {
      return {...state,jobByProjectData:[]}
    }

    case tradeViewAuthAction.GET_APPOINTMENT_ALL_INITIATE: {
      return {...state,allAppointmentList:[]}
    }
    case tradeViewAuthAction.GET_APPOINTMENT_ALL_SUCCESS: {
      return {...state, allAppointmentList:payload}
    }
    case tradeViewAuthAction.GET_APPOINTMENT_ALL_FAILURE: {
      return {...state,allAppointmentList:[]}
    }

    case tradeViewAuthAction.GET_APPOINTMENT_ALL_INITIATE: {
      return {...state,allAppointmentList:[]}
    }
    case tradeViewAuthAction.GET_APPOINTMENT_ALL_SUCCESS: {
      return {...state, allAppointmentList:payload}
    }
    case tradeViewAuthAction.GET_APPOINTMENT_ALL_FAILURE: {
      return {...state,allAppointmentList:[]}
    }

    case tradeViewAuthAction.GET_FOLDER_HISTORY_INITIATE: {
      return {...state,folderHistoryList:[]}
    }
    case tradeViewAuthAction.GET_FOLDER_HISTORY_SUCCESS: {
      return {...state, folderHistoryList:payload}
    }
    case tradeViewAuthAction.GET_FOLDER_HISTORY_FAILURE: {
      return {...state,folderHistoryList:[]}
    }


    case tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_INITIATE: {
      return {...state,pendingDateSelectionList:[]}
    }
    case tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_SUCCESS: {
      return {...state, pendingDateSelectionList:payload}
    }
    case tradeViewAuthAction.PENDING_DATE_SELECTION_LIST_FAILURE: {
      return {...state,pendingDateSelectionList:[]}
    }

    case tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_INITIATE: {
      return {...state,folderNFileList:[]}
    }
    case tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_SUCCESS: {
      return {...state, folderNFileList:payload}
    }
    case tradeViewAuthAction.GET_FOLDER_N_FILE_LIST_FAILURE: {
      return {...state,folderNFileList:[]}
    }

    case tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_INITIATE: {
      return {...state,designCategoryIdData:[]}
    }
    case tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_SUCCESS: {
      return {...state, designCategoryIdData:payload}
    }
    case tradeViewAuthAction.GET_DESIGN_CATEGORY_BY_ID_FAILURE: {
      return {...state,designCategoryIdData:[]}
    }

    case tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_INITIATE: {
      return {...state,actionRequriedSalesList:[]}
    }
    case tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_SUCCESS: {
      return {...state, actionRequriedSalesList:payload}
    }
    case tradeViewAuthAction.ACTION_REQURIED_SALES_DOCUMENT_LIST_FAILURE: {
      return {...state,actionRequriedSalesList:[]}
    }

    case tradeViewAuthAction.TRADE_VIEW_JOB_LIST_INITIATE: {
      return {...state,jobList:[]}
    }
    case tradeViewAuthAction.TRADE_VIEW_JOB_LIST_SUCCESS: {
      return {...state, jobList:payload}
    }
    case tradeViewAuthAction.TRADE_VIEW_JOB_LIST_FAILURE: {
      return {...state,jobList:[]}
    }

    case tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_INITIATE: {
      return {...state,membersList:[]}
    }
    case tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_SUCCESS: {
      return {...state, membersList:payload}
    }
    case tradeViewAuthAction.TRADE_VIEW_MEMBER_LIST_FAILURE: {
      return {...state,membersList:[]}
    }

    case tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_INITIATE: {
      return {...state,roleList:[]}
    }
    case tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_SUCCESS: {
      return {...state, roleList:payload}
    }
    case tradeViewAuthAction.GET_ROLES_FOR_TRADE_VIEW_FAILURE: {
      return {...state,roleList:[]}
    }

    case tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_INITIATE: {
      return {...state,allProjectList:[]}
    }
    case tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_SUCCESS: {
      return {...state, allProjectList:payload}
    }
    case tradeViewAuthAction.GET_ALL_PROJECTS_TRADE_VIEW_FAILURE: {
      return {...state,allProjectList:[]}
    }

    case tradeViewAuthAction.GET_VENDOR_USER_INITIATE: {
      return {...state,vendorUserList:[]}
    }
    case tradeViewAuthAction.GET_VENDOR_USER_SUCCESS: {
      return {...state, vendorUserList:payload}
    }
    case tradeViewAuthAction.GET_VENDOR_USER_FAILURE: {
      return {...state,vendorUserList:[]}
    }

    case tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_INITIATE: {
      return {...state,discussionList:[]}
    }
    case tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_SUCCESS: {
      return {...state, discussionList:payload}
    }
    case tradeViewAuthAction.LIST_DISCUSSION_TRADE_VIEW_FAILURE: {
      return {...state,discussionList:[]}
    }

    case tradeViewAuthAction.GET_UNIT_QR_INITIATE: {
      return {...state,unitListQr:[]}
    }
    case tradeViewAuthAction.GET_UNIT_QR_SUCCESS: {
      return {...state, unitListQr:payload}
    }
    case tradeViewAuthAction.GET_UNIT_QR_FAILURE: {
      return {...state,unitListQr:[]}
    }
    
    default: 
    return state
}
}

