import { multifamilyJobConstantDK } from "../actionTypes";
const initialState = {
  loader: false,
  multifamilyData:{},


};

export default function multifamilyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case multifamilyJobConstantDK.GET_MULTIFAMILY_INITIATE: {
        return {
          ...state,
          multifamilyData: [],
          loader:true
        };
      }
      case multifamilyJobConstantDK.GET_MULTIFAMILY_SUCCESS: {
        return {
          ...state,
          multifamilyData: payload?.data,
          loader:false
        };
      }
      case multifamilyJobConstantDK.GET_MULTIFAMILY_FAILURE: {
        return {
          ...state,
          multifamilyData: payload.data,
          loader:false
        };
      }

     
    

    default:
      return state;
  }
}




