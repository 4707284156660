import moment from 'moment';
import React from 'react'
import Modal from "react-bootstrap/Modal";
function SmsInfo({ SmsInfoModal, SetSmsInfoModal,userData }) {
    const handleMultifamilyJobStep1ModalClose = () => {
        SetSmsInfoModal(false);
    };
    const handleMultifamilyJobStep1ModalShow = () => {
        SetSmsInfoModal(true);
    };
    return (
        <Modal show={SmsInfoModal} className="ModalBox LargeModal">
            <div class="modal-content BgCreate">
                <a
                    onClick={handleMultifamilyJobStep1ModalClose}
                    class="CloseModal"
                    data-toggle="modal"
                >
                    ×
                </a>
                <div class="FormArea">
                    <div class="ModalTitleBox">
                        <h4>SMS Opt-in User information</h4>
                    </div>
                    <div class="JobTitleArea mb-2">
                        <div class="JobBox">
                            <h6><span>User:</span>&nbsp;{userData?.name ?userData?.name : userData?.vendorName}</h6>
                        </div>

                        <div class="JobBox">
                            <h6>SMS Information</h6>
                        </div>
                    </div>

                    <div class="TableList CommonHeading TableHeightScroll">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date of Opt-in</th>
                                    <th>Phone Number</th>
                                    <th>Permission Granted<br /> to Receive SMS</th>
                                    <th>Opt-In Cancelled by User</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{moment(userData?.createdAt).format("YYYY-MM-DD")}</td>
                                    <td>{userData?.phoneNumber}</td>
                                    <td>Yes</td>
                                    <td></td>
                                </tr>
                              
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

 </Modal >
  )
}

export default SmsInfo