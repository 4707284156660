import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";



const AddNoteModal = ({ addNoteModal, setAddNoteModal, setAssignResouceModal}) => {

  const dispatch = useDispatch()
  const handleOkaypayModalClose = () => {setAddNoteModal(false)
    setAssignResouceModal(true)
  }

 





  return (
    <>
      <Modal
        show={addNoteModal}
        onHide={handleOkaypayModalClose}
        className="ModalBox SmallModal"
      >
        <a
          onClick={handleOkaypayModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Add New Note</h4>
        </div>
        <h6>Note #1</h6>
        <div class="JobTitleArea">
          <div class="JobBox">
            <h6><span>Job:</span> UUnit3-CHI-2B-315</h6>
          </div>
          <div class="JobBox mb-3">
            <h6><span>Origin:</span> #185</h6>
          </div>
          <h6>Type</h6>

          <div class="FolderForm">
            <div class="form-group mb-4">
              <label class="Radio">Text
                <input type="radio" name="Cuisine" value="text" />
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="form-group">
              <label class="Radio">File
                <input type="radio" name="Cuisine" value="file" />
                <span class="checkmark"></span>
              </label>
            </div>

            <div class="text RadioText">
              <div class="form-group">
                <h6>Message (Max 1000 chars limit)</h6>
                <input type="text" class="form-control" placeholder="Write here ....." />
                <small class="form-text text-right">985 characters remaining</small>
              </div>
            </div>

            <div class="file RadioText">
              <div class="form-group">
                <h6>Select Files</h6>
                <div class="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div class="FilesAdded">
                <label>File_name.docs</label>
                <span><img src="images/fileview.png" /></span>
                <span><img src="images/Cross.png" class="cross" /></span>
              </div>
            </div>

            <div class="Button d-flex justify-content-between mt-4">
              <button class="Create FolderPermissionId" data-toggle="modal" data-target="#AssignResourcesHover" data-dismiss="modal">Update Note</button>
              <button class="Outline FolderPermissionId" data-toggle="modal" data-target="#AssignResourcesHover" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>

   
      </Modal>


    </>
  );
};

export default AddNoteModal;
