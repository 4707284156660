import { projectConstantDK } from "../actionTypes";
const initialState = {
  loader: false,
newProjectData:{},


};

export default function projectReducerDk(state = initialState, { type, payload }) {
  switch (type) {
    case projectConstantDK.GET_NEW_PROJECT_INITIATE: {
        return {
          ...state,
          newProjectData: [],
          loader:true
        };
      }
      case projectConstantDK.GET_NEW_PROJECT_SUCCESS: {
        return {
          ...state,
          newProjectData: payload,
          loader:false
        };
      }
      case projectConstantDK.GET_NEW_PROJECT_FAILURE: {
        return {
          ...state,
          newProjectData: payload.data,
          loader:false
        };
      }

     
    

    default:
      return state;
  }
}




