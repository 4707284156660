import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import "../App.css";
import taskSquare from "../assets/images/task-square.png";
import tasking1 from "../assets/images/taskimg-1.png";
import construction from "../assets/images/construction.png";
import box1 from "../assets/images/box-1.png";
import box2 from "../assets/images/box-2.png";
import taskHistory from "../assets/images/task-history.png";
import exportToCalendar from "../assets/images/export-to-calendar.png";
import rightarrow2 from "../assets/images/rightarrow2.png";
import googleCalendar from "../assets/images/google-calendar.png";
import outlookCalendar from "../assets/images/outlook-calendar.png";
import SetReminder from "../assets/images/set-reminder.png";
import emailCalendar from "../assets/images/email-calendar.png";
import next_calendar from "../assets/images/next_calendar.png";
import OwlCarousel from "react-owl-carousel2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getListProjectJobs } from "../redux/action/warrantyAction";
import {
  getAllLandDevelopmentHomelListAction,
  getAllMultiFamilyHomelListAction,
  getAllResidentaiHomelListAction,
  getAllTaskHomelListAction,
  getAllTodoHomelListAction,
  getIternaryHomeList,
  getLandDevelopmentProjectList,
  getLandPhaseList,
  getMultiBlocksList,
  getMultiFamilyProjectList,
} from "../redux/action/homeAction";
import {
  getAllListProjectJobs,
  getAllProjects,
} from "../redux/action/modelsAction";
import { checkArray } from "../utils/CheckType";

const initLeadState = {
  ProjectId: "",
};

const constructionInit = {
  jobListShow: false,
  insideJobListShow: false,
  changeOrderShow: false,
  designSelectionShow: false,
  clientViewConfigShow: false,
  accountingShow: false,
  warrantyShow: false,
  estimationShow: false,
  constructionShow: false,
  resdentalId: -1,
 accountingShow: false,
 

 
};
const Residential = () => {
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const { ProjectId } = iLeadState;
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [iState, updateState] = useState(constructionInit);
  const {
    jobListShow,
    insideJobListShow,
    estimationShow,
    changeOrderShow,
    designSelectionShow,
    clientViewConfigShow,
    accountingShow,
    warrantyShow,
    constructionShow,
    resdentalId,

  
  } = iState;
  const dispatch = useDispatch();
  const {
    residentialHomeListData,
    landDevelopmentHomeListData,
    multiFamilyHomeListData,
    taskHomeListData,
    todoHomeListData,
    iternaryHomeListData,
    multiblocksList,
    multiFamilyProjectList,
    landDevelopmentProjectList,
    landPhaseList,
  } = useSelector((state) => state.homeReducer);

  const { projectsList, listProjectJobs } = useSelector(
    (state) => state.modelsReducer
  );
  // console.log("multiblocksList", multiblocksList);
  console.log("landDevelopmentProjectList", landDevelopmentProjectList);
  console.log("landPhaseList", landPhaseList);

  

  const options = {
    items: 3,
    nav: true,
    navText: [
      "<i class='fa fa-angle-left'></i>",
      "<i class='fa fa-angle-right'></i>",
    ],
    rewind: true,
    autoplay: false,
    margin: 20,
  };

  const handleHideShow = () => {
    updateState({ ...iState, insideJobListShow: jobListShow });
  };

  const { projectJobList } = useSelector((state) => state.warrantyReducer);

  const handleChangeOrderShow = () => {
    updateState({ ...iState, changeOrderShow: !changeOrderShow });
  };

  useEffect(() => {
    if (changeOrderShow) {
      dispatch(getAllProjects());
    }
  }, [changeOrderShow]);

  const handleConstructionShow = () => {
    updateState({ ...iState, constructionShow: !constructionShow });
  };

  useEffect(() => {
    if (constructionShow) {
      dispatch(getAllProjects());
    }
  }, [constructionShow]);

  useEffect(() => {
    if (iBuildLocalData.user.CompanyId != null) {
      // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
      dispatch(getAllLandDevelopmentHomelListAction(ProjectId));
      dispatch(getAllMultiFamilyHomelListAction(ProjectId));
      dispatch(getAllResidentaiHomelListAction(ProjectId));
      dispatch(getAllTodoHomelListAction(ProjectId));
      dispatch(getAllTaskHomelListAction(ProjectId));
      dispatch(getIternaryHomeList());
    }
  }, [ProjectId, dispatch]);
  const handleChildData = (data) => {
    dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateLeadState({ ...iLeadState, ProjectId: data });
  };

  //  useEffect(()=>{
  //   if(iBuildLocalData?.user?.CompanyId!=null){
  //     dispatch(getListProjectJobs(iBuildLocalData?.user?.CompanyId))
  //   }

  //  },[])

  const handleDesignSelectionShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, designSelectionShow: !designSelectionShow });
  };

  useEffect(() => {
    if (designSelectionShow) {
      dispatch(getAllProjects());
    }
  }, [designSelectionShow]);

  const handleClientViewConfigShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, clientViewConfigShow: !clientViewConfigShow });
  };

  const handleAccountingShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, accountingShow: !accountingShow });
  };

  const handleEstimationShow = () => {
    // dispatch(getListProjectJobs(iBuildLocalData.user.CompanyId));
    updateState({ ...iState, estimationShow: !estimationShow });
  };

  useEffect(() => {
    if (estimationShow) {
      dispatch(getAllProjects());
    }
  }, [estimationShow]);

  const handleWarrantyShow = () => {
    updateState({ ...iState, warrantyShow: !warrantyShow });
  };

  useEffect(() => {
    if (warrantyShow) {
      dispatch(getAllProjects());
    }
  }, [warrantyShow]);

  const handleJobListByProject = (Id, Index) => {
    dispatch(getAllListProjectJobs(Id));
    updateState((prev) => ({
      ...prev,
      resdentalId: prev.resdentalId == Index ? -1 : Index,
    }));
  };

 
  return (
    <div>
    <Header sendDataToParent={handleChildData} data="dfkldsjflsd" />
    <SideNav />
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="MainTitle2">
          <h2>Welcome,</h2>
        </div>
        <div className="CommonBoxArea">
          <OwlCarousel options={options}>
            <div className="item">
              {/* <Link to="/individual_components_part2_User-WorkflowTasks"> */}
              <Link to="/individual-components-part-User-WorkflowTasks">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Task</h6>
                    <h5>{taskHomeListData?.task?.count}</h5>
                    <figure>
                      <img src={taskSquare} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(taskHomeListData?.task?.task) &&
                      taskHomeListData?.task?.task?.map((ele, i) => {
                        return (
                          <>
                            <div className="CommonBoxBodyUser">
                              <div className="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div className="">
                                  <h6>{ele?.description}</h6>
                                  <p>{ele?.JobType}</p>
                                </div>
                              </div>
                              <figure>
                                <img src={tasking1} />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{taskHomeListData?.task?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/notification">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Notification</h6>
                    <h5>4,266</h5>
                    <figure>
                      <img src={construction} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                      <span className="CbbOverlay" />
                    </div>
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                      <span className="CbbOverlay" />
                    </div>
                    <div className="CommonBoxBodyUser">
                      <div className="CommonBoxBodyTitle">
                        <span>MB</span>
                        <div className="">
                          <h6>John Doe</h6>
                          <p>Total active tasks group</p>
                        </div>
                      </div>
                      <figure>
                        <img src={tasking1} />
                      </figure>
                    </div>
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>4,266</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/todos">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Todo</h6>
                    <h5>{todoHomeListData?.todo?.count}</h5>
                    <figure>
                      <img src={taskSquare} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(todoHomeListData?.todo?.todo) &&
                      todoHomeListData?.todo?.todo?.map((ele, i) => {
                        return (
                          <>
                            <div className="CommonBoxBodyUser">
                              <div className="CommonBoxBodyTitle">
                                <span>MB</span>
                                <div className="">
                                  <h6>{ele?.description}</h6>
                                  <p>{ele?.JobType}</p>
                                </div>
                              </div>
                              <figure>
                                <img src={tasking1} />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{todoHomeListData?.todo?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="/itinerary">
                <div className="CommonBox">
                  <div className="CommonBoxHeader">
                    <h6>Itinerary</h6>
                    <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                    <figure>
                      <img src={construction} />
                    </figure>
                  </div>
                  {iternaryHomeListData?.itineraries?.itineraries?.length >
                    0 &&
                    iternaryHomeListData?.itineraries?.itineraries?.map(
                      (item, ind) => {
                        return (
                          <>
                            <div className="CommonBoxBody">
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>{item?.description?.slice(0, 30)}</h6>
                                    <p>{item?.JobType}</p>
                                  </div>
                                </div>
                                <figure>
                                  <img src={tasking1} />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}

                  <div className="CommonBoxFooter">
                    <h5>{iternaryHomeListData?.itineraries?.count ?? 0}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="clear"></div>
          </OwlCarousel>
          <div className="clear"></div>
        </div>
        {/* residential owl carousel  */}

        <div className="ResidentialCommonBoxArea">
          <div className="CommonBoxArea ">
            <div className="MainTitle">
              <h2>Residential</h2>
            </div>
            {/* <div className="home-carousel owl-carousel owl-theme"> */}
            <OwlCarousel options={options}>
              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleWarrantyShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Warranty</h6>
                    <h5>{residentialHomeListData?.warranties?.count}</h5>

                    <figure>
                      <img src={box1} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      residentialHomeListData?.warranties?.warranties
                    ) &&
                      residentialHomeListData?.warranties?.warranties?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{residentialHomeListData?.warranties?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="CommonBox CommonBoxTable"                       onClick={handleConstructionShow}
>
                  <div
                    className="CommonBoxHeader"
                  >
                      <h6>Construction</h6>
                      <h5>{residentialHomeListData?.construction?.count}</h5>
                    <figure>
                      <img src={box2} />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      residentialHomeListData?.construction?.construction
                    ) &&
                      residentialHomeListData?.construction?.construction?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{residentialHomeListData?.construction?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="CommonBox"
                  onClick={() => handleDesignSelectionShow()}
                >
                  <div className="CommonBoxHeader">
                    {/* <Link to="/design-selections"> */}
                    <h6>Design Selections</h6>
                    <h5>
                      {residentialHomeListData?.designSelections?.count}
                    </h5>
                    {/* </Link> */}
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      residentialHomeListData?.designSelections
                        ?.designSelections
                    ) &&
                      residentialHomeListData?.designSelections?.designSelections?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>
                      {residentialHomeListData?.designSelections?.count}
                    </h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>

              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleChangeOrderShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Change Order</h6>
                    <h5>{residentialHomeListData?.changeOrder?.count}</h5>
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      residentialHomeListData?.changeOrder?.changeOrder
                    ) &&
                      residentialHomeListData?.changeOrder?.changeOrder?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <span>MB</span>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{residentialHomeListData?.changeOrder?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div
                  className="CommonBox CommonBoxWarranty"
                  onClick={() => handleEstimationShow()}
                >
                  <div className="CommonBoxHeader">
                    <h6>Estimation</h6>
                    <h5>{residentialHomeListData?.estimation?.count}</h5>
                    <figure>
                      <img src="images/box-6.png" />
                    </figure>
                  </div>
                  <div className="CommonBoxBody">
                    {checkArray(
                      residentialHomeListData?.estimation?.estimation
                    ) &&
                      residentialHomeListData?.estimation?.estimation?.map(
                        (ele, i) => {
                          return (
                            <>
                              <div className="CommonBoxBodyUser">
                                <div className="CommonBoxBodyTitle">
                                  <Link to="/estimation">MB</Link>
                                  <div className="">
                                    <h6>
                                      {ele?.ResidentialJob?.subdividision}
                                    </h6>
                                    <p>{ele?.ResidentialJob?.jobNumber}</p>
                                  </div>
                                </div>

                                <figure>
                                  <img src="images/taskimg-1.png" />
                                </figure>
                                <span className="CbbOverlay" />
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                  <div className="CommonBoxFooter">
                    <h5>{residentialHomeListData?.estimation?.count}</h5>
                    <p>Total active tasks group</p>
                  </div>
                </div>
              </div>

              <div className="item">
                  <div
                    className="CommonBox CommonBoxViewConfig"
                    onClick={handleAccountingShow}
                  >
                    <div className="CommonBoxHeader">
                      <h6>Accounting</h6>
                      <h5>{residentialHomeListData?.accounting?.count}</h5>
                      <figure>
                        <img src="images/box-13.png" />
                      </figure>
                    </div>
                    <div className="CommonBoxBody">
                      {checkArray(
                        residentialHomeListData?.accounting?.accounting
                      ) &&
                        residentialHomeListData?.accounting?.accounting?.map(
                          (ele, i) => {
                            return (
                              <>
                                <div className="CommonBoxBodyUser">
                                  <div className="CommonBoxBodyTitle">
                                    <span>MB</span>
                                    <div className="">
                                      <h6>{ele?.tranId}</h6>
                                      <p>{ele?.notes}</p>
                                    </div>
                                  </div>

                                  <figure>
                                    <img src="images/taskimg-1.png" />
                                  </figure>
                                  <span className="CbbOverlay" />
                                </div>
                              </>
                            );
                          }
                        )}
                    </div>
                    <div className="CommonBoxFooter">
                      <h5>{residentialHomeListData?.accounting?.count}</h5>
                      <p>Total active tasks group</p>
                    </div>
                  </div>
                </div>
            </OwlCarousel>
            {/* </div> */}
          </div>
        </div>

        {warrantyShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleJobListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data.id}
                                                    <span className="Dots">
                                                      <img src="images/dots.png" />
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data.jobNumber}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data.unit ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data.client ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data.footage ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data?.warranties ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/warranty-dashboard"
                                                    state={data}
                                                  >
                                                    {data?.description ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </>
        )}

        {estimationShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    {/* <th>Lot</th>
                  <th>Block</th>
                  <th>Phase</th>
                  <th>Plan</th> */}
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleJobListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              {/* <td>{item.lot}</td>
                            <td>{item.block}</td>
                            <td>{item.phase}</td>
                            <td /> */}

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/estimation"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data.id}
                                                    <span className="Dots">
                                                      <img src="images/dots.png" />
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data.jobNumber}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data.unit ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data.client ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data.footage ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data?.warranties ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/estimation"
                                                    state={data}
                                                  >
                                                    {data?.description ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </>
        )}

        {clientViewConfigShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th>
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectJobList?.jobs?.length > 0
                    ? projectJobList?.jobs?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {item.i}
                              <span className="Dots">
                                <img src="images/dots.png" />
                              </span>{" "}
                            </td>
                            <td>
                              <a id="hidtableshow2">
                                {item.jobNumber}&nbsp;
                                <img src="images/down-arrow.png" />
                              </a>
                            </td>
                            <td>
                              <Link to="/client-view-config" state={item}>
                                {item.homeType}
                              </Link>
                            </td>
                            <td>
                              <Link to="/client-view-config" state={item}>
                                {item.jobAddress}
                              </Link>
                            </td>
                            <td>{item.lot}</td>
                            <td>{item.block}</td>
                            <td>{item.phase}</td>
                            <td />

                            <td>
                              <Link to="/client-view-config" state={item}>
                                {item.subdividision}
                              </Link>
                            </td>
                            <td>
                              <div className="TodoUser TodoUserHome">
                                <div className="TodoUserJob">
                                  <div className="dropdown">
                                    <p data-toggle="dropdown">
                                      <span>
                                        <img src="images/three-dots.png" />
                                      </span>
                                    </p>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuOffset"
                                    >
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="javascript:void(0);"
                                        >
                                          <img src="images/add_build.png" />{" "}
                                          Add to/Edit Build
                                          <img src="images/rightarrow2.png" />
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="lead_contract_managment.html"
                                        >
                                          <img src="images/details.png" />
                                          Details
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="javascript:void(0);"
                                        >
                                          <img src="images/change_order.png" />{" "}
                                          Change Order
                                          <img src="images/rightarrow2.png" />
                                        </a>
                                        <ul className="dropdown-menu submenu submenuhome">
                                          <li>
                                            <a
                                              to="change_order_list.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/change_order.png" />
                                              Change orders List
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="design_selections.html"
                                        >
                                          <img src="images/design.png" />
                                          Design
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="javascript:void(0);"
                                          data-toggle="modal"
                                          data-targer="#ClientContractDetailsNoUnit"
                                        >
                                          <img src="images/sales.png" />
                                          Sales
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="javascript:void(0);"
                                        >
                                          <img src="images/estimation.png" />{" "}
                                          Estimation
                                          <img src="images/rightarrow2.png" />
                                        </a>
                                        <ul className="dropdown-menu submenu submenuhome">
                                          <li>
                                            <a
                                              to="budget_details.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/estimation.png" />
                                              Budget Details
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="construction.html"
                                        >
                                          <img src="images/construction_icon.png" />{" "}
                                          Construction
                                          <img src="images/rightarrow2.png" />
                                        </a>
                                        <ul className="dropdown-menu submenu submenuhome">
                                          <li>
                                            <a
                                              to="construction.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/construuction_1.png" />{" "}
                                              Schedule View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              to="construction.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/construuction_2.png" />{" "}
                                              Gantt View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              to="construction.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/construuction_3.png" />{" "}
                                              Calendar View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              to="construction.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/construuction_4.png" />{" "}
                                              List View
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              to="construction.html"
                                              className="dropdown-item"
                                            >
                                              <img src="images/construuction_5.png" />{" "}
                                              Baseline
                                            </a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="Warranty_Dashboard.html"
                                        >
                                          <img src="images/warranty.png" />
                                          Warranty
                                        </a>
                                      </li>
                                      <li>
                                        <Link
                                          className="dropdown-item"
                                          to="/models"
                                        >
                                          <img src="images/model.png" /> Model
                                          <img src="images/rightarrow2.png" />
                                        </Link>
                                        {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          to="reports.html"
                                        >
                                          <img src="images/reports.png" />
                                          Reports
                                        </a>
                                      </li>
                                      <li>
                                        <Link
                                          className="dropdown-item"
                                          to="/client-view-config"
                                        >
                                          <img src="images/view_config.png" />
                                          Views Configs
                                        </Link>
                                      </li>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : ""}

                  <tr>
                    <td colSpan={10} className="padding_none">
                      <div className="JobBoxTable">
                        <table>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Unit Job Number</th>
                              <th>Unit#</th>
                              <th>Primary Client</th>
                              <th>Square Footage</th>
                              <th>Warranty Requests</th>
                              <th>Description</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a to="/warranty-dashboard">
                                  1{" "}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  UUnit3 -CHI -2B
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">Unit #Unit 3</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">John Doe</a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  1280 ft2 / 118.91 m2
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="/warranty-dashboard">
                                  3 outstanding ou of 4
                                </a>
                              </td>
                              <td>
                                <a to="javascript:void(0);">
                                  <i
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {changeOrderShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    {/* <th>Lot</th>
                  <th>Block</th>
                  <th>Phase</th>
                  <th>Plan</th> */}
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleJobListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              {/* <td>{item.lot}</td>
                            <td>{item.block}</td>
                            <td>{item.phase}</td>
                            <td /> */}

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data.id}
                                                    <span className="Dots">
                                                      <img src="images/dots.png" />
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data.jobNumber}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data.unit ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data.client ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data.footage ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data?.warranties ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/change-order-list"
                                                    state={data}
                                                  >
                                                    {data?.description ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </>
        )}

        {constructionShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    {/* <th>Lot</th>
                   <th>Block</th>
                   <th>Phase</th>
                   <th>Plan</th> */}
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleJobListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              {/* <td>{item.lot}</td>
                             <td>{item.block}</td>
                             <td>{item.phase}</td>
                             <td /> */}

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
         <li>
           <a
             to="javascript:void(0);"
             className="dropdown-item text-center"
             data-toggle="modal"
             data-target="#CreateNewModelHome"
           >
             <img src="images/model.png" /> Create New Model
           </a>
         </li>
       </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data.id}
                                                    <span className="Dots">
                                                      <img src="images/dots.png" />
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data.jobNumber}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data.unit ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data.client ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data.footage ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data?.warranties ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/construction"
                                                    state={data}
                                                  >
                                                    {data?.description ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {/* <a to="javascript:void(0);">
                                     <i
                                       className="fa fa-caret-down"
                                       aria-hidden="true"
                                     />
                                   </a> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </>
        )}
        {designSelectionShow && (
          <>
            <div className="JobTableHeadersWarranty">
              <div className="JobTableHeaderWarranty">
                <h5>Job/Unit List</h5>
                <div className="Actions">
                  <a to="">
                    <img src="images/icon-52.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-51.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-53.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-54.png" />
                  </a>
                  <a to="">
                    <img src="images/icon-55.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="JobTableWarranty">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Job Number</th>
                    <th>Build Type</th>
                    <th>Address</th>
                    {/* <th>Lot</th>
                  <th>Block</th>
                  <th>Phase</th>
                  <th>Plan</th> */}
                    <th>Sub Division</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="addClasss">
                  {projectsList?.projects?.length > 0
                    ? projectsList?.projects?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>
                                {item.i}
                                <span className="Dots">
                                  <img src="images/dots.png" />
                                </span>{" "}
                              </td>
                              <td>
                                <a
                                  id="hidtableshow2"
                                  onClick={() =>
                                    handleJobListByProject(item.id, i)
                                  }
                                >
                                  {item.jobNumber}&nbsp;
                                  <img
                                    src={"images/down-arrow.png"}
                                    style={{
                                      transform: `rotate(${
                                        resdentalId === i ? "180deg" : "0deg"
                                      })`,
                                    }}
                                  />
                                </a>
                              </td>
                              <td>
                                <a>{item.projectType}</a>
                              </td>
                              <td>
                                <a>{item.projectAddress}</a>
                              </td>
                              {/* <td>{item.lot}</td>
                            <td>{item.block}</td>
                            <td>{item.phase}</td>
                            <td /> */}

                              <td>
                                <a>{item.subdivisions?.map((x) => x)}</a>
                              </td>
                              <td>
                                <div className="TodoUser TodoUserHome">
                                  <div className="TodoUserJob">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/add_build.png" />{" "}
                                            Add to/Edit Build
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="lead_contract_managment.html"
                                          >
                                            <img src="images/details.png" />
                                            Details
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/change_order.png" />{" "}
                                            Change Order
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="change_order_list.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/change_order.png" />
                                                Change orders List
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="design_selections.html"
                                          >
                                            <img src="images/design.png" />
                                            Design
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                            data-toggle="modal"
                                            data-targer="#ClientContractDetailsNoUnit"
                                          >
                                            <img src="images/sales.png" />
                                            Sales
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="javascript:void(0);"
                                          >
                                            <img src="images/estimation.png" />{" "}
                                            Estimation
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="budget_details.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/estimation.png" />
                                                Budget Details
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="construction.html"
                                          >
                                            <img src="images/construction_icon.png" />{" "}
                                            Construction
                                            <img src="images/rightarrow2.png" />
                                          </a>
                                          <ul className="dropdown-menu submenu submenuhome">
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_1.png" />{" "}
                                                Schedule View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_2.png" />{" "}
                                                Gantt View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_3.png" />{" "}
                                                Calendar View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_4.png" />{" "}
                                                List View
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                to="construction.html"
                                                className="dropdown-item"
                                              >
                                                <img src="images/construuction_5.png" />{" "}
                                                Baseline
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/warranty-dashboard"
                                          >
                                            <img src="images/warranty.png" />
                                            Warranty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/models"
                                          >
                                            <img src="images/model.png" />{" "}
                                            Model
                                            <img src="images/rightarrow2.png" />
                                          </Link>
                                          {/* <ul className="dropdown-menu submenu">
        <li>
          <a
            to="javascript:void(0);"
            className="dropdown-item text-center"
            data-toggle="modal"
            data-target="#CreateNewModelHome"
          >
            <img src="images/model.png" /> Create New Model
          </a>
        </li>
      </ul> */}
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            to="reports.html"
                                          >
                                            <img src="images/reports.png" />
                                            Reports
                                          </a>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to="/client-view-config"
                                          >
                                            <img src="images/view_config.png" />
                                            Views Configs
                                          </Link>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={10} className="padding_none">
                                <div
                                  className="JobBoxTable"
                                  style={{
                                    display:
                                      resdentalId == i ? "block" : "none",
                                  }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Unit Job Number</th>
                                        <th>Unit#</th>
                                        <th>Primary Client</th>
                                        <th>Square Footage</th>
                                        <th>Warranty Requests</th>
                                        <th>Description</th>
                                        <th />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {resdentalId == i &&
                                        listProjectJobs?.jobs?.length > 0 &&
                                        listProjectJobs?.jobs?.map(
                                          (data, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data.id}
                                                    <span className="Dots">
                                                      <img src="images/dots.png" />
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data.jobNumber}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data.unit ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data.client ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data.footage ?? "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data?.warranties ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  <Link
                                                    to="/design-selections"
                                                    state={data}
                                                  >
                                                    {data?.description ??
                                                      "N/A"}
                                                  </Link>
                                                </td>
                                                <td>
                                                  {/* <a to="javascript:void(0);">
                                    <i
                                      className="fa fa-caret-down"
                                      aria-hidden="true"
                                    />
                                  </a> */}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
            </div>
          </>
        )}

{accountingShow && (
            <>
              <div className="JobTableHeadersWarranty">
                <div className="JobTableHeaderWarranty">
                  <h5>Job/Unit List</h5>
                  <div className="Actions">
                    <a to="">
                      <img src="images/icon-52.png" />
                    </a>
                    <a to="">
                      <img src="images/icon-51.png" />
                    </a>
                    <a to="">
                      <img src="images/icon-53.png" />
                    </a>
                    <a to="">
                      <img src="images/icon-54.png" />
                    </a>
                    <a to="">
                      <img src="images/icon-55.png" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="JobTableWarranty">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Number</th>
                      <th>Build Type</th>
                      <th>Address</th>
                      {/* <th>Lot</th>
                    <th>Block</th>
                    <th>Phase</th>
                    <th>Plan</th> */}
                      <th>Sub Division</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="addClasss">
                    {projectsList?.projects?.length > 0
                      ? projectsList?.projects?.map((item, i) => {
                          return (
                            <>
                              <tr key={i}>
                                <td>
                                  {item.i}
                                  <span className="Dots">
                                    <img src="images/dots.png" />
                                  </span>{" "}
                                </td>
                                <td>
                                  <a
                                    id="hidtableshow2"
                                    onClick={() =>
                                      handleJobListByProject(item.id, i)
                                    }
                                  >
                                    {item.jobNumber}&nbsp;
                                    <img
                                      src={"images/down-arrow.png"}
                                      style={{
                                        transform: `rotate(${
                                          resdentalId === i ? "180deg" : "0deg"
                                        })`,
                                      }}
                                    />
                                  </a>
                                </td>
                                <td>
                                  <a>{item.projectType}</a>
                                </td>
                                <td>
                                  <a>{item.projectAddress}</a>
                                </td>
                                {/* <td>{item.lot}</td>
                              <td>{item.block}</td>
                              <td>{item.phase}</td>
                              <td /> */}

                                <td>
                                  <a>{item.subdivisions?.map((x) => x)}</a>
                                </td>
                                <td>
                                  <div className="TodoUser TodoUserHome">
                                    <div className="TodoUserJob">
                                      <div className="dropdown">
                                        <p data-toggle="dropdown">
                                          <span>
                                            <img src="images/three-dots.png" />
                                          </span>
                                        </p>
                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuOffset"
                                        >
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/add_build.png" />{" "}
                                              Add to/Edit Build
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="lead_contract_managment.html"
                                            >
                                              <img src="images/details.png" />
                                              Details
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/change_order.png" />{" "}
                                              Change Order
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="change_order_list.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/change_order.png" />
                                                  Change orders List
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="design_selections.html"
                                            >
                                              <img src="images/design.png" />
                                              Design
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                              data-toggle="modal"
                                              data-targer="#ClientContractDetailsNoUnit"
                                            >
                                              <img src="images/sales.png" />
                                              Sales
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="javascript:void(0);"
                                            >
                                              <img src="images/estimation.png" />{" "}
                                              Estimation
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="budget_details.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/estimation.png" />
                                                  Budget Details
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="construction.html"
                                            >
                                              <img src="images/construction_icon.png" />{" "}
                                              Construction
                                              <img src="images/rightarrow2.png" />
                                            </a>
                                            <ul className="dropdown-menu submenu submenuhome">
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_1.png" />{" "}
                                                  Schedule View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_2.png" />{" "}
                                                  Gantt View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_3.png" />{" "}
                                                  Calendar View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_4.png" />{" "}
                                                  List View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  to="construction.html"
                                                  className="dropdown-item"
                                                >
                                                  <img src="images/construuction_5.png" />{" "}
                                                  Baseline
                                                </a>
                                              </li>
                                            </ul>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/warranty-dashboard"
                                            >
                                              <img src="images/warranty.png" />
                                              Warranty
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/models"
                                            >
                                              <img src="images/model.png" />{" "}
                                              Model
                                              <img src="images/rightarrow2.png" />
                                            </Link>
                                            {/* <ul className="dropdown-menu submenu">
          <li>
            <a
              to="javascript:void(0);"
              className="dropdown-item text-center"
              data-toggle="modal"
              data-target="#CreateNewModelHome"
            >
              <img src="images/model.png" /> Create New Model
            </a>
          </li>
        </ul> */}
                                          </li>
                                          <li>
                                            <a
                                              className="dropdown-item"
                                              to="reports.html"
                                            >
                                              <img src="images/reports.png" />
                                              Reports
                                            </a>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              to="/client-view-config"
                                            >
                                              <img src="images/view_config.png" />
                                              Views Configs
                                            </Link>
                                          </li>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan={10} className="padding_none">
                                  <div
                                    className="JobBoxTable"
                                    style={{
                                      display:
                                        resdentalId == i ? "block" : "none",
                                    }}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Unit Job Number</th>
                                          <th>Unit#</th>
                                          <th>Primary Client</th>
                                          <th>Square Footage</th>
                                          <th>Warranty Requests</th>
                                          <th>Description</th>
                                          <th />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {resdentalId == i &&
                                          listProjectJobs?.jobs?.length > 0 &&
                                          listProjectJobs?.jobs?.map(
                                            (data, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data.id}
                                                      <span className="Dots">
                                                        <img src="images/dots.png" />
                                                      </span>
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data.jobNumber}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data.unit ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data.client ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data.footage ?? "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data?.warranties ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to="/transactions-manager"
                                                      state={data}
                                                    >
                                                      {data?.description ??
                                                        "N/A"}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {/* <a to="javascript:void(0);">
                                      <i
                                        className="fa fa-caret-down"
                                        aria-hidden="true"
                                      />
                                    </a> */}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </>
          )}
        {/* <div className='clear'></div> */}

        {/* multi family owl carousel  */}
    
      </div>
    </div>
  </div>
  
  
  )
}

export default Residential