import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import {
  getTaskCreate,
  getTaskListJob,
  getCreatedTaskList,
  getInProcessTaskList,
  getCompleteTaskList,
  getTaskMemberList,
  getTaskHistoryList,
  getTaskDelete,
  getTaskAssign,
  getManageAccessTaskList,
  updatedReminder,
  getTaskRequestAccess,
  getGoogleCalendar,
  getOutlookCalendar,
  getEmailCalendar,
  taskHide,
  geAllShowTask,
  taskUnHide,
  getTaskCancel,
  getShowCancelList,
  getCalendarTaskList,
  getAddInternity,
  getViewTask,
  getIncomingTaskAccessRequestList,
  getManageAccessToMyTaskListList,
  getManageAccessUserTaskList,
  updateTask,
  getTaskRequestAccessUser,
  getShowMoveList,
  getShowHiddenList,
  updateTargetDate,
} from "../redux/action/taskAction";
import { calculateDateDifference, formatDate } from "../utils/uploadFile";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const initialState = {
  description: "",
  WorkFlowId: "",
  JobId: "",
  JobType: "",
  reminderDate: "",
  assignStaffs: "",
  AddTaskModal: false,
  AssignStaffMemberModal: false,
  ReminderModal: false,
  ViewSharedTaskModal: false,
  TaskHistoryModal: false,
  taskId: "",
  TaskDeleteModal: false,
  UpdateAssignStaffMemberModal: false,
  UserId: "",
  UpdateReminderModal: false,
  week: "",
  day: "",
  GiveAccessModal: false,
  requesters: "",
  MoveTaskModal: false,
  AddItineraryModal: false,

  subject: "",
  descriptions: "",
  location: "",
  date: "",
  fromTime: "",
  toTime: "",
  type: "",
  reminderDates: "",
  jobType: "",
  jobId: "",
  relatedToTask: "",
  relatedToJob: "",
  TaskId: "",
  assignStaffss: "",
  AssignStaffMemberModalInt: false,
  ReminderModalInt: false,
  ViewTaskModal: false,
  SystemTasksSettingModal: false,
  IncomingTaskAccessRequestModal: false,
  ManageAccesstoMyTasksModal: false,
  ManageAccessUserTaskListModal: false,
  EditTaskModal: false,
  RequestAccessUserModal: false,
  jobSearch: "",
  types: "",
  TargetDateModal: false,
  targetDate: "",
};
const IndividualComponentsUserWorkflowTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iState, updateState] = useState(initialState);
  const {
    description,
    WorkFlowId,
    JobId,
    JobType,
    reminderDate,
    assignStaffs,
    AddTaskModal,
    AssignStaffMemberModal,
    ReminderModal,
    ViewSharedTaskModal,
    TaskHistoryModal,
    taskId,
    TaskDeleteModal,
    UpdateAssignStaffMemberModal,
    UserId,
    UpdateReminderModal,
    week,
    day,
    GiveAccessModal,
    requesters,
    MoveTaskModal,
    AddItineraryModal,

    subject,
    descriptions,
    location,
    date,
    fromTime,
    toTime,
    type,
    reminderDates,
    jobType,
    jobId,
    relatedToTask,
    relatedToJob,
    TaskId,
    assignStaffss,
    AssignStaffMemberModalInt,
    ReminderModalInt,
    ViewTaskModal,
    SystemTasksSettingModal,
    IncomingTaskAccessRequestModal,
    ManageAccesstoMyTasksModal,
    ManageAccessUserTaskListModal,
    EditTaskModal,
    RequestAccessUserModal,
    jobSearch,
    types,
    TargetDateModal,
    targetDate,
  } = iState;

  const {
    jobList,
    createdTaskList,
    inProcessTaskList,
    completeTaskList, 
    memberList,
    historyTaskList,
    showCancelList,
    viewTaskDetail,
    incomingAccessList,
    manageAccessToMyList,
    manageAccessUserTaskList,
    showHiddenList,
    showMoveList,
   
  } = useSelector((state) => state.taskReducer);
  console.log("historyTaskList", historyTaskList);

  useEffect(() => {
    dispatch(getCreatedTaskList({ showAll: false }));
    dispatch(getInProcessTaskList());
    dispatch(getCompleteTaskList());
    dispatch(getTaskMemberList());
  }, []);

  useEffect(() => {
    dispatch(getTaskListJob());
    dispatch(getShowMoveList());
    dispatch(getShowCancelList());
    dispatch(getShowHiddenList());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value,
    });
  };

  const handleJobItemClick = (item) => {
    updateState({ ...iState, JobId: item.id, JobType: item.jobType });
  };

  const handleAddTaskShow = () => {
    updateState({
      ...iState,
      AddTaskModal: true,
      week: "",
      day: "",
      reminderDate: "",
      assignStaffs: "",
      description: "",
    });
  };

  const handleAddTaskClose = () => {
    updateState({
      ...iState,
      AddTaskModal: false,
    });
  };

  const handleTaskAdd = () => {
    // let formIsValid = handleShareDocumentValidation();
    // if (formIsValid) {
    const type = week ? week : day;
    const dataa = {
      description,
      WorkFlowId: "1",
      JobId,
      JobType,
      reminderDate: calculateDateDifference(type),
      assignStaffs: [{ UserId: Number(assignStaffs) }],
    };
    dispatch(getTaskCreate(dataa))
      .then((res) => {
        if (res.status === 201) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Add Task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddTaskModal: false,
            week: "",
            day: "",
            reminderDate: "",
            assignStaffs: "",
            JobId: "",
            JobType: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            AddTaskModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          AddTaskModal: true,
        });
      });
  };
  // }

  const handleEditTaskShow = (item) => {
    // console.log("item", item);
    updateState({
      ...iState,
      EditTaskModal: true,
      JobType: item.JobType,
      JobId: item.JobId,
      description: item.description,
      taskId: item.id,
      // reminderDate: calculateDateDifference(type),
      // assignStaffs: [{ UserId: Number(assignStaffs) }],
    });
  };

  const handleEditTaskClose = () => {
    updateState({
      ...iState,
      EditTaskModal: false,
    });
  };

  const handleUpdateTask = () => {
    // let formIsValid = handleShareDocumentValidation();
    // if (formIsValid) {
    const type = week ? week : day;
    const dataa = {
      description,
      WorkFlowId: "1",
      JobId,
      JobType,
      reminderDate: calculateDateDifference(type),
      assignStaffs: [{ UserId: Number(assignStaffs) }],
      taskStatus: "Created",
    };
    dispatch(updateTask(taskId, dataa))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Update Task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EditTaskModal: false,
            week: "",
            day: "",
            reminderDate: "",
            assignStaffs: "",
            jobId: "",
            jobType: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            EditTaskModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          EditTaskModal: true,
        });
      });
  };

  const handleAssignStaffMemberShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: true,
      AddTaskModal: false,
    });
  };

  const handleAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: false,
      AddTaskModal: true,
    });
  };

  const handleAssign = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: false,
      AddTaskModal: true,
    });
  };

  const handleReminderShow = () => {
    updateState({
      ...iState,
      ReminderModal: true,
      AddTaskModal: false,
    });
  };

  const handleReminderClose = () => {
    updateState({
      ...iState,
      ReminderModal: false,
      AddTaskModal: true,
    });
  };

  const handleReminder = () => {
    updateState({
      ...iState,
      ReminderModal: false,
      day: day,
      week: week,
      AddTaskModal: true,
    });
  };

  const handleViewSharedTaskModalShow = () => {
    updateState({
      ...iState,
      ViewSharedTaskModal: true,
    });
    dispatch(getManageAccessTaskList({ page: 1 }));
  };

  const handleViewSharedTaskModalClose = () => {
    updateState({
      ...iState,
      ViewSharedTaskModal: false,
    });
  };

  const handleTaskHistoryModalShow = (id) => {
    updateState({
      ...iState,
      TaskHistoryModal: true,
    });
    dispatch(getTaskHistoryList(id))

  };

  const handleTaskHistoryModalClose = () => {
    updateState({
      ...iState,
      TaskHistoryModal: false,
      id: "",
    });
  };

  // const handleTaskDeleteShow = (id) => {
  //   updateState({
  //     ...iState,
  //     TaskDeleteModal: true,
  //     taskId: id,
  //   });
  // };

  // const handleTaskDeleteClose = () => {
  //   updateState({
  //     ...iState,
  //     TaskDeleteModal: false,
  //   });
  // };

  const handleTaskCancel = (id) => {
    const datta = { taskId: id };
    dispatch(getTaskCancel(datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success(" Cancel Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            taskId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleUpdateAssignStaffMemberShow = (item) => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: true,
      taskId: item.id,
      UserId: item.UserId,
    });
  };

  const handleUpdateAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      UpdateAssignStaffMemberModal: false,
    });
  };

  const handleAssignStaffMember = () => {
    const datta = { assignStaffs: [{ UserId }] };
    dispatch(getTaskAssign(taskId, datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Assign Staff Member Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateAssignStaffMemberModal: false,
            taskId: "",
            UserId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            UpdateAssignStaffMemberModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateAssignStaffMemberModal: true,
        });
      });
  };

  const handleUpdateReminderShow = (id) => {
    updateState({
      ...iState,
      UpdateReminderModal: true,
      day: "",
      week: "",
      taskId: id,
    });
  };

  const handleUpdateReminderClose = () => {
    updateState({
      ...iState,
      UpdateReminderModal: false,
      day: "",
      week: "",
      id: "",
    });
  };

  const handleUpdateReminder = () => {
    const type = week ? week : day;
    const reminderDate = calculateDateDifference(type);

    const datta = { reminderDate };

    dispatch(updatedReminder(taskId, datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Assign Staff Member Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateReminderModal: false,
            taskId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            UpdateReminderModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UpdateReminderModal: true,
        });
      });
  };

  const handleGiveAccessModalShow = () => {
    updateState({
      ...iState,
      GiveAccessModal: true,
    });
  };

  const handleGiveAccessModalClose = () => {
    updateState({
      ...iState,
      GiveAccessModal: false,
    });
  };

  const handleTaskProvideAccess = () => {
    const datta = { requesters: [{ UserId: Number(requesters) }] };
    dispatch(getTaskRequestAccess(datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Task Provide Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            GiveAccessModal: false,
            requesters: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            GiveAccessModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          GiveAccessModal: true,
        });
      });
  };

  const handleRequestAccessUserModalShow = () => {
    updateState({
      ...iState,
      RequestAccessUserModal: true,
    });
  };

  const handleRequestAccessUserModalClose = () => {
    updateState({
      ...iState,
      RequestAccessUserModal: false,
    });
  };

  const handleTaskProvideAccessUser = () => {
    const datta = { requesters: [{ UserId: Number(requesters) }] };
    dispatch(getTaskRequestAccessUser(datta))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Task Provide Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            RequestAccessUserModal: false,
            requesters: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            RequestAccessUserModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          RequestAccessUserModal: true,
        });
      });
  };

  const handleGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getGoogleCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.google, "_blank");
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleOutLookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getOutlookCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.outlook, "_blank");
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleEmailData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getEmailCalendar(datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.yahoo, "_blank");
          dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleHideTask = (id) => {
    dispatch(taskHide(id))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: "" }));
          toast.success("Hide task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            id: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleUnHideTask = (id) => {
    dispatch(taskUnHide(id))
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: "" }));
          toast.success("Hide task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            id: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleAllHiddenTask = (type) => {
    if(type==="create"){
      dispatch(geAllShowTask())
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCreatedTaskList({ showAll: "" }));
          toast.success("Hide task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    }
    else if(type==="progress") {
      dispatch(geAllShowTask())
      .then((res) => {
        if (res.status === 200) {
          dispatch(getInProcessTaskList());
          toast.success("Hide task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    }
    else {
      dispatch(geAllShowTask())
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCompleteTaskList());
          toast.success("Hide task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
    }
   
     
  };

  const handleMoveTaskModalShow = (id) => {
    updateState({
      ...iState,
      MoveTaskModal: true,
      TaskId: id,
    });
  };

  const handleMoveTaskModalClose = () => {
    updateState({
      ...iState,
      MoveTaskModal: false,
    });
  };

  const handleAddItineraryModalShow = () => {
    updateState({
      ...iState,
      AddItineraryModal: true,
    });
  };

  const handleAddItineraryModalClose = () => {
    updateState({
      ...iState,
      AddItineraryModal: false,
    });
  };

  const handleJobTaskChecked = (e, type) => {
    const { checked } = e.target;
    if (type == "job") {
      updateState({ ...iState, relatedToJob: checked });
    } else {
      updateState({ ...iState, relatedToTask: checked });
    }
  };

  const handleAssignStaffMemberIntShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: true,
    });
  };

  const handleAssignStaffMemberIntClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: false,
    });
  };

  const handleAssignIn = () => {
    updateState({ ...iState, AssignStaffMemberModalInt: false });
  };

  const removeAssignIntMember = () => {
    updateState({ ...iState, assignStaffss: "" });
  };

  const handleReminderIntShow = () => {
    updateState({
      ...iState,
      ReminderModalInt: true,
    });
  };

  const handleReminderIntClose = () => {
    updateState({
      ...iState,
      ReminderModalInt: false,
    });
  };

  const handleReminderInt = () => {
    updateState({ ...iState, ReminderModalInt: false, day: day, week: week });
  };

  const handleJobIternaryItemClick = (item) => {
    updateState({ ...iState, jobId: item.id, jobType: item.jobType });
  };

  const handleAddItinerary = () => {
    const type = week ? week : day;
    const reminderDates = calculateDateDifference(type);
    const datta = {
      subject,
      description: descriptions,
      location,
      date,
      fromTime: `${fromTime}:00`,
      toTime: `${toTime}:00`,
      type: types,
      jobId,
      jobType,
      reminderDate: reminderDates,
      relatedToTask: relatedToTask == true ? true : false,
      relatedToJob: relatedToJob == true ? true : false,
      TaskId,
      assignStaffs: [{ UserId: Number(assignStaffss) }],
    };
    dispatch(getAddInternity(datta))
      .then((res) => {
        if (res.status === 201) {
          toast.success("Add Internity Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            subject: "",
            descriptions: "",
            location: "",
            date: "",
            fromTime: "",
            toTime: "",
            type: "",
            reminderDates: "",
            jobType: "",
            jobId: "",
            relatedToTask: "",
            relatedToJob: "",
            TaskId: "",
            assignStaffss: "",
            AddItineraryModal: false,
            MoveTaskModal: false,
            day: "",
            week: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, AddItineraryModal: false });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AddItineraryModal: false });
      });
  };

  const handleViewTaskShow = (id) => {
    updateState({
      ...iState,
      ViewTaskModal: true,
    });
    dispatch(getViewTask(id))
      .then((res) => {
        if (res.status === 200) {
          toast.success(" Get Task Details Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleViewTaskClose = () => {
    updateState({ ...iState, ViewTaskModal: false });
  };

  const calendarRef = useRef(null);

  const [view, setView] = useState("dayGridMonth");
  const [events, setEvents] = useState([]);

  const handleViewChange = (newView) => {
    setView(newView);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = await fetchEventData(view);
      console.log("data", data);
      setEvents(data);
    };

    fetchData();
  }, [view]);



  const fetchEventData = async (currentView) => {
    // Replace this with your actual data fetching logic based on the current view
    // For example, you might fetch events for the current month, week, or day
    const currentDate = new Date();

    // Example: Fetch events for the current month
    if (currentView === "dayGridMonth") {
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      // Fetch events for the current month
      const monthEvents = await fetchDataForDateRange(startOfMonth, endOfMonth);

      return monthEvents;
    }

    // Example: Fetch events for the current week
    if (currentView === "dayGridWeek") {
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      );

      // Fetch events for the current week
      const weekEvents = await fetchDataForDateRange(startOfWeek, endOfWeek);

      return weekEvents;
    }

    // Example: Fetch events for the current day
    const dayEvents = await fetchDataForDate(currentDate);
    return dayEvents;
  };

  const fetchDataForDateRange = async (startDate, endDate) => {
    const datta = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getCalendarTaskList(datta)).then((res) => {
      console.log("date datatat", res);
      if (res.status === 200) {
        const eventsData = res?.data?.tasks?.map((item) => ({
          title: item.description,
          start: item.targetDate, // Assuming 'date' is the property containing the event date
        })) || [];

        // Set the events state
        setEvents(eventsData);
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // toast.error(res.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    });
    // .catch((error) => {
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // });
  };

  const fetchDataForDate = async (date) => {
    const datta = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(getCalendarTaskList(datta)).then((res) => {
      if (res.status === 200) {
        const eventsData = res?.data?.tasks?.map((item) => ({
          title: item.description,
          start: item.targetDate, // Assuming 'date' is the property containing the event date
        })) || [];

        // Set the events state
        setEvents(eventsData);
        toast.success("Find data Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        // toast.error(res.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    });
    // .catch((error) => {
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // });
  };


  const handleDateSet = (dateInfo) => {
    // Handle "today" button click by resetting the view to the current date
    if (dateInfo.startStr === dateInfo.endStr) {
      setView("dayGridMonth"); // You can set it to the default view you prefer
    }
  };

  //===========================================================================================//
  function renderEventContent(eventInfo) {

    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        <p>{eventInfo.event.extendedProps.description}</p>
      </>
    );
  }




  const handleSystemTasksSettingShow = () => {
    updateState({
      ...iState,
      SystemTasksSettingModal: true,
    });
  };

  const handleSystemTasksSettingClose = () => {
    updateState({
      ...iState,
      SystemTasksSettingModal: false,
    });
  };

  const handleIncomingTaskAccessRequestShow = () => {
    updateState({
      ...iState,
      IncomingTaskAccessRequestModal: true,
      SystemTasksSettingModal: false,
    });
    dispatch(getIncomingTaskAccessRequestList({ page: 1 }));
  };

  const handleIncomingTaskAccessRequestClose = () => {
    updateState({
      ...iState,
      IncomingTaskAccessRequestModal: false,
    });
  };

  const handleManageAccesstoMyTasksModalShow = () => {
    updateState({
      ...iState,
      ManageAccesstoMyTasksModal: true,
      SystemTasksSettingModal: false,
    });
    dispatch(getManageAccessToMyTaskListList({ page: 1 }));
  };

  const handleManageAccesstoMyTasksModalClose = () => {
    updateState({
      ...iState,
      ManageAccesstoMyTasksModal: false,
    });
  };

  const handleManageAccessUserTaskListModalShow = () => {
    updateState({
      ...iState,
      ManageAccessUserTaskListModal: true,
      SystemTasksSettingModal: false,
    });
    dispatch(getManageAccessUserTaskList({ page: 1 }));
  };

  const handleManageAccessUserTaskListModalClose = () => {
    updateState({
      ...iState,
      ManageAccessUserTaskListModal: false,
    });
  };


  const handleTargetDateModalShow = (id) => {
    updateState({ ...iState, TargetDateModal: true, taskId: id, targetDate: "" })
  }

  const handleTargetDateModalClose = () => {
    updateState({ ...iState, TargetDateModal: false, targetDate: "" })
  }

  const handleTargetDate = () => {
    const datta = { targetDate: formatDate(targetDate) };
    dispatch(updateTargetDate(taskId, datta)).then((res) => {
      if (res.status === 200) {
        dispatch(getCreatedTaskList({ showAll: false }));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });

        updateState({ ...iState, TargetDateModal: false, targetDate: "", taskId: "" })
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, TargetDateModal: true })
      }

    });

  };

 
  const onDragEnd = (result) => {
    const { destination, source } = result;
   
    // Early exit if no destination
    if (!destination) {
      return;
    }
  
    // Early exit if the item is dropped in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
  
    let newItems1 = createdTaskList?.tasks || [];
    let newItems2 = inProcessTaskList?.tasks || [];
    let newItems3 = completeTaskList?.tasks || [];
    let draggedItem;
  
    if (source.droppableId === 'droppable1' && destination.droppableId === 'droppable2') {
      if (source.index >= newItems1.length || destination.index > newItems2.length) {
        console.error('Index out of bounds');
        return;
      }
  
      draggedItem = newItems1[source.index];
      newItems1.splice(source.index, 1);
      newItems2.splice(destination.index, 0, draggedItem);
  
      dispatch(updateTask(newItems2?.[0]?.id, {
        taskStatus: "In-Progress",
        WorkFlowId: 1,
        JobId: newItems2?.[0]?.JobId,
        JobType: newItems2?.[0]?.JobType
      })).then((res) => {
        if (res.status === 200) {
          dispatch(getInProcessTaskList());
          dispatch(getCreatedTaskList());
        }
      });
    } else if (source.droppableId === 'droppable2' && destination.droppableId === 'droppable3') {
      if (source.index >= newItems2.length || destination.index > newItems3.length) {
        console.error('Index out of bounds');
        return;
      }
  
      draggedItem = newItems2[source.index];
      newItems2.splice(source.index, 1);
      newItems3.splice(destination.index, 0, draggedItem);
  
      dispatch(updateTask(newItems3?.[0]?.id, {
        taskStatus: "Completed",
        WorkFlowId: 1,
        JobId: newItems3?.[0]?.JobId,
        JobType: newItems3?.[0]?.JobType
      })).then((res) => {
        if (res.status === 200) {
          dispatch(getCompleteTaskList());
          dispatch(getInProcessTaskList());
        }
      });
    } else {
      return;
    }
  };
  
  const handlePrints = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    document.body.innerHTML = printContent;
    window.print();
    window.location.reload();
  };
  const handleDownload = () => {
    const printContent = document.querySelector('.AgreementViewArea').innerHTML;
    const blob = new Blob([printContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'agreement_view.html';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };


  return (
    <>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TodosArea">
            <div className="TodosHeader">
              <h6>User Workflow Tasks</h6>
              <h6
                className="ClosePermissionId"
                onClick={handleSystemTasksSettingShow}
              >
                <img src="images/setting.png" />
              </h6>
            </div>
            <div className="CommonTabs">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#Board"
                  >
                    <span>
                      <img src={require("../assets/images/board_red.png")} />
                    </span>
                    Board
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Table">
                    <span>
                      <img src="images/icon-22.png" />
                    </span>
                    Table
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Calendar">
                    <span>
                      <img src="images/icon-23.png" />
                    </span>
                    Calendar
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane active show" id="Board">
              <div className="Tableheader">
                <h6>System Tasks</h6>
                {/* <a href="javascript:void(0);" className="ThreeLineDots">
                  <img src="images/icon-64.png" />
                </a> */}
              </div>
              <div className="TodoBoxArea d-flex">

              <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable1">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>Created</h6>
                  <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("create")}
                  />
                </div>
                <img src="images/add.png" onClick={handleAddTaskShow} />
                <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure>
              </div>

              {createdTaskList?.tasks?.length > 0 &&
                createdTaskList?.tasks?.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className="TodoUser RedLeftBorder"
                          ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                      >
                        <div className="TodoUserJob mb-1">
                          <h6>{item.description}</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job:{item.JobId}
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleViewTaskShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  View Task
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTargetDateModalShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  Target Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTaskHistoryModalShow(item.id)
                                  }
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calendar &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleGoogleData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleOutLookData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleEmailData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calendar
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMoveTaskModalShow(item.id)
                                  }
                                  title="Move Task"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleUpdateReminderShow(item.id)
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleGiveAccessModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/share.png" />
                                  Give access to my task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleRequestAccessUserModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/requests-access.png" />
                                  Request access to user's task
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <small>
                            {moment(item.assignDate).format(
                              "YYYY-MM-DD"
                            )}
                          </small>
                          <small>Age : --- year</small>
                        </div>
                        <div className="TodoUserName">
                          <small>Assigned : {item.assignStatus}</small>
                          <div className="TodoUserIcons">
                            <a
                              onClick={() => handleEditTaskShow(item)}
                              title="Edit Task"
                            >
                              <img src="images/todo-edit.png" />
                            </a>
                            <a title="Hide Task">
                              {item.hideTask === false ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleHideTask(item.id)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleUnHideTask(item.id)
                                  }
                                ></i>
                              )}
                            </a>
                            <a
                              onClick={() =>
                                handleUpdateAssignStaffMemberShow(item)
                              }
                              title="Assign Staff Member"
                            >
                              <img src="images/todo-user.png" />
                            </a>
                            <a
                              onClick={() =>
                                handleTaskCancel(item.id)
                              }
                              title="Cancel Task"
                            >
                              <img src="images/todo-not.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    <Droppable droppableId="droppable2">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>In-Progress</h6>
                  <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("progress")}
                  />
                </div>
                {/* <img src="images/add.png" onClick={handleAddTaskShow} /> */}
                {/* <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure> */}
              </div>

              {inProcessTaskList?.tasks?.length > 0 &&
                inProcessTaskList?.tasks?.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className="TodoUser RedLeftBorder"
                          ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                      >
                        <div className="TodoUserJob mb-1">
                          <h6>{item.description}</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job:{item.JobId}
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleViewTaskShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  View Task
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTargetDateModalShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  Target Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTaskHistoryModalShow(item.id)
                                  }
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calendar &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleGoogleData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleOutLookData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleEmailData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calendar
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMoveTaskModalShow(item.id)
                                  }
                                  title="Move Task"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleUpdateReminderShow(item.id)
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleGiveAccessModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/share.png" />
                                  Give access to my task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleRequestAccessUserModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/requests-access.png" />
                                  Request access to user's task
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <small>
                            {moment(item.assignDate).format(
                              "YYYY-MM-DD"
                            )}
                          </small>
                          <small>Age : --- year</small>
                        </div>
                        <div className="TodoUserName">
                          <small>Assigned : {item.assignStatus}</small>
                          <div className="TodoUserIcons">
                            <a
                              onClick={() => handleEditTaskShow(item)}
                              title="Edit Task"
                            >
                              <img src="images/todo-edit.png" />
                            </a>
                            <a title="Hide Task">
                              {item.hideTask === false ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleHideTask(item.id)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleUnHideTask(item.id)
                                  }
                                ></i>
                              )}
                            </a>
                            <a
                              onClick={() =>
                                handleUpdateAssignStaffMemberShow(item)
                              }
                              title="Assign Staff Member"
                            >
                              <img src="images/todo-user.png" />
                            </a>
                            <a
                              onClick={() =>
                                handleTaskCancel(item.id)
                              }
                              title="Cancel Task"
                            >
                              <img src="images/todo-not.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  
    <Droppable droppableId="droppable3">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="col-4">
          <div className="">
            <div className="TodoBox TodoBoxHeight">
              <div className="TodoBoxHeader">
                <div className="d-flex align-items-center">
                  <h6>Completed</h6>
                  <img
                    src="images/eye-icon.png"
                    onClick={()=>handleAllHiddenTask("complete")}
                  />
                </div>
                {/* <img src="images/add.png" onClick={handleAddTaskShow} />
                <figure className="mb-0">
                  <img src="images/task-square.png" />
                </figure> */}
              </div>

              {completeTaskList?.tasks?.length > 0 &&
                completeTaskList?.tasks?.map((item, i) => (
                  <Draggable key={item.id} draggableId={item?.id?.toString()} index={i}>
                    {(provided, snapshot) => (
                      <div
                        className="TodoUser RedLeftBorder"
                          ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                      >
                        <div className="TodoUserJob mb-1">
                          <h6>{item.description}</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job:{item.JobId}
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleViewTaskShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  View Task
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTargetDateModalShow(item.id)
                                  }
                                >
                                  <img src="images/view-po.png" />
                                  Target Date
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleTaskHistoryModalShow(item.id)
                                  }
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item">
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calendar &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleGoogleData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleOutLookData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      onClick={() =>
                                        handleEmailData(item)
                                      }
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calendar
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMoveTaskModalShow(item.id)
                                  }
                                  title="Move Task"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to To-Do
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleUpdateReminderShow(item.id)
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleGiveAccessModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/share.png" />
                                  Give access to my task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleRequestAccessUserModalShow()
                                  }
                                  title="Set Reminder"
                                >
                                  <img src="images/requests-access.png" />
                                  Request access to user's task
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <small>
                            {moment(item.assignDate).format(
                              "YYYY-MM-DD"
                            )}
                          </small>
                          <small>Age : --- year</small>
                        </div>
                        <div className="TodoUserName">
                          <small>Assigned : {item.assignStatus}</small>
                          <div className="TodoUserIcons">
                            <a
                              onClick={() => handleEditTaskShow(item)}
                              title="Edit Task"
                            >
                              <img src="images/todo-edit.png" />
                            </a>
                            <a title="Hide Task">
                              {item.hideTask === false ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleHideTask(item.id)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                  onClick={() =>
                                    handleUnHideTask(item.id)
                                  }
                                ></i>
                              )}
                            </a>
                            <a
                              onClick={() =>
                                handleUpdateAssignStaffMemberShow(item)
                              }
                              title="Assign Staff Member"
                            >
                              <img src="images/todo-user.png" />
                            </a>
                            <a
                              onClick={() =>
                                handleTaskCancel(item.id)
                              }
                              title="Cancel Task"
                            >
                              <img src="images/todo-not.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
              </div>
            </div>
            <div className="tab-pane fade" id="Table">
              <div className="Tableheader flex-lg-row flex-column align-items-lg-center align-items-start">
                <h6>System Tasks</h6>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="TodosButtons mt-0">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#WarrantyRequest"
                          id="WarrantyRequestTab"
                        >
                          <img src="images/red-close-eye.png" />
                          Show Hidden
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#WorkOrders"
                          id="WorkOrdersTab"
                        >
                          <img src="images/cancel.png" />
                          Show Cancelled
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#ShowMoved"
                        >
                          <img src="images/arrow-four.png" />
                          Show Moved
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="NewActions">
                    {/* <a href="javascript:void(0);">
                      <img src="images/icon-64.png" />
                    </a> */}
                    <a onClick={handlePrints} title="print">
                    <img src="images/icon-53.png" />
                  </a>
                  <a onClick={handleDownload} title="download">
                    <img src="images/download.png" />
                  </a>
                    {/* <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a> */}
                  </div>
                </div>
              </div>
              
              <div className="tab-content">
                <div className="tab-pane active show" id="WarrantyRequest">
                  <div className="TableList NewTableList BlueHeader TableHeight500">
                    <table>
                      <thead>
                        <tr>
                          <th>Job Id</th>
                          <th>Task #</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Workflow</th>
                          <th>Date Cereated</th>
                          <th>Date Assign</th>
                          <th>Target Date</th>
                          <th>Date Completed</th>
                          <th>Age (Days)</th>
                          <th>Assign member</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showHiddenList?.tasks?.length > 0 ? (
                          showHiddenList?.tasks?.map((item, i) => {
                            return (
                              <tr className="BorderCyan">
                                <td>{item.JobId}</td>
                                <td>{item.id}</td>
                                <td>{item.description}</td>
                                <td>{item.assignStatus}</td>
                                <td>{item.WorkFlowId}</td>
                                <td>
                                  {moment(item.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {moment(item.assignDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  {moment(item.targetDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>{item.completeDate ?? "N/A"}</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu ChangeOrderDropdown"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleUpdateAssignStaffMemberShow(item)}
                                          >
                                            <img src="images/assign-resources.png" />
                                            Assign Resources
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleViewTaskShow(item.id)
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View Task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleTaskHistoryModalShow(item.id)}
                                          >
                                            <img src="images/task-history.png" />
                                            Task History
                                          </a>
                                        </li>
                                        <li>
                          <a className="dropdown-item">
                            <img src="images/export-to-calendar.png" />
                            Export to Calender &nbsp;
                            <img src="images/rightarrow2.png" />
                          </a>
                          <ul className="dropdown-menu submenu">
                            <li>
                              <a
                                onClick={() =>
                                  handleGoogleData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/google-calendar.png" />
                                Google Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleOutLookData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/outlook-calendar.png" />
                                Outlook Calender
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleEmailData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/email-calendar.png" />
                                Email Calender
                              </a>
                            </li>
                          </ul>
                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#MoveTask"
                                          >
                                            <img src="images/move-to-task.png" />
                                            View To-Do
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleUpdateReminderShow(item.id)
                                            }
                                            title="Set Reminder"
                                          >
                                            <img src="images/set-reminder.png" />
                                            Set Reminder
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleGiveAccessModalShow()
                                            }
                                            title="Give Access"
                                          >
                                            <img src="images/share.png" />
                                            Give access to my task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleRequestAccessUserModalShow()}
                                          >
                                            <img src="images/requests-access.png" />
                                            Request access to user's task
                                          </a>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane fade" id="WorkOrders">
                  <div className="TableList NewTableList BlueHeader TableHeight500">
                    <table>
                      <thead>
                        <tr>
                          <th>Job Id</th>
                          <th>Task #</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Workflow</th>
                          <th>Date Cereated</th>
                          <th>Date Assign</th>
                          <th>Target Date</th>
                          <th>Date Completed</th>
                          <th>Age (Days)</th>
                          <th>Assign member</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showCancelList?.tasks?.length > 0 ? (
                          showCancelList?.tasks?.map((item, i) => {
                            return (
                              <tr className="BorderCyan">
                                <td>{item.JobId}</td>
                                <td>{item.id}</td>
                                <td>{item.description}</td>
                                <td>{item.assignStatus}</td>
                                <td>{item.WorkFlowId}</td>
                                <td>
                                  {moment(item.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {moment(item.assignDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  {moment(item.targetDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>{item.completeDate ?? "N/A"}</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu ChangeOrderDropdown"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleUpdateAssignStaffMemberShow(item)}
                                          >
                                            <img src="images/assign-resources.png" />
                                            Assign Resources
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleViewTaskShow(item.id)
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View Task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleTaskHistoryModalShow(item.id)}
                                          >
                                            <img src="images/task-history.png" />
                                            Task History
                                          </a>
                                        </li>
                                        <li>
                          <a className="dropdown-item">
                            <img src="images/export-to-calendar.png" />
                            Export to Calender &nbsp;
                            <img src="images/rightarrow2.png" />
                          </a>
                          <ul className="dropdown-menu submenu">
                            <li>
                              <a
                                onClick={() =>
                                  handleGoogleData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/google-calendar.png" />
                                Google Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleOutLookData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/outlook-calendar.png" />
                                Outlook Calender
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleEmailData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/email-calendar.png" />
                                Email Calender
                              </a>
                            </li>
                          </ul>
                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#MoveTask"
                                          >
                                            <img src="images/move-to-task.png" />
                                            View To-Do
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleUpdateReminderShow(item.id)
                                            }
                                            title="Set Reminder"
                                          >
                                            <img src="images/set-reminder.png" />
                                            Set Reminder
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleGiveAccessModalShow()
                                            }
                                            title="Give Access"
                                          >
                                            <img src="images/share.png" />
                                            Give access to my task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleRequestAccessUserModalShow()}
                                          >
                                            <img src="images/requests-access.png" />
                                            Request access to user's task
                                          </a>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane fade" id="ShowMoved">
                  <div className="TableList NewTableList BlueHeader TableHeight500">
                    <table>
                      <thead>
                        <tr>
                          <th>Job Id</th>
                          <th>Task #</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Workflow</th>
                          <th>Date Cereated</th>
                          <th>Date Assign</th>
                          <th>Target Date</th>
                          <th>Date Completed</th>
                          <th>Age (Days)</th>
                          <th>Assign member</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showMoveList?.tasks?.length > 0 ? (
                          showMoveList?.tasks?.map((item, i) => {
                            return (
                              <tr className="BorderCyan">
                                <td>{item.JobId}</td>
                                <td>{item.id}</td>
                                <td>{item.description}</td>
                                <td>{item.assignStatus}</td>
                                <td>{item.WorkFlowId}</td>
                                <td>
                                  {moment(item.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td>
                                  {moment(item.assignDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>
                                  {moment(item.targetDate ?? "N/A").format(
                                    "YYYY-MM-DD"
                                  )}
                                </td>
                                <td>{item.completeDate ?? "N/A"}</td>
                                <td>N/A</td>
                                <td>N/A</td>
                                <td>
                                  <div className="TableThreeDots">
                                    <div className="dropdown">
                                      <p data-toggle="dropdown">
                                        <span>
                                          <img src="images/three-dots.png" />
                                        </span>
                                      </p>
                                      <div
                                        className="dropdown-menu ChangeOrderDropdown"
                                        aria-labelledby="dropdownMenuOffset"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleUpdateAssignStaffMemberShow(item)}
                                          >
                                            <img src="images/assign-resources.png" />
                                            Assign Resources
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleViewTaskShow(item.id)
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                            View Task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleTaskHistoryModalShow(item.id)}
                                          >
                                            <img src="images/task-history.png" />
                                            Task History
                                          </a>
                                        </li>
                                        <li>
                          <a className="dropdown-item">
                            <img src="images/export-to-calendar.png" />
                            Export to Calender &nbsp;
                            <img src="images/rightarrow2.png" />
                          </a>
                          <ul className="dropdown-menu submenu">
                            <li>
                              <a
                                onClick={() =>
                                  handleGoogleData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/google-calendar.png" />
                                Google Calendar
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleOutLookData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/outlook-calendar.png" />
                                Outlook Calender
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={() =>
                                  handleEmailData(item)
                                }
                                className="dropdown-item"
                              >
                                <img src="images/email-calendar.png" />
                                Email Calender
                              </a>
                            </li>
                          </ul>
                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#MoveTask"
                                          >
                                            <img src="images/move-to-task.png" />
                                            View To-Do
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleUpdateReminderShow(item.id)
                                            }
                                            title="Set Reminder"
                                          >
                                            <img src="images/set-reminder.png" />
                                            Set Reminder
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleGiveAccessModalShow()
                                            }
                                            title="Give Access"
                                          >
                                            <img src="images/share.png" />
                                            Give access to my task
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={()=>handleRequestAccessUserModalShow()}
                                          >
                                            <img src="images/requests-access.png" />
                                            Request access to user's task
                                          </a>
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="10" className="text-center">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Calendar">
              <FullCalendar
                ref={calendarRef}
                // weekends={true}
                events={events}
                eventContent={renderEventContent}
                headerToolbar={{
                  start: "today prev next",
                  center: "title",
                  end: "dayGridMonth dayGridWeek dayGridDay",
                }}
                plugins={[dayGridPlugin]}
                views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                initialView="dayGridMonth"
                defaultView="dayGridMonth"
                datesSet={(info) => {
                  handleViewChange(info.view.type);
                  handleDateSet(info);
                }}
              />
              {/* <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            headerToolbar={{
              right: 'today,week,month',
              center: 'title',
              left: 'dayGridMonth,dayGridWeek'
            }}
            datesSet={(info) => {
              handleViewChange(info.view.type);
              handleDateSet(info);
            }}
          /> */}

            </div>
          </div>
        </div>
      </div>

      <Modal
        show={AddTaskModal}
        onHide={handleAddTaskClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleAddTaskClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add Task</h4>
                </div>
                <div className="FolderForm">
                  {/* <h3>Task #112</h3> */}
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert task Description"
                      name="description"
                      value={description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Workflow</h6>
                      <div className="Categories_all Categories_all2">
                        <span>Select Workflow</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          - List of Communication Workflows defined for the
                          company
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories3 mb-4">
                      <h6>Job</h6>
                      <div className="Categories_all Categories_all3">
                        <span>CHI-2B-345</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            name="jobSearch"
                            value={jobSearch}
                            onChange={handleInputChange}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {jobList?.jobs?.length > 0 &&
                          jobList?.jobs
                            ?.filter((data) =>
                              jobSearch.toLowerCase() === ""
                                ? true
                                : data.jobType
                                  .toLowerCase()
                                  .includes(jobSearch.toLowerCase())
                            )
                            .map((item, i) => {
                              return (
                                <li
                                  // value={item.id}
                                  key={i}
                                  onClick={() => handleJobItemClick(item)}
                                  style={{
                                    backgroundColor:
                                      JobId === item.id
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.jobType}
                                </li>
                              );
                            })}
                      </ul>
                    </div>
                  </div>
                  <div className="CommonModalAreaMain">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={handleAssignStaffMemberShow}
                          >
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleReminderShow}
                          >
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Outline" onClick={handleAddTaskClose}>
                      Cancel
                    </button>
                    <button className="Create" onClick={handleTaskAdd}>
                      Create Task
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={EditTaskModal}
        onHide={handleEditTaskClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleEditTaskClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit Task</h4>
                </div>
                <div className="FolderForm">
                  <h3>Task #112</h3>
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insert task Description"
                      name="description"
                      value={description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>Workflow</h6>
                      <div className="Categories_all Categories_all2">
                        <span>Select Workflow</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          - List of Communication Workflows defined for the
                          company
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="Categories Categories3 mb-4">
                      <h6>Job</h6>
                      <div className="Categories_all Categories_all3">
                        <span>CHI-2B-345</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>
                          <input
                            type="text"
                            placeholder="Search"
                            name="jobSearch"
                            value={jobSearch}
                            onChange={handleInputChange}
                          />
                          <span className="Search">
                            <img src="images/search.png" />
                          </span>
                        </li>
                        {jobList?.jobs?.length > 0 &&
                          jobList?.jobs
                            ?.filter((data) =>
                              jobSearch.toLowerCase() === ""
                                ? true
                                : data.jobType
                                  .toLowerCase()
                                  .includes(jobSearch.toLowerCase())
                            )
                            .map((item, i) => {
                              return (
                                <li
                                  // value={item.id}
                                  key={i}
                                  onClick={() => handleJobItemClick(item)}
                                  style={{
                                    backgroundColor:
                                      JobId === item.id
                                        ? "#e71e09"
                                        : "transparent",
                                  }}
                                >
                                  {item.jobType}
                                </li>
                              );
                            })}
                      </ul>
                    </div>
                  </div>
                  <div className="CommonModalAreaMain">
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Assign Staff Member</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={handleAssignStaffMemberShow}
                          >
                            <figure>
                              <img src="images/assign-resources.png" />
                            </figure>{" "}
                            <span className="Count">3</span> Assign
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="CommonModalArea">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleReminderShow}
                          >
                            <figure>
                              <img src="images/timer.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Outline" onClick={handleEditTaskClose}>
                      Cancel
                    </button>
                    <button className="Create" onClick={handleUpdateTask}>
                      Update Task
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewTaskModal}
        onHide={handleViewTaskClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleViewTaskClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>View To-Do</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>To-Do {viewTaskDetail?.task?.id}</h2>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Job</h6>
                    <p className="ml-0">{viewTaskDetail?.task?.JobId}</p>
                  </div>
                  {/* <div className="form-group">
                    <h6>
                      Schedule related To-Do?<span className="required">*</span>
                    </h6>
                    <p className="ml-0">Yes</p>
                  </div>
                  <div className="form-group">
                    <h6>Schedule's Task</h6>
                    <p className="TextBlue2 ml-0">1.3.1.3</p>
                  </div> */}
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <h6 className="fw-400">
                      {viewTaskDetail?.task?.description}
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>Date Created</h6>
                        <p className="ml-0">
                          {moment(viewTaskDetail?.task?.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        <h6>Target Date</h6>
                        {viewTaskDetail?.task?.targetDate !== null && (
                          <p className="ml-0">
                            {moment(viewTaskDetail?.task?.targetDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>Date Assigned</h6>
                        {viewTaskDetail?.task?.assignDate !== null && (
                          <p className="ml-0">
                            {moment(viewTaskDetail?.task?.assignDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <h6>Date Completed</h6>
                        {viewTaskDetail?.task?.completeDate !== null && (
                          <p className="ml-0">
                            {moment(viewTaskDetail?.task?.completeDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Outline" onClick={handleViewTaskClose}>
                      Close
                    </button>
                  </div>
                  <div className="DiscussionArea">
                    <div className="DiscussionTitle">
                      <h4>Discussion</h4>
                      <div className="DiscussionChat">
                        <img src="images/down.png" className="DiscussionIcon" />
                        <a href="#">
                          <img src="images/pdf2.png" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="DiscussionHideArea"
                      style={{ display: "block" }}
                    >
                      <div className="DiscussionBox">
                        <span>FM</span>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add a Comment ....."
                          />
                          <div className="DiscussionOverlay">
                            <a href="" className="CancelChat">
                              <img src="images/plus-cancel1.png" />
                            </a>
                            <a href="" className="SendChat">
                              <img src="images/forwardbutton.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="DiscussionBox">
                        <span>TH</span>
                        <div className="DiscussionRight">
                          <h6>Inspections are delayed</h6>
                          <small>04/19/2023, at 02:47 pm</small>
                        </div>
                      </div>
                      <div className="ReplyArea">
                        <a href="javascript:void(0);">Reply</a>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AssignStaffMemberModal}
        onHide={handleAssignStaffMemberClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleAssignStaffMemberClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3>Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control py-2"
                        name="assignStaffs"
                        value={assignStaffs}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>View Shared Task List</h6>
                    <div className="PermissionsName">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={handleViewSharedTaskModalShow}
                      >
                        <span>
                          <img src="images/share.png" />
                        </span>
                        <div className="Count">3</div>View Shared Task List
                      </h5>
                    </div>
                  </div>
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssign}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AssignStaffMemberModalInt}
        onHide={handleAssignStaffMemberIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleAssignStaffMemberIntClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3>Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control py-2"
                        name="assignStaffss"
                        value={assignStaffss}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>View Shared Task List</h6>
                    <div className="PermissionsName">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={handleViewSharedTaskModalShow}
                      >
                        <span>
                          <img src="images/share.png" />
                        </span>
                        <div className="Count">3</div>View Shared Task List
                      </h5>
                    </div>
                  </div>
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssignIn}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UpdateAssignStaffMemberModal}
        onHide={handleUpdateAssignStaffMemberClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleUpdateAssignStaffMemberClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Update Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3> Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control py-2"
                        name="assignStaffs"
                        value={assignStaffs[0]?.UserId || UserId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>View Shared Task List</h6>
                    <div className="PermissionsName">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={handleViewSharedTaskModalShow}
                      >
                        <span>
                          <img src="images/share.png" />
                        </span>
                        <div className="Count">3</div>View Shared Task List
                      </h5>
                    </div>
                  </div>
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssignStaffMember}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ReminderModal}
        onHide={handleReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleReminderClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminder}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ReminderModalInt}
        onHide={handleReminderIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleReminderIntClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderInt}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={UpdateReminderModal}
        onHide={handleUpdateReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUpdateReminderClose}>
                ×
              </a>

              <div className="FolderForm">
                {/* <div className="form-group">
                  <h6>Weeks</h6> */}
                {/* <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <span></span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>- Less than 1 week</li>
                      <li>- 01</li>
                      <li>- 02</li>
                    </ul>
                  </div> */}

                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                  {/* </div> */}
                </div>
                {/* <div className="form-group">
                  <h6>Date</h6>
                  <div className="Categories Categories3">
                    <div className="Categories_all Categories_all3">
                      <span>Select aumont of days for reminder</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>- 01</li>
                      <li>- 02</li>
                      <li>- 03</li>
                    </ul>
                  </div>
                </div> */}
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    {/* <span>
                      <img src="images/down.png" />
                    </span> */}
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleUpdateReminder}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ViewSharedTaskModal}
        onHide={handleViewSharedTaskModalClose}
        className="ModalBox fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleViewSharedTaskModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Manage Access to Users Task List</h4>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>User 1</td>
                        <td>Access Granted</td>
                        <td>08/17/2023 - 10:14 PM</td>
                        <td>
                          <div className="Action">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> <span>01</span>{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={TaskHistoryModal}
        onHide={handleTaskHistoryModalClose}
        className="ModalBox fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleTaskHistoryModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Task History</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>Task #112</h2>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>Action Taken</th>
                        <th>Date</th>
                        <th>Performed By</th>
                        <th>Assigned User</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        historyTaskList?.taskHistories?.length>0?
                        historyTaskList?.taskHistories?.map((item,i)=>{
                          return(
                       <tr key={i}>
                        <td>{item.activity}</td>
                        <td>{moment(item.createdAt).format("YYYY-MM-DD & hh:mm A")}</td>
                        <td>{item.modifiedBy??"N/A"}</td>
                        <td>{item.entityId}</td>
                      </tr>
                          )
                        }):(
                          <tr>
                            <td colSpan="10">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      }
                      
                    
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={TaskDeleteModal}
        onHide={handleTaskDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleTaskDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleFolderDelete}>
                      Delete
                    </button>
                    <button className="Outline" onClick={handleTaskDeleteClose}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        show={GiveAccessModal}
        onHide={handleGiveAccessModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleGiveAccessModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Give Access to My Tasks</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Users</h6>
                    <div className="Categories Categories1">
                      {/* <div className="Categories_all Categories_all1">
                            <span>Select Users</span>
                            <img src="images/down.png" />
                          </div> */}
                      <select
                        className="form-control"
                        name="requesters"
                        value={requesters}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="Button my-4">
                        <button className="Create">Add</button>
                      </div>
                      <div className="form-group">
                        <h6>Select Users</h6>
                        <div className="FilesAdded">
                          <label>File_name.docs</label>
                          <span>
                            <img src="images/fileview.png" />
                          </span>
                          <span className="cross">
                            <img src="images/Cross.png" />
                          </span>
                        </div>
                      </div> */}
                  <div className="Button d-flex justify-content-between mt-5">
                    <button
                      className="Create"
                      onClick={handleTaskProvideAccess}
                    >
                      Give Access
                    </button>
                    <button
                      className="Outline"
                      onClick={handleGiveAccessModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={RequestAccessUserModal}
        onHide={handleRequestAccessUserModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleRequestAccessUserModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Request Access to Users Task Lists</h4>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Users</h6>
                    <div className="Categories Categories1">
                      {/* <div className="Categories_all Categories_all1">
                            <span>Select Users</span>
                            <img src="images/down.png" />
                          </div> */}
                      <select
                        className="form-control"
                        name="requesters"
                        value={requesters}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="Button my-4">
                        <button className="Create">Add</button>
                      </div>
                      <div className="form-group">
                        <h6>Select Users</h6>
                        <div className="FilesAdded">
                          <label>File_name.docs</label>
                          <span>
                            <img src="images/fileview.png" />
                          </span>
                          <span className="cross">
                            <img src="images/Cross.png" />
                          </span>
                        </div>
                      </div> */}
                  <div className="Button d-flex justify-content-between mt-5">
                    <button
                      className="Create"
                      onClick={handleTaskProvideAccessUser}
                    >
                      Request Access
                    </button>
                    <button
                      className="Outline"
                      onClick={handleRequestAccessUserModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={MoveTaskModal}
        onHide={handleMoveTaskModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleMoveTaskModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Move Task</h4>
                </div>
                <div className="FolderForm">
                  <h3>Task #112</h3>
                  <h6 className="mb-4">Job: CHI-2B-345</h6>
                  <form>
                    <div className="form-group">
                      <h6>Create New To-Do Task</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleAddTaskShow}
                        >
                          <span>
                            <img src="images/icon-35.png" />
                          </span>{" "}
                          Create New To-Do Task
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Create New Itineraty item</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleAddItineraryModalShow}
                        >
                          <span>
                            <img src="images/double-check.png" />
                          </span>{" "}
                          Create New Itineraty item
                        </h5>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddItineraryModal}
        onHide={handleAddItineraryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleAddItineraryModalClose}
              >
                ×
              </a>

              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Itinerary Item</h4>
                </div>
                <div className="FolderForm">
                  <h6>Item #12</h6>

                  <div className="form-group">
                    <h6>Subject</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Executive Meeting"
                      name="subject"
                      value={subject}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Description</h6>
                    <textarea
                      rows="5"
                      className="form-control"
                      name="descriptions"
                      value={descriptions}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Room 1"
                      name="location"
                      value={location}
                      onChange={handleInputChange}
                    />
                  </div>
                  <h6>Select New Date</h6>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Date Option 1</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={date}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>From</label>
                      <input
                        type="time"
                        className="form-control"
                        name="fromTime"
                        value={fromTime}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>To</label>
                      <input
                        type="time"
                        className="form-control"
                        name="toTime"
                        value={toTime}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Type</label>
                      <select
                        className="form-control"
                        name="types"
                        value={types}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Phone Call">Phone Call</option>
                        <option value="Text">Text</option>
                        <option value="Task">Task</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="CommonModalArea ml-auto col-4">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleReminderIntShow}
                          >
                            <figure>
                              <img src="images/timer-red.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>Itinerary is related to Job or Schedule's Task?</h6>
                  <div className="row">
                    <div className="form-group col-6">
                      <div className="d-flex justify-content-between">
                        <label className="CheckBox">
                          Job
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "job")}
                            checked={relatedToJob}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          Schedule
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "sch")}
                            checked={relatedToTask}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Categories Categories1 mb-3">
                    <div className="Categories_all Categories_all1">
                      <span>Select a folder to insert the file</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <div className="Categories Categories2">
                    <h6>Job</h6>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Search"
                          name="jobSearch"
                          value={jobSearch}
                          onChange={handleInputChange}
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      {jobList?.jobs?.length > 0 &&
                        jobList?.jobs
                          ?.filter((data) =>
                            jobSearch.toLowerCase() === ""
                              ? true
                              : data.jobType
                                .toLowerCase()
                                .includes(jobSearch.toLowerCase())
                          )
                          .map((item, i) => {
                            return (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleJobIternaryItemClick(item)}
                                style={{
                                  backgroundColor:
                                    jobId === item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.jobType}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                  <div className="StaffMemberArea">
                    <div className="row">
                      <div className="col-4">
                        <h6>Assign Staff Member</h6>
                        <div className="StaffName">
                          <img src="images/member.png" />
                          <div className="Count">3</div>
                          <h6 onClick={handleAssignStaffMemberIntShow}>
                            Assign
                          </h6>
                        </div>
                      </div>
                      <div className="col-4 ml-auto">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>{assignStaffss}</p>
                            <img
                              src="images/Cross.png"
                              onClick={removeAssignIntMember}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      onClick={handleAddItinerary}
                    >
                      Create new Itinerary Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={SystemTasksSettingModal}
        onHide={handleSystemTasksSettingClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleSystemTasksSettingClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>System Tasks Settings</h4>
                </div>
                <div className="FolderForm">
                  <div className="ItineraryArea">
                    <h3>My Task List</h3>
                    <div className="form-group">
                      <h6>Give Access to My Tasks</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleGiveAccessModalShow}
                        >
                          <span>
                            <img src="images/share.png" />
                          </span>
                          Give Access
                        </h5>
                      </div>
                    </div>
                    <div className="ItineraryBox">
                      <div className="form-group">
                        <h6>Incoming Requests</h6>
                        <div className="PermissionsName">
                          <h5
                            className="CursorPointer FolderPermissionId"
                            onClick={handleIncomingTaskAccessRequestShow}
                          >
                            <span>
                              <img src="images/requests.png" />
                            </span>
                            <div className="Count">3</div>
                            Requests
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>Manage Access to My Tasks</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleManageAccesstoMyTasksModalShow}
                          >
                            <span>
                              <img src="images/access-control.png" />
                            </span>
                            Manage Access
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ItineraryArea">
                    <h3>Shared Task Lists</h3>
                    <div className="form-group">
                      <h6>Requests Access to Other Users Task Lists</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          data-toggle="modal"
                          data-target="#RequestAccessToUsersTasksLists"
                          data-dismiss="modal"
                        >
                          <span>
                            <img src="images/requests-access.png" />
                          </span>
                          Requests Access
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Manage Access to Users's Task Lists</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleManageAccessUserTaskListModalShow}
                        >
                          <span>
                            <img src="images/access-control.png" />
                          </span>
                          Manage Access
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="ItineraryArea">
                    <div className="form-group mb-0">
                      <label className="switch">
                        Show Cancelled Tasks
                        <input type="checkbox" />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={IncomingTaskAccessRequestModal}
        onHide={handleIncomingTaskAccessRequestClose}
        className="ModalBox  fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleIncomingTaskAccessRequestClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Incoming Tasks Access Requests</h4>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {
                        incomingAccessList?.
                      } */}
                      <tr>
                        <td>User 1</td>
                        <td>08/17/2023 - 10:14 PM</td>
                        <td>
                          <div className="Action">
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> <span>01</span>{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageAccesstoMyTasksModal}
        onHide={handleManageAccesstoMyTasksModalClose}
        className="ModalBox  fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleManageAccesstoMyTasksModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Manage Access to My Tasks</h4>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Grant Access Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>User 1</td>
                        <td>08/17/2023 - 10:14 PM</td>
                        <td>
                          <div className="Action">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> <span>01</span>{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ManageAccessUserTaskListModal}
        onHide={handleManageAccessUserTaskListModalClose}
        className="ModalBox  fade MediumModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleManageAccessUserTaskListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Manage Access to Users Task List</h4>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>User 1</td>
                        <td>Access Granted</td>
                        <td>08/17/2023 - 10:14 PM</td>
                        <td>
                          <div className="Action">
                            <i
                              className="fa fa-times-circle"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="PaginationArea">
                  <h6>Showing 1 to 10 of 27 entries</h6>
                  <h6>
                    <img src="images/leftarrow.png" /> <span>01</span>{" "}
                    <img src="images/rightarrow.png" /> Out 10
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={TargetDateModal}
        onHide={handleTargetDateModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleTargetDateModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Target Date</h4>
            </div>
            <div className="FolderForm">
              <h3>Task #115</h3>
              <div className="form-group">
                <h6>Please inform Target Date for this task:</h6>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Insert task Description"
                  name="targetDate"
                  value={targetDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Outline" onClick={handleTargetDateModalClose}>
                  Cancel
                </button>
                <button className="Create" onClick={handleTargetDate}>
                  Save
                </button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default IndividualComponentsUserWorkflowTasks;
