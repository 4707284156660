import { useState } from "react";
import "./SortableList.css"

const SortableList = ({menuList, setmenuList}) => {

  const [dragItemIndex, setDragItemIndex] = useState();
  const [dragOverItemIndex, setDragOverItemIndex] = useState();

  const handleDragStart = (index) => {
    setDragItemIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    const _menuList = [...menuList];
    const dragItem = _menuList.splice(dragItemIndex, 1);
    _menuList.splice(dragOverItemIndex, 0, dragItem[0]);
    setmenuList(_menuList);
  };

  const handleDragEnter = (index) => {
    setDragOverItemIndex(index);
  };

  const handleDragLeave = (event) => {
    setDragOverItemIndex(undefined);
  };

  const handleDragEnd = (event) => {
    setDragItemIndex(undefined);
    setDragOverItemIndex(undefined);
  };


  return (
    <ul>
      {menuList.map((menu, index) => (
        <li
          key={index}
          className={
            dragOverItemIndex === index
              ? "list-item next-position"
              : "list-item"
          }
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={handleDragOver}
          onDrop={() => handleDrop(index)}
          onDragEnter={() => handleDragEnter(index)}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragEnd}
        >
          <a>
            {menu?.modules}
            <span>
              <img src="images/four_arrow_icon.png" />
            </span>
          </a>{" "}
        </li>
      ))}
    </ul>
  );
};

export default SortableList;
