import React, { useEffect } from 'react'
import CommunicationSetup from './CommunicationSetup'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationHeadingAction } from '../../redux/action/adminNotificationAction'
import Loader from '../Loader'

const AdminNotificationIndex = ({adminNotificationStatus}) => {

  

  const dispatch = useDispatch()

  const {
    notificationHeadingList,

    loader,
  } = useSelector((state) => state.adminNotification);

 useEffect(()=>{


  dispatch(getNotificationHeadingAction())
  

 },[adminNotificationStatus])


  return (
    <div class="tab-pane fade" id="Notifications">
    <div class="ResidentialJobArea ViewConfigArea">
      <div class="ModalAccordian">
        <div id="accordion2">
          <div class="card">
            <div class="card-header">
              <h4 data-toggle="collapse" href="#CommunicationSetupTabs" class="" aria-expanded="true">
                <span><img src="images/communication_setup.png"  /></span> Communication Setup
              </h4>
            </div>
            <div id="CommunicationSetupTabs" class="collapse show" data-parent="#accordion2" >
              <div class="card-body">
                <div class="ViewConfigBodyMain">
                  <div class="ViewConfigBody flex-column">

                    {loader?
                    
                  <Loader />:
                  notificationHeadingList?.map((item)=>
                    <CommunicationSetup item={item} />


                )}


                  
                  </div>
                </div>
               
              </div>
            </div>
          </div>





        </div>
      </div>
    </div>
  </div>
  )
}

export default AdminNotificationIndex 