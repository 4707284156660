import React, { useState } from 'react'
import '../App.css';
import sidenav1 from "../assets/images/sidenav-1.png";
import sidenav2 from "../assets/images/sidenav-2.png";
import sidenav3 from "../assets/images/sidenav-3.png";
import sidenav4 from "../assets/images/sidenav-4.png";
import sidenav5 from "../assets/images/sidenav-5.png";
import sidenav6 from "../assets/images/sidenav-6.png";
import sidenav7 from "../assets/images/sidenav-7.png";
import sidenav8 from "../assets/images/sidenav-8.png";
import sidenav9 from "../assets/images/sidenav-9.png";
import sidenav10 from "../assets/images/sidenav-10.png";
import sidenav11 from "../assets/images/sidenav-11.png";
import sidenav12 from "../assets/images/sidenav-11.png";
import sidenav13 from "../assets/images/sidenav-13.png";

import theme8 from "../assets/images/theme-8.png";
import profile from "../assets/images/profile.png";
import contacts from "../assets/images/contacts.png";
import my_task from "../assets/images/my_task.png";
import signout from "../assets/images/signout.png";
import sales from "../assets/images/sales_red.png";
import { Link, useLocation } from 'react-router-dom';
import CreateModal from './CreateModal';
<img src="images/sales_red.png"></img>
const initModal = {

}

function SideNav() {
  const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
  const iBuildAdminDatas=JSON.parse(window.localStorage.getItem("iBuildAdminData"));

  const location = useLocation();
  const isActive = (path) => {
    return  location.pathname === path ||(location.pathname !== "/" && location.pathname.startsWith(path + "/"))
  };

  let path = window.location.href.split("/");
  let pathLength = parseInt(path.length) - 1;
  let pathMatch = path[pathLength];

  const [show,setShow]=useState(false)

  return (
    <div>
      {
        // show&&
        <CreateModal show={show} setShow={setShow}/>
      }
    
      {
        iBuildAdminDatas?.user?.role==="Builder Users" && 
        <div className="SidenavBar">
        <ul>
          <li className={pathMatch == "dashboard" ? "active" : ""}>
            <Link to="/dashboard">
              <span>
                {" "}
                <img src={sidenav1} />
              </span>
              Home
            </Link>
          </li>
          <li>
          <a role='button'  onClick={()=>setShow(true)}>
        <span>
          {" "}
          <img src={sidenav2} />
        </span>
        Create
      </a>
          </li>
      
          <li className={pathMatch == "sales" ? "active" : ""}>
            <Link to="/sales">
              <span>
                {" "}
                <img src={sales} />
              </span>
              Sales
            </Link>
          </li>
          <li className={pathMatch == "individual-components-part2-Vendor-List" ? "active" : ""}>
            <Link to="/individual-components-part2-Vendor-List">
              <span>
                {" "}
                <img src={sidenav3} />
              </span>
              Vendors/Suppliers
            </Link>
          </li>
          <li className={pathMatch == "land-development" ? "active" : ""}>
            <Link to="/land-development">
              <span>
                {" "}
                <img src={sidenav4} />
              </span>
              Land Development
            </Link>
          </li>
          <li className={pathMatch == "archive" ? "active" : ""}>
            <Link to="/archive">
              <span>
                {" "}
                <img src={sidenav5}/>
              </span>
              Archive
            </Link>
          </li>
          <li className={pathMatch == "project-proforma" ? "active" : ""}>
            <Link to="/project-proforma">
              <span>
                {" "}
                <img src={sidenav6}/>
              </span>
              Project Pro-forma
            </Link>
          </li>
          <li className={pathMatch == "residential" ? "active" : ""}>
            <Link to="/residential">
              <span>
                {" "}
                <img src={sidenav7} />
              </span>
              Residential
            </Link>
          </li>
          <li className={pathMatch == "multi-family" ? "active" : ""}>
            <Link to="/multi-family">
              <span>
                {" "}
                <img src={sidenav8}/>
              </span>
              Multi-Family
            </Link>
          </li>
          <li className={pathMatch == "admin" ? "active" : ""}>
            <Link to="/admin">
              <span>
                {" "}
                <img src={sidenav9} />
              </span>
              Admin
            </Link>
          </li>
          <li className={pathMatch == "file-manager" ? "active" : ""}>
            <Link to="/file-manager">
              <span>
                {" "}
                <img src={sidenav10} />
              </span>
              System File Manager
            </Link>
          </li>
          <li className={pathMatch == "price-book" ? "active" : ""}>
            <Link to="/price-book">
              <span>
                {" "}
                <img src={sidenav11} />
              </span>
              Price Book
            </Link>
          </li>
          
          <li className={pathMatch == "reports" ? "active" : ""}>
          <Link to="/reports">
            <span> <img src={sidenav13} /></span>
            Reports
          </Link>
        </li>
          <li className={pathMatch == "vendor-view-config" ? "active" : ""}>
            <Link to="/vendor-view-config">
              <span>
                {" "}
                <img src={sidenav12} />
              </span>
            Vendor View Config
              
            </Link>
          </li>
        </ul>
      </div>
      }
    








    </div>
  )
}

export default SideNav