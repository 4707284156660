
import axios from "axios";
import { homeActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));








  
export const getAllResidentailHomeListInitate = () => ({
    type: homeActionTypes.RESIDENTIAL_HOME_LIST_INITIATE,
  });
  
  export const getAllResidentailHomeListSuccess = (data) => ({
    type: homeActionTypes.RESIDENTIAL_HOME_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllResidentailHomeListFailure = (data) => ({
    type: homeActionTypes.RESIDENTIAL_HOME_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllResidentaiHomelListAction(pid ) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllResidentailHomeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/residentialJob?projectId=${pid}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(getAllResidentailHomeListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllResidentailHomeListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllResidentailHomeListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllResidentailHomeListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  
  


  
  
export const getAllLandDevelopmentHomeListInitate = () => ({
    type: homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_INITIATE,
  });
  
  export const getAllLandDevelopmentHomeListSuccess = (data) => ({
    type: homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllLandDevelopmentHomeListFailure = (data) => ({
    type: homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllLandDevelopmentHomelListAction(pid ) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllLandDevelopmentHomeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/landdevelopmentjob?projectId=${pid}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(getAllLandDevelopmentHomeListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllLandDevelopmentHomeListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllLandDevelopmentHomeListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllLandDevelopmentHomeListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  
  
  
export const getAllMultiFamilyHomeListInitate = () => ({
    type: homeActionTypes.MULTIFAMILY_HOME_LIST_INITIATE,
  });
  
  export const getAllMultiFamilyHomeListSuccess = (data) => ({
    type: homeActionTypes.MULTIFAMILY_HOME_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllMultiFamilyHomeListFailure = (data) => ({
    type: homeActionTypes.MULTIFAMILY_HOME_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllMultiFamilyHomelListAction(id ) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllMultiFamilyHomeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/multifamilyjob?projectId=${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(getAllMultiFamilyHomeListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllMultiFamilyHomeListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllMultiFamilyHomeListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllMultiFamilyHomeListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  

  
  
export const getAllTodoHomeListInitate = () => ({
    type: homeActionTypes.TODO_HOME_LIST_INITIATE,
  });
  
  export const getAllTodoHomeListSuccess = (data) => ({
    type: homeActionTypes.TODO_HOME_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllTodoHomeListFailure = (data) => ({
    type: homeActionTypes.TODO_HOME_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllTodoHomelListAction(jobType ) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllTodoHomeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/todo`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            // console.log("dataaaaaaaaaaa", data)
            if (data && data.status == 200) {
              dispatch(getAllTodoHomeListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllTodoHomeListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllTodoHomeListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllTodoHomeListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
  

  
  
export const getAllTaskHomeListInitate = () => ({
    type: homeActionTypes.TASK_HOME_LIST_INITIATE,
  });
  
  export const getAllTaskHomeListSuccess = (data) => ({
    type: homeActionTypes.TASK_HOME_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllTaskHomeListFailure = (data) => ({
    type: homeActionTypes.TASK_HOME_LIST_FAILURE,
    payload: data,
  });
  
  export function getAllTaskHomelListAction(jobType ) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getAllTaskHomeListInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/task`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            if (data && data.status == 200) {
              dispatch(getAllTaskHomeListSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getAllTaskHomeListFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getAllTaskHomeListSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getAllTaskHomeListFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }
//===========================iternary---count============================


export const iternaryHomeListInitiate = () => ({
  type: homeActionTypes.ITENARY_HOME_LIST_INITIATE,
});

export const iternaryHomeListSuccess = (data) => ({
  type: homeActionTypes.ITENARY_HOME_LIST_SUCCESS,
  payload: data,
});

export const iternaryHomeListFailure = (data) => ({
  type: homeActionTypes.ITENARY_HOME_LIST_FAILURE,
  payload: data,
});

export const getIternaryHomeList = (payload) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(iternaryHomeListInitiate(payload));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/home/itinerary`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(iternaryHomeListSuccess(response.data));
    } else {
      dispatch(iternaryHomeListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(iternaryHomeListFailure(err));
    return err.response;
  }
};

//======================================GET-MULITI---BLOCKS--=========================


export const getMultiBlocksListInitiate = () => ({
  type: homeActionTypes.GET_MULTI_BLOCKS_LIST_INITIATE,
});

export const getMultiBlocksListSuccess = (data) => ({
  type: homeActionTypes.GET_MULTI_BLOCKS_LIST_SUCCESS,
  payload: data,
});

export const getMultiBlocksListFailure = (data) => ({
  type: homeActionTypes.GET_MULTI_BLOCKS_LIST_FAILURE,
  payload: data,
});

export const getMultiBlocksList = (projectId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(getMultiBlocksListInitiate(projectId));
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/get-multi-blocks`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getMultiBlocksListSuccess(response.data));
    } else {
      dispatch(getMultiBlocksListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getMultiBlocksListFailure(err));
    return err.response;
  }
};

//===================MULITI-FAMILY-PROJECT---LIST=============


export const multiFamilyProjectListInitiate = () => ({
  type: homeActionTypes.MULTI_FAMILY_PROJECT_LIST_INITIATE,
});

export const multiFamilyProjectListSuccess = (data) => ({
  type: homeActionTypes.MULTI_FAMILY_PROJECT_LIST_SUCCESS,
  payload: data,
});

export const multiFamilyProjectListFailure = (data) => ({
  type: homeActionTypes.MULTI_FAMILY_PROJECT_LIST_FAILURE,
  payload: data,
});

export const getMultiFamilyProjectList = (ProjectId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(multiFamilyProjectListInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectType=Multi Family&ProjectId=${ProjectId}`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(multiFamilyProjectListSuccess(response.data));
    } else {
      dispatch(multiFamilyProjectListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(multiFamilyProjectListFailure(err));
    return err.response;
  }
};

//==============================Land-development-------------project==list====================================


export const landDevelopmentProjectListInitiate = () => ({
  type: homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_INITIATE,
});

export const landDevelopmentProjectListSuccess = (data) => ({
  type: homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_SUCCESS,
  payload: data,
});

export const landDevelopmentProjectListFailure = (data) => ({
  type: homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_FAILURE,
  payload: data,
});

export const getLandDevelopmentProjectList = () => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(landDevelopmentProjectListInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/all-project?projectType=Land Development`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(landDevelopmentProjectListSuccess(response.data));
    } else {
      dispatch(landDevelopmentProjectListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(landDevelopmentProjectListFailure(err));
    return err.response;
  }
};

//=====================================LAND-phases--list===============================================


export const getLandPhaseListInitiate = () => ({
  type: homeActionTypes.GET_LAND_PHASE_LIST_INITIATE,
});

export const getLandPhaseListSuccess = (data) => ({
  type: homeActionTypes.GET_LAND_PHASE_LIST_SUCCESS,
  payload: data,
});

export const getLandPhaseListFailure = (data) => ({
  type: homeActionTypes.GET_LAND_PHASE_LIST_FAILURE,
  payload: data,
});

export const getLandPhaseList = (projectId) => async (dispatch) => {
  
  try {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    dispatch(getLandPhaseListInitiate());
    const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${projectId}/get-land-phases`,{
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.status === 200) {
      dispatch(getLandPhaseListSuccess(response.data));
    } else {
      dispatch(getLandPhaseListFailure(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getLandPhaseListFailure(err));
    return err.response;
  }
};