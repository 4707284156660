import React, { useState ,useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import NewProjectStep2 from "./NewProjectStep2";
import NewProjectStep3 from "./NewProjectStep3";
import NewProjectStep4 from "./NewProjectStep4";
import NewProjectStep5 from "./NewProjectStep5";
import NewProjectStep6 from "./NewProjectStep6";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  createProjectAction,
  getNewProjectAction,
} from "../../redux/action/projectActionDk";
import { getCountriesList } from "../../redux/action/authAction";

const initialState = {
  projectName: "",
  projectLogo: "",
  projectEmail: "",

  projectAddress: "",

  city: "",

  country: "",

  zipCode: "",

  province: "",

  projectType: "",

  creationType: "",

  subdivisions: [],

  useCompanyPattern: false,

  jobPattern: "",
  profileCategories: {
    landCosts: false,
    archConsultantsFees: false,
    engineersFees: false,
    permitFees: false,
    siteLandDevServCosts: false,
    siteLanRetaiWallsRPR: false,
    sitePrepTemOfficeCosts: false,
    newHomeWarInsuranceFees: false,
    condPlanCorpoCosts: false,
    markSalesCosts: false,
    managLegFinFees: false,
  },

  integrateAccountingSoftware: false,

  developer: "",

  taxProfile: "",

  proformaId: 1,

  quickBooks: false,

  sage: false,
  errorMsg: {},
};

const NewProjectStep1 = ({
  NewProjectStep1Modal,
  SetNewProjectStep1Modal,
  setShow,
}) => {
  const dispatch = useDispatch();


  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

  const handleNewProjectStep1ModalClose = () => {
    setShow(true);
    SetNewProjectStep1Modal(false);
  };
  const handleNewProjectStep1ModalShow = () => {
    SetNewProjectStep1Modal(true);
  };

  const [iState, UpdateState] = useState(initialState);

  const [NewProjectStep2Modal, SetNewProjectStep2Modal] = useState(false);
  const [NewProjectStep3Modal, SetNewProjectStep3Modal] = useState(false);
  const [NewProjectStep4Modal, SetNewProjectStep4Modal] = useState(false);
  const [NewProjectStep5Modal, SetNewProjectStep5Modal] = useState(false);
  const [NewProjectStep6Modal, SetNewProjectStep6Modal] = useState(false);
  const [NewProjectStep7Modal, SetNewProjectStep7Modal] = useState(false);

  const { projectName, projectEmail, projectLogo, errorMsg } = iState;

  const handleValidation = () => {
    let formIsValid = true;
    let errorMsg = {};

    if (!projectName) {
      formIsValid = false;
      errorMsg.projectName = "* Please enter project name.";
    }

    UpdateState({ ...iState, errorMsg: errorMsg });

    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    UpdateState({
      ...iState,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    UpdateState({ ...iState, projectLogo:     URL.createObjectURL(file)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = handleValidation();

    if (formIsValid) {
  

          SetNewProjectStep1Modal(false);
          SetNewProjectStep2Modal(true);
     
    }
  };


  useEffect(() => {
    dispatch(getCountriesList());
  }, [NewProjectStep1Modal]);
  return (
    <>
      <Modal show={NewProjectStep1Modal} className="ModalBox LargeModal">
        <div class="modal-content BgCreate">
          <a
            onClick={handleNewProjectStep1ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project</h4>
            </div>

            <div class="StepBox">
              <ul>
                <li class="active">
                  <span class="Icon">
                    <img src={require("../../assets/images/step_1.png")} />
                  </span>
                  <span class="Text">Details</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    onClick={() => {
                      SetNewProjectStep2Modal(true);
                      SetNewProjectStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_2.png")} />
                  </span>
                  <span class="Text">Address</span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetNewProjectStep3Modal(true);
                      SetNewProjectStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_8.png")} />
                  </span>
                  <span class="Text">
                    Type&amp; <br /> Accounting{" "}
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetNewProjectStep4Modal(true);
                      SetNewProjectStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_5.png")} />
                  </span>
                  <span class="Text">
                    Job <br /> Numbering
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetNewProjectStep5Modal(true);
                      SetNewProjectStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_6.png")} />
                  </span>
                  <span class="Text">
                    Cost <br /> Profiles
                  </span>
                </li>
                <li>
                  <span
                    class="Icon FolderPermissionId "
                    data-toggle="modal"
                    onClick={() => {
                      SetNewProjectStep6Modal(true);
                      SetNewProjectStep1Modal(false);
                    }}
                  >
                    <img src={require("../../assets/images/step_7.png")} />
                  </span>
                  <span class="Text">Confirmation</span>
                </li>
              </ul>
            </div>

            <div class="FolderForm">
              <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>
                      Fields with <span class="required">*</span> are mandatory
                    </h6>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Company</h6>
                        <p>{iBuildLocalData?.companies?.[0]?.name}</p>
                      </div>
                      <div class="form-group">
                        <h6>
                          Project Name<span class="required">*</span>
                        </h6>
                        <input
                          type="text"
                          value={projectName}
                          name="projectName"
                          onChange={handleChange}
                          class="form-control"
                          placeholder="Insert Project name"
                        />

                        {errorMsg.projectName && !projectName && (
                          <p style={{ color: "red" }}>{errorMsg.projectName}</p>
                        )}
                      </div>
                      <div class="form-group">
                        <h6>Logo</h6>
                        <div class="Upload">
                          <span>-Drag and Drop Files or click to select</span>
                          <input onChange={handleUpload} type="file" />
                          {projectLogo && <img src={projectLogo} /> }
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>Project Email</h6>
                        <input
                          type="text"
                          value={projectEmail}
                          onChange={handleChange}
                          name="projectEmail"
                          class="form-control"
                          placeholder="Insert Project Email"
                        />
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button
                    class="Outline"
                    onClick={() => {
                      SetNewProjectStep1Modal(false);
                      setShow(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    class="Create FolderPermissionId"
                    onClick={handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <NewProjectStep2
        iState={iState}
        UpdateState={UpdateState}
        NewProjectStep2Modal={NewProjectStep2Modal}
        SetNewProjectStep2Modal={SetNewProjectStep2Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep3Modal={NewProjectStep3Modal}
        SetNewProjectStep3Modal={SetNewProjectStep3Modal}
        NewProjectStep4Modal={NewProjectStep4Modal}
        SetNewProjectStep4Modal={SetNewProjectStep4Modal}
        NewProjectStep5Modal={NewProjectStep5Modal}
        SetNewProjectStep5Modal={SetNewProjectStep5Modal}
        NewProjectStep6Modal={NewProjectStep6Modal}
        SetNewProjectStep6Modal={SetNewProjectStep6Modal}
        setShow={setShow}
      />
      <NewProjectStep3
        iState={iState}
        UpdateState={UpdateState}
        NewProjectStep3Modal={NewProjectStep3Modal}
        SetNewProjectStep3Modal={SetNewProjectStep3Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep2Modal={NewProjectStep2Modal}
        SetNewProjectStep2Modal={SetNewProjectStep2Modal}
        NewProjectStep4Modal={NewProjectStep4Modal}
        SetNewProjectStep4Modal={SetNewProjectStep4Modal}
        NewProjectStep5Modal={NewProjectStep5Modal}
        SetNewProjectStep5Modal={SetNewProjectStep5Modal}
        NewProjectStep6Modal={NewProjectStep6Modal}
        SetNewProjectStep6Modal={SetNewProjectStep6Modal}
        setShow={setShow}
      />
      <NewProjectStep4
        iState={iState}
        UpdateState={UpdateState}
        NewProjectStep4Modal={NewProjectStep4Modal}
        SetNewProjectStep4Modal={SetNewProjectStep4Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep2Modal={NewProjectStep2Modal}
        SetNewProjectStep2Modal={SetNewProjectStep2Modal}
        NewProjectStep3Modal={NewProjectStep3Modal}
        SetNewProjectStep3Modal={SetNewProjectStep3Modal}
        NewProjectStep5Modal={NewProjectStep5Modal}
        SetNewProjectStep5Modal={SetNewProjectStep5Modal}
        NewProjectStep6Modal={NewProjectStep6Modal}
        SetNewProjectStep6Modal={SetNewProjectStep6Modal}
        setShow={setShow}
      />
      <NewProjectStep5
        iState={iState}
        UpdateState={UpdateState}
        NewProjectStep5Modal={NewProjectStep5Modal}
        SetNewProjectStep5Modal={SetNewProjectStep5Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep2Modal={NewProjectStep2Modal}
        SetNewProjectStep2Modal={SetNewProjectStep2Modal}
        NewProjectStep3Modal={NewProjectStep3Modal}
        SetNewProjectStep3Modal={SetNewProjectStep3Modal}
        NewProjectStep4Modal={NewProjectStep4Modal}
        SetNewProjectStep4Modal={SetNewProjectStep4Modal}
        NewProjectStep6Modal={NewProjectStep6Modal}
        SetNewProjectStep6Modal={SetNewProjectStep6Modal}
        setShow={setShow}
      />
      <NewProjectStep6
        iState={iState}
        UpdateState={UpdateState}
        NewProjectStep6Modal={NewProjectStep6Modal}
        SetNewProjectStep6Modal={SetNewProjectStep6Modal}
        NewProjectStep1Modal={NewProjectStep1Modal}
        SetNewProjectStep1Modal={SetNewProjectStep1Modal}
        NewProjectStep2Modal={NewProjectStep2Modal}
        SetNewProjectStep2Modal={SetNewProjectStep2Modal}
        NewProjectStep3Modal={NewProjectStep3Modal}
        SetNewProjectStep3Modal={SetNewProjectStep3Modal}
        NewProjectStep4Modal={NewProjectStep4Modal}
        SetNewProjectStep4Modal={SetNewProjectStep4Modal}
        NewProjectStep5Modal={NewProjectStep5Modal}
        SetNewProjectStep5Modal={SetNewProjectStep5Modal}
        setShow={setShow}
      />
    </>
  );
};

export default NewProjectStep1;
