import { errorToast } from "./toast"

export const checkTYpeImage=(type)=>{
    let arr=['png','jpg','jpeg']

    if(arr.includes(type)){
        return true
    }
    else{
        return false
    }
}
export const checkTYpePDF=(type)=>{
    if(type=="pdf"){
        return true
    }
    else{
        // errorToast("Please Select correct format")
        return
    }
}

export const checkTYpeVideo=(type)=>{
    if(type=="mp4"){
        return true
    }
    else{
        // errorToast("Please Select correct format")
        return
    }
}


export const checkArray=(type)=>{
    if(type !==undefined && type !== null &&type.length>0){
        return true
    }
    else{
        return false
    }
}

export function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  
  export function FirstLetters({ string }) {
    // Split the input string into individual words
    const words = string.split(" ");

    // Ensure there are at least two words
    if (words.length >= 2) {
        // Extract the first letter of each word
        const firstLetterNikhil = words[0].charAt(0);
        const firstLetterTripathi = words[1].charAt(0);
         
        return <p>{firstLetterNikhil + firstLetterTripathi}</p>

        
    } else {
        return <p>String does not contain at least two words.</p>;
    }
}

//   <td>{index + 1 + serialNo - 10}</td>
  