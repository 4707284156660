import axios from "axios";
import { costCodesActionTypes } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const getAllCostProfielListInitate = () => ({
  type: costCodesActionTypes.COST_PROFILE_JOB_LIST_INITIATE,
});

export const getAllCostProfielListSuccess = (data) => ({
  type: costCodesActionTypes.COST_PROFILE_JOB_LIST_SUCCESS,
  payload: data,
});

export const getAllCostProfielListFailure = (data) => ({
  type: costCodesActionTypes.COST_PROFILE_JOB_LIST_FAILURE,
  payload: data,
});

export function getAllCostProfielListAction(name) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllCostProfielListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costprofile?search=${
            name ? name : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          console.log("data", data);
          // if (data && data.status == 200) {
            dispatch( getAllCostProfielListSuccess (data.data));
            resolve(data.data);
          // } else {
          //   dispatch(getAllCostProfielListFailure(data.data));
          //   reject(data);
          // }
        })
        .catch((err) => {
          console.log(err,"RRRRRRRRRRRR")
          // if (err.response.status === 404) {
          //   dispatch(getAllCostProfielListSuccess(err.data));
          //   resolve(err.data);
          // } else {
          //   dispatch(getAllCostProfielListFailure(err));
          //   reject(err);
          // }
        });
    });
  };
}





export const getAllCostProfileInitate = () => ({
  type: costCodesActionTypes.COST_PROFILE_LIST_INITIATE,
});

export const getAllCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.COST_PROFILE_LIST_SUCCESS,
  payload: data,
});

export const getAllCostProfileFailure = (data) => ({
  type: costCodesActionTypes.COST_PROFILE_LIST_FAILURE,
  payload: data,
});

export function getAllCostProfileAction(name) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllCostProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costprofile?search=${
            name ? name : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          console.log("data", data);
          // if (data && data.status == 200) {
            dispatch( getAllCostProfileSuccess (data.data));
            resolve(data.data);
          // } else {
          //   dispatch(getAllCostProfielListFailure(data.data));
          //   reject(data);
          // }
        })
        .catch((err) => {
          console.log(err,"RRRRRRRRRRRR")
          // if (err.response.status === 404) {
          //   dispatch(getAllCostProfielListSuccess(err.data));
          //   resolve(err.data);
          // } else {
          //   dispatch(getAllCostProfielListFailure(err));
          //   reject(err);
          // }
        });
    });
  };
}

export const getAllSubCostProfielListInitate = () => ({
  type: costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_INITIATE,
});

export const getAllSubCostProfielListSuccess = (data) => ({
  type: costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_SUCCESS,
  payload: data,
});

export const getAllSubCostProfielListFailure = (data) => ({
  type: costCodesActionTypes.SUB_COST_PROFILE_JOB_LIST_FAILURE,
  payload: data,
});

export function getAllSubCostProfielListAction(Id, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllSubCostProfielListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costsubprofile?CostProfileId=${Id ? Id : ""}&search=${
            search ? search : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllSubCostProfielListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllSubCostProfielListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllSubCostProfielListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllSubCostProfielListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const addCostProfileInitate = () => ({
  type: costCodesActionTypes.ADD_COST_PROFILE_INITIATE,
});

export const addCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.ADD_COST_PROFILE_SUCCESS,
  payload: data,
});

export const addCostProfileFailure = (data) => ({
  type: costCodesActionTypes.ADD_COST_PROFILE_FAILURE,
  payload: data,
});

export function addCostProfileAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addCostProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/CostProfile
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costprofile`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addCostProfileSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addCostProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addCostProfileFailure(err));
          reject(err);
        });
    });
  };
}

export const addSubCostProfileInitate = () => ({
  type: costCodesActionTypes.ADD_SUB_COST_PROFILE_INITIATE,
});

export const addSubCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.ADD_SUB_COST_PROFILE_SUCCESS,
  payload: data,
});

export const addSubCostProfileFailure = (data) => ({
  type: costCodesActionTypes.ADD_SUB_COST_PROFILE_FAILURE,
  payload: data,
});

export function addSubCostProfileAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addSubCostProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/SubCostProfile
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costsubprofile`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addSubCostProfileSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addSubCostProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addSubCostProfileFailure(err));
          reject(err);
        });
    });
  };
}

// export const removeCategoryInitate = () => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_INITIATE,
// });

// export const removeCategorySuccess = (data) => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_SUCCESS,
//   payload: data,
// });

// export const removeCategoryFailure = (data) => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_FAILURE,
//   payload: data,
// });

// export function removeCategoryAction( id, type) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(removeCategoryInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .delete(
//           `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${id ? id : "" }`,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           const data = response.data;
//           if ((data && data.status == 200) || 201) {
//             dispatch(removeCategorySuccess(data));
//             resolve(data);
//           } else {
//             dispatch(removeCategoryFailure(data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(removeCategoryFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const updateCategoryInitate = () => ({
//   type: costCodesActionTypes.UPDATE_CATEGORY_INITIATE,
// });

// export const updateCategorySuccess = (data) => ({
//   type: costCodesActionTypes.UPDATE_CATEGORY_SUCCESS,
//   payload: data,
// });

// export const updateCategoryFailure = (data) => ({
//   type: costCodesActionTypes.UPDATE_CATEGORY_SUCCESS,
//   payload: data,
// });

// export function updateCategoryAction(payload, params) {
//   const token = isLoggedIn("adminData1");
//   const updatedData = multiPartData({ image: payload.image, ...payload })
//   return (dispatch) => {
//     dispatch(updateCategoryInitate());
//     return new Promise((resolve, reject) => {
//       // ${payload?payload:""}
//       axios
//         .put(
//           `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${params}`,
//           payload,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           const data = response.data;
//           if ((data && data.status == 201) || 200) {
//             dispatch(updateCategorySuccess(data));
//             resolve(data);
//           } else {
//             dispatch(updateCategoryFailure(data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(updateCategoryFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const getCategoryDetailsInitate = () => ({
//   type: costCodesActionTypes.CATEGORY_DETAILS_INITIATE,
// });

// export const getCategoryDetailsSuccess = (data) => ({
//   type: costCodesActionTypes.CATEGORY_DETAILS_SUCCESS,
//   payload: data,
// });

// export const getCategoryDetailsFailure = (data) => ({
//   type: costCodesActionTypes.CATEGORY_DETAILS_FAILURE,
//   payload: data,
// });

// export function getCategoryDetailsAction( id, type ) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(getCategoryDetailsInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}?categoryType=${type}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", data);
//           if (data && data.status == 200 || 201) {
//             dispatch(getCategoryDetailsSuccess(data.data));
//             resolve(data.data);
//           } else {
//             dispatch(getCategoryDetailsFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           console.log("==errerr=====>", err)
//           if(err.response.status === 404){
//             dispatch(getCategoryDetailsSuccess(err.data));
//             resolve(err.data)
//           }else {
//             dispatch(getCategoryDetailsFailure(err));
//             reject(err);
//           }
//         });
//     });
//   };
// }

// export const getAllCategoryListInitate = () => ({
//   type: costCodesActionTypes.CATEGORY_LIST_INITIATE,
// });

// export const getAllCategoryListSuccess = (data) => ({
//   type: costCodesActionTypes.CATEGORY_LIST_SUCCESS,
//   payload: data,
// });

// export const getAllCategoryListFailure = (data) => ({
//   type: costCodesActionTypes.CATEGORY_LIST_FAILURE,
//   payload: data,
// });

// export function getAllCategoryListAction( sid, cid, search) {
//   const token = isLoggedIn("adminData1");

//   return (dispatch) => {
//     dispatch(getAllCategoryListInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory?CostSubProfileId=${sid}&CostProfileId=${cid}&search=${search}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", data);
//           if (data && data.status == 200) {
//             dispatch(getAllCategoryListSuccess(data.data));
//             resolve(data.data);
//           } else {
//             dispatch(getAllCategoryListFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           if(err.response.status === 404){
//             dispatch(getAllCategoryListSuccess(err.data));
//             resolve(err.data)
//           }else {
//             dispatch(getAllCategoryListFailure(err));
//             reject(err);
//           }

//         });
//     });
//   };
// }

// export const addCategoryInitate = () => ({
//   type: costCodesActionTypes.ADD_CATEGORY_INITIATE,
// });

// export const addCategorySuccess = (data) => ({
//   type: costCodesActionTypes.ADD_CATEGORY_SUCCESS,
//   payload: data,
// });

// export const addCategoryFailure = (data) => ({
//   type: costCodesActionTypes.ADD_CATEGORY_FAILURE,
//   payload: data,
// });

// export function addCategoryAction(payload) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(addCategoryInitate());
//     return new Promise((resolve, reject) => {
//       axios
//       // companies/2/Category
//         .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory`, payload, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", response);
//           if (data && data.status == 201) {
//             dispatch(addCategorySuccess(data.data));
//             resolve(data);
//           } else {
//             dispatch(addCategoryFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(addCategoryFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

export const updateCategoryInitate = () => ({
  type: costCodesActionTypes.UPDATE_CATEGORY_INITIATE,
});

export const updateCategorySuccess = (data) => ({
  type: costCodesActionTypes.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export const updateCategoryFailure = (data) => ({
  type: costCodesActionTypes.UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export function updateCategoryAction(payload, params) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload });
  return (dispatch) => {
    dispatch(updateCategoryInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateCategorySuccess(data));
            resolve(data);
          } else {
            dispatch(updateCategoryFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateCategoryFailure(err));
          reject(err);
        });
    });
  };
}

export const getCategoryDetailsInitate = () => ({
  type: costCodesActionTypes.CATEGORY_DETAILS_INITIATE,
});

export const getCategoryDetailsSuccess = (data) => ({
  type: costCodesActionTypes.CATEGORY_DETAILS_SUCCESS,
  payload: data,
});

export const getCategoryDetailsFailure = (data) => ({
  type: costCodesActionTypes.CATEGORY_DETAILS_FAILURE,
  payload: data,
});

export function getCategoryDetailsAction(id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getCategoryDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}?categoryType=${type}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200) || 201) {
            dispatch(getCategoryDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getCategoryDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err);
          if (err.response.status === 404) {
            dispatch(getCategoryDetailsSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getCategoryDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllCategoryListInitate = () => ({
  type: costCodesActionTypes.CATEGORY_LIST_INITIATE,
});

export const getAllCategoryListSuccess = (data) => ({
  type: costCodesActionTypes.CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const getAllCategoryListFailure = (data) => ({
  type: costCodesActionTypes.CATEGORY_LIST_FAILURE,
  payload: data,
});

export function getAllCategoryListAction(sid, cid, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCategoryListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costcategory?CostSubProfileId=${sid}&CostProfileId=${cid}&search=${
            search ? search : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllCategoryListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllCategoryListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllCategoryListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCategoryListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const addCategoryInitate = () => ({
  type: costCodesActionTypes.ADD_CATEGORY_INITIATE,
});

export const addCategorySuccess = (data) => ({
  type: costCodesActionTypes.ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const addCategoryFailure = (data) => ({
  type: costCodesActionTypes.ADD_CATEGORY_FAILURE,
  payload: data,
});

export function addCategoryAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addCategoryInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/Category
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addCategorySuccess(data.data));
            resolve(data);
          } else {
            dispatch(addCategoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addCategoryFailure(err));
          reject(err);
        });
    });
  };
}

export const updateCostProfileInitate = () => ({
  type: costCodesActionTypes.UPDATE_COST_PROFILE_INITIATE,
});

export const updateCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.UPDATE_COST_PROFILE_SUCCESS,
  payload: data,
});

export const updateCostProfileFailure = (data) => ({
  type: costCodesActionTypes.UPDATE_COST_PROFILE_SUCCESS,
  payload: data,
});

export function updateCostProfileAction(payload, params) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
    dispatch(updateCostProfileInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costprofile/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateCostProfileSuccess(data));
            resolve(data);
          } else {
            dispatch(updateCostProfileFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateCostProfileFailure(err));
          reject(err);
        });
    });
  };
}

export const removCostProifleInitate = () => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_INITIATE,
});

export const removCostProifleSuccess = (data) => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_SUCCESS,
  payload: data,
});

export const removCostProifleFailure = (data) => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_FAILURE,
  payload: data,
});

export function removeCostProfileAction(id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removCostProifleInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costprofile/${
            id ? id : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removCostProifleSuccess(data));
            resolve(data);
          } else {
            dispatch(removCostProifleFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removCostProifleFailure(err));
          reject(err);
        });
    });
  };
}

export const removeSubCostProifleInitate = () => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_INITIATE,
});

export const removeSubCostProifleSuccess = (data) => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_SUCCESS,
  payload: data,
});

export const removeSubCostProifleFailure = (data) => ({
  type: costCodesActionTypes.DELETE_COST_PROFILE_FAILURE,
  payload: data,
});

export function removeSubCostProfileAction(id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeSubCostProifleInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costsubprofile/${
            id ? id : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeSubCostProifleSuccess(data));
            resolve(data);
          } else {
            dispatch(removeSubCostProifleFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeSubCostProifleFailure(err));
          reject(err);
        });
    });
  };
}

export const updateSubCostProfileInitate = () => ({
  type: costCodesActionTypes.UPDATE_SUB_COST_PROFILE_INITIATE,
});

export const updateSubCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.UPDATE_SUB_COST_PROFILE_SUCCESS,
  payload: data,
});

export const updateSubCostProfileFailure = (data) => ({
  type: costCodesActionTypes.UPDATE_SUB_COST_PROFILE_SUCCESS,
  payload: data,
});

export function updateSubCostProfileAction(payload, params) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
    dispatch(updateSubCostProfileInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costsubprofile/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateSubCostProfileSuccess(data));
            resolve(data);
          } else {
            dispatch(updateSubCostProfileFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateSubCostProfileFailure(err));
          reject(err);
        });
    });
  };
}

export const removeCategoryInitate = () => ({
  type: costCodesActionTypes.DELETE_CATEGORY_INITIATE,
});

export const removeCategorySuccess = (data) => ({
  type: costCodesActionTypes.DELETE_CATEGORY_SUCCESS,
  payload: data,
});

export const removeCategoryFailure = (data) => ({
  type: costCodesActionTypes.DELETE_CATEGORY_FAILURE,
  payload: data,
});

export function removeCategoryAction(id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeCategoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${
            id ? id : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeCategorySuccess(data));
            resolve(data);
          } else {
            dispatch(removeCategoryFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeCategoryFailure(err));
          reject(err);
        });
    });
  };
}

export const removeCostCodeInitate = () => ({
  type: costCodesActionTypes.DELETE_COST_CODE_INITIATE,
});

export const removeCostCodeSuccess = (data) => ({
  type: costCodesActionTypes.DELETE_COST_CODE_SUCCESS,
  payload: data,
});

export const removeCostCodeFailure = (data) => ({
  type: costCodesActionTypes.DELETE_COST_CODE_FAILURE,
  payload: data,
});

export function removeCostCodeAction(id) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(removeCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/${
            id ? id : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 200) || 201) {
            dispatch(removeCostCodeSuccess(data));
            resolve(data);
          } else {
            dispatch(removeCostCodeFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(removeCostCodeFailure(err));
          reject(err);
        });
    });
  };
}

export const updateCostCodeInitate = () => ({
  type: costCodesActionTypes.UPDATE_COST_CODE_INITIATE,
});

export const updateCostCodeSuccess = (data) => ({
  type: costCodesActionTypes.UPDATE_COST_CODE_SUCCESS,
  payload: data,
});

export const updateCostCodeFailure = (data) => ({
  type: costCodesActionTypes.UPDATE_COST_CODE_SUCCESS,
  payload: data,
});

export function updateCostCodeAction(payload, params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(updateCostCodeInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateCostCodeSuccess(data));
            resolve(data);
          } else {
            dispatch(updateCostCodeFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateCostCodeFailure(err));
          reject(err);
        });
    });
  };
}

export const getCostCodeInitate = () => ({
  type: costCodesActionTypes.COST_CODE_DETAILS_INITIATE,
});

export const getCostCodeSuccess = (data) => ({
  type: costCodesActionTypes.COST_CODE_DETAILS_SUCCESS,
  payload: data,
});

export const getCostCodeFailure = (data) => ({
  type: costCodesActionTypes.COST_CODE_DETAILS_FAILURE,
  payload: data,
});

export function getCostCodeAction(id, type) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}?categoryType=${type}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if ((data && data.status == 200) || 201) {
            dispatch(getCostCodeSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          console.log("==errerr=====>", err);
          if (err.response.status === 404) {
            dispatch(getCostCodeSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getCostCodeFailure(err));
            reject(err);
          }
        });
    });
  };
}

// export const getAllCostCodeListInitate = () => ({
//   type: costCodesActionTypes.COST_CODE_LIST_INITIATE,
// });

// export const getAllCostCodeListSuccess = (data) => ({
//   type: costCodesActionTypes.COST_CODE_LIST_SUCCESS,
//   payload: data,
// });

// export const getAllCostCodeListFailure = (data) => ({
//   type: costCodesActionTypes.COST_CODE_LIST_FAILURE,
//   payload: data,
// });

// export function getAllCostCodeListAction(cid, cpd, sid, search) {
//   const token = isLoggedIn("adminData1");

//   return (dispatch) => {
//     dispatch(getAllCostCodeListInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode?CostCategoryId=${cid}&CostProfileId=${cpd}&CostSubProfileId=${sid}&search=${search}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", data);
//           if (data && data.status == 200) {
//             dispatch(getAllCostCodeListSuccess(data.data));
//             resolve(data.data);
//           } else {
//             dispatch(getAllCostCodeListFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           if(err.response.status === 404){
//             dispatch(getAllCostCodeListSuccess(err.data));
//             resolve(err.data)
//           }else {
//             dispatch(getAllCostCodeListFailure(err));
//             reject(err);
//           }

//         });
//     });
//   };
// }

// export const addCostCodeInitate = () => ({
//   type: costCodesActionTypes.ADD_COST_CODE_INITIATE,
// });

// export const addCostCodeSuccess = (data) => ({
//   type: costCodesActionTypes.ADD_COST_CODE_SUCCESS,
//   payload: data,
// });

// export const addCostCodeFailure = (data) => ({
//   type: costCodesActionTypes.ADD_COST_CODE_FAILURE,
//   payload: data,
// });

// export function addCostCodeAction(payload) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(addCostCodeInitate());
//     return new Promise((resolve, reject) => {
//       axios
//       // companies/2/CostCode
//         .post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode`, payload, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", response);
//           if (data && data.status == 201) {
//             dispatch(addCostCodeSuccess(data.data));
//             resolve(data);
//           } else {
//             dispatch(addCostCodeFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(addCostCodeFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const removeCategoryInitate = () => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_INITIATE,
// });

// export const removeCategorySuccess = (data) => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_SUCCESS,
//   payload: data,
// });

// export const removeCategoryFailure = (data) => ({
//   type: costCodesActionTypes.DELETE_CATEGORY_FAILURE,
//   payload: data,
// });

// export function removeCategoryAction( id, type) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(removeCategoryInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .delete(
//           `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${id ? id : "" }`,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           const data = response.data;
//           if ((data && data.status == 200) || 201) {
//             dispatch(removeCategorySuccess(data));
//             resolve(data);
//           } else {
//             dispatch(removeCategoryFailure(data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(removeCategoryFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const removeCostCodeInitate = () => ({
//   type: costCodesActionTypes.DELETE_COST_CODE_INITIATE,
// });

// export const removeCostCodeSuccess = (data) => ({
//   type: costCodesActionTypes.DELETE_COST_CODE_SUCCESS,
//   payload: data,
// });

// export const removeCostCodeFailure = (data) => ({
//   type: costCodesActionTypes.DELETE_COST_CODE_FAILURE,
//   payload: data,
// });

// export function removeCostCodeAction( id, type) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(removeCostCodeInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .delete(
//           `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/${id ? id : "" }`,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           const data = response.data;
//           if ((data && data.status == 200) || 201) {
//             dispatch(removeCostCodeSuccess(data));
//             resolve(data);
//           } else {
//             dispatch(removeCostCodeFailure(data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(removeCostCodeFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const updateCostCodeInitate = () => ({
//   type: costCodesActionTypes.UPDATE_COST_CODE_INITIATE,
// });

// export const updateCostCodeSuccess = (data) => ({
//   type: costCodesActionTypes.UPDATE_COST_CODE_SUCCESS,
//   payload: data,
// });

// export const updateCostCodeFailure = (data) => ({
//   type: costCodesActionTypes.UPDATE_COST_CODE_SUCCESS,
//   payload: data,
// });

// export function updateCostCodeAction(payload, params) {
//   const token = isLoggedIn("adminData1");
//   const updatedData = multiPartData({ image: payload.image, ...payload })
//   return (dispatch) => {
//     dispatch(updateCostCodeInitate());
//     return new Promise((resolve, reject) => {
//       // ${payload?payload:""}
//       axios
//         .put(
//           `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costCostCode/${params}`,
//           payload,
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         )
//         .then((response) => {
//           const data = response.data;
//           if ((data && data.status == 201) || 200) {
//             dispatch(updateCostCodeSuccess(data));
//             resolve(data);
//           } else {
//             dispatch(updateCostCodeFailure(data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           dispatch(updateCostCodeFailure(err));
//           reject(err);
//         });
//     });
//   };
// }

// export const getCostCodeInitate = () => ({
//   type: costCodesActionTypes.COST_CODE_DETAILS_INITIATE,
// });

// export const getCostCodeSuccess = (data) => ({
//   type: costCodesActionTypes.COST_CODE_DETAILS_SUCCESS,
//   payload: data,
// });

// export const getCostCodeFailure = (data) => ({
//   type: costCodesActionTypes.COST_CODE_DETAILS_FAILURE,
//   payload: data,
// });

// export function getCostCodeAction( id, type ) {
//   const token = isLoggedIn("adminData1");
//   return (dispatch) => {
//     dispatch(getCostCodeInitate());
//     return new Promise((resolve, reject) => {
//       axios
//         .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/categories/${id}?categoryType=${type}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         })
//         .then((response) => {
//           const data = response;
//           ////console.log("data", data);
//           if (data && data.status == 200 || 201) {
//             dispatch(getCostCodeSuccess(data.data));
//             resolve(data.data);
//           } else {
//             dispatch(getCostCodeFailure(data.data));
//             reject(data);
//           }
//         })
//         .catch((err) => {
//           console.log("==errerr=====>", err)
//           if(err.response.status === 404){
//             dispatch(getCostCodeSuccess(err.data));
//             resolve(err.data)
//           }else {
//             dispatch(getCostCodeFailure(err));
//             reject(err);
//           }
//         });
//     });
//   };
// }

export const getAllCostCodeListInitate = () => ({
  type: costCodesActionTypes.COST_CODE_LIST_INITIATE,
});

export const getAllCostCodeListSuccess = (data) => ({
  type: costCodesActionTypes.COST_CODE_LIST_SUCCESS,
  payload: data,
});

export const getAllCostCodeListFailure = (data) => ({
  type: costCodesActionTypes.COST_CODE_LIST_FAILURE,
  payload: data,
});

export function getAllCostCodeListAction(cid, cpd, sid, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCostCodeListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costcode?CostCategoryId=${cid}&CostProfileId=${cpd}&CostSubProfileId=${sid}&search=${
            search ? search : ""
          }`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllCostCodeListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllCostCodeListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllCostCodeListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCostCodeListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const addCostCodeInitate = () => ({
  type: costCodesActionTypes.ADD_COST_CODE_INITIATE,
});

export const addCostCodeSuccess = (data) => ({
  type: costCodesActionTypes.ADD_COST_CODE_SUCCESS,
  payload: data,
});

export const addCostCodeFailure = (data) => ({
  type: costCodesActionTypes.ADD_COST_CODE_FAILURE,
  payload: data,
});

export function addCostCodeAction(payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(addCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        // companies/2/CostCode
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", response);
          if (data && data.status == 201) {
            dispatch(addCostCodeSuccess(data.data));
            resolve(data);
          } else {
            dispatch(addCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(addCostCodeFailure(err));
          reject(err);
        });
    });
  };
}

export const getAllCostCodeMasterListInitate = () => ({
  type: costCodesActionTypes.MASTER_COSTCODE_INITIATE,
});

export const getAllCostCodeMasterListSuccess = (data) => ({
  type: costCodesActionTypes.MASTER_COSTCODE_SUCCESS,
  payload: data,
});

export const getAllCostCodeMasterListFailure = (data) => ({
  type: costCodesActionTypes.MASTER_COSTCODE_FAILURE,
  payload: data,
});

export function getAllCostCodeMasterListAction(cid, cpd, sid, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllCostCodeMasterListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costcode?CostCategoryId=${cid}&CostProfileId=${cpd}&CostSubProfileId=${sid}&search=${
            search ? search : ""
          }&master=${true}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllCostCodeMasterListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllCostCodeMasterListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllCostCodeMasterListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCostCodeMasterListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllMasterCategorylListInitate = () => ({
  type: costCodesActionTypes.MASTER_CATEGORY_INITIATE,
});

export const getAllMasterCategorylListSuccess = (data) => ({
  type: costCodesActionTypes.MASTER_CATEGORY_SUCCESS,
  payload: data,
});

export const getAllMasterCategorylListFailure = (data) => ({
  type: costCodesActionTypes.MASTER_CATEGORY_FAILURE,
  payload: data,
});

export function getAllMasterCategorylListAction(sid, cid, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllMasterCategorylListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costcategory?CostSubProfileId=${sid}&CostProfileId=${cid}&search=${
            search ? search : ""}&master=${true}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllMasterCategorylListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllMasterCategorylListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllMasterCategorylListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllMasterCategorylListFailure(err));
            reject(err);
          }
        });
    });
  };
}

export const getAllMasterSubProfilelListInitate = () => ({
  type: costCodesActionTypes.MASTER_SUBPROFILE_INITIATE,
});

export const getAllMasterSubProfilelListSuccess = (data) => ({
  type: costCodesActionTypes.MASTER_SUBPROFILE_SUCCESS,
  payload: data,
});

export const getAllMasterSubProfilelListFailure = (data) => ({
  type: costCodesActionTypes.MASTER_SUBPROFILE_FAILURE,
  payload: data,
});

export function getAllMasterSubProfilelListAction(Id, search) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getAllMasterSubProfilelListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${
            iBuildLocalData?.user?.CompanyId
          }/costsubprofile?CostProfileId=${Id ? Id : ""}&search=${
            search ? search : ""
          }&master=${true}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllMasterSubProfilelListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllMasterSubProfilelListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllMasterSubProfilelListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllMasterSubProfilelListFailure(err));
            reject(err);
          }
        });
    });
  };
}











// PROFORMA COST PROFILE



export const getAllProformaProfileInitate = () => ({
  type: costCodesActionTypes.GET_PROFORMA_PROFILE_INITIATE,
});

export const getAllProformaProfileSuccess = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_PROFILE_SUCCESS,
  payload: data,
});

export const getAllProformaProfileFailure = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_PROFILE_FAILURE,
  payload: data,
});

export function getAllProformaProfileAction(name) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllProformaProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}proformaprofiles`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllProformaProfileSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllProformaProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllProformaProfileSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCostProfielListFailure(err));
            reject(err);
          }
        });
    });
  };
}





export const getAllProformaBaseCostProfileInitate = () => ({
  type: costCodesActionTypes.GET_PROFORMA_BASE_COST_INITIATE,
});

export const getAllProformaBaseCostProfileSuccess = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_BASE_COST_SUCCESS,
  payload: data,
});

export const getAllProformaBaseCostProfileFailure = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_BASE_COST_FAILURE,
  payload: data,
});

export function getAllProformaBaseCostProfileAction(pid, name, parentTableId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getAllProformaBaseCostProfileInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}company/${iBuildLocalData?.user?.CompanyId}/proformabasecost?profileId=${pid}&parentTableName=${name ? name :""}&parentTableId=${parentTableId ? parentTableId :""}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getAllProformaBaseCostProfileSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getAllProformaBaseCostProfileFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getAllProformaBaseCostProfileSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCostProfielListFailure(err));
            reject(err);
          }
        });
    });
  };
}



export function updateProformaBaseCostAction( params, payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}company/${iBuildLocalData?.user?.CompanyId}/proformabasecost/${params}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}




export const unitAdminListInitate = () => ({
  type: costCodesActionTypes.UNIT_LIST_ADMIN_INITIATE,
});

export const unitAdminListSuccess = (data) => ({
  type: costCodesActionTypes.UNIT_LIST_ADMIN_SUCCESS,
  payload: data,
});

export const unitAdminListFailure = (data) => ({
  type: costCodesActionTypes.UNIT_LIST_ADMIN_FAILURE,
  payload: data,
});

export function unitAdminListAction() {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(unitAdminListInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}unit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(unitAdminListSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(unitAdminListFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(unitAdminListSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getAllCostProfielListFailure(err));
            reject(err);
          }
        });
    });
  };
}




export function setBidForEmailAction( payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/emailSetForBidMessages`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}



export function setDefaultSettingsAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/defaultSettings`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}




export function setRoleLimtiAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/createEstimation`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}



export function userLimitIncreasedAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/createUserLimit`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}






export function editEstimationAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/editEstimation`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}





export const getEstimationDetailsInitate = () => ({
  type: costCodesActionTypes.VIEW_ADMIN_ESTIMATION_INITIATE,
});

export const getEstimationDetailsSuccess = (data) => ({
  type: costCodesActionTypes.VIEW_ADMIN_ESTIMATION_SUCCESS,
  payload: data,
});

export const getEstimationDetailsFailure = (data) => ({
  type: costCodesActionTypes.VIEW_ADMIN_ESTIMATION_FAILURE,
  payload: data,
});

export function getEstimationDetailsAction(params) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getEstimationDetailsInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}estimation/viewEstimation?projectId=${params}`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 201) {
            dispatch(getEstimationDetailsSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getEstimationDetailsFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getEstimationDetailsSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getEstimationDetailsFailure(err));
            reject(err);
          }
        });
    });
  };
}



export function getProjectEstimationAction( params, payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .get(
          `${Url}estimation/viewProject?projectId=${params}`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}


export function editUserLimitAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {
   
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}estimation/createUserLimit`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}





export const getUserLimitRoleInitate = () => ({
  type: costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_INITIATE,
});

export const getUserLimitRoleSuccess = (data) => ({
  type: costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_SUCCESS,
  payload: data,
});

export const getUserLimitRoleFailure = (data) => ({
  type: costCodesActionTypes.VIEW_USER_LIMIT_DETAILS_FAILURE,
  payload: data,
});

export function getUserLimitRoleAction(params, roleId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getUserLimitRoleInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}estimation/getAllUserLimit?projectId=${params}&roleId=${roleId}`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getUserLimitRoleSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getUserLimitRoleFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getUserLimitRoleSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getUserLimitRoleFailure(err));
            reject(err);
          }
        });
    });
  };
}




export const getProformaSettingInitate = () => ({
  type: costCodesActionTypes.GET_PROFORMA_SETTING_INITIATE,
});

export const getProformaSettingSuccess = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_SETTING_SUCCESS,
  payload: data,
});

export const getProformaSettingFailure = (data) => ({
  type: costCodesActionTypes.GET_PROFORMA_SETTING_FAILURE,
  payload: data,
});




export function getProformaSettingAction(params, roleId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getProformaSettingInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/proforma-settings`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getProformaSettingSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getProformaSettingFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getProformaSettingSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getProformaSettingFailure(err));
            reject(err);
          }
        });
    });
  };
}



export function updateProformaSettingAction(  payload) {
  const token = isLoggedIn("adminData1");
  // const updatedData = multiPartData({ image: payload.image, ...payload })
  return (dispatch) => {  
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/proforma-settings`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {        
            resolve(data);
          } else {           
            reject(data);
          }
        })
        .catch((err) => {
        reject(err);
        });
    });
  };
}






export function changeSubProfileMasterSettingAction(params, payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costsubprofile/${params}`, payload ,    
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}


export function changeCostCategoryMasterSettingAction(params, payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/${params}`,  payload,    
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}



export function changeCostCodeMasterSettingAction(params, payload) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/${params}`, payload,     
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          if (data && data.status == 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            resolve(err.data);
          } else {
            reject(err);
          }
        });
    });
  };
}










export const getMasterCostCategoryInitate = () => ({
  type: costCodesActionTypes.GET_MASTER_COSTCATEGORY_INITIATE,
});

export const getMasterCostCategorySuccess = (data) => ({
  type: costCodesActionTypes.GET_MASTER_COSTCATEGORY_SUCCESS,
  payload: data,
});

export const getMasterCostCategoryFailure = (data) => ({
  type: costCodesActionTypes.GET_MASTER_COSTCATEGORY_FAILURE,
  payload: data,
});




export function getMasterCostCategoryAction(params, roleId) {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getMasterCostCategoryInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcategory/master`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getMasterCostCategorySuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getMasterCostCategoryFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getMasterCostCategorySuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getMasterCostCategoryFailure(err));
            reject(err);
          }
        });
    });
  };
}









export const getMasterCostCodeInitate = () => ({
  type: costCodesActionTypes.GET_MASTER_COSTCODE_INITIATE,
});

export const getMasterCostCodeSuccess = (data) => ({
  type: costCodesActionTypes.GET_MASTER_COSTCODE_SUCCESS,
  payload: data,
});

export const getMasterCostCodeFailure = (data) => ({
  type: costCodesActionTypes.GET_MASTER_COSTCODE_FAILURE,
  payload: data,
});




export function getMasterCostCodeAction() {
  const token = isLoggedIn("adminData1");
  return (dispatch) => {
    dispatch(getMasterCostCodeInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/costcode/master`,      
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getMasterCostCodeSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getMasterCostCodeFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(getMasterCostCodeSuccess(err.data));
            resolve(err.data);
          } else {
            dispatch(getMasterCostCodeFailure(err));
            reject(err);
          }
        });
    });
  };
}