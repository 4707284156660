import BlockFoundations from "./BlockFoundation/BlockFoundation";
import UpdatePerfomaGeneralDetail from "./UpdatePerfomaGeneralDetail";
import UnitPerforma from "./Unit/unit";

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCitiesList,
  getCountriesList,
  getStateList,
  getTimeZoneList,
} from "../../redux/action/authAction";
import { checkArray } from "../../utils/CheckType";
import {
  FoundationListListAction,
  ProformaTypesListAction,
  activeProforma,
  addProformaAction,
  addProformaBlockStep1Failure,
  addProformaBlockStep1aAction,
  createBlockFoundationStep1,
  createCommonAreaStep1,
  createCommonBuildingFoundationStep1,
  createCommonBuildingFoundationStep2,
  createCommonBuildingStep1,
  createCommonBuildingStep2,
  createCommonGarageStep1,
  createCommonGarageStep2,
  createLandDevelopmentStep1,
  createLandDevelopmentStep2,
  createMultiLevelStep1,
  createMultiLevelStep2,
  createProformaBlockStep2,
  createProjectsJobs,
  createUnitStep1,
  createUnitStep2,
  getBlockFoundationStep1,
  getBlockFoundationStep2,
  getBlockFoundationStep3,
  getCommonAreaStep1,
  getCommonAreaStep2,
  getCommonBuildingFoundationStep1,
  getCommonBuildingFoundationStep2,
  getCommonBuildingFoundationStep3,
  getCommonBuildingStep1,
  getCommonBuildingStep2,
  getCommonBuildingStep3,
  getCommonGarageStep1,
  getCommonGarageStep2,
  getCommonGarageStep3,
  getFoundationType,
  getLandDevelopmentStep1,
  getLandDevelopmentStep2,
  getLandDevelopmentStep3,
  getMultiLevelStep1,
  getMultiLevelStep2,
  getMultiLevelStep3,
  getProformaBlockStep2,
  getProformaBlockStep3,
  getProformaConfirmationSumarry,
  getUnitStep1,
  getUnitStep2,
  getUnitStep3,
  getUnitStep4,
  getUnitsList,
  getPerforma,
  getPerformastep1block,
} from "../../redux/action/proformaAction";
import MultifamilyJobStep1 from "../MultifamilyJob/MultifamilyJobStep1";
import PerformaBlock from "./Block/PerformaBlock";
import CommonArea from "./CommonArea/commonArea";
import CommonBuilding from "./commonBuilding/commonBuilding";
import CommonBuildingFoundation from "./commonBuildingFoundation/commonBuildingFoundation";
import CommonGarage from "./commonGarage/commonGarage";
import MultiLevelParkades from "./multiLevelParkade/multiLevelParkade";
import LandDevelopment from "./LandDevelopmentandAdminCost/landDevelopment";
import Conformation from "./conformation";
const initModal = {
  ProformaBlockStep1Modal: false,
  ProformaBlockStep2Modal: false,
  ProformaBlockStep3Modal: false,
  modalManagementState: "",

  blockQuantitiesData: [],
};

const initLeadState = {
  name: "",
  proformaTypeId: "",
  homeTypeId: "",
  stateCode: "",
  cityCode: "",
  jobTimeZone: "",
  country: "",
  projectName: "",
  firstStageErrors: {},
  blockFirstStepNumber: 1,
  blockfirstStep: [
    {
      blockDescription: "",
      foundationType: "",
      numOfUnits: "",
      numOfStories: "",
      foundationAndSubFloor: "",
      blockTotal: "",
      commonAreas: "",
      roof: "",
      exteriorWalls: "",
    },
  ],
};

const initialState = {
  ProformaBlockFoundationModal: false,
  ProformaBlockFoundationQuantityModal: false,
  ProformaBlockFoundationSummaryModal: false,
  UnitInputsModal: false,
  UnitOptionsInputsModal: false,
  UnitOptionQuantitiesModal: false,
  UnitSummaryPreviewModal: false,
  CommonAreasModal: false,
  FoundationSummaryPreviewModal: false,
  commonAreaUnit: [],
  CommonBuildingUnitsModal: false,
  CommonBuildingQuantitiesModal: false,
  LandDevlopmentCostModal: false,
  acres: "",
  LandDevlopmentQuantitiesModal: false,
  LandDevlopmentCostPreviewModal: false,
  ProformaConfirmSummaryModal: false,

  commonBuildingQuantities: [],
  CommonBuildingSummaryModal: false,

  CommonBuildingFoundationModal: false,
  CommonBuildingFoundationQuantitesModal: false,
  CommonBuildingFoundationSummaryModal: false,
  commonBuildingInput: [],
  CommonGarageInputModal: false,
  CommonGarageQuantitiesModal: false,
  CommonGarageSummaryModal: false,
  MultilevelParkadeModal: false,
  MultilevelParkadeQuantitiesModal: false,
  MulitileveleParkadeSummaryModal: false,

  commonBuildingFoundationInput: [],
  commonGarageQuantity: [],
  MulitileveleParkadeQuantiesData: [],
  LandDevlopmentQuantites: [],
};
const UpdatePerforma = ({
  UpdateProformaModal,
  setUpdateProformaModal,
  setShow,
  updateId,
}) => {
  const hideUpdateProformaModal = () => {
    handleShowComponent("details");
    setUpdateProformaModal(false);
    localStorage.removeItem("profomaId");
  };

  const [iModalState, updateModalState] = useState(initModal);
  const [iLeadState, updateLeadState] = useState(initLeadState);
  const [iState, updateState] = useState(initialState);
  const [MultifamilyJobStep1Modal, SetMultifamilyJobStep1Modal] =
    useState(false);

  const [perFormaId, setPerFomaId] = useState("");

  const {
    ProformaBlockFoundationModal,
    ProformaBlockFoundationQuantityModal,
    ProformaBlockFoundationSummaryModal,
    UnitInputsModal,
    UnitOptionsInputsModal,
    UnitOptionQuantitiesModal,
    UnitSummaryPreviewModal,
    CommonAreasModal,
    FoundationSummaryPreviewModal,
    commonAreaUnit,
    CommonBuildingUnitsModal,
    CommonBuildingQuantitiesModal,
    LandDevlopmentCostModal,
    acres,
    LandDevlopmentQuantitiesModal,
    LandDevlopmentCostPreviewModal,
    ProformaConfirmSummaryModal,

    commonBuildingQuantities,
    CommonBuildingSummaryModal,

    CommonBuildingFoundationModal,
    CommonBuildingFoundationQuantitesModal,
    CommonBuildingFoundationSummaryModal,
    commonBuildingInput,
    CommonGarageInputModal,
    CommonGarageQuantitiesModal,
    CommonGarageSummaryModal,
    MultilevelParkadeModal,
    MultilevelParkadeQuantitiesModal,
    MulitileveleParkadeSummaryModal,

    commonBuildingFoundationInput,
    commonGarageQuantity,
    MulitileveleParkadeQuantiesData,
    LandDevlopmentQuantites,
  } = iState;

  const { state } = useLocation();
  const {
    ProformaBlockStep1Modal,
    ProformaBlockStep2Modal,
    modalManagementState,
    ProformaBlockStep3Modal,
    blockQuantitiesData,
  } = iModalState;
  const {
    name,
    proformaTypeId,
    homeTypeId,
    stateCode,
    cityCode,
    jobTimeZone,
    country,
    firstStageErrors,
    projectName,
    blockfirstStep,
    blockFirstStepNumber,
  } = iLeadState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { countryData, cityData, stateData, timeZoneData } = useSelector(
    (state) => state.authReducer
  );
  const {
    proformaTypesListData,
    foundationListData,
    blockFoundationStep1List,
    blockFoundationStep2List,
    blockFoundationStep3List,
    unitStep1List,
    unitStep2List,
    unitStep3List,
    unitStep4List,
    commonAreaStep1List,
    commonAreaStep2List,
    commonBuildingStep1List,
    commonBuildingStep2List,
    commonBuildingStep3List,
    landDevelopmentStep1List,
    landDevelopmentStep2List,
    landDevelopmentStep3List,
    proformaConfirmSummaryList,
    foundationTypeData,
    unitList,
    proformaBlockStep2Data,
    proformaBlockStep3Data,

    commonBuildingFoundationStep1List,
    commonBuildingFoundationStep2List,
    commonBuildingFoundationStep3List,
    commonGarageStep1List,
    commonGarageStep2List,
    commonGarageStep3List,
    multiLevelStep1List,
    multiLevelStep2List,
    multiLevelStep3List,
  } = useSelector((state) => state.ProformaReducer);
  // console.log("foundationTypeData", foundationTypeData);
  // console.log("proformaBlockStep3Data", proformaBlockStep3Data);
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };
  const [showcreateModal, setShowCreateModal] = useState(true);

  // useEffect(()=>{
  //   console.log("sss**************************");
  //   dispatch(getPerforma(getdataBack.id)).then((data) => {
  //     console.log(data,"function is calll");

  //     updateLeadState({
  //       ...iLeadState,
  //       name: data.data.data.name,
  //       proformaTypeId: data.data.data.proformaTypeId,
  //       homeTypeId: data.data.data.homeTypeId,
  //       stateCode: data.data.data.stateCode,
  //       cityCode: data.data.data.cityCode,
  //       jobTimeZone: data.data.data.jobTimeZone,
  //       projectName: data.data.data.projectName,
  //     });
  //   });
  // },[])
  // useEffect(() => {
  //   console.log("ssss");
  //   if (!getdataBack.count) {
  //     dispatch(getPerforma(getdataBack.id)).then((data) => {
  //       console.log(data,"function is calll");
  //       setGetDataBack((prev) => ({
  //         ...prev,
  //         count: true,
  //       }));
  //       updateLeadState({
  //         ...iLeadState,
  //         name: data.data.data.name,
  //         proformaTypeId: data.data.data.proformaTypeId,
  //         homeTypeId: data.data.data.homeTypeId,
  //         stateCode: data.data.data.stateCode,
  //         cityCode: data.data.data.cityCode,
  //         jobTimeZone: data.data.data.jobTimeZone,
  //         projectName: data.data.data.projectName,
  //       });
  //     });
  //   }
  // }, []);

  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    if (name === "blockFirstStepNumber") {
      // console.log("value--->", value, name);
      let tempArray = new Array(+value).fill({
        blockDescription: "",
        foundationType: "",
        numOfUnits: "",
        numOfStories: "",
        foundationAndSubFloor: "",
        blockTotal: "",
        commonAreas: "",
        roof: "",
        exteriorWalls: "",
      });
      // console.log("tempArray", tempArray);
      //
      updateLeadState({
        ...iLeadState,
        [name]: result,
        blockfirstStep: tempArray,
      });
    } else {
      updateLeadState({ ...iLeadState, [name]: result });
    }
  };

  // const [numBlocks, setNumBlocks] = useState(1);
  // const [buildings, setBuildings] = useState([
  //   {
  //     id: 1,
  //     buildingName: "",
  //     foundationType: "",
  //     buildingSqFt: "",
  //     concreteCubicYards: "",
  //     foundationSqFt: "",
  //     drivewaysAndSidewalksSqFt: "",
  //     drivewaysAndSidewalksCubicYards: "",
  //   },
  // ]);

  // const handleNumBlocksChange = (e) => {
  //   const newNumBlocks = parseInt(e.target.value, 10);
  //   setNumBlocks(newNumBlocks > 0 ? newNumBlocks : 1);
  //   // Update buildings array length based on the number of blocks
  //   setBuildings((prevBuildings) => {
  //     const updatedBuildings = [...prevBuildings];
  //     while (updatedBuildings.length < newNumBlocks) {
  //       const newIndex = updatedBuildings.length + 1;
  //       updatedBuildings.push({
  //         id: newIndex,
  //         buildingName: "",
  //         foundationType: "",
  //         buildingSqFt: "",
  //         concreteCubicYards: "",
  //         foundationSqFt: "",
  //         drivewaysAndSidewalksSqFt: "",
  //         drivewaysAndSidewalksCubicYards: "",
  //       });
  //     }
  //     return updatedBuildings.slice(0, newNumBlocks);
  //   });
  // };

  // const handleBuildingFieldChange = (e, index, fieldName) => {
  //   const { value } = e.target;
  //   setBuildings((prevBuildings) => {
  //     const updatedBuildings = [...prevBuildings];
  //     updatedBuildings[index] = {
  //       ...updatedBuildings[index],
  //       [fieldName]: value,
  //     };
  //     return updatedBuildings;
  //   });
  // };

  // const handleCreateCommonBuildingStep1 = () => {
  //   const dataa = {
  //     noOfBuildings: numBlocks,
  //     buildings,
  //   };
  //   dispatch(createCommonBuildingStep1(dataa)).then((res) => {
  //     if (res.status === 201) {
  //       toast.success("Updated Successfully", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 1000,
  //       });
  //       updateState({
  //         ...iState,
  //         CommonBuildingUnitsModal: false,
  //         CommonBuildingQuantitiesModal: true,
  //       });
  //     } else {
  //       toast.error(res.data.message, {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       updateState({ ...iState, CommonBuildingUnitsModal: true });
  //     }
  //   });
  // };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: checked });
    } else {
      updateLeadState({ ...iLeadState, [name]: false });
    }
  };

  useEffect(() => {
    dispatch(getCountriesList());
    dispatch(getTimeZoneList());
    dispatch(ProformaTypesListAction());
    dispatch(FoundationListListAction());
    dispatch(getUnitsList());
  }, []);

  const hideMainCreateModal = () => {
    handleShowComponent("details");
    setUpdateProformaModal(false);
  };

  const handleFirstStageValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }

    if (!proformaTypeId) {
      error.proformaTypeIdError = "Proforma Type can't be empty";
      formIsValid = false;
    }

    if (!homeTypeId) {
      error.homeTypeIdError = "Home type can't be empty";
      formIsValid = false;
    }

    if (!stateCode) {
      error.stateCodeError = "State can't be empty";
      formIsValid = false;
    }

    if (!cityCode) {
      error.cityCodeError = "City can't be empty";
      formIsValid = false;
    }
    if (!jobTimeZone) {
      error.jobTimeZoneError = "Timezone can't be empty";
      formIsValid = false;
    }

    if (!projectName) {
      error.projectNameError = "Project can't can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, firstStageErrors: error });
    return formIsValid;
  };

  const firstStepCreateFunc = () => {
    let formIsValid = handleFirstStageValidation();
    // if (formIsValid) {
    let data = {
      name,
      proformaTypeId: +proformaTypeId,
      homeTypeId: +homeTypeId,
      stateCode,
      cityCode,
      // jobTimeZone:
      jobTimeZone: "2024-01-06T12:00:00Z",
      // country,
      projectName,
    };
    dispatch(addProformaAction(data)).then((res) => {
      // console.log("resss", res);
      window.localStorage.setItem(
        "profomaId",
        JSON.stringify(res?.data?.data?.id)
      );
      showProformaBlockStep1Modal();
      updateLeadState({
        ...iLeadState,
        name: "",
        proformaTypeId: "",
        homeTypeId: "",
        stateCode: "",
        cityCode: "",
        projectName: "",
        jobTimeZone: "",
      });
    });
    // }
  };

  const blockListStep1Func = (e) => {
    e.preventDefault();
    const dataa = {
      noOfBlocks: Number(blockFirstStepNumber),
      blocks: blockfirstStep,
    };
    dispatch(addProformaBlockStep1aAction(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Added Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          ProformaBlockStep1Modal: false,
          ProformaBlockStep2Modal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ProformaBlockStep1Modal: true });
      }
    });
  };

  // ProformaBlockStep1Modal
  const showProformaBlockStep1Modal = () => {
    if (handleFirstStageValidation()) {
      updateModalState({ ...iModalState, ProformaBlockStep1Modal: true });
      setUpdateProformaModal(false);
      handleShowComponent("details");
    } else {
    }
  };
  const hideProformaBlockStep1Modal = () => {
    updateModalState({ ...iModalState, ProformaBlockStep1Modal: false });
    setUpdateProformaModal(true);
    handleShowComponent("details");
  };

  const handleBlockFirstStepChange = (e, i) => {
    const { name, value } = e?.target;
    let tempBlockFirstStep = [...blockfirstStep];

    tempBlockFirstStep[i] = { ...blockfirstStep[i], [name]: value };
    updateLeadState({ ...iLeadState, blockfirstStep: tempBlockFirstStep });
  };

  // const blockListStep1Func = (e) => {
  //   e.preventDefault();
  //   dispatch(addProformaBlockStep1aAction(state, blockfirstStep)).then(
  //     (res) => {
  //       // console.log("re=eeeee", res);
  //     }
  //   );
  // };

  const hideProformaBlockStep2Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep2Modal: false,
      ProformaBlockStep1Modal: true,
    });
  };

  const showProformaBlockStep2Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep2Modal: true,
      ProformaBlockStep1Modal: false,
    });
  };

  useEffect(() => {
    if (ProformaBlockStep2Modal) {
      dispatch(getProformaBlockStep2()).then((res) => {
        if (res.status === 200) {
          updateModalState({
            ...iModalState,
            blockQuantitiesData: proformaBlockStep2Data?.data,
          });
        }
      });
    }
  }, [ProformaBlockStep2Modal]);

  const handleBlockQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...blockQuantitiesData];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateModalState({ ...iModalState, blockQuantitiesData: dummyQuantity });
  };

  const handleCreateBlockStep2 = () => {
    const dataa = blockQuantitiesData?.flatMap((item) =>
      item?.ProformaBaseCosts?.flatMap((itm) =>
        itm?.ProformaBlockQuantities?.map((itemm) => ({
          id: itemm.id,
          quantity: itemm.quantity,
          unitId: itemm.unitId,
        }))
      )
    );
    dispatch(createProformaBlockStep2(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateModalState({
          ...iModalState,
          ProformaBlockStep2Modal: false,
          ProformaBlockStep3Modal: true,
          blockQuantitiesData: [],
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateModalState({ ...iModalState, ProformaBlockStep2Modal: true });
      }
    });
  };

  const hideProformaBlockStep3Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: false,
      ProformaBlockStep2Modal: true,
    });
  };

  useEffect(() => {
    if (ProformaBlockStep3Modal) {
      dispatch(getProformaBlockStep3());
    }
  }, [ProformaBlockStep3Modal]);

  const showProformaBlockStep3Modal = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: true,
      ProformaBlockStep2Modal: false,
    });
  };

  //===========================blockFoundation===================================

  const handleProformaBlockFoundationModalShow = () => {
    updateModalState({
      ...iModalState,
      ProformaBlockStep3Modal: false,
    });
    updateState({
      ...iState,
      ProformaBlockFoundationModal: true,
    });
    dispatch(getBlockFoundationStep1());
  };

  const handleProformaBlockFoundationModalClose = () => {
    updateState({
      ...iModalState,
      ProformaBlockStep3Modal: true,
    });
    updateState({
      ...iState,
      ProformaBlockFoundationModal: false,
    });
  };

  const [foundationValues, setFoundationValues] = useState([]);
  const [changedValues, setChangedValues] = useState([]);

  useEffect(() => {
    if (blockFoundationStep1List?.data?.length > 0) {
      const initialValues = blockFoundationStep1List?.data.map((data) => ({
        id: data.id,
        blocks: data.FoundationPerimeters?.flatMap(
          (item) =>
            item.BlockFoundations?.map((block) => ({
              id: block.id,
              value: block.defaultValue || "",
            })) || []
        ),
      }));
      setFoundationValues(initialValues);
    }
  }, [blockFoundationStep1List?.data]);

  const handleInputChange = (foundationId, blockId, value) => {
    setFoundationValues((prevValues) =>
      prevValues.map((foundation) =>
        foundation.id === foundationId
          ? {
              ...foundation,
              blocks: (foundation.blocks || []).map((block) =>
                block.id === blockId ? { ...block, value } : block
              ),
            }
          : foundation
      )
    );

    // Update changed values state
    const updatedValues = [...changedValues];
    const existingIndex = updatedValues.findIndex(
      (item) => item.id === blockId
    );

    if (existingIndex !== -1) {
      updatedValues[existingIndex] = { id: blockId, value };
    } else {
      updatedValues.push({ id: blockId, value });
    }

    setChangedValues(updatedValues);
  };

  const handleCreateBlockFoundationStep1 = () => {
    const dataa = {
      changedValues,
    };
    dispatch(createBlockFoundationStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ProformaBlockFoundationModal: false,
          ProformaBlockFoundationQuantityModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ProformaBlockFoundationModal: true });
      }
    });
  };

  const handleProformaBlockFoundationModalQuantityShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationQuantityModal: true,
      ProformaBlockFoundationModal: false,
    });
    dispatch(getBlockFoundationStep2());
  };

  const handleProformaBlockFoundationModalQuantityClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationModal: true,
      ProformaBlockFoundationQuantityModal: false,
    });
  };

  const handleProformaBlockFoundationModalSummaryShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationQuantityModal: false,
      ProformaBlockFoundationSummaryModal: true,
    });
    dispatch(getBlockFoundationStep3());
  };

  const handleProformaBlockFoundationModalSummaryClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: false,
      ProformaBlockFoundationQuantityModal: true,
    });
  };

  const handleUnitInputsModalShow = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: false,
      UnitInputsModal: true,
    });
  };

  const handleUnitInputsModalClose = () => {
    updateState({
      ...iState,
      ProformaBlockFoundationSummaryModal: true,
      UnitInputsModal: false,
    });
  };
  const [unitTypes, setUnitTypes] = useState([]);
  useEffect(() => {
    if (UnitInputsModal) {
      dispatch(getUnitStep1());
      const newData = unitStep1List?.data?.table1?.units?.map(
        (item, index) => ({
          id: item?.id,
          value: item.UnitOptions?.[0]?.unitValue,
          type: item.type,
          bedRooms: item.bedRooms,
          bathRooms: item.bathRooms,
        })
      );
      setUnitTypes(newData);
    }
  }, [UnitInputsModal]);
  // console.log("unitTypes", unitTypes);

  const handleUnitChange = (event, index) => {
    const { name, value } = event.target;

    // Update the corresponding value in the state
    setUnitTypes((prevUnitTypes) => {
      const updatedUnitTypes = [...prevUnitTypes];
      updatedUnitTypes[index] = { ...updatedUnitTypes[index], value: value };
      return updatedUnitTypes;
    });
  };

  const handleCreateUnitsInputStep1 = () => {
    const dataa = {
      unitTypes: unitTypes?.map((data) => ({
        id: data.id,
        value: data.value,
      })),
      multiUnits: [],
    };
    dispatch(createUnitStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UnitInputsModal: false,
          UnitOptionsInputsModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UnitInputsModal: true });
      }
    });
  };

  const handleUnitOptionsInputsModalShow = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: true,
      UnitInputsModal: false,
    });
  };

  const [unitOption, setUnitOption] = useState([]);

  // console.log("unitOption", unitOption);
  useEffect(() => {
    if (UnitOptionsInputsModal) {
      dispatch(getUnitStep2());
      setUnitOption([
        {
          id: unitStep2List?.data?.[0]?.id,
          totalSqFt: unitStep2List?.data?.[0]?.totalSqFt,
          name: unitStep2List?.data?.[0]?.name,
        },
        {
          id: unitStep2List?.data?.[1]?.id,
          totalSqFt: unitStep2List?.data?.[1]?.totalSqFt,
          name: unitStep2List?.data?.[1]?.name,
          totalCubicYards: unitStep2List?.data?.[1]?.totalCubicYards,
        },
        {
          id: unitStep2List?.data?.[2]?.id,
          totalSqFt: unitStep2List?.data?.[2]?.totalSqFt,
          name: unitStep2List?.data?.[2]?.name,
          totalCubicYards: unitStep2List?.data?.[2]?.totalCubicYards,
        },
        {
          id: unitStep2List?.data?.[3]?.id,
          totalSqFt: unitStep2List?.data?.[3]?.totalSqFt,
          name: unitStep2List?.data?.[3]?.name,
          totalCubicYards: unitStep2List?.data?.[3]?.totalCubicYards,
        },
        {
          id: unitStep2List?.data?.[4]?.id,
          totalSqFt: unitStep2List?.data?.[4]?.totalSqFt,
          name: unitStep2List?.data?.[4]?.name,
          totalCubicYards: unitStep2List?.data?.[4]?.totalCubicYards,
        },
        {
          id: unitStep2List?.data?.[5]?.id,
          totalSqFt: unitStep2List?.data?.[5]?.totalSqFt,
          name: unitStep2List?.data?.[5]?.name,
          totalCubicYards: unitStep2List?.data?.[5]?.totalCubicYards,
          perimeterLinFt: unitStep2List?.data?.[5]?.perimeterLinFt,
        },
      ]);
    }
  }, [UnitOptionsInputsModal]);

  const handleUnitOptionChange = (event, index) => {
    const { name, value } = event.target;

    // Update the corresponding value in the state
    setUnitOption((prevUnitTypes) => {
      const updatedUnitTypes = [...prevUnitTypes];
      updatedUnitTypes[index] = { ...updatedUnitTypes[index], [name]: value };
      return updatedUnitTypes;
    });
  };

  const handleUnitOptionsInputsModalClose = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: false,
      UnitInputsModal: true,
    });
  };

  const handleCreateUnitsInputStep2 = () => {
    const dataa = {
      unitTypes: unitOption?.map((data) => ({
        id: data?.id,
        totalSqFt: data?.totalSqFt ?? 0,
        totalCubicYards: data?.totalCubicYards ?? 0,
        perimeterLinFt: data?.perimeterLinFt ?? 0,
      })),
    };
    dispatch(createUnitStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UnitOptionsInputsModal: false,
          UnitOptionQuantitiesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, UnitOptionsInputsModal: true });
      }
    });
  };

  const handleUnitOptionQuantitiesModalShow = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: false,
      UnitOptionQuantitiesModal: true,
    });
    // dispatch(getUnitStep2());
  };

  const [unitOptionQuantities, setUnitOptionQuantities] = useState([]);

  // console.log("unitOption", unitOption);
  useEffect(() => {
    if (UnitOptionQuantitiesModal) {
      dispatch(getUnitStep3());
      // setUnitOption([
      //   {
      //     id: unitStep2List?.data?.[0]?.id,
      //     totalSqFt: unitStep2List?.data?.[0]?.totalSqFt,
      //     name: unitStep2List?.data?.[0]?.name,
      //   },
      //   {
      //     id: unitStep2List?.data?.[1]?.id,
      //     totalSqFt: unitStep2List?.data?.[1]?.totalSqFt,
      //     name: unitStep2List?.data?.[1]?.name,
      //     totalCubicYards: unitStep2List?.data?.[1]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[2]?.id,
      //     totalSqFt: unitStep2List?.data?.[2]?.totalSqFt,
      //     name: unitStep2List?.data?.[2]?.name,
      //     totalCubicYards: unitStep2List?.data?.[2]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[3]?.id,
      //     totalSqFt: unitStep2List?.data?.[3]?.totalSqFt,
      //     name: unitStep2List?.data?.[3]?.name,
      //     totalCubicYards: unitStep2List?.data?.[3]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[4]?.id,
      //     totalSqFt: unitStep2List?.data?.[4]?.totalSqFt,
      //     name: unitStep2List?.data?.[4]?.name,
      //     totalCubicYards: unitStep2List?.data?.[4]?.totalCubicYards,
      //   },
      //   {
      //     id: unitStep2List?.data?.[5]?.id,
      //     totalSqFt: unitStep2List?.data?.[5]?.totalSqFt,
      //     name: unitStep2List?.data?.[5]?.name,
      //     totalCubicYards: unitStep2List?.data?.[5]?.totalCubicYards,
      //     perimeterLinFt: unitStep2List?.data?.[5]?.perimeterLinFt,
      //   },
      // ])
    }
  }, [UnitOptionQuantitiesModal]);

  const handleUnitOptionQuantitiesModalClose = () => {
    updateState({
      ...iState,
      UnitOptionsInputsModal: true,
      UnitOptionQuantitiesModal: false,
    });
  };

  const handleUnitSummaryPreviewModalShow = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: true,
      UnitOptionQuantitiesModal: false,
    });
    // dispatch(getUnitStep2());
  };

  useEffect(() => {
    if (UnitSummaryPreviewModal) {
      dispatch(getUnitStep4());
    }
  }, [UnitSummaryPreviewModal]);

  const handleUnitSummaryPreviewModalClose = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: false,
      UnitOptionQuantitiesModal: true,
    });
  };

  const handleCommonAreasModalShow = () => {
    updateState({
      ...iState,
      CommonAreasModal: true,
      UnitSummaryPreviewModal: false,
    });
    // dispatch(getUnitStep2());
  };

  useEffect(() => {
    if (CommonAreasModal) {
      dispatch(getCommonAreaStep1());
      updateState({ ...iState, commonAreaUnit: commonAreaStep1List?.data });
    }
  }, [CommonAreasModal]);
  const handleCommonAreasModalClose = () => {
    updateState({
      ...iState,
      UnitSummaryPreviewModal: true,
      CommonAreasModal: false,
    });
  };

  const handleMultiStorieschange = (e, i, index) => {
    const { name, value } = e.target;
    const newList = [...commonAreaUnit];
    newList[index].MultiStories[i] = {
      ...newList[index].MultiStories[i],
      [name]: value,
    };
    updateState({ ...iState, commonAreaUnit: newList });
  };

  // console.log("commonAreaUnit", commonAreaUnit);

  const handleCreateCommonAreaUnitStep1 = () => {
    const dataa = {
      unitTypes: commonAreaUnit?.flatMap(
        (data) =>
          data.MultiStories?.map((x) => ({
            id: x.id,
            value: x.value,
          })) ?? []
      ),
    };
    dispatch(createCommonAreaStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonAreasModal: false,
          FoundationSummaryPreviewModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonAreasModal: true });
      }
    });
  };

  const handleFoundationSummaryPreviewModalShow = () => {
    updateState({
      ...iState,
      CommonAreasModal: false,
      FoundationSummaryPreviewModal: true,
    });
  };

  useEffect(() => {
    if (FoundationSummaryPreviewModal) {
      dispatch(getCommonAreaStep2());
    }
  }, [FoundationSummaryPreviewModal]);

  const handleFoundationSummaryPreviewModalClose = () => {
    updateState({
      ...iState,
      FoundationSummaryPreviewModal: false,
      CommonAreasModal: true,
    });
  };

  const [numBlocks, setNumBlocks] = useState(1);
  const [buildings, setBuildings] = useState([
    {
      id: 1,
      buildingName: "",
      foundationType: "",
      buildingSqFt: "",
      concreteCubicYards: "",
      foundationSqFt: "",
      drivewaysAndSidewalksSqFt: "",
      drivewaysAndSidewalksCubicYards: "",
    },
  ]);

  const handleNumBlocksChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNumBlocks(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setBuildings((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          buildingName: "",
          foundationType: "",
          buildingSqFt: "",
          concreteCubicYards: "",
          foundationSqFt: "",
          drivewaysAndSidewalksSqFt: "",
          drivewaysAndSidewalksCubicYards: "",
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleBuildingFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setBuildings((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      return updatedBuildings;
    });
  };

  const handleCreateCommonBuildingStep1 = () => {
    const dataa = {
      noOfBuildings: numBlocks,
      buildings,
    };
    dispatch(createCommonBuildingStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingUnitsModal: false,
          CommonBuildingQuantitiesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingUnitsModal: true });
      }
    });
  };

  const handleCommonBuildingUnitsModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingUnitsModal: true,
      FoundationSummaryPreviewModal: false,
    });
  };

  useEffect(() => {
    if (CommonBuildingUnitsModal) {
      dispatch(getCommonBuildingStep1());
      dispatch(getFoundationType());
    }
  }, [CommonBuildingUnitsModal]);

  const handleCommonBuildingUnitsModalClose = () => {
    updateState({
      ...iState,
      FoundationSummaryPreviewModal: true,
      CommonBuildingUnitsModal: false,
    });
  };

  const handleCommonBuildingQuantitiesModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingUnitsModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  useEffect(() => {
    if (CommonBuildingQuantitiesModal) {
      dispatch(getCommonBuildingStep2());
      updateState({
        ...iState,
        commonBuildingQuantities: commonBuildingStep2List?.data,
      });
    }
  }, [CommonBuildingQuantitiesModal]);

  const handleQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonBuildingQuantities];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingQuantities: dummyQuantity });
  };

  const handleCommonBuildingQuantitiesModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingQuantitiesModal: false,
      CommonBuildingUnitsModal: true,
    });
  };

  const handleCreateCommonBuildingStep2 = () => {
    const dataa = {
      commonBuildingQuantities: commonBuildingQuantities?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };
    dispatch(createCommonBuildingStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingSummaryModal: true,
          CommonBuildingQuantitiesModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingQuantitiesModal: true });
      }
    });
  };

  const handleCommonBuildingSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingSummaryModal: true,
      CommonBuildingQuantitiesModal: false,
    });
  };
  useEffect(() => {
    if (CommonBuildingSummaryModal) {
      dispatch(getCommonBuildingStep3());
    }
  }, [CommonBuildingSummaryModal]);
  const handleCommonBuildingSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingSummaryModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  const handleCommonBuildingFoundationModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: true,
      CommonBuildingQuantitiesModal: false,
    });
  };
  useEffect(() => {
    if (CommonBuildingFoundationModal) {
      dispatch(getCommonBuildingFoundationStep1());
      updateState({
        ...iState,
        commonBuildingInput: commonBuildingFoundationStep1List?.data,
      });
    }
  }, [CommonBuildingFoundationModal]);

  // console.log("commonBuildingInput", commonBuildingInput);
  const handleCommonBuildingFoundationModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: false,
      CommonBuildingQuantitiesModal: true,
    });
  };

  const handleCommonBuildingUnitChange = (e, i, ind, index) => {
    const { name, value } = e.target;
    const newList = [...commonBuildingInput];
    commonBuildingInput[i].FoundationPerimeters[ind].BlockFoundations[index] = {
      ...commonBuildingInput[i].FoundationPerimeters[ind].BlockFoundations[
        index
      ],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingInput: newList });
  };

  const handleCreateCommonBuildingFoundationStep1 = () => {
    const dataa = {
      commonBuildingQuantities: commonBuildingInput?.flatMap((item) =>
        item?.FoundationPerimeters.flatMap((itm) =>
          itm?.BlockFoundations.map((itemm) => {
            return { id: itemm.id, value: Number(itemm.value) };
          })
        )
      ),
    };
    dispatch(createCommonBuildingFoundationStep1(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingFoundationQuantitesModal: true,
          CommonBuildingFoundationModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonBuildingFoundationModal: true });
      }
    });
  };

  const handleCommonBuildingFoundationQuantitesModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: false,
      CommonBuildingFoundationQuantitesModal: true,
    });
  };

  useEffect(() => {
    if (CommonBuildingFoundationQuantitesModal) {
      dispatch(getCommonBuildingFoundationStep2());
      updateState({
        ...iState,
        commonBuildingFoundationInput: commonBuildingFoundationStep2List?.data,
      });
    }
  }, [CommonBuildingFoundationQuantitesModal]);

  const handleCommonBuildingfoundationChange = (e, ind, inde, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonBuildingFoundationInput];
    dummyQuantity[ind].ProformaCostHeadings[inde].ProformaBaseCosts[
      i
    ].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaCostHeadings[inde].ProformaBaseCosts[i]
        .ProformaBlockQuantities[blocId],
      [name]: value,
    };
    updateState({ ...iState, commonBuildingFoundationInput: dummyQuantity });
  };

  const handleCreateCommonBuildingFoundationStep2 = () => {
    const dataa = {
      commonBuildingFoundationInput: commonBuildingFoundationInput?.flatMap(
        (item) =>
          item?.ProformaCostHeadings?.flatMap((itm) =>
            itm?.ProformaBaseCosts?.flatMap((itemm) =>
              itemm?.ProformaBlockQuantities?.map((items) => ({
                id: items.id,
                quantity: Number(items.quantity),
              }))
            )
          )
      ),
    };
    dispatch(createCommonBuildingFoundationStep2(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonBuildingFoundationQuantitesModal: false,
          CommonBuildingFoundationSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          CommonBuildingFoundationQuantitesModal: true,
        });
      }
    });
  };

  const handleCommonBuildingFoundationQuantitesModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationModal: true,
      CommonBuildingFoundationQuantitesModal: false,
    });
  };

  const handleCommonBuildingFoundationSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: true,
      CommonBuildingFoundationQuantitesModal: false,
    });
  };

  useEffect(() => {
    if (CommonBuildingFoundationSummaryModal) {
      dispatch(getCommonBuildingFoundationStep3());
    }
  }, [CommonBuildingFoundationSummaryModal]);

  const handleCommonBuildingFoundationSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: false,
      CommonBuildingFoundationQuantitesModal: true,
    });
  };

  const handleCommonGarageInputModalShow = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: false,
      CommonGarageInputModal: true,
    });
  };

  useEffect(() => {
    if (CommonGarageInputModal) {
      dispatch(getCommonGarageStep1());
    }
  }, [CommonGarageInputModal]);

  const [numBlocksGarage, setNumBlocksGarage] = useState(1);
  const [garage, setGarage] = useState([
    {
      id: 1,
      garageName: "",
      garageSqFt: "",
      garagePerimeterLinFt: "",
      garageCubicYard: "",
      drivewaysAndSideWalkSqFt: "",
      drivew: "",
    },
  ]);

  const handleNumGarageBlocksChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNumBlocksGarage(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setGarage((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          garageName: "",
          garageSqFt: "",
          garagePerimeterLinFt: "",
          garageCubicYard: "",
          drivewaysAndSideWalkSqFt: "",
          drivew: "",
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleBuildingGarageFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setGarage((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      return updatedBuildings;
    });
  };

  const handleCreateCommonGarageStep1 = () => {
    const dataa = {
      noOfCommonGarage: numBlocksGarage,
      garages: garage,
    };
    dispatch(createCommonGarageStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonGarageInputModal: false,
          CommonGarageQuantitiesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonGarageInputModal: true });
      }
    });
  };

  const handleCommonGarageInputModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingFoundationSummaryModal: true,
      CommonGarageInputModal: false,
    });
  };

  const handleCommonGarageQuantitiesModalShow = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageInputModal: false,
    });
  };

  useEffect(() => {
    if (CommonGarageQuantitiesModal) {
      dispatch(getCommonGarageStep2());
      updateState({
        ...iState,
        commonGarageQuantity: commonGarageStep2List?.data,
      });
    }
  }, [CommonGarageQuantitiesModal]);

  const handlecommonGarageQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...commonGarageQuantity];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, commonGarageQuantity: dummyQuantity });
  };

  const handleCreateCommonGarageStep2 = () => {
    const dataa = {
      commonGarageQuantity: commonGarageQuantity?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };

    dispatch(createCommonGarageStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          CommonGarageQuantitiesModal: false,
          CommonGarageSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, CommonGarageQuantitiesModal: true });
      }
    });
  };
  const handleCommonGarageQuantitiesModalClose = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageInputModal: false,
    });
  };

  const handleCommonGarageSummaryModalShow = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: false,
      CommonGarageSummaryModal: true,
    });
  };

  useEffect(() => {
    if (CommonGarageSummaryModal) {
      dispatch(getCommonGarageStep3());
    }
  }, [CommonGarageSummaryModal]);

  const handleCommonGarageSummaryModalClose = () => {
    updateState({
      ...iState,
      CommonGarageQuantitiesModal: true,
      CommonGarageSummaryModal: false,
    });
  };

  const handleMultilevelParkadeModalShow = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: true,
      CommonGarageSummaryModal: false,
    });
  };

  useEffect(() => {
    if (MultilevelParkadeModal) {
      dispatch(getMultiLevelStep1());
    }
  }, [MultilevelParkadeModal]);

  const [multiLevelParkade, setMultiLevelParkade] = useState(false);

  const handleRadioChange = (value) => {
    setMultiLevelParkade(value);
  };

  const [noOfMultiParkade, setNoOfMultiParkade] = useState(1);
  const [parkades, setParkades] = useState([
    {
      id: 1,
      footingTotalSqFt: "",
      footingTotalLinFt: "",
      footingYardsConcrete: "",
      wallYardsConcrete: "",
      slabYardsConcrete: "",
      heatedParking: true,
    },
  ]);

  const handleMultiParkadeChange = (e) => {
    const newNumBlocks = parseInt(e.target.value, 10);
    setNoOfMultiParkade(newNumBlocks > 0 ? newNumBlocks : 1);
    // Update buildings array length based on the number of blocks
    setParkades((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      while (updatedBuildings.length < newNumBlocks) {
        const newIndex = updatedBuildings.length + 1;
        updatedBuildings.push({
          id: newIndex,
          footingTotalSqFt: "",
          footingTotalLinFt: "",
          footingYardsConcrete: "",
          wallYardsConcrete: "",
          slabYardsConcrete: "",
          heatedParking: true,
        });
      }
      return updatedBuildings.slice(0, newNumBlocks);
    });
  };

  const handleMultiParkadeFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setParkades((prevBuildings) => {
      const updatedBuildings = [...prevBuildings];
      updatedBuildings[index] = {
        ...updatedBuildings[index],
        [fieldName]: value,
      };
      return updatedBuildings;
    });
  };

  const handleCreateMultiParkadeStep1 = () => {
    const dataa = {
      noOfMultiParkade,
      parkades,
      multiLevelParkade,
    };
    dispatch(createMultiLevelStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          MultilevelParkadeModal: false,
          MultilevelParkadeQuantitiesModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, MultilevelParkadeModal: true });
      }
    });
  };

  const handleMultilevelParkadeModalClose = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: false,
      CommonGarageSummaryModal: true,
    });
  };

  const handleMultilevelParkadeQuantitiesModalShow = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: false,
      MultilevelParkadeQuantitiesModal: true,
    });
  };

  useEffect(() => {
    if (MultilevelParkadeQuantitiesModal) {
      dispatch(getMultiLevelStep2());
      updateState({
        ...iState,
        MulitileveleParkadeQuantiesData: multiLevelStep2List?.data,
      });
    }
  }, [MultilevelParkadeQuantitiesModal]);

  const handleMultiLevelParkadeChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...MulitileveleParkadeQuantiesData];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, MulitileveleParkadeQuantiesData: dummyQuantity });
  };

  const handleCreateMultilLevelParkadeStep2 = () => {
    const dataa = {
      MulitileveleParkadeQuantiesData: MulitileveleParkadeQuantiesData?.flatMap(
        (item) =>
          item?.ProformaBaseCosts.flatMap((itm) =>
            itm?.ProformaBlockQuantities.map((itemm) => {
              return { id: itemm.id, quantity: itemm.quantity };
            })
          )
      ),
    };

    dispatch(createMultiLevelStep2(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          MultilevelParkadeQuantitiesModal: false,
          MulitileveleParkadeSummaryModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, MultilevelParkadeQuantitiesModal: true });
      }
    });
  };

  const handleMultilevelParkadeQuantitiesModalClose = () => {
    updateState({
      ...iState,
      MultilevelParkadeModal: true,
      MultilevelParkadeQuantitiesModal: false,
    });
  };

  const handleMulitileveleParkadeSummaryModalShow = () => {
    updateState({
      ...iState,
      MulitileveleParkadeSummaryModal: true,
      MultilevelParkadeQuantitiesModal: false,
    });
  };

  useEffect(() => {
    if (MulitileveleParkadeSummaryModal) {
      dispatch(getMultiLevelStep3());
    }
  }, [MulitileveleParkadeSummaryModal]);

  const handleMulitileveleParkadeSummaryModalClose = () => {
    updateState({
      ...iState,
      MulitileveleParkadeSummaryModal: false,
      MultilevelParkadeQuantitiesModal: true,
    });
  };

  const handleLandDevlopmentCostModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentCostModal: true,
      MulitileveleParkadeSummaryModal: false,
    });
  };

  useEffect(() => {
    if (LandDevlopmentCostModal) {
      dispatch(getLandDevelopmentStep1());
      updateState({
        ...iState,
        acres: landDevelopmentStep1List?.data?.acres ?? 0,
      });
    }
  }, [LandDevlopmentCostModal]);

  const handleAcresChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value });
  };

  const handleCreateLandDevelopmentStep1 = () => {
    const dataa = { acres };
    dispatch(createLandDevelopmentStep1(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LandDevlopmentQuantitiesModal: true,
          LandDevlopmentCostModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, LandDevlopmentCostModal: true });
      }
    });
  };

  const handleLandDevlopmentCostModalClose = () => {
    updateState({
      ...iState,
      CommonBuildingQuantitiesModal: true,
      LandDevlopmentCostModal: false,
    });
  };

  const handleLandDevlopmentQuantitiesModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: true,
      LandDevlopmentCostModal: false,
    });
  };

  useEffect(() => {
    if (LandDevlopmentQuantitiesModal) {
      dispatch(getLandDevelopmentStep2());
      updateState({
        ...iState,
        LandDevlopmentQuantites: landDevelopmentStep2List?.data,
      });
    }
  }, [LandDevlopmentQuantitiesModal]);

  const handleLandDevelopmentQuantityChange = (e, ind, i, blocId) => {
    const { name, value } = e.target;
    const dummyQuantity = [...LandDevlopmentQuantites];
    dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[blocId] = {
      ...dummyQuantity[ind].ProformaBaseCosts[i].ProformaBlockQuantities[
        blocId
      ],
      [name]: value,
    };
    updateState({ ...iState, LandDevlopmentQuantites: dummyQuantity });
  };

  const handleLandDevlopmentStep2 = () => {
    const dataa = {
      LandDevlopmentQuantites: LandDevlopmentQuantites?.flatMap((item) =>
        item?.ProformaBaseCosts.flatMap((itm) =>
          itm?.ProformaBlockQuantities.map((itemm) => {
            return { id: itemm.id, quantity: itemm.quantity };
          })
        )
      ),
    };

    dispatch(createLandDevelopmentStep2(dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          LandDevlopmentQuantitiesModal: false,
          LandDevlopmentCostPreviewModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };

  const handleLandDevlopmentQuantitiesModalClose = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: false,
      LandDevlopmentCostModal: true,
    });
  };

  const handleLandDevlopmentCostPreviewModalShow = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: false,
      LandDevlopmentCostPreviewModal: true,
    });
  };

  useEffect(() => {
    if (LandDevlopmentCostPreviewModal) {
      dispatch(getLandDevelopmentStep3());
    }
  }, [LandDevlopmentCostPreviewModal]);

  const handleLandDevlopmentCostPreviewModalClose = () => {
    updateState({
      ...iState,
      LandDevlopmentQuantitiesModal: true,
      LandDevlopmentCostPreviewModal: false,
    });
  };

  const handleProformaConfirmSummaryModalShow = () => {
    updateState({
      ...iState,
      ProformaConfirmSummaryModal: true,
      LandDevlopmentCostPreviewModal: false,
    });
  };

  useEffect(() => {
    if (ProformaConfirmSummaryModal) {
      dispatch(getProformaConfirmationSumarry());
    }
  }, [ProformaConfirmSummaryModal]);

  const handleProformaConfirmSummaryModalClose = () => {
    updateState({
      ...iState,
      ProformaConfirmSummaryModal: false,
      LandDevlopmentCostPreviewModal: true,
    });
  };

  const handleActiveProforma = () => {
    dispatch(activeProforma()).then((res) => {
      if (res.status === 200) {
        setPerFomaId(res?.data?.id);
        SetMultifamilyJobStep1Modal(true);

        updateState(initialState);

        toast.success("Active proforma Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // updateState({initialState});
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };

  const handleCreateJobsProjectsProforma = () => {
    dispatch(createProjectsJobs()).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({ initialState });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // updateState({ ...iState, LandDevlopmentQuantitiesModal: true });
      }
    });
  };

  const [showli, setShowli] = useState({
    details: true,
    block: false,
    blockInput: false,
    blockQuanties: false,
    blockSummeryPreview: false,
    blockFoundation: false,
    units: false,
    commonarae: false,
    commonBuilding: false,
    commonBuildingFoundation: false,
    commonGarage: false,
    multiLevelParakade: false,
    landDevelopment: false,
    confirmation: false,
  });

  const handleShowComponent = (component) => {
    setShowli({
      details: component === "details",
      block: component === "block",
      blockFoundation: component === "blockFoundation",
      units: component === "units",
      commonarae: component === "commonarae",
      commonBuilding: component === "commonBuilding",
      commonBuildingFoundation: component === "commonBuildingFoundation",
      commonGarage: component === "commonGarage",
      multiLevelParakade: component === "multiLevelParakade",
      landDevelopment: component === "landDevelopment",
      confirmation: component === "confirmation",
    });
  };
  const handleProformaBlockFoundationStep2 = (
    e,
    id,
    ind,
    i,
    k,
    blockFoundationStep2,
    setBlockFoundationStep2
  ) => {
    const { name, value } = e.target;
    console.log({ blockFoundationStep2 }, { name }, { value });

    // Creating a deep copy of the relevant nested structure
    const newblockFoundationStep2 = { ...blockFoundationStep2 };
    newblockFoundationStep2.data = [...blockFoundationStep2.data];

    // Further deep copy the nested structure where changes will happen
    newblockFoundationStep2.data[ind] = {
      ...newblockFoundationStep2.data[ind],
      ProformaCostHeadings: [
        ...newblockFoundationStep2.data[ind].ProformaCostHeadings,
      ],
    };

    newblockFoundationStep2.data[ind].ProformaCostHeadings[i] = {
      ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i],
      ProformaBaseCosts: [
        ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i]
          .ProformaBaseCosts,
      ],
    };

    // Assign value based on the condition
    if (name === "unitName") {
      newblockFoundationStep2.data[ind].ProformaCostHeadings[
        i
      ].ProformaBaseCosts[k] = {
        ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i]
          .ProformaBaseCosts[k],
        unit: {
          ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i]
            .ProformaBaseCosts[k].unit,
          name: value,
        },
      };
    } else {
      newblockFoundationStep2.data[ind].ProformaCostHeadings[
        i
      ].ProformaBaseCosts[k] = {
        ...newblockFoundationStep2.data[ind].ProformaCostHeadings[i]
          .ProformaBaseCosts[k],
        [name]: value,
      };
    }

    setBlockFoundationStep2(newblockFoundationStep2);
  };

  return (
    <div>
      <Modal
        show={UpdateProformaModal}
        onHide={hideUpdateProformaModal}
        className="ModalBox ExtraLargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideUpdateProformaModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Proforma</h4>
            </div>
            <div className="StepBox">
              <ul>
                <li
                  className={showli.details ? "active" : ""}
                  onClick={() => handleShowComponent("details")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaDetails"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/details_proforma.png")}
                    />
                  </span>
                  <span className="Text">Details</span>
                </li>
                <li
                  className={showli.block ? "active" : ""}
                  onClick={() => handleShowComponent("block")}
                >
                  <span className="Icon">
                    <img src="images/blocks.png" />
                  </span>
                  <span className="Text">Blocks</span>
                </li>
                <li
                  className={showli.blockFoundation ? "active" : ""}
                  onClick={() => handleShowComponent("blockFoundation")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaBlockFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/blocks_foundation.png")}
                    />
                  </span>
                  <span className="Text">
                    Blocks <br /> Foundation
                  </span>
                </li>
                <li
                  className={showli.units ? "active" : ""}
                  onClick={() => handleShowComponent("units")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaUnitsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/units.png" />
                  </span>
                  <span className="Text">Units</span>
                </li>
                <li
                  className={showli.commonarae ? "active" : ""}
                  onClick={() => handleShowComponent("commonarae")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonAreasInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/common_areas.png")}
                    />
                  </span>
                  <span className="Text">
                    Common <br /> Areas
                  </span>
                </li>
                <li
                  className={showli.commonBuilding ? "active" : ""}
                  onClick={() => handleShowComponent("commonBuilding")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/step_8.png" />
                  </span>
                  <span className="Text">Common Buildings</span>
                </li>
                <li
                  className={showli.commonBuildingFoundation ? "active" : ""}
                  onClick={() =>
                    handleShowComponent("commonBuildingFoundation")
                  }
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonBuildingsFoundationInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/building_foundation.png")}
                    />
                  </span>
                  <span className="Text">Common Buildings Foundation</span>
                </li>
                <li
                  className={showli.commonGarage ? "active" : ""}
                  onClick={() => handleShowComponent("commonGarage")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaCommonGarageInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/common_garage.png")}
                    />
                  </span>
                  <span className="Text">Common Garage</span>
                </li>
                <li
                  className={showli.multiLevelParakade ? "active" : ""}
                  onClick={() => handleShowComponent("multiLevelParakade")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaMultiLevelParkadeInput"
                    data-dismiss="modal"
                  >
                    <img
                      src={require("../../assets/images/common_garage.png")}
                    />
                  </span>
                  <span className="Text">
                    Multi-Level <br /> Parkade
                  </span>
                </li>
                <li
                  className={showli.landDevelopment ? "active" : ""}
                  onClick={() => handleShowComponent("landDevelopment")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaLandDevelopmentAdminCostsInput"
                    data-dismiss="modal"
                  >
                    <img src="images/land_tract.png" />
                  </span>
                  <span className="Text">
                    Land Development &amp; Admin Costs
                  </span>
                </li>
                <li
                  className={showli.confirmation ? "active" : ""}
                  onClick={() => handleShowComponent("confirmation")}
                >
                  <span
                    className="Icon FolderPermissionId"
                    data-toggle="modal"
                    data-target="#CreateProformaConfirmation"
                    data-dismiss="modal"
                  >
                    <img src="images/step_7.png" />
                  </span>
                  <span className="Text">Confirmation</span>
                </li>
              </ul>
            </div>
            <div className="FolderForm">
              {showli.details && (
                <UpdatePerfomaGeneralDetail
                  handleInput={handleInput}
                  proformaTypesListData={proformaTypesListData}
                  checkArray={checkArray}
                  updateId={updateId}
                  hideUpdateProformaModal={hideUpdateProformaModal}
                  setShowli={setShowli}
                  handleShowComponent={handleShowComponent}
                  showli={showli}
                />
              )}
              {showli.block && (
                <PerformaBlock
                  blockFirstStepNumber={blockFirstStepNumber}
                  handleInputNumber={handleInputNumber}
                  blockfirstStep={blockfirstStep}
                  handleBlockFirstStepChange={handleBlockFirstStepChange}
                  checkArray={checkArray}
                  foundationListData={foundationListData}
                  blockQuantitiesData={blockQuantitiesData}
                  unitList={unitList}
                  handleBlockQuantityChange={handleBlockQuantityChange}
                  proformaBlockStep3Data={proformaBlockStep3Data}
                  updateId={updateId}
                  handleShowComponent={handleShowComponent}
                />
              )}
              {showli.blockFoundation && (
                <BlockFoundations
                  blockFoundationStep1List={blockFoundationStep1List}
                  foundationValues={foundationValues}
                  handleInputChange={handleInputChange}
                  blockQuantitiesData={blockQuantitiesData}
                  unitList={unitList}
                  handleBlockQuantityChange={handleBlockQuantityChange}
                  updateId={updateId}
                  handleProformaBlockFoundationStep2={
                    handleProformaBlockFoundationStep2
                  }
                  handleShowComponent={handleShowComponent}
                />
              )}

              {showli.units && (
                <UnitPerforma
                  unitTypes={unitTypes}
                  handleUnitChange={handleUnitChange}
                  unitStep1List={unitStep1List}
                  unitOption={unitOption}
                  handleUnitOptionChange={handleUnitOptionChange}
                  unitStep4List={unitStep4List}
                  unitStep3List={unitStep3List}
                  updateId={updateId}
                  handleShowComponent={handleShowComponent}
                />
              )}

              {showli.commonarae && (
                <CommonArea
                  commonAreaUnit={commonAreaUnit}
                  handleMultiStorieschange={handleMultiStorieschange}
                  commonAreaStep2List={commonAreaStep2List}
                  updateId={updateId}
                  handleShowComponent={handleShowComponent}
                />
              )}
              {showli.commonBuilding && (
                <CommonBuilding
                  numBlocks={numBlocks}
                  handleNumBlocksChange={handleNumBlocksChange}
                  buildings={buildings}
                  handleBuildingFieldChange={handleBuildingFieldChange}
                  foundationTypeData={foundationTypeData}
                  commonBuildingQuantities={commonBuildingQuantities}
                  unitList={unitList}
                  handleQuantityChange={handleQuantityChange}
                  commonBuildingStep3List={commonBuildingStep3List}
                  handleShowComponent={handleShowComponent}
                />
              )}
              {showli.commonBuildingFoundation && (
                <CommonBuildingFoundation
                  commonBuildingInput={commonBuildingInput}
                  handleCommonBuildingUnitChange={
                    handleCommonBuildingUnitChange
                  }
                  commonBuildingFoundationInput={commonBuildingFoundationInput}
                  unitList={unitList}
                  handleCommonBuildingfoundationChange={
                    handleCommonBuildingfoundationChange
                  }
                  commonBuildingFoundationStep3List={
                    commonBuildingFoundationStep3List
                  }
                  handleShowComponent={handleShowComponent}
                />
              )}

              {showli.commonGarage && (
                <CommonGarage
                  handleBuildingGarageFieldChange={
                    handleBuildingGarageFieldChange
                  }
                  garage={garage}
                  handleNumGarageBlocksChange={handleNumGarageBlocksChange}
                  numBlocksGarage={numBlocksGarage}
                  commonGarageQuantity={commonGarageQuantity}
                  handlecommonGarageQuantityChange={
                    handlecommonGarageQuantityChange
                  }
                  commonGarageStep3List={commonGarageStep3List}
                  handleShowComponent={handleShowComponent}
                />
              )}

              {showli.multiLevelParakade && (
                <MultiLevelParkades
                  multiLevelParkade={multiLevelParkade}
                  handleRadioChange={handleRadioChange}
                  noOfMultiParkade={noOfMultiParkade}
                  handleMultiParkadeChange={handleMultiParkadeChange}
                  parkades={parkades}
                  handleMultiParkadeFieldChange={handleMultiParkadeFieldChange}
                  MulitileveleParkadeQuantiesData={
                    MulitileveleParkadeQuantiesData
                  }
                  handleMultiLevelParkadeChange={handleMultiLevelParkadeChange}
                  multiLevelStep3List={multiLevelStep3List}
                  handleShowComponent={handleShowComponent}
                />
              )}
              {showli.landDevelopment && (
                <LandDevelopment
                  acres={acres}
                  handleAcresChange={handleAcresChange}
                  updateId={updateId}
                  landDevelopmentStep3List={landDevelopmentStep3List}
                  unitList={unitList}
                  handleShowComponent={handleShowComponent}
                />
              )}

              {showli.confirmation && (
                <Conformation
                  proformaConfirmSummaryList={proformaConfirmSummaryList}
                  moment={moment}
                  updateId={updateId}
                  handleShowComponent={handleShowComponent}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdatePerforma;
