import { homeActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
multiFamilyHomeListData:[],
landDevelopmentHomeListData:[],
residentialHomeListData:[],
taskHomeListData:[],
todoHomeListData:[],
iternaryHomeListData:[],
multiblocksList:[],
multiFamilyProjectList:[],
landDevelopmentProjectList:[],
landPhaseList:[],
};

export default function jobReducer(state = initialState, { type, payload }) {
  switch (type) {
    case homeActionTypes.RESIDENTIAL_HOME_LIST_INITIATE: {
        return {
          ...state,
          residentialHomeListData: [],
          loader:true
        };
      }
      case homeActionTypes.RESIDENTIAL_HOME_LIST_SUCCESS: {
        return {
          ...state,
          residentialHomeListData: payload,
          loader:false
        };
      }
      case homeActionTypes.RESIDENTIAL_HOME_LIST_FAILURE: {
        return {
          ...state,
          residentialHomeListData: payload.data,
          loader:false
        };
      }

     
      case homeActionTypes.MULTIFAMILY_HOME_LIST_INITIATE: {
        return {
          ...state,
          multiFamilyHomeListData: [],
          loader:true
        };
      }
      case homeActionTypes.MULTIFAMILY_HOME_LIST_SUCCESS: {
        return {
          ...state,
          multiFamilyHomeListData: payload.data,
          loader:false
        };
      }
      case homeActionTypes.MULTIFAMILY_HOME_LIST_FAILURE: {
        return {
          ...state,
          multiFamilyHomeListData: payload.data,
          loader:false
        };
      }

      case homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_INITIATE: {
        return {
          ...state,
          landDevelopmentHomeListData: [],
          loader:true
        };
      }
      case homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_SUCCESS: {
        return {
          ...state,
          landDevelopmentHomeListData: payload.data,
          loader:false
        };
      }
      case homeActionTypes.LAND_DEVELOPMENT_HOME_LIST_FAILURE: {
        return {
          ...state,
          landDevelopmentHomeListData: payload.data,
          loader:false
        };
      }

      case homeActionTypes.TODO_HOME_LIST_INITIATE: {
        return {
          ...state,
          todoHomeListData: [],
          loader:true
        };
      }
      case homeActionTypes.TODO_HOME_LIST_SUCCESS: {
        return {
          ...state,
          todoHomeListData: payload,
          loader:false
        };
      }
      case homeActionTypes.TODO_HOME_LIST_FAILURE: {
        return {
          ...state,
          todoHomeListData: payload.data,
          loader:false
        };
      }


      case homeActionTypes.TASK_HOME_LIST_INITIATE: {
        return {
          ...state,
          taskHomeListData: [],
          loader:true
        };
      }
      case homeActionTypes.TASK_HOME_LIST_SUCCESS: {
        return {
          ...state,
          taskHomeListData: payload,
          loader:false
        };
      }
      case homeActionTypes.TASK_HOME_LIST_FAILURE: {
        return {
          ...state,
          taskHomeListData: payload.data,
          loader:false
        };
      }

      case homeActionTypes.ITENARY_HOME_LIST_INITIATE: {
        return {...state,iternaryHomeListData: []};
      }
   
      case homeActionTypes.ITENARY_HOME_LIST_SUCCESS: {
        return {...state,iternaryHomeListData:payload};
      }

      case homeActionTypes.ITENARY_HOME_LIST_FAILURE: {
        return {...state,iternaryHomeListData: []};
      }


      case homeActionTypes.GET_MULTI_BLOCKS_LIST_INITIATE: {
        return {...state,multiblocksList: []};
      }
   
      case homeActionTypes.GET_MULTI_BLOCKS_LIST_SUCCESS: {
        let temp = payload.data;
        let data =[...temp?.blocks,...temp?.multiBuildings,...temp?.multiGarages,...temp?.multiParkades]
        return {...state,multiblocksList:data};
      }

      case homeActionTypes.GET_MULTI_BLOCKS_LIST_FAILURE: {
        return {...state,multiblocksList: []};
      }
    

      case homeActionTypes.MULTI_FAMILY_PROJECT_LIST_INITIATE: {
        return {...state,multiFamilyProjectList: []};
      }
   
      case homeActionTypes.MULTI_FAMILY_PROJECT_LIST_SUCCESS: {
        return {...state,multiFamilyProjectList:payload};
      }

      case homeActionTypes.MULTI_FAMILY_PROJECT_LIST_FAILURE: {
        return {...state,multiFamilyProjectList: []};
      }

      case homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_INITIATE: {
        return {...state,landDevelopmentProjectList: []};
      }
   
      case homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_SUCCESS: {
        return {...state,landDevelopmentProjectList:payload};
      }

      case homeActionTypes.LAND_DEVELOPMENT_PROJECT_LIST_FAILURE: {
        return {...state,landDevelopmentProjectList: []};
      }


      case homeActionTypes.GET_LAND_PHASE_LIST_INITIATE: {
        return {...state,landPhaseList: []};
      }
   
      case homeActionTypes.GET_LAND_PHASE_LIST_SUCCESS: {
        return {...state,landPhaseList:payload};
      }

      case homeActionTypes.GET_LAND_PHASE_LIST_FAILURE: {
        return {...state,landPhaseList: []};
      }

   
    default:
      return state;
  }
}