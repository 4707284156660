import React from 'react'
import Header from './Header'
import SideNav from './SideNav'

const HouseRoughWarranty = () => {
  return (
    <div><>
    <Header/>
    <SideNav/>
    <div className="WrapperArea">
      <div className="WrapperBox">
        <div className="MainTitleBox">
          <div className="MainTitle">
            <h2>House Warranty</h2>
          </div>
          <div className="TodosButtons">
            <a href="Warranty_Request.html" id="NewWarrantyRequestBtn">
              <h6>
                <img src="images/new-warranty-request.png" />
                New Warranty Request
              </h6>
              {/* <span>3</span> */}
            </a>
          </div>
        </div>
        <div className="TitleBox">
          <div className="TodosButtons mt-0">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#WarrantyRequest"
                  id="WarrantyRequestTab"
                >
                  <img src="images/warranty-request.png" />
                  Warranty Request<span>3</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#WorkOrders"
                  id="WorkOrdersTab"
                >
                  <img src="images/work-order-red.png" />
                  Work Orders<span>3</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="TitleBoxProjectHeading">
            <h6>
              <span>Project:</span> Chinook Gate
            </h6>
            <h6>
              <span>Unit:</span> 32
            </h6>
          </div>
        </div>
        <div className="tab-content">
          <div className="tab-pane active show" id="WarrantyRequest">
            <div className="Tableheader">
              <h6>Warranty Request</h6>
              <div className="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-64.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
              </div>
            </div>
            <div className="TableList TableListHeader NewTableList TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Urgency</th>
                    <th>Description</th>
                    <th>Date Created</th>
                    <th>Type of Warranty</th>
                    <th>Assigned/# of Warranty Items</th>
                    <th>Status</th>
                    <th>Supporting Doc</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">1</a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="GreenTextTd">
                          Standard - Earliest Convenience
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        Cabinet fixes
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        03/10/2023
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        Courtesy
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="GreenTextTd">0 outstanding items</div>
                      </a>
                    </td>
                    <td>Completed</td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0)"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="DetailsDots">
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu ChangeOrderDropdown">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Warranty Request Details
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">2</a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="RedTextTd">
                          Standard - Earliest Convenience
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="RedTextTd">Plumbing fixes (draft)</div>
                      </a>
                    </td>
                    <td>04/23/2023</td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        Courtesy
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="RedTextTd">0 outstanding items</div>
                      </a>
                    </td>
                    <td>Item(s) Still O/S</td>
                    <td></td>
                    <td>
                      <div className="DetailsDots">
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu ChangeOrderDropdown">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Warranty Request Details
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">3</a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="GreenTextTd">
                          Standard - Earliest Convenience
                        </div>
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        Cabinet fixes
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        03/10/2023
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        Courtesy
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Request_work_order_table.html">
                        <div className="GreenTextTd">0 outstanding items</div>
                      </a>
                    </td>
                    <td>Completed</td>
                    <td></td>
                    <td>
                      <div className="DetailsDots">
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu ChangeOrderDropdown">
                                <li>
                                  <a href="Warranty_Work_Order1.html">
                                    <img src="images/view-po.png" />
                                    View Warranty Request Details
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="WorkOrders">
            <div className="Tableheader">
              <h6>Work Order</h6>
              <div className="NewActions">
                <a href="javascript:void(0);">
                  <img src="images/icon-64.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/icon-53.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/download.png" />
                </a>
              </div>
            </div>
            <div className="TableList NewTableList TableListHeader TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>Home Owner Accepted</th>
                    <th>Date Created</th>
                    <th>Vendor/ Technician name</th>
                    <th>Trades Day</th>
                    <th>Completed/# Work Order Items</th>
                    <th>Status</th>
                    <th>Supporting Doc</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="Warranty_Work_Order.html">1</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Repairs (draft)</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">No</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">03/10/2023</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Ltd sas</a>
                    </td>
                    <td />
                    <td>
                      <a href="Warranty_Work_Order.html">
                        03 outstanding out of 03
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">Item(s) Still O/S</a>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view-po.png" />
                                  View Work Order
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/approve_work_order.png" />
                                  Approve Work Order
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="Warranty_Work_Order.html">1</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Repairs (draft)</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">No</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">03/10/2023</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Ltd sas</a>
                    </td>
                    <td>04/23/2023</td>
                    <td>
                      <a href="Warranty_Work_Order.html">
                        03 outstanding out of 03
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">Item(s) Still O/S</a>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view-po.png" />
                                  View Work Order
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/approve_work_order.png" />
                                  Approve Work Order
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="Warranty_Work_Order.html">1</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Repairs (draft)</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">No</a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">03/10/2023</a>
                    </td>
                    <td>
                      <a href="javascript:void(0);">Plumbing Ltd sas</a>
                    </td>
                    <td>04/23/2023</td>
                    <td>
                      <a href="Warranty_Work_Order.html">
                        03 outstanding out of 03
                      </a>
                    </td>
                    <td>
                      <a href="Warranty_Work_Order.html">Item(s) Still O/S</a>
                    </td>
                    <td>
                      <div className="Actions">
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <img src="images/icon-63.png" />
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          <span className="Count">3</span>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="TableThreeDots">
                        <ul className="">
                          <li className="dropdown">
                            <a
                              className=""
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src="images/dots.png" />
                            </a>
                            <ol className="dropdown-menu">
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/view-po.png" />
                                  View Work Order
                                </a>
                              </li>
                              <li>
                                <a href="javascript:void(0);">
                                  <img src="images/approve_work_order.png" />
                                  Approve Work Order
                                </a>
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ModalBox">
      <div
        className="modal fade SmallModal show"
        id="NavigateProject"
        data-backdrop="static"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Navigate Project</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="NaviagateProjectArea">
                    <ul>
                      <li className="DarkBlueList">
                        <a href="javascript:void(0);">Avalon Towns - Project</a>
                      </li>
                      <li className="LightBlueList">
                        <a href="javascript:void(0);">
                          Administration and Development (CHI-2B-344)
                        </a>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/add_build.png" />
                                    Add to/Edit Build
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/details.png" />
                                    Details
                                  </a>
                                </li>
                                <li className="border-0">
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/change_order.png" />
                                    Change Order
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        Change orders List
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        Change to Contract (CTC) master
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/design.png" />
                                    Design
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/sales.png" />
                                    Sales
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/estimation.png" />
                                    Estimation
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/construction_icon.png" />
                                    Construction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/warranty.png" />
                                    Warranty
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/reports.png" />
                                    Reports
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/view_config.png" />
                                    Views Config
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li className="DarkBlueList">
                        <a href="javascript:void(0);">Avalon Towns - Project</a>
                      </li>
                      <li className="LightBlueList">
                        <a
                          href="javascript:void(0);"
                          className="LightBlueListDropdown"
                        >
                          <img src="images/black-down-arrow.png" /> Over Basement
                          (CHI-2B-345)
                        </a>
                        <div className="TableThreeDots">
                          <ul className="">
                            <li className="dropdown">
                              <a
                                className=""
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img src="images/dots.png" />
                              </a>
                              <ol className="dropdown-menu dropdown-menuwidth">
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/add_build.png" />
                                    Add to/Edit Build
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/details.png" />
                                    Details
                                  </a>
                                </li>
                                <li className="border-0">
                                  <a
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <img src="images/change_order.png" />
                                    Change Order
                                    <img src="images/rightarrow2.png" />
                                  </a>
                                  <ul className="dropdown-menu submenu">
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        Change orders List
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                      >
                                        Change to Contract (CTC) master
                                      </a>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/design.png" />
                                    Design
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/sales.png" />
                                    Sales
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/estimation.png" />
                                    Estimation
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/construction_icon.png" />
                                    Construction
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/warranty.png" />
                                    Warranty
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/reports.png" />
                                    Reports
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);">
                                    <img src="images/view_config.png" />
                                    Views Config
                                  </a>
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <ul className="AvalonTownsList">
                        <li>Avalon Towns - Units</li>
                        <li>
                          <a href="javascript:void(0);">
                            Lower Unit #1(U1-CHI-2B-346)
                          </a>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  id="navbarDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/add_build.png" />
                                      Add to/Edit Build
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/details.png" />
                                      Details
                                    </a>
                                  </li>
                                  <li className="border-0">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/change_order.png" />
                                      Change Order
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          Change orders List
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          Change to Contract (CTC) master
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/design.png" />
                                      Design
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/sales.png" />
                                      Sales
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/estimation.png" />
                                      Estimation
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/construction_icon.png" />
                                      Construction
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/warranty.png" />
                                      Warranty
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/reports.png" />
                                      Reports
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/view_config.png" />
                                      Views Config
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            Lower Unit #1(U1-CHI-2B-346)
                          </a>
                          <div className="TableThreeDots">
                            <ul className="">
                              <li className="dropdown">
                                <a
                                  className=""
                                  id="navbarDropdown"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <img src="images/dots.png" />
                                </a>
                                <ol className="dropdown-menu dropdown-menuwidth">
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/add_build.png" />
                                      Add to/Edit Build
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/details.png" />
                                      Details
                                    </a>
                                  </li>
                                  <li className="border-0">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <img src="images/change_order.png" />
                                      Change Order
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu">
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          Change orders List
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                        >
                                          Change to Contract (CTC) master
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/design.png" />
                                      Design
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/sales.png" />
                                      Sales
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/estimation.png" />
                                      Estimation
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/construction_icon.png" />
                                      Construction
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/warranty.png" />
                                      Warranty
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/reports.png" />
                                      Reports
                                    </a>
                                  </li>
                                  <li>
                                    <a href="javascript:void(0);">
                                      <img src="images/view_config.png" />
                                      Views Config
                                    </a>
                                  </li>
                                </ol>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade SmallModal show"
        id="JobMenu"
        data-backdrop="static"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Job Menu</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="JobMenuListing">
                    <ul>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/add_build.png" />
                          </figure>
                          Add to/Edit Build
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/details.png" />
                          </figure>
                          Details
                        </a>
                      </li>
                      <li className="JobMenuListing_All">
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/change_order.png" />
                          </figure>
                          Change Order
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                        <ul className="JobMenuListingUnder">
                          <li>
                            <a href="javascript:void(0);">Change orders List</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              Change to Contract (CTC) master
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/design.png" />
                          </figure>
                          Design
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/sales.png" />
                          </figure>
                          Sales
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/estimation.png" />
                          </figure>
                          Estimation
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/construction_icon.png" />
                          </figure>
                          Construction
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/warranty.png" />
                          </figure>
                          Warranty
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/reports.png" />
                          </figure>
                          Reports
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <figure>
                            <img src="images/view_config.png" />
                          </figure>
                          Views Config
                          <img
                            src="images/rightarrow2.png"
                            className="ListingOverflow"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade ExtraSmallModal show"
        id="VendorInformationModal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ContactTopBox ContactTopBoxIdArea">
                <span>MB</span>
                <div className="ContactTopBoxIdName">
                  <h4>Man Mohan</h4>
                  <h4>IT Consultant</h4>
                </div>
              </div>
              <div className="SendEmailText">
                <p>
                  <img src="images/send-email.png" />
                  Send E-mail
                </p>
                <p className="SendText">
                  <img src="images/send-text.png" />
                  Send Text
                </p>
                <div className="SendTextSearch">
                  <input type="text" />
                  <button>Apply</button>
                </div>
              </div>
              <div className="MolalContactForm">
                <h6>Contact</h6>
                <p>
                  <img src="images/send-email.png" />
                  johndoe@gmail.com
                </p>
                <p>
                  <img src="images/send-text.png" />
                  +91 990000000
                </p>
                <a href="javascript:void(0);">Show more</a>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Note</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <h6>Discussion</h6>
                    <input
                      type="text"
                      placeholder="Write here ....."
                      className="form-control"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="AddNewSupportingDocs2">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3">
                <button className="Create" data-dismiss="modal">
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal" id="SupportingDocumentList">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Documents List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <div>
                    <a
                      href="javascript:void(0);"
                      className="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#AddNewSupportingDocs"
                      data-dismiss="modal"
                    >
                      <img src="images/notebook.png" />
                    </a>
                  </div>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="NotesAreaMain">
                <div className="NotesArea mt-4">
                  <h6>Supporting Documents List</h6>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>John Doe</td>
                        <td>05/01/2023</td>
                        <td className="Inspection">floor_manual.pdf</td>
                        <td>
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              data-toggle="modal"
                              data-target="#SupportingDocsDetails"
                              data-dismiss="modal"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/delete.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>John Doe</td>
                        <td>05/01/2023</td>
                        <td className="Inspection">photo1.jpg, photo2.jpg</td>
                        <td>
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              data-toggle="modal"
                              data-target="#SupportingDocsDetails"
                              data-dismiss="modal"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/delete.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs"
                  data-dismiss="modal"
                >
                  Add New Supporting Docs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="AddNewSupportingDocs">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#SupportingDocumentList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3">
                <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="SupportingDocsDetails">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#SupportingDocumentList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Docs Details</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3 d-flex justify-content-between">
                <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Update Documents
                </button>
                <button
                  className="Outline"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  </div>
  )
}

export default HouseRoughWarranty