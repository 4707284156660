import React from "react";

const FoundationSummaryPreview = ({
  commonBuildingFoundationStep3List,
  handleShowComponent,
  handleShowComponents,
}) => {
  return (
    <>
      <div className="ModalBigheading">
        <h3>
          <span>Common Buildings Foundation Input</span>
        </h3>
      </div>
      <div className="ModalMediumheading d-flex align-items-center justify-content-between">
        <h3>
          <span>Basement Foundation</span>
        </h3>
        <h6>
          <img src="images/details-cost.png" />
          Detailed Costs
        </h6>
      </div>
      <div className="BlockInputs Tablewidth-50">
        <table>
          <thead>
            <tr>
              <th>Input</th>
              <th>Site Office</th>
              <th>Totals</th>
            </tr>
          </thead>
          <tbody>
            {commonBuildingFoundationStep3List?.data?.table1?.foundation
              ?.length > 0 &&
              commonBuildingFoundationStep3List?.data?.table1?.foundation?.map(
                (data, i) => {
                  return (
                    <tr>
                      <td>{data.name}</td>
                      <td>6.4</td>
                      <td>6.4</td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      {/* <div className="ModalMediumheading d-flex align-items-center justify-content-between mt-5">
                    <h3>
                      <span>Slab-On-Grade Foundation</span>
                    </h3>
                  </div> */}
      {/* <div className="BlockInputs Tablewidth-50">
                    <table>
                      <thead>
                        <tr>
                          <th>Input</th>
                          <th>Rec Center</th>
                          <th>Totals</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Slab/Floor Cubic/ Yard</td>
                          <td>18.5</td>
                          <td>18.5</td>
                        </tr>
                        <tr>
                          <td>Foundation Perimeter Lin/FT</td>
                          <td>160</td>
                          <td>160</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
      <div className="BlockCostsPreview">
        <div className="d-flex align-items-center justify-content-between">
          <h4>Common Areas Costs</h4>
          <div className="NewActions">
            <a href="javascript:void(0);" title="pdf">
              <img src="images/pdf.png" />
            </a>
            <a href="javascript:void(0);">
              <img src="images/download.png" />
            </a>
          </div>
        </div>
        <div className="BlockInputsCollapse">
          <div
            className="BcpHeader"
            data-toggle="collapse"
            href="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <h5>Basement Foundation - Common Building Foundation Costs</h5>
          </div>
          <div className="collapse show" id="collapseExample">
            <div className="card card-body border-0">
              <div className="BlockInputs">
                <table>
                  <thead>
                    <tr>
                      <th>Code Code #</th>
                      <th>Description</th>
                      <th>Site Office</th>
                      <th>Total Construction Costs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>4.0102</td>
                      <td>Windows. and Exterlor Doors</td>
                      <td>$ -</td>
                      <td>$ -</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td>Total Foundation Cost</td>
                      <td>67,480.00</td>
                      <td>2,75,480.00</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="BlockInputs Red">
          <table>
            <tfoot>
              <tr>
                <td colSpan={3}>Base Foundation</td>
                <td>$ 32,275,590.00</td>
              </tr>
              <tr>
                <td colSpan={3}>Slab-on-grade Foundation </td>
                <td>$ 32,275,590.00</td>
              </tr>
              <tr>
                <td colSpan={3}>Grand Total</td>
                <td>$ 32,275,590.00</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() =>
            handleShowComponents("commonBuildingFoundationQuanties")
          }
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() => handleShowComponent("commonGarage")}
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default FoundationSummaryPreview;
