import React from 'react'

const LoginBuilder = () => {
  return (
   <>
   <div className="LoginArea">
  <div className="LoginBoxBig">
    <div className="row">
      <div className="col-md-5">
        <div className="LoginBoxLeft">
          <figure>
            <img src="images/login_background.png" />
          </figure>
        </div>
      </div>
      <div className="col-md-7">
        <div className="LoginBox">
          <h6>Multi-Family and Residential Platform</h6>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="SystemAdmin">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="main_login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="home.html">
                  Login
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ClientView">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="main_login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="home.html">
                  Login
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="TradeView">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="main_login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="home.html">
                  Login
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="BuilderUser">
              <h3>Welcome Back</h3>
              <p>Login to continue</p>
              <form>
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter text"
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="InputPasswordView">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter text"
                    />
                    <span>
                      <img src="images/lock_password.png" />
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group">
                    <label className="CheckBox">
                      {" "}
                      Remember me
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <div className="Checkboxs">
                      <span>&nbsp;</span>
                      <a href="main_login-forgot.html">Forgot password?</a>
                    </div>
                  </div>
                </div>
                <a className="Button" href="home.html">
                  Login
                </a>
              </form>
              <div className="loginSocials">
                <a href="javascript:void(0);">
                  <img src="images/facebook_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/earth_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/mail_red.png" />
                </a>
                <a href="javascript:void(0);">
                  <img src="images/linkdin_red.png" />
                </a>
              </div>
              <div className="TabContentBottom">
                <p className="">
                  Not an <span>Ibuild</span> customer yet?{" "}
                  <a href="javascript:void(0);">Subscribe Now</a>
                </p>
                <div className="Logo">
                  <img src="images/Logo.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   </>
  )
}

export default LoginBuilder