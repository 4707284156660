import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlockFoundationStep1 } from '../../../redux/action/proformaAction'

const BlockFoundationInput = ({
  handleInputChange,
  foundationValues,
  handleShowComponent,
  handleShow
}) => {
    const dispatch=useDispatch()
    const[blockFoundationStep1List,setBlockFoundationStep1List]=useState([])
useEffect(()=>{
dispatch(getBlockFoundationStep1()).then(data=>{
  setBlockFoundationStep1List(data?.data?.data)
})
},[])
  console.log({blockFoundationStep1List},{foundationValues});
  return (
    <>
    <div className="ProformaAccordion">
    <div className="accordion" id="accordionExample">
      {blockFoundationStep1List?.length > 0 &&
        blockFoundationStep1List?.map((data, ind) => {
          return (
            <div className="card">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {data.name}
                  </button>
                </h2>
              </div>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
                style={{}}
              >
                <div className="card-body">
                  <div
                    className="EnterProjectTable"
                    style={{ overflowX: "scroll" }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th />
                          <th>Block A</th>
                          <th>Block C</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.FoundationPerimeters?.length > 0 &&
                          data?.FoundationPerimeters?.map(
                            (item, i) => {
                              return (
                                <tr key={i}>
                                  <td>{item.name}</td>
                                  {item?.BlockFoundations?.map(
                                    (blocks, index) => {
                                      return (
                                        <td key={index}>
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              className="form-control width-250"
                                              placeholder="Insert cubic yards"
                                              
                                              value={
                                                blocks.value
                                              }
                                              onChange={(e) =>
                                                handleInputChange(
                                                  data.id,
                                                  blocks.id,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                    <div className="PaginationArea pl-0">
                      <h6>Showing 1 to 10 of 27 entries</h6>
                      <h6>
                        <img src="images/leftarrow.png" /> 01{" "}
                        <img src="images/rightarrow.png" /> Out 10
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  </div>
  <div className="Button d-flex  justify-content-between align-items-center px-5">
                <button
                  className="Outline border text-success border-success"
                  onClick={() => handleShowComponent("block")}
                >
                  Cancel
                </button>
                <button
                  className="Create bg-success border border-success"
                  onClick={()=>handleShow("blockFoundationQuanties")}
                   >
                  Save Draft
                </button>
               
              </div>
    </>
  )
}

export default BlockFoundationInput