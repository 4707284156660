import React from 'react'
import Modal from "react-bootstrap/Modal";

const CostDistribution = ({costDistribution, setCostDistribution}) => {

    const handleAddContactModalClose = () => setCostDistribution(false);
    const handleAddContactModalShow = () => {
        setCostDistribution(true);
    };
  
  return (
    <Modal show={costDistribution} className="ModalBox LargeModal">
    <div class="modal-content">
      <a
        onClick={handleAddContactModalClose}
        class="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>{" "}
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Cost Distribution</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="ModalBigheading">
                    <h3><span>Report: </span>Cash Flow Report</h3>
                  </div>
                  
                  <div class="TableList CommonHeading">
                    <table>
                      <thead>
                        <tr>
                          <th>Q.R. Cat #</th>
                          <th>Cost Code Categories</th>
                          <th>Draw 1</th>
                          <th>Draw 2</th>
                          <th>Draw 3</th>
                          <th>Draw 4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>DESIGN - EXT. &amp; INT. - BLUEPRINTS/PLANS</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>SITE WORK, FOUNDATION &amp; RETAINING WALLS</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>WALK-OUT BASEMENT</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>STAIRS - WOOD-INTERIOR &amp; EXTERIOR</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>FLOOR SYSTEM - JOIST &amp; SHEATHING
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>FRAMING-HOUSE</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>ROOF SYSTEM-TRUSSES
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>WINDOWS &amp; DOORS - EXTERIOR
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>DECKS</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>FIREPLACES</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>PLUMBING</td>
                          <td>15%</td>
                          <td>45%</td>
                          <td></td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>HEATING, VENTILATION &amp; AIR CONDITIONING (HVAC)
                          </td>
                          <td></td>
                          <td>90%</td>
                          <td></td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>ELECTRICAL</td>
                          <td></td>
                          <td>70%</td>
                          <td></td>
                          <td>30%</td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>HEATING, VENTILATION &amp; AIR CONDITIONING (HVAC)
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>HEATING, VENTILATION &amp; AIR CONDITIONING (HVAC)
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>HEATING, VENTILATION &amp; AIR CONDITIONING (HVAC)
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>17</td>
                          <td>DRYWALL, TAPING &amp; TEXTURED CEILINGS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>INTERIOR FINISH</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>19</td>
                          <td>CABINETS &amp; COUNTER TOPS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>PAINTING, STAINING &amp; LACQUER
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>21</td>
                          <td>WALL TILE &amp; FLOOR COVERINGS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>22</td>
                          <td>LIGHTING, MIRRORS, SHELVING &amp; GLASS DOORS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>23</td>
                          <td>APPLIANCES
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>24</td>
                          <td>WINDOW COVERINGS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>25</td>
                          <td>CONCRETE FLAT-WORK
                          </td>
                          <td></td>
                          <td>50%</td>
                          <td></td>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <td>26</td>
                          <td>LANDSCAPING
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>27</td>
                          <td>WASTE REMOVAL, CLEANING &amp; OTHER
                          </td>
                          <td></td>
                          <td>34%</td>
                          <td>33%</td>
                          <td>33%</td>
                        </tr>
                        <tr>
                          <td>28</td>
                          <td>-BASE-DEVELOP FINISHING COSTS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>29</td>
                          <td>-BASE-DEVELOP FINAL COSTS
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>30</td>
                          <td>DETACHED GARAGE/PARKING PAD
                          </td>
                          <td>25%</td>
                          <td>50%</td>
                          <td>25%</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>31</td>
                          <td>HIGH-END CUSTOM OPTIONS - R/I's
                          </td>
                          <td>50%</td>
                          <td>50%</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>32</td>
                          <td> HIGH-END CUSTOM OPTIONS-FINALS
                          </td>
                          <td></td>
                          <td></td>
                          <td>50%</td>
                          <td>50%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                      

                  <div class="Button d-flex justify-content-between  mt-5">
                    <button class="Create">Save Cost Distribution</button>
                    <button class="Outline">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
    </div>
  </Modal>
  )
}

export default CostDistribution