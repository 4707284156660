import { investorViewAuthAction } from '../actionTypes'

const initialState = {
  investorViewData: {},
  loader: false,
  loginStatus: false,
  vendorData:[],
  commentList:[],
  vendorCompanyList:[],
  allProjectList:[],
  projectJobList:[],
  subCommentList:[],
  builderList:[],
  buliderByProjectList:[],
  jobByProjectList:[],
  folderNFileList:[],
}

export default function investorViewLogin(state = initialState, {type, payload}) {
  switch(type) {
    case investorViewAuthAction.INVESTOR_VIEW_LOGIN_INITIATE: {
      return {
        ...state, loader: true, investorViewData: {}
      }
    }
    case investorViewAuthAction.INVESTOR_VIEW_LOGIN_SUCCESS: {
				// 	delete payload.data.password
				// delete payload.data.plainPassword
      // console.log("payload",payload);
       window.localStorage.setItem('investorViewLogin', JSON.stringify(payload))
       
      return {
        ...state, loader: false, loginStatus: true, investorViewData: payload.data
      }
    }
    case investorViewAuthAction.INVESTOR_VIEW_LOGIN_FAILURE: {
      return {
        ...state, loader: false,investorViewData: payload
      }
    }

    case investorViewAuthAction.GET_VENDOR_BY_USER_ID_INITIATE: {
      return {...state, loader:true, vendorData:[]}
    }
    case investorViewAuthAction.GET_VENDOR_BY_USER_ID_SUCCESS: {
      return {...state, loader:false, vendorData:payload}
    }
    case investorViewAuthAction.GET_VENDOR_BY_USER_ID_FAILURE: {
      return {...state, loader:false,vendorData:[]}
    }

    case investorViewAuthAction.GET_COMMENT_INITIATE: {
      return {...state,  commentList:[]}
    }
    case investorViewAuthAction.GET_COMMENT_SUCCESS: {
      return {...state, commentList:payload}
    }
    case investorViewAuthAction.GET_COMMENT_FAILURE: {
      return {...state,commentList:[]}
    }
    case investorViewAuthAction.GET_VENDOR_COMPANIES_INITIATE: {
      return {...state,  vendorCompanyList:[]}
    }
    case investorViewAuthAction.GET_VENDOR_COMPANIES_SUCCESS: {
      return {...state, vendorCompanyList:payload}
    }
    case investorViewAuthAction.GET_VENDOR_COMPANIES_FAILURE: {
      return {...state,vendorCompanyList:[]}
    }

    case investorViewAuthAction.GET_ALL_PROJECT_INITIATE: {
      return {...state,  allProjectList:[]}
    }
    case investorViewAuthAction.GET_ALL_PROJECT_SUCCESS: {
      return {...state, allProjectList:payload}
    }
    case investorViewAuthAction.GET_ALL_PROJECT_FAILURE: {
      return {...state,allProjectList:[]}
    }


    case investorViewAuthAction.PROJECT_JOBS_LIST_INITIATE: {
      return {...state,  projectJobList:[]}
    }
    case investorViewAuthAction.PROJECT_JOBS_LIST_SUCCESS: {
      return {...state, projectJobList:payload}
    }
    case investorViewAuthAction.PROJECT_JOBS_LIST_FAILURE: {
      return {...state,projectJobList:[]}
    }
    case investorViewAuthAction.GET_SUB_COMMENT_INITIATE: {
      return {...state,  subCommentList:[]}
    }
    case investorViewAuthAction.GET_SUB_COMMENT_SUCCESS: {
      return {...state, subCommentList:payload}
    }
    case investorViewAuthAction.GET_SUB_COMMENT_FAILURE: {
      return {...state,subCommentList:[]}
    }

    case investorViewAuthAction.INVESTOR_BUILDER_LIST_INITIATE: {
      return {...state,  builderList:[]}
    }
    case investorViewAuthAction.INVESTOR_BUILDER_LIST_SUCCESS: {
      return {...state, builderList:payload}
    }
    case investorViewAuthAction.INVESTOR_BUILDER_LIST_FAILURE: {
      return {...state,builderList:[]}
    }

    case investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_INITIATE: {
      return {...state,  buliderByProjectList:[]}
    }
    case investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_SUCCESS: {
      return {...state, buliderByProjectList:payload}
    }
    case investorViewAuthAction.INVESTOR_BUILDER_BY_PROJECT_FAILURE: {
      return {...state,buliderByProjectList:[]}
    }

    case investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_INITIATE: {
      return {...state,  jobByProjectList:[]}
    }
    case investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_SUCCESS: {
      return {...state, jobByProjectList:payload}
    }
    case investorViewAuthAction.INVESTOR_JOB_BY_PROJECT_FAILURE: {
      return {...state,jobByProjectList:[]}
    }

    case investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_INITIATE: {
      return {...state,  folderNFileList:[]}
    }
    case investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_SUCCESS: {
      return {...state, folderNFileList:payload}
    }
    case investorViewAuthAction.INVESTOR_FOLDER_N_FILE_LIST_FAILURE: {
      return {...state,folderNFileList:[]}
    }
    default: 
    return state
}
}