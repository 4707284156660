import React, { useEffect, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CTCHistoryAction,
  TemplateHistoryAction,
  TemplateHistoryFailure,
  addAssembliesAction,
  addCTCAction,
  addCategoryAction,
  addDownloadClientBookAction,
  addDownloadPriceBookAction,
  addEntriesAction,
  addMeasureAction,
  addTemplateAction,
  addTemplatePurchaseAction,
  addUpdloadPriceBookAction,
  assemblyHistoryAction,
  categoryHistoryAction,
  dowloadTemplateAction,
  entryHistoryAction,
  getAllAssemblyListAction,
  getAllCTCListAction,
  getAllCategoryListAction,
  getAllEntryListAction,
  getAllMeasureListAction,
  getAllPermissionPriceBookAction,
  getAllTagListAction,
  getAllTemplateListAction,
  getAssemblyDetailsAction,
  getCTCAssemblyListAction,
  getCTCCategoryListAction,
  getCTCDetailsAction,
  getCategoryDetailsAction,
  getEntriesDetailsAction,
  getExcelData,
  getTemplateDetailsAction,
  importTemplateAction,
  purchaseDetailsAction,
  removeASSEMBLYAction,
  removeCTCAction,
  removeCategoryAction,
  removeEntriesAction,
  removeTemplateAction,
  updateASSEMBLYAction,
  updateAssemblyMarkupAction,
  updateCTCAction,
  updateCategoryAction,
  updateDisableTemplateAction,
  updateEnableTemplateAction,
  updateEntriesAction,
  updateEntryMarkupAction,
  updatePriceBookPermissionAction,
  updateTemplateAction,
  updateTemplatePicAction,
  uploadExcelgetData,
} from "../redux/action/priceBookAction";
import { Modal } from "react-bootstrap";
import { add, template } from "lodash";
import { errorToast, sucessToast } from "../utils/toast";
import NoDataFound from "./Nodatafound";
import { checkArray } from "../utils/CheckType";
import * as XLSX from "xlsx";
import Loader from "./Loader";
import { saveAs } from "file-saver";
import { updateLeadInitate } from "../redux/action/saleAction";
import ProformaModal from "./ProformaModal";
import sideImage from "../assets/images/icon-67.png";
import { Url } from "../Config/Config";
import {  getMasterCostCategoryAction } from "../redux/action/costProfileAction";
import { getAllCostCodeAction } from "../redux/action/authAction";
const leadInitState = {
  image: "",
  categoryType: "system_price_book",
  name: "",
  categoryError: {},
  CategoryId: "",
  entryType: "",
  AssemblyId: "",
  unitCost: "",
  MeasureId: "",
  markup: "",
  images: "",
  entryAccount: [],
  costCode: "",
  costArea: "",
  entryErrors: {},
  imagePreview: [],
  assemblyError: {},
  assemblyName: "",
  defaultMarkup: "",
  assemblyMeasureId: "",
  measureName: "",
  measureError: "",
  file: "",
  CTCError: "",
  templateName: "",
  templateError: {},
  CtcCategoryId: "",
  releaseUse: false,
  purchaseDetails: [],
  showEntries: false,
  purchaseError: [],
  systemPriceBook: false,
  clientPriceBook: false,
  purchaseDetailsData: [],
  purchaseDetailsError: {},
  CtcCategoryIdLocal: "",
  templateDetailsData: [],
  importTemplateError: "",
  staticEntryCheck: false,
  templateUploadExcelData: [],
  updloadDataOrPriceBook: false,
  filePreview:'',
  templateId:'',
  ctcCopyCategoryId:"",
  templateCreatedFrom:"",

};

const initModal = {
  addCategoryModal: false,
  edittCategoryModal: false,
  modalId: "",
  secondaryId: "",
  requiredName: "",
  accountModal: false,
  categoryHistoryModal: false,
  addEntriesModal: false,
  addFilesModal: false,
  EntryHistoryModal: false,
  EditEntryModal: false,
  editFilesModal: false,
  modalState: "",
  entryMarkupModal: false,
  assemblyModal: false,
  measureModal: false,
  uploadexcelFileModal: false,
  addCTCModal: false,
  editCTCModal: false,
  CTCHistoryModal: false,
  entryListModal: false,
  AssemblyHistoryModal: false,
  editAssemblyModal: false,
  assemblyMarkupModal: false,
  addTemplateModal: false,
  addPriceBookItemModal: false,
  permissionModal: false,
  updateTemplatePicModal: false,
  ImportTemplateModal: false,
  templateHistoryModal: false,
  updateTemplateModal: false,
  addStaticEntryModal: false,
  addCopyFromOldModal:false,
};

const PriceBook = () => {
  const [iLeadState, updateLeadState] = useState(leadInitState);
  const [iModalState, updateModalState] = useState(initModal);
  const [search, setSearch] = useState("");
  const [showEntriesMap, setShowEntriesMap] = useState({});
  const {
    addCategoryModal,
    editCategoryModal,
    addPriceBookItemModal,
    modalId,
    secondaryId,
    categoryHistoryModal,
    requiredName,
    accountModal,
    addEntriesModal,
    addFilesModal,
    EntryHistoryModal,
    EditEntryModal,
    editFilesModal,
    modalState,
    entryMarkupModal,
    assemblyModal,
    measureModal,
    uploadexcelFileModal,
    addCTCModal,
    editCTCModal,
    CTCHistoryModal,
    entryListModal,
    AssemblyHistoryModal,
    editAssemblyModal,
    assemblyMarkupModal,
    addTemplateModal,
    permissionModal,
    templateHistoryModal,
    updateTemplatePicModal,
    updateTemplateModal,
    ImportTemplateModal,

    addStaticEntryModal,
    addCopyFromOldModal,
    
  } = iModalState;
  const {
    image,
    categoryType,
    name,
    categoryError,
    CategoryId,
    entryType,
    AssemblyId,
    unitCost,
    MeasureId,
    markup,
    images,
    entryAccount,
    costCode,
    costArea,
    entryErrors,
    imagePreview,
    assemblyName,
    defaultMarkup,
    assemblyMeasureId,
    measureName,
    measureError,
    assemblyError,
    file,
    CTCError,
    templateName,
    templateError,
    CtcCategoryId,
    releaseUse,
    purchaseDetails,
    showEntries,
    purchaseError,
    systemPriceBook,
    clientPriceBook,
    purchaseDetailsData,
    purchaseDetailsError,
    CtcCategoryIdLocal,
    importTemplateError,
    staticEntryCheck,
    templateUploadExcelData,
    updloadDataOrPriceBook,
    filePreview,
    templateId,
    ctcCopyCategoryId,
    templateCreatedFrom,
    
  } = iLeadState;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    categoryListData,
    loader,
    categoryHistoryData,
    entriesListData,
    entryHistoryData,
    measureListData,
    assemblyListData,
    CTCHistoryData,
    CTCListData,
    assemblyHistoryData,
    tagListData,
    priceBooKListData,
    templateHistoryData,
    templateListData,
    templateDetailsData,
    ctcCategoryListData,
    ctcAssemblyListData,s
  } = useSelector((state) => state.PriceBookReducer);



   const {getAllCostCodeListData} = useSelector((state)=> state.authReducer)

  const {masterCostCategoryListData} = useSelector((state) => state.costReducer);

  useEffect(() => {
    dispatch(getAllCategoryListAction("system_price_book"));
    dispatch(getAllTagListAction());

    // updateLeadState({});


//     updateLeadState((prev)=>({...prev,purchaseDetailsData: [{



// "EntryId":12,
//       "dropDown": false,
//       "name": "New Custom4",
//       "measure": 40,
//       "markup": 30,
//       "quantity":1,
//       "CategoryId": 46,
//       "purchaseType":undefined,
//       "perUnit":1,






//     }]}))

  }, []);

  // useEffect(() => {
  //   updateLeadState({...iLeadState, CtcCategoryId:templateDetailsData?.ctcTemplate?.CtcCategoryId, templateName:templateDetailsData?.ctcTemplate?.name, releaseUse:templateDetailsData?.ctcTemplate?.releaseUse, images:templateDetailsData?.ctcTemplate?.images})
  // },[templateDetailsData])

  const handlePurchaseDetailsList = (value, index, secId, othervalues) => {
    let purchaseDetailsDataTemp = JSON.parse(
      JSON.stringify(purchaseDetailsData)
    );

    purchaseDetailsDataTemp.forEach((ele, indxx) => {
      console.log("eleelelee", ele);
      if (indxx == secId) {
        ele?.assemblies?.forEach((item, ind) => {
          if (ind == index) {
            item["dropDown"] = !value;
          }
        });
      }
    });

    updateLeadState({
      ...iLeadState,
      purchaseDetailsData: purchaseDetailsDataTemp,
    });
  };

  const [tableSelectedValues, setTableSelectedValues] = useState([]);
  // console.log("here id selection",tableSelectedValues)

  const handleSelection = (data, remove) => {

    if (!Array.isArray(data) && typeof data === "object") {
      if (remove) {
        setTableSelectedValues((prev) =>
          prev.filter((val) => val.id !== data.id)
        );
        return;
      } else {
        setTableSelectedValues((prev) => [...prev, data]);

        return;
      }
    } else if (Array.isArray(data)) {
      if (remove) {
        setTableSelectedValues((prev) => {
          return prev.filter((val) => {
            return !data.find((rec) => rec.id === val.id);
          });
        });
      } else {
        const newArray = new Set([...tableSelectedValues, ...data]);
        // console.log("here",newArray)
        setTableSelectedValues([...newArray]);
      }
    }
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };
  const handleInputFile = (e) => {
    const { name, value } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
      ) {
        let a = [];
        a.push(URL.createObjectURL(e.target.files[0]));
        updateLeadState({
          ...iLeadState,
          [name]: file,
          imagePreview: a,
        });
      } else {
        updateLeadState({
          ...iLeadState,
          [name]: file,
          filePreview:file.name
        });
      }
    }
  };


  const handleRemoveItem=(mainIndex, index, i)=>{
    {

      let tempArray =  [...templateUploadExcelData]
      let changeArray = tempArray[mainIndex].Assemblies[index].Entries
      changeArray.splice(i,1)

      updateLeadState((prev)=> ({...prev, templateUploadExcelData: tempArray }))





    }
  }
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };

  const handleCheckbox = (e) => {
    const { name, value, checked } = e.target;
    if (value === "system_price_book" || value === "client_price_book") {
      dispatch(getAllCategoryListAction(e.target.value));
    } else {
      dispatch(getAllCTCListAction());
    }
    if (checked == true) {
      updateLeadState({ ...iLeadState, [name]: value });
    } else {
      updateLeadState({ ...iLeadState, [name]: value });
    }
  };

  const showUploadExcelFileModal = () => {
    updateModalState({ ...iModalState, uploadexcelFileModal: true });
  };

  const hideUploadExcelFileModal = () => {
    updateModalState({ ...iModalState, uploadexcelFileModal: false });
    // updateLeadState(leadInitState);
  };

  const showEntryMarkupModal = (id, sid, name, markup) => {
    updateModalState({
      ...iModalState,
      entryMarkupModal: true,
      requiredName: name,
      modalId: id,
      secondaryId: sid,
    });
    updateLeadState({ ...iLeadState, markup: markup });
  };

  const hideEntryMarkupModal = () => {
    updateModalState({
      ...iModalState,
      entryMarkupModal: false,
      requiredName,
      modalId: "",
    });
    // updateLeadState(leadInitState);
  };

  const showEditFilesModal = () => {
    updateModalState({
      ...iModalState,
      EditEntryModal: false,
      editFilesModal: true,
    });
  };

  const hideEditFilesModal = () => {
    updateModalState({
      ...iModalState,
      EditEntryModal: true,
      editFilesModal: false,
    });
    // updateLeadState(leadInitState);
  };

  const showEditAssemblyListModal = (cid, id) => {
    dispatch(getAllMeasureListAction());
        dispatch(getAllEntryListAction(id));

    dispatch(getAssemblyDetailsAction(cid, id)).then((res) => {
      // console.log("=======>", res);
      updateLeadState({
        ...iLeadState,
        assemblyName: res?.assemblyDetails?.name,
        defaultMarkup: res?.assemblyDetails?.defaultMarkup,
        assemblyMeasureId: res?.assemblyDetails?.MeasureId,
      });
    });
    updateModalState({
      ...iModalState,
      editAssemblyModal: true,
      modalId: cid,
      secondaryId: id,
    });
  };

  const updateAssemblyFunc = () => {
    let formIsValid = handleAssemblyValidation();
    if (formIsValid) {
      let data = {
        name: assemblyName,
        defaultMarkup: +defaultMarkup,
        MeasureId: assemblyMeasureId,
      };
      dispatch(updateASSEMBLYAction(data, modalId, secondaryId)).then((res) => {
        hideEditAssemblyListModal();
        dispatch(getAllAssemblyListAction(modalId));
        sucessToast("Assembly updated successfully");
      });
    }
  };

  const hideEditAssemblyListModal = () => {
    updateModalState({ ...iModalState, editAssemblyModal: false });
  };

  console.log("ENTRY =============>",purchaseDetailsData )
  console.log("IMPORT =============>", templateCreatedFrom )


  const showAddAccountModal = (id) => {
    dispatch(getMasterCostCategoryAction()).then((res) => {
      console.log("EEEEE",res)
    })
    console.log("WORKING")
    if (id === "editEntry") {
      updateModalState({
        ...iModalState,
        accountModal: true,
        EditEntryModal: false,
        modalState: id,
      });
    } else if(id =="addEntry") {
      updateModalState({
        ...iModalState,
        accountModal: true,
        addEntriesModal: false,
        modalState: id,
      });
    }
    else{

      updateModalState({
        ...iModalState,
        accountModal: true,
        addStaticEntryModal: false,
        modalState: id,
      });

    }
  };

  const hideAddAccountModal = () => {
    if (modalState === "editEntry") {
      updateModalState({
        ...iModalState,
        accountModal: false,
        EditEntryModal: true,
      });
    } else if(modalState =="addEntry") {
      updateModalState({
        ...iModalState,
        accountModal: false,
        addEntriesModal: true,
      });
    }
    else{
      updateModalState({
        ...iModalState,
        accountModal: false,
        addStaticEntryModal: true,})

    }
  };

  const hideAddMeasureModal = () => {
    if (modalState === "entryAdd") {
      updateModalState({
        ...iModalState,
        measureModal: false,
        addEntriesModal: true,
      });
      updateLeadState({ ...iLeadState, measureName: "" });
    } else if (modalState === "assemblyAdd") {
      updateLeadState({ ...iLeadState, measureName: "" });
      updateModalState({
        ...iModalState,
        measureModal: false,
        assemblyModal: true,
        modalState: "",
      });
      updateLeadState({ ...iLeadState, measureName: "" });
    } else if (modalState === "editEntry") {
      updateModalState({
        ...iModalState,
        measureModal: false,
        EditEntryModal: true,
        modalState: "",
      });
      updateLeadState({ ...iLeadState, measureName: "" });
    } else if (modalState === "assemblyEdit") {
      updateModalState({
        ...iModalState,
        measureModal: false,
        editAssemblyModal: true,
        modalState: "",
      });
      updateLeadState({ ...iLeadState, measureName: "" });
    } else {
      updateLeadState({ ...iLeadState, measureName: "" });
      updateModalState({ ...iModalState, measureModal: false });
    }
  };

  const showAddMeasureModal = (id, name) => {
    if (id === "entryAdd") {
      updateModalState({
        ...iModalState,
        measureModal: true,
        addEntriesModal: false,
        modalState: id,
      });
    } else if (id === "assemblyAdd") {
      updateModalState({
        ...iModalState,
        measureModal: true,
        assemblyModal: false,
        modalState: id,
      });
    } else if (id === "editEntry") {
      updateModalState({
        ...iModalState,
        measureModal: true,
        EditEntryModal: false,
        modalState: id,
      });
    } else if (id === "assemblyEdit") {
      updateModalState({
        ...iModalState,
        measureModal: true,
        editAssemblyModal: false,
        modalState: id,
      });
    } else {
      updateModalState({ ...iModalState, measureModal: true, modalState: "" });
    }
  };

  const hideCategoryHistoryModal = () => {
    updateModalState({ ...iModalState, categoryHistoryModal: false });
    // updateLeadState(leadInitState);
  };

  const showUpdateCategoryModal = (id, type) => {
    dispatch(getCategoryDetailsAction(id, type)).then((res) => {
      updateLeadState({
        ...iLeadState,
        categoryType: res?.categoryDetails?.categoryType,
        name: res?.categoryDetails?.name,
        image: res?.categoryDetails?.imageUrl,
      });
    });
    updateModalState({ ...iModalState, editCategoryModal: true, modalId: id });
  };

  const hideUpdateCategoryModal = () => {
    updateModalState({ ...iModalState, editCategoryModal: false });
    // updateLeadState(leadInitState);
  };

  // CATEGORYFUNC

  const showAddCategoryModal = (id) => {
    if (id === "entryAdd") {
      updateModalState({
        ...iModalState,
        addCategoryModal: true,
        addEntriesModal: false,
        modalState: id,
      });
    } else if (id === "editEntry") {
      updateModalState({
        ...iModalState,
        addCategoryModal: true,
        modalState: id,
        EditEntryModal: false,
      });
    } else {
      updateModalState({
        ...iModalState,
        addCategoryModal: true,
        modalState: id,
      });
    }
  };

  const hideAddCategoryModal = () => {
    if (modalState === "entryAdd") {
      updateModalState({
        ...iModalState,
        addCategoryModal: false,
        addEntriesModal: true,
        modalState: "",
      });
      // updateLeadState(leadInitState);
    } else if (modalState === "editEntry") {
      updateModalState({
        ...iModalState,
        addCategoryModal: false,
        modalState: "",
        EditEntryModal: true,
      });
      // updateLeadState(leadInitState);
    } else {
      updateModalState({
        ...iModalState,
        addCategoryModal: false,
        modalState: "",
      });
      // updateLeadState(leadInitState);
    }
  };

  const handleAddCategoryValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }
    // if(!categoryType){
    //   error.categoryTypeError = "Category type can't be empty";
    //   formIsValid = false
    // }

    updateLeadState({ ...iLeadState, categoryError: error });
    return formIsValid;
  };

  const addCategoryFun = (e) => {
    e.preventDefault();
    let formIsValid = handleAddCategoryValidation();
    if (formIsValid) {
      let data = {
        image,
        name,
        categoryType,
      };
      dispatch(addCategoryAction(data)).then((res) => {
        sucessToast("Category added successfully");
        hideAddCategoryModal();
        dispatch(getAllCategoryListAction(categoryType));
      });
    }
  };

  const updateCategoryFun = (e) => {
    e.preventDefault();
    let formIsValid = handleAddCategoryValidation();
    if (formIsValid) {
      let data = {
        name,
        categoryType,
      };
      if (typeof image == "object") {
        data["image"] = image;
      }

      dispatch(updateCategoryAction(data, modalId)).then((res) => {
        sucessToast("Category updated successfully");
        hideUpdateCategoryModal();
        dispatch(getAllCategoryListAction(categoryType));
      });
    }
  };

  const removeCategory = (id, type) => {
    dispatch(removeCategoryAction(id, type)).then((res) => {
      sucessToast("Category removed successfully");
      dispatch(getAllCategoryListAction(type));
    });
  };

  // fileUploadFunc

  const handleAddDownloadPriceBook = (e) => {
    e.preventDefault();
    dispatch(addDownloadPriceBookAction())
      .then((res) => {
        // console.log("rs=======>", res.data);
        // updateState({...iState, xmlResponse:res.data})
        handleConvertToExcel(
          res.data.xlsxFileHead,
          res.data.rows,
          "System Price Book"
        );
      })
      .catch((err) => {
        errorToast(err.response?.data?.message);
      });
  };

  const handleAddDownloadClientPriceBook = (e) => {
    e.preventDefault();
    dispatch(addDownloadClientBookAction())
      .then((res) => {
        // updateState({...iState, xmlResponse:res.data})
        handleConvertToExcel(
          res.data.xlsxFileHead,
          res.data.rows,
          "Client Price Book"
        );
      })
      .catch((err) => {
        errorToast(err.response?.data?.message);
      });
  };

  const handleConvertToExcel = (headers, data, fileName = "PriceBook") => {
    if (!Array.isArray(data)) {
      // console.error("Data is not an array:", data);
      return;
    }

    if (data.length === 0) {
      return;
    }

    const dataArray = data.map((item) => headers.map((key) => item[key]));
    const ws = XLSX.utils.aoa_to_sheet([headers, ...dataArray]); // Append headers and data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob(
      [s2ab(XLSX.write(wb, { bookType: "xlsx", type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    saveAs(blob, `${fileName}.xlsx`);
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const addExcelFileUpload = (e) => {
    e.preventDefault();
    dispatch(addUpdloadPriceBookAction({ file }))
      .then((res) => {
        sucessToast("Price book uploaded successfully");
        hideUploadExcelFileModal();
      })
      .catch((err) => {
        errorToast(err.response?.data?.message);
      });
  };

  // ENTRYFUNCTION

  const showAddFilesModal = (state) => {
    if (state === "Template") {
      updateModalState({
        ...iModalState,
        addTemplateModal: false,
        addFilesModal: true,
        modalState: state,
      });
    } else if (state === "editEntry") {
      updateModalState({
        ...iModalState,
        EditEntryModal: false,
        addFilesModal: true,
        modalState: state,
      });
    } else if (state === "editTemplate") {
      updateModalState({
        ...iModalState,
        updateTemplateModal: false,
        addFilesModal: true,
        modalState: state,
      });
    } else {
      updateModalState({
        ...iModalState,
        addEntriesModal: false,
        addFilesModal: true,
      });
    }
  };

  const hideAddFilesModal = () => {
    if (modalState === "Template") {
      updateModalState({
        ...iModalState,
        addTemplateModal: true,
        addFilesModal: false,
      });
    } else if (modalState === "editEntry") {
      updateModalState({
        ...iModalState,
        EditEntryModal: true,
        addFilesModal: false,
        modalState: "",
      });
    } else if (modalState === "editTemplate") {
      updateModalState({
        ...iModalState,
        updateTemplateModal: true,
        addFilesModal: false,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        addEntriesModal: true,
        addFilesModal: false,
      });
    }
  };

  const showAddEntriesModal = () => {
    dispatch(getAllMeasureListAction());
    dispatch(getAllAssemblyListAction());
    updateModalState({ ...iModalState, addEntriesModal: true });
  };



  useEffect(() => {
    dispatch(getAllAssemblyListAction(CategoryId));
    // dispatch(getAllEntryListAction())
  }, [CategoryId]);
  const hideAddEntriesModal = () => {
    updateModalState({ ...iModalState, addEntriesModal: false });
    // updateLeadState(leadInitState);
  };

  const showEntryListModal = (id, sid) => {
    dispatch(getAllEntryListAction(sid));
    updateModalState({ ...iModalState, entryListModal: true });
  };

  const hideentryListModal = () => {
    updateModalState({ ...iModalState, entryListModal: false });
  };

  const showEditEntriesModal = (id, cid) => {
    dispatch(getAllAssemblyListAction(cid));
    dispatch(getAllMeasureListAction());
    dispatch(getEntriesDetailsAction(id)).then((res) => {
      updateLeadState({
        ...iLeadState,
        // categoryType: res?.entryDetails?.categoryType,
        name: res?.entryDetails?.name,
        CategoryId: res?.entryDetails?.CategoryId,
        entryType: res?.entryDetails?.entryType,
        AssemblyId: res?.entryDetails?.AssemblyId,
        unitCost: res?.entryDetails?.unitCost,
        MeasureId: res?.entryDetails?.MeasureId,
        markup: res?.entryDetails?.markup,
        images: res?.imageData?.[0]?.imageUrl,
        entryAccount: res?.entryAccounts?.map((ele, i) => {
          return { costCode: ele?.costCode, costArea: ele?.costArea };
        }),
      });
    });

    updateModalState({
      ...iModalState,
      EditEntryModal: true,
      modalId: id,
      entryListModal: false,
    });
  };

  const hideEditEntriesModal = () => {
    updateModalState({
      ...iModalState,
      EditEntryModal: false,
      entryListModal: true,
    });
  };

  const showEntryHistoryModal = (id, name) => {
    dispatch(entryHistoryAction(id));
    updateModalState({
      ...iModalState,
      EntryHistoryModal: true,
      requiredName: name,
      entryListModal: false, 
    });
  };

  const hideEntryHistoryModal = () => {
    updateModalState({
      ...iModalState,
      EntryHistoryModal: false,
      entryListModal: true,
    });
  };

  const handleAddEntryValidation = () => {
    let formIsValid = true;
    let error = {};
    if (!name) {
      error.nameError = "Name can't be empty";
      formIsValid = false;
    }
    if (!CategoryId) {
      error.CategoryIdError = "Category can't be empty";
      formIsValid = false;
    }
    //  if(!entryType){
    //   error.entryTypeError = "Entry type can't be empty";
    //   formIsValid = false
    //  }
    if (entryType === "Assembly") {
      if (!AssemblyId) {
        error.AssemblyIdError = "Assembly can't be empty";
        formIsValid = false;
      }
    }

    if (!unitCost) {
      error.unitCostError = "Unit cost can't be empty";
      formIsValid = false;
    }
    if (!MeasureId) {
      error.MeasureIdError = "Measure id can't be empty";
      formIsValid = false;
    }

   
    if (!markup) {
      error.markupError = "Markup can't be empty";
      formIsValid = false;
    }

    if (entryAccount?.length === 0) {
      error.entryAccountError = "Entry account can't be empty";
      formIsValid = false;
    }

    updateLeadState({ ...iLeadState, entryErrors: error });
    return formIsValid;
  };

  const addFilesFun = () => {
    let a = [...entryAccount];

    if (costArea && costCode) {
      a.push({
        costCode,
        costArea,
      });
      updateLeadState({
        ...iLeadState,
        entryAccount: a,
        costArea: "",
        costCode: "",
      });
    }
  };

  const addEntryFun = () => {
    let formIsValid = handleAddEntryValidation();
    if (formIsValid) {
      let data = {
        CategoryId: +CategoryId,
        name,
        entryType,
        // AssemblyId: +AssemblyId,
        unitCost,
        MeasureId: +MeasureId,
        markup,
        entryAccount,
      };

      if(images&&images!==undefined){
        data['images']= images
      }

      if (entryType === "Assembly") {
        data["AssemblyId"] = +AssemblyId;
      }

      // if(entryType === "Assembly"){
      //   data["AssemblyId"] =  +AssemblyId,
      // }
      // console.log("dataaaaaaaaaaaaaa", data);
      dispatch(addEntriesAction(data)).then((res) => {
        sucessToast("Entries added successfully");
        // updateLeadState(leadInitState);
        // dispatch(getAllEntryListAction());

        dispatch(getAllAssemblyListAction(CategoryId));


        let tempArray = []
        tempArray.push({"EntryId":res.data.entryDetails.id,
          "dropDown": false,
          "name": res.data.entryDetails.name,
          "measure": 40,
          "markup": 30,
          "quantity":1,
          "CategoryId": res.data.entryDetails.CategoryId,
          "AssemblyId":res.data.entryDetails.AssemblyId,
          "purchaseType":undefined,
          "perUnit":res.data.entryDetails.unitCost,})

          updateLeadState((prev)=> ({...prev, purchaseDetailsData:tempArray,updloadDataOrPriceBook:false}))

      
      });


      updateModalState({...iModalState,addStaticEntryModal:false, addTemplateModal:true })

    }
  };

  const removeEntryAccount = (i) => {
    let data = [...entryAccount];
    data.splice(i, 1);
    updateLeadState({ ...iLeadState, entryAccount: data });
  };

  const deleteEntryFun = (id, sid, tid) => {
    dispatch(removeEntriesAction(id)).then((res) => {
      sucessToast("Entry removed successfully");

      if (sid !== null) {
        dispatch(getAllEntryListAction(sid));
      }

      dispatch(getAllAssemblyListAction(tid));
    });
  };

  const editEntryFun = () => {
    let formIsValid = handleAddEntryValidation();
    if (formIsValid) {
      let data = {
        CategoryId: +CategoryId,
        name,
        entryType,
        // AssemblyId: +AssemblyId,
        unitCost,
        MeasureId: +MeasureId,
        markup,
        // images ,
        entryAccount,
      };
      if (typeof images == "object") {
        data["images"] = images;
      }
      if (entryType === "Assembly") {
        data["AssemblyId"] = +AssemblyId;
      }

      dispatch(updateEntriesAction(data, modalId))
        .then((res) => {
          dispatch(getAllEntryListAction(AssemblyId));
          sucessToast("Entires updated successfully");
          hideEditEntriesModal();
        })
        .catch((err) => {
          errorToast(err.response?.data?.message);
        });
    }
  };

  const updateMarkupFun = (e, sid) => {
    e.preventDefault();
    if (!markup && requiredName) {
      updateLeadState({ ...iLeadState, markupError: "Markup can't be empty" });
    } else {
      dispatch(updateEntryMarkupAction({ markup, name: requiredName }, modalId))
        .then((res) => {
          sucessToast("Markup updated successfully");
          dispatch(getAllEntryListAction(secondaryId));
          hideEntryMarkupModal();
        })
        .catch((err) => {
          errorToast(err.response?.data?.message);
        });
    }
  };

  // ASSEMBLYFUNCTION

  const showAssemblyHistoryModal = (id, secId, name) => {
    dispatch(assemblyHistoryAction(id, secId));
    updateModalState({
      ...iModalState,
      AssemblyHistoryModal: true,
      requiredName: name,
    });
  };

  const hideAssemblyHistoryModal = () => {
    updateModalState({ ...iModalState, AssemblyHistoryModal: false });
  };

  const showCategoryHistoryModal = (id, name) => {
    dispatch(categoryHistoryAction(id));
    updateModalState({
      ...iModalState,
      categoryHistoryModal: true,
      requiredName: name,
    });
  };

  const hideAddAssembldyModal = () => {
    if (modalState === "entryAdd" || "assemblyAdd") {
      updateModalState({
        ...iModalState,
        assemblyModal: false,
        addEntriesModal: true,
        modalState: "",
      });
      updateLeadState({
        ...iLeadState,
        name: "",
        defaultMarkup: "",
        MeasureId: "",
      });
    } else if (modalState === "editEntry") {
      updateModalState({
        ...iModalState,
        assemblyModal: false,
        modalState: "",
        EditEntryModal: true,
      });
      updateLeadState({
        ...iLeadState,
        name: "",
        defaultMarkup: "",
        MeasureId: "",
      });
    } else {
      updateModalState({ ...iModalState, assemblyModal: true });
    }
  };

  const showAddAssemblyModal = (id, name) => {
    dispatch(getAllMeasureListAction());
    if (id === "entryAdd") {
      updateModalState({
        ...iModalState,
        assemblyModal: true,
        addEntriesModal: false,
        modalState: id,
      });
    } else if (id === "editEntry") {
      updateModalState({
        ...iModalState,
        assemblyModal: true,
        modalState: id,
        EditEntryModal: false,
      });
    } else {
      updateModalState({ ...iModalState, assemblyModal: true });
    }
  };

  const handleAssemblyValidation = () => {
    let error = {};
    let formIsValid = true;

    if (!assemblyName) {
      error.assemblyNameError = "Assembly name can't be empty";
      formIsValid = false;
    }

    if (!defaultMarkup) {
      error.defaultMarkupError = "Default markup can't be empty";
      formIsValid = false;
    }

    if (!assemblyMeasureId) {
      error.assemblyMeasureIdError = "Measure can't be empty";
      formIsValid = false;
    }
    updateLeadState({ ...iLeadState, assemblyError: error });
    return formIsValid;
  };

  const addAssemblyFunc = () => {
    let formIsValid = handleAssemblyValidation();
    if (formIsValid) {
      dispatch(
        addAssembliesAction(CategoryId, {
          name: assemblyName,
          defaultMarkup,
          MeasureId: assemblyMeasureId,
        })
      ).then((Res) => {
        console.log("resss=====>", Res);
        hideAddAssembldyModal();
        dispatch(getAllAssemblyListAction(CategoryId));
      });
    }
  };

  const showAssemblyMarkupModal = (id, secId, name, markup) => {
    updateModalState({
      ...iModalState,
      assemblyMarkupModal: true,
      requiredName: name,
      modalId: id,
      secondaryId: secId,
      // defaultMarkup:markup
    });
    updateLeadState({ ...iLeadState, defaultMarkup: markup });
  };

  const hideAssemblyMarkupModal = () => {
    updateModalState({
      ...iModalState,
      assemblyMarkupModal: false,
      requiredName: "",
      modalId: "",
      secondaryId: "",
    });
    // updateLeadState(leadInitState);
  };

  const addMeasureFunc = () => {
    if (!measureName) {
      updateLeadState({
        ...iLeadState,
        measureError: "Measure Id can't be empty",
      });
    } else {
      dispatch(addMeasureAction({ name: measureName })).then((Res) => {
        sucessToast("Measure added successfully");
        hideAddMeasureModal();
        dispatch(getAllMeasureListAction());
      });
    }
  };

  const updateAssemblyMarkupFun = (e) => {
    e.preventDefault();
    if (!markup && !requiredName) {
      updateLeadState({ ...iLeadState, markupError: "Markup can't be empty" });
    } else {
      dispatch(
        updateAssemblyMarkupAction(modalId, secondaryId, {
          defaultMarkup,
        })
      )
        .then((res) => {
          sucessToast("Markup updated successfully");
          dispatch(getAllAssemblyListAction(modalId));
          hideAssemblyMarkupModal();
        })
        .catch((err) => {
          errorToast(err.response?.data?.message);
        });
    }
  };

  const hideAddCTCModal = () => {
    updateModalState({ ...iModalState, addCTCModal: false });
  };

  const showAddCTCModal = (type) => {
    updateModalState({ ...iModalState, addCTCModal: true, categoryType: type });
  };

  const hideUpdateCTCModal = () => {
    updateModalState({ ...iModalState, editCTCModal: false });
  };

  const showUpdateCTCModal = (id, name, type) => {
    // console.log("namee,,,,,", name);
    // dispatch(getCTCDetailsAction(id)).then((res) => {
    //   console.log("resss", res)
    updateLeadState({ ...iLeadState, name: name });
    // })
    updateModalState({ ...iModalState, editCTCModal: true, modalId: id });
  };

  const hideCTCHistoryModal = () => {
    updateModalState({
      ...iModalState,
      CTCHistoryModal: false,
      requiredName: "",
    });
  };

  const showCTCHistoryModal = (id, name) => {
    dispatch(CTCHistoryAction(id));
    updateModalState({
      ...iModalState,
      CTCHistoryModal: true,
      requiredName: name,
    });
  };

  const updateCTCFun = (e) => {
    e.preventDefault();
    if (!name) {
      updateLeadState({ ...iLeadState, CTCError: "CTC error can't be empty" });
    } else {
      dispatch(updateCTCAction({ name }, modalId))
        .then((res) => {
          console.log("resss", res);
          hideUpdateCTCModal();
          dispatch(getAllCTCListAction());
        })
        .catch((err) => {});
    }
  };

  const addCTCFun = (e) => {
    e.preventDefault();
    if (!name) {
      updateLeadState({ ...iLeadState, CTCError: "CTC error can't be empty" });
    } else {
      dispatch(addCTCAction({ name }))
        .then((res) => {
          hideAddCTCModal();
          dispatch(getAllCTCListAction());
        })
        .catch((err) => {
          errorToast(err.response?.data?.message);
        });
    }
  };

  const removeCTCFUn = (id) => {
    dispatch(removeCTCAction(id)).then((res) => {
      dispatch(getAllCTCListAction());
    });
  };

  // const removeAssemblies = (id, secId) => {
  //   dispatch(removeASSEMBLYAction(id, secId)).then((res) => {
  //     dispatch(getAllAssemblyListAction(id));
  //   });
  // };

  const storeCategoryId = (id, type) => {
    updateLeadState({ ...iLeadState, CategoryId: id, categoryType: type });
    dispatch(getAllAssemblyListAction(id));
  };

  const deleteAssemblyFun = (cid, id) => {
    dispatch(removeASSEMBLYAction(cid, id)).then((res) => {
      dispatch(getAllAssemblyListAction(id));
    });
  };

  const tagsFilteredData = (ele) => {
    dispatch(getAllAssemblyListAction(CategoryId, "", ele));
  };

  // TEMPLATEFUNCTION

  const hideAddTemplateModal = () => {
    updateModalState({ ...iModalState, addTemplateModal: false });
    updateLeadState({...iLeadState, templateId:""})
  };

  const showAddTemplateModal = (type) => {
    updateModalState({ ...iModalState, addTemplateModal: true });
    // updateLeadState(leadInitState);




    updateLeadState({
      ...iLeadState,
      templateUploadExcelData:[],
      purchaseDetailsData:[]
    });

  };

  const hideAddPriceBookItemModal = () => {
    if (modalState === "addTemplate") {
      updateModalState({
        ...iModalState,
        addPriceBookItemModal: false,
        addTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addPriceBookItemModal: false,
        updateTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };

  const hideAddCopyFromOldModal = () => {
    if (modalState === "addTemplate") {
      updateModalState({
        ...iModalState,
        addCopyFromOldModal: false,
        addTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addCopyFromOldModal: false,
        addTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };

  const showAddPriceBookItemModal = (type) => {
    if (type === "addTemplate") {
      updateModalState({
        ...iModalState,
        addPriceBookItemModal: true,
        addTemplateModal: false,
        modalState: type,
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addPriceBookItemModal: true,
        updateTemplateModal: false,
        modalState: type,
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };

  
  const showAddCopyFromOldModal = (type) => {
    if (type === "addTemplate") {
      dispatch(getCTCCategoryListAction(CtcCategoryId, templateId))
      updateModalState({
        ...iModalState,
        addCopyFromOldModal: true,
        addTemplateModal: false,
        modalState: type,
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addCopyFromOldModal: true,
        updateTemplateModal: false,
        modalState: type,
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };


  const handleTemplateValidaiton = () => {
    let formIsValid = true;
    let error = {};

    if (!templateName) {
      error.templateNameError = "Template name can't be empty";
      formIsValid = false;
    }

 
    if (!CtcCategoryId) {
      error.CtcCategoryIdError = "Ctc category id can't be empty";
      formIsValid = false;
    }

    // if (purchaseDetailsData?.length === 0) {
    //   error.purchaseDetailsError = "Purchase details data can't be empty";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, templateError: error });
    return formIsValid;
  };

  useEffect(() => {
    if (assemblyListData?.assemblyDetails) {
      const initialShowEntriesMap = {};
      assemblyListData.assemblyDetails.forEach((ele) => {
        initialShowEntriesMap[ele.name] = false;
      });
      setShowEntriesMap(initialShowEntriesMap);
    }
  }, [assemblyListData]);

  // const handleCheckBoxAssembly = (e,data) => {
  //    const {name,value,checked} = e.target;
  //     console.log("handleCheckBoxAssembly======>",data)
  //       entryListData
  // }

  const removePurchase = (i) => {
    let data = [...tableSelectedValues];
    data.splice(i, 1);
    setTableSelectedValues(data);
  };

  const handlePurchaseValidation = () => {
    let formIsValid = true;
    let error = {};
    if (tableSelectedValues?.length === 0) {
      error.CategoryIdError = "* Please enter the proper data";
      formIsValid = false;
    }
    // if (tableSelectedValues.some((x) => x.quantity?toString().trim() == "")) {
    //   error.quantityError = "*  Quantity price Can't be empty";
    //   formIsValid = false;
    // }

    // if (tableSelectedValues.some((x) => x.CategoryId.toString().trim() == "")) {
    //   error.categoryIdError = "* Please enter the proper data";
    //   formIsValid = false;
    // }
    // if (tableSelectedValues.some((x) => x.CategoryId.toString().trim() == "")) {
    //   error.categoryIdError = "* Please enter the proper data";
    //   formIsValid = false;
    // }

    updateLeadState({ ...iLeadState, purchaseDetailsError: error });
    return formIsValid;
  };

  const addItemsToPurchase = (e) => {
    e.preventDefault();
    let formIsValid = handlePurchaseValidation();
    if (formIsValid) {
      let data = tableSelectedValues?.map((item) => {
        return {
          CategoryId: item?.CategoryId,
          AssemblyId: item?.AssemblyId,
          EntryId: item?.id,
          quantity: item?.quantity,
          perUnit: item?.unitCost,
          markup: item?.markup,
          name: item?.name,
        };
      });

      dispatch(addTemplatePurchaseAction({ arr: data })).then((res) => {
        hideAddPriceBookItemModal();
        updateLeadState({ ...iLeadState, purchaseDetailsData: res.categories , updloadDataOrPriceBook: false,
        });
      });
    }
  };

  function addQuantityKey(quantity) {
    let data = tableSelectedValues.forEach((item) => {
      item.quantity = quantity;
      // Set the "quantity" key to the desired value
    });

    // console.log("addQuantityKeyaddQuantityKey",data)
  }

  const showPermissionModal = () => {
    dispatch(getAllPermissionPriceBookAction()).then((res) => {
      updateLeadState({
        ...iLeadState,
        systemPriceBook: res?.data?.systemPriceBook,
        clientPriceBook: res?.data?.clientPriceBook,
      });
    });
    updateModalState({ ...iModalState, permissionModal: true });
  };
  const hidePermissionModal = () => {
    updateModalState({ ...iModalState, permissionModal: false });
  };

  function handleRadio(event) {
    const selectedValue = event.target.value;

    if (selectedValue === "systemPriceBook") {
      updateLeadState({
        ...iLeadState,
        systemPriceBook: true,
        clientPriceBook: false,
      });
    } else if (selectedValue === "clientPriceBook") {
      updateLeadState({
        ...iLeadState,
        systemPriceBook: false,
        clientPriceBook: true,
      });
    }
  }

  const updatePermission = (e) => {
    e.preventDefault();
    let data = {
      systemPriceBook,
      clientPriceBook,
    };

    dispatch(updatePriceBookPermissionAction(data)).then((res) => {
      dispatch(getAllPermissionPriceBookAction());
      hidePermissionModal();
      sucessToast("Permission updated successfully");
      dispatch(getAllAssemblyListAction(CategoryId));
    });
  };

  const addTemplateFunc = (type) => {
    // Define the dynamic data
    let formIsValid = handleTemplateValidaiton();

    console.log("ADDED FROM===================> ",templateCreatedFrom)
    if (formIsValid) {
      const dynamicData = {
        name: templateName,
        releaseUse: releaseUse || false,
        ctcCategryId :CtcCategoryId ,
        CtcCategoryId,
        purchaseDetails: [],
      };



      
      if(templateCreatedFrom=="custom"){
        console.log("WORKING ================>")
        dynamicData["purchaseDetails"]=purchaseDetailsData

      }


      if(templateCreatedFrom=="pricebook"){
        dynamicData["purchaseDetails"]=purchaseDetailsData?.[0]?.assemblies?.[0]?.purchaseDetails

      }

      if(templateCreatedFrom=="copy"){
        dynamicData["purchaseDetails"]=(purchaseDetailsData?.[0]?.assemblies?.[0]?.purchaseDetails)

      }

     

      if(templateCreatedFrom=="import"){

        templateUploadExcelData.forEach((item, index) => {
          if (item.Assemblies.length > 0) {
            // console.log("lfldfsdjglkfdsgjkfgfkl")
            dynamicData.purchaseDetails.push({
              dropDown: item?.Assemblies[0]?.dropDown || false,
              name: item.Assemblies[0].name,
              measure: item.Assemblies[0].measure,
              purchaseType: "Assembly",
  
              markup: item.assemblies?.[0]?.purchaseDetails?.[0]?.markup,
              perUnit: item.assemblies?.[0]?.purchaseDetails?.[0]?.perUnit,
              quantity: item.assemblies?.[0]?.purchaseDetails?.[0]?.quantity,
  
              CategoryId: item?.Assemblies[0]?.CategoryId,
              AssemblyId: item?.Assemblies[0]?.id,
              EntryId: item?.Assemblies[0]?.Entries?.[0]?.id ?? 1,
  
              // Add other properties as needed
            });
          } else if (item?.standalones?.length > 0) {
            dynamicData.purchaseDetails.push({
              name: item.standalones[0].name,
              quantity: item?.standalones?.[0]?.quantity,
              measure: item?.standalones[0]?.measure,
              perUnit: item?.standalones?.[0]?.perUnit,
              markup: item?.standalones?.[0]?.markup,
              dropDown: false,
              CategoryId: item.standalones?.[0]?.CategoryId,
              purchaseType: "Standalone",
  
              EntryId: item?.standalones?.[0]?.EntryId,
            });
          }
        });
  
        
      }

       if(images){
        dynamicData['images'] = images
        
       }

      dispatch(addTemplateAction(CtcCategoryId, dynamicData)).then((res) => {
        sucessToast("Template created successfully");
        hideAddTemplateModal();
        updateLeadState({
          ...iLeadState,
          purchaseDetailsData: [],
          templateName: "",
          CtcCategoryId: "",
          images: "",
        });
        dispatch(getAllTemplateListAction(CtcCategoryId));
        updateModalState({...iModalState,
        addCopyFromOldModal: false,
        addTemplateModal:false,
        modalState: "",
      });      });
    }
  };

  const goToCtcTemplate = (id) => {
    dispatch(getAllTemplateListAction(id));
    updateLeadState({ ...iLeadState, CtcCategoryIdLocal: id , CtcCategoryId:id});
  };

  const deleteTemplate = (id) => {
    dispatch(removeTemplateAction(CtcCategoryIdLocal, id)).then((res) => {
      dispatch(getAllTemplateListAction(CtcCategoryIdLocal));
      sucessToast("Template removed successfully");
    });
  };

  const releaseTemplate = (id) => {
    dispatch(updateEnableTemplateAction(CtcCategoryIdLocal, id)).then((res) => {
      dispatch(getAllTemplateListAction(CtcCategoryIdLocal));
    });
  };

  const disableTemplate = (id) => {
    dispatch(updateDisableTemplateAction(CtcCategoryIdLocal, id)).then(
      (res) => {
        dispatch(getAllTemplateListAction(CtcCategoryIdLocal));
      }
    );
  };

  const hideTemplateHistoryModal = () => {
    updateModalState({ ...iModalState, templateHistoryModal: false });
  };

  const showTemplateHistoryModal = (id) => {
    dispatch(TemplateHistoryAction(CtcCategoryIdLocal, id));
    updateModalState({ ...iModalState, templateHistoryModal: true });
  };

  const showUpdateTemplatePicModal = (id) => {
    updateModalState({
      ...iModalState,
      updateTemplatePicModal: true,
      modalId: id,
    });
  };

  const hideUpdateTemplatePicModal = (id) => {
    updateModalState({
      ...iModalState,
      updateTemplatePicModal: false,
      modalId: "",
    });
  };

  const updateTemplatePicfun = (item) => {
    if (!images) {
    } else {
      dispatch(
        updateTemplatePicAction({ images }, CtcCategoryIdLocal, modalId)
      ).then((res) => {
        sucessToast("Template picture updated successfully");
        dispatch(getAllTemplateListAction(CtcCategoryIdLocal));
                hideUpdateTemplatePicModal();
      });
    }
  };

  const showUpdateTemplateModal = (cid, id, item) => {
    dispatch(getAllCTCListAction());
    dispatch(getTemplateDetailsAction(cid, id));

    dispatch(purchaseDetailsAction(cid, id)).then((res) => {
      updateLeadState((prev)=> ({...prev,
        
        purchaseDetailsData: res?.data?.categories,
        templateName: templateDetailsData?.ctcTemplate?.name,
        CtcCategoryId: templateDetailsData?.ctcTemplate?.CtcCategoryId,
        releaseUse: templateDetailsData?.ctcTemplate?.releaseUse,
        images: templateDetailsData?.ctcTemplate?.images,
      }));
    });


    updateLeadState((prev)=> ({...prev,
        
      purchaseDetailsData: item.categories,
      templateName: item?.name,
      CtcCategoryId:item.CtcCategoryId,
      releaseUse: item.releaseUse,
          images: item.images,
    }))
    updateModalState({
      ...iModalState,
      updateTemplateModal: true,
      modalId: id,
    });
  };

  const hideUpdateTemplateModal = (id) => {
    updateModalState({
      ...iModalState,
      updateTemplateModal: false,
      modalId: "",
    });
  };

  const updateTemplateFun = () => {
    let formIsValid = handleTemplateValidaiton();

    if (formIsValid) {
      const dynamicData = {
        name: templateName,
        releaseUse,
        CtcCategoryId,
        // images:images,
        purchaseDetails: [],
      };
      purchaseDetailsData.forEach((item, index) => {
        if (item.assemblies.length > 0) {
          // console.log("lfldfsdjglkfdsgjkfgfkl")
          dynamicData.purchaseDetails.push({
            dropDown: item?.assemblies[0]?.dropDown,
            name: item.assemblies[0].name,
            measure: item.assemblies[0].measure,
            purchaseType: "Assembly",

            markup: item.assemblies?.[0]?.purchaseDetails?.[0]?.markup,
            perUnit: item.assemblies?.[0]?.purchaseDetails?.[0]?.perUnit,
            quantity: item.assemblies?.[0]?.purchaseDetails?.[0]?.quantity,

            CategoryId: item.assemblies?.[0]?.purchaseDetails?.[0]?.CategoryId,
            AssemblyId: item?.assemblies?.[0]?.purchaseDetails?.[0]?.AssemblyId,
            EntryId: item.assemblies?.[0]?.purchaseDetails[0]?.EntryId,

            // Add other properties as needed
          });
        } else if (item.standalones.length > 0) {
          dynamicData.purchaseDetails.push({
            name: item.standalones[0].name,
            quantity: item?.standalones?.[0]?.quantity,
            measure: item?.standalones[0]?.measure,
            perUnit: item?.standalones?.[0]?.perUnit,
            markup: item?.standalones?.[0]?.markup,
            dropDown: false,
            CategoryId: item.standalones?.[0]?.CategoryId,
            purchaseType: "Standalone",

            EntryId: item?.standalones?.[0]?.EntryId,
          });
        }
      });
      if (typeof images == "object") {
        dynamicData["images"] = images;
      }
      dispatch(updateTemplateAction(CtcCategoryId, modalId, dynamicData)).then(
        (res) => {
          sucessToast("Template updated successfully");
          hideUpdateTemplateModal();
          dispatch(getAllTemplateListAction(CtcCategoryId));
          updateLeadState({
            ...iLeadState,
            templateUploadExcelData: [],
            templateName: "",
            CtcCategoryId: "",
          });
        }
      );
    }
  };

  const hideImportTemplateModal = () => {
    if (modalState === "addTemplate") {
      updateModalState({
        ...iModalState,
        ImportTemplateModal: false,
        addTemplateModal: true,
        modalState: "",
      });
    } else {
      updateModalState({
        ...iModalState,
        ImportTemplateModal: false,
        updateTemplateModal: true,
        modalState: "",
      });
    }
  };

  const showImportTemplateModal = (type) => {
    if (type === "addTemplate") {
      updateModalState({
        ...iModalState,
        ImportTemplateModal: true,
        addTemplateModal: false,
        modalState: type,
      });
    } else {
      updateModalState({
        ...iModalState,
        ImportTemplateModal: true,
        updateTemplateModal: false,
        modalState: type,
      });
    }
  };

  const addImportTemplateFun = (e) => {
    e.preventDefault();
    if (!file) {
      updateLeadState({
        ...iLeadState,
        importTemplateError: "File can't be empty",
      });
    } else {
      dispatch(importTemplateAction({ file }))
        .then((res) => {

          console.log(res?.data, "===================> RES")
          sucessToast("Template imported successfully");
          hideImportTemplateModal();
          dispatch(getExcelData(res?.uniqueId)).then((res) => {
            updateLeadState({
              ...iLeadState,
              templateUploadExcelData: res?.details, 
              updloadDataOrPriceBook: true,
            });
          });
        })
        .catch((err) => {
          errorToast("Upload the correct data");
        });
    }
  };

  // const downloadRowData = (rowData) => {
  //   // Define the header row
  //   const headerRow = 'Owner Name,Nid,Number,Created On,\n';

  //   // Convert the row data to a CSV string

  //   let csvData=rowData?.map((ele) => (
  //     `${ele?.fullName},${ele?.nid},${ele?.phoneNumber},${ele?.createdAt?.split("T")[0]},\n`
  //   ))
  //  console.log("csvData", csvData)
  //   // Combine the header row and row data into a single string
  //   const csvString =headerRow + csvData.join("");
  //   console.log("csvData", csvString)
  //   // Create a Blob object with the CSV data
  //   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });

  //   // Save the Blob object as a file using FileSaver
  //   saveAs(blob, 'row-data.csv');
  // }

  const hideAddStaticEntryModal = () => {
    if (modalState === "addTemplate") {
      updateModalState({
        ...iModalState,
        addStaticEntryModal: false,
        addTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addStaticEntryModal: false,
        updateTemplateModal: true,
        modalState: "",
      });
      setTableSelectedValues([]);
      updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };

  const showAddStaticEntryModal = (type) => {
    if (type === "addTemplate") {
      updateModalState({
        ...iModalState,
        addStaticEntryModal: true,
        addTemplateModal: false,
        modalState: type,
      });

      dispatch(getAllMeasureListAction());
      // setTableSelectedValues([]);
      // updateLeadState({ ...iLeadState, CategoryId: "" });
    } else {
      updateModalState({
        ...iModalState,
        addStaticEntryModal: true,
        updateTemplateModal: false,
        modalState: type,
      });
      // setTableSelectedValues([]);
      // updateLeadState({ ...iLeadState, CategoryId: "" });
    }
  };
  // const [proformaModal,setProformaModal]=useState(false)
  // const proformaShowFunc = () => {
  //   setProformaModal(true)
  //    setShow(false)
  // }

  const downloadUploadedTemplate = () => {
    dispatch(dowloadTemplateAction())
      .then((res) => {
        sucessToast("Template imported successfully");
        hideImportTemplateModal();
      })
      .catch((err) => {
        errorToast("Upload the correct data");
      });
  };




useEffect(()=>{
  if(costCode)
  dispatch(getAllCostCodeAction(costCode));



},[costCode])






const handeChangeTemplate=(e,mainIndex, index,i,name)=>{
  let obj = templateUploadExcelData[mainIndex].Assemblies[index].Entries[i]
  obj[name] =e.target.value


let tempArray =[...templateUploadExcelData[mainIndex].Assemblies[index].Entries]

tempArray[i] = obj


  updateLeadState({
    ...iLeadState,
    templateUploadExcelData })
}




const handeChangeTemplateForUpdate=(e,mainIndex, index,i,name)=>{
  let obj = purchaseDetailsData[mainIndex].assemblies[index].purchaseDetails[i]
  obj[name] =e.target.value


let tempArray =[...purchaseDetailsData[mainIndex].assemblies[index].purchaseDetails]

tempArray[i] = obj


  updateLeadState({
    ...iLeadState,
    purchaseDetailsData })



}





  return (
    <>
      {/* <ProformaModal  proformaModal={proformaModal} setProformaModal={setProformaModal} /> */}
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Price Book</h4>
            {/* <button onClick={proformaShowFunc}>click me to open </button> */}
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#PriceBookPermisisions"
            >
              <img src="images/Icon-4.png" />
            </a>
          </div>
          <div className="">
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="DashboardLeft">
                  <div className="ShowList">
                    <h4>Show :</h4>
                    <div className="PriceBookShowList">
                      <aside className="SelectBook">
                        <input
                          type="radio"
                          name="categoryType"
                          value={"system_price_book"}
                          onChange={handleCheckbox}
                          checked={categoryType === "system_price_book"}
                        />
                        <span>System Price Book</span>
                      </aside>

                      <aside className="SelectBook">
                        <input
                          type="radio"
                          name="categoryType"
                          value={"client_price_book"}
                          onChange={handleCheckbox}
                          checked={categoryType === "client_price_book"}
                        />
                        <span>Client Price Book</span>
                      </aside>

                      <aside className="SelectBook">
                        <input
                          type="radio"
                          name="categoryType"
                          value={"ctc_master"}
                          onChange={handleCheckbox}
                          checked={categoryType === "ctc_master"}
                        />
                        <span> CTC Master</span>
                      </aside>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {categoryError?.categoryTypeError}
                      </span>
                    </div>

                    {/* FIRST HALF */}

                    {categoryType === "system_price_book" ? (
                      <div className="SystemPriceBookRight abc">
                        <article>
                          <button
                            onClick={handleAddDownloadPriceBook}
                            className="DownloadPriceBook"
                          >
                            <img
                              src="images/download_price_book.png"
                              alt="Download Price Book"
                            />
                            Download Price Book
                          </button>
                        </article>
                        <aside>
                          <h5>Categories</h5>
                          <div>
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                showAddCategoryModal("system_price_book")
                              }
                            >
                              <img src="images/Plus.png" alt="Add Category" />
                            </a>
                            <a
                              href="javascript:void(0);"
                              onClick={() => showAddEntriesModal()}
                            >
                              <img
                                src="images/double_plus.png"
                                alt="Create Entry"
                              />
                            </a>
                            <a
                              href="javascript:void(0);"
                              onClick={showPermissionModal}
                            >
                              <img
                                src="images/Icon-4.png"
                                alt="Category Permissions"
                              />
                            </a>
                          </div>
                        </aside>
                        <div className="Search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            // onChange={(e) => handleCheckbox(e.target.value)}
                            onChange={(e) =>
                              dispatch(
                                getAllCategoryListAction(
                                  "system_price_book",
                                  e.target.value
                                )
                              )
                            }
                          />
                          <span>
                            <img src="images/search.png" alt="Search" />
                          </span>
                        </div>
                      </div>
                    ) : categoryType === "client_price_book" ? (
                      <div className="ClientPriceBookRight abc">
                        <article>
                          <button
                            onClick={handleAddDownloadClientPriceBook}
                            className="DownloadPriceBook"
                          >
                            <img
                              src="images/download_price_book.png"
                              alt="Download Price Book"
                            />
                            Download Price Book
                          </button>
                          <button
                            className="UploadPriceBook"
                            onClick={showUploadExcelFileModal}
                          >
                            <img
                              src="images/download_price_book.png"
                              alt="Upload Price Book"
                            />
                            Upload Price
                          </button>

                          <a
                            className="UploadPriceBook DownloadPriceBook"
                            href={`${Url}download-cpb-template`}
                            target="_blank"
                          >
                            <img
                              src="images/download_price_book.png"
                              alt="Upload Price Book"
                            />
                            Download Template
                          </a>
                        </article>
                        <aside>
                          <h5>Categories</h5>
                          <div>
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                showAddCategoryModal("client_price_book")
                              }
                            >
                              <img src="images/Plus.png" alt="Add Category" />
                            </a>
                            <a
                              href="javascript:void(0);"
                              onClick={() => showAddEntriesModal()}
                            >
                              <img
                                src="images/double_plus.png"
                                alt="Create Entry"
                              />
                            </a>
                            <a
                              href="javascript:void(0);"
                              onClick={showPermissionModal}
                            >
                              <img
                                src="images/Icon-4.png"
                                alt="Category Permissions"
                              />
                            </a>
                          </div>
                        </aside>
                        <div className="Search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) =>
                              dispatch(
                                getAllCategoryListAction(
                                  "client_price_book",
                                  e.target.value
                                )
                              )
                            }
                          />
                          <span>
                            <img src="images/search.png" alt="Search" />
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="CtcMasterRight abc">
                        <article>
                          <button
                            className="DownloadPriceBook py-3"
                            onClick={showAddTemplateModal}
                          >
                            Create CTC Template
                          </button>
                          <a
                            className="UploadPriceBook DownloadPriceBook"
                            href={`${Url}download-ctc-template`}
                            target="_blank"
                          >
                            <img
                              src="images/download_price_book.png"
                              alt="Upload Price Book"
                            />
                            Download Template
                          </a>
                        </article>
                        <aside>
                          <h5>Folders/Projects</h5>
                          <div>
                            <a
                              href="javascript:void(0);"
                              onClick={() => showAddCTCModal("ctc_master")}
                            >
                              <img src="images/Plus.png" alt="Add Category" />
                            </a>
                            <a
                              href="javascript:void(0);"
                              onClick={showPermissionModal}
                            >
                              <img
                                src="images/Icon-4.png"
                                alt="Category Permissions"
                              />
                            </a>
                          </div>
                        </aside>
                        <div className="Search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) =>
                              dispatch(getAllCTCListAction(e.target.value))
                            }
                          />
                          <span>
                            <img src="images/search.png" alt="Search" />
                          </span>
                        </div>
                      </div>
                    )}

                    {/* SECOND HALF  */}

                    {categoryType === "system_price_book" ? (
                      <div
                        className="FolderList SystemPriceBookRight abc"
                        style={{ display: "block" }}
                      >
                        <ul>
                          {loader ? (
                            <>
                              <td>
                                {" "}
                                <Loader />
                              </td>
                            </>
                          ) : (
                            <>
                              {checkArray(categoryListData?.categoryDetails) ? (
                                categoryListData?.categoryDetails?.map(
                                  (ele, ind) => {
                                    return (
                                      <>
                                        <li>
                                          <span className="Icon">
                                            <img src={sideImage} />
                                          </span>
                                          <label
                                            onClick={() =>
                                              storeCategoryId(
                                                ele?.id,
                                                ele?.categoryType
                                              )
                                            }
                                          >
                                            {ele?.name}
                                          </label>
                                          <a
                                            onClick={() =>
                                              storeCategoryId(
                                                ele?.id,
                                                ele?.categoryType
                                              )
                                            }
                                            href="javascript:void(0);"
                                          >
                                            <img
                                              width="50px"
                                              src={ele?.imageUrl}
                                            />
                                          </a>
                                          <div className="dropdown FolderListDropdown">
                                            <a href="javascript:void(0);"></a>
                                            <a
                                              className=""
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu">
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showUpdateCategoryModal(
                                                      ele?.id,
                                                      ele?.categoryType
                                                    )
                                                  }
                                                >
                                                  <img src="images/Action-1.png" />
                                                  Edit Folders/Projects
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showAddEntriesModal(ele?.id)
                                                  }
                                                >
                                                  <img src="images/double_plus.png" />
                                                  Add Template
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    removeCategory(
                                                      ele?.id,
                                                      ele?.categoryType
                                                    )
                                                  }
                                                >
                                                  <img src="images/dropdown-delete.png" />
                                                  Delete Folders/Projects
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() => {
                                                    showCategoryHistoryModal(
                                                      ele?.id,
                                                      ele?.name
                                                    );
                                                  }}
                                                >
                                                  <img src="images/create_category.png" />
                                                  Folders/Projects History
                                                </a>
                                              </li>
                                            </ol>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <td>
                                  {" "}
                                  <NoDataFound />
                                </td>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    ) : categoryType === "client_price_book" ? (
                      <div
                        className="FolderList SystemPriceBookRight abc"
                        style={{ display: "block" }}
                      >
                        <ul>
                          {loader ? (
                            <>
                              <td>
                                {" "}
                                <Loader />
                              </td>
                            </>
                          ) : (
                            <>
                              {checkArray(categoryListData?.categoryDetails) ? (
                                categoryListData?.categoryDetails?.map(
                                  (ele, ind) => {
                                    return (
                                      <>
                                        <li>
                                          <span className="Icon">
                                            <img src={sideImage} />
                                          </span>
                                          <label
                                            onClick={() =>
                                              storeCategoryId(
                                                ele?.id,
                                                ele?.categoryType
                                              )
                                            }
                                          >
                                            {ele?.name}
                                          </label>
                                          <a href="javascript:void(0);">
                                            <img
                                              onClick={() =>
                                                storeCategoryId(
                                                  ele?.id,
                                                  ele?.categoryType
                                                )
                                              }
                                              src={ele?.imageUrl}
                                            />
                                          </a>
                                          <div className="dropdown FolderListDropdown">
                                            <a href="javascript:void(0);"></a>
                                            <a
                                              className=""
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>
                                            <ol className="dropdown-menu">
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showUpdateCategoryModal(
                                                      ele?.id,
                                                      ele?.categoryType
                                                    )
                                                  }
                                                >
                                                  <img src="images/Action-1.png" />
                                                  Edit Category
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showAddEntriesModal(ele?.id)
                                                  }
                                                >
                                                  <img src="images/double_plus.png" />
                                                  Add Entry
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    removeCategory(
                                                      ele?.id,
                                                      ele?.categoryType
                                                    )
                                                  }
                                                >
                                                  <img src="images/dropdown-delete.png" />
                                                  Delete Category
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() => {
                                                    showCategoryHistoryModal(
                                                      ele?.id,
                                                      ele?.name
                                                    );
                                                  }}
                                                >
                                                  <img src="images/create_category.png" />
                                                  Create History
                                                </a>
                                              </li>
                                            </ol>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <td>
                                  {" "}
                                  <NoDataFound />
                                </td>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    ) : (
                      <div
                        className="FolderList SystemPriceBookRight abc"
                        style={{ display: "block" }}
                      >
                        <ul>
                          {loader ? (
                            <>
                              <td>
                                {" "}
                                <Loader />
                              </td>
                            </>
                          ) : (
                            <>
                              {checkArray(CTCListData?.ctcCategories) ? (
                                CTCListData?.ctcCategories?.map((ele, ind) => {
                                  return (
                                    <>
                                      <li>
                                        <span className="Icon">
                                          {/* <img src={ele?.imageUrl} /> */}
                                        </span>
                                        <label
                                          onClick={() =>
                                            goToCtcTemplate(ele?.id)
                                          }
                                        >
                                          {ele?.name}
                                        </label>
                                        <a href="javascript:void(0);">
                                          {/* <img src="https://ibuildstagingstorage.blob.core.windows.net/stagingblobs/2/1697611201069_Screenshot" /> */}
                                        </a>
                                        <div className="dropdown FolderListDropdown">
                                          <a href="javascript:void(0);"></a>
                                          <a
                                            className=""
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu">
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showUpdateCTCModal(
                                                    ele?.id,
                                                    ele?.name,
                                                    ele?.name
                                                  )
                                                }
                                              >
                                                <img src="images/Action-1.png" />
                                                Edit Category
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={showAddTemplateModal}                                              >
                                                <img src="images/double_plus.png" />
                                                Add Template
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  removeCTCFUn(
                                                    ele?.id,
                                                    ele?.categoryType
                                                  )
                                                }
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete CTC
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() => {
                                                  showCTCHistoryModal(
                                                    ele?.id,
                                                    ele?.name
                                                  );
                                                }}
                                              >
                                                <img src="images/create_category.png" />
                                                Create History
                                              </a>
                                            </li>
                                          </ol>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                              ) : (
                                <td>
                                  {" "}
                                  <NoDataFound />
                                </td>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="TagsList">
                    <h4>TAGS</h4>
                    <ul>
                      {loader ? (
                        <>
                          <td>
                            {" "}
                            <Loader />
                          </td>
                        </>
                      ) : (
                        <>
                          {checkArray(tagListData?.tags) ? (
                            tagListData?.tags?.map((ele, ind) => {
                              return (
                                <>
                                  <li onClick={() => tagsFilteredData(ele)}>
                                    <span>{ele}</span>
                                  </li>
                                </>
                              );
                            })
                          ) : (
                            <td>
                              {" "}
                              <NoDataFound />
                            </td>
                          )}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                {categoryType === "system_price_book" ||
                categoryType === "client_price_book" ? (
                  <div
                    className="abc SystemPriceBookRight"
                    style={{ display: "block" }}
                  >
                    <div className="FilesArea">
                      <div className="FilesHead">
                        <div className="FilesLeft">
                          <h4>Entries</h4>
                          <aside>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              onChange={(e) => {
                                dispatch(
                                  getAllAssemblyListAction(
                                    CategoryId,
                                    e.target.value
                                  )
                                );
                              }}
                            />
                            <span>
                              <img src="images/search.png" />
                            </span>
                          </aside>
                        </div>
                        <div className="FilesRight">
                          <a
                            href="javascript:void(0);"
                            onClick={showAddEntriesModal}
                          >
                            <img src="images/double_plus.png" />
                          </a>
                        </div>
                      </div>
                      <div className="FilesBody">
                        <div className="row">
                          {loader ? (
                            <>
                              <td>
                                {" "}
                                <Loader />
                              </td>
                            </>
                          ) : (
                            <>
                              {checkArray(assemblyListData?.assemblyDetails) ? (
                                assemblyListData?.assemblyDetails?.map(
                                  (ele, ind) => {
                                    return (
                                      <div className="col-xl-6 col-lg-6 col-md-12">
                                        <div className="FilesBox2">
                                          <figcaption>
                                            <div>
                                              <h4>{ele?.name}</h4>
                                            </div>
                                            <ul>
                                              <li className="dropdown">
                                                <a
                                                  className="dropdown-toggle"
                                                  id="navbarDropdown"
                                                  role="button"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  <img src="images/dots.png" />
                                                </a>
                                                <ol className="dropdown-menu">
                                                  <li>
                                                    <a
                                                      href="javascript:void(0);"
                                                      onClick={() =>
                                                        showEditAssemblyListModal(
                                                          ele?.CategoryId,
                                                          ele?.id
                                                        )
                                                      }
                                                    >
                                                      <img src="images/document-edit.png" />
                                                      Edit Assembly
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        showAddEntriesModal(
                                                          ele?.CategoryId,
                                                          ele?.id,
                                                          ele?.name
                                                        )
                                                      }
                                                      href="javascript:void(0);"
                                                    >
                                                      <img src="images/double_plus.png" />
                                                      Add Entry
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      onClick={() =>
                                                        deleteAssemblyFun(
                                                          ele?.CategoryId,
                                                          ele?.id
                                                        )
                                                      }
                                                      href="javascript:void(0);"
                                                    >
                                                      <img src="images/dropdown-delete.png" />
                                                      Delete Assembly
                                                    </a>
                                                  </li>
                                                  <li>
                                                    <a
                                                      href="javascript:void(0);"
                                                      onClick={() =>
                                                        showAssemblyMarkupModal(
                                                          ele?.CategoryId,
                                                          ele?.id,
                                                          ele?.name,
                                                          ele?.defaultMarkup
                                                        )
                                                      }
                                                    >
                                                      <img src="images/change_markup.png" />
                                                      Change Markup
                                                    </a>
                                                  </li>
                                                </ol>
                                              </li>
                                            </ul>
                                          </figcaption>
                                          <div className="FilesBoxCenter">
                                            <div className="FilesBoxCenterLeft">
                                              <p>
                                                <span>Markup</span>
                                                {ele?.defaultMarkup}
                                              </p>
                                              <h6>Assembly</h6>
                                              <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  showEntryListModal(
                                                    ele?.CategoryId,
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                {ele?.entriesCount}
                                              </p>
                                            </div>
                                            <div>
                                              <figure>
                                                {/* <img src="images/item-2.png" /> */}
                                              </figure>
                                            </div>
                                          </div>
                                          <figcaption>
                                            <div>
                                              <p>
                                                Added:{" "}
                                                {ele?.createdAt?.split("T")[0]}
                                              </p>
                                            </div>
                                            <ul>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  onClick={() =>
                                                    showAssemblyHistoryModal(
                                                      ele?.CategoryId,
                                                      ele?.id,
                                                      ele?.name
                                                    )
                                                  }
                                                >
                                                  <img src="images/timer.png" />
                                                </a>
                                              </li>
                                            </ul>
                                          </figcaption>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <td>
                                  {" "}
                                  <NoDataFound />
                                </td>
                              )}
                            </>
                          )}

                          {checkArray(assemblyListData?.standalones) ? (
                            assemblyListData?.standalones?.map((ele, ind) => {
                              return (
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                  <div className="FilesBox2">
                                    <figcaption>
                                      <div>
                                        <h4>{ele?.name}</h4>
                                      </div>
                                      <ul>
                                        <li className="dropdown">
                                          <a
                                            className="dropdown-toggle"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>
                                          <ol className="dropdown-menu">
                                            {/* <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showEditAssemblyListModal(
                                                    ele?.CategoryId,
                                                    ele?.id
                                                  )
                                                }
                                              >
                                                <img src="images/document-edit.png" />
                                                Edit Assembly
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                onClick={() =>
                                                  showAddEntriesModal(
                                                    ele?.CategoryId,
                                                    ele?.id,
                                                    ele?.name
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/double_plus.png" />
                                                Add Entries
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  deleteEntryFun(
                                                    ele?.id,
                                                    ele?.AssemblyId,
                                                    ele?.CategoryId
                                                  )
                                                }
                                                href="javascript:void(0);"
                                              >
                                                <img src="images/dropdown-delete.png" />
                                                Delete Standalone
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="javascript:void(0);"
                                                onClick={() =>
                                                  showEntryMarkupModal(
                                                    ele?.CategoryId,
                                                    ele?.id,
                                                    ele?.name,
                                                    ele?.defaultMarkup
                                                  )
                                                }
                                              >
                                                <img src="images/change_markup.png" />
                                                Change Markup
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                      </ul>
                                    </figcaption>
                                    <div className="FilesBoxCenter">
                                      <div className="FilesBoxCenterLeft">
                                        <p>
                                          <span>Markup</span>
                                          {ele?.markup}
                                        </p>
                                        {/* <h6>Assembly</h6> */}
                                        {/* <p
                                          onClick={() =>
                                            showEntryListModal(ele?.CategoryId)
                                          }
                                        >
                                          3 entries
                                        </p> */}
                                      </div>
                                      <div>
                                        <figure>
                                          <img
                                            src={ele?.images?.[0]?.imageUrl}
                                          />
                                        </figure>
                                      </div>
                                    </div>
                                    <figcaption>
                                      <div>
                                        <p>
                                          Added: {ele?.createdAt?.split("T")[0]}
                                        </p>
                                      </div>
                                      <ul>
                                        <li>
                                          <a
                                            href="javascript:void(0);"
                                            onClick={() =>
                                              showAssemblyHistoryModal(
                                                ele?.CategoryId,
                                                ele?.id,
                                                ele?.name
                                              )
                                            }
                                          >
                                            <img src="images/timer.png" />
                                          </a>
                                        </li>
                                      </ul>
                                    </figcaption>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <td>
                              {" "}
                              <NoDataFound />
                            </td>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="abc CtcMasterRight">
                    <div className="FilesArea">
                      <div className="FilesHead">
                        <div className="FilesLeft">
                          <h4>Templates</h4>
                          <aside>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              onChange={(e) =>
                                dispatch(
                                  getAllTemplateListAction(e.target.value)
                                )
                              }
                            />
                            <span>
                              <img src="images/search.png" />
                            </span>
                          </aside>
                        </div>
                        <div className="FilesRight">
                          <a
                            onClick={showAddTemplateModal}
                            href="javascript:void(0);"
                          >
                            <img src="images/double_plus.png" />
                          </a>
                          {/* <a href="javascript:void(0);">
                            <img src="images/import.png" />
                          </a> */}
                        </div>
                      </div>
                      <div className="FilesBody">
                        <div className="row">
                          {loader ? (
                            <>
                              <td>
                                {" "}
                                <Loader />
                              </td>
                            </>
                          ) : (
                            <>
                              {checkArray(templateListData?.ctcTemplates) ? (
                                templateListData?.ctcTemplates?.map(
                                  (ele, ind) => {
                                    return (
                                      <>
                                        <div className="col-sm-4">
                                          <div className="FilesBox2">
                                            <figcaption>
                                              <div>
                                                <h4>{ele?.name}</h4>
                                              </div>
                                              <ul>
                                                <li className="dropdown">
                                                  <a
                                                    className="dropdown-toggle"
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    <img src="images/dots.png" />
                                                  </a>
                                                  <ol className="dropdown-menu">
                                                    <li>
                                                      <a
                                                        href="javascript:void(0);"
                                                        onClick={() =>
                                                          showUpdateTemplateModal(
                                                            ele?.CtcCategoryId,
                                                            ele?.id,ele
                                                          )
                                                        }
                                                      >
                                                        <img src="images/document-edit.png" />
                                                        Edit Template
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          deleteTemplate(
                                                            ele?.id
                                                          )
                                                        }
                                                        href="javascript:void(0);"
                                                      >
                                                        <img src="images/dropdown-delete.png" />
                                                        Delete Template
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a href="javascript:void(0);">
                                                        <img src="images/icon-54.png" />
                                                        Export Template
                                                      </a>
                                                    </li>

                                                    <li>
                                                      <a href="javascript:void(0);">
                                                        <img src="images/icon-54.png" />
                                                        Copy Templates
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          releaseTemplate(
                                                            ele?.id
                                                          )
                                                        }
                                                        href="javascript:void(0);"
                                                      >
                                                        <img src="images/release_use.png" />
                                                        Release for Use
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          disableTemplate(
                                                            ele?.id
                                                          )
                                                        }
                                                        href="javascript:void(0);"
                                                      >
                                                        <img src="images/disable_use.png" />
                                                        Disable for Use
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() =>
                                                          showUpdateTemplatePicModal(
                                                            ele?.id
                                                          )
                                                        }
                                                        href="javascript:void(0);"
                                                      >
                                                        <img src="images/upload_template.png" />
                                                        Upload Template main
                                                        picture
                                                      </a>
                                                    </li>
                                                  </ol>
                                                </li>
                                              </ul>
                                            </figcaption>
                                            <div className="FilesBoxCenter">
                                              <div className="FilesBoxCenterLeft">
                                                <p>
                                                  {ele?.itemsCount}
                                                  <span> Items</span>
                                                </p>
                                              </div>
                                              <div>
                                                <figure style={{width:"30px", height:"30px"}}>
                                                  <img
                                                    src={
                                                      ele?.images?.[0]?.imageUrl
                                                    }
                                                  />
                                                </figure>
                                              </div>
                                            </div>
                                            <figcaption>
                                              <div>
                                                <p>
                                                  Updated:
                                                  {
                                                    ele?.createdAt?.split(
                                                      "T"
                                                    )[0]
                                                  }
                                                </p>
                                              </div>
                                              <ul>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    onClick={() =>
                                                      showTemplateHistoryModal(
                                                        ele?.id
                                                      )
                                                    }
                                                  >
                                                    <img src="images/timer.png" />
                                                  </a>
                                                </li>
                                              </ul>
                                            </figcaption>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              ) : (
                                <td>
                                  {" "}
                                  <NoDataFound />
                                </td>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ADDCATEGORYSHOW */}
      <Modal
        show={addCategoryModal}
        onHide={hideAddCategoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCategoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Category</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new Category's name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {categoryError?.nameError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>Images</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="image"
                      onChange={handleInputFile}
                    />
                    <img src={imagePreview} />
                  </div>
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {categoryError?.imageError}
                  </span>
                </div>
                {/* <div className="form-group FilesAddedGroup">
                    <div className="FilesAdded">
                      <label>picture01.jpg</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span className="cross">
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                  </div> */}
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addCategoryFun}>
                    Create Category
                  </button>
                  <button className="Outline" onClick={hideAddCategoryModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITCATEGORYSHOW */}
      <Modal
        show={editCategoryModal}
        onHide={hideUpdateCategoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateCategoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Category</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new Category's name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {categoryError?.nameError}
                  </span>
                </div>
                <div className="form-group">
                  <h6>Images</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      name="image"
                      onChange={handleInputFile}
                    />
                  </div>
                  {typeof image === "string" ? (
                    <img src={image} />
                  ) : (
                    <img src={imagePreview} />
                  )}

                  <span style={{ fontSize: "14px", color: "red" }}>
                    {categoryError?.imageError}
                  </span>
                </div>
                {/* <div className="form-group FilesAddedGroup">
                    <div className="FilesAdded">
                      <label>picture01.jpg</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span className="cross">
                        <img src="images/Cross.png" />
                      </span>
                    </div>
                  </div> */}
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={updateCategoryFun}>
                    Update Category
                  </button>
                  <button className="Outline" onClick={hideUpdateCategoryModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CATEGORYHISTORYSHOW */}
      <Modal
        show={categoryHistoryModal}
        onHide={hideCategoryHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideCategoryHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Category History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Category : </span>
                  {requiredName}
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(categoryHistoryData?.categoryHistories) ? (
                        categoryHistoryData?.categoryHistories?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.modifiedBy}</td>
                                  <td>{ele?.createdBy}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.activity}</td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDENTRYMODALSHOW */}
      <Modal
        show={addEntriesModal}
        onHide={hideAddEntriesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddEntriesModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Entry</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new entry name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.nameError}
                </span>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Category<span className="required">*</span>
                  </h6>
                  <Select
                    className="form-control"
                    defaultValue={CategoryId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, CategoryId: e.value })
                    }
                    options={categoryListData?.categoryDetails?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                  {/* <div className="Categories_all Categories_all2">
                    <select
                      name="CategoryId"
                      value={CategoryId}
                      onChange={handleInput}
                    >
                      <option>Select Category</option>
                      {checkArray(categoryListData?.categoryDetails) ? (
                        <>
                          {categoryListData?.categoryDetails?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                       
                      ) : (
                        <></>
                      )}
                    </select>


                    <img src="images/down.png" />
                  </div> */}
                  <ul>
               
                    <li
                      onClick={() => showAddCategoryModal("entryAdd")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.CategoryIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <h6>Type</h6>
                <label className="Radio margin-bottom">
                  Assembly entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Assembly"}
                    onChange={handleCheckbox}
                    checked={entryType == "Assembly"}
                  />
                  <span className="checkmark" />
                </label>
                <label className="Radio">
                  Stand alone entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Standalone"}
                    onChange={handleCheckbox}
                    checked={entryType === "Standalone"}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {entryErrors?.entryTypeError}
              </span>
              {CategoryId && entryType === "Assembly" ? (
                // assemblyListData?.assemblyDetails && assemblyListData?.assemblyDetails?.length > 0
                <div className="form-group">
                  <div className="Categories Categories2 mb-4">
                    <h6>
                      Assembly<span className="required">*</span>
                    </h6>
                    <Select
                    className="form-control"
                    defaultValue={AssemblyId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, AssemblyId: e.value })
                    }
                    options={assemblyListData?.assemblyDetails?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                    {/* <div className="Categories_all Categories_all2">
                      <select
                        name="AssemblyId"
                        value={AssemblyId}
                        onChange={handleInput}
                      >
                        <option>Select Assembly</option>
                        {checkArray(assemblyListData?.assemblyDetails) ? (
                          <>
                            {assemblyListData?.assemblyDetails?.map(
                              (ele, i) => {
                                return (
                                  <option value={ele?.id}>{ele?.name}</option>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                      <img src="images/down.png" />
                    </div> */}
                    <ul>
                      {/* <li>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) =>
                            dispatch(
                              getAllCategoryListAction(
                                categoryType,
                                e.target.value
                              )
                            )
                          }
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li> */}
                      <li
                        onClick={() => showAddAssemblyModal("entryAdd")}
                        class="CursorPointer FolderPermissionId"
                      >
                        <span class="text-red2 fw-16">+</span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; New
                      </li>
                    </ul>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {entryErrors?.AssemblyIdError}
                    </span>
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.countryError}
                    </span> */}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Measure Unit<span className="required">*</span>
                  </h6>
                  <Select
                    className="form-control"
                    defaultValue={MeasureId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, MeasureId: e.value })
                    }
                    options={measureListData?.measures?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                  {/* <div className="Categories_all Categories_all2">
                    <select
                      name="MeasureId"
                      value={MeasureId}
                      onChange={handleInput}
                    >
                      <option>Select Measure</option>
                      {checkArray(measureListData?.measures) ? (
                        <>
                          {measureListData?.measures?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div> */}
                  <ul>
                    {/* <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllMeasureListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li> */}
                    <li
                      onClick={() => showAddMeasureModal("entryAdd")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.MeasureIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-group">
                <h6>Unit Cost</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Unit Cost in $"
                  name="unitCost"
                  value={unitCost}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.unitCostError}
                </span>
              </div>
              <div className="form-group">
                <h6>Markup %</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert mark up"
                  name="markup"
                  value={markup}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.markupError}
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Images</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={showAddFilesModal}
                      >
                        <figure>
                          <img src="images/image-icon.png" />
                        </figure>{" "}
                        Images{" "}
                      </h5>
                      {/* <img src={imagePreview}/> */}
                      <span
                        className="Count CursorPointer"
                        // data-toggle="modal"
                        // data-target="#CarouselInModal"
                        // data-dismiss="modal"
                      >
                        {/* <img  src={imagePreview}/> */}
                      </span>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.imagesError}
                  </span>
                </div>
                </div>
                

                  <div className="col-md-6">
                  <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Select Accounts</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddAccountModal("addEntry")}
                      >
                        <figure>
                          <img src="images/select-account.png" />
                        </figure>{" "}
                        Select Account
                      </h5>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.entryAccountError}
                  </span>
                </div>
                  {checkArray(entryAccount) ? (
                  entryAccount?.map((ele, i) => {
                    return (
                      <>
                        <div className="FilesAdded">
                          <label>{ele?.costCode}</label>
                          <label>{ele?.costArea}</label>
                          <span>
                            <img
                              onClick={() => removeEntryAccount(i)}
                              src="images/Cross.png"
                            />
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
                  </div>

              </div>
            
          
           
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addEntryFun}>
                  Create Entry
                </button>
                <button className="Outline" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITENTRYMODALSHOW */}
      <Modal
        show={EditEntryModal}
        onHide={hideEditEntriesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEditEntriesModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update Entry</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new entry name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.nameError}
                </span>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Category<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="CategoryId"
                      value={CategoryId}
                      onChange={handleInput}
                    >
                      <option>Select Category</option>
                      {checkArray(categoryListData?.categoryDetails) ? (
                        <>
                          {categoryListData?.categoryDetails?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(
                            getAllCategoryListAction(
                              categoryType,
                              e.target.value
                            )
                          )
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li
                      onClick={() => showAddCategoryModal("editEntry")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.CategoryIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <h6>Type</h6>
                <label className="Radio margin-bottom">
                  Assembly entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Assembly"}
                    onChange={handleCheckbox}
                    checked={entryType == "Assembly"}
                  />
                  <span className="checkmark" />
                </label>
                <label className="Radio">
                  Stand alone entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Standalone"}
                    onChange={handleCheckbox}
                    checked={entryType === "Standalone"}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {entryErrors?.entryTypeError}
              </span>
              {CategoryId && entryType === "Assembly" ? (
                // assemblyListData?.assemblyDetails && assemblyListData?.assemblyDetails?.length > 0
                <div className="form-group">
                  <div className="Categories Categories2 mb-4">
                    <h6>
                      Assembly<span className="required">*</span>
                    </h6>
                    <div className="Categories_all Categories_all2">
                      <select
                        name="AssemblyId"
                        value={AssemblyId}
                        onChange={handleInput}
                      >
                        <option>Select Assembly</option>
                        {checkArray(assemblyListData?.assemblyDetails) ? (
                          <>
                            {assemblyListData?.assemblyDetails?.map(
                              (ele, i) => {
                                return (
                                  <option value={ele?.id}>{ele?.name}</option>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) =>
                            dispatch(
                              getAllCategoryListAction(
                                categoryType,
                                e.target.value
                              )
                            )
                          }
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li
                        onClick={() => showAddAssemblyModal("editEntry")}
                        class="CursorPointer FolderPermissionId"
                      >
                        <span class="text-red2 fw-16">+</span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; New
                      </li>
                    </ul>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {entryErrors?.AssemblyIdError}
                    </span>
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.countryError}
                    </span> */}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Measure Unit<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="MeasureId"
                      value={MeasureId}
                      onChange={handleInput}
                    >
                      <option>Select Measure</option>
                      {checkArray(measureListData?.measures) ? (
                        <>
                          {measureListData?.measures?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllMeasureListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li
                      onClick={() => showAddMeasureModal("editEntry")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.MeasureIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-group">
                <h6>Unit Cost</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Unit Cost in $"
                  name="unitCost"
                  value={unitCost}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.unitCostError}
                </span>
              </div>
              <div className="form-group">
                <h6>Markup %</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert mark up"
                  name="markup"
                  value={markup}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.markupError}
                </span>
              </div>
              <div className="CommonModalAreaMain">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Images</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddFilesModal("editEntry")}
                      >
                        <figure>
                          <img src="images/image-icon.png" />
                        </figure>{" "}
                        Images{" "}
                      </h5>
                      {/* <img src={imagePreview}/> */}
                      <span
                        className="Count CursorPointer"
                        // data-toggle="modal"
                        // data-target="#CarouselInModal"
                        // data-dismiss="modal"
                      >
                        {/* <img  src={imagePreview}/> */}
                      </span>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.imagesError}
                  </span>
                </div>
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Select Accounts</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddAccountModal("editEntry")}
                      >
                        <figure>
                          <img src="images/select-account.png" />
                        </figure>{" "}
                        Select Account
                      </h5>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.entryAccountError}
                  </span>
                </div>
              </div>
              {/* <div className="FilesAdded">
                  <label>23-0101 Appliances</label>
                  <span>
                    <img src="images/Cross.png" className="Cross" />
                  </span>
                </div> */}
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={editEntryFun}>
                  Update Entry
                </button>
                <button className="Outline" data-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CATEGORYHISTORYSHOW */}
      <Modal
        show={accountModal}
        onHide={hideAddAccountModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddAccountModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Select Accounts</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Cost Codes Categories<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="costCode"
                      value={costCode}
                      onChange={handleInput}
                    >
                      <option>Select Cost Codes</option>
                      {loader ? (
                        <>
                          {" "}
                          <Loader />
                        </>
                      ) : (
                        <>
                          {checkArray(masterCostCategoryListData?.data) ? (
                            masterCostCategoryListData?.data?.map(
                              (ele, ind) => {
                                return (
                                  <>
                                    <option value={ele?.id}>
                                      {ele?.name}
                                    </option>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Cost Codes<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="costArea"
                      value={costArea}
                      onChange={handleInput}
                    >
                      <option>Select Cost Codes</option>
                      {loader ? (
                        <>
                          {" "}
                          <Loader />
                        </>
                      ) : (
                        <>
                          {checkArray(getAllCostCodeListData?.data) ? (
                            getAllCostCodeListData?.data?.map(
                              (ele, ind) => {
                                return (
                                  <>
                                    <option value={ele?.name}>
                                      {ele?.name}
                                    </option>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <NoDataFound />
                          )}
                        </>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="Button mb-5">
                <button className="Create" onClick={addFilesFun}>
                  Add
                </button>
              </div>
              <div className="form-group">
                <h6>Selected Cost Codes</h6>
                {checkArray(entryAccount) ? (
                  entryAccount?.map((ele, i) => {
                    return (
                      <>
                        <div className="FilesAdded">
                          <label>{ele?.costCode}</label>
                          <label>{ele?.costArea}</label>
                          <span>
                            <img
                              onClick={() => removeEntryAccount(i)}
                              src="images/Cross.png"
                            />
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <NoDataFound />
                )}
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={hideAddAccountModal}>
                  Confirm Selection
                </button>
                <button className="Outline" onClick={hideAddAccountModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDFILESMODALSHOW */}
      <Modal
        show={addFilesModal}
        onHide={hideAddFilesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddFilesModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Images</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" name="images" onChange={handleInputFile} />
                  <img src={imagePreview} />
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={hideAddFilesModal}>
                  Save
                </button>
                <button className="Outline" onClick={hideAddFilesModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*EDITFILESMODAL  */}

      <Modal
        show={editFilesModal}
        onHide={hideEditFilesModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEditFilesModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Images</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" name="images" onChange={handleInputFile} />
                  <img src={images} />
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={hideEditFilesModal}>
                  Save
                </button>
                <button className="Outline" onClick={hideEditFilesModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ENRYHISTORYMODALSHOW */}
      <Modal
        show={EntryHistoryModal}
        onHide={hideEntryHistoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEntryHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Entry History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span> {requiredName}</span>
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      {" "}
                      <Loader />
                    </>
                  ) : (
                    <>
                      {checkArray(entryHistoryData?.entryHistories) ? (
                        entryHistoryData?.entryHistories?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.modifiedBy}</td>
                                <td>{ele?.createdBy}</td>
                                <td>{ele?.createdAt?.split("T")[0]}</td>
                                <td>{ele?.activity}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ENTRYMARKUPMMODALSHOW */}
      <Modal
        show={entryMarkupModal}
        onHide={hideEntryMarkupModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEntryMarkupModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit Markup</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Entry Name</h6>
                  <p className="ml-0">{requiredName}</p>
                </div>
                <div className="form-group">
                  <h6>Markup %</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="20 %"
                    name="markup"
                    value={markup}
                    onChange={handleInputNumber}
                  />
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={updateMarkupFun}>
                    Update Markup
                  </button>
                  <button className="Outline" onClick={hideEntryMarkupModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDASSEMBLYMODAL */}

      <Modal
        show={assemblyModal}
        onHide={hideAddAssembldyModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddAssembldyModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Assembly</h4>
            </div>
            <div className="FolderForm">
              <h2 className="f-500">
                {/* <span>Category:</span> Appliances */}
              </h2>
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new Assembly's name"
                  name="assemblyName"
                  value={assemblyName}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {assemblyError?.assemblyNameError}
                </span>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Measure Unit<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="assemblyMeasureId"
                      value={assemblyMeasureId}
                      onChange={handleInput}
                    >
                      <option>Select Measure</option>
                      {checkArray(measureListData?.measures) ? (
                        <>
                          {measureListData?.measures?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllMeasureListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li
                      onClick={() => showAddMeasureModal("assemblyAdd")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {assemblyError?.assemblyMeasureIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <h6>Default Markup %</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert mark up"
                  name="defaultMarkup"
                  value={defaultMarkup}
                  onChange={handleInputNumber}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {assemblyError?.defaultMarkupError}
                </span>
              </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create"
                  onClick={addAssemblyFunc}
                  data-dismiss="modal"
                >
                  Create Assembly
                </button>
                <button className="Outline" onClick={hideAddAssembldyModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDMEASUREMODAL */}

      <Modal
        show={measureModal}
        onHide={hideAddMeasureModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddMeasureModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Measure</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new measure's name"
                  name="measureName"
                  value={measureName}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {measureError}
                </span>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addMeasureFunc}>
                  Create Measure
                </button>
                <button className="Outline" onClick={hideAddMeasureModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CLIENTPRICEFILEUPLOADSHOW */}

      <Modal
        show={uploadexcelFileModal}
        onHide={hideUploadExcelFileModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUploadExcelFileModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Price Book</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" name="file" onChange={handleInputFile} />
                </div>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addExcelFileUpload}>
                  Save
                </button>
                <button className="Outline" onClick={hideUploadExcelFileModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addCTCModal}
        onHide={hideAddCTCModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCTCModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Folder/Projects</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new CTC's name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
                <span style={{ fontSize: "14px", color: "red" }}>
                  {CTCError}
                </span>
              </div>
              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addCTCFun}>
                  Create Folder/Projects
                </button>
                <button className="Outline" onClick={hideAddCTCModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* EDITCTCSHOW */}
      <Modal
        show={editCTCModal}
        onHide={hideUpdateCTCModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateCTCModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update CTC</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new CTC's name"
                    name="name"
                    value={name}
                    onChange={handleInput}
                  />
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {CTCError}
                  </span>
                </div>

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={updateCTCFun}>
                    Update CTC
                  </button>
                  <button className="Outline" onClick={hideUpdateCTCModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CATEGORYHISTORYSHOW */}
      <Modal
        show={CTCHistoryModal}
        onHide={hideCTCHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideCTCHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>CTC Folder/Projects History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span>Folder/Projects : </span>
                  {requiredName}
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(CTCHistoryData?.entryHistories) ? (
                        CTCHistoryData?.entryHistories?.map((ele, ind) => {
                          return (
                            <>
                              <tr>
                                <td>{ele?.modifiedBy}</td>
                                <td>{ele?.createdBy}</td>
                                <td>{ele?.createdAt?.split("T")[0]}</td>
                                <td>{ele?.activity}</td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={entryListModal}
        onHide={hideentryListModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideentryListModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>List Table</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="EntriesTable">
                  {/* <h4>
                    Entries
                    <img
                      src="images/double_plus.png"
                      className="FolderPermissionId CursorPointer"
                      data-toggle="modal"
                      data-target="#CreateEntryAssembly"
                      data-dismiss="modal"
                    />
                  </h4> */}
                  <article>
                    <table>
                      <thead>
                        <tr>
                          <th>Entry</th>
                          <th>Cost</th>
                          <th>Markup</th>
                          <th>Picture</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <>
                            <td>
                              {" "}
                              <Loader />
                            </td>
                          </>
                        ) : (
                          <>
                            {checkArray(entriesListData?.entries) ? (
                              entriesListData?.entries?.map((ele, ind) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{ele?.name}</td>
                                      <td>{ele?.unitCost}</td>
                                      <td>{ele?.markup}</td>
                                      <td>
                                        <img src={ele?.images?.[0]?.imageUrl} />
                                      </td>
                                      <td />
                                      <td>
                                        <div className="TableThreeDots">
                                          <ul className="">
                                            <li className="dropdown">
                                              <a
                                                className=""
                                                id="navbarDropdown"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="true"
                                              >
                                                <img src="images/dots.png" />
                                              </a>
                                              <ol
                                                className="dropdown-menu dropdown-menuwidth"
                                                x-placement="top-start"
                                                style={{
                                                  position: "absolute",
                                                  willChange: "transform",
                                                  top: 0,
                                                  left: 0,
                                                  transform:
                                                    "translate3d(-154px, -67px, 0px)",
                                                }}
                                              >
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="FolderPermissionId"
                                                    onClick={() =>
                                                      showEditEntriesModal(
                                                        ele?.id,
                                                        ele?.CategoryId
                                                      )
                                                    }
                                                  >
                                                    <img src="images/Action-1.png" />
                                                    Edit Entry
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      deleteEntryFun(
                                                        ele?.id,
                                                        ele?.AssemblyId,
                                                        ele?.CategoryId
                                                      )
                                                    }
                                                    href="javascript:void(0);"
                                                  >
                                                    <img src="images/dropdown-delete.png" />
                                                    Delete Entry
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="FolderPermissionId"
                                                    onClick={() =>
                                                      showEntryHistoryModal(
                                                        ele?.id
                                                      )
                                                    }
                                                  >
                                                    <img src="images/create_category.png" />
                                                    Entry History
                                                  </a>
                                                </li>
                                                {/* <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="FolderPermissionId"
                                                  >
                                                    <img
                                                      src="images/view_images.png"
                                                      data-toggle="modal"
                                                      data-target="#CarouselInModal1"
                                                      data-dismiss="modal"
                                                    />
                                                    View Images
                                                  </a>
                                                </li> */}
                                                <li>
                                                  <a
                                                    href="javascript:void(0);"
                                                    className="FolderPermissionId"
                                                    onClick={() =>
                                                      showEntryMarkupModal(
                                                        ele?.id,
                                                        ele?.AssemblyId,
                                                        ele?.name,
                                                        ele?.markup
                                                      )
                                                    }
                                                  >
                                                    <img src="images/change_markup.png" />
                                                    Change Markup
                                                  </a>
                                                </li>
                                              </ol>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : (
                              <td>
                                {" "}
                                <NoDataFound />
                              </td>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </article>
                </div>
                <div className="Button text-center">
                  {/* <button class="Create" data-dismiss="modal" >Update Assembly</button> */}
                  <button className="Outline" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ASSEMBLYHISTORYMODALSHOW */}
      <Modal
        show={AssemblyHistoryModal}
        onHide={hideAssemblyHistoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAssemblyHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Assembly History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span> {requiredName}</span>
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      {" "}
                      <Loader />
                    </>
                  ) : (
                    <>
                      {checkArray(assemblyHistoryData?.assemblyHistories) ? (
                        assemblyHistoryData?.assemblyHistories?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.modifiedBy}</td>
                                  <td>{ele?.createdBy}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.activity}</td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* UPDATEASSEMBLYMODAL */}

      <Modal
        show={editAssemblyModal}
        onHide={hideEditAssemblyListModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideEditAssemblyListModal}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create Assembly</h4>
            </div>
            <div className="FolderForm">
              <h2 className="f-500">
                {/* <span>Category:</span> Appliances */}
              </h2>
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new Assembly's name"
                  name="assemblyName"
                  value={assemblyName}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {assemblyError?.assemblyNameError}
                </span>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Measure Unit<span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="assemblyMeasureId"
                      value={assemblyMeasureId}
                      onChange={handleInput}
                    >
                      <option>Select Measure</option>
                      {checkArray(measureListData?.measures) ? (
                        <>
                          {measureListData?.measures?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllMeasureListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                    <li
                      onClick={() => showAddMeasureModal("assemblyEdit")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {assemblyError?.assemblyMeasureIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <h6>Default Markup %</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert mark up"
                  name="defaultMarkup"
                  value={defaultMarkup}
                  onChange={handleInputNumber}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {assemblyError?.defaultMarkupError}
                </span>
              </div>

              <div class="EntriesTable">
                    <h4>Entries<img src="images/double_plus.png" class="FolderPermissionId CursorPointer" data-toggle="modal" data-target="#CreateEntryAssembly" data-dismiss="modal"/></h4>

                    <article class="ArticleScroll">
                      <table>
                        <thead>
                          <tr>
                            <th>Entry</th>
                            <th>Cost</th>
                            <th>Makeups</th>
                            <th>Picture</th>
                            <th>Action</th>
                          </tr>
                       
                        </thead>
                        <tbody>

                          {  checkArray(entriesListData?.entries) &&entriesListData?.entries?.map((item)=>
                             <tr>
                             <td>{item?.name}</td>
                             <td></td>
                             <td></td>
                             <td></td>
                             <td>
                               <div class="TableThreeDots">
                                 <ul class="">
                                   <li class="dropdown">
                                     <a class="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <img src="images/dots.png" />
                                     </a>
                                     <ol class="dropdown-menu dropdown-menuwidth" >
                                       <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditEntry" data-dismiss="modal"><img src="images/Action-1.png" />Edit Entry</a></li>
                                       <li><a href="javascript:void(0);"><img src="images/dropdown-delete.png" />Delete Entry</a></li>
                                       <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EntryHistory" data-dismiss="modal"><img src="images/create_category.png" />Entry History</a></li>
                                       <li><a href="javascript:void(0);" class="FolderPermissionId"><img src="images/view_images.png" data-toggle="modal" data-target="#CarouselInModal1" data-dismiss="modal"/>View Images</a></li>
                                       <li><a href="javascript:void(0);" class="FolderPermissionId" data-toggle="modal" data-target="#EditMarkup" data-dismiss="modal"><img src="images/change_markup.png" />Change Markup</a></li>
                                     </ol>
                                   </li>
                                 </ul>
                                </div> 
                             </td>
                             
                           </tr>
         
                          
                          )}
                     
                         
                        
                        </tbody>
                      </table>
                    </article>

                  </div>
              <div className="Button d-flex justify-content-between">
                <button
                  className="Create"
                  onClick={updateAssemblyFunc}
                  data-dismiss="modal"
                >
                  Update Assembly
                </button>
                <button className="Outline" onClick={hideEditAssemblyListModal}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ENTRYMARKUPMMODALSHOW */}
      <Modal
        show={assemblyMarkupModal}
        onHide={hideAssemblyMarkupModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAssemblyMarkupModal}
          >
            ×
          </a>
          <div className="FormArea">
            {/* <div className="ModalTitleBox">
    <h4>Edit Markup</h4>
  </div> */}

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Edit Markup</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Assembly Name</h6>
                    <p className="ml-0">{requiredName}</p>
                  </div>
                  <div className="form-group">
                    <h6>Markup %</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="20 %"
                      name="defaultMarkup"
                      value={defaultMarkup}
                      onChange={handleInputNumber}
                    />
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      onClick={updateAssemblyMarkupFun}
                    >
                      Update Markup
                    </button>
                    <button
                      className="Outline"
                      onClick={hideAssemblyMarkupModal}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDCTCTEMPLATESHOW */}

      {/* addTemplateModalSHOW */}
      <Modal
        show={addTemplateModal}
        onHide={hideAddTemplateModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddTemplateModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Create CTC Template</h4>
            </div>
            <div className="FolderForm">
              <div className="form-row">
                <div className="form-group col-md-5">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new entry name"
                    name="templateName"
                    value={templateName}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.templateNameError}
                  </span>
                </div>
                <div className="form-group col-md-6 ml-auto">
                  <div className="CommonModalArea">
                    <h5>Add Template Main Picture</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddFilesModal("Template")}
                      >
                        <figure>
                          <img src="images/add_template.png" />
                        </figure>{" "}
                        Add Template Main Picture
                      </h5>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.imagesError}
                  </span>
                </div>
              </div>
              <div className="form-row">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    CTC Folder/Projects <span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="CtcCategoryId"
                      value={CtcCategoryId}
                      onChange={(e)=>{

                        goToCtcTemplate(e.target.value)

                      }}
                    >
                      <option>Select CTC</option>

                      {checkArray(CTCListData?.ctcCategories) ? (
                        <>
                          {CTCListData?.ctcCategories?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllCTCListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.CtcCategoryIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Items from Pricebook</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>{
                                                                updateLeadState((prev)=> ({...prev, templateCreatedFrom:"pricebook"}))


                                showAddPriceBookItemModal("addTemplate")
                              }
                              }
                            >
                              <figure>
                                <img src="images/add_template.png" />
                              </figure>{" "}
                              Add Items from Pricebook
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Custom Item</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                               onClick={() => {
                                updateLeadState((prev)=> ({...prev, templateCreatedFrom:"custom"}))
                                showAddStaticEntryModal("addTemplate")}}
                            >
                              <figure>
                                <img src="images/add_template.png" />
                              </figure>{" "}
                              Add Custom Item
                            </h5>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Import Template</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>{
                                updateLeadState((prev)=> ({...prev, templateCreatedFrom:"import"}))

                                showImportTemplateModal("addTemplate")}
                              }
                            >
                              <figure>
                                <img src="images/Icon-6.png" />
                              </figure>{" "}
                              Import Template
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Export Template</h5>
                          <div className="CommonModalBox">
                            <h5 className="">
                              <figure>
                                <img src="images/icon-54.png" />
                              </figure>{" "}
                              Export Template
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <h6 className="mb-2">Release for Use</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="releaseUse"
                      value={releaseUse}
                      onChange={() =>
                        updateLeadState({
                          ...iLeadState,
                          releaseUse: !releaseUse,
                        })
                      }
                    />
                    <span className="slider" />
                  </label>
                  <h6 className="mb-2">Copy From Old Template</h6>
                  <div class="Categories Categories1">
                 
                          <Select
                          placeholder="Select or Type Template name"
                    className="form-control"
                    defaultValue={templateId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, templateId: e.value })
                    }
                    options={templateListData?.ctcTemplates?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                       {templateId &&
                        <ul>
                          <li class="FolderPermissionId" data-toggle="modal" onClick={()=> {showAddCopyFromOldModal("addTemplate")
                                                            updateLeadState((prev)=> ({...prev, templateCreatedFrom:"copy"}))

                          }} data-dismiss="modal"><i class="fa fa-clone" aria-hidden="true"></i></li>
                        </ul>}
                      </div>
                </div>

             

                
              </div>
              <div className="PurchaseTable">
                <h6>Purchase Details</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Measure</th>
                      <th>Per Unit</th>
                      <th>Client Total</th>
                      <th>PO Line Total</th>
                      <th>Markup</th>
                      <th>Account(s)</th>
                      <th>Pictures</th>
                      <th>Drop Down</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {updloadDataOrPriceBook ? (
                      <>
                        {loader ? (
                          <>
                            <td>
                              {" "}
                              <Loader />
                            </td>
                          </>
                        ) : (
                          <>
                            {checkArray(templateUploadExcelData) ? (
                              <PurchaseDetailsUploadTemplateList
                                purchaseDetailsData={templateUploadExcelData}
                                handlePurchaseDetailsList={
                                  handlePurchaseDetailsList
                                }
                                handeChangeTemplate={handeChangeTemplate}

                                                                handleRemoveItem={handleRemoveItem}

                              />
                            ) : (
                              <td>
                                {" "}
                                <NoDataFound />
                              </td>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {loader ? (
                          <>
                            <td>
                              {" "}
                              <Loader />
                            </td>
                          </>
                        ) : (
                          <>
                            {checkArray(purchaseDetailsData) ? (
                              <PurchaseDetailsList
                                purchaseDetailsData={purchaseDetailsData}
                                handlePurchaseDetailsList={
                                  handlePurchaseDetailsList
                                }
                              />
                            ) : (
                              <td>
                                {" "}
                                <NoDataFound />
                              </td>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="Button d-flex mt-5">
                <button onClick={()=>addTemplateFunc("custom")} className="Create">
                  Create Template
                </button>
                <button className="Outline ml-md-5">Cancel</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* updateTemplateModalSHOW */}
      <Modal
        show={updateTemplateModal}
        onHide={hideUpdateTemplateModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateTemplateModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Update CTC Template</h4>
            </div>
            <div className="FolderForm">
              <div className="form-row">
                <div className="form-group col-md-5">
                  <h6>Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert new entry name"
                    name="templateName"
                    value={templateName}
                    onChange={handleInput}
                  />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.templateNameError}
                  </span>
                </div>
                <div className="form-group col-md-6 ml-auto">
                  <div className="CommonModalArea">
                    <h5>Add Template Main Picture</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddFilesModal("editTemplate")}
                      >
                        <figure>
                          <img src="images/add_template.png" />
                        </figure>{" "}
                        Add Template Main Picture
                      </h5>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.imagesError}
                  </span>
                </div>
                <div class="form-group col-md-6">
                  <div class="CommonModalArea">
                    <h5>Status</h5>
                    <div class="CommonModalBox">
                      <h5>Completed &nbsp;&nbsp;</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    CTC Folder/Projects <span className="required">*</span>
                  </h6>
                  <div className="Categories_all Categories_all2">
                    <select
                      name="CtcCategoryId"
                      value={CtcCategoryId}
                      onChange={handleInput}
                    >
                      <option>Select CTC</option>

                      {checkArray(CTCListData?.ctcCategories) ? (
                        <>
                          {CTCListData?.ctcCategories?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div>
                  <ul>
                    <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllCTCListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li>
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {templateError?.CtcCategoryIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Items from Pricebook</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() =>
                                showAddPriceBookItemModal("editTemplate")
                              }
                            >
                              <figure>
                                <img src="images/add_template.png" />
                              </figure>{" "}
                              Add Items from Pricebook
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Add Custom Item</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() => showAddStaticEntryModal("editTemplate")}
                            >
                              <figure>
                                <img src="images/add_template.png" />
                              </figure>{" "}
                              Add Custom Item
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Import Template</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              onClick={() => showImportTemplateModal()}
                            >
                              <figure>
                                <img src="images/Icon-6.png" />
                              </figure>{" "}
                              Import Template
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="CommonModalArea">
                          <h5>Export Template</h5>
                          <div className="CommonModalBox">
                            <h5 className="">
                              <figure>
                                <img src="images/icon-54.png" />
                              </figure>{" "}
                              Export Template
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <h6 className="mb-2">Release for Use</h6>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="releaseUse"
                      value={releaseUse}
                      checked={releaseUse}
                      onChange={() =>
                        updateLeadState({
                          ...iLeadState,
                          releaseUse: !releaseUse,
                        })
                      }
                    />
                    <span className="slider" />
                  </label>
                </div>
              </div>
              <div className="PurchaseTable">
                <h6>Purchase Details</h6>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Measure</th>
                      <th>Per Unit</th>
                      <th>Client Total</th>
                      <th>PO Line Total</th>
                      <th>Markup</th>
                      <th>Account(s)</th>
                      <th>Pictures</th>
                      <th>Drop Down</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(purchaseDetailsData) ? (
                          <PurchaseDetailsList
                            purchaseDetailsData={purchaseDetailsData}
                            handlePurchaseDetailsList={
                              handlePurchaseDetailsList
                            }
                            handeChangeTemplateForUpdate={handeChangeTemplateForUpdate}
                          />
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="Button d-flex mt-5">
                <button onClick={updateTemplateFun} className="Create">
                  Update Template
                </button>
                <button
                  onClick={hideUpdateTemplateModal}
                  className="Outline ml-5"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDPRICEBOOKITEMMODLASHOW */}
      <Modal
        show={addPriceBookItemModal}
        onHide={hideAddPriceBookItemModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddPriceBookItemModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Select Items from Price Book</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-row">
                  <div className="Categories Categories2 mb-4 form-group col-md-5">
                    <h6>
                      Category <span className="required">*</span>
                    </h6>
                 

                    <Select
                    className="form-control"
                    defaultValue={CategoryId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, CategoryId: e.value })
                    }
                    options={categoryListData?.categoryDetails?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />

<span style={{ color: "red", fontSize: "14px" }}>
                      {templateError?.CtcCategoryIdError}
                    </span>
                
                   
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <h6>Select Items</h6>
                    <div className="ModalInputSearch">
                      <input
                        type="text"
                        placeholder="Search entry or Assembly name"
                        onChange={(e) => {
                          dispatch(
                            getAllAssemblyListAction(CategoryId, e.target.value)
                          );
                        }}
                      />
                      <span>
                        <img src="images/search.png" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="BaselineTable BaselineTableModal">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Assembly</th>
                        <th>Measure</th>
                        <th />
                        <th>Markup</th>
                        <th />
                        <th>Select</th>
                      </tr>
                    </thead>

                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(assemblyListData?.assemblyDetails) ? (
                          assemblyListData?.assemblyDetails?.map((ele, ind) => {
                            return (
                              <MainTable
                                key={ind}
                                ele={ele}
                                entriesListData={ele?.Entries}
                                handleSelection={handleSelection}
                              />
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </table>
                  {/* <span style={{color:"red", fontSize:"14px"}}>{"purchaseDetailsError?.categoryIdError"}</span> */}
                </div>
                <span style={{ color: "red", fontSize: "14px" }}>
                  {purchaseDetailsError?.categoryIdError}
                </span>
                {/* <div className="Button d-flex mt-5">
        <button className="Create">Add Items to CTC Master</button>
      </div> */}
                <div className="ChooseQualityTable">
                  <h6>Choose Quantity for each selected Item</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Measure</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(tableSelectedValues) ? (
                        tableSelectedValues?.map((ele, i) => {
                          return (
                            <tr>
                              <td>
                                {ele?.name}
                                <a onClick={() => removePurchase(i)}>
                                  {" "}
                                  <img src="images/Cross.png" />
                                </a>
                              </td>
                            
                              <td>pkg allowance</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    addQuantityKey(e.target.value)
                                  }
                                />
                              </td>
                         
                            </tr>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="Button d-flex mt-4">
                  <button className="Create" onClick={addItemsToPurchase}>
                    Add Items to Client Pricebook
                  </button>
                  <button
                    className="Outline ml-md-5"
                    onClick={hideAddPriceBookItemModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal
        show={addCopyFromOldModal}
        onHide={hideAddPriceBookItemModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddCopyFromOldModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Copy From Old CTC Template</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-row">
                  <div className="Categories Categories2 mb-4 form-group col-md-5">
                    <h6>
                      Category <span className="required">*</span>
                    </h6>
                 

                    <Select
                    className="form-control"
                    defaultValue={ctcCopyCategoryId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, ctcCopyCategoryId: e.value })
                    }
                    options={ctcCategoryListData?.data?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />

<span style={{ color: "red", fontSize: "14px" }}>
                      {templateError?.CtcCategoryIdError}
                    </span>
                
                   
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <h6>Select Items</h6>
                    <div className="ModalInputSearch">
                      <input
                        type="text"
                        placeholder="Search entry or Assembly name"
                        onChange={(e) => {
                          dispatch(
                            getCTCAssemblyListAction(CtcCategoryId,templateId,ctcCopyCategoryId, e.target.value)
                          );
                        }}
                      />
                      <span>
                        <img src="images/search.png" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="BaselineTable BaselineTableModal">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Assembly</th>
                        <th>Measure</th>
                        <th />
                        <th>Markup</th>
                        <th />
                        <th>Select</th>
                      </tr>
                    </thead>

                    {loader ? (
                      <>
                        <td>
                          {" "}
                          <Loader />
                        </td>
                      </>
                    ) : (
                      <>
                        {checkArray(ctcAssemblyListData?.data) ? (
                          ctcAssemblyListData?.data?.map((ele, ind) => {
                            return (
                              <MainTable
                                key={ind}
                                ele={ele}
                                entriesListData={ele?.Entries}
                                handleSelection={handleSelection}
                              />
                            );
                          })
                        ) : (
                          <td>
                            {" "}
                            <NoDataFound />
                          </td>
                        )}
                      </>
                    )}
                  </table>
                  {/* <span style={{color:"red", fontSize:"14px"}}>{"purchaseDetailsError?.categoryIdError"}</span> */}
                </div>
                <span style={{ color: "red", fontSize: "14px" }}>
                  {purchaseDetailsError?.categoryIdError}
                </span>
                {/* <div className="Button d-flex mt-5">
        <button className="Create">Add Items to CTC Master</button>
      </div> */}
                <div className="ChooseQualityTable">
                  <h6>Choose Quantity for each selected Item</h6>
                  <table>
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Measure</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {checkArray(tableSelectedValues) ? (
                        tableSelectedValues?.map((ele, i) => {
                          return (
                            <tr>
                              <td>
                                {ele?.name}
                                <a onClick={() => removePurchase(i)}>
                                  {" "}
                                  <img src="images/Cross.png" />
                                </a>
                                   <span style={{ color: "red", fontSize: "14px" }}>
                                {purchaseDetailsError?.categoryIdError}
                              </span>
                              </td>
                           
                              <td>pkg allowance</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) =>
                                    addQuantityKey(e.target.value)
                                  }
                                />
                              </td>
                              <span style={{ color: "red", fontSize: "14px" }}>
                                {purchaseDetailsError?.quantityError}
                              </span>
                            </tr>
                          );
                        })
                      ) : (
                        <NoDataFound />
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="Button d-flex mt-4">
                  <button className="Create" onClick={addItemsToPurchase}>
                    Add Items to Client Pricebook
                  </button>
                  <button
                    className="Outline ml-md-5"
                    onClick={hideAddPriceBookItemModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={permissionModal}
        onHide={hidePermissionModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hidePermissionModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Price Book Permisisions</h4>
            </div>
            <div className="FolderForm">
              <div className="AssignTable">
                <h4>Assign Book Types</h4>
                <article class="width75">
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th colSpan={4}>Book Type</th>
                      </tr>
                      <tr>
                        <th>Group</th>
                        <th>System Price Book</th>
                        <th>Edit Price Book</th>
                        <th>CTC</th>
                        <th>All</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Admin</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Sales</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Design</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Construction</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Warranty</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <div className="Button">
                  <button className="Create">Set Price Book Permissions</button>
                </div>
              </div>
              <div className="AssignTable mb-0">
                <h4>Assign Book Actions</h4>
                <article>
                  <table>
                    <thead>
                      <tr>
                        <th />
                        <th colSpan={7}>Book Actions</th>
                      </tr>
                      <tr>
                        <th>Group</th>
                        <th>Upload</th>
                        <th>Dounload</th>
                        <th>View Category</th>
                        <th>Create Category</th>
                        <th>Edit Category</th>
                        <th>Delete Category</th>
                        <th>All</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Admin</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Sales</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Design</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Construction</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Warranty</td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td>
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                <div className="Button mb-4">
                  <button className="Create">Set Price Book Actions</button>
                </div>
                <div className="Button mt-5">
                  <h4 className="text-red my-4">
                    Set-up which PriceBook to put in use
                  </h4>
                  <div className="d-flex flex-wrap mt-4">
                    <label className="CheckBox mr-3">
                      {" "}
                      System PriceBook
                      <input
                        type="radio"
                        name="permission"
                        value={"systemPriceBook"}
                        onChange={handleRadio}
                      />
                      <span className="checkmark" />
                    </label>
                    <label className="CheckBox">
                      {" "}
                      Client PriceBook
                      <input
                        type="radio"
                        name="permission"
                        value={"clientPriceBook"}
                        onChange={handleRadio}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="Button mt-4">
                    <button onClick={updatePermission} className="Create">
                      Put in Use
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* updatetemplatepic */}

      {/* ADDFILESMODALSHOW */}
      <Modal
        show={updateTemplatePicModal}
        onHide={hideUpdateTemplatePicModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideUpdateTemplatePicModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Upload Images</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" name="images" onChange={handleInputFile} />
                  <img src={imagePreview} />
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={updateTemplatePicfun}>
                  Save
                </button>
                <button
                  className="Outline"
                  onClick={hideUpdateTemplatePicModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ENRYHISTORYMODALSHOW */}
      <Modal
        show={templateHistoryModal}
        onHide={hideTemplateHistoryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideTemplateHistoryModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Template History</h4>
            </div>
            <div className="JobTitleArea mb-2">
              <div className="JobBox">
                <h6>
                  <span> {requiredName}</span>
                </h6>
              </div>
            </div>
            <div className="TableList CommonHeading TableHeightScroll">
              <table>
                <thead>
                  <tr>
                    <th>Modified By</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <>
                      {" "}
                      <Loader />
                    </>
                  ) : (
                    <>
                      {checkArray(templateHistoryData?.ctcTemplateHistories) ? (
                        templateHistoryData?.ctcTemplateHistories?.map(
                          (ele, ind) => {
                            return (
                              <>
                                <tr>
                                  <td>{ele?.createdByUser?.name}</td>
                                  <td>{ele?.createdByUser?.name}</td>
                                  <td>{ele?.createdAt?.split("T")[0]}</td>
                                  <td>{ele?.activity}</td>
                                </tr>
                              </>
                            );
                          }
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ImportTemplateModal}
        onHide={hideImportTemplateModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideImportTemplateModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Import CTC Template</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    {filePreview?<span>{filePreview}</span>:<span>-Drag and Drop Files or click to select</span>

                      }
                  
                    <input type="file" name="file" onChange={handleInputFile} />
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {importTemplateError}
                  </span>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addImportTemplateFun}>
                    Save
                  </button>
                  <button className="Outline" onClick={hideImportTemplateModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ADDENTRYMODALSHOW */}
      <Modal
        show={addStaticEntryModal}
        onHide={hideAddStaticEntryModal}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal ClosePermissionId"
            onClick={hideAddStaticEntryModal}
          >
            ×
          </a>
      

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add Custom Item</h4>
              </div>
              <div className="FolderForm">
              <div className="form-group">
                <h6>Name</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert new entry name"
                  name="name"
                  value={name}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.nameError}
                </span>
              </div>

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Category<span className="required">*</span>
                  </h6>
                  <Select
                    className="form-control"
                    defaultValue={CategoryId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, CategoryId: e.value })
                    }
                    options={categoryListData?.categoryDetails?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                  {/* <div className="Categories_all Categories_all2">
                    <select
                      name="CategoryId"
                      value={CategoryId}
                      onChange={handleInput}
                    >
                      <option>Select Category</option>
                      {checkArray(categoryListData?.categoryDetails) ? (
                        <>
                          {categoryListData?.categoryDetails?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                       
                      ) : (
                        <></>
                      )}
                    </select>


                    <img src="images/down.png" />
                  </div> */}
                  <ul>
               
                    <li
                      onClick={() => showAddCategoryModal("entryAdd")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.CategoryIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>

              <div className="form-group">
                <h6>Type</h6>
                <label className="Radio margin-bottom">
                  Assembly entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Assembly"}
                    onChange={handleCheckbox}
                    checked={entryType == "Assembly"}
                  />
                  <span className="checkmark" />
                </label>
                <label className="Radio">
                  Stand alone entry
                  <input
                    type="radio"
                    name="entryType"
                    value={"Standalone"}
                    onChange={handleCheckbox}
                    checked={entryType === "Standalone"}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <span style={{ color: "red", fontSize: "14px" }}>
                {entryErrors?.entryTypeError}
              </span>
              {CategoryId && entryType === "Assembly" ? (
                // assemblyListData?.assemblyDetails && assemblyListData?.assemblyDetails?.length > 0
                <div className="form-group">
                  <div className="Categories Categories2 mb-4">
                    <h6>
                      Assembly<span className="required">*</span>
                    </h6>
                    <Select
                    className="form-control"
                    defaultValue={AssemblyId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, AssemblyId: e.value })
                    }
                    options={assemblyListData?.assemblyDetails?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                    {/* <div className="Categories_all Categories_all2">
                      <select
                        name="AssemblyId"
                        value={AssemblyId}
                        onChange={handleInput}
                      >
                        <option>Select Assembly</option>
                        {checkArray(assemblyListData?.assemblyDetails) ? (
                          <>
                            {assemblyListData?.assemblyDetails?.map(
                              (ele, i) => {
                                return (
                                  <option value={ele?.id}>{ele?.name}</option>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </select>
                      <img src="images/down.png" />
                    </div> */}
                    <ul>
                      {/* <li>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) =>
                            dispatch(
                              getAllCategoryListAction(
                                categoryType,
                                e.target.value
                              )
                            )
                          }
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li> */}
                      <li
                        onClick={() => showAddAssemblyModal("entryAdd")}
                        class="CursorPointer FolderPermissionId"
                      >
                        <span class="text-red2 fw-16">+</span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp; New
                      </li>
                    </ul>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {entryErrors?.AssemblyIdError}
                    </span>
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.countryError}
                    </span> */}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="form-group">
                <div className="Categories Categories2 mb-4">
                  <h6>
                    Measure Unit<span className="required">*</span>
                  </h6>
                  <Select
                    className="form-control"
                    defaultValue={MeasureId}
                    onChange={(e) =>
                      updateLeadState({ ...iLeadState, MeasureId: e.value })
                    }
                    options={measureListData?.measures?.map((cat) => {
                      return { label: cat?.name, value: cat?.id };
                    })}
                  />
                  {/* <div className="Categories_all Categories_all2">
                    <select
                      name="MeasureId"
                      value={MeasureId}
                      onChange={handleInput}
                    >
                      <option>Select Measure</option>
                      {checkArray(measureListData?.measures) ? (
                        <>
                          {measureListData?.measures?.map((ele, i) => {
                            return <option value={ele?.id}>{ele?.name}</option>;
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </select>
                    <img src="images/down.png" />
                  </div> */}
                  <ul>
                    {/* <li>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) =>
                          dispatch(getAllMeasureListAction(e.target.value))
                        }
                      />
                      <span className="Search">
                        <img src="images/search.png" />
                      </span>
                    </li> */}
                    <li
                      onClick={() => showAddMeasureModal("entryAdd")}
                      class="CursorPointer FolderPermissionId"
                    >
                      <span class="text-red2 fw-16">+</span>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp; New
                    </li>
                    {/* <li className="CursorPointer">
                        - Full list of names loaded from the system
                      </li> */}
                  </ul>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.MeasureIdError}
                  </span>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                          {errors?.countryError}
                        </span> */}
                </div>
              </div>
              <div className="form-group">
                <h6>Unit Cost</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert Unit Cost in $"
                  name="unitCost"
                  value={unitCost}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.unitCostError}
                </span>
              </div>
              <div className="form-group">
                <h6>Markup %</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insert mark up"
                  name="markup"
                  value={markup}
                  onChange={handleInput}
                />
                <span style={{ color: "red", fontSize: "14px" }}>
                  {entryErrors?.markupError}
                </span>
              </div>
              <div className="row">
                <div className="col-md-6">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Images</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={showAddFilesModal}
                      >
                        <figure>
                          <img src="images/image-icon.png" />
                        </figure>{" "}
                        Images{" "}
                      </h5>
                      {/* <img src={imagePreview}/> */}
                      <span
                        className="Count CursorPointer"
                        // data-toggle="modal"
                        // data-target="#CarouselInModal"
                        // data-dismiss="modal"
                      >
                        {/* <img  src={imagePreview}/> */}
                      </span>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.imagesError}
                  </span>
                </div>
                </div>
                

                  <div className="col-md-6">
                  <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Select Accounts</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => showAddAccountModal("customItem")}
                      >
                        <figure>
                          <img src="images/select-account.png" />
                        </figure>{" "}
                        Select Account
                      </h5>
                    </div>
                  </div>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {entryErrors?.entryAccountError}
                  </span>
                </div>
                  {checkArray(entryAccount) ? (
                  entryAccount?.map((ele, i) => {
                    return (
                      <>
                        <div className="FilesAdded">
                          <label>{ele?.costCode}</label>
                          <label>{ele?.costArea}</label>
                          <span>
                            <img
                              onClick={() => removeEntryAccount(i)}
                              src="images/Cross.png"
                            />
                          </span>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <></>
                )}
                  </div>

              </div>
            
          
           

                {/* <div className="FilesAdded">
                  <label>23-0101 Appliances</label>
                  <span>
                    <img src="images/Cross.png" className="Cross" />
                  </span>
                </div> */}
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addEntryFun}>
                    Create Entry
                  </button>
                  <button className="Outline" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            {/* 


  <div className="FolderForm">
  
      <div className="form-group">
        <h6>Name</h6>
        <input
          type="text"
          className="form-control"
          placeholder="Insert new entry name"
        />
      </div>
      <div className="form-group">
        <h6>Category</h6>
        <div className="Categories Categories1">
          <div className="Categories_all Categories_all1">
            <span>Select a category</span>
            <img src="images/down.png" />
          </div>
          <ul>
            <li>
              <input type="text" placeholder="Search" />
              <span className="Search">
                <img src="images/search.png" />
              </span>
            </li>
            <li className="CursorPointer">
              <span className="text-red2 fw-16">+</span>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp; New
            </li>
            <li>- Full list of categories loaded from the system</li>
          </ul>
        </div>
      </div>
      <div className="form-group">
        <h6>Type</h6>
        <label className="Radio margin-bottom">
          Assembly entry
          <input type="radio" name="Cuisine" defaultValue="text" />
          <span className="checkmark" />
        </label>
        <label className="Radio">
          Stand alone entry
          <input type="radio" name="Cuisine" defaultValue="text" />
          <span className="checkmark" />
        </label>
      </div>
      <div className="form-group">
        <h6>Assembly</h6>
        <div className="Categories Categories2">
          <div className="Categories_all Categories_all2">
            <span>Select an assembly</span>
            <img src="images/down.png" />
          </div>
          <ul>
            <li>
              <input type="text" placeholder="Search" />
              <span className="Search">
                <img src="images/search.png" />
              </span>
            </li>
            <li className="CursorPointer">
              <span className="text-red2 fw-16">+</span>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp; New
            </li>
            <li>- Full list of assemblies for the selected category</li>
          </ul>
        </div>
      </div>
      <div className="form-group">
        <h6>Measure (unit)</h6>
        <div className="Categories Categories3">
          <div className="Categories_all Categories_all3">
            <span>Select measure type</span>
            <img src="images/down.png" />
          </div>
          <ul>
            <li>
              <input type="text" placeholder="Search" />
              <span className="Search">
                <img src="images/search.png" />
              </span>
            </li>
            <li className="CursorPointer">
              <span className="text-red2 fw-16">+</span>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp; New
            </li>
            <li>- Full list of units loaded from the system</li>
          </ul>
        </div>
      </div>
      <div className="form-group">
        <h6>Unit Cost</h6>
        <input
          type="text"
          className="form-control"
          placeholder="Insert Unit Cost in $"
        />
      </div>
      <div className="form-group">
        <h6>Markup %</h6>
        <input
          type="text"
          className="form-control"
          placeholder="Insert mark up"
        />
      </div>
      <div className="CommonModalAreaMain">
        <div className="CommonModalArea">
          <div className="form-group">
            <h5>Add Picture Image</h5>
            <div className="CommonModalBox">
              <h5
                className="CursorPointer FolderPermissionId"
                data-toggle="modal"
                data-target="#UploadImagesCtc"
                data-dismiss="modal"
              >
                <figure>
                  <img src="images/image-icon.png" />
                </figure>{" "}
                <span className="Count">3</span> Images
              </h5>
            </div>
          </div>
        </div>
        <div className="CommonModalArea">
          <div className="form-group">
            <h5>Select Accounts</h5>
            <div className="CommonModalBox">
              <h5 className="">
                <figure>
                  <img src="images/select-account.png" />
                </figure>{" "}
                Select Account
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="FilesAdded">
        <label>23-0101 Appliances</label>
        <span>
          <img src="images/Cross.png" className="Cross" />
        </span>
      </div>
      <div className="form-group">
        <label className="CheckBox">
          {" "}
          Create New Entry in the Price Book
          <input type="checkbox" />
          <span className="checkmark" />
        </label>
      </div>
      <div className="Button d-flex justify-content-between">
        <button
          className="Create"
          data-toggle="modal"
          data-target="#CreateCTCTemplate"
          data-dismiss="modal"
        >
          Add Item to Change Order
        </button>
        <button
          className="Outline"
           onClick={hideAddStaticEntryModal}
        >
          Cancel
        </button>
      </div>
   
  </div> */}
          
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceBook;

const MianBoxItems = ({ mainCheck, item, handleSelection,key, selectedIndex }) => {
  const [curCheck, setCurCheck] = useState(false);

  let dynamicStyle ="none";

  if(key==selectedIndex){
    dynamicStyle= dynamicStyle==""?"none":""  }

  return (
    <tr className=""  style={{display: dynamicStyle}}>
      <td colSpan={6}>
        <table>
          <tbody>
         
            <tr>
              <td>
                <div className="BigBox">{item?.name}</div>
              </td>
              <td>
                <div className="SmallBox">pkg allowance</div>
              </td>
              <td>
                <div className="SmallBox">{item?.unitCost}</div>
              </td>
              <td>
                <div className="SmallBox">{item?.markup}</div>
              </td>
              <td>
                <div className="SmallBox">{item?.createdAt?.split("T")[0]}</div>
              </td>
              <td>
                <div className="SmallBox">
                  <label className="CheckBox">
                    <input
                      type="checkbox"
                      name={item.id}
                      onChange={(e) => {
                        !mainCheck && handleSelection(item, curCheck);
                        setCurCheck((prev) => !prev);
                      }}
                      checked={curCheck || mainCheck}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
};

const MainTable = ({ entriesListData, ele, handleSelection,key }) => {
  // console.log("=============", ele, handleSelection )
;
  const [showMainTable, setShowMainTable] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);
  const [entryData, setEntryData] = useState([]);
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(0)

  const showMainTableFunc = () => {

    const handleSelect=()=>{}
    setShowMainTable(true);
    // dispatch(getAllEntryListAction(ele?.id))
  };
  return (
    <tbody>
      <tr className="BaselineShowBorder" onClick={showMainTableFunc}>
        <td>
          <div className="BigBox BaselineShow" onClick={()=>setSelectedIndex(key)}>
            <img src="images/rightarrow2.png" />
            {ele?.name}
          </div>
        </td>
        <td>
          <div className="SmallBox BaselineShow">pkg allowance</div>
        </td>
        <td>
          <div className="SmallBox BaselineShow" />
        </td>
        <td>
          <div className="SmallBox BaselineShow">{ele?.defaultMarkup}</div>
        </td>
        <td>
          <div className="SmallBox BaselineShow" />
        </td>
        <td>
          <div className="SmallBox">
            <label className="CheckBox">
              <input
                type="checkbox"
                onChange={() => {
                  handleSelection(entriesListData?.entries, mainCheck);

                  setMainCheck((prev) => !prev);
                }}
                checked={mainCheck}
              />
              <span className="checkmark" />
            </label>
          </div>
        </td>
      </tr>
      {true &&
        checkArray(entriesListData) &&
        entriesListData?.map((item, i) => {
          return (
            <MianBoxItems
              mainCheck={mainCheck}
              item={item}
              key={i}
              handleSelection={handleSelection}
              selectedIndex={selectedIndex}
            />
          );
        })}
    </tbody>
  );
};

const AssemblyCard = ({ ele, handlePurchaseDetailsList, index, mainIndex,handeChangeTemplateForUpdate ,purchaseDetailsData}) => {
  const [checked, setChecked] = useState(false);
  // console.log("dfghjkjhgfdfghjkjhgfdfg",checked)


  

  return (
    <>
      <tr className="TableLightBlueRaw">
        <td>{ele?.name}</td>
        <td />
        <td>{ele?.measure}</td>
        <td />
        <td />
        <td />
        <td>{ele?.markup}</td>
        <td />
        <td />
        <td>
          <td></td>
          <label className="CheckBox">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                setChecked((prev) => !prev);
                handlePurchaseDetailsList?.(!checked, index, mainIndex, ele);
              }}
            />
            <span className="checkmark" />
          </label>
        </td>
        <td>
          <div className="dropdown">
            <a
              className=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="images/dots.png" />
            </a>
            <ol className="dropdown-menu">
              <li>
                <a
                  href="javascript:void(0);"
                  className="FolderPermissionId"
                  data-toggle="modal"
                  data-target="#FourEditAssemblyEleven"
                  data-dismiss="modal"
                >
                  <img src="images/update_price_book.png" />
                  Update Price Book's Assembly
                </a>
              </li>
            </ol>
          </div>
        </td>
      </tr>

      {checkArray(ele?.purchaseDetails) ? (
        ele?.purchaseDetails?.map((ele, i) => {
          return (
            <>
              <tr>
              <td>{ele?.name}</td>
                <td><input  value={ele?.quantity} onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i, "quantity",purchaseDetailsData)} className="form-control" /></td>
                <td>{"pkg allowance"}</td>
                <td><input value={ele?.unitCost ?ele?.unitCost:ele?.perUnit} onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i,"unitCost", purchaseDetailsData)} className="form-control" /></td>
                <td><input value={ele?.clientTotal}  className="form-control" onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i,"clientTotal",purchaseDetailsData)} /></td>
                <td><input value={ele?.poLineTotal} className="form-control" onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i,"poLineTotal",purchaseDetailsData)} /></td>
                <td><input value={ele?.markup}  className="form-control" onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i,"markup",purchaseDetailsData)} /></td>
                <td><input vlaue={ele?.accounts}  className="form-control" onChange={(e)=> handeChangeTemplateForUpdate(e, mainIndex, index, i,"accounts",purchaseDetailsData)} /></td>
                <td>
                  <figure>
                    <img src="images/picture-1.png" />
                  </figure>
                </td>
                <td />
                <td>
                  <div className="dropdown">
                    <a
                      className=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="images/dots.png" />
                    </a>
                    <ol className="dropdown-menu">
                      <li>
                        <a
                          href="javascript:void(0);"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#FourEditAssemblyEleven"
                          data-dismiss="modal"
                        >
                          <img src="images/update_price_book.png" />
                          Update Price Book's Assembly
                        </a>
                      </li>
                      <li>
                        <a >
                          <img src="images/dropdown-delete.png" />
                          Remove item
                        </a>
                      </li>
                    </ol>
                  </div>
                </td>
              </tr>
            </>
          );
        })
      ) : (
        <NoDataFound />
      )}
    </>
  );
};
const PurchaseDetailsList = ({
  purchaseDetailsData,
  handlePurchaseDetailsList,
  handeChangeTemplateForUpdate,
  
}) => {
  return purchaseDetailsData?.map((ele, ind) => {
    console.log("NAME ===============> ", ele)
    return (
      <>
        <tr className="TableDarkBlueRaw">
          <td colSpan={11}>{ele?.name}</td>
        </tr>
        {checkArray(ele?.assemblies) ? (
          ele?.assemblies?.map((ele, i) => {
            return (
              <AssemblyCard
                ele={ele}
                handlePurchaseDetailsList={handlePurchaseDetailsList}
                index={i}
                mainIndex={ind}
                handeChangeTemplateForUpdate={handeChangeTemplateForUpdate}
                purchaseDetailsData={purchaseDetailsData}
              />
            );
          })
        ) : (
          <></>
        )}

        {checkArray(ele?.standalones) ? (
          ele?.standalones?.map((ele, i) => {
            return (
              <>
                <tr>
                  <td>{ele?.name}</td>
                  <td>{ele?.quantity}</td>
                  <td>{ele?.name}</td>
                  <td>{ele?.perUnit}</td>
                  <td>{ele?.clientTotal}</td>
                  <td>{ele?.poLineTotal}</td>
                  <td>{ele?.markup}</td>
                  <td>{ele?.clientTotal}</td>
                  <td>{"Standalones"}</td>
                  <td>
                    <figure>
                      <img src="images/picture-1.png" />
                    </figure>
                  </td>
                  <td />
                  <td>
                    <div className="dropdown">
                      <a
                        className=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <ol className="dropdown-menu">
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#FourEditAssemblyEleven"
                            data-dismiss="modal"
                          >
                            <img src="images/update_price_book.png" />
                            Update Price Book's Assembly
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <img src="images/dropdown-delete.png" />
                            Remove item
                          </a>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
              </>
            );
          })
        ) : (
          <></>
        )}
      </>
    );
  });
};

const PurchaseDetailsUploadTemplateList = ({
  purchaseDetailsData,
  handlePurchaseDetailsList,
  handeChangeTemplate,
  handleRemoveItem
}) => {
  return purchaseDetailsData?.map((ele, ind) => {
    // console.log("purchase DetailsData, ", ele)
    return (
      <>
        <tr className="TableDarkBlueRaw">
          <td colSpan={11}>{ele?.name}</td>
        </tr>
        {checkArray(ele?.Assemblies) ? (
          ele?.Assemblies?.map((ele, i) => {
            return (
              <AssemblyCardTemplateUpload
                ele={ele}
                handlePurchaseDetailsList={handlePurchaseDetailsList}
                index={i}
                mainIndex={ind}
                handeChangeTemplate={handeChangeTemplate}
                handleRemoveItem={handleRemoveItem}
              />
            );
          })
        ) : (
          <></>
        )}

        {checkArray(ele?.standalones) ? (
          ele?.standalones?.map((ele, i) => {
            return (
              <>
                <tr>
                  <td>{ele?.name}</td>
                  <td>{ele?.quantity}</td>
                  <td>{ele?.name}</td>
                  <td>{ele?.perUnit}</td>
                  <td>{ele?.clientTotal}</td>
                  <td>{ele?.poLineTotal}</td>
                  <td>{ele?.markup}</td>
                  <td>{ele?.clientTotal}</td>
                  <td>{"Standalones"}</td>
                  <td>
                    <figure>
                      <img src="images/picture-1.png" />
                    </figure>
                  </td>
                  <td />
                  <td>
                    <div className="dropdown">
                      <a
                        className=""
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src="images/dots.png" />
                      </a>
                      <ol className="dropdown-menu">
                        <li>
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#FourEditAssemblyEleven"
                            data-dismiss="modal"
                          >
                            <img src="images/update_price_book.png" />
                            Update Price Book's Assembly
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <img src="images/dropdown-delete.png" />
                            Remove item
                          </a>
                        </li>
                      </ol>
                    </div>
                  </td>
                </tr>
              </>
            );
          })
        ) : (
          <></>
        )}
      </>
    );
  });
};

const AssemblyCardTemplateUpload = ({
  ele,
  handlePurchaseDetailsList,
  index,
  mainIndex,
  handeChangeTemplate,
  handleRemoveItem
}) => {
  const [checked, setChecked] = useState(false);
  console.log("NAME===========>",checked)



  return (
    <>
      <tr className="TableLightBlueRaw">
        <td>{ele?.name}</td>
        <td />
        <td>{ele?.measure}</td>
        <td />
        <td />
        <td />
        <td>{ele?.defaultMarkup}</td>
        <td />
        <td />
        <td>
          {/* <td>{"Assembles"}</td> */}
          <label className="CheckBox">
            <input
              type="checkbox"
              // checked={ele?.items?.length > 1}
              onChange={(e) => {
                setChecked((prev) => !prev);
                handlePurchaseDetailsList?.(!checked, index, mainIndex, ele);
              }}
            />
            <span className="checkmark" />
          </label>
        </td>
        <td>
          <div className="dropdown">
            <a
              className=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="images/dots.png" />
            </a>
            <ol className="dropdown-menu">
              <li>
                <a
                  href="javascript:void(0);"
                  className="FolderPermissionId"
                  data-toggle="modal"
                  data-target="#FourEditAssemblyEleven"
                  data-dismiss="modal"
                >
                  <img src="images/update_price_book.png" />
                  Update Price Book's Assembly
                </a>
              </li>
            </ol>
          </div>
        </td>
      </tr>

      {checkArray(ele?.Entries) && (
        ele?.Entries?.map((ele, i) => {
          return (
            <>
              <tr>
                <td>{ele?.name}</td>
                <td><input  value={ele?.quantity} onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i, "quantity", )} className="form-control" /></td>
                <td>{ele?.name}</td>
                <td><input value={ele?.unitCost} onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i,"unitCost")} className="form-control" /></td>
                <td><input value={(ele?.unitCost * ele?.quantity) +( ele?.unitCost * ele?.quantity) * ele?.quantity *ele?.markup / 100}  className="form-control" onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i,"clientTotal")} disabled={true} /></td>
                <td><input value={ele?.unitCost * ele?.quantity} className="form-control" onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i,"poLineTotal")} disabled={true} /></td>
                <td><input value={ele?.markup}  className="form-control" onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i,"markup")} /></td>
                <td><input vlaue={ele?.accounts}  className="form-control" onChange={(e)=> handeChangeTemplate(e, mainIndex, index, i,"accounts")} /></td>
                <td>
                  {ele?.Images&&
                  <figure>
                    <img src="images/picture-1.png" />
                  </figure>}
                </td>
                <td />
                <td>
                  <div className="dropdown">
                    <a
                      className=""
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="images/dots.png" />
                    </a>
                    <ol className="dropdown-menu">
                      <li>
                        <a
                          href="javascript:void(0);"
                          className="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#FourEditAssemblyEleven"
                          data-dismiss="modal"
                        >
                          <img src="images/update_price_book.png" />
                          Update Price Book's Assembly
                        </a>
                      </li>
                      <li>
                        <a onClick={()=> handleRemoveItem(mainIndex,index,i)} >
                          <img src="images/dropdown-delete.png" />
                          Remove item
                        </a>
                      </li>
                    </ol>
                  </div>
                </td>
              </tr>
            </>
          );
        })
      ) }
    </>
  );
};
