import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './ThemeContext';

export default function ThemeContextWrapper(props) {
  const [theme, setTheme] = useState(themes.dark);

  function changeTheme(theme) {

    window.localStorage.setItem("themeColor", theme);

  
    setTheme(theme);
  }






  useEffect(() => {


    if(theme=="yellow"){
      document.body.classList.add(`theme-darkyellow`);
      document.body.classList.remove(`theme-cyan`);
      document.body.classList.remove(`theme-pink`);

      document.body.classList.remove(`theme-brown`);


      document.body.classList.remove(`theme-lightgreen`);


    }
    else if(theme=="cyan"){
      document.body.classList.add(`theme-cyan`);
      document.body.classList.remove(`theme-darkyellow`);
      document.body.classList.remove(`theme-brown`);
      document.body.classList.remove(`theme-lightgreen`);
      document.body.classList.remove(`theme-pink`);



    }

    else if(theme=="lightgreen"){
      document.body.classList.add(`theme-lightgreen`);
      document.body.classList.remove(`theme-darkyellow`);
      document.body.classList.remove(`theme-brown`);
      document.body.classList.remove(`theme-cyan`);
      document.body.classList.remove(`theme-pink`);



    }
    else if(theme=="pink"){
      document.body.classList.add(`theme-pink`);
      document.body.classList.remove(`theme-lightgreen`);
      document.body.classList.remove(`theme-brown`);
      document.body.classList.remove(`theme-cyan`);





    }

    else if(theme=="brown"){
      document.body.classList.add(`theme-brown`);
      document.body.classList.remove(`theme-darkyellow`);
      document.body.classList.remove(`theme-lightgreen`);
      document.body.classList.remove(`theme-cyan`);
      document.body.classList.remove(`theme-pink`);







    }
  
    
    
 
  }, [theme]);


  useEffect(()=>{

    let check =     window.localStorage.getItem("themeColor");
    if(check){
      setTheme(check)
    }




  },[])

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
