import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import CreateFolder from "./CreateFolder";
import CreateFile from "./CreateFile";
import UploadFile from "./UploadFile";
import Loader from "../../../Loader";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import {
  constructionfilesDocsUpdates,
  getAllFolderList,
  getAllTagList,
  getDeleteFolder,
  getFileHistoryList,
  getFolderByName,
  getFolderHistoryList,
  getFolderRemove,
  getHtmlFile,
  getListDocumentation,
  getShareDocumentationAdd,
  getViewDocumentation,
  getpendingFileEventList,
  releasFolderfiles,
} from "../../../../redux/action/constructionFileManger";
import moment from "moment";
import SendClientInfo from "./SendClientInfo";
import ManageSignature from "./ManageSignature";
import RealseFiles from "./RealseFiles";
import SignaturePad from "react-signature-canvas";
import FolderHistory from "./FolderHistory";
import {
  getAllClientAction,
  getBuilderSigneeList,
  getConverToString,
} from "../../../../redux/action/saleAction";
import { getAllProjectList } from "../../../../redux/action/vendorViewConfigAction";
import {
  getListDesignDocument,
  getVendorList,
  uploadAggrementFiles,
} from "../../../../redux/action/designSelectionsAction";
import htmlDocx from "html-docx-js/dist/html-docx";
import { dataURLtoBlob, convertToPNG } from "../../../../utils/blog";

const initialState = {
  folderName: "",
  signature: "",
  initial: "",
  errors: {},
  NewFileModal: false,
  fileFolderName: "",
  clientFile: "",
  DocumentationDeleteModal: false,
  docId: "",
  ShareClientDocumentationModal: false,
  emailBody: "",
  clientEmail: "",
  clientName: "",
  emails: [{ name: "", email: "" }],
  folderId: "",
  id: "",
  approverType: "",
  contactSelection: [{ name: "", email: "" }],
  UploadTemplateModal: false,
  templateFile: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
  FolderDeleteModal: false,
  EditDocumentationModal: false,
  content: "",
  viewDocumentEditData: "",
  RelaseTemplateModal: false,
  LockTemplateModal: false,
  FilesHistoryModal: false,
  FolderHistoryModal: false,
  ShareClientDocumentationModal: false,
  fileNames: "",
  projectId: "",
  folderData: [],
  SendForClientModal: false,
  primarySignee: "",
  additionalSignee: "",
  numberOfSignee: 1,
  secondarySignee: "",
  builderSignStatus: "",
  primarySignStatus: "",
  secondarySignStatus: "",
  additionalSignStatus: "",
  primarySignature: "",
  secondarySignature: "",
  additionalSignature: "",
  builderSignature: "",
  ManageSignatures: false,
  signTypes: "",
  primarySignatureBlob: "",
  secondarySignatureBlob: "",
  builderSignatureBlob: "",
  additionalSignatureBlob: "",
  docsId: "",
  builderSignee: "",
  HeaderPreviewModal: false,
  draft: false,
  VendorId:"",
  releaseFileData: [],
};

const FileManger = ({ FileMangerModal, SetFileMangerModal, state }) => {
  const dispatch = useDispatch();

 

  const {
    folderName,
    allFolderList,
    searchFolderList,
    documentationList,
    searchDocumentationList,
    viewDocumentation,
    tagList,
    pendingFileList,
    fileHistoryList,
    loader,
    pendingLoader,
    folderHistoryList,
    folderLoader,
  } = useSelector((state) => state.fileManagerData);
  const [iState, updateState] = useState(initialState);
  const {
    signature,
    initial,
    errors,
    NewFileModal,
    fileFolderName,
    clientFile,
    DocumentationDeleteModal,
    docId,
    ShareClientDocumentationModal,
    emailBody,
    clientName,
    clientEmail,
    emails,
    folderId,
    id,
    approverType,
    contactSelection,
    UploadTemplateModal,
    templateFile,
    ViewDocumentationModal,
    FolderDeleteModal,
    EditDocumentationModal,
    viewDocumentData,
    content,
    viewDocumentEditData,
    RelaseTemplateModal,
    LockTemplateModal,
    FilesHistoryModal,
    FolderHistoryModal,
    fileNames,
    projectId,
    folderData,
    SendForClientModal,
    primarySignee,
    additionalSignee,
    numberOfSignee,
    secondarySignee,
    builderSignStatus,
    primarySignStatus,
    secondarySignStatus,
    additionalSignStatus,
    primarySignature,
    secondarySignature,
    additionalSignature,
    builderSignature,
    ManageSignatures,
    signTypes,
    primarySignatureBlob,
    secondarySignatureBlob,
    builderSignatureBlob,
    additionalSignatureBlob,
    docsId,
    builderSignee,
    HeaderPreviewModal,
    draft,
    VendorId,
    releaseFileData,
  } = iState;

  const [ManageSignatureModal, SetManageSignatureModal] = useState(false);
  const [SendClientInfoModal, SetSendClientInfoModal] = useState(false);
  const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
  const [CreateFolderModal, SetCreateFolderModal] = useState(false);
  const [CreateFileModal, SetCreateFileModal] = useState(false);
  const [UploadFileModal, SetUploadFileModal] = useState(false);
  const [folderHistoryMod, setFolderHistoryMod] = useState(false);

  const [RealseFilesModal, SetRealseFilesModal] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [searchFolderName, setSearchFolderName] = useState("");


  const {
   vendorList,   
  } = useSelector((state) => state.designSelectionsReducers);
  console.log("vendorList",vendorList);
  const handleFileMangerModalClose = () => {
    SetFileMangerModal(false);
  };

  useEffect(() => {
    if(FileMangerModal){
 
    let data = {
      JobId: state?.id,
      JobType: state?.jobType,
      projectId: state?.ProjectId,
    };
    dispatch(getAllFolderList(data));
    dispatch(getVendorList(state?.ProjectId));
         
  }
  }, [FileMangerModal]);

  useEffect(() => {
    if (searchName) {
      dispatch(getFolderByName({ name: searchName }));
    }
  }, [searchName, dispatch]);

  const { allProjectList } = useSelector(
    (state) => state.vendorViewConfigReducer
  );

  useEffect(() => {
    dispatch(getAllProjectList(iBuildLocalData?.user?.CompanyId));
  }, []);

  const [folderIDD, setFolderIDD] = useState("");
  const handleTagDocument = (e, id) => {
    dispatch(getAllTagList(id));
    window.localStorage.setItem("folderID", JSON.stringify(id));
    e.stopPropagation();
    setFolderIDD(id);
    dispatch(getListDocumentation(id));
    dispatch(getpendingFileEventList(id));
  };

  const handleFolderDeleteShow = (id, foldName) => {
    SetFileMangerModal(false);
    updateState({
      ...iState,
      FolderDeleteModal: true,
      docId: id,
      folderName: foldName,
    });
  };

  const handleFolderDeleteClose = () => {
    updateState({
      ...iState,
      FolderDeleteModal: false,
      docId: "",
    });
    SetFileMangerModal(true);
  };

  const handleFolderDelete = () => {
    let dataa = {
      folderName: iState.folderName,
    };
    dispatch(getFolderRemove(dataa, iState.docId, state))
      .then((res) => {
        if (res.status === 200) {
          let data = {
            JobId: state?.id,
            JobType: state?.jobType,
            projectId: state?.projectId
          };
          dispatch(getAllFolderList(data));
          toast.success("Delete Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            FolderDeleteModal: false,
            docId: "",
            folderName: "",
          });
          SetFileMangerModal(true);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            FolderDeleteModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          FolderDeleteModal: true,
        });
      });
  };

  const handleViewDocumentationClose = () => {
    updateState({
      ...iState,
      ViewDocumentationModal: false,
      folderId: "",
      id: "",
      viewDocumentData: "",
    });
    SetFileMangerModal(true);
  };

  const handleViewDocumentationShow = async (foldId, Id, data) => {
    // console.log("data",data);
    SetFileMangerModal(false);
    updateState({
      ...iState,
      ViewDocumentationModal: true,
      folderId: foldId,
      id: Id,
    });
    // const datta = { clientView: true };

    try {
      // const response = await dispatch(getViewDocumentation(foldId, Id));
      // console.log("response", response);
      let urlWithoutQueryString, htmlData, docData;
      if (data?.htmlUrl) {
        urlWithoutQueryString = data?.htmlUrl?.split("?")[0];
        htmlData = await dispatch(
          getConverToString(urlWithoutQueryString, iBuildLocalData?.user?.token)
        );
      } else {
        urlWithoutQueryString = data?.docUrl?.split("?")[0];
        docData = await dispatch(getHtmlFile({ url: urlWithoutQueryString }));
      }

      if (htmlData) {
        updateState({
          ...iState,
          viewDocumentData: htmlData?.data?.data,
          ViewDocumentationModal: true,
        });
      } else if (docData) {
        updateState({
          ...iState,
          viewDocumentData: docData?.data,
          ViewDocumentationModal: true,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleShareClientDocumentShow = (foldId, Id, name) => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: true,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      folderId: foldId,
      id: Id,
      fileNames: name,
    });
    SetFileMangerModal(false);
  };

  const handleShareClientDocumentClose = () => {
    updateState({
      ...iState,
      ShareClientDocumentationModal: false,
      emailBody: "",
      emails: [],
      clientName: "",
      clientEmail: "",
      fileNames: "",
    });
    SetFileMangerModal(true);
  };

  const handleAddRecipient = () => {
    const { clientName, clientEmail } = iState;

    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Push a new recipient object with the clientName and clientEmail values
    updatedEmails.push({ name: clientName, email: clientEmail });

    // Update the state with the modified emails array and reset clientName and clientEmail
    updateState({
      ...iState,
      emails: updatedEmails,
      clientName: "",
      clientEmail: "",
    });
  };

  // Function to remove a recipient at a given index
  const handleRemoveRecipient = (indexToRemove) => {
    // Create a copy of the emails array
    const updatedEmails = [...iState.emails];

    // Use the filter method to create a new array without the item at the specified index
    const updatedEmailsFiltered = updatedEmails.filter(
      (_, index) => index !== indexToRemove
    );

    // Update the state with the modified emails array
    updateState({ ...iState, emails: updatedEmailsFiltered });
  };

  const handleShareDocumentValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!emailBody) {
      error.eBodyError = "* Message can't be empty";
      formIsValid = false;
    }

    // if (!clientName) {
    //   error. = "* Name can't be empty";
    //   formIsValid = false;
    // }

    // if (!clientEmail) {
    //   error.cliEmailError = "*  can't be empty";
    //   formIsValid = false;
    // }
    if (emails?.length == 0) {
      error.cliNameError = "* Name & Email can't be empty";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleShareDocumentSubmit = () => {
    let formIsValid = handleShareDocumentValidation();
    if (formIsValid) {
      const dataa = {
        emailBody,
        emails,
      };

      dispatch(getShareDocumentationAdd(folderId, id, dataa))
        .then((res) => {
          if (res.status === 200) {
            dispatch(getListDocumentation(folderId));
            toast.success("Share Document Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({
              ...iState,
              ShareClientDocumentationModal: false,
              folderId: "",
              id: "",
            });
            SetFileMangerModal(true);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            updateState({
              ...iState,
              loading: false,
              ShareClientDocumentationModal: true,
            });
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            ShareClientDocumentationModal: true,
          });
        });
    }
  };

  const [filesName, setFilesName] = useState("");
  const handleFilesHistoryModalShow = (folderID, id, name) => {
    SetFileMangerModal(false);
    setFolderHistoryMod(true);
    setFilesName(name);
    dispatch(getFileHistoryList(folderID, id));
  };

  const handleFolderHistory = (folderID) => {
    SetFileMangerModal(false);
    setFolderHistoryMod(true);
    // dispatch(getFolderHistoryList(folderID,state));
  };

  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  const handleFolderCheckboxChange = (event, item) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((selectedItem) => selectedItem !== item)
      );
    }
  };
  const handleRelaseTemplateModalShow = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: true,
      projectId: "",
      folderId: "",
    });
    setSelectedOption([]);
    setSelectedItems([]);
    SetFileMangerModal(false);
  };

  const handleRelaseTemplateModalClose = () => {
    updateState({
      ...iState,
      RelaseTemplateModal: false,
    });
    SetFileMangerModal(true);
  };

  // projectId: jobData?.state?.ProjectId,
  // subFileManager: selectedOption,
  // folderId: releaseFileData?.folderId,
  // clientDocId: releaseFileData?.id,
  // VendorId:VendorId||0,

  const handleReleaseTemplate = () => {
    const datta = {
      // projectId: state?.ProjectId,
      // jobId:state?.jobId,
      // jobType:state?.jobType,
      // subFileManager: selectedOption,
      // data: [
      //   {
      //     folderId: selectedItems?.[0]?.FolderId,
      //     Templates: selectedItems?.map((data) => ({
      //       id: data.id,
      //     })),
      //   },
      // ],
      projectId:state?.ProjectId,
      jobId:state?.jobId,
      jobType:state?.jobType,
      subFileManager: selectedOption,
      VendorId:VendorId||null,
      data: [
        {
          folderId: selectedItems?.[0]?.FolderId,
          Templates: selectedItems?.map((data) => ({
            id: data.id,
          })),
        },
      ],
    };
    
    dispatch(releasFolderfiles(datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          RelaseTemplateModal: false,
          folderId:"",
          folderData:[]
        });
        SetFileMangerModal(true);
        setSelectedOption([]);
        setSelectedItems([]);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, RelaseTemplateModal: true });
      }
    });
  };

  const [docsUrlData, setDocsUrlData] = useState("");
  const handleSendForClientModalShow = (data) => {
    updateState({
      ...iState,
      SendForClientModal: true,
      folderId: data?.folderId,
      docsId: data?.id,
    });

    setDocsUrlData(data?.docUrl);
    SetFileMangerModal(false);
  };

  const handleSendForClientModalClose = () => {
    updateState({
      ...iState,
      SendForClientModal: false,
    });
    SetFileMangerModal(true);
  };

  const handleSendForClient = () => {
    handleSendforClientSignatureModalShow();
  };

  const createAboutHeader = () => {
    return { __html: iBuildLocalData?.companies?.[0]?.letterHeader };
  };
  const [SendforClientSignatureModal, setSendforClientSignatureModal] =
    useState(false);
  const handleSendforClientSignatureModalShow = async () => {
    // Show the client signature modal
    setSendforClientSignatureModal(true);

    // Update the modal state
    updateState((prevState) => ({
      ...prevState,
      SendForClientModal: false,
    }));

    try {
      // const contId = JSON.parse(window.localStorage.getItem("contId"));
      const payload = {
        // primaryClientId:+primarySignee,
        // secondaryClientId:+secondarySignee,
        // additionalClientId:+additionalSignee,
        // clientDocId :+docsId,
        // contractId: +contId,
        // folderId: +folderId,
        // builderId:+builderSignee
      };

      const response = await dispatch(payload);

      if (response?.data) {
        setDocsUrlData(response.data.data);
      } else {
        console.warn("Response data not found.");
      }
    } catch (error) {
      // Handle the error, e.g., log it or show an error message.
      console.error("Error fetching documentation:", error);
    }
  };

  const handleSendforClientSignatureModalClose = () => {
    setSendforClientSignatureModal(false);
    updateState((prevState) => ({
      ...prevState,
      SendForClientModal: true,
    }));
  };

  const signCanvas = useRef({});

  const [primayClient, setPrimaryClient] = useState([]);
  const [secondaryClient, setSecondaryClient] = useState([]);
  const [builderData, setBuilder] = useState([]);

  useEffect(() => {
    if (SendForClientModal) {
      let contId = JSON.parse(window.localStorage.getItem("contId"));
      dispatch(getBuilderSigneeList()).then((response) => {
        if (response.status === 200) {
          const buillldata = response?.data?.staff;
          setBuilder(buillldata);
        }
      });
      dispatch(getAllClientAction(contId)).then((res) => {
        if (res) {
          const primeData = res?.clientDetails?.filter(
            (data) => data.isPrimary === true
          );
          const secondData = res?.clientDetails?.filter(
            (data) => data.isPrimary === false
          );
          setPrimaryClient(primeData);
          setSecondaryClient(secondData);
        }
      });
    }
  }, [SendForClientModal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "numberOfSignee") {
      updateState({
        ...iState,
        [name]: value,
        secondarySignee: "",
        additionalSignee: "",
        secondarySignStatus: "",
        additionalSignStatus: "",
      });
    } else if (name === "primarySignee") {
      const dummmyPrimaryData = primayClient?.find((item) => item.id == value);
      updateState({
        ...iState,
        [name]: value,
        primarySignStatus: dummmyPrimaryData?.legalName,
      });
    } else if (name === "secondarySignee") {
      const dummmySecondaryData = secondaryClient?.find(
        (item) => item.id == value
      );
      updateState({
        ...iState,
        [name]: value,
        secondarySignStatus: dummmySecondaryData?.legalName,
      });
    } else if (name === "additionalSignee") {
      const dummmyAdditionalData = secondaryClient?.find(
        (item) => item.id == value
      );
      updateState({
        ...iState,
        [name]: value,
        additionalSignStatus: dummmyAdditionalData?.legalName,
      });
    } else if (name === "builderSignee") {
      const dummmyBuilderData = builderData?.find((item) => item.id == value);
      updateState({
        ...iState,
        [name]: value,
        builderSignStatus: dummmyBuilderData?.name,
      });
    }
    // else if (name === "slectedClient") {
    //   const dummmyallClientsData = allClients?.find((item) => item.id == value);
    //   updateState({
    //     ...iState,
    //     [name]: value,
    //     name: dummmyallClientsData?.legalName,
    //     email: dummmyallClientsData?.email,
    //   });
    // }
    else if (name === "folderId") {
      dispatch(getListDesignDocument(value)).then((res) => {
        if (res.status === 200) {
          updateState({
            ...iState,
            folderData: res?.data?.clientDocDetails,
            [name]: value,
          });
        }
      });
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleManageSignatuesShow = (type) => {
    if (type == "primarySignature") {
      updateState({
        ...iState,
        ManageSignatures: true,
        primarySignature: "",
        signTypes: type,
      });
    }
    if (type == "secondarySignature") {
      updateState({
        ...iState,
        ManageSignatures: true,
        secondarySignature: "",
        signTypes: type,
      });
    }
    if (type == "builderSignature") {
      updateState({
        ...iState,
        ManageSignatures: true,
        builderSignature: "",
        signTypes: type,
      });
    }
    if (type == "additionalSignature") {
      updateState({
        ...iState,
        ManageSignatures: true,
        additionalSignature: "",
        signTypes: type,
      });
    }
  };
  const handleManageSignatuesClose = () => {
    updateState({
      ...iState,
      ManageSignatures: false,
      signature: "",
      signTypes: "",
    });
  };

  const addSignature = () => {
    const signatureBlob = dataURLtoBlob(signature);
    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob);
        return convertedBlob;
      }
      return blob;
    };
    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );
    const validSignatureBlobURL = URL.createObjectURL(validSignatureBlob);

    if (signTypes === "primarySignature") {
      updateState({
        ...iState,
        primarySignature: validSignatureBlobURL,
        primarySignatureBlob: validSignatureBlob,
        ManageSignatures: false,
      });
    } else if (signTypes === "secondarySignature") {
      updateState({
        ...iState,
        secondarySignature: validSignatureBlobURL,
        secondarySignatureBlob: validSignatureBlob,
        ManageSignatures: false,
      });
    } else if (signTypes === "builderSignature") {
      updateState({
        ...iState,
        builderSignature: validSignatureBlobURL,
        builderSignatureBlob: validSignatureBlob,
        ManageSignatures: false,
      });
    } else if (signTypes === "additionalSignature") {
      updateState({
        ...iState,
        additionalSignature: validSignatureBlobURL,
        additionalSignatureBlob: validSignatureBlob,
        ManageSignatures: false,
      });
    }
  };

  const clearSignatureFunc = () => {
    signCanvas.current.clear();
  };

  const handleSendToClient = async (type) => {
    let contId = JSON.parse(window.localStorage.getItem("contId"));
    const htmlContent =
      iBuildLocalData?.companies?.[0]?.letterHeader + docsUrlData;
    const blob = new Blob([htmlContent], { type: "text/html" });
    const file = new File([blob], "content.html");

    const initialFormData = new FormData();
    initialFormData.append("files", file);

    try {
      const uploadResponse = await dispatch(
        uploadAggrementFiles(initialFormData)
      );
      if (uploadResponse.status === 200) {
        const formData = new FormData();
        formData.append("htmlBlobName", uploadResponse?.data?.data);
        formData.append("primarySignee", Number(primarySignee));
        formData.append("primarySign", primarySignatureBlob);
        formData.append("secondarySign", secondarySignatureBlob);
        formData.append("additionalSign", additionalSignatureBlob);
        formData.append("builderSign", builderSignatureBlob);
        formData.append("primarySignStatus", !!primarySignStatus);
        formData.append("secondarySignee", Number(secondarySignee));
        formData.append("secondarySignStatus", !!secondarySignStatus);
        formData.append("builderSignee", Number(builderSignee));
        formData.append("builderSignStatus", !!builderSignStatus);
        formData.append("additionalSignee", Number(additionalSignee));
        formData.append("additionalSignStatus", !!additionalSignStatus);
        formData.append("numberOfSignee", Number(numberOfSignee));
        formData.append("status", type === "save" ? "DRAFT" : "SEND");

        const signatureResponse = await dispatch(
          (folderId, docsId, contId, formData)
        );

        if (signatureResponse.status === 200) {
          toast.success(signatureResponse.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setSendforClientSignatureModal(false);
          updateState((prev) => ({
            ...prev,
            primarySignature: "",
            secondarySignature: "",
            additionalSignature: "",
            builderSignature: "",
            signTypes: "",
            primarySignee: "",
            primarySignStatus: "",
            secondarySignee: "",
            secondarySignStatus: "",
            builderSignee: "",
            builderSignStatus: "",
            additionalSignee: "",
            additionalSignStatus: "",
          }));
        } else {
          throw new Error(signatureResponse.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSendforClientSignatureModal(true);
    }
  };
  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const handlHeaderPreviewModalShow = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: true,
      EditDocumentationModal: false,
    });
  };
  const handlHeaderPreviewModalClose = () => {
    updateState({
      ...iState,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleApply = () => {
    const dataa = iBuildLocalData?.companies?.[0]?.letterHeader;
    const updatedContent = dataa ? dataa + content : content;
    updateState({
      ...iState,
      headerPreviewData: dataa,
      content: updatedContent,
      HeaderPreviewModal: false,
      EditDocumentationModal: true,
    });
  };

  const handleDocumentKeyModalShow = () => {
    updateState({
      ...iState,
      DocumentKeyModal: true,
      JobId: "",
      EditDocumentationModal: false,
    });
  };

  const handleDocumentKeyModalClose = () => {
    updateState({
      ...iState,
      DocumentKeyModal: false,
      JobId: "",
      EditDocumentationModal: true,
    });
  };

  const handleItemClick = (item) => {
    const updatedContent = item ? item + content : content;
    updateState({
      ...iState,
      content: updatedContent,
      DocumentKeyModal: false,
      EditDocumentationModal: true,
    });
  };
  const [contentData, setContentData] = useState([]);
  const handleEditDocumentationShow = async (item) => {
    updateState({
      ...iState,
      EditDocumentationModal: true,
    });
    SetFileMangerModal(false);
    setContentData(item);
    try {
      const urlWithoutQueryString = item?.docUrl?.split("?")[0];
      const htmlData = await dispatch(
        getHtmlFile({ url: urlWithoutQueryString })
      );
      if (htmlData) {
        console.log({ htmlData });
        updateState({
          ...iState,
          EditDocumentationModal: true,
          content: htmlData?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching documentation:", error);
    }
  };

  const handleEditDocumentationClose = () => {
    updateState({
      ...iState,
      EditDocumentationModal: false,
    });
    SetFileMangerModal(true);
  };


  const editorsRef = useRef(null);

  const handleEditorChange = (newContent) => {
    updateState({ ...iState, content: newContent });
  };

  const handleEditDocumentation = async () => {
    updateState({ ...iState, loading: true });
    const blob = new Blob([content], { type: "text/html" });
    const file = new File([blob], "content.html");

    const initialFormData = new FormData();
    initialFormData.append("files", file);
    try {
      const uploadResponse = await dispatch(
        uploadAggrementFiles(initialFormData)
      );
    

      if (uploadResponse.status === 200) {
        const formData = new FormData();
        const docxBlob = htmlDocx.asBlob(content);
        const filesName = `${contentData?.name}`;
        const docxFile = new File([docxBlob], filesName, {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        // saveAs(docxBlob, filesName);
        formData.append("files", docxFile);
        formData.append("htmlUrl", uploadResponse?.data?.data);
        formData.append("draft", draft);
        formData.append("name", contentData?.name);

        const signatureResponse = await dispatch(
          constructionfilesDocsUpdates(
            contentData?.folderId,
            contentData?.id,
            formData
          )
        );

        if (signatureResponse.status === 200) {
          dispatch(getListDocumentation(folderId));
          toast.success("Docs Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            EditDocumentationModal: false,
            loading: false,
            content: "",
          });
          SetFileMangerModal(true);
        } else {
          throw new Error(signatureResponse.data.message);
        }
      } else {
        throw new Error(uploadResponse.data.message);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      updateState({
        ...iState,
        loading: false,
        EditDocumentationModal: true,
      });
    }
  };
  return (
    <>
      <Modal
        show={FileMangerModal}
        className="ModalBox modal fade ExtraLargeModal show"
      >
         <div>
         <div>
        <a
          class="CloseModal CloseModalNew2"
          onClick={handleFileMangerModalClose}
        >
          ×
        </a>
        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Construction File Manager</h4>
          </div>
          <div class="FolderForm CommonSelectBoxMain">
            <form>
              <div class="row">
                <div class="col-sm-4">
                  <div class="DashboardLeft">
                    <div class="ShowList">
                      <div class="ContactInformationArea">
                        <h6>Contact Information</h6>
                        <div class="ContactInformationBox border-0">
                          <span class="circle">JL</span>
                          <div class="ContactInformationName">
                            <h6>John Lead</h6>
                            <p>
                              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
                              <img
                                src={require("../../../../assets/images/location.png")}
                                class="CursorPointer FolderPermissionId ml-2"
                                data-toggle="modal"
                                data-target="#MapModal2"
                                data-dismiss="modal"
                                p=""
                              />
                            </p>
                            <p>
                              Phone: <span>(403)555-555</span>
                            </p>
                            <p>
                              Email: <span>John.lead@email.com</span>
                            </p>
                          </div>
                        </div>
                        <div class="AssignUnit">
                          <h4>Assigned Unit</h4>
                          <p>{state?.jobType} ({state?.jobNumber}))</p>
                        </div>
                      </div>
                      <aside>
                        <h5>Folder</h5>
                        <div>
                          <a
                            onClick={() => {
                              SetCreateFolderModal(true);
                              SetFileMangerModal(false);
                            }}
                            class="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#folderCreateModal2"
                            data-dismiss="modal"
                            title="Add Folder"
                          >
                            <img
                              src={require("../../../../assets/images/Plus.png")}
                            />
                          </a>
                        </div>
                      </aside>

                      <div class="Search">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          value={searchName}
                          onChange={(e) => setSearchName(e.target.value)}
                        />
                        <span>
                          <img
                            src={require("../../../../assets/images/search.png")}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="FolderList border-0">
                      <ul>
                        {folderLoader ? (
                          <td>
                            <Loader />
                          </td>
                        ) : (
                          <>
                            {searchName ? (
                              searchFolderList.length > 0 ? (
                                searchFolderList.map((item) => (
                                  <li
                                    key={item.id}
                                    style={{
                                      backgroundColor:
                                        folderIDD === item.id
                                          ? "green"
                                          : "transparent",
                                    }}
                                    onClick={(e) =>
                                      handleTagDocument(e, item.id)
                                    }
                                  >
                                    <span className="Icon">
                                      <img
                                        src="images/Folder.png"
                                        alt="Folder"
                                      />
                                    </span>
                                    <label>{item.folderName}</label>
                                    <a
                                      title="Cancel"
                                      onClick={() =>
                                        handleFolderDeleteShow(
                                          item.id,
                                          item.folderName
                                        )
                                      }
                                    >
                                      <img
                                        src={require("../../../../assets/images/Cross.png")}
                                        alt="Delete"
                                      />
                                    </a>
                                    {/* <a
                                      title="History"
                                      onClick={() =>
                                        handleFolderHistory(item.id)
                                      }
                                      className="ml-5 FolderPermissionId"
                                    >
                                      <img
                                        src={require("../../../../assets/images/timer.png")}
                                        alt="Timer"
                                      />
                                    </a> */}
                                  </li>
                                ))
                              ) : (
                                <p>No data found</p>
                              )
                            ) : (
                              allFolderList.map((item) => (
                                <li
                                  key={item.id}
                                  style={{
                                    backgroundColor:
                                      folderIDD === item.id
                                        ? "green"
                                        : "transparent",
                                  }}
                                  onClick={(e) => handleTagDocument(e, item.id)}
                                >
                                  <span className="Icon">
                                    <img src="images/Folder.png" alt="Folder" />
                                  </span>
                                  <label>{item.folderName}</label>
                                  <a
                                    title="Cancel"
                                    onClick={() =>
                                      handleFolderDeleteShow(
                                        item.id,
                                        item.folderName
                                      )
                                    }
                                  >
                                    <img
                                      src={require("../../../../assets/images/Cross.png")}
                                      alt="Delete"
                                    />
                                  </a>
                                  {/* <a
                                    title="History"
                                    onClick={() => handleFolderHistory(item.id)}
                                    className="ml-5 FolderPermissionId"
                                  >
                                    <img
                                      src={require("../../../../assets/images/timer.png")}
                                      alt="Timer"
                                    />
                                  </a> */}
                                </li>
                              ))
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="EventArea">
                    <h4>Pending File Events</h4>

                    <div className="TableList NewTableList">
                      {pendingLoader ? (
                        <td>
                          <Loader />
                        </td>
                      ) : (
                        <table>
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Modified by</th>
                              <th>Status</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pendingFileList?.pendingFileEvents?.length > 0 ? (
                              pendingFileList?.pendingFileEvents?.map(
                                (item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{item.fileName}</td>
                                      <td>{item.modifiedBy ?? "N/A"}</td>
                                      <td>{item.actionStatus ?? "N/A"}</td>
                                      <td>
                                        {moment(item.createdAt).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </td>
                                      <td>
                                        <div className="Actions">
                                          <a
                                            onClick={() =>
                                              handleViewDocumentationShow(
                                                item.folderId,
                                                item.id,
                                                item
                                              )
                                            }
                                          >
                                            <img src="images/view-po.png" />
                                          </a>
                                          <a href="javascript:void(0);">
                                            <img src="images/Action-1.png" />
                                          </a>
                                          <a
                                            onClick={() =>
                                              handleShareClientDocumentShow(
                                                item.folderId,
                                                item.id,
                                                item.name
                                              )
                                            }
                                          >
                                            <img src="images/Action-2.png" />
                                          </a>
                                          {/* <a
                                          onClick={() =>
                                            handleDocumentationDeleteShow(
                                              item.id
                                            )
                                          }
                                        >
                                          <img src="images/icon-30.png" />
                                        </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan="10">
                                  <p>No Data found.</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>

                  <div class="TodosButtons d-flex justify-content-between align-items-center mb-4">
                    <a
                      onClick={() => {
                        SetSendClientInfoModal(true);
                        SetFileMangerModal(false);
                      }}
                      class="Create FolderPermissionId"
                      data-toggle="modal"
                      data-target="#SendClientInformation2"
                      data-dismiss="modal"
                    >
                      <img
                        src={require("../../../../assets/images/send_clinet.png")}
                      />
                      Send Client Information<span>3</span>
                    </a>

                    <a
                      onClick={() => {
                        SetManageSignatureModal(true);
                        SetFileMangerModal(false);
                      }}
                      class="ml-0 FolderPermissionId"
                      data-toggle="modal"
                      data-target="#ManageSignatures2"
                      data-dismiss="modal"
                    >
                      <img
                        src={require("../../../../assets/images/white_manage_signatures.png")}
                      />{" "}
                      Manage Signatures<span>3</span>
                    </a>
                    <div class="FilesArea d-inline-block">
                      <div class="FilesHead mb-0">
                        <div class="FilesLeft FilesLeftSalesdoocument">
                          <aside>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search"
                            />
                            <span>
                              <img
                                src={require("../../../../assets/images/search.png")}
                              />
                            </span>
                          </aside>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="FilesArea">
                    <div class="FilesHead">
                      <div class="FilesLeft">
                        <h4>Files</h4>
                      </div>
                      <div class="FilesRight">
                        <a
                          title="Release File"
                          class="FolderPermissionId"
                          onClick={() => handleRelaseTemplateModalShow()}
                        >
                          <img
                            src={require("../../../../assets/images/release-file.png")}
                          />
                        </a>
                        {/* <a
                          onClick={() => {
                            SetUploadFileModal(true);
                            SetFileMangerModal(false);
                          }}
                          title="Upload Files"
                          class="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#UploadFiles"
                          data-dismiss="modal"
                        >
                          <img
                            src={require("../../../../assets/images/Icon-6.png")}
                          />
                        </a> */}
                        <a
                          onClick={() => {
                            SetCreateFileModal(true);

                            SetFileMangerModal(false);
                          }}
                          title="Create New File"
                          class="FolderPermissionId"
                          data-toggle="modal"
                          data-target="#CreateNewFileTransaction"
                          data-dismiss="modal"
                        >
                          <img
                            src={require("../../../../assets/images/document-support.png")}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {loader ? (
                      <td>
                        <Loader />
                      </td>
                    ) : (
                      <>
                        {searchFolderName
                          ? searchDocumentationList?.length > 0
                            ? searchDocumentationList?.map((data, i) => {
                                return (
                                  <div className="col-sm-4">
                                    <div className="FilesBox">
                                      <figure>
                                        <img src={data.docUrl} />
                                      </figure>
                                      <figcaption>
                                        <div>
                                          <h4>{data.name}</h4>
                                          <p>
                                            Added:{" "}
                                            {moment(data.createdAt).format(
                                              "YYYY-MM-DD"
                                            )}
                                          </p>
                                        </div>
                                        <ul>
                                          <li className="dropdown">
                                            <a
                                              className="dropdown-toggle"
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img src="images/dots.png" />
                                            </a>

                                            <ol className="dropdown-menu dropdown-menuwidth">
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#ViewFile"
                                                >
                                                  <img src="images/document-view.png" />
                                                  View
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#EditFileDesktop"
                                                >
                                                  <img src="images/document-edit.png" />
                                                  Edit
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#InsertSignature"
                                                >
                                                  <img
                                                    src={require("../../../../assets/images/insert_signature.png")}
                                                  />
                                                  Insert Signature
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#ApproveFile"
                                                >
                                                  <img
                                                    src={require("../../../../assets/images/approve_icon.png")}
                                                  />
                                                  Approve
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#SendForApproval"
                                                >
                                                  <img
                                                    src={require("../../../../assets/images/send_approval.png")}
                                                  />
                                                  Send for approvel
                                                </a>
                                              </li>
                                              <li>
                                                <a href="javascript:void(0);">
                                                  <img src="images/download.png" />
                                                  Download File
                                                </a>
                                              </li>
                                              <li>
                                                <a
                                                  href="javascript:void(0);"
                                                  data-toggle="modal"
                                                  data-target="#ShareDocument"
                                                >
                                                  <img src="images/Action-2.png" />
                                                  Share documents
                                                </a>
                                              </li>
                                            </ol>
                                          </li>
                                          <li>
                                            <a
                                              onClick={() =>
                                                handleFolderHistory(data.id)
                                              }
                                            >
                                              <img src="images/timer.png" />
                                            </a>
                                          </li>
                                        </ul>
                                      </figcaption>
                                    </div>
                                  </div>
                                );
                              })
                            : "No Documentation Found"
                          : documentationList?.length > 0
                          ? documentationList?.map((data, i) => {
                              return (
                                <div className="col-sm-4">
                                  <div className="FilesBox">
                                    {/* <figure>
                                      <img src={data.docUrl} />
                                    </figure> */}
                                    <figcaption>
                                      <div>
                                        <h4>{data.name}</h4>
                                        <p>
                                          Added:{" "}
                                          {moment(data.createdAt).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </p>
                                      </div>
                                      <ul>
                                        <li className="dropdown">
                                          <a
                                            className="dropdown-toggle"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <img src="images/dots.png" />
                                          </a>

                                          <ol className="dropdown-menu dropdown-menuwidth">
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleEditDocumentationShow(
                                                    data
                                                  )
                                                }
                                              >
                                                <img src="images/document-edit.png" />
                                                Edit Document
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleViewDocumentationShow(
                                                    data.folderId,
                                                    data.id,
                                                    data
                                                  )
                                                }
                                              >
                                                <img src="images/document-view.png" />
                                                View Document
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="FolderPermissionId"
                                                onClick={() =>
                                                  handleSendForClientModalShow(
                                                    data
                                                  )
                                                }
                                              >
                                                <img src="images/sign_document.png" />
                                                Sent for Client Signature
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleSendForClientModalShow(
                                                    data
                                                  )
                                                }
                                                class="FolderPermissionId"
                                              >
                                                <img src="images/upload_new.png" />
                                                Upload Signed documents
                                              </a>
                                            </li>

                                            <li>
                                              <a
                                                href={data.docUrl}
                                                target="_blank"
                                                title="ImageName"
                                              >
                                                <img src="images/download.png" />
                                                Download document
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                onClick={() =>
                                                  handleShareClientDocumentShow(
                                                    data.folderId,
                                                    data.id,
                                                    data.name
                                                  )
                                                }
                                              >
                                                <img src="images/Action-2.png" />
                                                Share documents
                                              </a>
                                            </li>
                                          </ol>
                                        </li>
                                        <li>
                                          <a
                                            onClick={() =>
                                              handleFilesHistoryModalShow(
                                                data.folderId,
                                                data.id,
                                                data.name
                                              )
                                            }
                                          >
                                            <img src="images/timer.png" />
                                          </a>
                                        </li>
                                      </ul>
                                    </figcaption>
                                  </div>
                                </div>
                              );
                            })
                          : "No Documentation Found"}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        </div>
        </div>
      </Modal>
      <Modal
        show={FolderDeleteModal}
        onHide={handleFolderDeleteClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleFolderDeleteClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button className="Create" onClick={handleFolderDelete}>
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleFolderDeleteClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ViewDocumentationModal}
        onHide={handleViewDocumentationClose}
        className="ModalBox LargeModal GreyModalContent"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleViewDocumentationClose}>
                ×
              </a>
              <div>
                <div className="ModalTitleBox">
                  <h4>View File</h4>
                </div>
                {viewDocumentData ? (
                  <div dangerouslySetInnerHTML={{ __html: viewDocumentData }} />
                ) : (
                  <p>Loading document...</p>
                )}
              </div>
              <div className="FolderForm">
                <div className="Button d-flex justify-content-between m-3">
                  <button
                    className="Outline"
                    onClick={handleViewDocumentationClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShareClientDocumentationModal}
        onHide={handleShareClientDocumentClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleShareClientDocumentClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Share Document</h4>
                </div>
                <div className="FolderForm">
                  <div className="ModalBigheading">
                    <h3>
                      <span>Documents:</span>
                      {fileNames ?? "N/A"}
                    </h3>
                  </div>
                  <div className="ModalFilterAreaBox mb-3">
                    <h4 className="mb-4">Select Recipient</h4>
                    <div className="RadioArea d-flex  flex-column">
                      <div className="form-group">
                        <label className="Radio">
                          Select Recipient
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="text"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group mb-4">
                        <label className="Radio">
                          Other Contact
                          <input
                            type="radio"
                            name="Cuisine"
                            defaultValue="file"
                            defaultChecked=""
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group"></div>
                  </div>
                  <div
                    className="file RadioText mb-5"
                    style={{ display: "block" }}
                  >
                    <div className="form-group">
                      <h6>Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert name"
                        name="clientName"
                        value={clientName}
                        onChange={handleInputChange}
                      />
                      {/* <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span> */}
                    </div>
                    <div className="form-group">
                      <h6>Email</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Insert Email"
                        name="clientEmail"
                        value={clientEmail}
                        onChange={handleInputChange}
                      />
                      {/* <span style={{ color: "red" }}>
                          {errors?.cliEmailError}
                        </span> */}
                    </div>

                    <div className="Button" onClick={handleAddRecipient}>
                      <button
                        className="Create"
                        disabled={clientEmail == "" && clientName == ""}
                      >
                        Add Recipient
                      </button>
                    </div>
                  </div>
                  <h6>Recipients</h6>
                  <div className="FilesAddedTable">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {emails?.length > 0 &&
                          emails?.map((item, ind) => {
                            return (
                              <tr>
                                <td>
                                  <div className="FilesAddedName">
                                    <p>{item.name}</p>
                                    <span>
                                      <img
                                        src="images/Cross.png"
                                        onClick={() =>
                                          handleRemoveRecipient(ind)
                                        }
                                      />
                                    </span>
                                  </div>
                                </td>
                                <td>{item.email}</td>
                              </tr>
                            );
                          })}
                        <span style={{ color: "red" }}>
                          {errors?.cliNameError}
                        </span>
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group">
                    <h6>Message</h6>
                    <textarea
                      type="text"
                      className="form-control"
                      rows={4}
                      placeholder="Include message (optional)"
                      name="emailBody"
                      value={emailBody}
                      onChange={handleInputChange}
                    />
                    <span style={{ color: "red" }}>{errors?.eBodyError}</span>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create mr-3"
                      onClick={handleShareDocumentSubmit}
                    >
                      Share Document
                    </button>
                    <button
                      className="Outline ml-3"
                      onClick={handleShareClientDocumentClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={RelaseTemplateModal}
        onHide={handleRelaseTemplateModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleRelaseTemplateModalClose}
              >
                ×
              </a>
              <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Release Files</h4>
            </div>
            <div className="JobTitleArea">
              <h6>Construction File Manager</h6>
              <div className="FolderForm">
                <div className="form-group mb-4">
                  <label className="Radio">
                    {" "}
                    Sales
                    <input
                      type="radio"
                      name="designFiles"
                      value="Sales"
                      checked={selectedOption === "Sales"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Client View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Client View"
                      checked={selectedOption === "Client View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Trade View
                    <input
                      type="radio"
                      name="designFiles"
                      value="Trade View"
                      checked={selectedOption === "Trade View"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    {" "}
                    Estimation
                    <input
                      type="radio"
                      name="designFiles"
                      value="Estimation"
                      checked={selectedOption === "Estimation"}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>

                {selectedOption === "Trade View" && (
                  <div
                    className="TradeView RadioText"
                    style={{ display: "block" }}
                  >
                    <div className="form-group">
                      <h6>Vendor</h6>
                      <select
                        className="form-control"
                        name="VendorId"
                        value={VendorId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Vendor</option>
                        {vendorList?.details?.map((data, ind) => {
                          return (
                            <option key={ind} value={data.id}>
                              {data.vendorName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <h6>File Manager Folder</h6>
                  <select
                    className="form-control"
                    name="folderId"
                    // value={folderId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a Folder</option>
                    {allFolderList?.length > 0 &&
                      allFolderList?.map((item, i) => {
                        return (
                          <option
                            value={item.id}
                            key={i}
                            selected={item.id == folderId}
                          >
                            {" "}
                            {item.folderName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {folderId != null && (
                  <div className="FileCheckBoxTable">
                    <table>
                      <tbody>
                        {folderData?.length > 0 &&
                          folderData?.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>
                                  <label className="CheckBox">
                                    <input
                                      type="checkbox"
                                      checked={selectedItems.includes(item)}
                                      onChange={(e) =>
                                        handleFolderCheckboxChange(e, item)
                                      }
                                    />
                                    <span className="checkmark" />
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="Button d-flex justify-content-between mt-5">
                  <button className="Create" onClick={handleReleaseTemplate}>
                    Release Folder Files
                  </button>
                  <button
                    className="Outline"
                    onClick={handleRelaseTemplateModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SendForClientModal}
        onHide={handleSendForClientModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendForClientModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Send Document for Client Signature</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>Template</h6>
                    <p className="ml-0">Construction Contract</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <h6>File Id</h6>
                    <p className="ml-0">F1</p>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="ModalMediumheading">
                    <h3>
                      <span>Client Signees</span>
                    </h3>
                  </div>
                  <div className="form-group">
                    <h6>Number of Signee</h6>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Insert Number of Signees"
                      name="numberOfSignee"
                      value={numberOfSignee}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Select Primary Client Signee</h6>
                    <div class="CommonSelectBox w-100">
                      <select
                        name="primarySignee"
                        value={primarySignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {primayClient?.length > 0 &&
                          primayClient?.map((item, i) => {
                            return (
                              <option value={item.id} key={i}>
                                {item.legalName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {numberOfSignee >= 2 && (
                    <div className="form-group">
                      <h6>Select Secondary Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="secondarySignee"
                          value={secondarySignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  {numberOfSignee == 3 && (
                    <div className="form-group">
                      <h6>Select Additional Client Signee</h6>
                      <div class="CommonSelectBox w-100">
                        <select
                          name="additionalSignee"
                          value={additionalSignee}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {secondaryClient?.length > 0 &&
                            secondaryClient?.map((item, i) => {
                              return (
                                <option value={item.id}>
                                  {item.legalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <h6>Select Builder Signee</h6>
                    {/* <p className="ml-0">{iBuildLocalData?.user?.id}</p> */}
                    <div class="CommonSelectBox w-100">
                      <select
                        name="builderSignee"
                        // value={builderSignee}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {builderData?.length > 0 &&
                          builderData?.map((item, i) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Button d-flex justify-content-between mt-3">
                <button
                  className="Create FolderPermissionId"
                  onClick={handleSendForClient}
                >
                  Generate Document
                </button>
                <button
                  className="Outline"
                  onClick={handleSendForClientModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={SendforClientSignatureModal}
        onHide={handleSendforClientSignatureModalClose}
        className="ModalBox modal fade LargeModal ExtraLargeModalGreen show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleSendforClientSignatureModalClose}
          >
            ×
          </a>
          <div class="ModalTitleBox">
            <h4>Send for Client Signature</h4>
          </div>
          <div className="FolderForm">
            <div className="AgreementViewArea mt-5">
              <div className="AgreementViewTop">
                <div className="AgreementViewTopRight">
                  <p
                    dangerouslySetInnerHTML={createAboutHeader()}
                    className="editor"
                  ></p>
                </div>
              </div>

              <div className="ChangeOrderCreatArea mt-5">
                {primarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("primarySignature")
                            }
                          >
                            {primarySignature && <img src={primarySignature} />}
                            {!primarySignature && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{primarySignStatus}</label>
                          <label>Primary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {secondarySignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("secondarySignature")
                            }
                          >
                            {secondarySignature && (
                              <>
                                <img src={secondarySignature} />
                              </>
                            )}
                            {!secondarySignature && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{secondarySignStatus}</label>
                          <label>Secondary Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {additionalSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("additionalSignature")
                            }
                          >
                            {additionalSignature && (
                              <>
                                <img src={additionalSignature} />
                              </>
                            )}
                            {additionalSignature == null && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{additionalSignStatus}</label>
                          <label>Additional Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {builderSignStatus && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="date" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ml-auto">
                      <div className="InputBox">
                        <div className="form-group">
                          <div
                            className="InsertSignature"
                            onClick={() =>
                              handleManageSignatuesShow("builderSignature")
                            }
                          >
                            {builderSignature && (
                              <>
                                <img src={builderSignature} />
                              </>
                            )}
                            {!builderSignature && (
                              <a>
                                <img src="images/insert_signature.png" />
                                Insert Signature
                              </a>
                            )}
                          </div>
                          <label>{builderSignStatus}</label>
                          <label>Builder Signee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Button d-flex justify-content-between">
              <button
                className="Create"
                onClick={() => handleSendToClient("save")}
              >
                Save Draft
              </button>
              <button
                className="Create"
                onClick={() => handleSendToClient("send")}
              >
                Save &amp; Send to Client
              </button>
              <button
                className="Outline"
                onClick={handleSendforClientSignatureModalClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ManageSignatures}
        onHide={handleManageSignatuesClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a className="CloseModal" onClick={handleManageSignatuesClose}>
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              <div className="form-group">
                <div className="CommonModalArea">
                  <h5>Add new signature</h5>
                </div>
              </div>
              <div className="form-group">
                <div className="CommonModalArea border p-2">
                  <h5>Signatures</h5>
                  <div className="CommonModalBox">
                    <SignaturePad
                      ref={signCanvas}
                      penColor="green"
                      canvasProps={{
                        width: 400,
                        height: 300,
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        updateState({
                          ...iState,
                          signature: signCanvas?.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png"),
                        })
                      }
                    />
                    <a onClick={clearSignature}>
                      <img src="images/circle_minus.png" />
                    </a>
                    {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                </div>
              </div>

              <div className="Button d-flex justify-content-between">
                <button className="Create" onClick={addSignature}>
                  Save
                </button>
                <button
                  className="Outline"
                  onClick={handleManageSignatuesClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={EditDocumentationModal}
        onHide={handleEditDocumentationClose}
        className="ModalBox modal fade ExtraLargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleEditDocumentationClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Edit File</h4>
                </div>
                <div className="FolderForm">
                  <div className="EditOption">
                    <div className="EditOptionBox">
                      <h6>Name</h6>
                      <a href="javascript:void(0);">
                        {contentData?.name ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>
                          <img src="images/fileview.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Folder</h6>
                      <a href="javascript:void(0);">
                        {contentData?.fileName ?? "N/A"}
                        &nbsp;&nbsp;
                        <span>{/* <img src="images/refresh.png" /> */}</span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Draft</h6>
                      <a href="javascript:void(0);">
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Use Company Header</h6>
                      <a
                        className="FolderPermissionId"
                        onClick={() => handlHeaderPreviewModalShow()}
                      >
                        <span>
                          <img src="images/company_header.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Download</h6>
                      <a href={contentData?.docUrl} target="_blank">
                        <span>
                          <img src="images/download.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Signature</h6>
                      <a
                        href="javascript:void(0);"
                        data-toggle="modal"
                        data-target="#InsertSignatureSmall5"
                        data-dismiss="modal"
                      >
                        <span>
                          <img src="images/signature.png" />
                        </span>
                      </a>
                    </div>
                    <div className="EditOptionBox">
                      <h6>Insert Keys</h6>
                      <a
                        className="FolderpermissionId"
                        onClick={() => handleDocumentKeyModalShow()}
                      >
                        <span>
                          <img src="images/insert_keys.png" />
                        </span>
                      </a>
                    </div>
                  </div>

                  {content ? (
                    <div className="mce-pagebreak">
                      <Editor
                        apiKey="rye4mz748ekd8ke0loyu8j7gq7iu1tkxs3nticj32j848g6r" // Use your premium API key
                        onInit={(evt, editor) => (editorsRef.current = editor)}
                        value={content ?? ""}
                        init={{
                          height: 500,
                          menubar:
                            "file edit view insert format tools table help",
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "print",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "paste",
                            "help",
                            "wordcount",
                            "lists",
                            "table",
                            "pagebreak",
                            "spellchecker",
                            "powerpaste",
                            "mediaembed",
                            "advcode",
                            "emoticons",
                            "codesample",
                            "advtable",
                            "visualchars",
                            "visualblocks",
                            "linkchecker",
                            "checklist",
                            "media",
                            "tableofcontents",
                            "a11ychecker",
                            "permanentpen",
                            "casechange",
                            "pageembed",
                            "nonbreaking",
                            "quickbars",
                            "advlist",
                            "autosave",
                            "autolink",
                            "autoresize",
                          ],
                          toolbar:
                            "undo redo | styles| bold italic underline strikethrough | fontfamily fontsize blocks | " +
                            "alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | " +
                            "forecolor backcolor casechange permanentpen formatpainter removeformat | " +
                            "pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | " +
                            "a11ycheck ltr rtl | showcomments addcomment",
                          toolbar_sticky: true,
                          autosave_ask_before_unload: true,
                          autosave_interval: "30s",
                          autosave_prefix: "{path}{query}-{id}-",
                          autosave_restore_when_empty: true,
                          autosave_retention: "2m",
                          image_advtab: true,
                          content_css: "default",
                          importcss_append: true,
                          image_title: true,
                          automatic_uploads: true,
                          powerpaste_word_import: "clean", // options: 'clean', 'merge', 'prompt'
                          powerpaste_html_import: "merge", // options: 'merge', 'clean', 'prompt'
                          forced_root_block: "", // allows for line breaks instead of <p>
                          force_br_newlines: true,
                          force_p_newlines: false,
                          content_style: `
          .mce-item-table:not([border]), .mce-item-table:not([border]) caption, 
          .mce-item-table:not([border]) td, .mce-item-table:not([border]) th, 
          .mce-item-table[border="0"], .mce-item-table[border="0"] caption, 
          .mce-item-table[border="0"] td, .mce-item-table[border="0"] th, 
          table[style*="border-width: 0px"], table[style*="border-width: 0px"] caption, 
          table[style*="border-width: 0px"] td, table[style*="border-width: 0px"] th { 
            border: 0;
          }
        `,
                          setup: (editor) => {
                            editor.on("drop", (e) => {
                              e.preventDefault();
                              e.stopPropagation();

                              if (e.dataTransfer && e.dataTransfer.files) {
                                const files = e.dataTransfer.files;
                                for (const file of files) {
                                  const reader = new FileReader();
                                  reader.onload = (event) => {
                                    editor.insertContent(
                                      `<img src="${event.target.result}" alt="${file.name}"/>`
                                    );
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }
                            });
                          },
                          link_list: [
                            {
                              title: "My page 1",
                              value: "https://www.tinymce.com",
                            },
                            {
                              title: "My page 2",
                              value: "http://www.moxiecode.com",
                            },
                          ],
                          image_list: [
                            {
                              title: "My image 1",
                              value:
                                "https://www.tiny.cloud/images/glyph-tinymce.svg",
                            },
                            {
                              title: "My image 2",
                              value:
                                "https://www.tiny.cloud/docs/images/glyph-tinymce.svg",
                            },
                          ],
                          image_class_list: [
                            { title: "None", value: "" },
                            { title: "Some class", value: "some-class" },
                          ],
                          importcss_file_filter: "main.css",
                          templates: [
                            {
                              title: "New Table",
                              description: "creates a new table",
                              content:
                                "<table><tr><td>Sample Text</td></tr></table>",
                            },
                            {
                              title: "Starting my story",
                              description: "A sample document",
                              content: "Once upon a time...",
                            },
                            {
                              title: "New list with dates",
                              description: "New List with Dates",
                              content:
                                "<ul><li>12 December</li><li>13 December</li></ul>",
                            },
                          ],
                          template_cdate_format: "[CDATE: %m/%d/%Y : %H:%M:%S]",
                          template_mdate_format: "[MDATE: %m/%d/%Y : %H:%M:%S]",
                          height: 600,
                          image_caption: true,
                          quickbars_selection_toolbar:
                            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                          noneditable_class: "mceNonEditable",
                          toolbar_mode: "sliding",
                          spellchecker_dialog: true,
                          spellchecker_whitelist: ["Ephox", "Moxiecode"],
                          tinycomments_mode: "embedded",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          a11y_advanced_options: true,
                          branding: false, // Remove TinyMCE branding
                          powerpaste_word_import: "clean", // Configure PowerPaste
                          powerpaste_html_import: "merge",
                          powerpaste_allow_local_images: true,
                          media_live_embeds: true, // Enable live media embeds
                          forced_root_block: "",
                          force_br_newlines: true,
                          force_p_newlines: false,
                        }}
                        onEditorChange={handleEditorChange}
                      />
                    </div>
                  ) : (
                    <p>Loading document...</p>
                  )}
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create mt-5"
                      onClick={handleEditDocumentation}
                    >
                      Save File
                    </button>
                    <button
                      className="Create mt-5"
                      onClick={handleEditDocumentationClose}
                    >
                      Cancel &amp; Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={HeaderPreviewModal}
        onHide={handlHeaderPreviewModalClose}
        className="ModalBox modal fade LargeModal GreyModalContent show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlHeaderPreviewModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Header Preview</h4>
            </div>

            <div className="FolderForm">
              <div className="AgreementViewArea">
                <div className="AgreementViewTop">
                  {/* <a href="javscript:void(0)">
                    <img src="images/modal-big-logo.png" />
                  </a> */}
                  <div className="AgreementViewTopRight">
                    <p
                      dangerouslySetInnerHTML={createAboutHeader()}
                      className="editor"
                    ></p>
                  </div>
                </div>
              </div>

              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={() => handleApply()}>
                  Apply
                </button>
                <button
                  className="Outline"
                  onClick={handlHeaderPreviewModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <CreateFolder
        CreateFolderModal={CreateFolderModal}
        SetFileMangerModal={SetFileMangerModal}
        SetCreateFolderModal={SetCreateFolderModal}
        state={state}
      />

      <CreateFile
        CreateFileModal={CreateFileModal}
        SetFileMangerModal={SetFileMangerModal}
        SetCreateFileModal={SetCreateFileModal}
      />
      <UploadFile
        UploadFileModal={UploadFileModal}
        SetFileMangerModal={SetFileMangerModal}
        SetUploadFileModal={SetUploadFileModal}
      />

      <ManageSignature
        SetFileMangerModal={SetFileMangerModal}
        ManageSignatureModal={ManageSignatureModal}
        SetManageSignatureModal={SetManageSignatureModal}
      />

      <SendClientInfo
        SetFileMangerModal={SetFileMangerModal}
        SendClientInfoModal={SendClientInfoModal}
        SetSendClientInfoModal={SetSendClientInfoModal}
      />

      <RealseFiles
        SetFileMangerModal={SetFileMangerModal}
        RealseFilesModal={RealseFilesModal}
        SetRealseFilesModal={SetRealseFilesModal}
      />
      <FolderHistory
        folderHistoryMod={folderHistoryMod}
        folderHistoryList={folderHistoryList}
        SetFileMangerModal={SetFileMangerModal}
        setFolderHistoryMod={setFolderHistoryMod}
        filesName={filesName}
      />
    </>
  );
};

export default FileManger;
