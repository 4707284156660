import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  addTodoTradeView,
  getChangeOrderCheckList,
  getChangeOrderUploadFiles,
  getJobByProjectList,
  getListCheckListChangeOrder,
  getProjectByBuilder,
  getTodoVendorList,
  getTradeListJob,
  getTradeTaskMemberList,
  getTradeVendorList,
} from "../redux/action/tradeViewAuthAction";
import Loader from "./Loader";
import moment from "moment";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";
import { calculateDateDifference, convertToISOFormat } from "../utils/uploadFile";
import { Link } from "react-router-dom";

const initialState = {
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  id: "",
  SupportingDocumentsListModal: false,
  companyId: "",
  projectId: "",
  jobId: "",
  ChangeOrderCheckListModal: false,
  changeOrderCheckListData: [],

  AddTodoModal: false,
  JobId: "",
  JobType: "",
  jobSearch: "",
  scheRelatedToDo: true,
  description:"",
  targetDate:"",
  AssignStaffMemberModal:false,
  assignStaffs:"",
  day:"",
  week:"",
  ReminderModal:false,
  TradeModal:false,
  VendorId:"",
  contact:"",
  email:"",
  message:"",
  AddNotesModal:false,
  supportingDocFiles:null,
  noteType:"",
  messages:"",
};

const Change_Order_Checklist_Tradeview = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    AddNewSupportingDocsModal,
    supportingDocFiless,
    id,
    SupportingDocumentsListModal,
    projectId,
    jobId,
    ChangeOrderCheckListModal,
    changeOrderCheckListData,

    AddTodoModal,
    JobType,
    JobId,
    jobSearch,
    scheRelatedToDo,
    description,
  targetDate,
  AssignStaffMemberModal,
  assignStaffs,
  week,
  day,
  ReminderModal,
  TradeModal,
  VendorId,
  contact,
  email,
  message,
  AddNotesModal,
  supportingDocFiles,
  noteType,
  messages,
  } = iState;
  const {
    changeOrderLoader,
    changeOrderCheckList,
    projectByBuilderData,
    jobByProjectData,

    jobList,
    membersList,
    vendorList,
  } = useSelector((state) => state.tradeViewLogin);
  console.log("vendorList", vendorList);

  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  const tradeData = JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
    dispatch(getTradeListJob(builderIdd));
    dispatch(getTradeTaskMemberList(builderIdd));
    dispatch(getTodoVendorList(builderIdd));

  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      const selectedJob = jobByProjectData?.data?.find(
        (data) => data.id == value
      );
      if (selectedJob) {
        const { id } = selectedJob;
        dispatch(getChangeOrderCheckList(builderIdd, projectId, id));
      }
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "notes") {
      updateState({ ...iState, supportingDocFiles: file });
    }

  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiless: "",
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      files: supportingDocFiless,
    };
    dispatch(getChangeOrderUploadFiles(dataa)).then((res) => {
      if (res.status === 200) {
        // dispatch(getConstructScheduleList(compId?.user?.id));
        // dispatch(getConstructionSupportDocsList(docsId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: false,
          id: "",
          supportingDocFiless: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewSupportingDocsModal: true,
        });
      }
    });
  };

  const handleSupportingDocumentsListModalShow = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
    });
  };

  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleChangeOrderCheckListModalShow = () => {
    updateState({
      ...iState,
      ChangeOrderCheckListModal: true,
    });
  };

  useEffect(() => {
    if (ChangeOrderCheckListModal) {
      dispatch(getListCheckListChangeOrder(builderIdd, projectId, jobId)).then(
        (res) => {
          updateState({ ...iState, changeOrderCheckListData: res?.data?.data });
        }
      );
    }
  }, [ChangeOrderCheckListModal]);

  const handleChangeOrderCheckListModalClose = () => {
    updateState({
      ...iState,
      ChangeOrderCheckListModal: false,
    });
  };

  const handleAddTodoModalShow = () => {
    updateState({
      ...iState,
      AddTodoModal: true,
      ChangeOrderCheckListModal: false,
    });
  };

  const handleAddTodoModalClose = () => {
    updateState({
      ...iState,
      AddTodoModal: false,
      ChangeOrderCheckListModal: true,
    });
  };

  const handleJobIternaryItemClick = (item) => {
    updateState({ ...iState, JobId: item.id, JobType: item.jobType });
  };

  // console.log("JobType",JobType,JobId);
  const handleCheckbox = (e) => {
    const value = e.target.value === "true"; // Convert string value to boolean
    updateState({ ...iState, scheRelatedToDo: value });
  };

  const handleAssignStaffMemberShow = () => {
    updateState({
      ...iState,
      AddTodoModal: false,
      AssignStaffMemberModal:true,
    });
  };

  const handleAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      AddTodoModal: true,
      AssignStaffMemberModal: false,
    });
  };

  const handleAssign = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: false,
      AddTodoModal: true,
    });
  };

  const handleReminderShow = () => {
    updateState({
      ...iState,
      ReminderModal: true,
      AddTodoModal: false,
    });
  };

  const handleReminderClose = () => {
    updateState({
      ...iState,
      ReminderModal: false,
      AddTodoModal: true,
    });
  };

  const handleReminder = () => {
    updateState({
      ...iState,
      ReminderModal: false,
      day: day,
      week: week,
      AddTodoModal: true,
    });
  };

  const handleTradeModalShow = () => {
    updateState({
      ...iState,
      TradeModal: true,
      AddTodoModal: false,
    });
  };

  const handleTradeModalClose = () => {
    updateState({
      ...iState,
      TradeModal: false,
      AddTodoModal: true,
    });
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };


  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };
  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const addTodoFunc = () => {
    const type = week ? week : day;
    let dataa = {
      toDoNotes: {
        noteType,
        message:messages,
      },
      assignTrade: {
        message,
        VendorId,
        contact,
        email,
      },
      JobId: +JobId,
      JobType:JobType,
      scheRelatedToDo: true,
      TaskId:1,
      description,
      targetDate: convertToISOFormat(targetDate),
      assignStaffs: [{ assignStaffs }],
       supportingDocFiles,
      reminderDate: calculateDateDifference(type),
    };
    dispatch(addTodoTradeView(builderIdd,tradeData?.user?.id,dataa)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddTodoModal: false,
          
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddTodoModal: true,
        });
      }
    });
  };
  return (
    <div>
      <>
        {/* <Header /> */}
        <TradeHeader />
        <TradeViewSidenav />
        <div className="WrapperAreaClient">
          <div className="WrapperBoxClient">
            <div className="MainTitleBox">
              <div className="MainTitle">
                <h2>Change Order Checklist</h2>
              </div>
            </div>
            <div className="TitleBox TitleBoxWidth d-block">
                <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="BuilderBox">
                <h4>Project</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="projectId"
                    value={projectId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    {projectByBuilderData?.data?.length > 0 &&
                      projectByBuilderData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
                </div>
                </div>

              <div className="col-lg-3 col-md-4 col-sm-6 ml-auto">
              <div className="BuilderBox">
                <h4>Job</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="jobId"
                    value={jobId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Jobs</option>
                    {jobByProjectData?.data?.length > 0 &&
                      jobByProjectData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.jobType}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              </div>
                </div>
            </div>
            <div className="TableHeadingArea mt-5">
              <div className="FilesHead">
                <div className="FilesLeft">
                  <h4>Change Orders</h4>
                </div>
                <div className="NewActions">
                  <a  title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                  <a title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  <a title="settings">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="TableList TableListLarge CommonHeading">
              <table>
                <thead>
                  <tr>
                    <th>Job id</th>
                    <th>Change Order ID</th>
                    <th>Description</th>
                    <th>Address</th>
                    <th>Account Description</th>
                    <th>Date Created</th>
                    <th>Date Schedule</th>
                    <th>Vendor</th>
                    <th>Client</th>
                    <th>Approve</th>
                    <th>Date Approved</th>
                    <th>Status</th>
                    <th>Date Completed</th>
                    <th>Completed By</th>
                    <th>Supporting Documents</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!changeOrderLoader ? (
                    changeOrderCheckList?.data?.length > 0 ? (
                      changeOrderCheckList?.data?.map((data, ind) => {
                        return (
                          <tr className="BorderCyan" key={ind}>
                            <td>{data.id}</td>
                            <td>{data.jobId}</td>
                            <td>{data.description}</td>
                            <td>
                              <div>
                              <img src="images/location.png" />{" "}
                                {data.jobAddress}
                             
                              </div>
                            </td>
                            <td>{data.clientAcknowledgementContract}</td>
                            <td>
                              {moment(data.createdAt).format(
                                "YYYY-MM-DD & HH:MM A"
                              )}
                            </td>
                            <td>{moment(data.possessionDate).format(
                                "YYYY-MM-DD & HH:MM A"
                              )}</td>
                            <td>{data.vendor??"N/A"}</td>
                            <td>{data.clientName}</td>
                            <td>{data.approved===true?"Yes":"No"}</td>
                            <td>{data.approvedDate!==null?
                            moment(data.approvedDate).format(
                              "YYYY-MM-DD & HH:MM A"
                            ):"N/A"
                            }</td>
                            <td>{data.todoStatus}</td>
                            <td>{
                            data.dateCompleted!=null?
                            moment(data.possessionDate).format(
                              "YYYY-MM-DD & HH:MM A"
                            ):"N/A"
                            }</td>
                             <td>{data.vendor??"N/A"}</td>
                            <td>
                              <div className="text-center">
                                <a
                                  onClick={() =>
                                    handleAddNewSupportingDocsModalShow()
                                  }
                                >
                                  <img src="images/notebook.png" />
                                </a>
                                <span
                                  className="Count"
                                  onClick={() =>
                                    handleSupportingDocumentsListModalShow()
                                  }
                                >
                                  3
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu Smalldropdown-menu">
                                      <li>
                                        <a
                                          onClick={() =>
                                            handleChangeOrderCheckListModalShow()
                                          }
                                        >
                                          <img src="images/access_po.png" />
                                          Item Change Order
                                        </a>
                                      </li>
                                      <li>
                                        <Link to="/edit-change-order-tradeview" state={data}>
                                          <img src="images/access_po.png" />
                                          Edit Change Order
                                        </Link>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          <p>No Data found.</p>
                        </td>
                      </tr>
                    )
                  ) : (
                    <Loader />
                  )}
                </tbody>
              </table>
            </div>
            <div className="PaginationArea">
              <h6>Showing 1 to 10 of 27 entries</h6>
              <h6>
                <img src="images/leftarrow.png" /> 01{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
            <div className="DiscussionArea">
              <div className="DiscussionTitle">
                <h4>Discussion</h4>
                <div className="DiscussionChat">
                  <img src="images/down.png" className="DiscussionIcon" />
                  <a href="#">
                    <img src="images/pdf2.png" />
                  </a>
                </div>
              </div>
              <div className="DiscussionHideArea" style={{ display: "block" }}>
                <div className="DiscussionBox">
                  <span>FM</span>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add a Comment ....."
                    />
                    <div className="DiscussionOverlay">
                      <a href="" className="CancelChat">
                        <img src="images/plus-cancel1.png" />
                      </a>
                      <a href="" className="SendChat">
                        <img src="images/forwardbutton.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="DiscussionBox">
                  <span>TH</span>
                  <div className="DiscussionRight">
                    <h6>Inspections are delayed</h6>
                    <small>04/19/2023, at 02:47 pm</small>
                  </div>
                </div>
                <div className="ReplyArea">
                  <a href="javascript:void(0);">Reply</a>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                  <div className="DiscussionBox">
                    <span>TH</span>
                    <div className="DiscussionRight">
                      <h6>Inspections are delayed</h6>
                      <small>04/19/2023, at 02:47 pm</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="ModalBox fade SmallModal"
          show={AddNewSupportingDocsModal}
          onHide={handleAddNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleSupportDocs}>
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade LargeModal show"
          show={SupportingDocumentsListModal}
          onHide={handleSupportingDocumentsListModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleSupportingDocumentsListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Supporting Documents List</h4>
                </div>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                    <div>
                      <a
                      // onClick={() =>
                      //   handleAddNewSupportingDocsModalShow(taskId)
                      // }
                      >
                        <img src="images/notebook.png" />
                      </a>
                    </div>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="NotesAreaMain">
                  <div className="NotesArea mt-4">
                    <h6>Supporting Documents List</h6>
                    <div>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a>
                    </div>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Sent by</th>
                          <th>Date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {warrantSupportODcsListLoader === false ? (
                        todoWarrantySupportDocsList?.supportDocs?.length > 0 ? (
                          todoWarrantySupportDocsList.supportDocs.map(
                            (data, ind) => (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td>{data.sendBy ?? "N/A"}</td>
                                <td>
                                  {moment(data.createdAt).format("YYYY-MM-DD")}
                                </td>
                                <td className="Inspection">
                                  {data.notes ?? "N/A"}
                                </td>
                                <td>
                                  <div className="Actions">
                                    <a
                                      onClick={()=>handleUpdateNewSupportingDocsModalShow(data.parenttableId)}
                                    >
                                      <img
                                        src="images/Action-1.png"
                                        alt="Action 1"
                                      />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSupportDocumentDeleteModalHideShow(
                                          "show",
                                          data.parenttableId
                                        )
                                      }
                                    >
                                      <img
                                        src="images/delete.png"
                                        alt="Delete"
                                      />
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">
                              <p>No Data found.</p>
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <Loader />
                          </td>
                        </tr>
                      )} */}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  // onClick={() => handleAddNewSupportingDocsModalShow(taskId)}
                >
                  Add New Supporting Docs
                </button>
              </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={ChangeOrderCheckListModal}
          onHide={handleChangeOrderCheckListModalClose}
          className="ModalBox modal fade LargeModal show"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal"
              onClick={handleChangeOrderCheckListModalClose}
            >
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Change Order Checklist</h4>
              </div>
              <div className="JobTitleArea mb-2">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
              </div>
              <div className="NotesArea mb-2">
                <h6>Change Orders</h6>
                <div>
                  <a href="javascript:void(0);">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/download.png" />
                  </a>
                  <a href="javascript:void(0);">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList CommonHeading">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order id</th>
                      <th>Description</th>
                      <th>Account Description</th>
                      <th>Date Created</th>
                      <th>Date Scheduled</th>
                      <th>Client</th>
                      <th>Approved</th>
                      <th>Date approved</th>
                      <th>Schedule Date</th>
                      <th>Status</th>
                      <th>Date Completed</th>
                      <th>Completed by</th>
                      <th>Supporting Doc</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {changeOrderCheckListData?.length > 0
                      ? changeOrderCheckListData?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.id}</td>
                              <td>{item.changeOrderId}</td>
                              <td>{item.description}</td>
                              <td>N/A</td>
                              <td>
                                {moment(item.createdAt).format(
                                  "YYYY-MM-DD hh:mm:ss A"
                                )}
                              </td>
                              <td>
                                {moment(item.createdAt).format(
                                  "YYYY-MM-DD hh:mm:ss A"
                                )}
                              </td>
                              <td>{item.clientName}</td>
                              <td>{item.approved === true ? "Yes" : "No"}</td>
                              <td>
                                {item.approvedDate != null
                                  ? moment(item.createdAt).format(
                                      "YYYY-MM-DD hh:mm:ss A"
                                    )
                                  : "N/A"}
                              </td>
                              <td>
                                {item.possessionDate != null
                                  ? moment(item.possessionDate).format(
                                      "YYYY-MM-DD hh:mm:ss A"
                                    )
                                  : "N/A"}
                              </td>
                              <td>{item.todoStatus}</td>
                              <td>
                                {item.dateCompleted != null
                                  ? moment(item.dateCompleted).format(
                                      "YYYY-MM-DD hh:mm:ss A"
                                    )
                                  : "N/A"}
                              </td>
                              <td>{item.completedBy ?? "N/A"}</td>
                              <td>
                                <a href="#">
                                  <img src="images/notebook.png" />
                                </a>
                              </td>
                              <td>
                                <div className="Actions">
                                  <a
                                    onClick={() => handleAddTodoModalShow()}
                                    className="FolderPermissionId"
                                  >
                                    <img src="images/Icon-5.png" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : "No Data found"}
                  </tbody>
                </table>
              </div>
              {/* <div class="Button mt-5">
          <button class="Create">
            View Consolidated Change Order Checklists
          </button>
          <button class="Create ml-3" data-dismiss="modal">
            Create new Field Order P.O.
          </button>
        </div> */}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={AddTodoModal}
          onHide={handleAddTodoModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              onClick={handleAddTodoModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New To-Do</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>To-Do Information</h2>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>Job</h6>
                  <div className="Categories Categories1">
                    <div className="Categories_all Categories_all1">
                      <span>Select a Job</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input
                          type="text"
                          placeholder="Search"
                          name="jobSearch"
                          value={jobSearch}
                          onChange={handleInputChange}
                        />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      {jobList?.jobs?.length > 0 &&
                        jobList?.jobs
                          ?.filter((data) =>
                            jobSearch.toLowerCase() === ""
                              ? true
                              : data.jobType
                                  .toLowerCase()
                                  .includes(jobSearch.toLowerCase())
                          )
                          .map((item, i) => {
                            return (
                              <li
                                // value={item.id}
                                key={i}
                                onClick={() => handleJobIternaryItemClick(item)}
                                style={{
                                  backgroundColor:
                                    JobId === item.id
                                      ? "#e71e09"
                                      : "transparent",
                                }}
                              >
                                {item.jobType}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Schedule related To-Do?<span className="required">*</span>
                  </h6>
                  <label className="CheckBox mr-4">
                    {" "}
                    Yes
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={true}
                      checked={scheRelatedToDo === true}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="CheckBox">
                    {" "}
                    No
                    <input
                      type="radio"
                      name="scheRelatedToDo"
                      value={false}
                      checked={scheRelatedToDo === false}
                      onChange={(e) => handleCheckbox(e)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <h6>Schedule's Task</h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      {/* <select
                        name="TaskId"
                        value={TaskId}
                        onChange={handleInputChange}
                      >
                        <option>Select Schedule's Task</option>
                        <option value="1">Schedule's Task</option>
                      </select> */}
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Schedule tasks for job</li>
                    </ul>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Description <span className="required">*</span>
                  </h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Write here ....."
                    name="description"
                    value={description}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h6>Target Date</h6>
                      <input
                        type="date"
                        className="form-control"
                        name="targetDate"
                        value={targetDate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Supporting Document</h6>
                    <div className="StaffName">
                      <img src="images/document-support.png" />

                      <h6
                        className="CursorPointer FolderPermissionId"
                        // onClick={() => showSupportingDocsModal()}
                      >
                        Add Supporting Document
                      </h6>
                    </div>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Add Notes</h6>
                    <div className="StaffName">
                      <img src="images/add-notes.png" />
                      <h6
                        className="CursorPointer FolderPermissionId"
                        // onClick={() => showAddNotesModal()}
                      >
                        Add Notes
                      </h6>
                    </div>
                  </aside>
                  <aside>
                    <h6>Assign Trade</h6>
                    <div className="StaffName">
                      <img src="images/assign-trade.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => handleTradeModalShow()}
                      >
                        Assign
                      </h6>
                    </div>
                  </aside>
                </div>
                <div className="StaffMemberArea StaffMemberAreaConst">
                  <aside>
                    <h6>Assign Staff Member</h6>
                    <div className="StaffName">
                      <img src="images/member.png" />
                      {/* <div className="Count">3</div> */}
                      <h6
                        className="FolderPermissionId"
                        onClick={() => handleAssignStaffMemberShow()}
                      >
                        Assign
                      </h6>
                    </div>
                  </aside>
                  <aside>
                    <h6>Set Reminder</h6>
                    <div className="StaffName">
                      <img src="images/timer-red.png" />
                      <h6
                        className="FolderPermissionId"
                        onClick={() => handleReminderShow()}
                      >
                        Reminder
                      </h6>
                    </div>
                  </aside>
                </div>
                <div className="Button text-center mt-5">
                  <button
                    className="Create"
                     onClick={addTodoFunc}
                  >
                    Create Todo
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={AssignStaffMemberModal}
        onHide={handleAssignStaffMemberClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleAssignStaffMemberClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3>Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control"
                        name="assignStaffs"
                        value={assignStaffs}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {membersList?.staff?.length > 0 &&
                          membersList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssign}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ReminderModal}
        onHide={handleReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleReminderClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminder}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
        <Modal
        show={TradeModal}
        onHide={handleTradeModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleTradeModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleHeading">
              <h2>Trade Information</h2>
            </div>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Assign Trade</h4>
              </div>
              <div className="ModalTitleHeading">
                <h2>Trade Information</h2>
              </div>
              <div className="FolderForm">
                  <div className="form-group">
                    <div className="Categories Categories2 mb-4">
                      <h6>
                        Sub Trade Name<span className="required">*</span>
                      </h6>
                      <div className="Categories_all Categories_all2">
                        <select
                          name="VendorId"
                          value={VendorId}
                          onChange={handleInputChange}
                        >
                          <option>Select Sub Trade</option>
                          {vendorList?.VendorList &&
                            vendorList?.VendorList?.map((ele, i) => {
                              return (
                                <>
                                  <option value={ele?.id}>
                                    {ele?.vendorName}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                        <img src="images/down.png" />
                      </div>
                   
                    </div>
                  </div>

                  <div className="form-group">
                    <h6>Contact</h6>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Contact"
                      name="contact"
                      value={contact}
                      onChange={handleInputChange}
                    />
                
                  </div>
                  <div className="form-group">
                    <h6>Email</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                   
                  </div>
                  <div className="form-group">
                    <h6>Message</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write here ..."
                      name="message"
                      value={message}
                      onChange={handleInputChange}
                    />

                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" 
                    onClick={handleTradeModalClose}
                    >
                      Create Trade
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
          className="ModalBox fade SmallModal"
          show={AddNotesModal}
          onHide={handleAddNotesClose}
        >
          <Modal.Body>
            <div className="Category">
              <a className="CloseModal" onClick={handleAddNotesClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Note</h4>
                </div>
                <h6>Note #1</h6>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox mb-3">
                    <h6>
                      <span>Origin:</span> #185
                    </h6>
                  </div>
                  <h6>Type</h6>

                  <div className="FolderForm">
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Text
                        <input
                          type="radio"
                          value="Text"
                          checked={selectedOption === "Text"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        File
                        <input
                          type="radio"
                          value="File"
                          checked={selectedOption === "File"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    {textInput == true && (
                      <div className="form-group">
                        <h6>Message (Max 1000 chars limit)</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="message"
                          value={message}
                          onChange={handleInputChange}
                        />
                        <small className="form-text text-right">
                          {1000 - Number(message.length)} characters remaining
                        </small>
                      </div>
                    )}

                    {fileData == true && (
                      <>
                        <div className="form-group">
                          <h6>Select Files</h6>
                          <div className="Upload">
                            <span>-Drag and Drop Files or click to select</span>
                            <input
                              type="file"
                              id="fileInput"
                              onChange={(e) => handleFileChange(e, "support")}
                            />
                          </div>
                        </div>
                        {supportingDocFiles && (
                          <div className="FilesAdded">
                            <label>{supportingDocFiles?.name}</label>
                            <span>
                              <img src="images/fileview.png" alt="File view" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img
                                src="images/Cross.png"
                                className="cross"
                                alt="Cross"
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    <div className="Button d-flex justify-content-between mt-4">
                      <button className="Create" onClick={handleAddNotesClose}>
                        Add Note
                      </button>
                      <button className="Outline" onClick={handleAddNotesClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default Change_Order_Checklist_Tradeview;
