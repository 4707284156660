import React, { useEffect, useState } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import {
  createComment,
  createSubComment,
  getAllProjectList,
  getCommentList,
  getInvestorBuilderList,
  getInvestorJobByProject,
  getInvestorNotifications,
  getInvestorView,
  getProjectByBuilder,
  getProjectJobList,
  getSubCommentList,
  getUploadIconImage,
  getUploadMainImage,
  getVendorByUserId,
  getVendorCompaniesList,
} from "../redux/action/investorViewAuthAction";
import { toast } from "react-toastify";
import moment from "moment";
import InvestorViewSidenav from "./investor-view-sidenav";
import InvestorHeader from "./InvestorHeader";
const initialState = {
  file: null,
  mainFile: null,
  content: "",
  projectId: "",
  investorId: "",
  subContent: "",
  index: "",
  parentId: "",

  companyId:"",
  jobId:"",
  notificationData:[],
};

const InvestorViewHome = () => {
  const dispatch = useDispatch();
  const investorViewData = JSON.parse(localStorage.getItem("investorViewLogin"))
  const [iState, updateState] = useState(initialState);
  const {
    file,
    mainFile,
    content,
    projectId,
    investorId,
    subContent,
    index,
    parentId,
    companyId,
    jobId,
    notificationData,
  } = iState;
  const {
    vendorData,
    commentList,
    vendorCompanyList,
    allProjectList,
    projectJobList,
    subCommentList,
    builderList,
    buliderByProjectList,
    jobByProjectList,
  } = useSelector((state) => state.investorViewLogin);

  const compId = JSON.parse(window.localStorage.getItem("investorViewLogin"));

  useEffect(() => {
    dispatch(getVendorByUserId()).then((res) => {
      // dispatch(getVendorCompaniesList(res?.data?.VendorList?.id));
    });
    // dispatch(getAllProjectList(compId?.user?.CompanyId));
    dispatch(getInvestorView(compId?.user?.CompanyId)).then((res) => {
      dispatch(
        getCommentList(res?.data?.ViewSetting?.id, compId?.user?.CompanyId)
      );
      // dispatch(getSubCommentList(res?.data?.ViewSetting?.id,compId?.user?.CompanyId));
      updateState({ ...iState, investorId: res?.data?.ViewSetting?.id });
    });
    dispatch(getInvestorBuilderList())
    dispatch(getInvestorNotifications()).then((res)=>{
      if(res.status===200){
       
        updateState({...iState,notificationData:res.data.notifications})
      }
    })
  }, []);

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "companyId") {
      dispatch(getProjectByBuilder(value));
      updateState({
        ...iState,
        [name]: value,
      });
      window.localStorage.setItem("builderId", JSON.stringify(value));
    } else if (name === "projectId") {
      dispatch(getInvestorJobByProject(companyId, value));
      updateState({
        ...iState,
        [name]: value,
      });
    } else if (name === "jobId") {
      updateState({
        ...iState,
        [name]: value,
      });
    }
    else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleFileChanges = (event) => {
    const file = event?.target?.files[0];
    updateState({ ...iState, file: file });
    if (file) {
      const datta = { file };
      dispatch(getUploadIconImage(vendorData?.VendorList?.id, datta)).then(
        (res) => {
          if (res.status === 200) {
            dispatch(getVendorByUserId());
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({ ...iState, file: null });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleMainFileChanges = (event) => {
    const file = event?.target?.files[0];
    updateState({ ...iState, mainFile: file });
    if (mainFile) {
      const datta = { file: mainFile };
      dispatch(getUploadMainImage(vendorData?.VendorList?.id, datta)).then(
        (res) => {
          if (res.status === 200) {
            dispatch(getVendorByUserId());
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({ ...iState, mainFile: null });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleCreateComment = () => {
    const dataa = { content };
    dispatch(createComment(investorId, compId?.user?.CompanyId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getCommentList(investorId, compId?.user?.CompanyId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            content: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleRemoveComments = (type) => {
    if (type === "comment") {
      updateState({ ...iState, content: "" });
    } else {
      updateState({ ...iState, subContent: "" });
    }
  };

 

  const handleSubCreateComment = (id) => {
    const dataa = { content: subContent, parentId: id };
    dispatch(createSubComment(investorId, compId?.user?.CompanyId, dataa)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getSubCommentList(investorId, id, compId?.user?.CompanyId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            subContent: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  useEffect(()=>{
    dispatch(getSubCommentList(investorId,parentId,compId?.user?.CompanyId));
  },[index])

  return (
    <div>
      <InvestorHeader/>
      <InvestorViewSidenav/>
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
         
          <div className="TitleBoxClient">
            <h4 className="TitleClient">Investor Overview</h4>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="ProjectViewTop">
                <figure>
                  <img src={require("../assets/images/project_view_banner.png")} />
                  <img src={vendorData?.VendorList?.mainUrl} />
                  <div className="CameraOverlay">
                    <input
                      type="file"
                      onChange={(e) => handleMainFileChanges(e)}
                    />
                     <img src="images/camera_icon.png" />
                  </div>
                </figure>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="HomeUpdatesAreaTrade">
                    <a
                      href="javascript:void(0);"
                      className="HuatOverlay"
                      data-toggle="modal"
                      data-target="#RequestJobAccess"
                    >
                      <img src="images/lock-settings.png" />
                    </a>
                    <div className="SelectBuilderMain SelectBuilderMainShow">
                      <h3>Builder</h3>
                      <p>Builder</p>
                      <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="companyId"
                            value={companyId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Builder</option>
                            {builderList?.data?.length > 0 &&
                              builderList?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    </div>
                    <div className="BuilderBox">
                        <h4>Project</h4>
                        <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="projectId"
                            value={projectId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Project</option>
                            {buliderByProjectList?.data?.length > 0 &&
                              buliderByProjectList?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.projectName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className="BuilderBox">
                        <h4>Job</h4>
                        <div className="SelectBuilder">
                          <select
                            className="CommonSelect"
                            name="jobId"
                            value={jobId}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Jobs</option>
                            {jobByProjectList?.data?.length > 0 &&
                              jobByProjectList?.data?.map((item, i) => {
                                return (
                                  <option value={item.id} key={i}>
                                    {item.jobType}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="ProjectProgress ProjectProgressHide">
                    <h6>Project Progress</h6>
                    <div className="ProjectProgressArea">
                      <div className="Progress_heading">
                        <h6>Insulation, Boarding &amp; Taping</h6>
                        <p>Jan 23</p>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="progress-bar-overlay">
                        <img src="images/check_progress.png" />
                      </div>
                    </div>
                    <div className="ProjectProgressArea">
                      <div className="Progress_heading">
                        <h6>Insulation, Boarding &amp; Taping</h6>
                        <p>Jan 23</p>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="progress-bar-overlay">
                        <img src="images/check_progress.png" />
                      </div>
                    </div>
                    <div className="ProjectProgressArea">
                      <div className="Progress_heading">
                        <h6>Paint, Wall &amp; Floor Tile &amp; Flooring</h6>
                        <p>Jan 23</p>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="progress-bar-overlay">
                        <img src="images/check_progress.png" />
                      </div>
                    </div>
                    <div className="ProjectProgressArea">
                      <div className="Progress_heading">
                        <h6>Balance of Finals, Cleaning &amp; Possession</h6>
                        <p>Mar 23</p>
                      </div>
                      <div className="progress">
                        <div className="progress-bar" style={{ width: "92%" }}>
                          92%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                <div className="HomeUpdatesArea">
                  <div className="HomeUpdatesAreaTop">
                    <h6>Updates</h6>
                    <h6>Dates</h6>
                  </div>
                  <div className="HomeUpdatesAreaBottom">
                    {
                      notificationData?.length>0&&
                      notificationData?.map((data,i)=>{
                        return(
                          <div className="HomeUpdatesAreaRow">
                          <h6>
                            <img src="images/update_1.png" />
                            {data.notifiMessage}
                          </h6>
                          <p>{moment(data.sendDate).format("MM/DD/YYYY - hh:mm A")}</p>
                        </div>
                        )
                      })
                    }
                   
                    {/* <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_2.png" />1 new document to sign!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_3.png" />
                        Warranty Request - Plumbing Fixes was completed! Click
                        here to view and approve!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_4.png" />
                        New change order Change order one was created!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_5.png" />3 new discussion messages
                        arrived!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div> */}
                  </div>
                </div>
               
              </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="UserDetailsArea">
                <div className="UserDetailsBox">
                  <figure>
                    <img src={vendorData?.VendorList?.iconUrl} />
                    <div className="CameraOverlay">
                      <input
                        type="file"
                        onChange={(e) => handleFileChanges(e)}
                      />
                      <img src="images/camera_icon.png" />
                    </div>
                  </figure>
                  <p>Welcome back {investorViewData?.user?.name}</p>
                  <p>Sales</p>
                  <p>Investor</p>
                </div>
                <div className="SalesContactBox ">
                  <h6>Builder information</h6>
                  <h6 className="Brand">{investorViewData?.builderInfo?.name}</h6>
                  <p className="Address">
                  {investorViewData?.builderInfo?.streetAddressLine1},
                    <br />
                    {investorViewData?.builderInfo?.city},
                  </p>
                  <p>
                    <img src="images/send-email.png" />
                    {investorViewData?.builderInfo?.email},
                  </p>
                  <p>
                    <img src="images/call.png" />
                    {investorViewData?.builderInfo?.primaryNumber},
                  </p>
                </div>
                <div className="SalesContactBox SalesContactBoxHidden">
                  <h6>Job information</h6>
                  <h6 className="Brand">
                    Unit 160
                    <img src="images/location.png" />
                  </h6>
                  <p className="Address">
                    52 Ave SW, Calgary,
                    <br />
                    Alberta, TM5 1Y4
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="DiscussionArea">
            <div className="DiscussionTitle">
              <h4>Discussion</h4>
              <div className="DiscussionChat">
                <img src="images/down.png" className="DiscussionIcon" />
                <a href="#">
                  <img src="images/pdf2.png" />
                </a>
              </div>
            </div>
            <div className="DiscussionHideArea" style={{ display: "block" }}>
              <div className="DiscussionBox">
                <span>FM</span>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add a Comment ....."
                    name="content"
                    value={content}
                    onChange={handleInputChange}
                  />
                  <div className="DiscussionOverlay">
                    <a
                      className="CancelChat"
                      onClick={() => handleRemoveComments("comment")}
                    >
                      <img src="images/plus-cancel1.png" />
                    </a>
                    <button
                      className="SendChat"
                      onClick={handleCreateComment}
                      disabled={content == ""}
                    >
                      <img src="images/forwardbutton.png" />
                    </button>
                  </div>
                </div>
              </div>
              {commentList?.comments?.length > 0 &&
                commentList?.comments
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((item, ind) => {
                    return (
                      <div className="DiscussionBox">
                        <span>TH</span>
                        <div className="DiscussionRight">
                          <h6>{item.content}</h6>
                          <small>
                            {moment(item.createdAt).format(
                              "MM/DD/YYYY, [at] hh:mm A"
                            )}
                          </small>

                          <div class="ReplyArea">
                            <a
                              onClick={() =>
                                updateState({ ...iState, index: ind,parentId: item.id })
                              }
                            >
                              Reply
                            </a>
                            {
                            index===ind &&
                            subCommentList?.comments?.length > 0 &&
                              subCommentList?.comments?.map((ele) => (
                                <div class="DiscussionBox">
                                  <span>RE</span>
                                  <div class="DiscussionRight">
                                    <h6>{ele.content}</h6>
                                    <small>
                                      {moment(ele.createdAt).format(
                                        "MM/DD/YYYY, [at] hh:mm A"
                                      )}
                                    </small>
                                  </div>
                                </div>
                              ))}
                            {index === ind && (
                              <div class="DiscussionBox w-100">
                                <span>FM</span>
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Add reply ....."
                                    name="subContent"
                                    value={subContent}
                                    onChange={(e) => handleInputChange(e)}
                                  />

                                  <div class="DiscussionOverlay">
                                    <a
                                      onClick={() =>
                                        handleRemoveComments("subcomment")
                                      }
                                      class="CancelChat"
                                    >
                                      <img
                                        src={require("../assets/images/plus-cancel1.png")}
                                      />
                                    </a>
                                    <a
                                      onClick={() =>
                                        handleSubCreateComment(item.id)
                                      }
                                      class="SendChat"
                                    >
                                      <img
                                        src={require("../assets/images/forwardbutton.png")}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorViewHome;
