import React, {useEffect, useState} from 'react'
import { getAllJobListAction } from '../../../redux/action/jobAction';
import { useDispatch, useSelector } from 'react-redux';
import { checkArray } from "../../../utils/CheckType";
import { toast } from "react-toastify";
import { blockActualCostsAction, blockUnitActualCostsAction, estimationActualAction, finaceSalesCashDepositeAction } from '../../../redux/action/reportsAction';


const Financial = ({activeTab, setActiveTab, iState, updateState}) => {
    const dispatch = useDispatch()



    const selectedProject = JSON.parse(localStorage.getItem("tempData"));

    
  const { projectListData, jobListData } = useSelector(
    (state) => state.jobReducer
  );

    const {
        reportName,
        ProjectId,
        allJob,
        JobIds,
        JobType,
        AvaiInvestorView,
        toDateChecked,
        fromDate,
        toDate,
      } = iState;


    const handleSelectProject = (e) => {
        updateState({ ...iState, ProjectId: e.target.value });
        dispatch(getAllJobListAction(e.target.value));
      };
    



      const handleSelectJob = (e) => {
        updateState({ ...iState, JobIds: [e.target.value] });
      };
    
      const handleSubmitFinanace = () => {
        let formIsValid = true;
        if (formIsValid) {
          const data = {
            reportName: activeTab,
            ProjectId,
            allJob,
            JobIds,
            JobType: "MultiUnit",
            AvaiInvestorView,
            toDateChecked,
            fromDate: fromDate,
            toDate: toDate,
          };
    
          if (activeTab == "Sales & Cash Deposits Status") {

            data.reportName ="Sales & Cash Deposits Status"
            dispatch(finaceSalesCashDepositeAction(data))
              .then((res) => {
                if (res.status === 200) {
                  // updateState({
                  //   reportName: "Sales & Cash Deposits Status",
                  //   ProjectId: "",
                  //   allJob: false,
                  //   JobIds: [],
                  //   JobType: "",
                  //   AvaiInvestorView: false,
                  //   toDateChecked: false,
                  //   fromDate: "",
                  //   toDate: "",
                  // });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  updateState({
                    reportName: "Sales & Cash Deposits Status",
                    ProjectId: "",
                    allJob: false,
                    JobIds: [],
                    JobType: "",
                    AvaiInvestorView: false,
                    toDateChecked: false,
                    fromDate: "",
                    toDate: "",
                  });
                }
              })
              .catch((error) => {
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              });
          } else {

            data.reportName ="Sales & Cash Deposits Status"

            dispatch(finaceSalesCashDepositeAction(data))
              .then((res) => {
                if (res.status === 200) {
                  // updateState({
                  //   reportName: "Sales & Cash Deposits Status",
                  //   ProjectId: "",
                  //   allJob: false,
                  //   JobIds: [],
                  //   JobType: "",
                  //   AvaiInvestorView: false,
                  //   toDateChecked: false,
                  //   fromDate: "",
                  //   toDate: "",
                  // });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  updateState({
                    reportName: "Sales & Cash Deposits Status",
                    ProjectId: "",
                    allJob: false,
                    JobIds: [],
                    JobType: "",
                    AvaiInvestorView: false,
                    toDateChecked: false,
                    fromDate: "",
                    toDate: "",
                  });
                }
              })
              .catch((error) => {
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              });
    
            dispatch(blockActualCostsAction(data))
              .then((res) => {
                if (res.status === 200) {
                  // updateState({
                  //   reportName: "Sales & Cash Deposits Status",
                  //   ProjectId: "",
                  //   allJob: false,
                  //   JobIds: [],
                  //   JobType: "",
                  //   AvaiInvestorView: false,
                  //   toDateChecked: false,
                  //   fromDate: "",
                  //   toDate: "",
                  // });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  updateState({
                    reportName: "Sales & Cash Deposits Status",
                    ProjectId: "",
                    allJob: false,
                    JobIds: [],
                    JobType: "",
                    AvaiInvestorView: false,
                    toDateChecked: false,
                    fromDate: "",
                    toDate: "",
                  });
                }
              })
              .catch((error) => {
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              });
    
            dispatch(blockUnitActualCostsAction(data))
              .then((res) => {
                if (res.status === 200) {
                  // updateState({
                  //   reportName: "Sales & Cash Deposits Status",
                  //   ProjectId: "",
                  //   allJob: false,
                  //   JobIds: [],
                  //   JobType: "",
                  //   AvaiInvestorView: false,
                  //   toDateChecked: false,
                  //   fromDate: "",
                  //   toDate: "",
                  // });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  updateState({
                    reportName: "Sales & Cash Deposits Status",
                    ProjectId: "",
                    allJob: false,
                    JobIds: [],
                    JobType: "",
                    AvaiInvestorView: false,
                    toDateChecked: false,
                    fromDate: "",
                    toDate: "",
                  });
                }
              })
              .catch((error) => {
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              });
    
            dispatch(estimationActualAction(data))
              .then((res) => {
                if (res.status === 200) {
                  // updateState({
                  //   reportName: "Sales & Cash Deposits Status",
                  //   ProjectId: "",
                  //   allJob: false,
                  //   JobIds: [],
                  //   JobType: "",
                  //   AvaiInvestorView: false,
                  //   toDateChecked: false,
                  //   fromDate: "",
                  //   toDate: "",
                  // });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  updateState({
                    reportName: "Sales & Cash Deposits Status",
                    ProjectId: "",
                    allJob: false,
                    JobIds: [],
                    JobType: "",
                    AvaiInvestorView: false,
                    toDateChecked: false,
                    fromDate: "",
                    toDate: "",
                  });
                }
              })
              .catch((error) => {
                toast.error(error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              });
          }
        }
      };
    
    

      useEffect(()=>{

        const data = {
          reportName: "Sales & Cash Deposits Status",
          ProjectId : ProjectId? ProjectId: selectedProject?.ProjectId,
          allJob,
          JobIds,
          JobType: "MultiUnit",
          AvaiInvestorView,
          toDateChecked,
          fromDate: fromDate,
          toDate: toDate,
        };
  
          dispatch(finaceSalesCashDepositeAction(data))
            .then((res) => {
              if (res.status === 200) {
                // updateState({
                //   reportName: "Sales & Cash Deposits Status",
                //   ProjectId: "",
                //   allJob: false,
                //   JobIds: [],
                //   JobType: "",
                //   AvaiInvestorView: false,
                //   toDateChecked: false,
                //   fromDate: "",
                //   toDate: "",
                // });
              }
            })
            .catch((error) => {
              toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
              // updateState({
              //   reportName: "Sales & Cash Deposits Status",
              //   ProjectId: "",
              //   allJob: false,
              //   JobIds: [],
              //   JobType: "",
              //   AvaiInvestorView: false,
              //   toDateChecked: false,
              //   fromDate: "",
              //   toDate: "",
              // });
            });
         
      },[])
    

  return (
    <div class="tab-pane fade active show" id="Financial">
              <div
                class="SalesDocumentArea"
                style={{ display: activeTab == "" ? "block" : "none" }}
              >
                <div class="row">
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/sales-report.png")}
                            />{" "}
                            &nbsp; Sales
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select
                              class="myselect"
                              onChange={(e) => setActiveTab(e.target.value)}
                            >
                              <option value="">Select a Vendor Type</option>
                              <option value="Sales & Cash Deposits Status">
                                Sales & Cash Deposits Status
                              </option>
                              <option value="Revenues & Gross Margin Analysis Summary">
                                Revenues & Gross Margin Analysis Summary
                              </option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/design-report.png")}
                            />{" "}
                            &nbsp; Design
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Estimation
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/estimation-report.png")}
                            />{" "}
                            &nbsp; Purchasing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/construction_apps.png")}
                            />{" "}
                            &nbsp; Constrcution
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/warranty-reports.png")}
                            />{" "}
                            &nbsp; Warranty
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Accounting
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/accounting-report.png")}
                            />{" "}
                            &nbsp; Proforma
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/electronic-signing.png")}
                            />{" "}
                            &nbsp; Electronic Signing
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/job-icon.png")}
                            />{" "}
                            &nbsp; Job
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="FilesBox2 Safety">
                      <figcaption>
                        <div>
                          <h4>
                            {" "}
                            <img
                              src={require("../../../assets/images/vendor-icon.png")}
                            />{" "}
                            &nbsp; Vendors
                          </h4>
                        </div>
                      </figcaption>

                      <div class="FilesBoxCenterSafety">
                        <div class="form-group">
                          <div class="CommonSelectBox">
                            <select class="myselect">
                              <option selected="">Select a Vendor Type</option>
                            </select>
                            <span>
                              <img
                                src={require("../../../assets/images/down.png")}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="FinalcialReportArea"
                style={{
                  display:
                    activeTab == "Sales & Cash Deposits Status" ||
                    activeTab == "Revenues & Gross Margin Analysis Summary"
                      ? "block"
                      : "none",
                }}
              >
                <div class="FraHeader pb-0">
                  <ul>
                    <li>
                      <a onClick={() => setActiveTab("")} class="BackReportBtn">
                        <img
                          src={require("../../../assets/images/left-arrow-red.png")}
                        />
                        Back Report List
                      </a>
                    </li>
                  </ul>
                  <div class="FraHeaderLeft">
                    <h5>
                      <img
                        src={require("../../../assets/images/sales-icon.png")}
                      />
                      Sales
                    </h5>
                    <h6>{activeTab}</h6>
                  </div>
                  <div class="Filter">
                    <div class="form-group">
                      <h6>Project Name:</h6>
                      <select onChange={handleSelectProject}>
                        <option>Select Project Name</option>
                        {checkArray(projectListData) &&
                          projectListData?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}>
                                  {ele?.projectName}
                                </option>
                              </>
                            );
                          })}{" "}
                      </select>
                    </div>
                    <div class="form-group ml-5">
                      <h6>Job(s) #:</h6>
                      <select class="form-group" onChange={handleSelectJob}>
                        <option>Select Job Id</option>
                        {checkArray(jobListData?.jobs) &&
                          jobListData?.jobs?.map((ele, i) => {
                            return (
                              <>
                                <option value={ele?.id}>
                                  {ele?.jobNumber}
                                </option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div class="FraHeader">
                  <div class="form-group d-flex align-items-center">
                    <h6>Available in Investor View</h6>
                    <label class="switch ml-4">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          updateState({
                            ...iState,
                            AvaiInvestorView: e.target.checked,
                          })
                        }
                      />
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div class="Filter">
                    <div class="form-group">
                      <h6>To-Date</h6>
                      <label class="CheckBox">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            updateState({
                              ...iState,
                              toDateChecked: e.target.checked,
                            })
                          }
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <h6>Or</h6>
                      <input
                        type="date"
                        class="form-control"
                        onChange={(e) =>
                          updateState({ ...iState, fromDate: e.target.value })
                        }
                      />
                    </div>
                    <div class="form-group">
                      <h6>To</h6>
                      <input
                        type="date"
                        class="form-control"
                        onChange={(e) =>
                          updateState({ ...iState, toDate: e.target.value })
                        }
                      />
                    </div>
                    <a class="RunReportBtn" onClick={handleSubmitFinanace}>
                      Run Report
                    </a>
                  </div>
                </div>
                <div class="FinalcialReportBox">
                  <div class="FinalcialReportBoxHeader">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          href="#ReportTab"
                          data-toggle="tab"
                        >
                          {" "}
                          <span>
                            <img
                              src={require("../../../assets/images/report-red.png")}
                            />
                          </span>{" "}
                          Report{" "}
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#ChartsTab" data-toggle="tab">
                          {" "}
                          <span>
                            <img
                              src={require("../../../assets/images/chart-img.png")}
                            />
                          </span>{" "}
                          Charts{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane fade active show" id="ReportTab">
                      <div class="FinalcialReportBoxCenter">
                        <div class="SortByArea">
                          <div class="d-flex justify-content-between">
                            <h6 class="mb-2">
                              <img
                                src={require("../../../assets/images/sort-ascending.png")}
                              />
                              &nbsp; Sort
                            </h6>
                            <h6
                              class="mb-2 CursorPointer"
                              data-toggle="modal"
                              data-target="#CreateCustomReport"
                            >
                              <img
                                src={require("../../../assets/images/create-custom-reports.png")}
                              />
                              &nbsp; Create Custom Report
                            </h6>
                          </div>
                          <div class="SortByBox">
                            <h6 class="mb-3">Sort By</h6>
                            <select class="form-control">
                              <option selected="">Column name</option>
                            </select>
                            <div class="form-group mt-3">
                              <h6 class="mb-3">Sort in</h6>
                              <label class="Radio" checked="">
                                {" "}
                                Ascending order
                                <input
                                  type="radio"
                                  name="Ascending"
                                  checked=""
                                />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="Radio">
                                {" "}
                                Descending order
                                <input type="radio" name="Ascending" />
                                <span class="checkmark"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="FrbcRight">
                          <div class="Actions">
                            <a
                              href="javscript:void(0);"
                              class="CursorPointer"
                              data-toggle="modal"
                              data-target="ShareReport"
                            >
                              <img
                                src={require("../../../assets/images/Action-2.png")}
                              />
                            </a>
                            <a href="javscript:void(0);">
                              <img
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                            <span class="dropdown">
                              <a
                                href="javscript:void(0);"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  src={require("../../../assets/images/download.png")}
                                />
                              </a>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a class="dropdown-item" href="#">
                                  Action
                                </a>
                                <a class="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a class="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </span>
                            <a href="javscript:void(0);">
                              <img
                                src={require("../../../assets/images/setting.png")}
                              />
                            </a>
                          </div>
                          <figure>
                            <img
                              src={require("../../../assets/images/large-logo.png")}
                            />
                          </figure>
                          <h6 class="text-right">
                            January 1 - October 7, 2023
                          </h6>
                        </div>
                      </div>
                      <div class="FinalcialReportBoxBottom">
                        <div class="FinalcialReportBoxBottomTable">
                          <table>
                            <thead>
                              <tr>
                                <th>Column 1</th>
                                <th>Column 2</th>
                                <th>Column 3</th>
                                <th>Column 4</th>
                                <th>Column 5</th>
                                <th>Column 6</th>
                                <th>Column 7</th>
                              </tr>
                            </thead>
                          </table>
                          <h6>Report</h6>
                        </div>
                      </div>
                      <div class="FinalcialReportBoxCenter">
                        <div class="SortByArea">
                          <div class="d-flex justify-content-between">
                            <h6>
                              <img
                                src={require("../../../assets/images/sort-ascending.png")}
                              />
                              &nbsp; Sort
                            </h6>
                            <h6
                              class="ml-5 CursorPointer"
                              data-toggle="modal"
                              data-target="#CreateCustomReport"
                            >
                              <img
                                src={require("../../../assets/images/create-custom-reports.png")}
                              />
                              &nbsp; Create Custom Report
                            </h6>
                          </div>
                        </div>
                        <div class="FrbcRight">
                          <div class="Actions">
                            <a
                              href="javscript:void(0);"
                              data-toggle="modal"
                              data-target="#ShareReport"
                            >
                              <img
                                src={require("../../../assets/images/Action-2.png")}
                              />
                            </a>
                            <a href="javscript:void(0);">
                              <img
                                src={require("../../../assets/images/pdf.png")}
                              />
                            </a>
                            <span class="dropdown">
                              <a
                                href="javscript:void(0);"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <img
                                  src={require("../../../assets/images/download.png")}
                                />
                              </a>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a class="dropdown-item" href="#">
                                  Action
                                </a>
                                <a class="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a class="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </span>
                            <a href="javscript:void(0);">
                              <img
                                src={require("../../../assets/images/setting.png")}
                              />
                            </a>
                          </div>
                          <figure>
                            <img
                              src={require("../../../assets/images/large-logo.png")}
                            />
                          </figure>
                          <h6 class="text-right">
                            January 1 - October 7, 2024
                          </h6>
                        </div>
                      </div>

                      {activeTab =="Sales & Cash Deposits Status" &&

                      <div class="ZenSequelSalesArea Project1 ProjectSelectBox">
                        <h6>
                          Zen Sequel Sales Stages &amp; Sales Deposits Tracking
                        </h6>
                        <h6>As of: 29-Feb-24</h6>
                        <div
                          class="CommonGreenTable mb-5 mx-5"
                          style={{ width: "70%" }}
                        >
                          <table>
                            <thead>
                              <tr>
                                <th>
                                  <div class="text-left">Sales Stages</div>
                                </th>
                                <th>Block - 1</th>
                                <th>Block - 2</th>
                                <th>Block - 3</th>
                                <th>Block - 4</th>
                                <th>Block - 5</th>
                                <th>Block - 6</th>
                                <th>Block - 7</th>
                                <th>Block - 8</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="text-left">
                                    <strong>Unconditional (U) :</strong>
                                  </div>
                                </td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>56</td>
                              </tr>
                           
                              <tr>
                                <td>
                                  <div class="text-left">
                                    <strong>Turned-over (T) :</strong>
                                  </div>
                                </td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>14</td>
                                <td>56</td>
                              </tr>
                             
                            </tbody>
                          </table>
                        </div>
                        <div class="CommonGreenTable">
                          <table>
                            <thead>
                              <tr>
                                <th rowspan="2">
                                  <div class="text-left">No. of Units</div>
                                </th>
                                <th rowspan="2">Job No.</th>
                                <th rowspan="2">
                                  Unit No.( Initial <br />
                                  SIte Plan )
                                </th>
                                <th rowspan="2">Address</th>
                                <th rowspan="2">Bldg# - Unit#</th>
                                <th rowspan="2">
                                  Min. Selling <br />
                                  Price
                                </th>
                                <th rowspan="2">Buyer</th>
                                <th rowspan="2">Status</th>
                                <th rowspan="2">Price (Net)</th>
                                <th rowspan="2">
                                  Price (with <br />
                                  GST )
                                </th>
                                <th colspan="4" class="BorderBottom">
                                  Deposit
                                </th>
                                <th rowspan="2">%</th>
                                <th rowspan="2">Possession</th>
                                <th rowspan="2">Notes</th>
                              </tr>
                              <tr>
                                <th>With Avalon</th>
                                <th>In-Trust</th>
                                <th>Released</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>6</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>7</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>8</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>9</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>10</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>11</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>12</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>13</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>14</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>15</td>
                                <td>SEQ1 -00101</td>
                                <td>6</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td></td>
                                <td></td>
                                <td>S</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td>$ -</td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>16</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>17</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>18</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>19</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>20</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>21</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>22</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>23</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>24</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>25</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>26</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>27</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>28</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>29</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>30</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>31</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>32</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>33</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>34</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>35</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>36</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>37</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>38</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>39</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>40</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>41</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>42</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>43</td>
                                <td>SEQ1 -00101</td>
                                <td>12</td>
                                <td>338 Secton CL SE</td>
                                <td>Bldg 1, Unit 101</td>
                                <td>$</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>
                                  <div class="text-left">
                                    <strong>Totals :</strong>
                                  </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <strong>124</strong>
                                </td>
                                <td>$ 3,094,666.56</td>
                                <td>$ 3,094,666.56</td>
                                <td>$ 3,094,666.56</td>
                                <td>$ 3,094,666.56</td>
                                <td>$ 3,094,666.56</td>
                                <td>$ 3,094,666.56</td>
                                <td>4.2%</td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>}
                      
                     
                
                     {activeTab=="Revenues & Gross Margin Analysis Summary" && <div class="RevenuesAndGrossMarginAnalysisSummaryToDateReport Project15 ProjectSelectBox">
                        <div class="CommonGreenTable">
                          <table>
                            <thead>
                              <tr class="BorderBottom">
                                <th colspan="12" class="py-3">
                                  {" "}
                                  Project Name &amp; # or Job Name &amp; #{" "}
                                  <br /> Revenues and Gross Margin Analysis
                                  Summary To - Date Report{" "}
                                </th>
                              </tr>
                              <tr>
                                <th colspan="2" class="text-left">
                                  Descriptions
                                </th>
                                <th colspan="2">Parking Stall/Pad Sales</th>
                                <th colspan="2">CTC</th>
                                <th colspan="2">Change Orders </th>
                                <th colspan="2">Contract </th>
                                <th colspan="2">Grand Total $’s &amp; %</th>
                              </tr>
                              <tr>
                                <th class="text-left">
                                  Job Completion Percentage
                                </th>
                                <th class="bg-white">75%</th>
                                <th colspan="10"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-left" colspan="2">
                                  Actual Revenues To - Date
                                </td>
                                <td>$ 3,000.00</td>
                                <td>100.0%</td>
                                <td>$ 7,000.00</td>
                                <td>100.0%</td>
                                <td>$ 5,000.00</td>
                                <td>100.0%</td>
                                <td>$ 155,000.00</td>
                                <td>100.0%</td>
                                <td>$ 170,000.00</td>
                                <td>100.0%</td>
                              </tr>
                        
                    
                            </tbody>
                            <tfoot>
                              <tr>
                                <td>Totals :</td>
                                <td>49</td>
                                <td>34</td>
                                <td>8</td>
                                <td>Totals:</td>
                                <td>49</td>
                                <td>34</td>
                                <td>8</td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                   
                      </div>}
                      </div>

                 
                  </div>
                </div>
              </div>
            </div>  )
}

export default Financial