


import Modal from "react-bootstrap/Modal";

const RealseFiles = ({
  RealseFilesModal,
  SetRealseFilesModal,
  SetFileMangerModal,
}) => {
  const handleRealseFilesModalClose = () => {
    SetRealseFilesModal(false);
    SetFileMangerModal(true);
  };
  return (
    <Modal
      show={RealseFilesModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleRealseFilesModalClose}
      >
        ×
      </a>
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Release Files</h4>
              </div>

              <div class="JobTitleArea">
                
                <h6>System File Manager</h6>
  
                <div class="FolderForm">
                  <div class="form-group mb-4">
                    <label class="Radio"> Sales
                      <input type="radio" name="Cuisine" value="Sales" checked="" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
  
                  <div class="form-group">
                    <label class="Radio"> Client View
                      <input type="radio" name="Cuisine" value="ClientView" />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="form-group">
                    <label class="Radio"> Trade View
                      <input type="radio" name="Cuisine" value="TradeView" />
                      <span class="checkmark"></span>
                    </label>
                  </div>

                  <div class="form-group">
                    <label class="Radio"> Estimation
                      <input type="radio" name="Cuisine" value="Estimation" />
                      <span class="checkmark"></span>
                    </label>
                  </div>

           
  
                  

                  <div class="TradeView RadioText">
                    <div class="form-group">
                      <h6>Vendor</h6>
                      <select class="form-control">
                        <option selected="">Select a vendor</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <h6>File Manager Folder</h6>
                    <select class="form-control">
                      <option selected="">Select a Folder</option>
                    </select>
                  </div>
                  <div class="FileCheckBoxTable">
                    <table>
                      <tbody>
                        <tr>
                          <td colspan="4">
                            <div class="form-group mb-0">
                              <div class="d-flex">
                                <h6 class="mr-4">All</h6>
                                <label class="CheckBox">
                                  <input type="checkbox" />
                                  <span class="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>File 1</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                          <td>File 5</td>
                          <td>
                            <label class="CheckBox">
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td>File 2</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                          <td>File 6</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                        </tr>
                        <tr>
                          <td>File 3</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                          <td>File 7</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                        </tr>
                        <tr>
                          <td>File 4</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                          <td>File 8</td>
                          <td>
                            <label class="CheckBox"> 
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                          </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="Button d-flex justify-content-between mt-5">
                    <button class="Create" onClick={handleRealseFilesModalClose} >Release Files</button>
                    <button class="Outline" onClick={handleRealseFilesModalClose}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>
  );
};

export default RealseFiles;
