import React, { useState } from 'react'
import CommonBuildingUnit from './commonBuildingUnit'
import CommonBuildingQuantities from './commonBuildingQuantities'
import CommonbuildingSummaryPreviwe from './commonbuildingSummaryPreviwe'

const CommonBuilding = ({  numBlocks,
  handleNumBlocksChange,
  buildings={buildings},
  handleBuildingFieldChange,
  foundationTypeData,
  commonBuildingQuantities,
                  unitList,
                  handleQuantityChange,
                  commonBuildingStep3List,
                  handleShowComponent
 }) => {
  const[show,setShow]=useState({
    commonBuildingUnit:true,
    commonBuildingQuanties:false,
    commonBuildingSummeryPreview:false
  })
  
  const handleShowComponents=(component)=>{
  setShow({
    commonBuildingUnit:component==="commonBuildingUnit",
    commonBuildingQuanties:component==="commonBuildingQuanties",
    commonBuildingSummeryPreview:component==="commonBuildingSummeryPreview",
  })
  }
  return (
    <div className="FolderFormCreateArea Proforma">
    <div className="StepBox Green">
      <ul>
      <li className={show.commonBuildingUnit ? "active" : ""} onClick={() => handleShowComponents('commonBuildingUnit')}>
        
          <span className="Icon">
           <img src={require("../../../assets/images/block_input.png")} />
          </span>
          <span className="Text">
            Common <br /> Buildings <br /> Units
          </span>
        </li>
        <li className={show.commonBuildingQuanties ? "active" : ""} onClick={() => handleShowComponents('commonBuildingQuanties')}>
        
          <span
            className="Icon FolderPermissionId"
            data-toggle="modal"
            data-target="#CreateProformaCommonBuildingsQuantities"
            data-dismiss="modal"
          >
           <img src={require("../../../assets/images/block_quantities.png")} />
          </span>
          <span className="Text">
            Common <br /> Buildings <br />
            Quantities
          </span>
        </li>
        <li className={show.commonBuildingSummeryPreview ? "active" : ""} onClick={() => handleShowComponents('commonBuildingSummeryPreview')}>
        
          <span
            className="Icon FolderPermissionId"
            data-toggle="modal"
            data-target="#CreateProformaCommonBuildingsSummaryPreview"
            data-dismiss="modal"
          >
           <img src={require("../../../assets/images/block_quantities.png")} />
          </span>
          <span className="Text">
            Common <br /> Buildings <br /> Summary <br /> Preview
          </span>
        </li>
      </ul>
    </div>
    <div className="FieldsMandatory">
      <h6>
        Fields with <span className="required">*</span> are
        mandatory
      </h6>
    </div>
    {
      show.commonBuildingUnit && <CommonBuildingUnit
      numBlocks={numBlocks}
      handleNumBlocksChange={handleNumBlocksChange}
      buildings={buildings}
      handleBuildingFieldChange={handleBuildingFieldChange}
      foundationTypeData={foundationTypeData}
      handleShowComponent={handleShowComponent}
      handleShowComponents={handleShowComponents}
      />
    }{
      show.commonBuildingQuanties && <CommonBuildingQuantities
      commonBuildingQuantities={commonBuildingQuantities}
                  unitList={unitList}
                  handleQuantityChange={handleQuantityChange}
                  handleShowComponents={handleShowComponents}
      />
    }

    {
      show.commonBuildingSummeryPreview && <CommonbuildingSummaryPreviwe 
      handleShowComponent={handleShowComponent}
      commonBuildingStep3List={commonBuildingStep3List}
      handleShowComponents={handleShowComponents}
      />
    }
  </div>
  )
}

export default CommonBuilding