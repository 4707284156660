import Modal from "react-bootstrap/Modal";
import SignaturePad from "react-signature-canvas";
import React, { useState, useEffect, useRef } from "react";


const initialState = {
  folderName: "",
  CreateFolderModal: false,
  ManageSignatueModal: false,
  signature: "",
  initial: "",
  errors: {},
  NewFileModal: false,
  fileFolderName: "",
  clientFile: "",
  DocumentationDeleteModal: false,
  docId: "",
  ShareClientDocumentationModal: false,
  emailBody: "",
  clientEmail: "",
  clientName: "",
  emails: [{ name: "", email: "" }],
  folderId: "",
  id: "",
  SendForApprovalModal: false,
  approverType: "",
  contactSelection: [{ name: "", email: "" }],
  UploadTemplateModal: false,
  templateFile: "",
  ViewDocumentationModal: false,
  viewDocumentData: "",
  ApproveDocumentationModal: false,
  FolderDeleteModal: false,
  EditDocumentationModal: false,
  content: "",
  viewDocumentEditData: "",
  RelaseTemplateModal: false,
  LockTemplateModal: false,
  FilesHistoryModal: false,
  DocumentKeyModal: false,
  documentSearch: "",
  JobId: "",
};

const ManageSignature = ({
  ManageSignatureModal,
  SetManageSignatureModal,
  SetFileMangerModal,
}) => {



  const signCanvas = useRef({});
  const intialCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    folderName,
    CreateFolderModal,
    ManageSignatueModal,
    signature,
    initial,
    errors,
    NewFileModal,
    fileFolderName,
    clientFile,
    DocumentationDeleteModal,
    docId,
    ShareClientDocumentationModal,
    emailBody,
    clientName,
    clientEmail,
    emails,
    folderId,
    id,
    SendForApprovalModal,
    approverType,
    contactSelection,
    UploadTemplateModal,
    templateFile,
    ViewDocumentationModal,
    ApproveDocumentationModal,
    FolderDeleteModal,
    EditDocumentationModal,
    viewDocumentData,
    content,
    viewDocumentEditData,
    RelaseTemplateModal,
    LockTemplateModal,
    FilesHistoryModal,
    DocumentKeyModal,
    documentSearch,
    JobId,
  } = iState;

  const handleManageSignatureModalClose = () => {
    SetManageSignatureModal(false);
    SetFileMangerModal(true);
  };


  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const clearInitials = () => {
    intialCanvas.current.clear();
  };
  return (
    <Modal
      show={ManageSignatureModal}
      className="ModalBox modal fade SmallModal show"
      style={{ display: "block" }}
    >
      <a
        class="CloseModal"
        data-toggle="modal"
        onClick={handleManageSignatureModalClose}
      >
        ×
      </a>
  
      <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                      <SignaturePad
                        ref={signCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.signatureError}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new Initials</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new Initials
                      </h5>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Initials</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={intialCanvas}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            initial: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearInitials}>
                        <img src="images/circle_minus.png" />
                      </a>
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors?.initialError}
                    </span>
                  </div>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleManageSignatureModalClose}>
                    Save
                  </button>
                  <button
                    className="Outline"

                    onClick={handleManageSignatureModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
    </Modal>
  );
};

export default ManageSignature;
