import { profileConstant } from "../actionTypes";
const initialState = {
    profileData: {},
    contactList:[],
  
    loader: false,
    error: {},
  };
  
  export default function profileReducer(
    state = initialState,
    { type, payload }
    
  ) {
    switch (type) {

      case profileConstant.GET_PROFILE_INITIATE:
        return { ...state, loader: true, profileData: {} };
  
      case profileConstant.GET_PROFILE_SUCCESS:
        return { ...state, loader: false, profileData: payload };
  
      case profileConstant.GET_PROFILE_FAILURE:
        return { ...state, loader: false, error: payload.details};

            
      case profileConstant.CONTACT_LIST_INITIATE:
        return { ...state, loader: true, contactList: [] };
  
      case profileConstant.CONTACT_LIST_SUCCESS:
        return { ...state, loader: false, contactList: payload };
  
      case profileConstant.CONTACT_LIST_FAILURE:
        return { ...state, loader: false, error: payload };


      

  
      default:
        return state;
    }
  }
  