import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";


const initialState = {
  buildingPermit: "",
  subdividision: "",
  specificationDoc: "",
  taxProfile: "",
  baseModel: "",
  homeType: "",
  defaultCurrency: "",
  costScheme: "",
  timeZone: "",
  accountSoftware: false,
  underParkade: false,
  jobAddress: "",
  lot: "",
  block: "",
  phase: "",
  city: "",
  zipCode: "",
  country: "",
  province: "",
  numOfBlocks: 0,
  blocks: [],
  numOfBuildings: 0,
  buildings: [],
  errorMsg: {},
};

const ProjectAsEntityStep7 = ({
  iState,
  UpdateState,
    ProjectAsEntityStep7Modal,
    SetProjectAsEntityStep1Modal,
    SetProjectAsEntityStep2Modal,
    SetProjectAsEntityStep3Modal,
    SetProjectAsEntityStep4Modal,
    SetProjectAsEntityStep5Modal,
    SetProjectAsEntityStep6Modal,
    SetProjectAsEntityStep7Modal,
    SetProjectAsEntityStep8Modal,
    setShow,
  
}) => {
  const handleProjectAsEntityStep7ModalClose = () => {
    setShow(true);
    SetProjectAsEntityStep7Modal(false);
  };
  const handleProjectAsEntityStep7ModalShow = () => {
    SetProjectAsEntityStep7Modal(true);
  };


  const {profileCategories,accoTransSett,costCodeStructure,commWorkflows,designSettings,jobModels, errorMsg} =iState;



  const handleChange = (e) => {
    const { name,checked } = e.target;
  
    UpdateState({
      ...iState, [name]:checked
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formIsValid = true

    if (formIsValid) {
      SetProjectAsEntityStep7Modal(false);
      SetProjectAsEntityStep8Modal(true);
    }
  };

  const handleCheck = (e) => {
    UpdateState({ ...iState, accountSoftware: e.target.value });
  };

  return (
    <>
      <Modal show={ProjectAsEntityStep7Modal} className="ModalBox LargeModal BgClass">
        <div>
          <a
            onClick={handleProjectAsEntityStep7ModalClose}
            class="CloseModal"
            data-toggle="modal"
          >
            ×
          </a>
          <div class="FormArea">
            <div class="ModalTitleBox">
              <h4>Create New Project as an Entity</h4>
            </div>
            <div class="StepBox">
              <ul>
                  <li class="active">
                      <span class="Icon"  onClick={()=> {SetProjectAsEntityStep1Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}}>
                          <img src={require("../../assets/images/step_1.png")} />
                      </span>
                      <span class="Text">Details</span>
                  </li>
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep2Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}}  class="Icon FolderPermissionId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityAddress" data-dismiss="modal">
                        <img src={require("../../assets/images/step_2.png")} />
                    </span>
                    <span class="Text">Address</span>
                </li>
                  <li onClick={()=> {SetProjectAsEntityStep3Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}} className="active">
                    <span class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityType" data-dismiss="modal">
                        <img src={require("../../assets/images/step_8.png")} />
                    </span>
                    <span class="Text">Type</span>
                </li>
                  <li className="active">
                      <span onClick={()=> {SetProjectAsEntityStep4Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntitySubscription" data-dismiss="modal">
                          <img  src={require("../../assets/images/subscription_step.png")}/>
                      </span>
                      <span class="Text">Subscription</span>
                  </li>
                  <li className="active">
                      <span onClick={()=> {SetProjectAsEntityStep5Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityTax" data-dismiss="modal">
                          <img src={require("../../assets/images/step_3.png")} />
                      </span>
                      <span class="Text">Accounting <br /> &amp; Tax</span>
                  </li> 
                  <li className="active">
                    <span onClick={()=> {SetProjectAsEntityStep6Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityNumber" data-dismiss="modal">
                        <img src={require("../../assets/images/step_5.png")} />
                    </span>
                    <span class="Text">Job Number</span>
                </li> 
                <li className="active">
                  <span  class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityInitial" data-dismiss="modal">
                      <img  src={require("../../assets/images/initial_settings.png")} />
                  </span>
                  <span class="Text">Initial <br />
                    Settings</span>
              </li> 
              <li>
                <span onClick={()=> {SetProjectAsEntityStep8Modal(true) 
                      SetProjectAsEntityStep7Modal(false)}} class="Icon FolderPermissioId" data-toggle="modal" data-target="#CreateNewProjectAsAnEntityConfirmation" data-dismiss="modal">
                    <img src={require("../../assets/images/step_7.png")} />
                </span>
                <span class="Text">Confirmation</span>
            </li> 
              </ul>
            </div>
            <div class="FolderForm">
              
            <form>
                <div class="FolderFormCreateArea">
                  <div class="FieldsMandatory">
                    <h6>Fields with <span class="required">*</span> are mandatory</h6>
                  </div>
                  <div class="row">

                    <div class="col-md-12">
                      <h6>Job Pattern Definition <span class="required">*</span></h6>
                    </div>
                    <div class="col-md-6">

                      <div class="form-group mb-2 ">
                        <label class="switch"> Accounting &amp; Transaction Settings
                          <input type="checkbox"  onChange={handleChange} name="accoTransSett"  checked={accoTransSett}  />
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div class="form-group mb-2 ">
                        <label class="switch"> Cost Code Structure
                          <input type="checkbox" onChange={handleChange} name="costCodeStructure"  checked={costCodeStructure} />
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div class="form-group mb-2 ">
                        <label class="switch"> Design Settings &amp; Templates
                          <input type="checkbox" onChange={handleChange} name="designSettings"  checked={designSettings} />
                          <span class="slider"></span>
                        </label>
                      </div>
                      <div class="form-group mb-2 ">
                        <label class="switch"> Job Models
                          <input type="checkbox" onChange={handleChange} name="jobModels"  checked={jobModels} />
                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-2 ">
                        <label class="switch"> Communication Workflows
                          <input type="checkbox" onChange={handleChange} name="commWorkflows"  checked={commWorkflows} />
                          <span class="slider"></span>
                        </label>
                      </div>

                    </div>

                  </div>
                </div>
             
                <div class="Button d-flex justify-content-between align-items-center px-5">
                  <button class="Outline" data-dismiss="modal"onClick={()=> {
                    SetProjectAsEntityStep7Modal(false)
                    SetProjectAsEntityStep6Modal(true)
                  }} >Back</button>
                  <button class="Create FolderPermissionId" data-toggle="modal" 
                    onClick={handleSubmit}
                  >Next</button>
                </div>  
                </form>
              
            </div>

          </div>
        </div>
      </Modal>

   
      
    </>
  );
};

export default ProjectAsEntityStep7;
