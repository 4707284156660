import { notificationConstantDK } from "../actionTypes";
const initialState = {
  loader: false,
notificationList:[],
notificationData:{},


};

export default function notificationReducerDk(state = initialState, { type, payload }) {
  switch (type) {
    case notificationConstantDK.GET_ALL_NOTIFICATION_INITIATE: {
        return {
          ...state,
          notificationList: [],
          loader:true
        };
      }
      case notificationConstantDK.GET_ALL_NOTIFICATION_SUCCESS: {
        return {
          ...state,
          notificationList: payload,
          loader:false
        };
      }
      case notificationConstantDK.GET_ALL_NOTIFICATION_FAILURE: {
        return {
          ...state,
          notificationList: payload.data,
          loader:false
        };
      }

     


      case notificationConstantDK.GET_SINGLE_NOTIFICATION_INITIATE: {
        return {
          ...state,
          notificationData: {},
          loader:true
        };
      }
      case notificationConstantDK.GET_SINGLE_NOTIFICATION_SUCCESS: {
        return {
          ...state,
          notificationData: payload,
          loader:false
        };
      }
      case notificationConstantDK.GET_SINGLE_NOTIFICATION_FAILURE: {
        return {
          ...state,
          notificationData: payload.data,
          loader:false
        };
      }

     

    

    default:
      return state;
  }
}




