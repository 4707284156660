import React from 'react';

export default function Theme(props) {
  return (
    
          <li
            onClick={() => {
                props.chooseTheme();
            }}
          >
          </li>
 
  );
}