import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getWorkOrderRequestItemList,
  workOrderInspection,
} from "../redux/action/warrantyAction";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { calculateDateDifference } from "../utils/uploadFile";
const TradeSuggestedDateModal = ({ id }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    multipleItems: false,
    sameDateForAll: true,
    UpdateReminderModal: false,
    day: "",
    week: "",
    itemId: null,
    inspectorName: "",
    inspectionDateSelectionModal: false,
    selectedDatesOfCalender: [],
    updatedSelectedTimes: [],
    inspectionDateSelection: false,
  });
  useEffect(() => {
    dispatch(getWorkOrderRequestItemList()).then((data) => {
      console.log({ data });
      setState((prev) => ({
        ...prev,
        itemId: data?.data?.data?.[0]?.id,
        inspectorName: data?.data?.data?.[0]?.inspectorUser?.name,
      }));
    });
  }, []);

  const {
    multipleItems,
    sameDateForAll,
    day,
    week,
    UpdateReminderModal,
    inspectorName,
    itemId,
    inspectionDateSelectionModal,
    selectedDatesOfCalender,
    updatedSelectedTimes,
    inspectionDateSelection,
  } = state;
  const [dates, setDates] = useState([
    { dateOption: "", fromTime: "", toTime: "" },
  ]);

  const handleAddItem = () => {
    const newDateItem = sameDateForAll
      ? dates[0]
      : { dateOption: "", fromTime: "", toTime: "" };
    setDates([...dates, { ...newDateItem }]);
  };

  const handleRemoveItem = (index) => {
    const newDates = [...dates];
    newDates.splice(index, 1);
    setDates(newDates);
  };

  const handleDateChange = (index, field, value) => {
    const newDates = [...dates];

    if (field === "fromTime" || field === "toTime") {
      const formattedTime = `${value}:00`;
      newDates[index][field] = formattedTime;
    } else {
      newDates[index][field] = value;
    }

    setDates(newDates);

    if (sameDateForAll) {
      setDates(
        newDates.map((item) => {
          if (field === "fromTime" || field === "toTime") {
            return { ...item, [field]: `${value}:00` };
          } else {
            return { ...item, [field]: value };
          }
        })
      );
    }
  };

  console.log();
  const handleSameDateForAll = () => {
    setState((prev) => ({
      ...prev,
      sameDateForAll: true,
    }));
    setDates(dates.map((item) => dates[0]));
  };
  console.log({ dates });
  const handleRequestConfirmation = () => {
    const types = week ? week : day;
    const reminderDates = calculateDateDifference(types);
    const data = {
      requestItemsType: multipleItems ? "Schedule multiple" : "Schedule single",
      suggestedDates: sameDateForAll ? "Same Dates" : "Individual Dates",
      sendToClient: true,
      sendToInspector: false,
      setReminder: reminderDates,
      items: [
        {
          RequestItemId: itemId,
          dates: dates,
        },
      ],
    };
    dispatch(workOrderInspection(id, data));
  };
  const handleUpdateReminderClose = () => {
    setState((prev) => ({
      ...prev,
      UpdateReminderModal: false,
    }));
  };
  const handleInspectionDateSelectionModalClose = () => {
    setState((prev) => ({
      ...prev,
      inspectionDateSelectionModal: false,
    }));
  };
  const handleInspectionDateSelectionModalShow = () => {
    setState((prev) => ({
      ...prev,
      inspectionDateSelectionModal: true,
    }));
    const selectedDatesOfCalender = dates
      .flatMap((item) => {
        if (item?.dateOption) {
          // Split date string and construct a new Date object with the correct format
          const [year, month, day] = item.dateOption.split("-");
          return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date constructor
        } else {
          console.error("item or item.dateOption is null or undefined:", item);
          return null; // Handle null or undefined item or item.dateOption
        }
      })
      .filter((date) => date !== null);
    const updatedSelectedTimes = dates.map((item) => {
      const fromHour = item.fromTime
        ? convertTo12HourFormat(item.fromTime)
        : null;
      const toHour = item.toTime ? convertTo12HourFormat(item.toTime) : null;
      return {
        fromHour,
        toHour,
      };
    });

    console.log(selectedDatesOfCalender);
    // Filter out null values
    setState((prev) => ({
      ...prev,
      selectedDatesOfCalender: selectedDatesOfCalender,
      updatedSelectedTimes: updatedSelectedTimes,
    }));
  };

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const period = +hours >= 12 ? "PM" : "AM";
    const adjustedHours = +hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };
  const handleReminderUpdate = () => {
    setState((prev) => ({
      ...prev,
      UpdateReminderModal: false,
    }));
  };
  const handleRemainderChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  const handleInspectionDateSelectionShow = () => {
    setState((prev) => ({
      ...prev,
      inspectionDateSelection: true,
    }));
  };
  const handleInspectionDateSelectionHide = () => {
    setState((prev) => ({
      ...prev,
      inspectionDateSelection: false,
    }));
  };
  return (
    <>
      <div className="FormArea">
        <div className="ModalTitleBox">
          <h4>Trades Day Selection</h4>
        </div>
        <div className="FolderForm">
          <form action="">
            <div className="row">
              <div className="col-lg-4">
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Request Items</h4>
                  <div className="RadioArea d-flex flex-column">
                    <div className="form-group mb-3">
                      <label className="Radio">
                        Schedule single item
                        <input
                          type="radio"
                          name="Schedule"
                          checked={!multipleItems}
                          onChange={() => {
                            setState((prev) => ({
                              ...prev,
                              multipleItems: false,
                            }));
                            setDates([dates[0]]);
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        Schedule multiple items
                        <input
                          type="radio"
                          name="Schedule"
                          checked={multipleItems}
                          onChange={() =>
                            setState((prev) => ({
                              ...prev,
                              multipleItems: true,
                            }))
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="ModalFilterAreaBox mb-3">
                  <h4 className="mb-4">Suggested Dates</h4>
                  <div className="RadioArea d-flex flex-column">
                    <div className="form-group mb-3">
                      <label className="Radio">
                        Same date for all items
                        <input
                          type="radio"
                          name="Dates"
                          checked={sameDateForAll}
                          onChange={() => handleSameDateForAll()}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        Individual dates for each item
                        <input
                          type="radio"
                          name="Dates"
                          checked={!sameDateForAll}
                          onChange={() =>
                            setState((prev) => ({
                              ...prev,
                              sameDateForAll: false,
                            }))
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {dates.map((item, index) => (
              <div className="row" key={index}>
                <div className="col-lg-3">
                  <div className="ModalFilterAreaBox">
                    <h4>
                      Item # {index + 1} - Leaking Sink
                      <label className="CheckBox">
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </h4>
                    <p>Inspector - {inspectorName}</p>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="ModalFilterAreaBox">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Date Option 1</label>
                          <input
                            type="date"
                            className="form-control"
                            value={item.dateOption}
                            onChange={(e) =>
                              handleDateChange(
                                index,
                                "dateOption",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>From</label>
                          <input
                            type="time"
                            className="form-control"
                            value={item.fromTime}
                            onChange={(e) =>
                              handleDateChange(
                                index,
                                "fromTime",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>To</label>
                          <input
                            type="time"
                            className="form-control"
                            value={item.toTime}
                            onChange={(e) =>
                              handleDateChange(index, "toTime", e.target.value)
                            }
                          />
                        </div>
                      </div>
                      {multipleItems && (
                        <>
                          <button
                            type="button"
                            className="col-lg-1 mr-2"
                            style={{
                              height: "43px",
                              alignSelf: "center",
                              borderRadius: "10px",
                              backgroundColor: "#FC3400",
                              border: "none",
                              color: "white",
                            }}
                            onClick={handleAddItem}
                          >
                            Add
                          </button>
                          {dates.length > 1 && (
                            <button
                              type="button"
                              className="col-lg-1 ml-2"
                              style={{
                                height: "43px",
                                alignSelf: "center",
                                borderRadius: "10px",
                                backgroundColor: "#FC3400",
                                border: "none",
                                color: "white",
                              }}
                              onClick={() => handleRemoveItem(index)}
                            >
                              Remove
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="row">
              <div className="col-lg-8 ml-auto">
                <div className="CommonModalArea">
                  <div className="form-group">
                    <h5>Set Reminder</h5>
                    <div className="CommonModalBox">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            UpdateReminderModal: true,
                          }));
                        }}
                      >
                        <figure>
                          <img src="images/timer-red.png" alt="Reminder" />
                        </figure>
                        Reminder
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="Button mt-2">
                  <button
                    type="button"
                    className="Create"
                    onClick={handleRequestConfirmation}
                  >
                    Request Confirmation
                  </button>
                </div>
              </div>
              <div className="col-md-8">
                <div className="Button mt-2">
                  <button
                    type="button"
                    className="Create FolderPermissionId"
                    onClick={handleInspectionDateSelectionModalShow}
                  >
                    Preview Calendar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={UpdateReminderModal}
        onHide={handleUpdateReminderClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleUpdateReminderClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleRemainderChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleRemainderChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderUpdate}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={inspectionDateSelectionModal}
        onHide={handleInspectionDateSelectionModalClose}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleInspectionDateSelectionModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Inspection Dates Selection</h4>
                </div>
                <div className="FolderForm">
                  <h3>Work Order #{id}</h3>
                  <form>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="CalendarLeft">
                          <h6 className="Heading">Inspection Scheduling</h6>
                          <div className="ClientsDetails">
                            <p>Client Details</p>
                            <h6 className="ClientName">Luke Anderson</h6>
                            <p className="ClientEmail">
                              linden@ibuildapplications.com
                            </p>
                            <p className="ClientNumber">4034005933</p>
                          </div>
                          <div className="ClientLocation">
                            <p>
                              Location <img src="images/location.png" />
                            </p>
                            <p className="ClientAddress">
                              #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                              Calgary
                            </p>
                          </div>
                          <div className="ItemArea">
                            <h6>Items</h6>
                            <div className="ItemAreaBox ItemAreaBoxBlue">
                              <h6>#1 - Leaking Sink</h6>
                              <h6>Inspector - {inspectorName}</h6>
                            </div>
                            <div className="ItemAreaBox ItemAreaBoxGreen">
                              <h6>#2 - Faucet Problem</h6>
                              <h6>Inspector - {inspectorName}</h6>
                            </div>
                          </div>
                          <div className="ItemArea mb-0">
                            <h6>Selected Dates</h6>
                            <div className="ItemAreaBox ItemAreaBoxBlue">
                              <h6>#1 - Leaking Sink</h6>
                              <h6>Inspector - {inspectorName}</h6>
                            </div>
                          </div>
                          <div className="ClientLocation">
                            <p>Option 1</p>
                            <p className="ClientAddress">
                              02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                              Mountain Time - US &amp; Canada
                            </p>
                          </div>
                          <div className="ClientLocation">
                            <p>Option 2</p>
                            <p className="ClientAddress">
                              02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                              Mountain Time - US &amp; Canada
                            </p>
                          </div>
                          <div className="ItemArea">
                            <div className="ItemAreaBox ItemAreaBoxGreen">
                              <h6>#2 - Faucet Problem</h6>
                              <h6>Inspector - {inspectorName}</h6>
                            </div>
                          </div>
                          <div className="ClientLocation">
                            <p>Option 1</p>
                            <p className="ClientAddress">
                              02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                              Mountain Time - US &amp; Canada
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="AppointmentCalendar d-flex">
                          {selectedDatesOfCalender?.length > 0 && (
                            <DatePicker
                              // selected={new Date()} // Set to null to avoid selecting a default date
                              // onChange={date => handleDateChange(date)}
                              inline
                              selectsRange
                              dateFormat="yyyy/mm/dd"
                              placeholderText="Select dates"
                              highlightDates={selectedDatesOfCalender}
                            />
                          )}
                        </div>
                      </div>
                      <div className="CalendarRight CalendarRightGreen col-md-1">
                        <h6>Thursday, August 10</h6>
                        <div className="d-flex flex-column justify-content-between">
                          {updatedSelectedTimes.length > 0 &&
                            updatedSelectedTimes.map((item) => (
                              <button
                                className="GreenBrandBtn"
                                style={{
                                  border: "1px solid #55813E",
                                  borderRadius: "10px",
                                  backgroundColor: "transparent",
                                  margin: "10px 0",
                                  padding: "10px 15px",
                                  fontWeight: "400",
                                  color: "#55813E",
                                }}
                              >
                                {item.fromHour} - {item.toHour}
                              </button>
                            ))}
                          <button
                            className="GreenBrandBtn"
                            style={{
                              border: "1px solid #55813E",
                              borderRadius: "10px",
                              backgroundColor: "#55813E",
                              margin: "10px 0",
                              padding: "10px 15px",
                              fontWeight: "400",
                              color: "#fff",
                            }}
                          >
                            Next
                          </button>
                        </div>
                        <button
                          className="GreenBrandBtn FolderPermissionId"
                          style={{
                            border: "1px solid #55813E",
                            borderRadius: "10px",
                            backgroundColor: "#55813E",
                            margin: "10px 0",
                            padding: "10px 15px",
                            fontWeight: "400",
                            color: "#fff",
                          }}
                          onClick={handleInspectionDateSelectionShow}
                        >
                          Finish Selection
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={inspectionDateSelection}
        onHide={handleInspectionDateSelectionHide}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleInspectionDateSelectionHide}
              >
                ×
              </a>
              <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>Inspection Dates Selection</h4>
  </div>
  <div className="FolderForm">
    <h3>Work Order #{id}</h3>
    <form>
      <div className="row">
        <div className="col-lg-4">
          <div className="CalendarLeft">
            <h6 className="Heading">Inspection Scheduling</h6>
            <div className="ClientsDetails">
              <p>Client Details</p>
              <h6 className="ClientName">Luke Anderson</h6>
              <p className="ClientEmail">linden@ibuildapplications.com</p>
              <p className="ClientNumber">4034005933</p>
            </div>
            <div className="ClientLocation">
              <p>
                Location <img src="images/location.png" />
              </p>
              <p className="ClientAddress">
                #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate, Calgary
              </p>
            </div>
            <div className="ItemArea">
              <h6>Items</h6>
              <div className="ItemAreaBox ItemAreaBoxBlue">
                <h6>#1 - Leaking Sink</h6>
                <h6>Inspector - {inspectorName}</h6>
              </div>
              <div className="ItemAreaBox ItemAreaBoxGreen">
                <h6>#2 - Faucet Problem</h6>
                <h6>Inspector - {inspectorName}</h6>
              </div>
            </div>
            <div className="ItemArea mb-0">
              <h6>Selected Dates</h6>
              <div className="ItemAreaBox ItemAreaBoxBlue">
                <h6>#1 - Leaking Sink</h6>
                <h6>Inspector - {inspectorName}</h6>
              </div>
            </div>
            <div className="ClientLocation">
              <p>Option 1</p>
              <p className="ClientAddress">
                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br /> Mountain Time
                - US &amp; Canada
              </p>
            </div>
            <div className="ClientLocation">
              <p>Option 2</p>
              <p className="ClientAddress">
                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br /> Mountain Time
                - US &amp; Canada
              </p>
            </div>
            <div className="ItemArea">
              <div className="ItemAreaBox ItemAreaBoxGreen">
                <h6>#2 - Faucet Problem</h6>
                <h6>Inspector - {inspectorName}</h6>
              </div>
            </div>
            <div className="ClientLocation">
              <p>Option 1</p>
              <p className="ClientAddress">
                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br /> Mountain Time
                - US &amp; Canada
              </p>
            </div>
            <div className="ClientLocation">
              <p>Option 2</p>
              <p className="ClientAddress">
                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br /> Mountain Time
                - US &amp; Canada
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </form>
  </div>
</div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TradeSuggestedDateModal;
