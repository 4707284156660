import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  addUserUrgency,
  addWarranty,
  craeteNewItemType,
  craeteNewServiceType,
  createInspector,
  createRequestItem,
  getAllItemTypeList,
  getAllRequestItemList,
  getAllServiceTypeList,
  getAllSystemItemTypeList,
  getAllSystemServiceTypeList,
  getAllSystemUrgencyList,
  getAllUrgencyList,
  getAllUserItemTypeList,
  getAllUserServiceTypeList,
  getAllUserUrgencyList,
  getClearTableData,
  getDeleteRequestItem,
  getDeleteServiceType,
  getDeleteUrgency,
  getDraftWarranty,
  getInspectorList,
  getPrimaryClientByJobs,
  getWarrantyEditableByClient,
  getWarrantySupportDocsUpdate,
  getWarrantyUpdate,
  updateWarrantyItem,
} from "../redux/action/warrantyAction";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
  AddNotesModal: false,
  message: "",
  supportingDocFiles: null,
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  AddNewUrgencyModal: false,

  name: "",
  actionId: "",
  showToggleSetting: false,
  ConfigServiceTypesModal: false,

  description: "",
  serviceType: "",
  itemStatus: "",
  inspector: "",
  dateInspected: "",
  ItemDeleteModal: false,
  reqId: "",
  AddItemDocsModal: false,
  itemDocs: "",
  AddItemStatusModal: false,
  AddNewServiceTypeModal: false,
  UserServiceDeleteModal: false,
  ConfigItemTypesModal: false,
  ConfigUrgencyModal: false,
  ConfigUrgencyDeleteModal: false,

  descriptions: "",
  requestDate: "",
  UrgencyId: "",
  SendMailModal: false,
  fullName: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  phonecode:"",
  inspectorName: "",
  serviceName: "",
  itemStatusName: "",
  errors: {},
  itemId:"",
};

const Warranty_Request_work_order_table = () => {

  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [iState, updateState] = useState(initialState);
  const {
    AddNotesModal,
    message,
    supportingDocFiles,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    AddNewUrgencyModal,
    name,
    actionId,
    showToggleSetting,
    ConfigServiceTypesModal,

    description,
    serviceType,
    itemStatus,
    inspector,
    dateInspected,
    ItemDeleteModal,
    reqId,
    AddItemDocsModal,
    itemDocs,
    AddItemStatusModal,
    AddNewServiceTypeModal,
    UserServiceDeleteModal,
    ConfigItemTypesModal,
    ConfigUrgencyModal,
    ConfigUrgencyDeleteModal,
    descriptions,
    requestDate,
    UrgencyId,

    SendMailModal,
    fullName,
    companyName,
    phoneNumber,
    email,
    phonecode,
    inspectorName,
  serviceName,
  itemStatusName,
    errors,
    itemId,
  } = iState;

  const {
    draftWarranty,
    allSystemUrgencyList,
    allUrgencyList,
    allRequestItemList,
    allServiceTypeList,
    allItemTypeList,
    allSystemServiceTypeList,
    allUserServiceTypeList,
    allSystemItemTypeList,
    allUserItemTypeList,
    allUserUrgencyList,
    inspectorList,
    primaryClientsDatas,
  } = useSelector((state) => state.warrantyReducer);


  console.log("draftWarranty", draftWarranty);

  const { state } = useLocation();
  console.log( {state});
  useEffect(()=>{
    setSelectedOption(state?.notes?.[0]?.noteType)
    updateState(prev=>({
      ...prev,
      descriptions:state?.description,
      supportingDocFiless:state?.SupportDocs?.[0],
      UrgencyId:state?.urgency?.id,
      supportingDocFiles:state?.SupportDocs?.[0]?.fileName,
      message:state?.notes?.[0]?.message
        }))
  },[state])
 
  useEffect(() => {
    dispatch(getDraftWarranty(state?.ProjectId, state?.id, state?.JobType));
    dispatch(getAllSystemUrgencyList());
    dispatch(getAllUrgencyList());
    dispatch(getAllServiceTypeList());
    dispatch(getAllItemTypeList());
    dispatch(getAllSystemServiceTypeList());
    dispatch(getAllUserServiceTypeList());
    dispatch(getAllSystemItemTypeList());
    dispatch(getAllUserItemTypeList());
    dispatch(getAllUserUrgencyList());
    dispatch(getInspectorList());
    dispatch(getPrimaryClientByJobs({id: state?.id,jobType: state?.jobType,isPrimary: true}));
    
  }, []);

  useEffect(() => {
    if (state?.id) {
      dispatch(getAllRequestItemList(state?.id));
    
    }
  }, [dispatch, draftWarranty]);

  useEffect(() => {
    if (!_.isEmpty(state)) {
      const updateData = _.cloneDeep(iState);
      updateData.requestDate =moment(state?.requestDate).format("YYYY-MM-DD");
      updateData.phonecode =state?.phonecode
      updateData.descriptions =state?.description
      updateData.UrgencyId =state?.UrgencyId
   

      updateState(updateData);
    }

  }, [state]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const modifiedValue = value.slice(0, 1000);
    updateState({
      ...iState,
      [name]: modifiedValue,
    });
  };

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    if(name==="serviceType"){
      let serviceData=allServiceTypeList?.warrantyOptions?.find((item)=>item.id==value);
      updateState({
        ...iState,
        [name]: value,
        serviceName:serviceData?.name
      })
    }
    else if (name==="itemStatus"){
      let itemStatusData=allItemTypeList?.warrantyOptions?.find((item)=>item.id==value);
      updateState({
        ...iState,
        [name]: value,
        itemStatusName:itemStatusData?.name
      });
    }
      else if (name==="inspector"){
        let inspectorData=inspectorList?.approverList?.find((item)=>item.id==value);
        updateState({
          ...iState,
          [name]: value,
          inspectorName:inspectorData?.name
        });
      }
    else{
      updateState({
        ...iState,
        [name]: value,
      });
    }
   
  };

  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "text");
    setFileData(value === "file");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "itemDoc") {
      updateState({ ...iState, itemDocs: file });
    } 
    else {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleAddNotes = () => {
    let dataa;
    if (textInput) {
      dataa = {
        warrantyNotes: [{ noteType: "text", message: message }],
        description: state?.description
          ? state?.description
          : "N/A",
        // jobType:state?.jobType,
        // jobId:state?.id,
      };
    } else {
      dataa = {
        warrantyNotes: [{ noteType: "files" }],
        supportingDocFiles,
        description: state?.description
          ? state?.description
          : "N/A",
        // jobType:state?.jobType,
        // jobId:state?.id,
      };
    }

    dispatch(addWarranty(state?.id, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Add Notes Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNotesModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNotesModal: true,
        });
      }
    });
  };

  const handleAddNewSupportingDocsModalShow = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
    });
  };

  const handleSupportDocs = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiles,
    });
  };

  const handleAddItemDocsModalShow = () => {
    updateState({
      ...iState,
      AddItemDocsModal: true,
    });
  };
  const handleAddItemDocsModalClose = () => {
    updateState({
      ...iState,
      AddItemDocsModal: false,
    });
  };

  const handleItemDocs = () => {
    let dataa = {
      supportingDocFiles: itemDocs,
    };

    dispatch(
      getWarrantySupportDocsUpdate(state?.id, dataa)
    ).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddItemDocsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddItemDocsModal: true,
        });
      }
    });
  };

  const handleAddNewUrgencyModalShow = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: true,
    });
  };
  const handleAddNewUrgencyModalClose = () => {
    updateState({
      ...iState,
      AddNewUrgencyModal: false,
    });
  };

  const handleurgencyAdd = () => {
    let dataa = { name, definedType: "User", configType: "Urgency", actionId };
    dispatch(addUserUrgency(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: false,
          name: "",
          actionId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewUrgencyModal: true,
        });
      }
    });
  };

  const handleToggleSetting = () => {
    updateState({ ...iState, showToggleSetting: !showToggleSetting });
  };

  const handleToggleSettingClose = () => {
    updateState({ ...iState, showToggleSetting: false });
  };

  const handleAConfigServiceTypesModalShow = () => {
    updateState({
      ...iState,
      ConfigServiceTypesModal: true,
    });
  };
  const handleAConfigServiceTypesModalClose = () => {
    updateState({
      ...iState,
      ConfigServiceTypesModal: false,
    });
  };

  const handleAddItem = () => {
    const dataa = {
      description,
      serviceType,
      itemStatus,
      inspector,
      dateInspected,
    };
  
    const filteredData = Object.fromEntries(
      Object.entries(dataa).filter(([_, value]) => value !== "")
    );
  
    dispatch(createRequestItem(Number(state?.id), filteredData)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getAllRequestItemList(state?.id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            serviceType: "",
            itemStatus: "",
            inspector: "",
            dateInspected: "",
            description: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };
  

  const handleClearTable = () => {
    dispatch(getClearTableData(Number(state?.id))).then(
      (res) => {
        if (res.status === 200) {
          dispatch(getAllRequestItemList(draftWarranty?.warranty?.id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const handleFolderDeleteShow = (id) => {
    updateState({
      ...iState,
      ItemDeleteModal: true,
      reqId: id,
    });
  };

  const handleItemDeleteClose = () => {
    updateState({
      ...iState,
      ItemDeleteModal: false,
      reqId: "",
    });
  };

  const handleFolderDelete = () => {
    let dataa = { reqId };
    dispatch(
      getDeleteRequestItem(Number(state?.id), dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAllRequestItemList(state?.id));
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ItemDeleteModal: false,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ItemDeleteModal: true,
        });
      }
    });
  };

  const handleCheckboxChange = () => {
    const datta = state?.editByClient === true ? false : true;
    dispatch(
      getWarrantyEditableByClient(Number(state?.id), datta)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getDraftWarranty(state?.ProjectId, state?.id, state?.JobType));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleUrgencyClick = (id) => {
    updateState({ ...iState, UrgencyId: id });
  };

  const handleSaveAndEmail = async (type) => {
    
    try {
      const baseData = {
        description: descriptions,
        requestDate,
        UrgencyId,
        sendEmail: type === "email" ? true : false,
        JobId: state?.id,
        JobType: state?.JobType,
        phonecode,
        supportingDocFiles: supportingDocFiless,
       
      };

      const datta = {
        ...baseData,
        warrantyNotes: textInput
          ? [{ noteType: "text", message: message }]
          : [{ noteType: "files",files:supportingDocFiles }],
        description: draftWarranty?.warranty?.description
          ? draftWarranty?.warranty?.description
          : "N/A",
      };

      const res = await dispatch(
        getWarrantyUpdate(Number(state?.id), datta)
      );
      if (res && res.status === 200) {
        const selectedState = {
          id: state?.jobId,
          jobType:state?.JobType,
          ProjectId:state?.ProjectId,
          jobNumber:state?.jobNumber
        };
        navigate("/warranty-dashboard",{state:selectedState});
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState(initialState);
      } else {
        toast.error(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAddItemStatusModalShow = () => {
    updateState({
      ...iState,
      AddItemStatusModal: true,
    });
  };
  const handleAddItemStatusModalClose = () => {
    updateState({
      ...iState,
      AddItemStatusModal: false,
    });
  };

  const handleItemAddStatus = () => {
    let dataa = { name, definedType: "User", configType: "Item", actionId };
    dispatch(craeteNewItemType(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddItemStatusModal: false,
          name: "",
          actionId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddItemStatusModal: true,
        });
      }
    });
  };
  const handleAddNewServiceTypeModalShow = () => {
    updateState({
      ...iState,
      AddNewServiceTypeModal: true,
    });
  };
  const handleAddNewServiceTypeModalClose = () => {
    updateState({
      ...iState,
      AddNewServiceTypeModal: false,
    });
  };

  const handleServiceAddStatus = () => {
    let dataa = { name, definedType: "User", configType: "Service", actionId };
    dispatch(craeteNewServiceType(dataa)).then((res) => {
      if (res.status === 201) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AddNewServiceTypeModal: false,
          name: "",
          actionId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          AddNewServiceTypeModal: true,
        });
      }
    });
  };

  const handleUserServiceDeleteModalShow = (id) => {
    updateState({
      ...iState,
      UserServiceDeleteModal: true,
      ConfigServiceTypesModal: false,
      reqId: id,
    });
  };

  const handleUserServiceDeleteModalClose = () => {
    updateState({
      ...iState,
      UserServiceDeleteModal: false,
      ConfigServiceTypesModal: true,
      reqId: "",
    });
  };

  const handleUserServiceDelete = () => {
    let dataa = { reqId };
    dispatch(getDeleteServiceType(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllUserServiceTypeList());
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          UserServiceDeleteModal: false,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          UserServiceDeleteModal: true,
        });
      }
    });
  };

  const handleConfigItemTypesModalShow = () => {
    updateState({
      ...iState,
      ConfigItemTypesModal: true,
    });
  };
  const handleConfigItemTypesModalClose = () => {
    updateState({
      ...iState,
      ConfigItemTypesModal: false,
    });
  };

  const handleConfigUrgencyModalShow = () => {
    updateState({
      ...iState,
      ConfigUrgencyModal: true,
    });
  };

  const handleConfigUrgencyModalClose = () => {
    updateState({
      ...iState,
      ConfigUrgencyModal: false,
    });
  };

  const handleUrgencyDeleteModalShow = (id) => {
    updateState({
      ...iState,
      ConfigUrgencyDeleteModal: true,
      ConfigUrgencyModal: false,
      reqId: id,
    });
  };

  const handleUrgencyDeleteModalClose = () => {
    updateState({
      ...iState,
      ConfigUrgencyDeleteModal: false,
      ConfigUrgencyModal: true,
      reqId: "",
    });
  };

  const handleUrgencyDelete = () => {
    let dataa = { reqId };
    dispatch(getDeleteUrgency(dataa)).then((res) => {
      if (res.status === 200) {
        dispatch(getAllUserUrgencyList());
        toast.success(" Delete Folder Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          ConfigUrgencyDeleteModal: false,
          ConfigUrgencyModal: true,
          reqId: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          ConfigUrgencyDeleteModal: true,
        });
      }
    });
  };

  const handleSendMailModalShow = () => {
    updateState({ ...iState, SendMailModal: true });
  };

  const handleSendMailModalClose = () => {
    updateState({ ...iState, SendMailModal: false });
  };

  const handleSendMail = () => {
    let dataa = {
      fullName,
      companyName,
      phoneNumber,
      email,
      };
    dispatch(createInspector(state?.id, dataa)).then((res) => {
      if (res.status === 200) {
        toast.success("Add Notes Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          SendMailModal: false,
          fullName:"",
          companyName:"",
          phoneNumber:"",
          email:"",
        });
      } 
      else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          SendMailModal: true,
        });
      }
    });
  };

  const handlePhoneInputChange = (value,country) => {
    updateState({
      ...iState,
      phonecode: `+${country.dialCode}`,
    });
  };
  let handleValidation = () => {
    let error = {};
    let formIsValid = true;
    if (!requestDate) {
      error.requestDateError = " * Request Date can't be empty";
      formIsValid = false;
    }

    if (!descriptions || !descriptions.trim()) {
      error.descError = " * Description can't be empty";
      formIsValid = false;
    }
    if (!phonecode) {
      error.phonecodeError = " * countrycode  can't be empty";
      formIsValid = false;
    }
    if (!UrgencyId) {
      error.UrgencyIdError = " * Urgency can't be empty";
      formIsValid = false;
    }
    if (!message  || !message .trim()) {
      error.messageError = "  Add Notes  can't be empty";
      formIsValid = false;
    }
    if (!supportingDocFiless) {
      error.supportingDocFilesError = " * supportingDocFiles  can't be empty";
      formIsValid = false;
    }
  

   
    updateState({
      ...iState,
      errors: error,
    });
    return formIsValid;
  };

  const handleSetEdit=(data)=>{
    updateState({...iState,
      description:data.description,
      serviceType:data.serviceType,
      itemStatus:data.itemStatus,
      inspector:data.inspector,
      itemId:data?.id,
      dateInspected:moment(data.dateInspected).format("YYYY-MM-DD"),
    })
  }
  

  const handleUpdateItem = () => {
    const dataa = {
      description,
      serviceType,
      itemStatus,
      inspector,
      dateInspected,
      inspectorName,
      serviceName,
      itemStatusName,
    };

    const filteredData = Object.fromEntries(
      Object.entries(dataa).filter(([_, value]) => value !== "")
    );

    dispatch(
      updateWarrantyItem(Number(state?.id),itemId, filteredData)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(getAllRequestItemList(state?.id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          serviceType: "",
          itemStatus: "",
          inspector: "",
          dateInspected: "",
          description: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
    <Header />
    <SideNav />
    <div className="WrapperArea">
      <div className="WrapperBox pb-5">
        <div className="MainTitleBox">
          <div className="MainTitle">
            <h2>Edit Warranty Request</h2>
          </div>
          <div className="TodosButtons">
            <a onClick={handleAddNotesShow}>
              <h6 data-toggle="modal">
                <img src="images/add-notes-1.png" />
                Edit Notes
              </h6>{" "}
              <span data-toggle="modal" data-target="#NotesList2">
                3
              </span>
            </a>
            <a onClick={handleAddNewSupportingDocsModalShow}>
              <h6 data-toggle="modal">
                <img src="images/add-docuement-1.png" />
                Edit Supporting documents
              </h6>
              <span data-toggle="modal" data-target="#SupportingDocumentList">
                3
              </span>
            </a>
          </div>
        </div>
        <div className="TitleBox">
          <h4 className="Title">Warranty Request #{state?.id}</h4>
          <div className="TitleBox2 TitleBoxRight">
            <div className="dropdown">
            <h6 data-toggle="dropdown">{state?.jobNumber ?? "N/A"}</h6>
                <span>{state?.JobType ?? "N/A"}</span>
               {/* <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a className="dropdown-item" href="#">Select a job</a>
        <a className="dropdown-item" href="#">
          <input type="text" placeholder="-Search">
          <span><img src="images/search.png"></span>
        </a>
        <a className="dropdown-item" href="#">List of jobs loaded from the system</a>
      </div> */}
            </div>
            {/* <a href="#">
              <img src="images/icon-61.png" />
            </a>
            <a href="#" className="mx-3">
              <img src="images/icon-62.png" />
            </a>
            <a onClick={() => handleToggleSetting()}>
              <img src="images/Icon-4.png" />
            </a> */}
          </div>
        </div>
        <div className="WarrantyDashboardForm mb-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Description <span className="Required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Write here ....."
                      name="descriptions"
                      value={descriptions}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WarrantyDashboardForm">
          <h4>Client Details</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Possession Date</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      disabled
                      value={primaryClientsDatas?.clientsDetails?.possessionDate ?? "N/A"}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Request Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      name="requestDate"
                      value={requestDate}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Client Full Legal Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      disabled
                      value={
                        primaryClientsDatas?.clientsDetails?.legalName ?? "N/A"
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <label>Country Code</label>
                  <PhoneInput inputClass="CountryCodeInput"
                    countryCodeEditable={false}
                    enableSearch
                    value={phonecode}
                    onChange={handlePhoneInputChange}
                  />
                               
                </div>
              </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      disabled
                      value={primaryClientsDatas?.clientsDetails?.primaryPhoneNum ?? "N/A"}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Primary Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      disabled
                      value={primaryClientsDatas?.clientsDetails?.email ?? "N/A"}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Job Address{" "}
                      <img src="images/location.png" className="ml-2" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                      disabled
                      value={primaryClientsDatas?.clientsDetails?.streetAddress ?? "N/A"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="UrgencyArea">
                <div className="form-group">
                  <h6>Urgency</h6>
                  <div className="Categories Categories2">
                    <div className="Categories_all Categories_all2">
                      <span>Select an Option</span>

                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li onClick={handleAddNewUrgencyModalShow}>
                        <span className="AddNew">+</span>Add New
                      </li>
                      {allUrgencyList?.warrantyOptions?.length > 0
                        ? allUrgencyList?.warrantyOptions?.map((item, i) => (
                            <li
                              // value={item.id}
                              key={i}
                              onClick={() => handleUrgencyClick(item.id)}
                              style={{
                                backgroundColor:
                                  UrgencyId == item.id
                                    ? "#e71e09"
                                    : "transparent",
                              }}
                            >
                              {item.name}
                            </li>
                          ))
                        : "No data found"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="EditableByClient">
          <div className="form-group">
            <label className="switch">
              <input
                type="checkbox"
                checked={state?.editByClient == true}
                onChange={handleCheckboxChange}
              />
              <span className="slider" />
            </label>
            <h6>Editable by client</h6>
          </div>
        </div>
        <div className="TableList NewTableList TableListHeader TableHeightScroll TableCommonSelect TableCommonSelectNew ">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Description</th>
                <th>Service Type</th>
                <th>Work Order #</th>
                <th>Item Status</th>
                <th>Inspector</th>
                <th>Date Inspected</th>
                <th>Supporting Doc</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {allRequestItemList?.data?.length > 0
                  ? allRequestItemList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.id}</td>
                          <td>{item.description}</td>
                          <td>
                            {item.serviceTypeOption?.name ?? "N/A"}
                          </td>
                          <td>{item?.workOrderNumber ?? 0}</td>
                          <td>  {item.itemStatusOption?.name ?? "N/A"}</td>
                          <td>
                            {item.inspectorUser?.name ?? "N/A"}
                          </td>

                          <td>
                            {moment(item.dateInspected).format("YYYY-MM-DD")}
                          </td>

                          <td>
                            <div className="Actions">
                              <a onClick={handleAddItemDocsModalShow}>
                                <img src="images/icon-63.png" />
                              </a>
                            </div>
                          </td>
                          <td>
                            <div className="DetailsDots">
                              <div className="TableThreeDots">
                                <ul className="">
                                  <li className="dropdown">
                                    <a
                                      className=""
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <img src="images/dots.png" />
                                    </a>
                                    <ol className="dropdown-menu">
                                      {/* {draftWarranty?.warranty?.editByClient ==
                                        false && ( */}
                                        <li>
                                          <a href="javascript:void(0);" onClick={()=>handleSetEdit(item)}>
                                            <img src="images/Action-1.png" />
                                            Edit
                                          </a>
                                        </li>
                                      {/* )} */}

                                      <li>
                                        <a
                                          onClick={() =>
                                            handleFolderDeleteShow(item.id)
                                          }
                                        >
                                          <img src="images/dropdown-delete.png" />
                                          Delete
                                        </a>
                                      </li>
                                    </ol>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : // <div className="text-center w-150">
                    //   <h6>No data found</h6>
                    // </div>
                    ""}

                <tr>
                  <td></td>
                  <td>
                    <div className="form-group">
                      <h6>Description </h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter description"
                        name="description"
                        value={description}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="serviceType"
                          value={serviceType}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Service Type</option>
                          {allServiceTypeList?.warrantyOptions?.length > 0 &&
                            allServiceTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                return (
                                  <option key={ind} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        {/* <span>
                          <img src="images/down.png" />
                        </span> */}
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="itemStatus"
                          value={itemStatus}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Item Status</option>
                          {allItemTypeList?.warrantyOptions?.length > 0 &&
                            allItemTypeList?.warrantyOptions?.map(
                              (data, ind) => {
                                return (
                                  <option key={ind} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              }
                            )}
                        </select>
                        {/* <span>
                        <img src="images/down.png" />
                      </span> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <h6>&nbsp;</h6>
                      <div class="CommonSelectBox">
                        <select
                          name="inspector"
                          value={inspector}
                          onChange={handleInputChanges}
                        >
                          <option value="">Select Inspector Name</option>
                          {inspectorList?.approverList?.length > 0 &&
                            inspectorList?.approverList?.map((data, ind) => {
                              return (
                                <option key={ind} value={data.id}>
                                  {data.name}
                                </option>
                              );
                            })}
                        </select>
                        {/* <span>
                        <img src="images/down.png" />
                      </span> */}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <h6>Date Inspected </h6>
                      <input
                        type="date"
                        className="form-control"
                        name="dateInspected"
                        value={dateInspected}
                        onChange={handleInputChanges}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="Actions">
                      <a onClick={handleAddItemDocsModalShow}>
                        <img src="images/icon-63.png" />
                      </a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan={9}>
                    <div className="TableButtons">
                      <button className="BrandBtn mr-2" onClick={handleAddItem}>
                        Add Item
                      </button>
                      <button className="BrandBtn mr-2" onClick={handleClearTable}>
                        Clear Table
                      </button>
                      <button className="BrandBtn" onClick={handleUpdateItem}>
                      Update Item
                      </button>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-between mt-3 pb-5">
          <button className="BrandBtn">Cancel</button>
          <div className="d-flex ">
            <button
              className="BrandBtnGreen"
              onClick={() => handleSaveAndEmail("save")}
            >
              Update
            </button>
            <div className="dropdown ml-3">
              <button
                className="BrandBtnGreen"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Update and <img src="images/down-arrow-white.png" />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a className="dropdown-item">Edit and Print</a>
                <a
                  className="dropdown-item"
                  onClick={() => handleSaveAndEmail("email")}
                >
                  Edit and E-mail
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="DiscussionArea">
    <div className="DiscussionTitle">
      <h4>Discussion</h4>
      <div className="DiscussionChat">
        <img src="images/down.png" className="DiscussionIcon" />
        <a href="#"><img src="images/pdf2.png" /></a>
      </div>
    </div>
    <div className="DiscussionHideArea" style={{display: "block"}}>
      <div className="DiscussionBox">
        <span>FM</span>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Add a Comment ....."
          />
          <div className="DiscussionOverlay">
            <a href="" className="CancelChat">
              <img src="images/plus-cancel1.png"/>
              </a>
            <a href="" className="SendChat">
              <img src="images/forwardbutton.png"/>
             
              </a>
          
          </div>
        </div>
      </div>
      <div className="DiscussionBox">
        <span>TH</span>
        <div className="DiscussionRight">
          <h6>Inspections are delayed</h6>
          <small>04/19/2023, at 02:47 pm</small>
        </div>
      </div>
      <div className="ReplyArea">
        <a href="javascript:void(0);">Reply</a>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      </div>
    </div>

    <>
      {showToggleSetting == true && (
        <div
          className="sidenav BoxSettings"
          style={{ width: showToggleSetting == true ? "250px" : "0" }}
        >
          <a className="closebtn" onClick={handleToggleSettingClose}>
            ×
          </a>
          <div className="SideNavTopBox">
            <h1>Settings</h1>
          </div>
          <div className="SideNavBottom">
            <div className="mySettingsWarrantyBox">
              <h6>Config Service Types</h6>
              <a onClick={handleAConfigServiceTypesModalShow}>
                Config Service Types
              </a>
            </div>
            <div className="mySettingsWarrantyBox">
              <h6>Config Item Status</h6>
              <a onClick={handleConfigItemTypesModalShow}>
                Config Item Status
              </a>
            </div>
            <div className="mySettingsWarrantyBox">
              <h6>Config Urgency</h6>
              <a onClick={handleConfigUrgencyModalShow}>Config Urgency</a>
            </div>
          </div>
        </div>
      )}
    </>

    <Modal
      className="ModalBox fade SmallModal"
      show={AddNotesModal}
      onHide={handleAddNotesClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleAddNotesClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit New Note</h4>
            </div>
            
            <div className="JobTitleArea">
              <div className="JobBox">
                <h6>
                  <span>Job:</span> {state?.JobType}
                </h6>
              </div>
             
              <h6>Type</h6>

              <div className="FolderForm">
                <div className="form-group mb-4">
                  <label className="Radio">
                    Text
                    <input
                      type="radio"
                      value="text"
                      checked={selectedOption=="text"}
                      onChange={onValueChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <div className="form-group">
                  <label className="Radio">
                    File
                    <input
                      type="radio"
                      value="file"
                      checked={selectedOption=="file"}
                      onChange={onValueChange}
                    />
                    <span className="checkmark" />
                  </label>
                </div>

                {textInput == true && (
                  <div className="form-group">
                    <h6>Message (Max 1000 chars limit)</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Write here ....."
                      name="message"
                      value={message}
                      onChange={handleInputChange}
                    />
                    <small className="form-text text-right">
                      {1000 - Number(message.length)} characters remaining
                    </small>
                  </div>
                )}

                {fileData == true && (
                  <>
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={(e) => handleFileChange(e, "support")}
                        />
                      </div>
                    </div>
                    {supportingDocFiles && (
                      <div className="FilesAdded">
                        <label>{supportingDocFiles?.name}</label>
                        <span>
                          <img src="images/fileview.png" alt="File view" />
                        </span>
                        <span onClick={handleRemoveClientFile}>
                          <img
                            src="images/Cross.png"
                            className="cross"
                            alt="Cross"
                          />
                        </span>
                      </div>
                    )}
                  </>
                )}

                <div className="Button d-flex justify-content-between mt-4">
                  <button className="Create" onClick={handleAddNotes}>
                    Update Note
                  </button>
                  <button className="Outline" onClick={handleAddNotesClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox fade SmallModal"
      show={AddNewSupportingDocsModal}
      onHide={handleAddNewSupportingDocsModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a
            className="CloseModal"
            onClick={handleAddNewSupportingDocsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Edit New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> {state?.JobType}
                </h6>
              </div>
             
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  id="fileInput"
                  onChange={(e) => handleFileChange(e, "supportDocs")}
                />
              </div>
            </div>
            <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            {supportingDocFiless && (
              <div className="FilesAdded">
                <label>{supportingDocFiless.name || supportingDocFiless.fileName}</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span onClick={handleRemoveClientFile}>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
            )}

            <div className="Button mt-3">
              <button className="Create" onClick={handleSupportDocs}>
                Add Documents
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox  fade MediumModal"
      show={AddNewUrgencyModal}
      onHide={handleAddNewUrgencyModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleAddNewUrgencyModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Urgency</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-md-6">
                  <h6>Urgency Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Urgency Name"
                    name="name"
                    value={name}
                    onChange={handleInputChanges}
                  />
                </div>
              </div>
              <div className="form-group">
                <h6>Action #</h6>
                <div className="CommonSelectBox w-100">
                  <select
                    name="actionId"
                    value={actionId}
                    onChange={handleInputChanges}
                  >
                    <option value="">Select Action #</option>
                    {allSystemUrgencyList?.warrantyOptions?.length > 0 &&
                      allSystemUrgencyList?.warrantyOptions?.map(
                        (item, i) => {
                          return <option value={item.id}>{item.name}</option>;
                        }
                      )}
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={handleurgencyAdd}>
                  Add New Urgency
                </button>
                <button
                  className="Outline"
                  onClick={handleAddNewUrgencyModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox  fade LargeModal"
      show={ConfigServiceTypesModal}
      onHide={handleAConfigServiceTypesModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a
            className="CloseModal"
            onClick={handleAConfigServiceTypesModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Config Service Types</h4>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="SystemDefineTable">
                  <h4>System Defined Service Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>
                            <div className="Actions">Name </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSystemServiceTypeList?.warrantyOptions?.length >
                          0 &&
                          allSystemServiceTypeList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{data.id}</td>
                                  <td>
                                    {data.name}{" "}
                                    <a href="javascript:void(0);">
                                      <img src="images/edit-32.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-5 ml-auto">
                <div className="SystemDefineTable">
                  <h4>User Defined Service Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUserServiceTypeList?.warrantyOptions?.length >
                          0 &&
                          allUserServiceTypeList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>
                                    <div className="SelectAction_All CursorPointer">
                                      {/* Select Action #  */}
                                      {data.id}
                                      {/* <img src="images/down.png" /> */}
                                    </div>
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    <a
                                      onClick={() =>
                                        handleUserServiceDeleteModalShow(
                                          data.id
                                        )
                                      }
                                    >
                                      <img src="images/delete.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="SelectAction_Main d-flex">
                    <div className="SelectAction">
                      <ul>
                        <li>-01</li>
                        <li>-02</li>
                        <li>-03</li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="Button mt-2 d-flex justify-content-between">
                    <button type="button" className="Create px-3">
                      Add line
                    </button>
                    <button type="button" className="Create px-3">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox  fade LargeModal"
      show={ConfigItemTypesModal}
      onHide={handleConfigItemTypesModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleConfigItemTypesModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Config Item Status</h4>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="SystemDefineTable">
                  <h4>System Defined Item Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>
                            <div className="Actions">Name </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSystemItemTypeList?.warrantyOptions?.length > 0 &&
                          allSystemItemTypeList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{data.id}</td>
                                  <td>
                                    {data.name}{" "}
                                    <a href="javascript:void(0);">
                                      <img src="images/edit-32.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-5 ml-auto">
                <div className="SystemDefineTable">
                  <h4>User Defined Item Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUserItemTypeList?.warrantyOptions?.length > 0 &&
                          allUserItemTypeList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>
                                    <div className="SelectAction_All CursorPointer">
                                      {/* Select Action #  */}
                                      {data.id}
                                      {/* <img src="images/down.png" /> */}
                                    </div>
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    <a
                                    // onClick={()=>handleUserServiceDeleteModalShow(data.id)}
                                    >
                                      <img src="images/delete.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="SelectAction_Main d-flex">
                    <div className="SelectAction">
                      <ul>
                        <li>-01</li>
                        <li>-02</li>
                        <li>-03</li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="Button mt-2 d-flex justify-content-between">
                    <button type="button" className="Create px-3">
                      Add line
                    </button>
                    <button type="button" className="Create px-3">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      show={ItemDeleteModal}
      onHide={handleItemDeleteClose}
      className="ModalBox SmallModal"
    >
      <Modal.Body>
        <div>
          <div>
            <a className="CloseModal" onClick={handleItemDeleteClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Delete</h4>
              </div>
              <div className="FolderForm">
                <div className="my-5">
                  <h6>Are you sure you want to delete this ?</h6>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleFolderDelete}>
                    Delete
                  </button>
                  <button className="Outline" onClick={handleItemDeleteClose}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      className="ModalBox fade SmallModal"
      show={AddItemDocsModal}
      onHide={handleAddItemDocsModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleAddItemDocsModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Supporting Docs</h4>
            </div>
            <div className="JobTitleArea">
              <h3>Supporting Documents #2</h3>
              <div className="JobBox">
                <h6>
                  <span>Job:</span> UUnit3-CHI-2B-315
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Origin</span> #185
                </h6>
              </div>
              <div className="JobBox">
                <h6>
                  <span>Item:</span> #143
                </h6>
              </div>
            </div>
            <div className="form-group">
              <h6>Select Files</h6>
              <div className="Upload">
                <span>-Drag and Drop Files or click to select</span>
                <input
                  type="file"
                  id="itemDocumnet"
                  onChange={(e) => handleFileChange(e, "itemDoc")}
                />
              </div>
            </div>
            <div className="FilesAddedTitle">
              <h4>Files</h4>
            </div>
            {itemDocs && (
              <div className="FilesAdded">
                <label>{itemDocs.name}</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span onClick={handleRemoveClientFile}>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
            )}

            <div className="Button mt-3">
              <button className="Create" onClick={handleItemDocs}>
                Add Documents
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox  fade MediumModal"
      show={AddItemStatusModal}
      onHide={handleAddItemStatusModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleAddItemStatusModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Item Status</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-md-6">
                  <h6>Item Status Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insert Status Name"
                    name="name"
                    value={name}
                    onChange={handleInputChanges}
                  />
                </div>
              </div>
              <div className="form-group">
                <h6>Action #</h6>
                <div className="CommonSelectBox w-100">
                  <select
                    name="actionId"
                    value={actionId}
                    onChange={handleInputChanges}
                  >
                    <option value="">Select Action #</option>
                    {allItemTypeList?.warrantyOptions?.length > 0 &&
                      allItemTypeList?.warrantyOptions?.map((item, i) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={handleItemAddStatus}>
                  Add New Item Status
                </button>
                <button
                  className="Outline"
                  onClick={handleAddItemStatusModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      className="ModalBox  fade MediumModal"
      show={AddNewServiceTypeModal}
      onHide={handleAddNewServiceTypeModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a
            className="CloseModal"
            onClick={handleAddNewServiceTypeModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Add New Service Type</h4>
            </div>
            <div className="FolderForm">
              <div className="row">
                <div className="form-group col-md-6">
                  <h6>Service Type Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" Service Type Name"
                    name="name"
                    value={name}
                    onChange={handleInputChanges}
                  />
                </div>
              </div>
              <div className="form-group">
                <h6>Action #</h6>
                <div className="CommonSelectBox w-100">
                  <select
                    name="actionId"
                    value={actionId}
                    onChange={handleInputChanges}
                  >
                    <option value="">Select Action #</option>
                    {allServiceTypeList?.warrantyOptions?.length > 0 &&
                      allServiceTypeList?.warrantyOptions?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  <span>
                    <img src="images/down.png" />
                  </span>
                </div>
              </div>
              <div className="Button d-flex justify-content-between mt-5">
                <button className="Create" onClick={handleServiceAddStatus}>
                  Add New Service Type
                </button>
                <button
                  className="Outline"
                  onClick={handleAddNewServiceTypeModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      show={UserServiceDeleteModal}
      onHide={handleUserServiceDeleteModalClose}
      className="ModalBox SmallModal"
    >
      <Modal.Body>
        <div>
          <div>
            <a
              className="CloseModal"
              onClick={handleUserServiceDeleteModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Delete</h4>
              </div>
              <div className="FolderForm">
                <div className="my-5">
                  <h6>Are you sure you want to delete this ?</h6>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button
                    className="Create"
                    onClick={handleUserServiceDelete}
                  >
                    Delete
                  </button>
                  <button
                    className="Outline"
                    onClick={handleUserServiceDeleteModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      className="ModalBox  fade LargeModal"
      show={ConfigUrgencyModal}
      onHide={handleConfigUrgencyModalClose}
    >
      <Modal.Body>
        <div className="Category">
          <a className="CloseModal" onClick={handleConfigUrgencyModalClose}>
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Config Urgency</h4>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="SystemDefineTable">
                  <h4>System Defined Service Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>
                            <div className="Actions">Name </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSystemUrgencyList?.warrantyOptions?.length > 0 &&
                          allSystemUrgencyList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>{data.id}</td>
                                  <td>
                                    {data.name}{" "}
                                    <a href="javascript:void(0);">
                                      <img src="images/edit-32.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-5 ml-auto">
                <div className="SystemDefineTable">
                  <h4>User Defined Service Types</h4>
                  <div className="TableList ConfigServiceTypes">
                    <table>
                      <thead>
                        <tr>
                          <th>Action #</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allUserUrgencyList?.warrantyOptions?.length > 0 &&
                          allUserUrgencyList?.warrantyOptions?.map(
                            (data, ind) => {
                              return (
                                <tr key={ind}>
                                  <td>
                                    <div className="SelectAction_All CursorPointer">
                                      {/* Select Action #  */}
                                      {data.id}
                                      {/* <img src="images/down.png" /> */}
                                    </div>
                                  </td>
                                  <td>{data.name}</td>
                                  <td>
                                    <a
                                      onClick={() =>
                                        handleUrgencyDeleteModalShow(data.id)
                                      }
                                    >
                                      <img src="images/delete.png" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="SelectAction_Main d-flex">
                    <div className="SelectAction">
                      <ul>
                        <li>-01</li>
                        <li>-02</li>
                        <li>-03</li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="Button mt-2 d-flex justify-content-between">
                    <button type="button" className="Create px-3">
                      Add line
                    </button>
                    <button type="button" className="Create px-3">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      show={ConfigUrgencyDeleteModal}
      onHide={handleUrgencyDeleteModalClose}
      className="ModalBox SmallModal"
    >
      <Modal.Body>
        <div>
          <div>
            <a className="CloseModal" onClick={handleUrgencyDeleteModalClose}>
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Delete</h4>
              </div>
              <div className="FolderForm">
                <div className="my-5">
                  <h6>Are you sure you want to delete this ?</h6>
                </div>
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleUrgencyDelete}>
                    Delete
                  </button>
                  <button
                    className="Outline"
                    onClick={handleUrgencyDeleteModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      show={SendMailModal}
      onHide={handleSendMailModalClose}
      className="ModalBox modal fade SmallModal show"
    >
      <Modal.Body>
        <a
          href="javascript:void(0);"
          className="CloseModal"
          onClick={handleSendMailModalClose}
        >
          ×
        </a>
        <div className="FormArea">
          <div className="ModalTitleBox">
            <h4>Item# 1 Send Email</h4>
          </div>
          <div className="FolderForm">
            <div className="FormTitleBox">
              <div className="FormTitle">
                <h4>Inspector Contact Information</h4>
              </div>
            </div>
            <div className="CommonForm">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Full name"
                      name="fullName"
                      value={fullName}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>

                
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Company</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company"
                      name="companyName"
                      value={companyName}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Phone number"
                      name="phoneNumber"
                      value={phoneNumber}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      name="email"
                      value={email}
                      onChange={handleInputChanges}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="Button text-center mt-4">
                    <button className="Create" onClick={handleSendMail}>Send E-mail</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
  
  )
}

export default Warranty_Request_work_order_table