import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment  from "moment";
import {
  UpdateReminder,
  createTodoTask,
  getProjectByBuilder,
  getTodoCalendarTaskList,
  getTodoEmailCalendar,
  getTodoGoogleCalendar,
  getTodoMemberList,
  getTodoOutlookCalendar,
  getTodoTaskList,
  getTodoVendorList,
  getTodoViewTask,
  getTodoViewTaskHistoryList,
  gettodoListJob,
} from "../redux/action/tradeViewAuthAction";
import { calculateDateDifference } from "../utils/uploadFile";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";

const initialState = {
  AddTaskModal: false,
  search: "",
  AddNotesModal: false,
  message: "",
  supportingDocFiles: "",
  JobId: "",
  JobType: "",
  toogleJob: false,
  AddAssignTradeModal: false,
  VendorId: "",
  vendorToogle: false,
  ReminderModal: false,
  day: "",
  week: "",
  AssignStaffMemberModal:false,
  AddNewSupportingDocsModal:false,
  supportingDocFiless:null,
  toogleStaffMember: false,
  TodoTaskDeleteModal:false,
  assignStaffs:"",
  TaskId:"",
  description:"",
  UserId:"",
  assignTradeContact:"",
  assignTradeEmail:"",
  assignTradeMessage:"",
  toDoNotesFiles:null,
  UpdateReminderModal:false,
  taskId:"",
  ViewTaskModal:false,
  TaskHistoryModal:false,
  MoveTaskModal:false,


  AddItineraryModal:false,
  subject: "",
  descriptions: "",
  location: "",
  date: "",
  fromTime: "",
  toTime: "",
  type: "",
  reminderDates: "",
  jobType: "",
  jobId: "",
  relatedToTask: "",
  relatedToJob: "",
  assignStaffss: "",
  toogleIternaryJob:false,
  AssignStaffMemberModalInt:false,
  ReminderModalInt:false,
  projectId:"",
    jobIdd:"",
};

const TodoTradeview = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    AddTaskModal,
    search,
    AddNotesModal,
    message,
    supportingDocFiles,
    JobId,
    JobType,
    toogleJob,
    AddAssignTradeModal,
    VendorId,
    vendorToogle,
    ReminderModal,
    week,
    day,
    AssignStaffMemberModal,
    AddNewSupportingDocsModal,
    supportingDocFiless,
    toogleStaffMember,
    UserId,
    TodoTaskDeleteModal,
    assignStaffs,
    TaskId,
    description,
  assignTradeContact,
  assignTradeEmail,
  assignTradeMessage,
  toDoNotesFiles,
  UpdateReminderModal,
  taskId,
  ViewTaskModal,
  TaskHistoryModal,
  MoveTaskModal,

  AddItineraryModal,
  toogleIternaryJob,
  subject,
  descriptions,
  location,
  date,
  fromTime,
  toTime,
  type,
  reminderDates,
  jobType,
  jobId,
  relatedToTask,
  relatedToJob,
  assignStaffss,
  AssignStaffMemberModalInt,
  ReminderModalInt,
  projectId,
  jobIdd,
  } = iState;
  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  const { jobList, vendorList ,memberList,taskLoader ,todoTaskList,todoViewTaskDetails,todoViewTaskHistoryList, projectByBuilderData,
    jobByProjectData,} = useSelector((state) => state.tradeViewLogin);
  console.log("todoViewTaskHistoryList", todoViewTaskHistoryList);

  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
  },[])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(gettodoListJob(builderIdd));
      dispatch(getTodoVendorList(builderIdd));
      dispatch(getTodoMemberList(builderIdd));
      dispatch(getTodoTaskList(builderIdd));
      updateState({
        ...iState,
        [name]: value,
        jobIdd: "",
      });
    } else if (name === "jobIdd") {
      updateState({
        ...iState,
        [name]: value,
      });
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleAddTaskShow = () => {
    updateState({
      ...iState,
      AddTaskModal: true,
    });
  };

  const handleAddTaskClose = () => {
    updateState({
      ...iState,
      AddTaskModal: false,
    });
  };

  const handleTaskAdd = () => {
    // let formIsValid = handleShareDocumentValidation();
    // if (formIsValid) {
    const type = week ? week : day;
    const dataa = {
      description,
      JobId,
      JobType,
      reminderDate: calculateDateDifference(type),
      assignStaffs: [{ UserId: Number(UserId) }],
      TaskId:"1",
      scheRelatedToDo:false,
      toDoNotes:{ noteType: "text", message: message },
      supportingDocFiles,
      assignTrade:{ email: assignTradeEmail, message: assignTradeMessage,VendorId:VendorId,contact:assignTradeContact },
     supportingDocFiles:supportingDocFiless,


    };
    dispatch(createTodoTask(compId?.user?.CompanyId,compId?.user?.id,dataa))
      .then((res) => {
        if (res.status === 201) {
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Add Task Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddTaskModal: false,
            week: "",
            day: "",
            reminderDate: "",
            assignStaffs: "",
            JobId:"",
            JobType:"",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            AddTaskModal: true,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          loading: false,
          AddTaskModal: true,
        });
      });
  };

  const [selectedOption, setSelectedOption] = useState("Text");
  const [textInput, setTextInput] = useState(true);
  const [fileData, setFileData] = useState(false);

  const onValueChange = (event) => {
    updateState({ ...iState, message: "", supportingDocFiles: "" });
    const value = event.target.value;
    setSelectedOption(value);
    setTextInput(value === "Text");
    setFileData(value === "File");
  };

  const handleRemoveClientFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiles: null,
      supportingDocFiless: null,
      itemDocs: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNotesShow = () => {
    updateState({
      ...iState,
      AddNotesModal: true,
      AddTaskModal: false,
      message: "",
      supportingDocFiles: "",
    });
  };
  const handleAddNotesClose = () => {
    updateState({
      ...iState,
      AddNotesModal: false,
      AddTaskModal: true,
    });
  };

  const handleFileChange = (event, type) => {
    const file = event?.target?.files[0];
    console.log("file changed", file);
    if (type === "support") {
      updateState({ ...iState, supportingDocFiles: file });
    }
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleAddNotes = () => {
    // let dataa;
    // if (textInput) {
    //   dataa = {
    //     warrantyNotes: [{ noteType: "text", message: message }],
    //     description: draftWarranty?.warranty?.description
    //       ? draftWarranty?.warranty?.description
    //       : "N/A",
    //       // jobType:"ResidentialJob",
    //       // jobId:2,
    //   };
    // } else {
    //   dataa = {
    //     warrantyNotes: [{ noteType: "files" }],
    //     supportingDocFiles,
    //     description: draftWarranty?.warranty?.description
    //       ? draftWarranty?.warranty?.description
    //       : "N/A",
    //       // jobType:"ResidentialJob",
    //       // jobId:2,
    //   };
    // }
    // dispatch((draftWarranty?.warranty?.id, dataa)).then((res) => {
    //   if (res.status === 200) {
    //     toast.success("Add Notes Successfully", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 1000,
    //     });
    //     updateState({
    //       ...iState,
    //       AddNotesModal: false,
    //     });
    //   } else {
    //     toast.error(res.data.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     updateState({
    //       ...iState,
    //       AddNotesModal: true,
    //     });
    //   }
    // });
  };

  const handleItemClick = (item) => {
    updateState({ ...iState, JobId: item?.id, JobType: item?.jobType });
  };
  const handleJobToggle = () => {
    updateState({ ...iState, toogleJob: !toogleJob });
  };
  const handleVendorToogle = () => {
    updateState({ ...iState, vendorToogle: !vendorToogle });
  };

  const handleAddAssignTradeModalShow = () => {
    updateState({ ...iState, AddAssignTradeModal: true, AddTaskModal: false });
  };

  const handleAddAssignTradeModalClose = () => {
    updateState({ ...iState, AddAssignTradeModal: false, AddTaskModal: true });
  };

  const handleVendorClick = (item) => {
    updateState({ ...iState, VendorId: item?.id });
  };

  const handleReminderShow = () => {
    updateState({
      ...iState,
      ReminderModal: true,
      AddTaskModal: false,
    });
  };

  const handleReminderClose = () => {
    updateState({
      ...iState,
      ReminderModal: false,
      AddTaskModal: true,
    });
  };

  const handleReminder = () => {
    updateState({ ...iState, ReminderModal: false, day: day, week: week });
  };

  const handleAssignStaffMemberShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: true,
      AddTaskModal:false,
    });
  };

  const handleAssignStaffMemberClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModal: false,
      AddTaskModal:true,
    });
  };

  const handleAssign = () => {
    updateState({ ...iState, AssignStaffMemberModal: false });
  };

  
  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      id: id,
      supportingDocFiless: "",
      AddTaskModal:false,
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      AddTaskModal:true
    });
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({
      ...iState,
      supportingDocFiless: null,
    });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleStaffMemberToggle=()=>{
    updateState({...iState,toogleStaffMember:!toogleStaffMember})
  }

  const handleStaffMemberClick = (item) => {
    updateState({ ...iState, UserId: item?.id });
  };
  
  const handleTodoTaskDeleteModalShow=()=>{
    updateState({ ...iState, TodoTaskDeleteModal:true });
  }
  const handleTodoTaskDeleteModalClose=()=>{
    updateState({ ...iState, TodoTaskDeleteModal:false });
  }


  const handleUpdateReminderModalShow = (id) => {
    updateState({
      ...iState,
      UpdateReminderModal: true,
      taskId:id
    });
  };

  const handleUpdateReminderModalClose = () => {
    updateState({
      ...iState,
      UpdateReminderModal: false,
    });
  };

  const updateReminder = () => {
    const type = week ? week : day;
    const dataa = {
      reminderDate: calculateDateDifference(type)
    };
    dispatch(UpdateReminder(compId?.user?.CompanyId,taskId,dataa))
      .then((res) => {
        if (res.status === 200) {
          toast.success("Update Reminder Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateReminderModal: false,
            week: "",
            day: "",
            reminderDate: "",
            taskId:"",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            loading: false,
            UpdateReminderModal: true,
          });
        }
      })
  
  };

  const handleTodoGoogleData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getTodoGoogleCalendar(compId?.user?.CompanyId,datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.google, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTodoOutLookData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getTodoOutlookCalendar(compId?.user?.CompanyId,datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.outlook, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleTodoEmailData = (item) => {
    const datta = {
      title: "Sample Event",
      description: item.description,
      assignDate: item.assignDate,
      reminderDate: item.reminderDate,
      location: "Sample Location",
    };
    dispatch(getTodoEmailCalendar(compId?.user?.CompanyId,datta))
      .then((res) => {
        if (res.status === 200) {
          window.open(res.data.yahoo, "_blank");
          // dispatch(getCreatedTaskList({ showAll: false }));
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleViewTaskShow = (id) => {
    updateState({
      ...iState,
      ViewTaskModal: true,
    });
    dispatch(getTodoViewTask(compId?.user?.CompanyId,id))
      .then((res) => {
        if (res.status === 200) {
          toast.success(" Get Task Details Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleViewTaskClose = () => {
    updateState({ ...iState, ViewTaskModal: false });
  };

  const handleTaskHistoryModalShow = (id) => {
    updateState({
      ...iState,
      TaskHistoryModal: true,
    });
    dispatch(getTodoViewTaskHistoryList(compId?.user?.CompanyId,id));
  };

  const handleTaskHistoryModalClose = () => {
    updateState({
      ...iState,
      TaskHistoryModal: false,
      id: "",
    });
  };


  const handleMoveTaskModalShow = (id) => {
    updateState({
      ...iState,
      MoveTaskModal: true,
      TaskId: id,
    });
  };

  const handleMoveTaskModalClose = () => {
    updateState({
      ...iState,
      MoveTaskModal: false,
      TaskId:"",
    });
  };

  const handleAddItineraryModalShow = () => {
    updateState({
      ...iState,
      AddItineraryModal: true,
      MoveTaskModal: false,

    });
  };

  const handleAddItineraryModalClose = () => {
    updateState({
      ...iState,
      AddItineraryModal: false,
    });
  };

  const handleJobTaskChecked = (e, type) => {
    const { checked } = e.target;
    if (type == "job") {
      updateState({ ...iState, relatedToJob: checked });
    } else {
      updateState({ ...iState, relatedToTask: checked });
    }
  };
  const handleIternaryItemClick = (item) => {
    updateState({ ...iState, jobId: item?.id, jobType: item?.jobType });
  };
  const handleIternaryJobToggle = () => {
    updateState({ ...iState, toogleIternaryJob: !toogleIternaryJob });
  };

  const handleAssignStaffMemberIntShow = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: true,
      AddItineraryModal: false,
    });
  };

  const handleAssignStaffMemberIntClose = () => {
    updateState({
      ...iState,
      AssignStaffMemberModalInt: false,
      AddItineraryModal: true
    });
  };

  const handleAssignIn = () => {
    updateState({ ...iState, AssignStaffMemberModalInt: false , AddItineraryModal: true});
  };

  const removeAssignIntMember = () => {
    updateState({ ...iState, assignStaffss: "" });
  };

  const handleReminderIntShow = () => {
    updateState({
      ...iState,
      ReminderModalInt: true,
      AddItineraryModal: false,
    });
  };

  const handleReminderIntClose = () => {
    updateState({
      ...iState,
      ReminderModalInt: false,
      AddItineraryModal: true,
    });
  };

  const handleReminderInt = () => {
    updateState({ ...iState, ReminderModalInt: false, day: day, week: week ,AddItineraryModal: true});
  };



  const calendarRef = useRef(null);

  const [view, setView] = useState("dayGridMonth");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch or generate your dynamic events data based on the selected view (date, week, or month)
    const fetchData = async () => {
      // Replace this with your actual data fetching logic
      const data = await fetchEventData(view);
      setEvents(data);
    };

    fetchData();
  }, [view]);

  const fetchEventData = async (currentView) => {
    // Replace this with your actual data fetching logic based on the current view
    // For example, you might fetch events for the current month, week, or day
    const currentDate = new Date();

    // Example: Fetch events for the current month
    if (currentView === "dayGridMonth") {
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      // Fetch events for the current month
      const monthEvents = await fetchDataForDateRange(startOfMonth, endOfMonth);

      return monthEvents;
    }

    // Example: Fetch events for the current week
    if (currentView === "dayGridWeek") {
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      );

      // Fetch events for the current week
      const weekEvents = await fetchDataForDateRange(startOfWeek, endOfWeek);

      return weekEvents;
    }

    // Example: Fetch events for the current day
    const dayEvents = await fetchDataForDate(currentDate);
    return dayEvents;
  };

  const fetchDataForDateRange = async (startDate, endDate) => {
    const datta = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(getTodoCalendarTaskList(compId?.user?.CompanyId,datta))
      .then((res) => {
        console.log("date datatat", res);
        if (res.status === 200) {
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // toast.error(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const fetchDataForDate = async (date) => {
    const datta = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(getTodoCalendarTaskList(compId?.user?.CompanyId,datta))
      .then((res) => {
        if (res.status === 200) {
          toast.success("Find data Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          // toast.error(res.data.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      })
      // .catch((error) => {
      //   toast.error(error.message, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // });
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleDateSet = (dateInfo) => {
    // Handle "today" button click by resetting the view to the current date
    if (dateInfo.startStr === dateInfo.endStr) {
      setView("dayGridMonth"); // You can set it to the default view you prefer
    }
  };

  //===========================================================================================//
  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }
  return (
    <>
      {/* <Header /> */}
      <TradeHeader/>
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          {/* <h6 data-toggle="modal" data-target="#TargetDate">TargetDate</h6>
    <h6 data-toggle="modal" data-target="#ContactModal">TargetDatesasas</h6> */}
          <div className="TodosArea">
            <div className="TodosHeader align-items-start">
            <div className="BuilderBox">
                <h4>Project</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="projectId"
                    value={projectId}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Project</option>
                    {projectByBuilderData?.data?.length > 0 &&
                      projectByBuilderData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.projectName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="BuilderBox">
                <h4>Job</h4>
                <div className="SelectBuilder">
                  <select
                    className="CommonSelect"
                    name="jobIdd"
                    value={jobIdd}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Jobs</option>
                    {jobByProjectData?.data?.length > 0 &&
                      jobByProjectData?.data?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.jobType}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="d-flex">
                <h6
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#SystemTasksSettings"
                >
                  <img src="images/setting.png" />
                </h6>
              </div>
            </div>
            <div className="CommonTabs mt-3">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#TodoList"
                  >
                    <span>
                      <img src="images/task_list_icon.png" />
                    </span>
                    TODOs List
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#Calendar">
                    <span>
                      <img src="images/icon-23.png" />
                    </span>
                    Calendar
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="TodoList">
              <div className="TodoBoxArea">
                <div className="row">
                  <div className="col-md-4">
                    <div className="TodoBox">
                      <div className="TodoBoxHeader">
                        <div className="d-flex align-items-center">
                          <h6>To-do</h6>
                        </div>
                        <img src="images/add.png" onClick={handleAddTaskShow} />
                        <figure className="mb-0">
                          <img src="images/task-square.png" />
                        </figure>
                      </div>  
                      
                      {
                        todoTaskList?.toDos?.length>0&&
                        todoTaskList?.toDos?.map((data,index)=>{
                          return(
                            <div className="TodoUser RedLeftBorder">
                            <div className="TodoUserJob mb-1">
                              <h6>{data.description}</h6>
                              <div className="dropdown">
                                <p data-toggle="dropdown">
                                  Job: U2-CHI-2B-307
                                  <span>
                                    <img src="images/three-dots.png" />
                                  </span>
                                </p>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuOffset"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                    onClick={()=>handleViewTaskShow(data.id)}
                                    >
                                      <img src="images/view-po.png" />
                                      View P.O.
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={()=>handleTaskHistoryModalShow(data.id)}
                                    >
                                      <img src="images/task-history.png" />
                                      Task History
                                    </a>
                                  </li>
                                  <li>
                                    <a className="dropdown-item" href="#">
                                      <img src="images/export-to-calendar.png" />
                                      Export to Calender &nbsp;
                                      <img src="images/rightarrow2.png" />
                                    </a>
                                    <ul className="dropdown-menu submenu">
                                      <li>
                                        <a
                                          onClick={()=>handleTodoGoogleData(data)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/google-calendar.png" />
                                          Google Calendar
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                         onClick={()=>handleTodoOutLookData(data)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/outlook-calendar.png" />
                                          Outlook Calender
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                           onClick={()=>handleTodoEmailData(data)}
                                          className="dropdown-item"
                                        >
                                          <img src="images/email-calendar.png" />
                                          Email Calender
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                    onClick={()=>handleMoveTaskModalShow(data.id)}
                                    >
                                      <img src="images/move-to-task.png" />
                                      Move to Task
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={()=>handleUpdateReminderModalShow(data.id)}
                                    >
                                      <img src="images/set-reminder.png" />
                                      Set Reminder
                                    </a>
                                  </li>
                                </div>
                              </div>
                            </div>
                            <div className="TodoUserDetails">
                              <small>05 May 2023</small>
                              <small>Schedule task: 1.3.1.3</small>
                            </div>
                            <div className="TodoUserName">
                              <small>Trade TODO</small>
                              <div className="TodoUserIcons">
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="modal"
                                  data-target="#TaskDetails"
                                >
                                  <img src="images/todo-edit.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/todo-calendar.png" />
                                </a>
                                <a href="javascript:void(0);">
                                  <img src="images/todo-delete.png" />
                                </a>
                              </div>
                            </div>
                          </div>
                          )
                        })
                      }
                       
                     


                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="TodoBox">
                      <div className="TodoBoxHeader TodoBoxHeaderComplete">
                        <h6 className="CursorPointer">In-Process</h6>
                        <img src="images/eye-icon.png" alt="" />
                        <figure className="mb-0">
                          <img src="images/task-square.png" />
                        </figure>
                      </div>
                      <div className="TodoUser YellowLeftBorder">
                        <div className="TodoUserJob mb-1">
                          <h6>Framing</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/assign-resources.png" />
                                  Assign Resources
                                </a>
                              </li>
                              <li></li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/view-po.png" />
                                  View P.O.
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/export-to-calendar.png" />
                                  Export to Calender &nbsp;
                                  <img src="images/rightarrow2.png" />
                                </a>
                                <ul className="dropdown-menu submenu">
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/google-calendar.png" />
                                      Google Calendar
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/outlook-calendar.png" />
                                      Outlook Calender
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <img src="images/email-calendar.png" />
                                      Email Calender
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/move-to-task.png" />
                                  Move to Task
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/set-reminder.png" />
                                  Set Reminder
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <small>05 May 2023</small>
                        </div>
                        <div className="TodoUserName">
                          <small>Assigned : John Doe</small>
                          <div className="TodoUserIcons">
                            <a href="javascript:void(0);">
                              <img src="images/esclamation.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="TodoBox">
                      <div className="TodoBoxHeader TodoBoxHeaderComplete">
                        <h6>Completed</h6>
                        <img src="images/eye-icon.png" alt="" />
                        <figure className="mb-0">
                          <img src="images/task-square.png" />
                        </figure>
                      </div>
                      <div className="TodoUser GreenLeftBorder">
                        <div className="TodoUserJob mb-1">
                          <h6>Hardfoor</h6>
                          <div className="dropdown">
                            <p data-toggle="dropdown">
                              Job: U2-CHI-2B-307
                              <span>
                                <img src="images/three-dots.png" />
                              </span>
                            </p>
                            <div
                              className="dropdown-menu dropdown-menuUserflow"
                              aria-labelledby="dropdownMenuOffset"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/view-po.png" />
                                  View P.O.
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  <img src="images/task-history.png" />
                                  Task History
                                </a>
                              </li>
                            </div>
                          </div>
                        </div>
                        <div className="TodoUserDetails">
                          <small>05 May 2023</small>
                          <div className="TodoUserName">
                            <small>Assigned : John Doe</small>
                          </div>
                        </div>
                        <div className="TodoUserName">
                          <div className="TodoUserJob mb-1">
                            <h6>Trade TODO</h6>
                          </div>
                          <div className="TodoUserIcons">
                            <a href="javascript:void(0);">
                              <img src="images/esclamation.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/close-eyes.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="Calendar">
              <FullCalendar
                ref={calendarRef}
                // weekends={true}
                events={events}
                eventContent={renderEventContent}
                headerToolbar={{
                  start: "today prev next",
                  center: "title",
                  end: "dayGridMonth dayGridWeek dayGridDay",
                }}
                plugins={[dayGridPlugin]}
                views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                initialView="dayGridDay"
                datesSet={(info) => {
                  handleViewChange(info.view.type);
                  handleDateSet(info);
                }}
              />
              {/* <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            headerToolbar={{
              right: 'today,week,month',
              center: 'title',
              left: 'dayGridMonth,dayGridWeek'
            }}
            datesSet={(info) => {
              handleViewChange(info.view.type);
              handleDateSet(info);
            }}
          /> */}
              ;
            </div>
          </div>
        </div>
        <Modal
          show={AddTaskModal}
          onHide={handleAddTaskClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleAddTaskClose}>
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Add New Task</h4>
                  </div>
                  <div className="ModalTitleHeading">
                    <h2>Task Information</h2>
                  </div>
                  <div className="FolderForm">
                      <div className="form-group">
                        <h6>
                          Job<span className="required">*</span>
                        </h6>
                        <div className="Categories Categories1">
                          <div
                            className="Categories_all Categories_all1"
                            onClick={handleJobToggle}
                          >
                            <span>Select a Job</span>
                            <img src="images/down.png" />
                          </div>
                          {toogleJob && (
                            <ul>
                              <li>
                                <input
                                  type="text"
                                  placeholder="-Search"
                                  name="search"
                                  value={search}
                                  onChange={handleInputChange}
                                />
                                <span className="Search">
                                  <img src="images/search.png" />
                                </span>
                              </li>
                              {jobList?.jobs?.length > 0 &&
                                jobList.jobs
                                  .filter((data) =>
                                    search.toLowerCase() === ""
                                      ? true
                                      : data.jobType
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                  )
                                  .map((item, i) => (
                                    <li
                                      value={item.id}
                                      key={i}
                                      onClick={() => handleItemClick(item)}
                                      style={{
                                        backgroundColor:
                                          JobId === item.id &&
                                          JobType === item.jobType
                                            ? "#e71e09"
                                            : "transparent",
                                      }}
                                    >
                                      {item.jobType}
                                    </li>
                                  ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>
                          Schedule related To-Do?
                          <span className="required">*</span>
                        </h6>
                        <label className="CheckBox mr-4">
                          {" "}
                          Yes
                          <input type="radio" name="schedule" />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          {" "}
                          No
                          <input type="radio" name="schedule" />
                          <span className="checkmark" />
                        </label>
                      </div>
                      <div className="form-group">
                        <h6>Schedule's Task</h6>
                        <div className="Categories Categories2">
                          <div className="Categories_all Categories_all2">
                            <span>Select a Job</span>
                            <img src="images/down.png" />
                          </div>
                          <ul style={{}}>
                            <li>
                              <input type="text" placeholder="-Search" />
                              <span className="Search">
                                <img src="images/search.png" />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <h6>
                          Description <span className="required">*</span>
                        </h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="description"
                          value={description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="StaffMemberArea StaffMemberAreaConst">
                        <aside>
                          <h6>Add Supporting Document</h6>
                          <div className="StaffName">
                            <img src="images/document-support.png" />
                            <div
                              className="Count CursorPointer FolderPermissionId"
                            
                            >
                              3
                            </div>
                            <h6
                              className="CursorPointer FolderPermissionId"
                              onClick={handleAddNewSupportingDocsModalShow}
                            >
                              Add Supporting Document
                            </h6>
                          </div>
                        </aside>
                      </div>
                      <div className="StaffMemberArea StaffMemberAreaConst">
                        <aside>
                          <h6>Add Notes</h6>
                          <div className="StaffName">
                            <img src="images/add-notes.png" />
                            <div
                              className="Count CursorPointer FolderPermissionId"
                              data-toggle="modal"
                              data-target="#NotesList"
                              data-dismiss="modal"
                            >
                              3
                            </div>
                            <h6
                              className="CursorPointer FolderPermissionId"
                              onClick={handleAddNotesShow}
                            >
                              Add Notes
                            </h6>
                          </div>
                        </aside>
                        <aside>
                          <h6>Assign Trade</h6>
                          <div className="StaffName">
                            <img src="images/assign-trade.png" />
                            <div className="Count">3</div>
                            <h6
                              className="FolderPermissionId"
                              onClick={handleAddAssignTradeModalShow}
                            >
                              Assign
                            </h6>
                          </div>
                        </aside>
                      </div>
                      <div className="StaffMemberArea StaffMemberAreaConst">
                        <aside>
                          <h6>Assign Staff Member</h6>
                          <div className="StaffName">
                            <img src="images/member.png" />
                            <div className="Count">3</div>
                            <h6
                              className="FolderPermissionId"
                             onClick={handleAssignStaffMemberShow}
                            >
                              Assign
                            </h6>
                          </div>
                        </aside>
                        <aside>
                          <h6>Set Reminder</h6>
                          <div className="StaffName">
                            <img src="images/timer-red.png" />
                            <h6
                              className="FolderPermissionId"
                              onClick={handleReminderShow}
                            >
                              Reminder
                            </h6>
                          </div>
                        </aside>
                      </div>
                      <div className="Button text-center mt-5">
                        <button className="Create" onClick={handleTaskAdd}>
                          Create Task
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade SmallModal"
          show={AddNotesModal}
          onHide={handleAddNotesClose}
        >
          <Modal.Body>
            <div className="Category">
              <a className="CloseModal" onClick={handleAddNotesClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Note</h4>
                </div>
                <h6>Note #1</h6>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox mb-3">
                    <h6>
                      <span>Origin:</span> #185
                    </h6>
                  </div>
                  <h6>Type</h6>

                  <div className="FolderForm">
                    <div className="form-group mb-4">
                      <label className="Radio">
                        Text
                        <input
                          type="radio"
                          value="Text"
                          checked={selectedOption === "Text"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group">
                      <label className="Radio">
                        File
                        <input
                          type="radio"
                          value="File"
                          checked={selectedOption === "File"}
                          onChange={onValueChange}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>

                    {textInput == true && (
                      <div className="form-group">
                        <h6>Message (Max 1000 chars limit)</h6>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write here ....."
                          name="message"
                          value={message}
                          onChange={handleInputChange}
                        />
                        <small className="form-text text-right">
                          {1000 - Number(message.length)} characters remaining
                        </small>
                      </div>
                    )}

                    {fileData == true && (
                      <>
                        <div className="form-group">
                          <h6>Select Files</h6>
                          <div className="Upload">
                            <span>-Drag and Drop Files or click to select</span>
                            <input
                              type="file"
                              id="fileInput"
                              onChange={(e) => handleFileChange(e, "support")}
                            />
                          </div>
                        </div>
                        {supportingDocFiles && (
                          <div className="FilesAdded">
                            <label>{supportingDocFiles?.name}</label>
                            <span>
                              <img src="images/fileview.png" alt="File view" />
                            </span>
                            <span onClick={handleRemoveClientFile}>
                              <img
                                src="images/Cross.png"
                                className="cross"
                                alt="Cross"
                              />
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    <div className="Button d-flex justify-content-between mt-4">
                      <button className="Create" onClick={handleAddNotes}>
                        Update Note
                      </button>
                      <button className="Outline" onClick={handleAddNotesClose}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade SmallModal"
          show={AddAssignTradeModal}
          onHide={handleAddAssignTradeModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddAssignTradeModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Note</h4>
                </div>
                <h6>Note #1</h6>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox mb-3">
                    <h6>
                      <span>Origin:</span> #185
                    </h6>
                  </div>
                  <h6>Type</h6>

                  <div className="FormArea">
                    <div className="ModalTitleBox">
                      <h4>Assign Trade</h4>
                    </div>
                    <div className="ModalTitleHeading">
                      <h2>Trade Information</h2>
                    </div>
                    <div className="FolderForm">
                      <form>
                        <div className="form-group">
                          <h6>Sub-Trade Name</h6>
                          <div className="Categories Categories1">
                            <div
                              className="Categories_all Categories_all1"
                              onClick={handleVendorToogle}
                            >
                              <span>Select Sub-Trade Name</span>
                              <img src="images/down.png" />
                            </div>

                            {vendorToogle && (
                              <ul>
                                {vendorList?.VendorList?.length > 0 &&
                                  vendorList?.VendorList?.map((item, i) => (
                                    <li
                                      key={i}
                                      value={item.id}
                                      onClick={() => handleVendorClick(item)}
                                      style={{
                                        backgroundColor:
                                          VendorId === item.id
                                            ? "#e71e09"
                                            : "transparent",
                                      }}
                                    >
                                      {item.vendorName}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <h6>Contact</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Contact"
                            name="assignTradeContact"
                            value={assignTradeContact}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <h6>Email</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            name="assignTradeEmail"
                            value={assignTradeEmail}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="form-group">
                          <h6>Message</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Write here ..."
                            name="assignTradeMessage"
                            value={assignTradeMessage}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="Button text-center mt-5">
                          <button
                            className="Create"
                            data-toggle="modal"
                            data-target="#AddNewTask"
                            data-dismiss="modal"
                          >
                            Send E-mail
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={ReminderModal}
          onHide={handleReminderClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleReminderClose}>
                  ×
                </a>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>
                      Weeks <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={day}
                        name="week"
                        value={week}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of weeks for reminder
                        </option>
                        <option value="7">1</option>
                        <option value="14">2</option>
                        <option value="21">3</option>
                        <option value="28">4</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Days <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={week}
                        name="day"
                        value={day}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of days for reminder
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" onClick={handleReminder}>
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={AssignStaffMemberModal}
          onHide={handleAssignStaffMemberClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={handleAssignStaffMemberClose}
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Assign Staff Member</h4>
                  </div>
                  <div className="ModalTitleHeading">
                    <h2>Assign Staff Member</h2>
                  </div>
                  <div className="FolderForm">
                    <div className="form-group">
                      <h6>Name</h6>
                      <div className="Categories Categories1">
                        <div className="Categories_all Categories_all1" onClick={handleStaffMemberToggle}>
                          <span>Select a Staff Member</span>
                          <img src="images/down.png" />
                        </div>
                        {
                          toogleStaffMember===true&&
                          <ul>
                          {memberList?.staff?.length > 0 &&
                                  memberList?.staff
                                    ?.map((item, i) => (
                                      <li
                                        value={item.id}
                                        key={i}
                                        onClick={() => handleStaffMemberClick(item)}
                                        style={{
                                          backgroundColor:
                                          UserId === item.id 
                                              ? "#e71e09"
                                              : "transparent",
                                        }}
                                      >
                                        {item.email}
                                      </li>
                                    ))}
                          </ul>
                        }
                       
                      </div>
                    </div>
                    <div className="Button text-center mt-5">
                      <button className="Create">Assign</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        className="ModalBox fade SmallModal"
        show={AddNewSupportingDocsModal}
        onHide={handleAddNewSupportingDocsModalClose}
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              onClick={handleAddNewSupportingDocsModalClose}
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4> New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input
                    type="file"
                    id="docsInput"
                    onChange={(e) => handleFileChange(e, "docsUpdate")}
                  />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              {supportingDocFiless && (
                <div className="FilesAdded">
                  <label>{supportingDocFiless?.name}</label>
                  <span>
                    <img src="images/fileview.png" />
                  </span>
                  <span onClick={handleRemoveDocsFile}>
                    <img src="images/Cross.png" className="cross" />
                  </span>
                </div>
              )}

              <div className="Button mt-3">
                <button className="Create" >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={TodoTaskDeleteModal}
        onHide={handleTodoTaskDeleteModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleTodoTaskDeleteModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Delete</h4>
                </div>
                <div className="FolderForm">
                  <div className="my-5">
                    <h6>Are you sure you want to delete this ?</h6>
                  </div>
                  <div className="Button d-flex justify-content-between">
                    <button
                      className="Create"
                      // onClick={handleDocumentationDelete}
                    >
                      Delete
                    </button>
                    <button
                      className="Outline"
                      onClick={handleTodoTaskDeleteModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
          show={UpdateReminderModal}
          onHide={handleUpdateReminderModalClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a className="CloseModal" onClick={handleUpdateReminderModalClose}>
                  ×
                </a>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>
                      Weeks <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={day}
                        name="week"
                        value={week}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of weeks for reminder
                        </option>
                        <option value="7">1</option>
                        <option value="14">2</option>
                        <option value="21">3</option>
                        <option value="28">4</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>
                      Days <span className="required"></span>
                    </h6>
                    <div className="CommonSelectBox w-100">
                      <select
                        disabled={week}
                        name="day"
                        value={day}
                        onChange={handleInputChange}
                      >
                        <option value="">
                          Select amount of days for reminder
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                      </select>
                      <span>
                        <img src="images/down.png" />
                      </span>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create" onClick={updateReminder}>
                      Set Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
        show={ViewTaskModal}
        onHide={handleViewTaskClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleViewTaskClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>View To-Do</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>To-Do {todoViewTaskDetails?.task?.id}</h2>
                </div>
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Job</h6>
                    <p className="ml-0">{todoViewTaskDetails?.task?.JobId}</p>
                  </div>
                  {/* <div className="form-group">
                    <h6>
                      Schedule related To-Do?<span className="required">*</span>
                    </h6>
                    <p className="ml-0">Yes</p>
                  </div>
                  <div className="form-group">
                    <h6>Schedule's Task</h6>
                    <p className="TextBlue2 ml-0">1.3.1.3</p>
                  </div> */}
                  <div className="form-group">
                    <h6>
                      Description <span className="required">*</span>
                    </h6>
                    <h6 className="fw-400">
                      {todoViewTaskDetails?.task?.description}
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>Date Created</h6>
                        <p className="ml-0">
                          {moment(todoViewTaskDetails?.task?.createdAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        <h6>Target Date</h6>
                        {todoViewTaskDetails?.task?.targetDate !== null && (
                          <p className="ml-0">
                            {moment(todoViewTaskDetails?.task?.targetDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <h6>Date Assigned</h6>
                        {todoViewTaskDetails?.task?.assignDate !== null && (
                          <p className="ml-0">
                            {moment(todoViewTaskDetails?.task?.assignDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <h6>Date Completed</h6>
                        {todoViewTaskDetails?.task?.completeDate !== null && (
                          <p className="ml-0">
                            {moment(todoViewTaskDetails?.task?.completeDate).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Outline" onClick={handleViewTaskClose}>
                      Close
                    </button>
                  </div>
                  <div className="DiscussionArea">
                    <div className="DiscussionTitle">
                      <h4>Discussion</h4>
                      <div className="DiscussionChat">
                        <img src="images/down.png" className="DiscussionIcon" />
                        <a href="#">
                          <img src="images/pdf2.png" />
                        </a>
                      </div>
                    </div>
                    <div
                      className="DiscussionHideArea"
                      style={{ display: "block" }}
                    >
                      <div className="DiscussionBox">
                        <span>FM</span>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add a Comment ....."
                          />
                          <div className="DiscussionOverlay">
                            <a href="" className="CancelChat">
                              <img src="images/plus-cancel1.png" />
                            </a>
                            <a href="" className="SendChat">
                              <img src="images/forwardbutton.png" />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="DiscussionBox">
                        <span>TH</span>
                        <div className="DiscussionRight">
                          <h6>Inspections are delayed</h6>
                          <small>04/19/2023, at 02:47 pm</small>
                        </div>
                      </div>
                      <div className="ReplyArea">
                        <a href="javascript:void(0);">Reply</a>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>Inspections are delayed</h6>
                            <small>04/19/2023, at 02:47 pm</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={TaskHistoryModal}
        onHide={handleTaskHistoryModalClose}
        className="ModalBox fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleTaskHistoryModalClose}>
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Task History</h4>
                </div>
                <div className="ModalTitleHeading">
                  <h2>Task #112</h2>
                  <div className="Actions">
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList TableHeightScroll">
                  <table>
                    <thead>
                      <tr>
                        <th>Action Taken</th>
                        <th>Date</th>
                        <th>Performed By</th>
                        <th>Assigned User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Completed</td>
                        <td>02/05/2023 02:30 PM</td>
                        <td>John Doe</td>
                        <td>Jane Williams</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={MoveTaskModal}
        onHide={handleMoveTaskModalClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleMoveTaskModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Move Task</h4>
                </div>
                <div className="FolderForm">
                  <h3>Task #112</h3>
                  <h6 className="mb-4">Job: CHI-2B-345</h6>
                  <form>
                    <div className="form-group">
                      <h6>Create New To-Do Task</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleAddTaskShow}
                        >
                          <span>
                            <img src="images/icon-35.png" />
                          </span>{" "}
                          Create New To-Do Task
                        </h5>
                      </div>
                    </div>
                    <div className="form-group">
                      <h6>Create New Itineraty item</h6>
                      <div className="PermissionsName">
                        <h5
                          className="CursorPointer FolderPermissionId"
                          onClick={handleAddItineraryModalShow}
                        >
                          <span>
                            <img src="images/double-check.png" />
                          </span>{" "}
                          Create New Itineraty item
                        </h5>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={AddItineraryModal}
        onHide={handleAddItineraryModalClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal ClosePermissionId"
                onClick={handleAddItineraryModalClose}
              >
                ×
              </a>

              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Add New Itinerary Item</h4>
                </div>
                <div className="FolderForm">
                  <h6>Item #12</h6>

                  <div className="form-group">
                    <h6>Subject</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Executive Meeting"
                      name="subject"
                      value={subject}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <h6>Description</h6>
                    <textarea
                      rows="5"
                      className="form-control"
                      name="descriptions"
                      value={descriptions}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <h6>Location</h6>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Room 1"
                      name="location"
                      value={location}
                      onChange={handleInputChange}
                    />
                  </div>
                  <h6>Select New Date</h6>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Date Option 1</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={date}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>From</label>
                      <input
                        type="time"
                        className="form-control"
                        name="fromTime"
                        value={fromTime}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group col-4">
                      <label>To</label>
                      <input
                        type="time"
                        className="form-control"
                        name="toTime"
                        value={toTime}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="Filter row">
                    <div className="form-group col-4">
                      <label>Type</label>
                      <select
                        className="form-control"
                        name="type"
                        value={type}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Type</option>
                        <option value="Meeting">Meeting</option>
                        <option value="Phone Call">Phone Call</option>
                        <option value="Text">Text</option>
                        <option value="Task">Task</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="CommonModalArea ml-auto col-4">
                      <div className="form-group">
                        <h5>Set Reminder</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="FolderPermissionId CursorPointer"
                            onClick={handleReminderIntShow}
                          >
                            <figure>
                              <img src="images/timer-red.png" />
                            </figure>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>Itinerary is related to Job or Schedule's Task?</h6>
                  <div className="row">
                    <div className="form-group col-6">
                      <div className="d-flex justify-content-between">
                        <label className="CheckBox">
                          Job
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "job")}
                            checked={relatedToJob}
                          />
                          <span className="checkmark" />
                        </label>
                        <label className="CheckBox">
                          Schedule
                          <input
                            type="checkbox"
                            onChange={(e) => handleJobTaskChecked(e, "sch")}
                            checked={relatedToTask}
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Categories Categories1 mb-3">
                    <div className="Categories_all Categories_all1">
                      <span>Select a folder to insert the file</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>
                        <input type="text" placeholder="-Search" />
                        <span className="Search">
                          <img src="images/search.png" />
                        </span>
                      </li>
                      <li>- List of Jobs already filtered</li>
                    </ul>
                  </div>
                  <div className="Categories Categories1">
                          <div
                            className="Categories_all Categories_all1"
                            onClick={handleIternaryJobToggle}
                          >
                            <span>Select a Job</span>
                            <img src="images/down.png" />
                          </div>
                          {toogleIternaryJob && (
                            <ul>
                              <li>
                                <input
                                  type="text"
                                  placeholder="-Search"
                                  name="search"
                                  value={search}
                                  onChange={handleInputChange}
                                />
                                <span className="Search">
                                  <img src="images/search.png" />
                                </span>
                              </li>
                              {jobList?.jobs?.length > 0 &&
                                jobList.jobs
                                  .filter((data) =>
                                    search.toLowerCase() === ""
                                      ? true
                                      : data.jobType
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                  )
                                  .map((item, i) => (
                                    <li
                                      value={item.id}
                                      key={i}
                                      onClick={() => handleIternaryItemClick(item)}
                                      style={{
                                        backgroundColor:
                                        jobId === item.id &&
                                        jobType === item.jobType
                                            ? "#e71e09"
                                            : "transparent",
                                      }}
                                    >
                                      {item.jobType}
                                    </li>
                                  ))}
                            </ul>
                          )}
                        </div>
                  <div className="StaffMemberArea">
                    <div className="row">
                      <div className="col-4">
                        <h6>Assign Staff Member</h6>
                        <div className="StaffName">
                          <img src="images/member.png" />
                          <div className="Count">3</div>
                          <h6 
                          
                          onClick={handleAssignStaffMemberIntShow}
                          >
                            Assign
                          </h6>
                        </div>
                      </div>
                      <div className="col-4 ml-auto">
                        <h6>Assign Staff member</h6>
                        <div className="StaffBox">
                          <div className="StaffName">
                            <p>{assignStaffss}</p>
                            <img
                              src="images/Cross.png"
                              onClick={removeAssignIntMember}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      // onClick={handleAddItinerary}
                    >
                      Create new Itinerary Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AssignStaffMemberModalInt}
        onHide={handleAssignStaffMemberIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={handleAssignStaffMemberIntClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Assign Staff Member</h4>
                </div>
                <div className="FolderForm">
                  <h3>Staff Member Information</h3>
                  <h6>Name</h6>

                  <div className="Categories Categories1 mb-4">
                    <div className="Categories_all Categories_all1">
                      <span>Assign Staff Member Modal</span>
                      {/* <img src="images/down.png" />      */}
                      <select
                        className="form-control"
                        name="assignStaffss"
                        value={assignStaffss}
                        onChange={handleInputChange}
                      >
                        <option value="">Select </option>
                        {memberList?.staff?.length > 0 &&
                          memberList?.staff?.map((item, i) => {
                            return (
                              <option value={item.id}>{item.email}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <h6>View Shared Task List</h6>
                    <div className="PermissionsName">
                      <h5
                        className="CursorPointer FolderPermissionId"
                        onClick={handleViewSharedTaskModalShow}
                      >
                        <span>
                          <img src="images/share.png" />
                        </span>
                        <div className="Count">3</div>View Shared Task List
                      </h5>
                    </div>
                  </div> */}
                  <div
                    className="Button text-center mt-5"
                    onClick={handleAssignIn}
                  >
                    <button className="Create mr-4">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ReminderModalInt}
        onHide={handleReminderIntClose}
        className="ModalBox SmallModal"
      >
        <Modal.Body>
          <div>
            <div>
              <a className="CloseModal" onClick={handleReminderIntClose}>
                ×
              </a>
              <div className="FolderForm">
                <div className="form-group">
                  <h6>
                    Weeks <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={day}
                      name="week"
                      value={week}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of weeks for reminder
                      </option>
                      <option value="7">1</option>
                      <option value="14">2</option>
                      <option value="21">3</option>
                      <option value="28">4</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <h6>
                    Days <span className="required"></span>
                  </h6>
                  <div className="CommonSelectBox w-100">
                    <select
                      disabled={week}
                      name="day"
                      value={day}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select amount of days for reminder
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <span>
                      <img src="images/down.png" />
                    </span>
                  </div>
                </div>
                <div className="Button text-center mt-5">
                  <button className="Create" onClick={handleReminderInt}>
                    Set Reminder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default TodoTradeview;
