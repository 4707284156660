import React, { useState,useEffect } from 'react'
import { clientViewGeneralConfig, createClientComment, createClientSubComment, getChangeOrderList, getClientAllAppointmentList, getClientCommentList, getClientSubCommentList, getNotifications, getUploadIconClientImage, getUploadMainClientImage, getWarrantyRequestList } from '../redux/action/clientViewAuthAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import moment from "moment";
import ClientViewSidenav from './client-view-sidenav';
import ClientHeader from './ClientHeader';
import { headerNotifcaitionList } from '../redux/action/authAction';
import { ProgressBar } from 'react-bootstrap';
import { createTradeComment } from '../redux/action/tradeViewAuthAction';
const initialState={
  file:null,
  mainFile:null,
  notificationData:[],
  content: "",
  projectId: "",
  investorId: "",
  subContent: "",
  index: "",
  parentId: "",
}
const ClientViewHome = () => {

  const dispatch=useDispatch();
  const [iState,updateState]=useState(initialState);
  const {file,mainFile,notificationData,content,
    projectId,
    investorId,
    subContent,
    index,
    parentId,}=iState;
  const clientViewData=JSON.parse(window.localStorage.getItem("clientViewLogin"));
 
  const {commentList,subCommentList,warrantyRequestList,clientChangeOrdereList,allAppointmentList}=useSelector((state)=>state.clientViewLogin)
  
  // console.log("allAppointmentList", allAppointmentList)


  useEffect(()=>{
    dispatch(clientViewGeneralConfig()).then((res) => {
      dispatch(
        getClientCommentList(
          res?.data?.ViewSetting?.id,
          clientViewData?.user?.ClientId,
        )
      );
      updateState({ ...iState, investorId: res?.data?.ViewSetting?.id });
    });
      dispatch(getWarrantyRequestList());
      dispatch(
        getChangeOrderList(
          clientViewData?.jobDetails?.ProjectId,
          clientViewData?.jobDetails?.id,
          clientViewData?.jobDetails?.jobType
        )
      );


    dispatch(getClientAllAppointmentList(clientViewData?.user?.CompanyId,
      clientViewData?.jobDetails?.ProjectId,
      clientViewData?.jobDetails?.id,
      clientViewData?.jobDetails?.jobType))
    
  },[])
  const handleInputChange = (e) => {
    const { name, value } = e.target;
      updateState({
        ...iState,
        [name]: value,
      });
    }


  const handleFileChanges = (event) => {
    const file = event?.target?.files[0];
    updateState({ ...iState, file: file });
    if (file != null) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(getUploadIconClientImage( clientViewData?.user?.ClientId,formData)).then(
        (res) => {
          if (res.status === 200) {
            // dispatch(getTradeVendorByUserId());
            console.log("---------->", res)
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({ ...iState, file: null });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const handleMainFileChanges = (event) => {
    const files = event?.target?.files[0];
    updateState({ ...iState, mainFile: files });
    if (files != null) {
      const formData = new FormData();
      formData.append("file", files);
      dispatch(getUploadMainClientImage(clientViewData?.user?.ClientId, formData)).then(
        (res) => {
          if (res.status === 200) {
            // dispatch(getTradeVendorByUserId());
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            updateState({ ...iState, mainFile: null });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  }; 
  
  useEffect(() => {
    dispatch(
      getChangeOrderList(
        clientViewData?.jobDetails?.ProjectId,
        clientViewData?.jobDetails?.id,
        clientViewData?.jobDetails?.jobType
      )
    );
    dispatch(getNotifications()).then((res)=>{
      if(res.status===200){
       
        updateState({...iState,notificationData:res.data.notifications})
      }
    })
  }, []);


 
  const handleCreateComment = () => {
    const dataa = { content };
    dispatch(
      createClientComment(investorId, clientViewData?.user?.CompanyId, dataa)
    )
    .then((res) => {
      if (res.status === 200) {
        dispatch(getClientCommentList(investorId, clientViewData?.user?.CompanyId));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          content: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleRemoveComments = (type) => {
    if (type === "comment") {
      updateState({ ...iState, content: "" });
    } else {
      updateState({ ...iState, subContent: "" });
    }
  };
  const handleSubCreateComment = (id) => {
    const dataa = { content: subContent, parentId: id };
    dispatch(
      createClientSubComment(investorId, clientViewData?.user?.CompanyId, dataa)
    ).then((res) => {
      if (res.status === 200) {
        dispatch(
          getClientSubCommentList(investorId, id, clientViewData?.user?.CompanyId)
        );
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          subContent: "",
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };


  return (
    <>
      <ClientHeader/>
      <ClientViewSidenav />
    <div className="WrapperAreaClient">
      <div className="WrapperBoxClient">
        <div className="TitleBoxClient">
          <h4 className="TitleClient">Project Overview</h4>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="ProjectViewTop">
            <figure>
                    {/* <img src={require("../assets/images/project_view_banner.png")} /> */}
                    {/* <img src={vendorTradeData?.VendorList?.mainUrl} /> */}    
                     <img src={clientViewData?.client?.mainUrl  } />
                    <div className="CameraOverlay">
                      <input
                        type="file"
                        onChange={(e) => handleMainFileChanges(e)}
                      />
                      <img src="images/camera_icon.png" />
                    </div>
                  </figure>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="ProjectProgress">
                  <h6>Project Progress</h6>
                  <div className="ProjectProgressArea">
                    <div className="Progress_heading">
                      <h6>Insulation, Boarding &amp; Taping</h6>
                      <p>Jan 23</p>
                    </div>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: "100%" }} />
                    </div>
                    <div className="progress-bar-overlay">
                      <img src="images/check_progress.png" />
                    </div>
                  </div>
                  <div className="ProjectProgressArea">
                    <div className="Progress_heading">
                      <h6>Insulation, Boarding &amp; Taping</h6>
                      <p>Jan 23</p>
                    </div>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: "100%" }} />
                    </div>
                    <div className="progress-bar-overlay">
                      <img src="images/check_progress.png" />
                    </div>
                  </div>
                  <div className="ProjectProgressArea">
                    <div className="Progress_heading">
                      <h6>Paint, Wall &amp; Floor Tile &amp; Flooring</h6>
                      <p>Jan 23</p>
                    </div>
                    <div className="progress">
                    <ProgressBar now="34" />
                      {/* <div className="progress-bar" style={{ width: "100%" }} /> */}
                    </div>
                    <div className="progress-bar-overlay">
                      <img src="images/check_progress.png" />
                    </div>
                  </div>
                  <div className="ProjectProgressArea">
                    <div className="Progress_heading">
                      <h6>Balance of Finals, Cleaning &amp; Possession</h6>
                      <p>Mar 23</p>
                    </div>
                    <div className="progress">
                      <div className="progress-bar" style={{ width: "92%" }}>
                        92%
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ProjectProgress">
                  <h6>Project Progress</h6>
                  <div className="ProjectProgresstable">
                    <table>
                      <thead>
                        <tr>
                          <th>Payment</th>
                          <th>Amount</th>
                          <th>Due Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Down Payment Deposit 1</td>
                          <td>$1,000.00</td>
                          <td>$1,000.00</td>
                        </tr>
                        <tr>
                          <td>Down Payment Deposit 1</td>
                          <td>$1,000.00</td>
                          <td>$1,000.00</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="ProjectProgress HeightFix">
                  <h6>Change Orders</h6>
                  <div className="ChangeOrdersArea">
                    {
                       clientChangeOrdereList?.data?.length > 0 &&
                       clientChangeOrdereList?.data?.map((data)=>{
                        return(
                          <a>
                          <img src="images/update_4.png" />
                          Change Orders #{data.id}<span className="Count">{data.supportDocCount??0}</span>
                        </a>
                        )
                       })
                    }
                   
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="HomeUpdatesArea">
                  <div className="HomeUpdatesAreaTop">
                    <h6>Updates</h6>
                    <h6>Dates</h6>
                  </div>
                  <div className="HomeUpdatesAreaBottom">
                    {
                      notificationData?.length>0&&
                      notificationData?.map((data,i)=>{
                        return(
                          <div className="HomeUpdatesAreaRow">
                          <h6>
                            <img src="images/update_1.png" />
                            {data.notifiMessage}
                          </h6>
                          <p>{moment(data.sendDate).format("MM/DD/YYYY - hh:mm A")}</p>
                        </div>
                        )
                      })
                    }
                   
                    {/* <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_2.png" />1 new document to sign!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_3.png" />
                        Warranty Request - Plumbing Fixes was completed! Click
                        here to view and approve!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_4.png" />
                        New change order Change order one was created!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div>
                    <div className="HomeUpdatesAreaRow">
                      <h6>
                        <img src="images/update_5.png" />3 new discussion messages
                        arrived!
                      </h6>
                      <p>05/25/2023 - 02:00 PM</p>
                    </div> */}
                  </div>
                </div>
                <div className="HomeUpdatesArea">
                  <div className="HomeUpdatesAreaTop">
                    <h6>Updates</h6>
                    <a href="javascript:void(0);">view all</a>
                  </div>
                  <div className="HomeUpdatesAreaRecent">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_1.png" />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_2.png" />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_3.png" />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_4.png" />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_5.png" />
                        </figure>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-12">
                        <figure>
                          <img src="images/recent_6.png" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="ProjectProgress Calendar">
                  <div className="SmallCalendarLeft">
                    <h6>Calendar</h6>
                    {
                      allAppointmentList?.data?.length > 0&&
                      allAppointmentList?.data?.map((item)=>{
                        return(
                          <div className="LightGreenBg Date">
                          <p>
                          {item.title}
                            <img
                              src="images/calendar_multi.png"
                              className="CursorPointer"
                            />
                          </p>
                        </div>
                        )
                      })
                    }
                   
                    
                  </div>
                  {/* <figure>
                    <img src="images/small_calendar.png" />
                    <p>
                      <img src="images/earth.png" />
                      Mountain time - US &amp; Canada (08:08 am){" "}
                      <img src="images/black-down-arrow.png" />
                    </p>
                  </figure> */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="ProjectProgress WarrantyHeightFix">
                  <h6>Warranty Requests</h6>
                  <div className="ChangeOrdersArea">
                    {
                       warrantyRequestList?.warranties?.length>0&&
                       warrantyRequestList?.warranties?.map((item)=>{
                        return(
                          <a>
                          <img src="images/plumbing_fix.png" />
                          {item.description??"N/A"}<span className="Count">{item.supportDocCount??0}</span>
                        </a>
                        )
                       })
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
          <div className="UserDetailsArea">
              <div className="UserDetailsBox">
                <figure>
                <img src={clientViewData?.client?.iconUrl  } />
                  <div className="CameraOverlay">
                    <input
                      type="file"
                      onChange={(e) => handleFileChanges(e)}
                    />
                     <img src="images/camera_icon.png" />
                    </div>
                </figure>
                <p>Welcome back {clientViewData?.user?.name}!</p>
              {/* <figure>
                      <img src={vendorTradeData?.VendorList?.iconUrl} />
                      <div className="CameraOverlay">
                        <input
                          type="file"
                          onChange={(e) => handleFileChanges(e)}
                        />
                        <img src="images/camera_icon.png" />
                      </div>
                    </figure> */}
                
                {/* <p>Your project</p> */}
                <h6>
                {clientViewData?.projectDetails?.projectName}
                  <br />
                  {clientViewData?.projectDetails?.projectEmail}
                </h6>
                <p>
                {clientViewData?.projectDetails?.projectAddress} <br />   {clientViewData?.projectDetails?.city}
                </p>
              </div>
              <div className="SalesContactBox FinanceBox">
                <h6>
                  Finances{" "}
                  <img
                    src="images/finance_icon.png"
                    data-toggle="modal"
                    data-target="#FinancesDetails"
                    className="CursorPointer"
                  />
                </h6>
                <table>
                  <tbody>
                    <tr>
                      <td>Contract Price</td>
                      <td>{clientViewData?.financesData?.contractPrice}</td>
                    </tr>
                    <tr>
                      <td>Selections</td>
                      <td>{clientViewData?.financesData?.selections}</td>
                    </tr>
                    <tr>
                      <td>Change Orders</td>
                      <td>{clientViewData?.financesData?.changeOrders}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>Total Price</td>
                      <td>{clientViewData?.financesData?.totalPrice}</td>
                    </tr>
                  </tfoot>
                </table>
                <h6 className="TableHeading">Adjustments and Deposits</h6>
                <table>
                  <tbody>
                    <tr>
                      <td>Adjusments</td>
                       <td>{clientViewData?.financesData?.adjustmentsAndDeposits?.adjustments}</td>
                    </tr>
                    <tr>
                      <td>Down Payment Paid</td>
                       <td>{clientViewData?.financesData?.adjustmentsAndDeposits?.downPaymentPaid}</td>
                    </tr>
                    <tr>
                      <td>Down Payment Required</td>
                      <td>{clientViewData?.financesData?.adjustmentsAndDeposits?.downPaymentRequired}</td>
                    </tr>
                  </tbody>
                </table>
                {/* <h6 className="StatementAdjustment">
                  <img src="images/statement_adjustment.png" /> Statement of
                  Adjustments <span className="Count">3</span>
                </h6> */}
              </div>
              <div className="SalesContactBox">
                <h6>Sales Contact</h6>
                <h6 className="UserName">{clientViewData?.client?.legalName}</h6>
                <p>
                  <img src="images/send-email.png" />
                  {clientViewData?.client?.email}
                </p>
                <p>
                  <img src="images/call.png" />
                  {clientViewData?.client?.businessPhoneNum}
                </p>
              </div>
              <div className="SalesContactBox">
                <h6>Builder information</h6>
                <h6 className="Brand">Multi Builder</h6>
                <p className="Address">
                  52 Ave SW, Calgary,
                  <br />
                  Alberta, TM3 1Y3
                </p>
                <p>
                  <img src="images/send-email.png" />
                  mailto:janewilliams@multibuilder.com
                </p>
                <p>
                  <img src="images/call.png" />
                  (587)5555544
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="DiscussionArea">
              <div className="DiscussionTitle">
                <h4>Discussion</h4>
                <div className="DiscussionChat">
                  <img src="images/down.png" className="DiscussionIcon" />
                  <a href="#">
                    <img src="images/pdf2.png" />
                  </a>
                </div>
              </div>
              <div className="DiscussionHideArea" style={{ display: "block" }}>
                <div className="DiscussionBox">
                  <span>FM</span>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Add a Comment ....."
                      name="content"
                      value={content}
                      onChange={handleInputChange}
                    />
                    <div className="DiscussionOverlay">
                      <a
                        className="CancelChat"
                        onClick={() => handleRemoveComments("comment")}
                      >
                        <img src="images/plus-cancel1.png" />
                      </a>
                      <button
                        className="SendChat"
                        onClick={handleCreateComment}
                        disabled={content == ""}
                      >
                        <img src="images/forwardbutton.png" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="DiscussionBox"></div>
                {commentList?.comments?.length > 0 &&
                  commentList?.comments
                    ?.sort((a, b) => b.id - a.id)
                    ?.map((item, ind) => {
                      return (
                        <div className="DiscussionBox">
                          <span>TH</span>
                          <div className="DiscussionRight">
                            <h6>{item.content}</h6>
                            <small>
                              {moment(item.createdAt).format(
                                "MM/DD/YYYY, [at] hh:mm A"
                              )}
                            </small>

                            <div class="ReplyArea">
                              <a
                                onClick={() =>
                                  updateState({
                                    ...iState,
                                    index: ind,
                                    parentId: item.id,
                                  })
                                }
                              >
                                Reply
                              </a>
                              {index === ind &&
                                subCommentList?.comments?.length > 0 &&
                                subCommentList?.comments?.map((ele) => (
                                  <div class="DiscussionBox">
                                    <span>RE</span>
                                    <div class="DiscussionRight">
                                      <h6>{ele.content}</h6>
                                      <small>
                                        {moment(ele.createdAt).format(
                                          "MM/DD/YYYY, [at] hh:mm A"
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                ))}
                              {index === ind && (
                                <div class="DiscussionBox w-100">
                                  <span>FM</span>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Add reply ....."
                                      name="subContent"
                                      value={subContent}
                                      onChange={(e) => handleInputChange(e)}
                                    />

                                    <div class="DiscussionOverlay">
                                      <a
                                        onClick={() =>
                                          handleRemoveComments("subcomment")
                                        }
                                        class="CancelChat"
                                      >
                                        <img
                                          src={require("../assets/images/plus-cancel1.png")}
                                        />
                                      </a>
                                      <a
                                        onClick={() =>
                                          handleSubCreateComment(item.id)
                                        }
                                        class="SendChat"
                                      >
                                        <img
                                          src={require("../assets/images/forwardbutton.png")}
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
      </div>
    </div>
    

    
    
  </>
  
  
  )
}

export default ClientViewHome