import React from 'react'
import ClientHeader from './ClientHeader'
import ClientViewSidenav from './client-view-sidenav'

const ChangeOrderClientViewDetails = () => {
  return (
    <>
      <ClientHeader />
        <ClientViewSidenav />
    <div className="WrapperAreaClient">
  <div className="WrapperBoxClient">
    {/* <div className="d-flex flex-wrap mb-5">
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AdjustmentRequest"
      >
        AdjustmentRequest
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#SignAgreement"
      >
        SignAgreement
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AdjustmentRequestList"
      >
        (AdjustmentRequestList)
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#RequestAdjustmentDetails"
      >
        Request Adjustment Details
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#CreateMeasure"
      >
        Create Measure
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AgreementView"
      >
        Agreement View
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#EditAgreementView"
      >
        Edit Agreement View
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AddCustomItem"
      >
        Add Custom Item
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#UploadImages"
      >
        UploadImages
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#SelectAccounts"
      >
        Select Accounts
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AddNewClient"
      >
        Add New Client
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ClientAgreementEsign"
      >
        Client Agreement E-sign
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#SelectItemsfromPriceBook"
      >
        Select Items from Price Book
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ConfirmationAdjustments"
      >
        Confirmation Adjustments
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ChangeOrderPurchaseOrderList"
      >
        Change Order Purchase Order List
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#AgreementHistory"
      >
        Agreement History
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#RequestAdjustments"
      >
        Request Adjustments
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ManageUser"
      >
        Manage User
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#InspectionDatesSelection"
      >
        Inspection Dates Selection
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#FinancesDetails"
      >
        Finances Details
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ConfirmationEsign"
      >
        ConfirmationE Sign
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </div> */}
    {/* <div className="d-flex flex-wrap mb-5">
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ShareDocument"
      >
        Share Documents
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#DocumentHistory"
      >
        Document History
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#FolderHistory"
      >
        Folder History
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ManageSignatures"
      >
        Manage Signatures
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ClientChangeRequestList"
      >
        Client Change Request List
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ClientChangeRequestDetails"
      >
        Client Change Request Details
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ClientChangeRequestDetails2"
      >
        Client Change Request Details2
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#ReminderModal"
      >
        Reminder Modal
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#PendingDateSelectionList"
      >
        Pending Date Selection List
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#CreateNewFile"
      >
        Create New File
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#CreateNewFileBuild"
      >
        Create New File Build
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h6
        className="CursorPointer"
        data-toggle="modal"
        data-target="#PendingDateSelectionListTable"
      >
        Pending Date Selection List Table
      </h6>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </div> */}
    <div className="TitleBox d-flex flex-md-row flex-column align-items-md-center align-items-start">
      <h4 className="Title">Change Order #1</h4>
      <div className="TitleBoxProjectHeading">
        <h6>
          <span>Status:</span>&nbsp;Purchase Orders Created
        </h6>
        <h6>
          <span>Authorization Status:</span>&nbsp;In progess
        </h6>
      </div>
      <div className="TitleBox2 TitleBoxRight">
        <div>
          <h6>UUnit3 -CHI -2B -315</h6>
          <span>Multi-Family - Residential Unit</span>
        </div>
      </div>
    </div>
    <div className="WarrantyDashboardForm mb-4">
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>
                  Description <span className="Required">*</span>
                </label>
                <p>Lorem Ipsum</p>
                {/* <textarea
                class="form-control"
                rows="3"
                placeholder="Write here ....."
              ></textarea> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Supporting Document</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs2"
                >
                  <figure>
                    <img src="images/document-support.png" />
                  </figure>
                  Supporting Document
                </h5>
                <span
                  className="Count CursorPointer"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                >
                  3
                </span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="CommonModalArea">
              <h5>Add Notes</h5>
              <div className="CommonModalBox">
                <h5
                  className="CursorPointer"
                  data-toggle="modal"
                  data-target="#NoteAdd2"
                >
                  <figure>
                    <img src="images/document-support.png" />
                  </figure>
                  Add Notes
                </h5>
                <span
                  className="Count CursorPointer"
                  data-toggle="modal"
                  data-target="#NotesList"
                >
                  3
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="WarrantyDashboardForm">
      <h4>Client Details</h4>
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Possession Date</label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Request Date</label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Client Full Legal Name</label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Phone Number</label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>Primary Email</label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>
                  Job Address <img src="images/location.png" className="ml-2" />
                </label>
                <p>Auto-filled by System</p>
                {/* <input
                type="text"
                class="form-control"
                placeholder="Auto-filled by System"
              /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="Profile_ontario mb-5">
            <div className="Profile_ontario_box">
              <h6>Profile:</h6>
              <p>Ontario Tax</p>
            </div>
            <div className="Profile_ontario_box">
              <h6>HST:</h6>
              <p>13%</p>
            </div>
          </div>
          {/* <div class="form-group mb-5">
          <div class="CommonModalArea">
           <h5>Adjust Change Order</h5>
          <div class="CommonModalBox">
            <h5 class="CursorPointer" data-toggle="modal" data-target="#SelectItemsfromPriceBook" data-dismiss="modal"><figure><img src="images/adjust_change_order.png"></figure>Adjust Change Order</h5>
          </div>
          </div>
        </div>



        <div class="form-group">
          <div class="CommonModalArea">
           <h5>Add Items from Pricebook</h5>
          <div class="CommonModalBox">
            <h5 class="CursorPointer" data-toggle="modal" data-target="#SelectItemsfromPriceBook" ><figure><img src="images/add_items_from_pricebook.png"></figure>Add Items from Pricebook</h5>
          </div>
          </div>
        </div>
        <div class="form-group">
          <div class="CommonModalArea">
           <h5>Add Custom Item</h5>
          <div class="CommonModalBox">
            <h5 class="CursorPointer" data-toggle="modal" data-target="#AddCustomItem">
              <figure><img src="images/add_custom_item.png"></figure>Add Custom Item</h5>
          </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
    <div className="Tableheader mt-5">
      <h6>Change Items</h6>
    </div>
    <div className="TableList NewTableList TableListHeader TableHeightScroll">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Measure</th>
            <th>Per Unit</th>
            <th>Client Pre-Tax</th>
            <th>Client Total</th>
            <th>Markup</th>
            <th>Pictures</th>
            <th>Supporting Doc</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              Sandstone Color <br /> Pallete
            </td>
            <td>2</td>
            <td>Per</td>
            <td>$1,000.00</td>
            <td>$1,000.00</td>
            <td>$1,000.00</td>
            <td>20 %</td>
            <td>
              <figure>
                <img src="images/picture-11.png" />
              </figure>
            </td>
            <td>
              <div className="Actions">
                <a
                  href="javascript:void(0);"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs2"
                >
                  <img src="images/icon-63.png" />
                </a>
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                >
                  <span className="Count">3</span>
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={10}>2</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="TodosButtons mt-5">
      <a
        href="javascript:void(0)"
        data-toggle="modal"
        data-target="#AdjustmentRequestList"
      >
        <h6>
          <img src="images/show-create-purchase.png" />
          Adjustment Requests
        </h6>
        <span>!</span>
      </a>
    </div>
    <div className="ClientContactArea mt-5">
      <div className="ClientContactTitle ClientContactTitle1">
        <h4>Client Contact</h4>
        <div className="DiscussionChat">x``
          <img src="images/down.png" className="DiscussionIcon" />
        </div>
      </div>
      <div className="ClientContactHideArea ClientContactHideArea1">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-5">
                <div className="UrgencyArea mb-5">
                  <div className="form-group">
                    <label>Builder Signee</label>
                    <p className="fw-600 ml-0">
                      John Doe(johh.doe@builder.com)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ml-auto">
                <div className="FolderForm">
                  <div className="form-group">
                    <h6>Client Signees</h6>
                    <h6>Number of Signees</h6>
                    <p className="ml-0">2</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <label>Primary Client Signee</label>
                    <p>John Joe(johh.joe@client.com)</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ml-auto">
                <div className="UrgencyArea">
                  <div className="form-group">
                    <label>Additional Client Signee</label>
                    <p>Jill Jill(jill.jill@client.com)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 ml-auto">
            <div className="FolderForm">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <p className="RedStatusColor ml-0">
                        Pending with John Doe
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#ClientAgreementEsign"
                      >
                        Sign Document
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <div className="ClinetSignatureStatus">
                      <h6>Signature Status</h6>
                      <p className="GreenSignedColor ml-0 mb-4">Signed</p>
                      <p className="RedStatusColor ml-0">Pending</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <h6 className="">Request Adjustments Function</h6>
                    <label className="CheckBox">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ClientContactArea">
      <div className="ClientContactTitle ClientContactTitle2">
        <h4>Agreement Document</h4>
        <div className="DiscussionChat">
          <img src="images/down.png" className="DiscussionIcon" />
        </div>
      </div>
      <div className="ClientContactHideArea ClientContactHideArea2">
        <div className="FolderForm">
          <form>
            <div className="row">
              <div className="form-group col-lg-6 mb-4">
                <div className="CommonModalArea">
                  <h5>Agreement Document</h5>
                  <div
                    className="CommonModalBox CursorPointer"
                    data-toggle="modal"
                    data-target="#AgreementView"
                  >
                    <figure className="mb-0">
                      <img src="images/agreement_document.png" />
                    </figure>
                    <span className="Count CursorPointer">3</span>
                    <h5 className="ml-2"> Agreement Document</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ModalFilterAreaBox">
                  <h4 className="mb-4">
                    Client Acknowledgement of Change Contract
                  </h4>
                  <div className="RadioArea">
                    {/* <div class="form-group">
                          <label class="Radio">Electronic
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-group ml-5">
                          <label class="Radio">Hardcopy
                            <input type="radio" name="Cuisine">
                            <span class="checkmark"></span>
                          </label>
                        </div> */}
                    <div className="form-group">
                      <label className="Radio">
                        Electronic
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="text"
                          defaultChecked=""
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div className="form-group ml-5">
                      <label className="Radio">
                        Hardcopy
                        <input
                          type="radio"
                          name="Cuisine"
                          defaultValue="file"
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                {/* <div class="text RadioText">
                  <div class="form-group">
                    <h6>Message (Max 1000 chars limit)</h6>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Write here ....."
                    />
                    <small class="form-text text-right"
                      >985 characters remaining</small
                    >
                  </div>
                </div> */}
                <div className="file RadioText">
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <div className="CommonModalArea">
                        <h5>Download Hardcopy Agreement</h5>
                        <div className="CommonModalBox">
                          <figure className="mb-0">
                            <img src="images/agreement_document.png" />
                          </figure>
                          <h5 className="ml-2"> Download Hardcopy Agreement</h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-6">
                      <div className="CommonModalArea">
                        <h5>Upload Signed Agreement</h5>
                        <div className="CommonModalBox">
                          <figure className="mb-0">
                            <img src="images/agreement_document.png" />
                          </figure>
                          <h5 className="ml-2"> Download Hardcopy Agreement</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-4">
                  <h6>Read-Only Carbon Copies</h6>
                  <div className="ReadOnlyCarbonCopies">
                    <h6 className="mb-0">1</h6>
                    <p>Jack Jack</p>
                    <p>jack.jack@realtor.com</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="SaveDraftBtn d-flex justify-content-between mt-5">
      <button className="BrandBtn">Cencel</button>
      <div className="d-flex ">
        <button className="BrandBtnGreen">Download</button>
        <button className="BrandBtnGreen ml-5">Print</button>
      </div>
    </div>
    <div className="DiscussionArea">
      <div className="DiscussionTitle">
        <h4>Discussion</h4>
        <div className="DiscussionChat">
          <img src="images/down.png" className="DiscussionIcon" />
          <a href="#">
            <img src="images/pdf2.png" />
          </a>
        </div>
      </div>
      <div className="DiscussionHideArea" style={{ display: "block" }}>
        <div className="DiscussionBox">
          <span>FM</span>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Add a Comment ....."
            />
            <div className="DiscussionOverlay">
              <a href="" className="CancelChat">
                <img src="images/plus-cancel1.png" />
              </a>
              <a href="" className="SendChat">
                <img src="images/forwardbutton.png" />
              </a>
            </div>
          </div>
        </div>
        <div className="DiscussionBox">
          <span>TH</span>
          <div className="DiscussionRight">
            <h6>Inspections are delayed</h6>
            <small>04/19/2023, at 02:47 pm</small>
          </div>
        </div>
        <div className="ReplyArea">
          <a href="javascript:void(0);">Reply</a>
          <div className="DiscussionBox">
            <span>TH</span>
            <div className="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
          <div className="DiscussionBox">
            <span>TH</span>
            <div className="DiscussionRight">
              <h6>Inspections are delayed</h6>
              <small>04/19/2023, at 02:47 pm</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default ChangeOrderClientViewDetails