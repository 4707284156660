import { vendorViewConfigAction } from "../actionTypes";
const initialState = {
  loader: false,
  generalconfigData:[],
  designSelectionData:[],
  appointmentsData:[],
  constructionData:[],
  changeOrderData:[],
  warrantyData:[],
  todoData:[],
  estimationData:[],
  vendorAuthorizedList:[],
  allProjectList:[],
  generalConfigInvestorView:[],
  vendorInvestorAuthorizedList:[],
  vendorUserList:[],
  innvestorVendorUserList:[],
};

export default function vendorViewConfigReducer(state = initialState, { type, payload }) {
  switch (type) {

    case vendorViewConfigAction.GET_GENERAL_CONFIGURATION_INITIATE: {
      return {...state,generalconfigData: []};
    }
    case vendorViewConfigAction.GET_GENERAL_CONFIGURATION_SUCCESS: {
      return { ...state, generalconfigData: payload};
    }
    case vendorViewConfigAction.GET_GENERAL_CONFIGURATION_FAILURE: {
      return {...state,generalconfigData: []};
    }

    case vendorViewConfigAction.GET_DESIGN_SELECTIONS_INITIATE: {
        return {...state,designSelectionData: []};
      }
      case vendorViewConfigAction.GET_DESIGN_SELECTIONS_SUCCESS: {
        return { ...state, designSelectionData: payload};
      }
      case vendorViewConfigAction.GET_DESIGN_SELECTIONS_FAILURE: {
        return {...state,designSelectionData: []};
      }

      case vendorViewConfigAction.GET_APPOINTMENTS_INITIATE: {
        return {...state,appointmentsData: []};
      }
      case vendorViewConfigAction.GET_APPOINTMENTS_SUCCESS: {
        return { ...state, appointmentsData: payload};
      }
      case vendorViewConfigAction.GET_APPOINTMENTS_FAILURE: {
        return {...state,appointmentsData: []};
      }


      case vendorViewConfigAction.GET_CONSTRUCTION_INITIATE: {
        return {...state,constructionData: []};
      }
      case vendorViewConfigAction.GET_CONSTRUCTION_SUCCESS: {
        return { ...state, constructionData: payload};
      }
      case vendorViewConfigAction.GET_CONSTRUCTION_FAILURE: {
        return {...state,constructionData: []};
      }

      case vendorViewConfigAction.GET_CHANGE_ORDER_INITIATE: {
        return {...state,changeOrderData: []};
      }
      case vendorViewConfigAction.GET_CHANGE_ORDER_SUCCESS: {
        return { ...state, changeOrderData: payload};
      }
      case vendorViewConfigAction.GET_CHANGE_ORDER_FAILURE: {
        return {...state,changeOrderData: []};
      }

      case vendorViewConfigAction.GET_WARRANTY_INITIATE: {
        return {...state,warrantyData: []};
      }
      case vendorViewConfigAction.GET_WARRANTY_SUCCESS: {
        return { ...state, warrantyData: payload};
      }
      case vendorViewConfigAction.GET_WARRANTY_FAILURE: {
        return {...state,warrantyData: []};
      }

      case vendorViewConfigAction.GET_TODO_INITIATE: {
        return {...state,todoData: []};
      }
      case vendorViewConfigAction.GET_TODO_SUCCESS: {
        return { ...state, todoData: payload};
      }
      case vendorViewConfigAction.GET_TODO_FAILURE: {
        return {...state,todoData: []};
      }

      case vendorViewConfigAction.GET_ESTIMATION_INITIATE: {
        return {...state,estimationData: []};
      }
      case vendorViewConfigAction.GET_ESTIMATION_SUCCESS: {
        return { ...state, estimationData: payload};
      }
      case vendorViewConfigAction.GET_ESTIMATION_FAILURE: {
        return {...state,estimationData: []};
      }

      case vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_INITIATE: {
        return {...state,vendorAuthorizedList: []};
      }
      case vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_SUCCESS: {
        return { ...state, vendorAuthorizedList: payload};
      }
      case vendorViewConfigAction.VENDOR_AUTHORIZED_LIST_FAILURE: {
        return {...state,vendorAuthorizedList: []};
      }


      case vendorViewConfigAction.ALL_PROJECT_LIST_INITIATE: {
        return {...state,allProjectList: []};
      }
      case vendorViewConfigAction.ALL_PROJECT_LIST_SUCCESS: {
        return { ...state, allProjectList: payload};
      }
      case vendorViewConfigAction.ALL_PROJECT_LIST_FAILURE: {
        return {...state,allProjectList: []};
      }

      case vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_INITIATE: {
        return {...state,assignVendorJobProjectList: []};
      }
      case vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_SUCCESS: {
        return { ...state, assignVendorJobProjectList: payload};
      }
      case vendorViewConfigAction.ASSGNED_VENDOR_JOBS_PROJECTS_LIST_FAILURE: {
        return {...state,assignVendorJobProjectList: []};
      }


      case vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_INITIATE: {
        return {...state,generalConfigInvestorView: []};
      }
      case vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_SUCCESS: {
        return { ...state, generalConfigInvestorView: payload};
      }
      case vendorViewConfigAction.GENERAL_CONFIGURATION_INVESTOR_VIEW_FAILURE: {
        return {...state,generalConfigInvestorView: []};
      }

      case vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_INITIATE: {
        return {...state,vendorInvestorAuthorizedList: []};
      }
      case vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_SUCCESS: {
        return { ...state, vendorInvestorAuthorizedList: payload};
      }
      case vendorViewConfigAction.VENDOR_INVESTOR_AUTHORIZED_LIST_FAILURE: {
        return {...state,vendorInvestorAuthorizedList: []};
      }

      case vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_INITIATE: {
        return {...state,vendorUserList: []};
      }
      case vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_SUCCESS: {
        return { ...state, vendorUserList: payload};
      }
      case vendorViewConfigAction.TRADE_VIEW_VENDOR_USER_LIST_FAILURE: {
        return {...state,vendorUserList: []};
      }

      case vendorViewConfigAction.TRADE_ACCESS_REQUEST_INITIATE: {
        return {...state,tradeAccesRequestList: []};
      }
      case vendorViewConfigAction.TRADE_ACCESS_REQUEST_SUCCESS: {
        return { ...state, tradeAccesRequestList: payload};
      }
      case vendorViewConfigAction.TRADE_ACCESS_REQUEST_FAILURE: {
        return {...state,tradeAccesRequestList: []};
      }

      case vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_INITIATE: {
        return {...state,innvestorVendorUserList: []};
      }
      case vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_SUCCESS: {
        return { ...state, innvestorVendorUserList: payload};
      }
      case vendorViewConfigAction.INVESTOR_VIEW_VENDOR_USER_LIST_FAILURE: {
        return {...state,innvestorVendorUserList: []};
      }

    default:
      return state;
  }
}
