import React, { useState,useRef } from 'react'
import ClientHeader from './ClientHeader'
import ClientViewSidenav from './client-view-sidenav'
import { checkArray } from "../utils/CheckType";
import { convertToPNG, dataURLtoBlob } from '../utils/blog';
import { addSignatureAction } from '../redux/action/clientDocumentationAction';
import { useDispatch } from 'react-redux';
import { errorToast, sucessToast } from "../utils/toast";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { getDate } from '../utils/uploadFile';
import { useLocation } from 'react-router-dom';
const initModal = {
  clientSignatureModal:false,
  clientHistoryModal:false,
}
const initLeadState = {
  signature:"",
  modalId:"",
  initial:"",
  signatureErros:{}
}
const ClientCTCSales = () => {
  const[iModalState , updateModalState] = useState(initModal)
  const[iLeadState, updateLeadState] = useState(initLeadState)
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const CompanyId = urlParams?.get("companyId");
  const clientCTCId = urlParams?.get("clientCTCId");
  const contractId = urlParams?.get("contractId");
  const token = urlParams?.get("token");
 
  console.log("CompanyId", CompanyId)
  console.log("clientCTCId", clientCTCId)
  console.log("tokennnnnn", token)
  console.log("clientId",contractId)
  const{clientSignatureModal,clientHistoryModal } = iModalState;
  const{signature,
    modalId,
    signatureErrors,
    initial} = iLeadState ;
    const signCanvas = useRef({});
  const intialCanvas = useRef({});
  

  const showClientSignatureModal = () =>{
    updateModalState({...iModalState, clientSignatureModal:true})
  }

  const hideClientSignatureModal = () =>{
    updateModalState({...iModalState, clientSignatureModal:false})
  }

  const showClientHistoryModal = () =>{
    updateModalState({...iModalState, clientHistoryModal:true})
   }
 
   const hideClientHistoryModal = () =>{
     updateModalState({...iModalState, clientHistoryModal:false})
   }
  const clearSignature = () => {
    signCanvas.current.clear();
  };
  const handleSignatureValidation = () => {
    let formIsValid = true;
    let error = {};

    if (!signature) {
      error.signatureError = "* Signature can't be empty";
      formIsValid = false;
    }

   

    updateLeadState({ ...iLeadState, signatureErrors: error });
    return formIsValid;
  };
  const addSignatureFun = (e) => {
    e.preventDefault();
    sucessToast("Signature added successfully")
    updateModalState({ ...iModalState, manageSignatureModal: true });
    let formIsValid = handleSignatureValidation();
    if (formIsValid) {
      const signatureBlob = dataURLtoBlob(signature);
      const initialBlob = dataURLtoBlob(initial);

      const convertToValidFormat = (blob, fileName) => {
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ![
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "bmp",
            "webp",
            "svg+xml",
          ].includes(fileExtension)
        ) {
          const convertedBlob = convertToPNG(blob);
          return convertedBlob;
        }
        return blob;
      };
      const validSignatureBlob = convertToValidFormat(
        signatureBlob,
        "signature.png"
      );
      const validInitialBlob = convertToValidFormat(initialBlob, "initial.png");
      const formData = new FormData();
      formData.append("signature", validSignatureBlob, "signature.png");
      formData.append("initial", validInitialBlob, "initial.png");
      formData.append("imageOwner", "test");
      dispatch(addSignatureAction(formData, modalId))
        .then((res) => {
          sucessToast("Signaute updated successfully");
          updateModalState({ ...iModalState, manageSignatureModal: true });
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          hideClientSignatureModal();
        });
    }
  };

  return (
    <>
      {/* <ClientHeader />
        <ClientViewSidenav /> */}
        <div className="FormArea">
  <div className="ModalTitleBox">
    <h4>View Client's CTC</h4>
  </div>
  <div className="FolderForm">
    <form>
      <div className="ContactInformationArea">
        <h6>Contact Information</h6>
        <div className="ContactInformationBox">
          <span className="circle">JL</span>
          <div className="ContactInformationName">
            <h6>John Lead</h6>
            <p>
              312 Weddenburn Rd SE <br /> Calgary, Alberta{" "}
              <img
                className="FolderPermissionId CursorPointer"
                data-toggle="modal"
                data-target="#TwoMapModal4"
                data-dismiss="modal"
                src="images/location.png"
              />
            </p>
            <p>
              Phone: <span>(403)555-555</span>
            </p>
            <p>
              Email: <span>John.lead@email.com</span>
            </p>
          </div>
          <div className="AssignUnit">
            <h4>Assigned Unit</h4>
            <p>U222-CHI-2B-243 (Unit #222)</p>
            {/* <a href="javascript:void(0);" class="CommonBtn mt-4"><img src="images/generate_ctc.png">Generate Client's CTC</a> */}
          </div>
        </div>
      </div>
      <div className="AgreementViewAreaGreen mt-3">
        <table>
          <thead>
            <tr>
              <th>
                {/* <label class="CheckBox"> Client Visible
                        <input type="checkbox">
                        <span class="checkmark"></span>
                      </label> */}
              </th>
              <th>Name</th>
              <th>Pictures</th>
              <th>Unit Cost</th>
              <th>Unit Discount</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr className="DarkBlueBg">
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td colSpan={6}>Appliances</td>
            </tr>
            <tr className="LightBlueBg">
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td colSpan={6}>Appliance Allowances: Includes GST.</td>
            </tr>
            <tr>
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td>Platinum - Appliance Allowances: Includes GST.</td>
              <td>
                <div>
                  <img src="images/picture-1.png" />
                </div>
              </td>
              <td>$10,000.00</td>
              <td>$300</td>
              <td>1</td>
              <td>$9,700.00</td>
            </tr>
            <tr>
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td>Silver - Appliance Allowances: Includes GST</td>
              <td>
                <div>
                  <img src="images/picture-1.png" />
                </div>
              </td>
              <td>$2,5000.00</td>
              <td>1</td>
              <td />
              <td />
            </tr>
            <tr className="DarkBlueBg">
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td colSpan={6}>Cabinets and Vanities</td>
            </tr>
            <tr>
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td>
                Add Standard Spec Base Cabinets - <br />
                Laminate Countertop Included
              </td>
              <td>
                <div>
                  <img src="images/picture-1.png" />
                </div>
              </td>
              <td>$4,50.00</td>
              <td />
              <td>2</td>
              <td>$900.00</td>
            </tr>
            <tr className="LightBlueBg">
              <td>
                <label className="CheckBox">
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </td>
              <td colSpan={6}>Upgrade to granite</td>
            </tr>
            <tr>
              <td />
              <td>Cabinets</td>
              <td>
                <div>
                  <img src="images/picture-1.png" />
                </div>
              </td>
              <td>$225.00</td>
              <td />
              <td>1</td>
              <td>$13,500.00</td>
            </tr>
          </tbody>
        </table>
        <div className="CostBreakDownArea">
          <h5 className="TableHeading CursorPointer CostBreakDown">
            Cost Breakdown <img src="images/down-arrow.png" />
          </h5>
          <div className="CostBreakDownShow">
            <div className="Box">
              <h6>Selections Details</h6>
            </div>
            <div className="Box DarkBlueBg">
              <h6>Options Sub-Total</h6>
              <h6>$15,075.00</h6>
            </div>
            <div className="Box LightBlueBg">
              <h6>Tax 5%</h6>
              <h6>$753.75</h6>
            </div>
            <div className="Box NavyBlueBg">
              <h6>Total</h6>
              <h6>$15,828.75</h6>
            </div>
          </div>
        </div>
        <div className="CostBreakDownArea">
          <h5 className="TableHeading  my-4">Cost Breakdown With Options</h5>
          <div className="Box DarkBlueBg">
            <h6>Contract Price Before Tax</h6>
            <h6>$400,000.00</h6>
          </div>
          <div className="Box LightBlueBg">
            <h6>Change Order Total</h6>
            <h6>$15,075.00</h6>
          </div>
          <div className="Box DarkBlueBg">
            <h6>Sub Total</h6>
            <h6>$415,075.00</h6>
          </div>
          <div className="Box LightBlueBg">
            <h6>Tax 5%</h6>
            <h6>$20,753.75</h6>
          </div>
          <div className="Box LightBlueBg">
            <h6>Adjustment: Applied Tax Rebate</h6>
            <h6>-$2,843.26</h6>
          </div>
          <div className="Box NavyBlueBg">
            <h6>Total</h6>
            <h6>$432,985.49</h6>
          </div>
        </div>
        <div className="ChangeOrderCreatArea mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="InputBox">
                <div className="form-group">
                  <div className="InputBoxSmall">
                    <h6>25/03/2023</h6>
                  </div>
                  <label>Date</label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="InputBox">
                <div className="form-group">
                  <a href="javascript:void(0);">
                    <img src="images/sign-img.png" />
                  </a>
                  <label>John Joe Client</label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="InputBox border-0 text-center">
                <h6>
                  Signed on 05/25/2023 02:42 PM <br />
                  by John Doe <br />
                  (johndoe@email.com)
                </h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="InputBox">
                <div className="form-group">
                  <input type="date" />
                  <label>{getDate()}</label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="InputBox">
                <div className="form-group">
                  <div className="InsertSignature">
                    <a
                      className="FolderPermissionId"
                      href="javascript:void(0);"
                      onClick={showClientSignatureModal}
                       
                    >
                      <img src="images/insert_signature.png" />
                      Insert Signature
                    </a>
                  </div>
                  {/* <label>John Joe Builder Company</label> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Button d-flex justify-content-between">
        <div className="Button dropdown">
          <button
            className="Create px-4"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Send for client signature <img src="images/down-arrow-white.png" />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item FolderPermissionId"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#TwoSendClientInformationView2"
              data-dismiss="modal"
            >
              Send for Client Signature
            </a>
            {/* <a class="dropdown-item" href="javascript:void(0);">Confirm Signature</a> */}
            <a
              className="dropdown-item FolderPermissionId"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#TwoRejectReturnCtcMaster"
              data-dismiss="modal"
            >
              Reject/Return
            </a>
            <a
              className="dropdown-item FolderPermissionId"
              href="javascript:void(0);"
              onClick={showClientHistoryModal}
            >
              View History
            </a>
            {/* <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoGenerateChangeOrderCtc" data-dismiss="modal">Generate Change Order List</a>
                  <a class="dropdown-item FolderPermissionId" href="javascript:void(0);" data-toggle="modal" data-target="#TwoChangeOrderCtc" data-dismiss="modal">View Generated Change Order</a> */}
          </div>
        </div>
        <button type="button" className="Create">
          Confirm Signature
        </button>
        <button
          className="Outline"
          data-toggle="modal"
          data-target="#TwoCreateClientCtc2"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</div>
<Modal
        show={clientSignatureModal}
        onHide={hideClientSignatureModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientSignatureModal}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Manage Signatures</h4>
            </div>
            <div className="FolderForm">
              
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                    <div className="CommonModalBox">
                      <h5>
                        <figure>
                          <img src="images/add_new_signature.png" />
                        </figure>{" "}
                        Add new signature
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      {/* <input
                        // type="file"
                        // name="signature"
                        // onChange={handleInputFile}
                      /> */}
                    <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateLeadState({
                            ...iLeadState,
                            clientSignature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature} href="javascript:void(0);">
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {signatureErrors?.signatureError}
                    </span>
                  </div>
                </div>
             
                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={addSignatureFun}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={hideClientSignatureModal}
                  >
                    Cancel
                  </button>
                </div>
              
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={clientHistoryModal}
        onHide={hideClientHistoryModal}
        className="ModalBox LargeModal"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={hideClientHistoryModal }
          >
            ×
          </a>
          <div className="FormArea">
        <div className="ModalTitleBox">
          <h4>CTC History</h4>
        </div>
        <div className="JobTitleArea mb-2">
          <div className="JobBox">
            <h6><span>Document:</span>&nbsp;Sales Contract.pdf</h6>
          </div>
        </div>
        <div className="TableList CommonHeading TableHeightScroll">
          <table>
            <thead>
              <tr>
                <th>Modified By</th>
                <th>Created By</th>
                <th>Date</th> 
                {/* /<th>View</th> */}
                <th>Activity</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>John Doe</td>
                <td>Jane Williams</td>
                <td>04/25/2023</td>
                {/* <td>
                  <a href="javascript:void(0);" className="FolderPermissionId" data-toggle="modal" data-target="#TwoSignClientCtcThird" data-dismiss="modal"><i className="fa fa-eye" /></a>
                </td> */}
                <td>File Shared by the trade</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ClientCTCSales