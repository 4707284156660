import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { getSelectedRequestItems, getWorkOrderSettingDiscussion, updateWorkOrderSettingDiscussion } from "../redux/action/warrantyAction";
import { AddDiscussionAction, AddReplyAction, discussionListAction } from "../redux/action/constructionsAction";
const initialState = {
  DiscussionSettingsModal: false,
  discussionData: [],
  visibleInClientView: false,
  visibleInTradeView: false,
  visibleInInvestorView: false,
  jobId:"",
};
const WarrantyWorkOrderDetails = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    DiscussionSettingsModal,
    discussionData,
    visibleInClientView,
    visibleInTradeView,
    visibleInInvestorView,
    jobId,
  } = iState;
  const {selectedRequestItems} = useSelector(
    (state) => state.warrantyReducer
  );
  const {discussionList } =
  useSelector((state) => state.constructionReducer);

  const { state } = useLocation();
//   console.log("state ", state);
useEffect(() => {
    if (state?.data?.id!=null) {
      dispatch(getSelectedRequestItems(state?.data?.id));
    }
  }, [dispatch, state?.data?.id]);

  
  useEffect(() => {
    dispatch(getWorkOrderSettingDiscussion(state?.data?.id)).then((res) => {
      if (res.status === 200) {
        updateState({
          ...iState,
          discussionData: res.data,
          visibleInTradeView: res.data.settings?.visibleInTradeView,
          visibleInInvestorView: res.data.settings?.visibleInInvestorView,
          visibleInClientView: res.data.settings?.visibleInClientView,
        });
      }
    });
  }, []);

  const handleDiscussionSettingsModalShow = () => {
    updateState({ ...iState, DiscussionSettingsModal: true });
  };
  const handleDiscussionSettingsModalClose = () => {
    updateState({ ...iState, DiscussionSettingsModal: false });
  };

  const handleDiscussionCheckBox = (type) => {
    if (type === "client") {
      updateState({
        ...iState,
        visibleInClientView: !visibleInClientView,
      });
    } else if (type === "trade") {
      updateState({
        ...iState,
        visibleInTradeView: !visibleInTradeView,
      });
    } else {
      updateState({
        ...iState,
        visibleInInvestorView: !visibleInInvestorView,
      });
    }
  };

  const handleDiscussionSettingUpdate = () => {
    const dataa = {
      visibleInClientView,
      visibleInTradeView,
      visibleInInvestorView,
      discussableId:discussionData?.settings?.id,
      discussableType: "WorkOrder",
    };
    dispatch(
      updateWorkOrderSettingDiscussion(dataa)
    ).then((res) => {
      if (res.status === 200) {
        // dispatch(getTodo(viewConfigId));
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          DiscussionSettingsModal: true,
        });
      }
    });
  };

  const [discussionState, updateDiscussionState] = useState({
    jobId: "",
    message: "",
    discussionError: "",
  });

  const [replyState, updateReplyState] = useState({
    messageId: "",
    jobId: "",
    message: "",
    replyError: "",
  });
  const [replyField, updateReplyField] = useState({ flag: false, index: "" });

  let handleValidationDiscussion = () => {
    let discussionError = "";
    let formIsValid = true;

    if (discussionState.message == "") {
      discussionError = "* Please enter comment";
      formIsValid = false;
    }

    updateDiscussionState({
      ...discussionState,
      discussionError: discussionError,
    });
    return formIsValid;
  };

  const handleAddDiscussion = () => {
    let formIsValid = handleValidationDiscussion();
    if (formIsValid) {
      dispatch(
        AddDiscussionAction
        ({ message: discussionState.message, jobId: jobId })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleAddReply = () => {
    let formIsValid = true;
    if (formIsValid) {
      dispatch(
        AddReplyAction({
          message: replyState.message,
          jobId: replyState.jobId,
          messageId: replyState.messageId,
        })
      )
        .then((res) => {
          if (res.status == 201) {
            dispatch(discussionListAction({ jobId: jobId }));
            updateReplyState({
              messageId: "",
              jobId: "",
              message: "",
              replyError: "",
            });

            updateDiscussionState({ message: "", discussionError: "" });
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };


 useEffect(()=>{
  dispatch(discussionListAction({ jobId: state?.data?.jobId }));
},[])
  return (
    <div>
      <Header />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox pb-5">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Work Order</h2>
            </div>
          </div>
          <div className="TitleBox">
            <h4 className="Title">Work Order #114</h4>
            <div className="TitleBox2 TitleBoxRight">
              <div className="dropdown">
                <h6 data-toggle="dropdown">UUnit3 -CHI -2B -315</h6>
                <span>Multi-Family - Residential Unit</span>
                {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="#">Select a job</a>
        <a class="dropdown-item" href="#">
          <input type="text" placeholder="-Search">
          <span><img src="images/search.png"></span>
        </a>
        <a class="dropdown-item" href="#">List of jobs loaded from the system</a>
      </div> */}
              </div>
              <a href="#">
                <img src="images/icon-61.png" />
              </a>
              <a href="#" className="mx-3">
                <img src="images/icon-62.png" />
              </a>
              <a href="#">
                <img src="images/Icon-4.png" />
              </a>
            </div>
          </div>
          <div className="WarrantyDashboardForm mb-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Description<span className="Required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={3}
                        placeholder="Write here ....."
                        defaultValue={""}
                        value={state?.data?.description?? "N/A"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div>
                  <div className="form-group">
                    <label>
                      Vendor<span className="Required">*</span>
                    </label>
                  </div>
                  <div className="TodosButtons mt-3">
                    <a
                      href="javascript:void(0);"
                      className="ml-0"
                      id="ManualEntry"
                    >
                      <img src="images/manual-entry.png" />
                      Manual entry
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-toggle="modal"
                      data-target="#VendorSelection"
                    >
                      <img src="images/select-from-list.png" />
                      Select from the list
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="WarrantyDashboardForm">
            <div className="row">
              <div className="col-lg-6">
                <h4>Client Details</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Possession Date</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Request Date</label>
                      <input
                        type="text"
                        className="form-control"
                        value={moment(state?.data?.requestDate).format("YYYY-MM-DD")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Client Full Legal Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Primary Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>
                        Job Address{" "}
                        <img src="images/location.png" className="ml-2" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Auto-filled by System"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Supporting Document</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            data-toggle="modal"
                            data-target="#AddNewSupportingDocs2"
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Supporting Document
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#SupportingDocumentList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="CommonModalArea">
                        <h5>Add Notes</h5>
                        <div className="CommonModalBox">
                          <h5
                            className="CursorPointer"
                            data-toggle="modal"
                            data-target="#NoteAdd2"
                          >
                            <figure>
                              <img src="images/document-support.png" />
                            </figure>
                            Add Notes
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#NotesList"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ml-auto">
                <div className="ManualEntryHiddenArea">
                  <h3>Vendor</h3>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Company Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Type</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Non Vendor"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Warranty Contacts</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto-filled by System"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="CommonModalArea2">
                      <h5>Schedule Trades Day</h5>
                      <div className="CommonModalBox2">
                        <div className="d-flex align-items-center">
                          <h5
                            className="CursorPointer mb-0"
                            data-toggle="modal"
                            data-target="#TradesDaySelectionRequest"
                          >
                            <figure>
                              <img src="images/icon-18.png" />
                            </figure>
                            &nbsp; Trade/Client Suggested Dates
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#TradesDaySelection11"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="Filter FullFormGroup">
                    <div className="form-group">
                      <label>Trades- Day</label>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>From</label>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>To</label>
                      <input type="time" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="Filter">
                    <div className="form-group">
                      <label>Required Completion Date</label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Tableheader mt-5">
            <h6>Inspected Items</h6>
            <div className="EditableByClient">
              <div className="form-group mb-0">
                <label className="switch">
                  <input type="checkbox" />
                  <span className="slider" />
                </label>
                <h6>Editable by client</h6>
              </div>
            </div>
          </div>
          <div className="TableList TableListHeader ">
            <table>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>#</th>
                  <th>Description</th>
                  <th>Inspector</th>
                  <th>Date Created</th>
                  <th>Date Inspected</th>
                  <th>Service Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                  selectedRequestItems?.data?.length>0?
                  selectedRequestItems?.data?.map((item,i)=>{
                    return(
                      <tr key={i}>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox"
                           checked={item.workOrderStatus===true?true:false}
                           disabled
                //   onChange={() => handleItemCheckboxChange(item.id)} 
                  />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>{item.id}</td>
                      <td>{item.description}</td>
                      <td>{item.inspectorUser?.name??"N/A"}</td>
                      <td>{moment(item?.createdAt).format("YYYY-MM-DD")}</td>
                      <td>{moment(item?.dateInspected).format("YYYY-MM-DD")}</td>
                      <td>{item.serviceTypeOption?.name??"N/A"}</td>
                      <td>
                        <div className="Actions">
                        <a>
                              <img src="images/icon-63.png" />
                            </a>
                        </div>
                      </td>
                    </tr>
                    )
                  }): <tr>
                  <td colSpan="8" className="text-center">
                    No data found.
                  </td>
                </tr>
                }
               
              </tbody>
            </table>
          </div>
          {/* <div className="d-flex justify-content-between mt-3 pb-5">
            <button className="BrandBtn">Cencel</button>
            <div className="d-flex ">
              <button className="BrandBtnGreen">Save</button>
              <div className="dropdown ml-3">
                <button
                  className="BrandBtnGreen"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Save and <img src="images/down-arrow-white.png" />
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#">
                    Save and Print
                  </a>
                  <a className="dropdown-item" href="#">
                    Save and E-mail
                  </a>
                </div>
              </div>
            </div>
          </div> */}
            <div class="DiscussionArea">
            <div class="DiscussionTitle">
            <h4>Discussion {" "}
              <span className="borderOranges">{discussionList?.length??0}</span>
              </h4>
              <div class="DiscussionChat">
                <img
                  src={require("../assets/images/down.png")}
                  class="DiscussionIcon"
                />
                <a>
                  <img src={require("../assets/images/pdf2.png")} />
                </a>
                <a onClick={() => handleDiscussionSettingsModalShow()}>
                  <img src="images/setting.png" />
                </a>
              </div>
            </div>
            <div class="DiscussionHideArea" style={{ display: "block" }}>
              <div class="DiscussionBox">
                <span>FM</span>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    value={discussionState?.message}
                    onChange={(e) =>
                      updateDiscussionState({
                        ...discussionState,
                        message: e.target.value,
                      })
                    }
                    placeholder="Add a Comment ....."
                  />
                  {discussionState.discussionError &&
                    !discussionState.message && (
                      <p style={{ color: "red" }}>
                        {discussionState.discussionError}
                      </p>
                    )}

                  <div class="DiscussionOverlay">
                    <a
                      onClick={() =>
                        updateDiscussionState({
                          ...discussionState,
                          message: "",
                        })
                      }
                      class="CancelChat"
                    >
                      <img src={require("../assets/images/plus-cancel1.png")} />
                    </a>
                    <a onClick={handleAddDiscussion} class="SendChat">
                      <img
                        src={require("../assets/images/forwardbutton.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {discussionList?.map((item, i) => (
                <>
                  <div class="DiscussionBox">
                    <span>CO</span>
                    <div class="DiscussionRight">
                      <h6>{item?.message}</h6>
                      <small>{moment(item.updatedAt).format("lll")}</small>
                    </div>
                  </div>
                  <div class="ReplyArea">
                    <a
                      onClick={(e) => {
                        updateReplyField({ flag: true, index: i });
                        updateReplyState({ ...replyState, message: "" });
                      }}
                    >
                      Reply
                    </a>
                    {item?.ReplyDiscussions?.map((ele) => (
                      <div class="DiscussionBox">
                        <span>RE</span>
                        <div class="DiscussionRight">
                          <h6>{ele?.message}</h6>
                          <small>{moment(ele.updatedAt).format("lll")}</small>
                        </div>
                      </div>
                    ))}

                    {replyField.index == i && (
                      <div class="DiscussionBox w-100">
                        <span>FM{item?.id}</span>
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            value={replyState?.message}
                            onChange={(e) =>
                              updateReplyState({
                                ...replyState,
                                message: e.target.value,
                                messageId: item?.id,
                                jobId: item?.jobId,
                              })
                            }
                            placeholder="Add reply ....."
                          />
                          {discussionState.discussionError &&
                            !discussionState.message && (
                              <p style={{ color: "red" }}>
                                {discussionState.discussionError}
                              </p>
                            )}

                          <div class="DiscussionOverlay">
                            <a
                              onClick={() =>
                                updateReplyState({ ...replyState, message: "" })
                              }
                              class="CancelChat"
                            >
                              <img
                                src={require("../assets/images/plus-cancel1.png")}
                              />
                            </a>
                            <a onClick={handleAddReply} class="SendChat">
                              <img
                                src={require("../assets/images/forwardbutton.png")}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={DiscussionSettingsModal}
        onHide={handleDiscussionSettingsModalClose}
        className="ModalBox modal fade SmallModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleDiscussionSettingsModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Discussion Settings</h4>
            </div>
            <div className="FolderForm">
              <div
                className="FolderFormCreateArea p-0"
                style={{ overflow: "initial" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="ModalMediumheading">
                      <h3>
                        <span>Linked to: </span>Warranty Request #
                        {state?.data?.id}
                      </h3>
                    </div>
                    <h6>Visible in :</h6>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-2 mb-4 ">
                      <label className="switch">
                        Client View
                        <input
                          type="checkbox"
                          checked={visibleInClientView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("client")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Trade View
                        <input
                          type="checkbox"
                          checked={visibleInTradeView === true ? true : false}
                          onClick={() => handleDiscussionCheckBox("trade")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                    <div className="form-group mb-4 ">
                      <label className="switch">
                        Investor View
                        <input
                          type="checkbox"
                          checked={
                            visibleInInvestorView === true ? true : false
                          }
                          onChange={() => handleDiscussionCheckBox("invest")}
                        />
                        <span className="slider" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Button d-flex justify-content-between align-items-center">
                <button
                  className="Create"
                  onClick={handleDiscussionSettingUpdate}
                >
                  Save Discussion Settings
                </button>
                <button
                  className="Outline"
                  onClick={handleDiscussionSettingsModalClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WarrantyWorkOrderDetails;
