import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import {
  getJobByProjectList,
  getProjectByBuilder,
  getSupportDocsDelete,
  getTodoBuilderList,
  getTodoWarrantySupportDocsList,
  getTodoWarrantyWorkOrderList,
  getTradeView,
  todoWarrantySupportDocs,
} from "../redux/action/tradeViewAuthAction";
import Loader from "./Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import TradeViewSidenav from "./trade-view-sidenav";
import TradeHeader from "./TradeHeader";
const initialState = {
  AddNewSupportingDocsModal: false,
  supportingDocFiless: null,
  id: "",
  SupportingDocumentsListModal: false,
  taskId: "",
  UpdateNewSupportingDocsModal: false,
  SupportDocumentDeleteModal: false,
  companyId: "",
  projectId: "",
  jobId: "",
  viewId: "",
  PendingVendorConfirmationModal: false,
  AcceptRejectModal: false,
  TradeDaySelectionDetails:false,
};

const WarrantyWorkOrderTradeView = () => {
  const dispatch = useDispatch();
  const [iState, updateState] = useState(initialState);
  const {
    AddNewSupportingDocsModal,
    supportingDocFiless,
    id,
    SupportingDocumentsListModal,
    taskId,
    UpdateNewSupportingDocsModal,
    SupportDocumentDeleteModal,
    companyId,
    projectId,
    jobId,
    viewId,
    PendingVendorConfirmationModal,
    AcceptRejectModal,
    TradeDaySelectionDetails,
  } = iState;
  const compId = JSON.parse(window.localStorage.getItem("tradeViewLogin"));
  const jobData = JSON.parse(window.localStorage.getItem("jobInformation"));
  const {
    warrantyWorkOrderLoader,
    todoWarrantyWorkOrderList,
    warrantSupportODcsListLoader,
    todoWarrantySupportDocsList,
    projectByBuilderData,
    jobByProjectData,
  } = useSelector((state) => state.tradeViewLogin);
  const builderIdd = JSON.parse(window.localStorage.getItem("builderId"));
  useEffect(() => {
    dispatch(getProjectByBuilder(builderIdd));
    dispatch(getTradeView(compId?.user?.CompanyId)).then((res) => {
      updateState({ ...iState, viewId: res?.data?.ViewSetting?.id });
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "projectId") {
      dispatch(getJobByProjectList(builderIdd, value));
      updateState({
        ...iState,
        [name]: value,
        jobId: "",
      });
    } else if (name === "jobId") {
      const selectedJob = jobByProjectData?.data?.find(
        (data) => data.id == value
      );
      if (selectedJob) {
        const { id, jobType } = selectedJob;
        dispatch(getTodoWarrantyWorkOrderList(builderIdd));
        updateState({
          ...iState,
          [name]: value,
        });
      }
    } else {
      updateState({
        ...iState,
        [name]: value,
      });
    }
  };

  const handleFileChanges = (event, type) => {
    const file = event?.target?.files[0];
    console.log("file changed", file);
    if (type === "docsUpdate") {
      updateState({ ...iState, supportingDocFiless: file });
    }
  };

  const handleRemoveDocsFile = () => {
    // Reset the 'templateFile' state to null
    updateState({ ...iState, supportingDocFiless: null });

    // Clear the file input field by resetting its value
    const fileInput = document.querySelector("#docsInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleAddNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: true,
      id: id,
      supportingDocFiless: "",
    });
  };
  const handleAddNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      AddNewSupportingDocsModal: false,
      supportingDocFiless: "",
    });
  };

  const handleSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    dispatch(todoWarrantySupportDocs(compId?.user?.CompanyId, id, dataa)).then(
      (res) => {
        if (res.status === 201) {
          // dispatch(getConstructScheduleList(compId?.user?.id));
          // dispatch(getConstructionSupportDocsList(docsId));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: false,
            id: "",
            supportingDocFiless: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            AddNewSupportingDocsModal: true,
          });
        }
      }
    );
  };

  const handleSupportingDocumentsListModalShow = (id) => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: true,
      // docsId: id,
      // taskId: taskId,
    });
    dispatch(getTodoWarrantySupportDocsList(compId?.user?.CompanyId, id));
  };
  const handleSupportingDocumentsListModalClose = () => {
    updateState({
      ...iState,
      SupportingDocumentsListModal: false,
    });
  };

  const handleUpdateNewSupportingDocsModalShow = (id) => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: true,
      id: id,
      SupportingDocumentsListModal: false,
    });
  };
  const handleUpdateNewSupportingDocsModalClose = () => {
    updateState({
      ...iState,
      UpdateNewSupportingDocsModal: false,
      supportingDocFiless: "",
      SupportingDocumentsListModal: true,
    });
  };

  const handleEditSupportDocs = () => {
    let dataa = {
      supportingDocFiles: supportingDocFiless,
    };
    dispatch(todoWarrantySupportDocs(compId?.user?.CompanyId, id, dataa)).then(
      (res) => {
        if (res.status === 201) {
          dispatch(getTodoWarrantySupportDocsList(compId?.user?.CompanyId, id));
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            UpdateNewSupportingDocsModal: false,
            id: "",
            SupportingDocumentsListModal: true,
            parenttableId: "",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({
            ...iState,
            UpdateNewSupportingDocsModal: true,
          });
        }
      }
    );
  };

  const handleSupportDocumentDeleteModalHideShow = (type, id) => {
    if (type === "show") {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: true,
        id: id,
        SupportingDocumentsListModal: false,
      });
    } else {
      updateState({
        ...iState,
        SupportDocumentDeleteModal: false,
        id: "",
        SupportingDocumentsListModal: true,
      });
    }
  };

  const handleDeleteSupportDocs = () => {
    dispatch(getSupportDocsDelete(compId?.user?.CompanyId, id)).then((res) => {
      if (res.status === 200) {
        dispatch(getTodoWarrantySupportDocsList(compId?.user?.CompanyId, id));
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          SupportDocumentDeleteModal: false,
          id: "",
          SupportingDocumentsListModal: true,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({
          ...iState,
          SupportDocumentDeleteModal: true,
        });
      }
    });
  };

  const handlePendingVendorConfirmationModalHideShow = (type) => {
    if (type === "show") {
      updateState({
        ...iState,
        PendingVendorConfirmationModal: true,
      });
    } else {
      updateState({
        ...iState,
        PendingVendorConfirmationModal: false,
      });
    }
  };




  const handleAcceptRejectModalShow = (id) => {
    updateState({ ...iState, AcceptRejectModal: true, appointmentId: id ,PendingVendorConfirmationModal:false});
  };

  const handleAcceptRejectAppointment = (type) => {
    const datta = {
    
    };
    dispatch((datta)).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        updateState({
          ...iState,
          AcceptRejectModal: false,
        });
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, AcceptRejectModal: true });
      }
    });
  };
  const handleAcceptRejectModalClose = () => {
    updateState({ ...iState, AcceptRejectModal: false,PendingVendorConfirmationModal:true });
  };

  const handleTradeDaySelectionDetailsShow = () => {
    updateState({ ...iState, TradeDaySelectionDetails: true });
  };

  const handleTradeDaySelectionDetailsClose = () => {
    updateState({ ...iState, TradeDaySelectionDetails: false });
  };
  return (
    <>
      {/* <Header /> */}
      <TradeHeader/>
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Warranty Work Orders</h2>
            </div>
          </div>
          <div className="TitleBox TitleBoxWidth">
            <div class="row align-items-start">
               <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
               <div className="BuilderBox">
              <h4>Project</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="projectId"
                  value={projectId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Project</option>
                  {projectByBuilderData?.data?.length > 0 &&
                    projectByBuilderData?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.projectName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
               </div>

               <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mx-auto">
               <div className="BuilderBox">
              <h4>Job</h4>
              <div className="SelectBuilder">
                <select
                  className="CommonSelect"
                  name="jobId"
                  value={jobId}
                  onChange={handleInputChange}
                >
                  <option value="">Select Jobs</option>
                  {jobByProjectData?.data?.length > 0 &&
                    jobByProjectData?.data?.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.jobType}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
               </div>
               
               <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
               <div className="TodosButtons mt-0 d-flex">
              <a
                onClick={()=>handlePendingVendorConfirmationModalHideShow("show")}
                className=""
                
              >
                <img src="images/pending_trades_day.png" />
                Pending Trades Day Selections<span>3</span>
              </a>
            </div>
               </div>
            </div>
          </div>
          <div className="TableHeadingArea mt-5">
            <div className="FilesHead">
              <div className="FilesLeft">
                <h4>Work Orders</h4>
              </div>
              <div className="NewActions">
                  <a  title="filter">
                    <img src="images/icon-64.png" />
                  </a>
                  <a title="pdf">
                    <img src="images/icon-53.png" />
                  </a>
                  {/* <a title="settings">
                    <img src="images/setting.png" />
                  </a> */}
                </div>
            </div>
          </div>
          <div className="TableList TableListLarge CommonHeading">
            <table>
              <thead>
                <tr>
                  <th>Job id</th>
                  <th>Work Order Number</th>
                  <th>Description</th>
                  <th>Address</th>
                  <th>Home Owner Accpeted</th>
                  <th>Date Created</th>
                  <th>Vendor/Technician name</th>
                  <th>Trades Day</th>
                  <th>Completed/# Work Order Item</th>
                  <th>Status</th>
                  <th>Supporting Documents</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!warrantyWorkOrderLoader ? (
                  todoWarrantyWorkOrderList?.workOrder?.length > 0 ? (
                    todoWarrantyWorkOrderList?.workOrder?.map((item, i) => {
                      return (
                        <tr className="BorderCyan PendingConfirmation" key={i}>
                          <td>{item.JobId}</td>
                          <td>{item.id}</td>
                          <td>{item.description}</td>
                          <td>
                            <div>
                              {item.jobAddress}
                              <img src="images/location.png" />
                            </div>
                          </td>
                          <td>
                            {item.homeOwnerAccept === true ? "Yes" : "No"}
                          </td>
                          <td>
                            {moment(item.createdAt).format(
                              "YYYY-MM-DD & HH:MM A"
                            )}
                          </td>
                          <td>{item.clientLegalName}</td>
                          <td>
                            {moment(item.requestDate).format(
                              "YYYY-MM-DD & HH:MM A"
                            )}
                          </td>
                          <td>{item.workOrderStatus??"N/A"}</td>
                          <td>{item.progressStatus??"N/A"}</td>
                          <td>
                            <div className="text-center">
                              <a
                                onClick={() =>
                                  handleAddNewSupportingDocsModalShow(
                                    item.WarrantyId
                                  )
                                }
                              >
                                <img src="images/notebook.png" />
                              </a>
                              <span
                                className="Count"
                                onClick={() =>
                                  handleSupportingDocumentsListModalShow(
                                    item.WarrantyId
                                  )
                                }
                              >
                                3
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="TableThreeDots">
                              <ul className="">
                                <li className="dropdown">
                                  <a
                                    className=""
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="images/dots.png" />
                                  </a>
                                  <ol
                                    className="dropdown-menu dropdown-menuwidth2"
                                    style={{}}
                                  >
                                    <li>
                                      <Link
                                        to="/warranty-work-order-tradeview-details"
                                        state={item}
                                      >
                                        <img src="images/view-po.png" />
                                        View Work Order
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <a href="javascript:void(0);">
                                        <img src="images/view_po_new.png" />
                                        View PO
                                      </a>
                                    </li> */}
                                    <li>
                                      <a onClick={()=>handleTradeDaySelectionDetailsShow()}>
                                        <img src="images/trades_day_selection.png" />
                                        Trades Day Selection
                                      </a>
                                    </li>
                                  </ol>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12">
                        <p>No Data found.</p>
                      </td>
                    </tr>
                  )
                ) : (
                  <Loader />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          className="ModalBox fade SmallModal"
          show={AddNewSupportingDocsModal}
          onHide={handleAddNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleAddNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleSupportDocs}>
                    Add Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade LargeModal show"
          show={SupportingDocumentsListModal}
          onHide={handleSupportingDocumentsListModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleSupportingDocumentsListModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>Supporting Documents List</h4>
                </div>
                <div className="JobTitleArea">
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                    <div>
                      <a
                      // onClick={() =>
                      //   handleAddNewSupportingDocsModalShow(taskId)
                      // }
                      >
                        <img src="images/notebook.png" />
                      </a>
                    </div>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span className="text-red">Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="NotesAreaMain">
                  <div className="NotesArea mt-4">
                    <h6>Supporting Documents List</h6>
                    <div>
                      <a href="javascript:void(0);">
                        <img src="images/pdf.png" />
                      </a>
                      <a href="javascript:void(0);">
                        <img src="images/download.png" />
                      </a>
                      {/* <a href="javascript:void(0);">
                        <img src="images/setting.png" />
                      </a> */}
                    </div>
                  </div>
                  <div className="TableList">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Sent by</th>
                          <th>Date</th>
                          <th>Notes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {warrantSupportODcsListLoader === false ? (
                          todoWarrantySupportDocsList?.supportDocs?.length >
                          0 ? (
                            todoWarrantySupportDocsList.supportDocs.map(
                              (data, ind) => (
                                <tr key={ind}>
                                  <td>{ind + 1}</td>
                                  <td>{data.sendBy ?? "N/A"}</td>
                                  <td>
                                    {moment(data.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td className="Inspection">
                                    {data.notes ?? "N/A"}
                                  </td>
                                  <td>
                                    <div className="Actions">
                                      <a
                                        onClick={() =>
                                          handleUpdateNewSupportingDocsModalShow(
                                            data.parenttableId
                                          )
                                        }
                                      >
                                        <img
                                          src="images/Action-1.png"
                                          alt="Action 1"
                                        />
                                      </a>
                                      <a
                                        onClick={() =>
                                          handleSupportDocumentDeleteModalHideShow(
                                            "show",
                                            data.parenttableId
                                          )
                                        }
                                      >
                                        <img
                                          src="images/delete.png"
                                          alt="Delete"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="5">
                                <p>No Data found.</p>
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="5">
                              <Loader />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  // onClick={() => handleAddNewSupportingDocsModalShow(taskId)}
                >
                  Add New Supporting Docs
                </button>
              </div> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="ModalBox fade SmallModal"
          show={UpdateNewSupportingDocsModal}
          onHide={handleUpdateNewSupportingDocsModalClose}
        >
          <Modal.Body>
            <div className="Category">
              <a
                className="CloseModal"
                onClick={handleUpdateNewSupportingDocsModalClose}
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4> Update New Supporting Docs</h4>
                </div>
                <div className="JobTitleArea">
                  <h3>Supporting Documents #2</h3>
                  <div className="JobBox">
                    <h6>
                      <span>Job:</span> UUnit3-CHI-2B-315
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Origin</span> #185
                    </h6>
                  </div>
                  <div className="JobBox">
                    <h6>
                      <span>Item:</span> #143
                    </h6>
                  </div>
                </div>
                <div className="form-group">
                  <h6>Select Files</h6>
                  <div className="Upload">
                    <span>-Drag and Drop Files or click to select</span>
                    <input
                      type="file"
                      id="docsInput"
                      onChange={(e) => handleFileChanges(e, "docsUpdate")}
                    />
                  </div>
                </div>
                <div className="FilesAddedTitle">
                  <h4>Files</h4>
                </div>
                {supportingDocFiless && (
                  <div className="FilesAdded">
                    <label>{supportingDocFiless?.name}</label>
                    <span>
                      <img src="images/fileview.png" />
                    </span>
                    <span onClick={handleRemoveDocsFile}>
                      <img src="images/Cross.png" className="cross" />
                    </span>
                  </div>
                )}

                <div className="Button mt-3">
                  <button className="Create" onClick={handleEditSupportDocs}>
                    Update Documents
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={SupportDocumentDeleteModal}
          onHide={() => handleSupportDocumentDeleteModalHideShow("hide", "")}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <div>
              <div>
                <a
                  className="CloseModal"
                  onClick={() =>
                    handleSupportDocumentDeleteModalHideShow("hide", "")
                  }
                >
                  ×
                </a>
                <div className="FormArea">
                  <div className="ModalTitleBox">
                    <h4>Delete</h4>
                  </div>
                  <div className="FolderForm">
                    <div className="my-5">
                      <h6>Are you sure you want to delete this ?</h6>
                    </div>
                    <div className="Button d-flex justify-content-between">
                      <button
                        className="Create"
                        onClick={handleDeleteSupportDocs}
                      >
                        Delete
                      </button>
                      <button
                        className="Outline"
                        onClick={() =>
                          handleSupportDocumentDeleteModalHideShow("hide", "")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
     
      <Modal
        show={PendingVendorConfirmationModal}
        onHide={() => handlePendingVendorConfirmationModalHideShow("hide", "")}
        className="ModalBox modal fade LargeModal show"
      >
        <Modal.Body>
          <div>
            <div>
              <a
                className="CloseModal"
                onClick={() =>
                  handlePendingVendorConfirmationModalHideShow("hide", "")
                }
              >
                ×
              </a>
              <div className="FormArea">
                <div className="ModalTitleBox">
                  <h4>
                    Construction Schedule Tasks Pending Vendor Confirmation
                  </h4>
                </div>
                <div className="TableList CommonHeading">
                  <table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Data Received</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Construction Schedule Task 1.3.1.1.6 -Taping</td>
                        <td>Construction Schedule</td>
                        <td>04/25/2023</td>
                        <td>
                          <div className="Actions">
                            <a onClick={() => handleAcceptRejectModalShow()}>
                              <img src="images/calendar_arrow.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AcceptRejectModal}
        onHide={handleAcceptRejectModalClose}
        className="ModalBox modal fade SmallModal show NewTaskAssigned1Background"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleAcceptRejectModalClose}
          >
            ×
          </a>

          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>New Task Assigned</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <div className="NewTaskAssignModalArea">
                <h6>Vendor</h6>
                <p>
                  A new task has been assigned to you by Builder. Please
                  <br />
                  confirm execution date
                </p>
                <div className="NewTaskAssignModalAreaBox">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            Location: <img src="images/location.png" />
                          </div>
                        </td>
                        <td>26th Zalda Cr, Calgary</td>
                      </tr>
                      <tr>
                        <td>Client:</td>
                        <td>Luke Anderson</td>
                      </tr>
                      <tr>
                        <td>Date Suggested:</td>
                        <td>
                          <input type="date" />
                        </td>
                      </tr>
                      <tr>
                        <td>Duration:</td>
                        <td>
                          <input type="text" placeholder="1 day" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between my-5">
                  <div className="dropdown modalButtonDropdown">
                    <button
                      className="BrandBtnGreen2"
                      onClick={() => handleAcceptRejectAppointment("accept")}
                    >
                      Approve
                      {/* <span>
                <img src="images/down-arrow-white.png" />
              </span> */}
                    </button>
                  </div>
                  <div className="Button">
                    <button
                      className="Create"
                      onClick={() => handleAcceptRejectAppointment("reject")}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={TradeDaySelectionDetails}
        onHide={handleTradeDaySelectionDetailsClose}
        className="ModalBox modal fade MediumModal show"
      >
        <Modal.Body>
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handleTradeDaySelectionDetailsClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand">
              <h4>Trades Day Selection</h4>
              <a href="">
                <img src="images/logo-white.png" />
              </a>
            </div>
            <div className="FolderForm">
              <h3>Work Order #158</h3>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="fw-700">Trades Day Confirmation</h3>
                  <div className="ClientsDetails">
                    <p>Client Details</p>
                    <h6 className="ClientName">Luke Anderson</h6>
                    <p className="ClientEmail">linden@ibuildapplications.com</p>
                    <p className="ClientNumber">4034005933</p>
                  </div>
                  <div className="ClientLocation">
                    <p>
                      Location <img src="images/location.png" />
                    </p>
                    <p className="ClientAddress">
                      #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate, Calgary
                    </p>
                  </div>
                  <div className="ClientsDetails">
                    <p>Vendor Details</p>
                    <h6 className="ClientName">Bigfoot Drywall</h6>
                    <p className="ClientEmail">linden@ibuildapplications.com</p>
                    <p className="ClientNumber">4034005933</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="fw-700">Items to be Fixed</h3>
                  <div className="ItemAreaBox ItemAreaBoxUnderline mb-5">
                    <h6>#1 - Leaking Sink</h6>
                    <h6>#2 Faucet Problem</h6>
                  </div>
                  <div className="ClientsDetails">
                    <h6 className="ClientName">Technician</h6>
                    <p className="ClientEmail">John Doe</p>
                    <p className="ClientNumber">Contact: (403)555-555</p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="DatesBoxAreaMain">
                    <h3>Dates</h3>
                    <div className="DatesBoxArea">
                      <h6>#1 - Leaking Sink</h6>
                      <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                      <p className="mb-3">Mountain Time - US &amp; Canada</p>
                      <div className="AddToSocialArea">
                        <h6>Add to</h6>
                        <a href="javascript:void(0);">
                          <img src="images/google-calendar.png" /> Calendar
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/outlook-calendar.png" />
                          Outlook Calender
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/yahoo-calendar.png" />
                          Yahoo Calender
                        </a>
                      </div>
                    </div>
                    <div className="DatesBoxArea">
                      <h6>#2 - Faucet Problem</h6>
                      <p>02:30pm - 04:30pm. Wednesday, May 17, 2023 </p>
                      <p className="mb-3">Mountain Time - US &amp; Canada</p>
                      <div className="AddToSocialArea">
                        <h6>Add to</h6>
                        <a href="javascript:void(0);">
                          <img src="images/google-calendar.png" /> Calendar
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/outlook-calendar.png" />
                          Outlook Calender
                        </a>
                        <a href="javascript:void(0);">
                          <img src="images/yahoo-calendar.png" />
                          Yahoo Calender
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default WarrantyWorkOrderTradeView;
