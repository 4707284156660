import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import Modal from "react-bootstrap/Modal";
import Loader from "./Loader";
import SignaturePad from "react-signature-canvas";
import { convertToPNG, dataURLtoBlob } from "../utils/blog";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addSignatureInAgreements,
  changeOrderDetails,
} from "../redux/action/changeOrderAction";
import { Button } from "react-bootstrap";
import { companyDetails } from "../redux/action/designSelectionsAction";

const initialState = {
  ManageSignatueModal: false,
  signature: "",
  companyData: [],
  changeOrderData: [],
};
const ChangeOrderAgreement = () => {
  const dispatch = useDispatch();
  const signCanvas = useRef({});
  const [iState, updateState] = useState(initialState);
  const {
    ManageSignatueModal,
    signature,
    companyData,
    changeOrderData,
    signData,
  } = iState;
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(
    location?.search?.split("?")[1].split("#")[0]
  );
  const CompanyId = urlParams?.get("companyId");
  const changeOrderId = urlParams?.get("changeOrderId");
  const clientId = urlParams?.get("clientId");
  const token = urlParams?.get("token");
  const [signatureData, setSignatureData] = useState([]);
  useEffect(() => {
    dispatch(changeOrderDetails(CompanyId, 1, changeOrderId, token)).then(
      (res) => {
        const signDataDummy =
          res?.data?.data?.changeOrderAgreementSignatures?.at(-1);
        setSignatureData(signDataDummy);
        updateState({
          ...iState,
          changeOrderData: res?.data?.data,
        });
      }
    );
  }, []);

  useEffect(() => {
    dispatch(companyDetails(CompanyId, token)).then((res) => {
      if (res.status === 200) {
        updateState({ ...iState, companyData: res?.data?.company });
      }
    });
  }, []);

  const handleManageSignatueShow = () => {
    updateState({
      ...iState,
      ManageSignatueModal: true,
      signature: "",
    });
  };
  const handleManageSignatueClose = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
      signature: "",
    });
  };
  const handleManageSave = () => {
    updateState({
      ...iState,
      ManageSignatueModal: false,
    });
  };

  const addSignatureFun = () => {
    const signatureBlob = dataURLtoBlob(signature);

    const convertToValidFormat = (blob, fileName) => {
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (
        ![
          "jpg",
          "jpeg",
          "png",
          "gif",
          "pdf",
          "bmp",
          "webp",
          "svg+xml",
        ].includes(fileExtension)
      ) {
        const convertedBlob = convertToPNG(blob); // Ensure convertToPNG is defined
        return convertedBlob;
      }
      return blob;
    };

    const validSignatureBlob = convertToValidFormat(
      signatureBlob,
      "signature.png"
    );

    const formData = new FormData();
    formData.append("files", validSignatureBlob, "signature.png");
    // formData.append("name",primary?.legalName);
    formData.append("designation", "Design Manager");
    formData.append("changeOrderId", changeOrderId);

    dispatch(addSignatureInAgreements(CompanyId, 1, formData, token))
      .then((res) => {
        if (res.status === 200) {
          navigate("/client-view-login");
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          updateState({
            ...iState,
            ManageSignatueModal: false,
            signature,
          });
        } else {
          toast.error("Failed to upload signature", {
            position: toast.POSITION.TOP_RIGHT,
          });
          updateState({ ...iState, ManageSignatueModal: true });
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        updateState({ ...iState, ManageSignatueModal: true });
      });
  };

  const clearSignature = () => {
    signCanvas.current.clear();
  };

  const createAboutMarkup = () => {
    return { __html: companyData?.letterHeader };
  };

  const taxTotal =
    changeOrderData?.items?.reduce(
      (accumulator, item) => accumulator + item.poLineTotal,
      0
    ) || 0;

  console.log("signData", signData);
  return (
    <>
      <div className="FormArea mx-5">
        <div className="ModalTitleBox">
          <h4>Agreement View</h4>
        </div>
        <div className="FolderForm">
          <div className="AgreementViewArea">
            <div className="AgreementViewTop">
              <div className="AgreementViewTopRight">
                <p
                  dangerouslySetInnerHTML={createAboutMarkup()}
                  className="editor"
                ></p>
              </div>
            </div>
            <button>Change Order C2</button>
            <div className="AgreementViewTable">
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quanity</th>
                    <th>Measure</th>
                    <th>Amount</th>
                    <th>Line Total</th>
                  </tr>
                </thead>
                <tbody>
                  {changeOrderData?.items?.length > 0 &&
                    changeOrderData?.items?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.description ?? "N/A"}</td>
                          <td>{data.quantity ?? "0"}</td>
                          <td>{data.measure ?? "N/A"}</td>
                          <td>$ {data.clientTotal ?? 0}</td>
                          <td>$ {data.poLineTotal ?? 0}</td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>Total</td>
                    <td>$ {taxTotal ?? 0}</td>
                  </tr>
                  <tr>
                    <td colSpan={4}>After Tax Total</td>
                    <td>$ {taxTotal ?? 0}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <p>
              Note : The TAX rebate, If Applicable, will be adjusted on the
              statement of adjusting at closing
            </p>
            <div className="ChangeOrderCreatArea">
              <h5>
                {/* <span>Change Order Created by :</span> {primary?.legalName} */}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div
                      className="col-md-6"
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="InputBox">
                        <div className="form-group">
                          <img
                            src={signatureData?.imageUrl}
                            style={{
                              border: "1px solid gray",
                              padding: "5px",
                              marginBottom: "10px",
                            }}
                          />

                          {/* <label>{primary?.legalName}</label> */}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6"
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="InputBox">
                        <div className="form-group">
                          <input type="text" />
                          <label>Date</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="InputBox">
                        <div className="form-group">
                          {signature && (
                            <img
                              src={signature}
                              style={{
                                border: "1px solid gray",
                                padding: "5px",
                                marginBottom: "10px",
                                height: "190px",
                                width: "200px",
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                            />
                          )}

                          <Button
                            className="mb-4"
                            onClick={handleManageSignatueShow}
                          >
                            signature
                          </Button>
                          <label>iBuild Client Signature</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Button d-flex justify-content-between">
            <button
              className="Create ClosePermissionId "
              style={{
                margin: "auto",
                marginBottom: "20px",
              }}
              onClick={addSignatureFun}
            >
              {/* <img src="images/history_icon.png" /> */}
              Save
            </button>
          </div>
        </div>
        <Modal
          show={ManageSignatueModal}
          onHide={handleManageSignatueClose}
          className="ModalBox SmallModal"
        >
          <Modal.Body>
            <a className="CloseModal" onClick={handleManageSignatueClose}>
              ×
            </a>

            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Manage Signatures</h4>
              </div>
              <div className="FolderForm">
                <div className="form-group">
                  <div className="CommonModalArea">
                    <h5>Add new signature</h5>
                  </div>
                </div>
                <div className="form-group">
                  <div className="CommonModalArea border p-2">
                    <h5>Signatures</h5>
                    <div className="CommonModalBox">
                      <SignaturePad
                        ref={signCanvas}
                        penColor="green"
                        canvasProps={{
                          width: 400,
                          height: 300,
                          className: "sigCanvas",
                        }}
                        onEnd={() =>
                          updateState({
                            ...iState,
                            signature: signCanvas?.current
                              ?.getTrimmedCanvas()
                              .toDataURL("image/png"),
                          })
                        }
                      />
                      <a onClick={clearSignature}>
                        <img src="images/circle_minus.png" />
                      </a>
                      {/* <a onClick={saveSignature} href="javascript:void(0);">
                        Save
                      </a> */}
                    </div>
                    {/* <span style={{ color: "red", fontSize: "14px" }}>
                    {errors?.signatureError}
                  </span> */}
                  </div>
                </div>

                <div className="Button d-flex justify-content-between">
                  <button className="Create" onClick={handleManageSave}>
                    Save
                  </button>
                  <button
                    className="Outline"
                    onClick={handleManageSignatueClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ChangeOrderAgreement;
