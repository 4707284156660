import React, { useState } from 'react'
import { useEffect } from 'react';
import {Modal} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SubscriptionPlanListAction } from '../redux/action/authAction';
import { checkArray } from '../utils/CheckType';
import Loader from './Loader';
import NoDataFound from './Nodatafound';
 const init = {
    fullDetailsModal:false,
    starterPlanModal:false,
    monthlyAndYearlyState:"Monthly",

    loginDetailsModal:false,
    SubscriptionRequestModal:false,
    SubscriptionRequestReceiveModal:false,
    SubscriptionCompleteModal:false,
    InterestModal:false,
    StarterPlanFeaturesData:[],
    MergedFeaturesData:[],
    speicifiedPlanListData:[],
    currencyType :"usd"
 }
const SubscriptionStart = () => {
    const[iState, updateState] = useState(init);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {planListData, loader} = useSelector((state) => state.authReducer);
    console.log("==========>", planListData)

    const {fullDetailsModal,starterPlanModal,monthlyAndYearlyState,currencyType,MergedFeaturesData, loginDetailsModal,StarterPlanFeaturesData,SubscriptionRequestModal, SubscriptionRequestReceiveModal,SubscriptionCompleteModal, InterestModal,speicifiedPlanListData } = iState;
    const handlefullDetailsModalClose = () => {
        updateState({...iState, fullDetailsModal:false, })
    }
    const handlefullDetailsModalShow = () => {

      var allFeatures = planListData?.plans?.flatMap((plan) =>plan?.Features);
      const uniqueFeatures = removeDuplicates(allFeatures);
    
    const basicPlanFeatures = planListData?.plans?.find(plan => plan.planName === "Basic Plan" && monthlyAndYearlyState === plan.interval)?.Features || [];
    const completePlanFeatures = planListData?.plans?.find(plan => plan.planName === "Complete Plan" && monthlyAndYearlyState === plan.interval)?.Features || [];
    const advancePlanFeatures = planListData?.plans?.find(plan => plan.planName === "Advanced Plan" && monthlyAndYearlyState === plan.interval)?.Features || [];

    const basicPlanId = planListData?.plans?.find(plan => plan.planName === "Basic Plan" && monthlyAndYearlyState === plan.interval)?.id || [];
    const completePlanId = planListData?.plans?.find(plan => plan.planName === "Complete Plan" && monthlyAndYearlyState === plan.interval)?.id || [];
    const advancePlanId = planListData?.plans?.find(plan => plan.planName === "Advanced Plan" && monthlyAndYearlyState === plan.interval)?.id || [];

    const basicPlanPrice = planListData?.plans?.find(plan => plan.planName === "Basic Plan" && monthlyAndYearlyState === plan.interval)?.price || [];
    const completePlanPrice = planListData?.plans?.find(plan => plan.planName === "Complete Plan" && monthlyAndYearlyState === plan.interval)?.price || [];
    const advancePlanPrice = planListData?.plans?.find(plan => plan.planName === "Advanced Plan" && monthlyAndYearlyState === plan.interval)?.price || [];
    const updatedSecondData = uniqueFeatures?.map(feature => ({
      ...feature,
      basic: basicPlanFeatures?.some(f => f.name === feature.name),
      advanced: advancePlanFeatures?.some(f => f.name === feature.name),
      complete: completePlanFeatures?.some(f => f.name === feature.name),
    }));
    updatedSecondData.basicPlanId = basicPlanId
    updatedSecondData.completePlanId = completePlanId
    updatedSecondData.advancePlanId  = advancePlanId ;
    updatedSecondData.basicPrice = basicPlanPrice
    updatedSecondData.completePrice = completePlanPrice
    updatedSecondData.advancePrice  = advancePlanPrice ;
      updateState({...iState, MergedFeaturesData:updatedSecondData,fullDetailsModal:true, starterPlanModal:false,})
  }
  const handleStarterModalClose = () => {
    updateState({...iState, starterPlanModal:false,StarterPlanFeaturesData:[]})
  }

  const handleStarterModalShow = (data) => {
  updateState({...iState, starterPlanModal:true,StarterPlanFeaturesData:data})
  }

  const handleInterestModalClose = () => {
    updateState({...iState, InterestModal:false})
}
const handleInterestModalShow = () => {
  updateState({...iState, InterestModal:true})
}


const handleSubscriptionRequestModalClose = () => {
  updateState({...iState, SubscriptionRequestModal:false})
}
const handleSubscriptionRequestModalShow = () => {
updateState({...iState, SubscriptionRequestModal:true})
}



const SubscriptonRequestReceiveModalClose = () => {
  updateState({...iState, SubscriptionRequestReceiveModal:false})
}
const SubscriptonRequestReceiveModalShow = () => {
updateState({...iState, SubscriptionRequestReceiveModal:true})
}

const SubscriptonCompleteModalClose = () => {
  updateState({...iState, SubscriptionCompleteModal:false})
}
const SubscriptonCompleteModalShow = () => {
updateState({...iState, SubscriptionCompleteModal:true})
}

const handleLoginDetailsClose = () => {
  updateState({...iState, starterPlanModal:false})
}
const handleLoginDetailsShow = () => {
updateState({...iState, starterPlanModal:true})
}
useEffect(() => {
  dispatch(SubscriptionPlanListAction())
},[])
const removeDuplicates = (arr) => {
  return arr?.reduce((unique, item) => {
    if (!unique?.some((feature) => areObjectsEqual(feature, item))) {
      unique.push(item);
    }
    return unique;
  }, []);
};

const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

const handleCheckBox = (e) => {
  const {checked} = e.target;
    if(checked){
      updateState({...iState, monthlyAndYearlyState:"Yearly"})
    }else{
      updateState({...iState, monthlyAndYearlyState:"Monthly"})
    }
}

  return (
  
   <>
  <div className="SubsCriptionBackground">
    <div className="SubscriptionTitleBox">
      <h4 className="Title">Subscription starts</h4>
      <div>
        <img src="images/Logo.png" />
      </div>
    </div>
    {/* ======= Pricing Section ======= */}
    <div className="pricing">
      <div className="section-header">
        <ul>
          <li>
            <a href="javascript:void(0);">Products</a>
          </li>
          <li>
            <a href="javascript:void(0);" className="active">
              Plans &amp; Pricing
            </a>
          </li>
          <li>
            <a href="javascript:void(0);">Contact</a>
          </li>
        </ul>
      </div>
      <div className="PricingArea">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center">
            <div className="form-group mb-0">
              <h6>Monthly</h6>
              <label className="switch">
                <input type="checkbox" name='Monthly' onChange={handleCheckBox} />
                <span className="slider" />
              </label>
              <h6>Yearly</h6>
            </div>
            <button type="button" className="SaveUpto">
              Save Upto 5%
            </button>
            <div class="CurrencyOverlay">
                <img src="images/canadian.png"/>
                <div class="CurrencyOverlaySelect">
                  <select name='currencyType' value={currencyType} onChange={(e) => {
                    updateState({...iState, currencyType:e.target.value})
                  }}>
                      <option value="usd">US Dollars </option>
                    <option value="csd">Canadian Dollars</option>
                  
                  </select>
                  <span><img src="images/black-down-arrow.png"/></span>
                </div>
              </div>
          </div>
          <div className="row g-4 py-lg-5">
          {loader ? (
                    <>
                      <td>
                        {" "}
                        <Loader />
                      </td>
                    </>
                  ) : (
                    <>
                      {checkArray(planListData?.plans) ? (
                        planListData?.plans?.filter((ele)=> ele.interval == monthlyAndYearlyState).map((item, ind) => {
                          return (
                            <>
                          <div className="col-lg-4">
              <div className={`pricing-item ${ ind == 0 ?"featured" :""}`}>
                <div>
                  <h3>{item?.planName}</h3>
                  <h4>
                    {currencyType === "usd" ? <>
                    {item?.price}<span>/{item?.interval}</span>
                    </> : <>
                    {(item?.price * 0.80).toFixed(2)}<span>/{item?.interval}</span>
                    </>}
                    
                  </h4>
                  <div className="PricingList">
                    <ul>
                    {item?.Features?.slice(0, 5)?.map((item) => (
                           <li>
                           <figure>
                             <img src="images/red_check.png" />
                           </figure>{" "}
                           {item?.name}
                         </li>
                        ))}
                    </ul>
                    {item?.isContract ? <></>  : <>
                    <a
                     onClick={() =>handleStarterModalShow(item)}
                      className="SeeDetails"
                    >
                      See Details
                      <img src="images/see_details_arrow.png" />
                    </a>
                    </>}
                 
                  </div>
                </div>
                <div className="text-center">
                  <Link to={`/plan-screen/${item?.id}/${item?.price}`}  state={item?.id} className="buy-btn">
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>


                          
                            </>
                          );
                        })
                      ) : (
                        <td>
                          {" "}
                          <NoDataFound />
                        </td>
                      )}
                    </>
                  )}
          
          </div>
        </div>
      </div>
    </div>
    {/* End Pricing Section */}
  </div>



  <Modal
        show={fullDetailsModal}
        onHide={handlefullDetailsModalClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body > 
      
      <div className="">
        <div className="">
          <a
            href="javascript:void(0);"
            className="CloseModal"
            onClick={handlefullDetailsModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBox">
              <h4>Multi-Family and Residential Platform</h4>
            </div>
            <div className="FolderForm">
              <form>
                <div className="PlanTables">
                  <table>
                    <thead>
                      <tr>
                        <th>Full Plan Comparison</th>
                        <th>Starter Plan</th>
                        <th>Advanced  Plan</th>
                        <th>Complete Plan</th>
                      </tr>
                      <tr>
                        <th>Prices/yearly</th>
                        <th>
                          <h4>
                            <sup>$</sup>{MergedFeaturesData?.basicPrice}
                          </h4>
                        </th>
                        <th>
                          <h4>
                            <sup>$</sup>{MergedFeaturesData?.advancePrice}
                          </h4>
                        </th>
                        <th>
                          <h4>
                            <sup>$</sup>{MergedFeaturesData?.completePrice}
                          </h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {checkArray(MergedFeaturesData) && MergedFeaturesData?.map((ele,i) => {
                        return(
                          <>
 <tr>
                        <td>{ele?.name}</td>
                        <td>
                          <i className={`fa fa-${ele?.basic ? "check":"times"}`} aria-hidden="true" />
                        </td>
                        <td>
                             <i className={`fa fa-${ele?.advanced ? "check":"times"}`} aria-hidden="true" />
                        </td>
                        <td>
                             <i className={`fa fa-${ele?.complete ? "check":"times"}`} aria-hidden="true" />
                        </td>
                      </tr>
                          </>
                        )
                      })}
                     
                   
                     
                  
                    </tbody>
                    <tfoot>
                      <tr>
                        <td />
                        <td>
                          <Link to={`/plan-screen/${MergedFeaturesData?.basicPlanId}/${MergedFeaturesData?.basicPrice}`} state={MergedFeaturesData?.basicPlanId} className="ChoosePlan">
                            Choose Plan
                          </Link>
                        </td>
                        <td>
                        <Link  to={`/plan-screen/${MergedFeaturesData?.advancePlanId}/${MergedFeaturesData?.advancePrice}`} state={MergedFeaturesData?.advancePlanId} className="ChoosePlan">
                            Choose Plan
                          </Link>
                        </td>
                        <td>
                        <Link  to={`/plan-screen/${MergedFeaturesData?.completePlanId}/${MergedFeaturesData?.completePrice}`} state={MergedFeaturesData?.completePlanId} className="ChoosePlan">
                            Choose Plan
                          </Link>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> 
        </Modal.Body>
      </Modal>


      <Modal
        show={starterPlanModal}
        onHide={handleStarterModalClose}
        className="ModalBox SmarterModal"
  >
        <Modal.Body > 
      
        <div >
          <div >
            <a
              href="javascript:void(0);"
              class="CloseModal"
              onClick={handleStarterModalClose}
              >×</a >
            <div class="FormArea">
              
              <div class="FolderForm">
                <form>
                  <div class="pricing">
                    <div class="pricing-item">
                      <div>
                        <h3 class="mb-0">{StarterPlanFeaturesData?.planName}</h3>
                      <h4>$ {StarterPlanFeaturesData?.price}<span> /{StarterPlanFeaturesData?.interval}</span></h4>
                      <div class="">

                      <ul>
                    {StarterPlanFeaturesData?.Features?.slice(0, 10)?.map((item) => (
                           <li>
                           <figure>
                             <img src="images/red_check.png" />
                           </figure>{" "}
                           {item?.name}
                         </li>
                        ))}
                    </ul>
                       
                        <a onClick={handlefullDetailsModalShow} class="SeeDetails FolderPermissionId">See Full Feature List<img src="images/see_details_arrow.png"/></a>
                      </div>
                      </div>
                      <div class="text-center mt-3"><Link  to={`/plan-screen/${StarterPlanFeaturesData?.id}/${StarterPlanFeaturesData?.price}`} state={StarterPlanFeaturesData?.id} class="buy-btn">Subscribe</Link></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
     
     
        </Modal.Body>
      </Modal>

{/* OTHER PAGES       ------------------------------------------------ */}
      <Modal
        show={InterestModal}
        onHide={handleInterestModalClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div>
          <a
           onClick={handleInterestModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>
                    Thank you for your interest in{" "}
                    <span>Ibuild Multi-Family and Residential Platform!</span>
                  </h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p>
                      Our team has received your request and within 24h a sales
                      representative will contact you via phone and email to
                      talk about your contract!
                    </p>
                    <p>
                      Here is a summary of your subscription request details:
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Selected Plan</h6>
                        <h6>Plan 1</h6>
                        <p>Billing: $xxx.xx/year</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Payment Method</h6>
                        <p className="fw-500 mb-2 text-decoration-underline">
                          Here is a summary of your subscription request
                          details:
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="CompanyDetailsTable">
                    <h6>Company Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Primary phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Builder 1</td>
                          <td>admin.builder1@builder1.com</td>
                          <td>(587) 555-5555</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="AddressArea">
                    <h5>Address</h5>
                    <p>Address</p>
                    <p>
                      55 43 St NW <br />
                      Calgary Alberta T3A T3A <br />
                      Canada
                    </p>
                  </div>
                  <div className="SetToStartArea">
                    <p>
                      A copy of your order, invoice and login details was sent
                      to your email. If you have any questions you can reach us
                      at <a href="">support@ibuildapplications.com</a> or{" "}
                      <a href="">(587) 444-4444.</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     
        </Modal.Body>
      </Modal>


 <Modal
        show={SubscriptionCompleteModal}
        onHide={SubscriptonCompleteModalClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div >
          <a
          onClick={SubscriptonCompleteModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>Subscription Completed Successfully!</h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p>
                      Welcome to Ibuild Multi-Family and Residential Platform!
                    </p>
                    <p>Here is a summary of your subscription details:</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Selected Plan</h6>
                        <h6>Plan 1</h6>
                        <p>Billing: $xxx.xx/year</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Payment Method</h6>
                        <p className="fw-500 mb-2 text-decoration-underline">
                          Credit Card
                        </p>
                        <p>
                          Mastercard ending 5454 Grand Total (GST 5% included){" "}
                          <span>$yyy.yy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="CompanyDetailsTable">
                    <h6>Company Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Primary phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Builder 1</td>
                          <td>admin.builder1@builder1.com</td>
                          <td>(587) 555-5555</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="AddressArea">
                    <h5>Address</h5>
                    <p>Address</p>
                    <p>
                      55 43 St NW <br />
                      Calgary Alberta T3A T3A <br />
                      Canada
                    </p>
                  </div>
                  <div className="SetToStartArea">
                    <h6>You are all set to start!</h6>
                    <p>
                      Please use the following <a href="">login link</a> to get
                      started and the email and password informed during the
                      subscription process.
                    </p>
                    <p>
                      A copy of your order, invoice and login details was sent
                      to your email. If you have any questions you can reach us
                      at <a href="">support@ibuildapplications.com</a> or{" "}
                      <a href="">(587) 444-4444.</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
     
        </Modal.Body>
      </Modal>


      <Modal
        show={SubscriptionRequestReceiveModal}
        onHide={SubscriptonRequestReceiveModalClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div>
          <a
          onClick={SubscriptonRequestReceiveModalClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>Subscription Request Received Successfully!</h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p className="text-black">
                      Thank you for your interest{" "}
                      <span>Ibuild Multi-Family and Residential Platform!</span>
                    </p>
                    <p>
                      Our team has received your request and within 24h a sales
                      representative will contact you via phone and email to
                      talk about your contract!
                    </p>
                    <p>Here is a summary of your subscription details:</p>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Selected Plan</h6>
                        <h6>Plan 1</h6>
                        <p>Billing: $xxx.xx/year</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="SelectPlanArea">
                        <h6>Payment Method</h6>
                        <p className="fw-500 mb-2 text-decoration-underline">
                          Credit Card
                        </p>
                        <p>
                          Mastercard ending 5454 Grand Total (GST 5% included){" "}
                          <span>$yyy.yy</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="CompanyDetailsTable">
                    <h6>Company Details</h6>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Primary phone Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Builder 1</td>
                          <td>admin.builder1@builder1.com</td>
                          <td>(587) 555-5555</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="AddressArea">
                    <h5>Address</h5>
                    <p>Address</p>
                    <p>
                      55 43 St NW <br />
                      Calgary Alberta T3A T3A <br />
                      Canada
                    </p>
                  </div>
                  <div className="SetToStartArea">
                    <h6>You are all set to start!</h6>
                    <p>
                      Please use the following <a href="">login link</a> to get
                      started and the email and password informed during the
                      subscription process. Your login details were sent on a
                      separate email
                    </p>
                    <p>
                      If you have any questions you can reach us at{" "}
                      <a href="">support@ibuildapplications.com</a> or{" "}
                      <a href="">(587) 444-4444.</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={loginDetailsModal}
        onHide={handleLoginDetailsClose}
        className="ModalBox MediumModal"
  >
        <Modal.Body >     
        <div>
        <div>
          <a
           onClick={handleLoginDetailsClose}
          >
            ×
          </a>
          <div className="FormArea">
            <div className="ModalTitleBoxBrand ModalTitleBoxBrandOrange">
              <h4>Multi-Family and Residential Platform</h4>
              <a href="">
                <img src="images/logo-orange.png" />
              </a>
            </div>
            <div className="FolderForm">
              <form>
                <div className="SubscriptionArea">
                  <h3>Login Details</h3>
                  <div className="BuilderArea">
                    <h4>Builder 1,</h4>
                    <p>
                      Welcome to{" "}
                      <span>Ibuild Multi-Family and Residential Platform!</span>
                    </p>
                  </div>
                  <div className="SetToStartArea mb-4">
                    <h6>You are all set to start!</h6>
                    <p className="text-grey-80">
                      Please find bellow email and password created during
                      subscription process that must be used during initial
                      login in the platform.
                    </p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Email</h6>
                    <p>admin.builder1@builder1.com</p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Password</h6>
                    <p>ABD4@busrs</p>
                  </div>
                  <div className="LoginDetailsBox">
                    <h6>Login Link</h6>
                    <a href="javascript:vid(0);">
                      https://ibuildapplications.com/login
                    </a>
                  </div>
                  <div className="SetToStartArea">
                    <p>
                      If you have any questions or experience any problem you
                      can reach us at{" "}
                      <a href="javascript:vid(0);">
                        support@ibuildapplications.com
                      </a>
                      or <a href="javascript:vid(0);">(587) 444-4444.</a>
                    </p>
                    <p>
                      You can find additional documentation and tutorials
                      regarding the use of the platform at this{" "}
                      <a href="javascript:vid(0);">link</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
        </Modal.Body>
      </Modal>

</>

  )
}

export default SubscriptionStart
