

import { changeOrderAction,  } from "../actionTypes";
const initialState = {
  allCategoryList:[],
  allAssemblyList:[],
  allMeasureList:[],
  costCategoryList:[],
  costCodeList:[],
  changeOrderLoader:false ,
  changeOrderList: [],
  clientChangeRequestLoader:false ,
  clientChangeRequestList: [],
  itemList:[],
  historyChangeOrderList:[],
};

export default function changeOrderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case changeOrderAction.GET_ALL_CATEGORY_LIST_INITIATE: {
        return {  ...state,allCategoryList: []};
      }
      case changeOrderAction.GET_ALL_CATEGORY_LIST_SUCCESS: {
        return {  ...state,allCategoryList:payload};
      }
      case changeOrderAction.GET_ALL_CATEGORY_LIST_FAILURE: {
        return {  ...state,allCategoryList: []};
      }

      case changeOrderAction.GET_ASSEMBLY_LIST_INITIATE: {
        return {  ...state,allAssemblyList: []};
      }
      case changeOrderAction.GET_ASSEMBLY_LIST_SUCCESS: {
        return {  ...state,allAssemblyList:payload};
      }
      case changeOrderAction.GET_ASSEMBLY_LIST_FAILURE: {
        return {  ...state,allAssemblyList: []};
      }

      case changeOrderAction.GET_MEASURE_LIST_INITIATE: {
        return {  ...state,allMeasureList: []};
      }
      case changeOrderAction.GET_MEASURE_LIST_SUCCESS: {
        return {  ...state,allMeasureList:payload};
      }
      case changeOrderAction.GET_MEASURE_LIST_FAILURE: {
        return {  ...state,allMeasureList: []};
      }

      case changeOrderAction.COST_CATEGORY_LIST_INITIATE: {
        return {  ...state,costCategoryList: []};
      }
      case changeOrderAction.COST_CATEGORY_LIST_SUCCESS: {
        return {  ...state,costCategoryList:payload};
      }
      case changeOrderAction.COST_CATEGORY_LIST_FAILURE: {
        return {  ...state,costCategoryList: []};
      }

      case changeOrderAction.COST_CODE_LIST_INITIATE: {
        return {  ...state,costCodeList: []};
      }
      case changeOrderAction.COST_CODE_LIST_SUCCESS: {
        return {  ...state,costCodeList:payload};
      }
      case changeOrderAction.COST_CODE_LIST_FAILURE: {
        return {  ...state,costCodeList: []};
      }
     

      case changeOrderAction.CHANGE_ORDER_LIST_INITIATE: {
        return {  ...state,changeOrderLoader:true ,changeOrderList: []};
      }
      case changeOrderAction.CHANGE_ORDER_LIST_SUCCESS: {
        return {  ...state,changeOrderLoader:false ,changeOrderList:payload};
      }
      case changeOrderAction.CHANGE_ORDER_LIST_FAILURE: {
        return {  ...state,changeOrderLoader:false ,changeOrderList: []};
      }

      case changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_INITIATE: {
        return {  ...state,clientChangeRequestLoader:true ,clientChangeRequestList: []};
      }
      case changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_SUCCESS: {
        return {  ...state,clientChangeRequestLoader:false ,clientChangeRequestList:payload};
      }
      case changeOrderAction.CLIENT_CHANGE_REQUEST_LIST_FAILURE: {
        return {  ...state,clientChangeRequestLoader:false ,clientChangeRequestList: []};
      }

      case changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_INITIATE: {
        return {  ...state,itemList: []};
      }
      case changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_SUCCESS: {
        return {  ...state,itemList:payload};
      }
      case changeOrderAction.GET_ITEM_BY_CHANGE_ORDER_ID_FAILURE: {
        return {  ...state,itemList: []};
      }

      case changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_INITIATE: {
        return {  ...state,historyChangeOrderList: []};
      }
      case changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_SUCCESS: {
        return {  ...state,historyChangeOrderList:payload};
      }
      case changeOrderAction.HISTORY_CHANGE_ORDER_AGREEMENT_FAILURE: {
        return {  ...state,historyChangeOrderList: []};
      }



    default:
      return state;
  }
}
