
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";


import { toast } from "react-toastify";
import ResidentialUpdate from "./ResidentialUpdate";

import {
 
    getResidentialDetailsAction,
   
  } from "../../../redux/action/jobAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const units = []


const ResidentialDetails = ({residentialDetailsModal, setResidentialDetailsModal,jobId,selectedId}) => {

    const [residentialUpdateModal, setResidentialUpdateModal] =useState(false);
    const dispatch = useDispatch();

    const {
        residentialDetailsData,
      } = useSelector((state) => state.jobReducer);


    
    useEffect(()=>{
        if(residentialDetailsModal){

        dispatch(getResidentialDetailsAction(selectedId, jobId))

        }


    },[residentialDetailsModal])


    console.log(residentialDetailsData,'DDDDDDDDDDDDDDDDD')

  return (
    <>

    <Modal show={residentialDetailsModal} className="ModalBox LargeModal">
   
      <div class="modal-content">
      <a
        class="CloseModal"
        onClick={()=> setResidentialDetailsModal(false)}
        data-dismiss="modal"
      >
        ×
      </a>          <div class="ResidentialJobArea">

<div class="ModalTitleBox GrayModalTitle">
  <h4>Residential Job Details</h4>
</div>

<div class="ResidentialJobHead">
  <h3>
    {residentialDetailsData?.details?.jobNumber}
    <span class="dropdown">
      <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img src="images/dots.png" />
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#"><img src="images/Archive.png" /> Archive Job</a>
      </div>
    </span>
  </h3>
  <article>
    <aside>
      <h4>Created By</h4>
      <p></p>
    </aside>
    <aside>
      <h4>Creation Date</h4>
    

      <p>  {residentialDetailsData?.details?.createdAt?.split("T")[0]}</p>
    </aside>
    <aside>
      <h4>Last Update</h4>
      <p>  {residentialDetailsData?.details?.updatedAt?.split("T")[0]}</p>
    </aside>
  </article>
</div>

<div class="ModalAccordian">
  <div id="accordion">
    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseOne">
        <h4><span><img src="images/generation_information.png" /></span> Project &amp; Foundation</h4>
        <a class="Edit" onClick={()=> setResidentialUpdateModal(true)}>
          <img src="images/Action-1.png" />
        </a>
      </div>
      <div id="collapseOne" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Project</h6>
                  <p>{residentialDetailsData?.details?.Project?.projectName}</p>
                </div>
                <div class="Residential">
                  <h6>Foundation</h6>
                  <p>Basement Development</p>
                </div>
              </div>
            </div>
{/* 
            <div class="col-sm-12">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Add-Ons</h6>
                  <div class="AddonTable">
                    <div class="Addon">
                      <table>
                        <thead>
                          <tr>
                            <th>Job Id</th>
                            <th>Job Id</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>HIL-2B-377</td>
                            <td>Detached Garage or Workshop or Major Storage Shed</td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Action-1.png" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>HIL-2B-378</td>
                            <td>Secondary Suite - Over Detached G arage</td>
                            <td>
                              <a href="javascript:void(0);">
                                <img src="images/Action-1.png" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}



          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseTwo">
        <h4><span><img src="images/Residential-2.png" /></span> Details</h4>
        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#ResidentialDetailsBasementEdit" data-dismiss="modal">
          <img src="images/Action-1.png"/>
        </a>
      </div>
      <div id="collapseTwo" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Home Type</h6>
                  <p>2-Storey</p>
                </div>
                <div class="Residential">
                  <h6>Specification Document</h6>
                  <p>Standard Specifications</p>
                </div>
                <div class="Residential">
                  <h6>Tax Profile</h6>
                  <p>Alberta Profile</p>
                </div>
                <div class="Residential">
                  <h6>Job Site GMT (UTC) Timezone</h6>
                  <p>Moutain Time GMT - 06</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Building Permit #</h6>
                  <p>{residentialDetailsData?.details?.buildingPermit
                  }</p>                </div>
                <div class="Residential">
                  <h6>Base Model</h6>
                  <p>Residential Job</p>
                </div>
                <div class="Residential">
                  <h6>Default Currency</h6>
                  <p>CAD</p>
                </div>
                <div class="Residential">
                  <h6>Accounting Status</h6>
                  <h6 class="fw-400 mb-4">Integrate with Accounting Software</h6>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseThree">
        <h4><span><img src="images/Residential-3.png" /></span> Square Foot</h4>
        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#ResidentialDetailsBasementEdit" data-dismiss="modal">
          <img src="images/Action-1.png" />
        </a>
      </div>
      <div id="collapseThree" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>SqFt Main Floor</h6>
                  <p>{residentialDetailsData?.details?.sqFtMainFloor
                  }</p>   
                </div>
                <div class="Residential">
                  <h6>SqFt Third Floor</h6>
                  <p>{residentialDetailsData?.details?.sqFtThirdFloor
                  }</p>                 </div>
                <div class="Residential">
                  <h6>SqFt Garage Floor</h6>
                  <p>{residentialDetailsData?.details?.sqFtGarageFloor
                  }</p>                 </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>SqFt Second Floor</h6>
                  <p>{residentialDetailsData?.details?.sqFtSecondFloor
                  }</p>                   </div>
                <div class="Residential">
                  <h6>Total House Square Footage</h6>
                  <p>{(+residentialDetailsData?.details?.sqFtMainFloor) + (+residentialDetailsData?.details?.sqFtSecondFloor) + (+residentialDetailsData?.details?.sqFtThirdFloor) ?? 0}</p>
                </div>
                <div class="Residential">
                  <h6>SqFt Basement/Lower Level</h6>
                  <p>{residentialDetailsData?.details?.block
                  }</p>                    </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseFour">
        <h4><span><img src="images/Residential-4.png" /></span> Business Address</h4>
        <a class="Edit" href="javascript:void(0);" data-toggle="modal" data-target="#ResidentialDetailsBasementEdit" data-dismiss="modal">
          <img src="images/Action-1.png" />
        </a>
      </div>
      <div id="collapseFour" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Job Address</h6>
                  <p>{residentialDetailsData?.details?.jobAddress
                  }</p>                   </div>
                <div class="Residential">
                  <h6>Lot</h6>
                  <p>{residentialDetailsData?.details?.lot
                  }</p>                  </div>
                <div class="Residential">
                  <h6>Block</h6>
                  <p>{residentialDetailsData?.details?.block
                  }</p>                   </div>
                <div class="Residential">
                  <h6>Phase</h6>
                  <p>{residentialDetailsData?.details?.phase
                  }</p>                    </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="ResidentialBox">
              <div class="Residential">
                  <h6>City</h6>
                  <p>{residentialDetailsData?.details?.city
                  }</p>                 </div>
                <div class="Residential">
                  <h6>Zip/Postal Code</h6>
                  <p>{residentialDetailsData?.details?.zipCode
                  }</p>                </div>
                <div class="Residential">
                  <h6>Country</h6>
                  <p>{residentialDetailsData?.details?.country}</p>
                </div>
                <div class="Residential">
                  <h6>State/Province</h6>
                  <p>{residentialDetailsData?.details?.province}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseFive">
        <h4><span><img src="images/Residential-5.png" /></span> Sales Information</h4>

      </div>
      <div id="collapseFive" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Agreement Date</h6>
                  <p>05/15/2023</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Possession Date</h6>
                  <p>05/15/2024</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Customer Purchase Price</h6>
                  <p>$450,000.00</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Sales Consultant</h6>
                  <p>Jill Sales</p>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Sales Type</h6>
                  <p>Model Home</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseSix">
        <h4><span><img src="images/Residential-6.png" /></span> Estimation &amp; Construction Information</h4>

      </div>
      <div id="collapseSix" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Primary Estimator/Purchaser</h6>
                  <p>Jack Purchaser</p>
                </div>
                <div class="Residential">
                  <h6>Primary Site Super</h6>
                  <p>Joe Site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header" data-toggle="collapse" href="#collapseSeven">
        <h4><span><img src="images/Residential-7.png" /></span> Client Information</h4>

      </div>
      <div id="collapseSeven" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Personal Information</h6>
                </div>
                <div class="Residential">
                  <h6>Client Full Legal name</h6>
                  <p>Joe Full Client</p>
                </div>
                <div class="Residential">
                  <h6>Primary Phone Number</h6>
                  <p>(587)555-555</p>
                </div>
                <div class="Residential">
                  <h6>Email</h6>
                  <p>joe.fclient@email.com</p>
                </div>
                <div class="Residential">
                  <h6>Business Phone Number</h6>
                  <p>&nbsp;</p>
                </div>
                <div class="Residential">
                  <h6>Fax Phone Number</h6>
                  <p>&nbsp;</p>
                </div>
                <div class="Residential">
                  <h6>Preferred Communication</h6>
                  <p>Email</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="ResidentialBox">
                <div class="Residential">
                  <h6>Address</h6>
                </div>
                <div class="Residential">
                  <h6>Address</h6>
                  <p>{residentialDetailsData?.details?.jobAddres
                  }</p>                                 </div>
            
                <div class="Residential">
                  <h6>City</h6>
                  <p>{residentialDetailsData?.details?.city
                  }</p>                 </div>
                <div class="Residential">
                  <h6>Zip/Postal Code</h6>
                  <p>{residentialDetailsData?.details?.zipCode
                  }</p>                </div>
                <div class="Residential">
                  <h6>Country</h6>
                  <p>{residentialDetailsData?.details?.country}</p>
                </div>
                <div class="Residential">
                  <h6>State/Province</h6>
                  <p>{residentialDetailsData?.details?.province}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="CloseButton">
    <button class="Close ClosePermissionId" data-dismiss="modal">Close</button>
  </div>
</div>
</div>

        </div>
  </Modal>

  {/* <ResidentialUpdate
        residentialUpdateModal={residentialUpdateModal}
        setResidentialUpdateModal={setResidentialUpdateModal}
        // jobId={jobId}
        // selectedId={selectedId}
        createdFrom={"home"}
      /> */}

  </>

  


)
}

export default ResidentialDetails