import { saleActionTypes, vendorActionTypes } from "../actionTypes";
const initialState = {
  loader: false,
staffListData:[],
allLeadsListData:[],
vendorDetailsData:[],
contactListData:[],
addressListData:[],
prefferedListData:[],
followUpListData:[],
contractListData:[],
supprtingDocsListData:[],
scheduledPaymentListData:[],
transactionListData:[],
salesTypeListData:[],
clientAssignedListData:[],
allClientListData:[],
unlockListData:[],
SOAHistoryListData:[],

transactionListData:[],
releaseList: [],
loaderRelease:false,
clientCtcByTemplate:[],
generateHistoryList: [],
loaderHistory:false,
costBreakDownData:[],
marginBreakDownData:[],
allSignatureList:[],
clientDocTemplateList:[],
};

export default function salesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case saleActionTypes.GET_STAFF_LIST_INITIATE: {
        return {
          ...state,
          staffListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_STAFF_LIST_SUCCESS: {
        return {
          ...state,
          staffListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_STAFF_LIST_FAILURE: {
        return {
          ...state,
          staffListData: payload.data,
          loader:false
        };
      }



      case saleActionTypes.SUPPORTING_DOCS_LIST_INITIATE: {
        return {
          ...state,
          supprtingDocsListData: [],
          loader:true
        };
      }
      case saleActionTypes.SUPPORTING_DOCS_LIST_SUCCESS: {
        return {
          ...state,
          supprtingDocsListData: payload,
          loader:false
        };
      }
      case saleActionTypes.SUPPORTING_DOCS_LIST_FAILURE: {
        return {
          ...state,
          supprtingDocsListData: payload.data,
          loader:false
        };
      }

      case saleActionTypes.GET_ALL_CONTRACTS_INITIATE: {
        return {
          ...state,
          contractListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_ALL_CONTRACTS_SUCCESS: {
        return {
          ...state,
          contractListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_CONTRACTS_FAILURE: {
        return {
          ...state,
          contractListData: payload.data,
          loader:false
        };
      }

      case saleActionTypes.GET_FOLLOWUP_LIST_INITIATE: {
        return {
          ...state,
          followUpListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_FOLLOWUP_LIST_SUCCESS: {
        return {
          ...state,
          followUpListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_FOLLOWUP_LIST_FAILURE: {
        return {
          ...state,
          followUpListData: payload.data,
          loader:false
        };
      }

      case saleActionTypes.GET_ALL_LEADS_INITIATE: {
        return {
          ...state,
          allLeadsListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_ALL_LEADS_SUCCESS: {
        return {
          ...state,
          allLeadsListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_LEADS_FAILURE: {
        return {
          ...state,
          allLeadsListData: payload.data,
          loader:false
        };
      }


      case saleActionTypes.GET_ALL_SCHEDULE_INITIATE: {
        return {
          ...state,
          scheduledPaymentListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_ALL_SCHEDULE_SUCCESS: {
        return {
          ...state,
          scheduledPaymentListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_SCHEDULE_FAILURE: {
        return {
          ...state,
          scheduledPaymentListData: payload.data,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_INITIATE: {
        return {
          ...state,
          transactionListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_SUCCESS: {
        return {
          ...state,
          transactionListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_SCHEDULE_PAYMENTS_TRANSACATION_FAILURE: {
        return {
          ...state,
          transactionListData: payload.data,
          loader:false
        };
      }



      case saleActionTypes.SALE_TYPE_INITIATE: {
        return {
          ...state,
          salesTypeListData: [],
          loader:true
        };
      }
      case saleActionTypes.SALE_TYPE_SUCCESS: {
        return {
          ...state,
          salesTypeListData: payload,
          loader:false
        };
      }
      case saleActionTypes.SALE_TYPE_FAILURE: {
        return {
          ...state,
          salesTypeListData: payload.data,
          loader:false
        };
      }


      case saleActionTypes.CLIENT_ASSIGNED_INITIATE: {
        return {
          ...state,
          clientAssignedListData: [],
          loader:true
        };
      }
      case saleActionTypes.CLIENT_ASSIGNED_SUCCESS: {
        return {
          ...state,
          clientAssignedListData: payload,
          loader:false
        };
      }
      case saleActionTypes.CLIENT_ASSIGNED_FAILURE: {
        return {
          ...state,
          clientAssignedListData: payload.data,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_CLIENTS_INITIATE: {
        return {
          ...state,
          allClientListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_ALL_CLIENTS_SUCCESS: {
        return {
          ...state,
          allClientListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_ALL_CLIENTS_FAILURE: {
        return {
          ...state,
          allClientListData: payload.data,
          loader:false
        };
      }


      case saleActionTypes.GET_PARKING_LIST_INITIATE: {
        return {
          ...state,
          parkingListData: [],
          loader:true
        };
      }
      case saleActionTypes.GET_PARKING_LIST_SUCCESS: {
        return {
          ...state,
          parkingListData: payload,
          loader:false
        };
      }
      case saleActionTypes.GET_PARKING_LIST_FAILURE: {
        return {
          ...state,
          parkingListData: payload.data,
          loader:false
        };
      }


      case saleActionTypes.UNLOCK_CONDITION_LIST_INITIATE: {
        return {
          ...state,
          unlockListData: [],
          loader:true
        };
      }
      case saleActionTypes.UNLOCK_CONDITION_LIST_SUCCESS: {
        return {
          ...state,
          unlockListData: payload,
          loader:false
        };
      }
      case saleActionTypes.UNLOCK_CONDITION_LIST_FAILURE: {
        return {
          ...state,
          unlockListData: payload.data,
          loader:false
        };
      }


      case saleActionTypes.SOA_HISTORY_INITIATE: {
        return {
          ...state,
          SOAHistoryListData: [],
          loader:true
        };
      }
      case saleActionTypes.SOA_HISTORY_SUCCESS: {
        return {
          ...state,
          SOAHistoryListData: payload,
          loader:false
        };
      }
      case saleActionTypes.SOA_HISTORY_FAILURE: {
        return {
          ...state,
          SOAHistoryListData: payload.data,
          loader:false
        };
      }


 
      case saleActionTypes.TEMPLATE_RELEASE_LIST_INITIATE: {
        return {...state,releaseList: [],loaderRelease:true};
      }
      case saleActionTypes.TEMPLATE_RELEASE_LIST_SUCCESS: {
        return {...state,releaseList:payload,loaderRelease:false};
      }
      case saleActionTypes.TEMPLATE_RELEASE_LIST_FAILURE: {
        return {...state,releaseList: [],loaderRelease:false};
      }


      case saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_INITIATE: {
        return {...state,clientCtcByTemplate: []};
      }
      case saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_SUCCESS: {
        return {...state,clientCtcByTemplate:payload};
      }
      case saleActionTypes.GET_CLIENT_CTC_BY_TEMPLATE_FAILURE: {
        return {...state,clientCtcByTemplate: []};
      }
      
      case saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_INITIATE: {
        return {...state,generateHistoryList: [],loaderHistory:true};
      }
      case saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_SUCCESS: {
        return {...state,generateHistoryList:payload,loaderHistory:false};
      }
      case saleActionTypes.GET_CLIENT_CTC_GENERATE_HISTORY_FAILURE: {
        return {...state,generateHistoryList: [],loaderHistory:false};
      }

      case saleActionTypes.GET_COST_BREAKDOWN_INITIATE: {
        return {...state,costBreakDownData: []};
      }
      case saleActionTypes.GET_COST_BREAKDOWN_SUCCESS: {
        return {...state,costBreakDownData:payload};
      }
      case saleActionTypes.GET_COST_BREAKDOWN_FAILURE: {
        return {...state,costBreakDownData: []};
      }

      case saleActionTypes.GET_MARGIN_BREAKDOWN_INITIATE: {
        return {...state,marginBreakDownData: []};
      }
      case saleActionTypes.GET_MARGIN_BREAKDOWN_SUCCESS: {
        return {...state,marginBreakDownData:payload};
      }
      case saleActionTypes.GET_MARGIN_BREAKDOWN_FAILURE: {
        return {...state,marginBreakDownData: []};
      }
      
      case saleActionTypes.GET_ALL_SIGNATURE_INITIATE: {
        return {...state,allSignatureList: []};
      }
      case saleActionTypes.GET_ALL_SIGNATURE_SUCCESS: {
        return {...state,allSignatureList:payload};
      }
      case saleActionTypes.GET_ALL_SIGNATURE_FAILURE: {
        return {...state,allSignatureList: []};
      }

      case saleActionTypes.GET_CLIENT_DOC_TEMPLATE_INITIATE: {
        return {...state,clientDocTemplateList: []};
      }
      case saleActionTypes.GET_CLIENT_DOC_TEMPLATE_SUCCESS: {
        return {...state,clientDocTemplateList:payload};
      }
      case saleActionTypes.GET_CLIENT_DOC_TEMPLATE_FAILURE: {
        return {...state,clientDocTemplateList: []};
      }
      
      
    default:
      return state;
  }
}
