import React from 'react'
import Modal from "react-bootstrap/Modal";

const ItinaryConfigure = ({ItinaryConfigureModal, SetItinarytConfigureModal,element}) => {

    const handleItinaryConfigModalClose = () => {
        SetItinarytConfigureModal(false);
    
    
      };
    

  return (

    <Modal show={ItinaryConfigureModal} className="ModalBox LargeModal BgClass">
    <a
      onClick={handleItinaryConfigModalClose}
      class="CloseModal ClosePermissionId"
    >
      ×
    </a>
    <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Configure Notification Details</h4>
              </div>
              <div class="FolderForm GreenSwitch">
                <form>
                  <div class="form-group">
                    <h6>Event</h6>
                    <h6 class="fw-400 fw-14">{element?.eventName}</h6>
                    </div>
                  <div class="form-group">
                    <h6>Notification Type</h6>
                    <h6 class="fw-400 fw-14">Itinerary</h6>
                  </div>

                  <div class="ModalBigheading">
                    <h3><span>Itinerary Item Details</span></h3>
                  </div>
                  
                 
                  <div class="row">
                    
                    <div class="col-md-5">
                      <h6 class="fw-20 fw-600">Subject <span class="required">*</span></h6>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Insert Notification text" />
                       </div>
                      <h6 class="fw-20 fw-600">Description <span class="required">*</span></h6>
                      <div class="form-group">
                        <h6>Insert keys <span class="ml-3"><img src="images/insert_key_24.png" /></span> </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select document key</option>
                         </select>
                       </div>
                       <div class="Button my-3">
                        <button class="Create px-3">Add Key</button>
                       </div>
                       <input type="text" class="form-control" placeholder="Insert Notification text" />
                       </div>
                    </div>
                    
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <h6>Type  </h6>
                        <div class="CommonSelectBox w-100">
                         <select>
                           <option selected="">Select Type</option>
                         </select>
                       </div>
                       </div>
                     <div class="CommonModalArea">
                      <div class="form-group">
                        <h5>Set Reminder</h5>
                        <div class="CommonModalBox">
                          <h5>
                            <figure><img src="images/timer.png" class="mr-2" /></figure>3 days
                            
                          </h5>
                        </div>
                      </div>
                      </div>

                       
                    </div>
                  </div>

                  
                  <div class="Button d-flex justify-content-between mt-4">
                    <button class="Create" data-dismiss="modal">Save</button>
                    <button class="Outline" data-dismiss="modal">Cancel</button>
                  </div>
                   
                </form>
              </div>
            </div>
  </Modal>  )
}

export default ItinaryConfigure