import axios from "axios";
import { projectConstantDK } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));



console.log(iBuildLocalData?.user?.CompanyId, 'COMPANYID')
export const getNewProjectInitate = () => ({
    type: projectConstantDK.GET_NEW_PROJECT_INITIATE,
  });
  
  export const getNewProjectSuccess = (data) => ({
    type: projectConstantDK.GET_NEW_PROJECT_SUCCESS,
    payload: data,
  });
  
  export const getNewProjectFailure = (data) => ({
    type: projectConstantDK.GET_NEW_PROJECT_FAILURE,
    payload: data,
  });
  
  export function getNewProjectAction( pid) {
    const token = isLoggedIn("adminData1");
    const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));
    return (dispatch) => {
      dispatch(getNewProjectInitate());
      return new Promise((resolve, reject) => {
        axios
          .get(`${Url}companies/${iBuildLocalData?.user?.id}/projects/new`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const data = response;
            ////console.log("data", data);
            if (data && data.status == 200) {
              dispatch(getNewProjectSuccess(data.data));
              resolve(data.data);
            } else {
              dispatch(getNewProjectFailure(data.data));
              reject(data);
            }
          })
          .catch((err) => {
            if(err.response.status === 404){
              dispatch(getNewProjectSuccess(err.data));
              resolve(err.data)
            }else {
              dispatch(getNewProjectFailure(err));
              reject(err);
            }
           
          });
      });
    };
  }



  export const createProjectInitiate = () => ({
    type: projectConstantDK.CREATE_NEW_PROJECT_INITIATE,
  });
  
  export const createProjectSuccess = (data) => ({
    type: projectConstantDK.CREATE_NEW_PROJECT_SUCCESS,
    payload: data,
  });
  
  export const createProjectFailure = (data) => ({
    type: projectConstantDK.CREATE_NEW_PROJECT_FAILURE,
    payload: data,
  });
  
  export const createProjectAction = (payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(createProjectInitiate(payload));
        const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response;
        if (data && data.status == 201) {
          dispatch(createProjectSuccess(response.data));
        } else {
          dispatch(createProjectFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(createProjectFailure(err));
        return err.response
      }
    };






    export const updateProjectInitate = () => ({
      type: projectConstantDK.UPDATE_PROJECT_INITIATE,
    });
    
    export const updateProjectSuccess = (data) => ({
      type: projectConstantDK.UPDATE_PROJECT_SUCCESS,
      payload: data,
    });
    
    export const updateProjectFailure = (data) => ({
      type: projectConstantDK.UPDATE_PROJECT_FAILURE,
      payload: data,
    });
    
    export function updateProjectAction(payload, params) {
      const token = isLoggedIn("adminData1");
      const updatedData = multiPartData({ image: payload.image, ...payload })
      return (dispatch) => {
        dispatch(updateProjectInitate());
        return new Promise((resolve, reject) => {
          // ${payload?payload:""}
          axios
            .put(
              `${Url}companies/${iBuildLocalData?.user?.id}/projects/${params}`,
              updatedData,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((response) => {
              const data = response.data;
              if ((data && data.status == 201) || 200) {
                dispatch(updateProjectSuccess(data));
                resolve(data);
              } else {
                dispatch(updateProjectFailure(data));
                reject(data);
              }
            })
            .catch((err) => {
              dispatch(updateProjectFailure(err));
              reject(err);
            });
        });
      }
    }
    
    






    export const createProjectAsEntityInitiate = () => ({
      type: projectConstantDK.CREATE_PROJECT_ENTITY_INITIATE,
    });
    
    export const createProjectAsEntitySuccess = (data) => ({
      type: projectConstantDK.CREATE_PROJECT_ENTITY_SUCCESS,
      payload: data,
    });
    
    export const createProjectAsEntityFailure = (data) => ({
      type: projectConstantDK.CREATE_PROJECT_ENTITY_FAILURE,
      payload: data,
    });
    
    export const createProjectAsEntityAction = (payload) => async (dispatch) => {
        try {
          const token = isLoggedIn("adminData1");
          dispatch(createProjectAsEntityInitiate(payload));
          const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects-entity`,payload, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = response;
          if (data && data.status == 201) {
            dispatch(createProjectAsEntitySuccess(response.data));
          } else {
            dispatch(createProjectAsEntityFailure(response.data));
          }
          return response;
        } catch (err) {
          dispatch(createProjectFailure(err));
          return err.response
        }
      };
  
  
  
  
  
  