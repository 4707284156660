
import { accountingAction } from "../actionTypes";
import { Url } from "../../Config/Config";
import axios from "axios";
import { isLoggedIn } from "../../utils/tokenCheck";
import { multiPartData, multiPartDatas } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

//===========================getArchivelist===============================

export const getAllContractListInitiate = () => ({
    type: accountingAction.GET_ALL_CONTRACT_LIST_INITIATE,
  });
  
  export const getAllContractListSuccess = (data) => ({
    type: accountingAction.GET_ALL_CONTRACT_LIST_SUCCESS,
    payload: data,
  });
  
  export const getAllContractListFailure = (data) => ({
    type: accountingAction.GET_ALL_CONTRACT_LIST_FAILURE,
    payload: data,
  });
  
  export const getAllContractList = (jobId,jobType) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(getAllContractListInitiate());
        const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts-search?jobId=${jobId}&jobType=${jobType}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(getAllContractListSuccess(response.data));
        } else {
          dispatch(getAllContractListFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(getAllContractListFailure(err));
        return err.response
      }
    };

//======================================update-contract-assign-unit-=========================

export const updateContactAssignUnitInitiate = () => ({
    type: accountingAction.UPDATE_CONTRACT_ASSIGN_UNIT_INITIATE,
  });
  
  export const updateContactAssignUnitSuccess = (data) => ({
    type: accountingAction.UPDATE_CONTRACT_ASSIGN_UNIT_LIST_SUCCESS,
    payload: data,
  });
  
  export const updateContactAssignUnitFailure = (data) => ({
    type: accountingAction.UPDATE_CONTRACT_ASSIGN_UNIT_LIST_FAILURE,
    payload: data,
  });
  
  export const updateContactAssignUnit = (contracId,payload) => async (dispatch) => {
      try {
        const token = isLoggedIn("adminData1");
        dispatch(updateContactAssignUnitInitiate());
        const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/assign-unit`,payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status == 200) {
          dispatch(updateContactAssignUnitSuccess(response.data));
        } else {
          dispatch(updateContactAssignUnitFailure(response.data));
        }
        return response;
      } catch (err) {
        dispatch(updateContactAssignUnitFailure(err));
        return err.response
      }
    };
//=======================================create-transaction============

export const createTransactionInitiate = () => ({
  type: accountingAction.CREATE_TRANSACTON_INITIATE,
});

export const createTransactionSuccess = (data) => ({
  type: accountingAction.CREATE_TRANSACTON_SUCCESS,
  payload: data,
});

export const createTransactionFailure = (data) => ({
  type: accountingAction.CREATE_TRANSACTON_FAILURE,
  payload: data,
});

export const createTransaction = (contracId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createTransactionInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/contract-transactions`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(createTransactionSuccess(response.data));
      } else {
        dispatch(createTransactionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createTransactionFailure(err));
      return err.response
    }
  };

//========================transaction--list======================


export const transactionListInitiate = () => ({
  type: accountingAction.TRANSACTON_LIST_INITIATE,
});

export const transactionListSuccess = (data) => ({
  type: accountingAction.TRANSACTON_LIST_SUCCESS,
  payload: data,
});

export const transactionListFailure = (data) => ({
  type: accountingAction.TRANSACTON_LIST_FAILURE,
  payload: data,
});

export const getTransactionList = (contracId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(transactionListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/contract-transactions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(transactionListSuccess(response.data));
      } else {
        dispatch(transactionListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(transactionListFailure(err));
      return err.response
    }
  };

//=======================CHANGE_TRANSACTION_STATUS_TO_VOID=========

export const changeTransactioStatusToVoidInitiate = () => ({
  type: accountingAction.CHANGE_TRANSACTION_STATUS_TO_VOID_INITIATE,
});

export const changeTransactioStatusToVoidSuccess = (data) => ({
  type: accountingAction.CHANGE_TRANSACTION_STATUS_TO_VOID_SUCCESS,
  payload: data,
});

export const changeTransactioStatusToVoidFailure = (data) => ({
  type: accountingAction.CHANGE_TRANSACTION_STATUS_TO_VOID_FAILURE,
  payload: data,
});

export const changeTransactioStatusToVoid = (contracId,transId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(changeTransactioStatusToVoidInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/contract-transactions/${transId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(changeTransactioStatusToVoidSuccess(response.data));
      } else {
        dispatch(changeTransactioStatusToVoidFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(changeTransactioStatusToVoidFailure(err));
      return err.response
    }
  };

//=====================================Aadd-supporting-docs-in-transaction===

export const addSupportingDocsInTransactionInitiate = () => ({
  type: accountingAction.ADD_SUPPORTING_DOCS_IN_TRANSACTION_INITIATE,
});

export const addSupportingDocsInTransactionSuccess = (data) => ({
  type: accountingAction.ADD_SUPPORTING_DOCS_IN_TRANSACTION_SUCCESS,
  payload: data,
});

export const addSupportingDocsInTransactionFailure = (data) => ({
  type: accountingAction.ADD_SUPPORTING_DOCS_IN_TRANSACTION_FAILURE,
  payload: data,
});

export const addSupportingDocsInTransaction = (transId,payload) => async (dispatch) => {
    try {
      const updatedData = multiPartDatas(payload );
      const token = isLoggedIn("adminData1");
      dispatch(addSupportingDocsInTransactionInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/transactions/${transId}/supporting-docs`,updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addSupportingDocsInTransactionSuccess(response.data));
      } else {
        dispatch(addSupportingDocsInTransactionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addSupportingDocsInTransactionFailure(err));
      return err.response
    }
  };

//=============================get-all-primary--clients=============


export const getAllPrimaryClientsInitiate = () => ({
  type: accountingAction.GET_ALL_PRIMARY_CLIENTS_INITIATE,
});

export const getAllPrimaryClientsSuccess = (data) => ({
  type: accountingAction.GET_ALL_PRIMARY_CLIENTS_SUCCESS,
  payload: data,
});

export const getAllPrimaryClientsFailure = (data) => ({
  type: accountingAction.GET_ALL_PRIMARY_CLIENTS_FAILURE,
  payload: data,
});

export const getAllPrimaryClients = (contracId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAllPrimaryClientsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/clients?clientType=primary`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAllPrimaryClientsSuccess(response.data));
      } else {
        dispatch(getAllPrimaryClientsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllPrimaryClientsFailure(err));
      return err.response
    }
  };

//=======================================create--schedule--payment=====================


export const createSchedulePaymentInitiate = () => ({
  type: accountingAction.CREATE_SCHEDULE_PAYMENT_INITIATE,
});

export const createSchedulePaymentSuccess = (data) => ({
  type: accountingAction.CREATE_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const createSchedulePaymentFailure = (data) => ({
  type: accountingAction.CREATE_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export const createSchedulePayment = (contracId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createSchedulePaymentInitiate());
      const response = await axios.post(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/schedule-payments`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(createSchedulePaymentSuccess(response.data));
      } else {
        dispatch(createSchedulePaymentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createSchedulePaymentFailure(err));
      return err.response
    }
  };

//=======================================get-schedule--list========================

export const getSchedulePaymentInitiate = () => ({
  type: accountingAction.GET_SCHEDULE_PAYMENT_INITIATE,
});

export const getSchedulePaymentSuccess = (data) => ({
  type: accountingAction.GET_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const getSchedulePaymentFailure = (data) => ({
  type: accountingAction.GET_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export const getSchedulePayment = (contracId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSchedulePaymentInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/schedule-payments`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSchedulePaymentSuccess(response.data));
      } else {
        dispatch(getSchedulePaymentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSchedulePaymentFailure(err));
      return err.response
    }
  };

//=============================remove-assignment============================


export const removeSchedulePaymentInitiate = () => ({
  type: accountingAction.REMOVE_SCHEDULE_PAYMENT_INITIATE,
});

export const removeSchedulePaymentSuccess = (data) => ({
  type: accountingAction.REMOVE_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const removeSchedulePaymentFailure = (data) => ({
  type: accountingAction.REMOVE_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export const removeSchedulePayment = (contracId,schdelId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(removeSchedulePaymentInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/schedule-payments/${schdelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(removeSchedulePaymentSuccess(response.data));
      } else {
        dispatch(removeSchedulePaymentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(removeSchedulePaymentFailure(err));
      return err.response
    }
  };

//==================================update----schedule=======================


export const updateSchedulePaymentInitiate = () => ({
  type: accountingAction.UPDATE_SCHEDULE_PAYMENT_INITIATE,
});

export const updateSchedulePaymentSuccess = (data) => ({
  type: accountingAction.UPDATE_SCHEDULE_PAYMENT_SUCCESS,
  payload: data,
});

export const updateSchedulePaymentFailure = (data) => ({
  type: accountingAction.UPDATE_SCHEDULE_PAYMENT_FAILURE,
  payload: data,
});

export const updateSchedulePayment = (contracId,schdelId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateSchedulePaymentInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/schedule-payments/${schdelId}`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updateSchedulePaymentSuccess(response.data));
      } else {
        dispatch(updateSchedulePaymentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateSchedulePaymentFailure(err));
      return err.response
    }
  };

//=========================get---FOLLOW-ups---list=============

export const getFollowUpsListInitiate = () => ({
  type: accountingAction.GET_FOLLOW_UPS_LIST_INITIATE,
});

export const getFollowUpsListSuccess = (data) => ({
  type: accountingAction.GET_FOLLOW_UPS_LIST_SUCCESS,
  payload: data,
});

export const getFollowUpsListFailure = (data) => ({
  type: accountingAction.GET_FOLLOW_UPS_LIST_FAILURE,
  payload: data,
});

export const getFollowUpsList = (leadId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getFollowUpsListInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(getFollowUpsListSuccess(response.data));
      } else {
        dispatch(getFollowUpsListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getFollowUpsListFailure(err));
      return err.response
    }
  };

//======================================delete---follow--ups======================


export const deleteFollowUpsInitiate = () => ({
  type: accountingAction.DELETE_FOLLOW_UPS_INITIATE,
});

export const deleteFollowUpsSuccess = (data) => ({
  type: accountingAction.DELETE_FOLLOW_UPS_SUCCESS,
  payload: data,
});

export const deleteFollowUpsFailure = (data) => ({
  type: accountingAction.DELETE_FOLLOW_UPS_FAILURE,
  payload: data,
});

export const deleteFollowUps = (leadId,followId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(deleteFollowUpsInitiate());
      const response = await axios.delete(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups/${followId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(deleteFollowUpsSuccess(response.data));
      } else {
        dispatch(deleteFollowUpsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(deleteFollowUpsFailure(err));
      return err.response
    }
  };

//============================================SEnd--invitation================

export const sendInvitationInitiate = () => ({
  type: accountingAction.SEND_FOLLOW_UP_INVITATION_INITIATE,
});

export const sendInvitationSuccess = (data) => ({
  type: accountingAction.SEND_FOLLOW_UP_INVITATION_SUCCESS,
  payload: data,
});

export const sendInvitationFailure = (data) => ({
  type: accountingAction.SEND_FOLLOW_UP_INVITATION_FAILURE,
  payload: data,
});

export const sendInvitation = (leadId,followId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(sendInvitationInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups/${followId}/send-invitation`,{}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        dispatch(sendInvitationSuccess(response.data));
      } else {
        dispatch(sendInvitationFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(sendInvitationFailure(err));
      return err.response
    }
  };

//=============================UPdate--follow---reminder====================

export const updateFollowUpReminderInitiate = () => ({
  type: accountingAction.UPDATE_FOLLOW_UPS_REMINDER_INITIATE,
});

export const updateFollowUpReminderSuccess = (data) => ({
  type: accountingAction.UPDATE_FOLLOW_UPS_REMINDER_SUCCESS,
  payload: data,
});

export const updateFollowUpReminderFailure = (data) => ({
  type: accountingAction.UPDATE_FOLLOW_UPS_REMINDER_FAILURE,
  payload: data,
});

export const updateFollowUpsReminder = (leadId,followId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateFollowUpReminderInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups/${followId}/set-reminder`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        dispatch(updateFollowUpReminderSuccess(response.data));
      } else {
        dispatch(updateFollowUpReminderFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateFollowUpReminderFailure(err));
      return err.response
    }
  };

//==========================================UPDATE-follow----member======================================

export const updateFollowMemberInitiate = () => ({
  type: accountingAction.UPDATE_FOLLOW_MEMBER_INITIATE,
});

export const updateFollowMemberSuccess = (data) => ({
  type: accountingAction.UPDATE_FOLLOW_MEMBER_SUCCESS,
  payload: data,
});

export const updateFollowMemberFailure = (data) => ({
  type: accountingAction.UPDATE_FOLLOW_MEMBER_FAILURE,
  payload: data,
});

export const updateFollowMembers = (leadId,followId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updateFollowMemberInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups/${followId}/assign-staff`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(updateFollowMemberSuccess(response.data));
      } else {
        dispatch(updateFollowMemberFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updateFollowMemberFailure(err));
      return err.response
    }
  };

//=============================get-estimation---list==================

export const getEstimationDocumnetListInitiate = () => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_INITIATE,
});

export const getEstimationDocumnetListSuccess = (data) => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_SUCCESS,
  payload: data,
});

export const getEstimationDocumnetListFailure = (data) => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_FAILURE,
  payload: data,
});

export const getEstimationDocumnetList = (projectId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getEstimationDocumnetListInitiate());
      const response = await axios.get(`${Url}project/${projectId}/estimation-documents`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getEstimationDocumnetListSuccess(response.data));
      } else {
        dispatch(getEstimationDocumnetListFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getEstimationDocumnetListFailure(err));
      return err.response
    }
  };
//===================================cancel===document=====================

export const cancelEstimationDocumentInitiate = () => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_INITIATE,
});

export const cancelEstimationDocumentSuccess = (data) => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_SUCCESS,
  payload: data,
});

export const cancelEstimationDocumentFailure = (data) => ({
  type: accountingAction.GET_ESTIMATION_DOCUMENT_LIST_FAILURE,
  payload: data,
});

export const cancelEstimationDocument = (projectId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(cancelEstimationDocumentInitiate());
      const response = await axios.patch(`${Url}project/${projectId}/estimation-documents`,payload.selectedItems, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(cancelEstimationDocumentSuccess(response.data));
      } else {
        dispatch(cancelEstimationDocumentFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(cancelEstimationDocumentFailure(err));
      return err.response
    }
  };

//===========================GET-soa--details=======================

export const getSoaDetailsInitiate = () => ({
  type: accountingAction.GET_SOA_DETAILS_INITIATE,
});

export const getSoaDetailsSuccess = (data) => ({
  type: accountingAction.GET_SOA_DETAILS_SUCCESS,
  payload: data,
});

export const getSoaDetailsFailure = (data) => ({
  type: accountingAction.GET_SOA_DETAILS_FAILURE,
  payload: data,
});

export const getSoaDetails = (contactId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getSoaDetailsInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contactId}/soa`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getSoaDetailsSuccess(response.data));
      } else {
        dispatch(getSoaDetailsFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getSoaDetailsFailure(err));
      return err.response
    }
  };

//=====================================add----iternanray=====================

export const addIternaryInitiate = () => ({
  type: accountingAction.ADD_FOLLOW_ITERNARAY_INITIATE,
});

export const addIternarySuccess = (data) => ({
  type: accountingAction.ADD_FOLLOW_ITERNARAY_SUCCESS,
  payload: data,
});

export const addIternaryFailure = (data) => ({
  type: accountingAction.ADD_FOLLOW_ITERNARAY_FAILURE,
  payload: data,
});

export const addFollowIternary = (leadId,followId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(addIternaryInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/leads/${leadId}/follow-ups/${followId}/send-itinerary`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 201) {
        dispatch(addIternarySuccess(response.data));
      } else {
        dispatch(addIternaryFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(addIternaryFailure(err));
      return err.response
    }
  };
//=======================update---postal--transaction===========


export const updatePostalTransactionInitiate = () => ({
  type: accountingAction.UPDATE_POSTAL_TRANSACTION_INITIATE,
});

export const updatePostalTransactionSuccess = (data) => ({
  type: accountingAction.UPDATE_POSTAL_TRANSACTION_FAILURE,
  payload: data,
});

export const updatePostalTransactionFailure = (data) => ({
  type: accountingAction.UPDATE_POSTAL_TRANSACTION_SUCCESS,
  payload: data,
});

export const updatePostalTransaction = (contracId,transId,payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(updatePostalTransactionInitiate());
      const response = await axios.put(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/contracts/${contracId}/contract-transactions/${transId}/postal-transaction`,payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(updatePostalTransactionSuccess(response.data));
      } else {
        dispatch(updatePostalTransactionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(updatePostalTransactionFailure(err));
      return err.response
    }
  };

//=============================get-all--supporting--docs=============


export const getAllSupportingDocsTransactionInitiate = () => ({
  type: accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_INITIATE,
});

export const getAllSupportingDocsTransactionSuccess = (data) => ({
  type: accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_SUCCESS,
  payload: data,
});

export const getAllSupportingDocsTransactionFailure = (data) => ({
  type: accountingAction.GET_ALL_SUPPORTING_DOCS_TRANSACTION_FAILURE,
  payload: data,
});

export const getAllSupportingDocsTransactions = (transId) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(getAllSupportingDocsTransactionInitiate());
      const response = await axios.get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/transactions/${transId}/supporting-docs`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status == 200) {
        dispatch(getAllSupportingDocsTransactionSuccess(response.data));
      } else {
        dispatch(getAllSupportingDocsTransactionFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(getAllSupportingDocsTransactionFailure(err));
      return err.response
    }
  };