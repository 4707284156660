import { AdminAccountingConstant } from "../actionTypes";
const initialState={
    transactionAccountSetting:{},
    accountPosting:[],
    loader:false,
}


export default function adminAccountingReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_INITIATE: {
        return {
          ...state, loader:true, transactionAccountSetting:{}
        }
      }
      case AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_SUCCESS: {
        return {
          ...state, loader:false, transactionAccountSetting:payload?.transactionsetting
        }
      }
      case AdminAccountingConstant.GET_TRANSACTION_ACCOUNT_FAILURE: {
        return {
          ...state, loader:false,transactionAccountSetting:{}
        }
      }


      case AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_INITIATE: {
        return {
          ...state, loader:true, accountPosting:[]
        }
      }
      case AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_SUCCESS: {
        return {
          ...state, loader:false, accountPosting:payload?.accountPosting
        }
      }
      case AdminAccountingConstant.GET_ACCOUNT_SYSTEM_POSTING_FAILURE: {
        return {
          ...state, loader:false,accountPosting:[]
        }
      }
  
      
      default: 
      return state
  }
}