import React, { useState, useEffect } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import TradeViewSidenav from "./trade-view-sidenav";
import { useLocation } from "react-router-dom";
import { getDesignCategoryById } from "../redux/action/tradeViewAuthAction";

const DesignSelectionTradeViewDetails = () => {
  const dispatch = useDispatch();
  const { designCategoryIdData } = useSelector((state) => state.tradeViewLogin);
  console.log("designCategoryIdData", designCategoryIdData);

  const { state } = useLocation();
  // console.log("state", state);

  useEffect(() => {
    dispatch(
      getDesignCategoryById(
        state?.item?.CompanyId,
        state?.item?.projectId,
        state?.item?.id
      )
    );
  }, []);

  
  const [selectedIdx, setSelectedIdx] = useState(null);
  return (
    <div>
      {/* <Header /> */}
      <TradeViewSidenav />
      <div className="WrapperAreaClient">
        <div className="WrapperBoxClient">
          {/* 
   <div class="d-flex py-5">
     <h6 class="CursorPointer" data-toggle="modal" data-target="#DeisgnDocuments" data-dismiss="modal" >Deisgn Documents</h6>
   </div> */}
          <div className="MainTitleBox">
            <div className="MainTitle">
              <h2>Design Selections</h2>
            </div>
          </div>
          <div className="TitleBox">
            <div className="TodosButtons">
              <ul className="nav nav-tabs">
                {designCategoryIdData?.data?.selectionType === "interior" && (
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#InteriorSelectionsTab"
                    >
                      <img src="images/warranty-request.png" />
                      Interior Selections<span>3</span>
                    </a>
                  </li>
                )}

                {designCategoryIdData?.data?.selectionType === "exterior" && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#ExteriorSelectionsTab"
                    >
                      <img src="images/work-order-red.png" />
                      Exterior Selections<span>3</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="tab-content DesignSelectionsContent">
            <div
              className="tab-pane fade active show"
              id="InteriorSelectionsTab"
            >
              <div className="InteriorSelections">
                <div className="DesignSelectionHide d-block">
                  <div className="row">
                    <div className="col-md-4 ml-auto">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>
                          {moment(designCategoryIdData?.data?.updatedAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p className="mb-3">
                          {designCategoryIdData?.data?.selectionType}
                        </p>
                        <h6 className="text-black2 fw-600 fw-12">
                          {designCategoryIdData?.data?.selectionType}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="NewAccording mb-4">
                    {designCategoryIdData?.data?.DesignCategoriesJunctions
                      ?.length > 0 &&
                      designCategoryIdData?.data?.DesignCategoriesJunctions?.map(
                        (data, ind) => {
                            const isOpen = ind === selectedIdx;
                          return (
                            <div className="card">
                               <div className={`card-header HeaderGreen mt-2 mb-2 ${isOpen ? 'open' : ''}`}>
                                <h4 onClick={() => setSelectedIdx(isOpen ? null : ind)}>
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>{" "}
                                  {/* Internal Doors{" "} */}
                                </h4>
                               
                              </div>

                              <div  className={`collapse ${isOpen ? 'show' : ''}`}  >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>
                                      Deadline <a href="javascript:void(0);" />{" "}
                                    </h6>
                                    <p>{data.deadline??"N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance</h6>
                                    <p>${data.allowance??0}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Change Order</h6>
                                    <p>{data.category??"N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type</h6>
                                    <p>{data.type??"N/A"}</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>Select pending</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea py-0">
                                  <div className="InteriorSelectionsTable d-block ">
                                    <table style={{ width: "160%" }}>
                                      <tbody>
                                        {
                                          data?.DesingSelectionOptions?.length>0&&
                                          data?.DesingSelectionOptions?.map((items,ind)=>{
                                            return(
                                              <tr  className={
                                                items.selected
                                                  ? "ChangeColor"
                                                  : "RowGreen"
                                              }
                                              key={ind}>
                                              <td>
                                                <div className="Action">
                                                  <img src="images/gate_img.png" />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Name</h6>
                                                  <p className="BlueText">
                                                  {items.name}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Description</h6>
                                                  <p>{items.description}</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Cost Code</h6>
                                                  <p>
                                                  {items?.designOptionPriceBookSelections?.[0]
                                                          ?.costcode ?? ""}
                                                  </p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Measure</h6>
                                                  <p>{items
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.measure ?? ""}</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>
                                                    Builder Per Unit Price{" "}
                                                    {/* <img src="images/Action-1.png" /> */}
                                                  </h6>
                                                  <p>${items
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.perUnit ?? ""}0</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Quantity</h6>
                                                  <p>{items
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.quantity ??0}</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>
                                                    Discount{" "}
                                                    {/* <img src="images/Action-1.png" /> */}
                                                  </h6>
                                                  <p>{items
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.discount ?? 0}%</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Client Total</h6>
                                                  <p>$ {items
                                                          ?.designOptionPriceBookSelections?.[0]
                                                          ?.clientTotal ?? ""}</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="Action">
                                                  <h6>Attachments</h6>
                                                  <div className="Actions">
                                                    <a>
                                                      <img src="images/attachment.png" />
                                                    </a>
                                                    <a>
                                                      <span className="Count">
                                                      {items
                                                              ?.designOptionAttachments
                                                              ?.length ?? 0}
                                                      </span>
                                                    </a>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            )
                                          })
                                        }
                                       
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div
                    className="Button text-center"
                    style={{ margin: "100px 0" }}
                  >
                    <button className="Create mr-5">Update Selection</button>
                    {/* <button class="Create SelectionHistoryBtn">Selection History</button> */}
                    <button className="Outline ml-5">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="ExteriorSelectionsTab">
              <div className="InteriorSelections">
              <div className="DesignSelectionHide d-block">
                  <div className="row">
                    <div className="col-md-4 ml-auto">
                      <div className="form-group">
                        <h6>Last Update</h6>
                        <p>
                          {moment(designCategoryIdData?.data?.updatedAt).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <h6>Selection Status</h6>
                        <p className="mb-3">
                          {designCategoryIdData?.data?.selectionType}
                        </p>
                        <h6 className="text-black2 fw-600 fw-12">
                          {designCategoryIdData?.data?.selectionType}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="NewAccording mb-4">
                    {designCategoryIdData?.data?.DesignCategoriesJunctions
                      ?.length > 0 &&
                      designCategoryIdData?.data?.DesignCategoriesJunctions?.map(
                        (data, ind) => {
                            const isOpen = ind === selectedIdx;
                          return (
                            <div className="card">
                               <div className={`card-header HeaderGreen mt-2 mb-2 ${isOpen ? 'open' : ''}`}>
                                <h4 onClick={() => setSelectedIdx(isOpen ? null : ind)}>
                                  <span>
                                    <img src="images/down_arrow_white.png" />
                                  </span>{" "}
                               
                                </h4>
                               
                              </div>

                              <div  className={`collapse ${isOpen ? 'show' : ''}`}  >
                                <div className="DesignSelectionHeader">
                                  <div className="form-group">
                                    <h6>
                                      Deadline <a href="javascript:void(0);" />{" "}
                                    </h6>
                                    <p>Jan 24, 2024</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Allowance</h6>
                                    <p>$1,000.00</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Change Order</h6>
                                    <p>$0.00</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Type</h6>
                                    <p>Single Option Selection</p>
                                  </div>
                                  <div className="form-group">
                                    <h6>Status</h6>
                                    <p>Selection pending</p>
                                  </div>
                                </div>
                                <div className="InteriorSelectionsArea py-0">
                                  <div className="InteriorSelectionsTable d-block ">
                                    <table style={{ width: "160%" }}>
                                      <tbody>
                                        <tr className="RowGreen">
                                          <td>
                                            <div className="Action">
                                              <img src="images/gate_img.png" />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Name</h6>
                                              <p className="BlueText">
                                                Krosswood Door
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Description</h6>
                                              <p>
                                                36 in. x 80 in. Right Hand
                                                Douglas Fir 9 Lite Provincial
                                                Stain Solid Wood Single Prehung
                                                Front Doo
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Cost Code</h6>
                                              <p>
                                                08.0101 Windows &amp; Doors -
                                                Exterior
                                              </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Measure</h6>
                                              <p>per unit</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>
                                                Builder Per Unit Price{" "}
                                                <img src="images/Action-1.png" />
                                              </h6>
                                              <p>$394.00</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Quantity</h6>
                                              <p>2</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>
                                                Discount{" "}
                                                <img src="images/Action-1.png" />
                                              </h6>
                                              <p>0%</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Client Total</h6>
                                              <p>$945.60</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="Action">
                                              <h6>Attachments</h6>
                                              <div className="Actions">
                                                <a href="javascript:void(0);">
                                                  <img src="images/attachment.png" />
                                                </a>
                                                <a href="javascript:void(0)">
                                                  <span className="Count">
                                                    3
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div
                    className="Button text-center"
                    style={{ margin: "100px 0" }}
                  >
                    <button className="Create mr-5">Update Selection</button>
                    {/* <button class="Create SelectionHistoryBtn">Selection History</button> */}
                    <button className="Outline ml-5">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignSelectionTradeViewDetails;
