import React from 'react'
import Modal from "react-bootstrap/Modal";

const CreateReportModal = ({createReportModal, setCreateReportModal}) => {

    const handleAddContactModalClose = () => setCreateReportModal(false);
    const handleAddContactModalShow = () => {
        setCreateReportModal(true);
    };

  return (
<Modal show={createReportModal} className="ModalBox LargeModal">
    <div class="modal-content">
      <a
        onClick={handleAddContactModalClose}
        class="CloseModal"
        data-dismiss="modal"
      >
        ×
      </a>{" "}
      <div class="FormArea">
              <div class="ModalTitleBox">
                <h4>Create Custom Report</h4>
              </div>
              <div class="FolderForm">
                <form>
                  <div class="ModalBigheading">
                    <h3><span>Base Report: </span>Sale Report 1</h3>
                  </div>
                  <div class="form-group">
                    <h6>Report Name</h6>
                    <input type="text" class="form-control" placeholder="Insert Custom Report Name" />
                  </div>
                  <div class="form-group">
                    <h6>Columns</h6>
                    <h6 class="fw-400 fw-16"> Column 1 </h6>
                    <h6 class="fw-400 fw-16"> Column 2 </h6>
                    <h6 class="fw-400 fw-16"> Column 3 </h6>
                  </div>
                  <div class="form-group Green">
                    <h6>Available in Investor View</h6>
                    <label class="switch ml-4">
                      <input type="checkbox" /> 
                      <span class="slider"></span>
                    </label>
                  </div>
                  <div class="Button d-flex justify-content-between  mt-5">
                    <button class="Outline" data-dismiss="modal">Cancel</button>
                    <button class="Create" data-dismiss="modal">Save Custom Report</button>
                  </div>
                </form>
              </div>
            </div>
    </div>
  </Modal>   )
}

export default CreateReportModal