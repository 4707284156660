import React from 'react'
import Header from './Header'
import SideNav from './SideNav'

const WarrantyWorkOrder1 = () => {
  return (
    <>
    <Header/>
    <SideNav/>
    <div className="WrapperArea">
      <div className="WrapperBox pb-5">
        <div className="MainTitleBox">
          <div className="MainTitle">
            <h2>New Work Order</h2>
          </div>
        </div>
        <div className="TitleBox">
          <h4 className="Title">Work Order #114</h4>
          <div className="TitleBox2 TitleBoxRight">
            <div className="dropdown">
              <h6 data-toggle="dropdown">UUnit3 -CHI -2B -315</h6>
              <span>Multi-Family - Residential Unit</span>
              {/* <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">Select a job</a>
          <a class="dropdown-item" href="#">
            <input type="text" placeholder="-Search">
            <span><img src="images/search.png"></span>
          </a>
          <a class="dropdown-item" href="#">List of jobs loaded from the system</a>
        </div> */}
            </div>
            <a href="#">
              <img src="images/icon-61.png" />
            </a>
            <a href="#" className="mx-3">
              <img src="images/icon-62.png" />
            </a>
            <a href="#">
              <img src="images/Icon-4.png" />
            </a>
          </div>
        </div>
        <div className="WarrantyDashboardForm mb-4">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>
                      Description<span className="Required">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Write here ....."
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-8 ml-auto">
                      <div className="Filter">
                        <div className="form-group FormGroupAcceptance d-flex align-items-center">
                          <label>Status:</label>
                          <input type="text" className="form-control ml-5" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Home Owner Acceptance ?</label>
                    <div className="d-flex">
                      <label className="CheckBox">
                        {" "}
                        Yes
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                      <label className="CheckBox ml-5">
                        {" "}
                        No
                        <input type="checkbox" />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="Filter">
                    <div className="form-group FormGroupAcceptance">
                      <label>Acceptance Date</label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WarrantyDashboardForm">
          <div className="row">
            <div className="col-lg-6">
              <h4>Client Details</h4>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Possession Date</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Request Date</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Client Full Legal Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Primary Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>
                      Job Address{" "}
                      <img src="images/location.png" className="ml-2" />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Supporting Document</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          data-toggle="modal"
                          data-target="#AddNewSupportingDocs2"
                        >
                          <figure>
                            <img src="images/document-support.png" />
                          </figure>
                          Supporting Document
                        </h5>
                        <span
                          className="Count CursorPointer"
                          data-toggle="modal"
                          data-target="#SupportingDocumentList"
                        >
                          3
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="CommonModalArea">
                      <h5>Add Notes</h5>
                      <div className="CommonModalBox">
                        <h5
                          className="CursorPointer"
                          data-toggle="modal"
                          data-target="#NoteAdd2"
                        >
                          <figure>
                            <img src="images/document-support.png" />
                          </figure>
                          Add Notes
                        </h5>
                        <span
                          className="Count CursorPointer"
                          data-toggle="modal"
                          data-target="#NotesList"
                        >
                          3
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ml-auto">
              <h3>Vendor</h3>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Type</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Non Vendor"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Warranty Contacts</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Auto-filled by System"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <div className="CommonModalArea2">
                      <h5>Schedule Trades Day</h5>
                      <div className="CommonModalBox2">
                        <div className="d-flex align-items-center">
                          <h5
                            className="CursorPointer mb-0"
                            data-toggle="modal"
                            data-target="#TradesDaySelectionRequest"
                          >
                            <figure>
                              <img src="images/icon-18.png" />
                            </figure>
                            &nbsp; Trade/Client Suggested Dates
                          </h5>
                          <span
                            className="Count CursorPointer"
                            data-toggle="modal"
                            data-target="#TradesDaySelection11"
                          >
                            3
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="Filter FullFormGroup">
                    <div className="form-group">
                      <label>Trades- Day</label>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>From</label>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>To</label>
                      <input type="time" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="Filter">
                    <div className="form-group">
                      <label>Required Completion Date</label>
                      <input type="date" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mb-3">
          <label className="switch">
            Editable by client
            <input type="checkbox" />
            <span className="slider" />
          </label>
        </div>
        <div className="Tableheader">
          <h6>Work Order Items</h6>
          <div className="Actions">
            <a href="">
              <img src="images/icon-53.png" />
            </a>
            <a href="">
              <img src="images/download.png" />
            </a>
            <a href="">
              <img src="images/setting.png" />
            </a>
          </div>
        </div>
        <div className="TableList TableListHeader ">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>#</th>
                <th>Description</th>
                <th>Inspector</th>
                <th>Date Created</th>
                <th>Date Inspected</th>
                <th>Service Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <label className="CheckBox">
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </td>
                <td>1</td>
                <td>Lux Windows</td>
                <td>Leaking sink</td>
                <td>John Doe</td>
                <td>4/19/2023</td>
                <td>Warranty</td>
                <td>
                  <div className="Actions">
                    <a href="">
                      <img src="images/icon-63.png" />
                    </a>
                    <a href="javascript:void(0)">
                      <span className="Count">3</span>
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <label className="CheckBox">
                    <input type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </td>
                <td>2</td>
                <td>Lux Windows</td>
                <td>Leaking sink</td>
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="TodosButtons">
          <a href="javascript:void(0)" className="ml-0">
            <h6 data-toggle="modal" data-target="#CreateAdditionalWorkOrder">
              <img src="images/show-create-purchase.png" />
              Show/Create Purchase Order
            </h6>
            <span data-toggle="modal" data-target="#WorkOrderPurchaseOrderList">
              3
            </span>
          </a>
        </div>
        <div className="d-flex justify-content-between mt-3 pb-5">
          <button className="BrandBtn">Cencel</button>
          <div className="d-flex ">
            <button className="BrandBtnGreen">Save</button>
            <div className="dropdown ml-3">
              <button
                className="BrandBtnGreen"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Save and <img src="images/down-arrow-white.png" />
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a className="dropdown-item" href="#">
                  Save and Print
                </a>
                <a className="dropdown-item" href="#">
                  Save and E-mail
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="DiscussionArea">
          <div className="DiscussionTitle">
            <h4>Discussion</h4>
            <div className="DiscussionChat">
              <img src="images/down.png" className="DiscussionIcon" />
              <a href="#">
                <img src="images/pdf2.png" />
              </a>
            </div>
          </div>
          <div className="DiscussionHideArea" style={{ display: "block" }}>
            <div className="DiscussionBox">
              <span>FM</span>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add a Comment ....."
                />
                <div className="DiscussionOverlay">
                  <a href="" className="CancelChat">
                    <img src="images/plus-cancel1.png" />
                  </a>
                  <a href="" className="SendChat">
                    <img src="images/forwardbutton.png" />
                  </a>
                </div>
              </div>
            </div>
            <div className="DiscussionBox">
              <span>TH</span>
              <div className="DiscussionRight">
                <h6>Inspections are delayed</h6>
                <small>04/19/2023, at 02:47 pm</small>
              </div>
            </div>
            <div className="ReplyArea">
              <a href="javascript:void(0);">Reply</a>
              <div className="DiscussionBox">
                <span>TH</span>
                <div className="DiscussionRight">
                  <h6>Inspections are delayed</h6>
                  <small>04/19/2023, at 02:47 pm</small>
                </div>
              </div>
              <div className="DiscussionBox">
                <span>TH</span>
                <div className="DiscussionRight">
                  <h6>Inspections are delayed</h6>
                  <small>04/19/2023, at 02:47 pm</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="ModalBox">
      <div
        className="modal fade ExtraLargeModal show"
        id="TradesDaySelection11"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content pb-0">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Selection</h4>
              </div>
              <div className="FolderForm">
                <h3>Work Order #158</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="CalendarLeft">
                        <h6 className="Heading">Trades Day</h6>
                        <div className="ClientsDetails">
                          <p>Client Details</p>
                          <h6 className="ClientName">Luke Anderson</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ClientLocation">
                          <p>
                            Location <img src="images/location.png" />
                          </p>
                          <p className="ClientAddress">
                            #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                            Calgary
                          </p>
                        </div>
                        <div className="ClientsDetails">
                          <p>Vendor Details</p>
                          <h6 className="ClientName">Bigfoot Drywall</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ItemArea">
                          <h6>Items</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6>#1 - Leaking Sink</h6>
                          </div>
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6>#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="ItemArea mb-0">
                          <h6>Selected Dates</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6>#1 - Leaking Sink</h6>
                          </div>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 2</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ItemArea">
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6>#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="InspectionConfirmationArea">
                        <h6>
                          Please select one of the dates bellow or suggest new
                          dates for inspection
                        </h6>
                        <div className="InspectionConfirmationAreaBox">
                          <h6>Selected Dates</h6>
                          <h6 className="IcaBlueHeading">
                            Item #1 - Leaking Sink
                          </h6>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnBlue">
                                Select
                              </button>
                            </div>
                          </div>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnBlue">
                                Select
                              </button>
                            </div>
                          </div>
                          <p className="IcaBluePara">
                            Confirmation Selection dates and respond to builder or
                            add another option ?
                          </p>
                          <div className="d-flex mt-4">
                            <button className="ChangeBtn ChangeBtnReject">
                              Reject
                            </button>
                            <button className="ChangeBtn ChangeBtnConfirm ml-5">
                              Confirm
                            </button>
                          </div>
                        </div>
                        <div className="InspectionConfirmationAreaBox">
                          <h6>Selected Dates</h6>
                          <h6 className="IcaGreenHeading">
                            Item #2 - Faucet Problem
                          </h6>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnGreen">
                                Select
                              </button>
                            </div>
                          </div>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnGreen">
                                Select
                              </button>
                            </div>
                          </div>
                          <p className="IcaBluePara">
                            Confirmation Selection dates and respond to builder or
                            add another option ?
                          </p>
                          <div className="d-flex mt-4">
                            <button className="ChangeBtn ChangeBtnReject">
                              Rejected
                            </button>
                            <button className="ChangeBtn ChangeBtnConfirm ml-5">
                              Confrim
                            </button>
                          </div>
                        </div>
                        <div className="Button">
                          <button
                            className="Create FolderPermissionId"
                            data-toggle="modal"
                            data-target="#TradesDaySelectionSend"
                            data-dismiss="modal"
                          >
                            Suggest New Dates for Rejected Items
                          </button>
                          <button className="Respond mt-2">Confirm Date</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal" id="TradesDaySelectionSend">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDaySelection11"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Selection</h4>
              </div>
              <div className="FolderForm">
                <form action="">
                  <div className="row">
                    <div className="col-lg-8 ml-auto">
                      <div className="ModalFilterAreaBox mb-3">
                        <h4 className="mb-4">Suggested Dates</h4>
                        <div className="RadioArea d-flex  flex-column">
                          <div className="form-group mb-3">
                            <label className="Radio">
                              Same date for all items
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="Radio">
                              Individual dates for each item
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox">
                        <h4>Item # 1 - Leaking Sink</h4>
                        <p> Inspector - John Doe</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ModalFilterAreaBox">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="ModalFilterAreaBox">
                              <h4>Item # 1 - Leaking Sink</h4>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="Button mb-5">
                              <button className="Create">Add Date</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox">
                        <h4>Item # 2 - Leaking Sink</h4>
                        <p> Inspector - John Doe</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ModalFilterAreaBox">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="ModalFilterAreaBox">
                              <h4>Item #2 - Faucet Problem</h4>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="Button mb-5">
                              <button className="Create">Add Date</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Set Reminder</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="FolderPermissionId CursorPointer"
                              data-toggle="modal"
                              data-target="#ReminderModalTradesSend"
                              data-dismiss="modal"
                            >
                              <figure>
                                <img src="images/timer-red.png" />
                              </figure>{" "}
                              Reminder
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="Button mt-5">
                        <button
                          className="Create"
                          data-toggle="modal"
                          data-target="#TradesDaySelection11"
                          data-dismiss="modal"
                        >
                          Send New Dates
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="ReminderModalTradesSend">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDaySelectionSend"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Reminder</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Weeks</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select aumont of weeks for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul style={{ display: "none" }}>
                        <li>- Less than 1 week</li>
                        <li>- 01</li>
                        <li>- 02</li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Date</h6>
                    <div className="Categories Categories3">
                      <div className="Categories_all Categories_all3">
                        <span>Select aumont of days for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul style={{ display: "none" }}>
                        <li>- 01</li>
                        <li>- 02</li>
                        <li>- 03</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      data-toggle="modal"
                      data-target="#TradesDaySelectionSend"
                      data-dismiss="modal"
                    >
                      Set Reminder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal" id="TradesDaySelectionRequest">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Selection</h4>
              </div>
              <div className="FolderForm">
                <form action="">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox mb-3">
                        <h4 className="mb-4">Request Items</h4>
                        <div className="RadioArea d-flex  flex-column">
                          <div className="form-group mb-3">
                            <label className="Radio">
                              Schedule single item
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="Radio">
                              Schedule multiple items
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ModalFilterAreaBox mb-3">
                        <h4 className="mb-4">Suggested Dates</h4>
                        <div className="RadioArea d-flex  flex-column">
                          <div className="form-group mb-3">
                            <label className="Radio">
                              Same date for all items
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group">
                            <label className="Radio">
                              Individual dates for each item
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox">
                        <h4>
                          Item # 1 - Leaking Sink
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </h4>
                        <p> Inspector - John Doe</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ModalFilterAreaBox">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="Button mb-5">
                              <button className="Create">Add Date</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox">
                        <h4>
                          Item # 2 - Leaking Sink
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </h4>
                        <p> Inspector - John Doe</p>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="ModalFilterAreaBox">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Date Option 1</label>
                              <input type="date" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>From</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>To</label>
                              <input type="time" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="Button mb-5">
                              <button className="Create">Add Date</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="ModalFilterAreaBox">
                        <h4>
                          Item # 3 - Leaking Sink
                          <label className="CheckBox">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </h4>
                        <p> Inspector - John Doe</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ModalFilterAreaBox">
                        <h4 className="mb-4">Send to</h4>
                        <div className="RadioArea">
                          <div className="form-group mb-0">
                            <label className="Radio">
                              Client
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="form-group ml-5 mb-0">
                            <label className="Radio">
                              Inspectors
                              <input type="radio" name="Cuisine" />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="Button mt-5">
                          <button
                            className="Create FolderPermissionId"
                            data-toggle="modal"
                            data-target="#TradesDaySelection9"
                            data-dismiss="modal"
                          >
                            Request Confirmation
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="CommonModalArea">
                        <div className="form-group">
                          <h5>Set Reminder</h5>
                          <div className="CommonModalBox">
                            <h5
                              className="CursorPointer FolderPermissionId"
                              data-toggle="modal"
                              data-target="#ReminderModalTrades"
                              data-dismiss="modal"
                            >
                              <figure>
                                <img src="images/timer-red.png" />
                              </figure>{" "}
                              Reminder
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="Button mt-5">
                        <button
                          className="Create FolderPermissionId"
                          data-toggle="modal"
                          data-target="#CalendarPreview2"
                          data-dismiss="modal"
                        >
                          Preview Calendar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal show"
        id="CalendarPreview2"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDaySelectionRequest"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>CalendarPreview</h4>
              </div>
              <div className="FolderForm">
                <h3>Warranty Request #185</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="CalendarLeft">
                        <h6 className="Heading">Trades Day</h6>
                        <div className="ClientsDetails">
                          <p>Client Details</p>
                          <h6 className="ClientName">Luke Anderson</h6>
                          <p>linden@ibuildapplications.com</p>
                          <p>4034005933</p>
                        </div>
                        <div className="ClientLocation">
                          <p>
                            Location <img src="images/location.png" />
                          </p>
                          <p className="ClientAddress">
                            #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                            Calgary
                          </p>
                        </div>
                        <div className="ClientsDetails">
                          <p>Vendor Details</p>
                          <p>Bigfoot Drywall</p>
                          <p>linden@ibuildapplications.com</p>
                          <p>4034005933</p>
                        </div>
                        <div className="ItemArea">
                          <h6>Items</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6 className="mb-0">#1 - Leaking Sink</h6>
                          </div>
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6 className="mb-0">#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="Heading">Selected Dates</div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="CalendarSection">
                        <h6>Select a Date and Time</h6>
                        <div className="ModalCalendarTable">
                          <div className="ModalCalendarTableFull">
                            <table>
                              <thead>
                                <tr>
                                  <th colSpan={7}>
                                    <div className="TableHeader">
                                      <h6 className="MonthName">
                                        August
                                        <img src="images/unfold.png" />
                                      </h6>
                                      <h6 className="YearName">
                                        2022
                                        <img src="images/calendar-right-arrow.png" />
                                      </h6>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th>Sunday</th>
                                  <th>Monday</th>
                                  <th>Tuesday</th>
                                  <th>Wednesday</th>
                                  <th>Thursday</th>
                                  <th>Friday</th>
                                  <th>Saturday</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="TdBgColor">29</div>
                                  </td>
                                  <td>30</td>
                                  <td>1</td>
                                  <td>2</td>
                                  <td>3</td>
                                  <td>4</td>
                                  <td>
                                    <div className="LightBlue">5</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>7</td>
                                  <td>
                                    <div className="LightBlue">8</div>
                                  </td>
                                  <td>9</td>
                                  <td>
                                    <div className="LightBlue">10</div>
                                  </td>
                                  <td>11</td>
                                  <td>
                                    <div className="LightBlue">12</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>13</td>
                                  <td>14</td>
                                  <td>
                                    <div className="LightBlue">15</div>
                                  </td>
                                  <td>16</td>
                                  <td>
                                    <div className="LightGreen">17</div>
                                  </td>
                                  <td>18</td>
                                  <td>
                                    <div className="LightBlue">19</div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>20</td>
                                  <td>21</td>
                                  <td>22</td>
                                  <td>
                                    <div className="LightBlue">23</div>
                                  </td>
                                  <td>24</td>
                                  <td>
                                    <div className="LightGreen">25</div>
                                  </td>
                                  <td>26</td>
                                </tr>
                                <tr>
                                  <td>27</td>
                                  <td>28</td>
                                  <td>29</td>
                                  <td>30</td>
                                  <td>31</td>
                                  <td>
                                    <div className="TdBgColor">1</div>
                                  </td>
                                  <td>
                                    <div className="TdBgColor">2</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {/* <div className="TableFooter">
                              <h6>Time Zone</h6>
                              <p>
                                <img src="images/earth.png" />
                                Mountain time - US &amp; Canada (08:08 am){" "}
                                <img src="images/black-down-arrow.png" />
                              </p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal show" id="ReminderModalTrades">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#TradesDaySelectionRequest"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Reminder</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="form-group">
                    <h6>Weeks</h6>
                    <div className="Categories Categories2">
                      <div className="Categories_all Categories_all2">
                        <span>Select aumont of weeks for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>- Less than 1 week</li>
                        <li>- 01</li>
                        <li>- 02</li>
                      </ul>
                    </div>
                  </div>
                  <div className="form-group">
                    <h6>Date</h6>
                    <div className="Categories Categories3">
                      <div className="Categories_all Categories_all3">
                        <span>Select aumont of days for reminder</span>
                        <img src="images/down.png" />
                      </div>
                      <ul>
                        <li>- 01</li>
                        <li>- 02</li>
                        <li>- 03</li>
                      </ul>
                    </div>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#TradesDaySelectionRequest"
                      data-dismiss="modal"
                    >
                      Set Reminder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade LargeModal show"
        id="TradesDaySelection9"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content pb-0">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDaySelectionRequest"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Selection</h4>
              </div>
              <div className="FolderForm">
                <h3>Work Order #158</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="CalendarLeft">
                        <h6 className="Heading">Trades Day</h6>
                        <div className="ClientsDetails">
                          <p>Client Details</p>
                          <h6 className="ClientName">Luke Anderson</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ClientLocation">
                          <p>
                            Location <img src="images/location.png" />
                          </p>
                          <p className="ClientAddress">
                            #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                            Calgary
                          </p>
                        </div>
                        <div className="ClientsDetails">
                          <p>Vendor Details</p>
                          <h6 className="ClientName">Bigfoot Drywall</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ItemArea">
                          <h6>Items</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6>#1 - Leaking Sink</h6>
                          </div>
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6>#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="ItemArea mb-0">
                          <h6>Selected Dates</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6>#1 - Leaking Sink</h6>
                          </div>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 2</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ItemArea">
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6>#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ClientLocation">
                          <p>Option 2</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="InspectionConfirmationArea">
                        <h6>Confirmation</h6>
                        <div className="InspectionConfirmationAreaBox">
                          <h6>Selected Dates</h6>
                          <h6 className="IcaBlueHeading">
                            Item #1 - Leaking Sink
                          </h6>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnBlue">
                                Change
                              </button>
                            </div>
                          </div>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnBlue">
                                Change
                              </button>
                            </div>
                          </div>
                          <p className="IcaBluePara">
                            Confirmation Selection dates and respond to builder or
                            add another option ?
                          </p>
                          <div className="d-flex mt-4">
                            <button className="ChangeBtn ChangeBtnBlue">
                              Add Date
                            </button>
                            <button className="ChangeBtn ChangeBtnBlue ml-5">
                              Confrim
                            </button>
                          </div>
                        </div>
                        <div className="InspectionConfirmationAreaBox">
                          <h6>Selected Dates</h6>
                          <h6 className="IcaGreenHeading">
                            Item #2 - Faucet Problem
                          </h6>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 1</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnGreen">
                                Change
                              </button>
                            </div>
                          </div>
                          <div className="InspectionConfirmationAreaAddress">
                            <div className="ClientLocation">
                              <p>Option 2</p>
                              <p className="ClientAddress">
                                02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                                Mountain Time - US &amp; Canada
                              </p>
                            </div>
                            <div>
                              <button className="ChangeBtn ChangeBtnGreen">
                                Change
                              </button>
                            </div>
                          </div>
                          <p className="IcaBluePara">
                            Confirmation Selection dates and respond to builder or
                            add another option ?
                          </p>
                          <div className="d-flex mt-4">
                            <button className="ChangeBtn ChangeBtnGreen">
                              Add Date
                            </button>
                            <button className="ChangeBtn ChangeBtnGreen ml-5">
                              Confrim
                            </button>
                          </div>
                        </div>
                        <div className="Button">
                          <button
                            className="Create FolderPermissionId"
                            data-toggle="modal"
                            data-target="#TradesDaySelection10"
                            data-dismiss="modal"
                          >
                            Send Response to Builder
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade ExtraLargeModal show"
        id="TradesDaySelection10"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content pb-0">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDaySelection9"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBoxBrand">
                <h4>Trades Day Selection</h4>
                <a href="">
                  <img src="images/logo-white.png" />
                </a>
              </div>
              <div className="FolderForm">
                <h3>Work Order #158</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="CalendarLeft">
                        <h6 className="Heading">Trades Day</h6>
                        <div className="ClientsDetails">
                          <p>Client Details</p>
                          <h6 className="ClientName">Luke Anderson</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ClientLocation">
                          <p>
                            Location <img src="images/location.png" />
                          </p>
                          <p className="ClientAddress">
                            #Unit 2 312 Weddenburn Rd SE <br /> Chinook Gate,
                            Calgary
                          </p>
                        </div>
                        <div className="ClientsDetails">
                          <p>Vendor Details</p>
                          <h6 className="ClientName">Bigfoot Drywall</h6>
                          <p className="ClientEmail">
                            linden@ibuildapplications.com
                          </p>
                          <p className="ClientNumber">4034005933</p>
                        </div>
                        <div className="ItemArea">
                          <h6>Items</h6>
                          <div className="ItemAreaBox ItemAreaBoxBlue">
                            <h6>#1 - Leaking Sink</h6>
                          </div>
                          <div className="ItemAreaBox ItemAreaBoxGreen">
                            <h6>#2 - Faucet Problem</h6>
                          </div>
                        </div>
                        <div className="ItemArea mb-0">
                          <h6>Selected Dates</h6>
                        </div>
                        <div className="ClientLocation ItemAreaBoxGreen mr-3">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                        <div className="ClientLocation ItemAreaBoxGreen mr-3">
                          <p>Option 1</p>
                          <p className="ClientAddress">
                            02:30pm - 04:30pm. Wednesday, May 17, 2023 <br />{" "}
                            Mountain Time - US &amp; Canada
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="InspectionSuccessfulArea">
                        <figure>
                          <img src="images/check.png" />
                        </figure>
                        <h4>Your dates have been received by the Builder!</h4>
                        <p>
                          As soon as the inspector selects the date, you will{" "}
                          <br />
                          receive a confirmation message with all the details!
                        </p>
                        <div className="Button">
                          <button className="Create" data-toggle="modal">
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal" id="SupportingDocumentList">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Documents List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <div>
                    <a
                      href="javascript:void(0);"
                      className="FolderPermissionId"
                      data-toggle="modal"
                      data-target="#AddNewSupportingDocs"
                      data-dismiss="modal"
                    >
                      <img src="images/notebook.png" />
                    </a>
                  </div>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span className="text-red">Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="NotesAreaMain">
                <div className="NotesArea mt-4">
                  <h6>Supporting Documents List</h6>
                  <div>
                    <a href="javascript:void(0);">
                      <img src="images/pdf.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/download.png" />
                    </a>
                    <a href="javascript:void(0);">
                      <img src="images/setting.png" />
                    </a>
                  </div>
                </div>
                <div className="TableList">
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Sent by</th>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>John Doe</td>
                        <td>05/01/2023</td>
                        <td className="Inspection">floor_manual.pdf</td>
                        <td>
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              data-toggle="modal"
                              data-target="#SupportingDocsDetails"
                              data-dismiss="modal"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/delete.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>John Doe</td>
                        <td>05/01/2023</td>
                        <td className="Inspection">photo1.jpg, photo2.jpg</td>
                        <td>
                          <div className="Actions">
                            <a
                              href="javascript:void(0);"
                              className="FolderPermissionId"
                              data-toggle="modal"
                              data-target="#SupportingDocsDetails"
                              data-dismiss="modal"
                            >
                              <img src="images/Action-1.png" />
                            </a>
                            <a href="javascript:void(0);">
                              <img src="images/delete.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  data-toggle="modal"
                  data-target="#AddNewSupportingDocs"
                  data-dismiss="modal"
                >
                  Add New Supporting Docs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="AddNewSupportingDocs">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#SupportingDocumentList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3">
                <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="AddNewSupportingDocs2">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Supporting Docs</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3">
                <button className="Create" data-dismiss="modal">
                  Add Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="SupportingDocsDetails">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#SupportingDocumentList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Supporting Docs Details</h4>
              </div>
              <div className="JobTitleArea">
                <h3>Supporting Documents #2</h3>
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Origin</span> #185
                  </h6>
                </div>
                <div className="JobBox">
                  <h6>
                    <span>Item:</span> #143
                  </h6>
                </div>
              </div>
              <div className="form-group">
                <h6>Select Files</h6>
                <div className="Upload">
                  <span>-Drag and Drop Files or click to select</span>
                  <input type="file" />
                </div>
              </div>
              <div className="FilesAddedTitle">
                <h4>Files</h4>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="FilesAdded">
                <label>File_name.docs</label>
                <span>
                  <img src="images/fileview.png" />
                </span>
                <span>
                  <img src="images/Cross.png" className="cross" />
                </span>
              </div>
              <div className="Button mt-3 d-flex justify-content-between">
                <button
                  className="Create"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Update Documents
                </button>
                <button
                  className="Outline"
                  data-toggle="modal"
                  data-target="#SupportingDocumentList"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="VendorSelection">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Vendor Selection</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="Categories Categories2 mb-5">
                    <h6>Category</h6>
                    <div className="Categories_all Categories_all2">
                      <span>Select a category</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>-Full List</li>
                      <li>Categories loaded from the system</li>
                    </ul>
                  </div>
                  <div className="Categories Categories3">
                    <h6>Name</h6>
                    <div className="Categories_all Categories_all3">
                      <span>Select a vendor</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>List of vendors loaded from the system</li>
                    </ul>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create mr-4">Confirm Selection</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade MediumModal" id="TradesDayDateSelection">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Date Selection</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalFilterAreaBox">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Date Option 1</label>
                        <input type="date" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>From</label>
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>To</label>
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Date Option 2</label>
                        <input type="date" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>From</label>
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>To</label>
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ModalFilterAreaBox">
                  <div className="row">
                    <div className="col-8">
                      <h4 className="mb-4">Send to</h4>
                      <div className="RadioArea">
                        <div className="form-group">
                          <label className="Radio">
                            Client
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="form-group ml-5">
                          <label className="Radio">
                            Inspectors
                            <input type="radio" name="Cuisine" />
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <h6>Set Reminder</h6>
                        <div className="PermissionsName">
                          <h5
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#ReminderModal"
                            data-dismiss="modal"
                          >
                            <span>
                              <img src="images/timer-red.png" />
                            </span>{" "}
                            Set Reminder
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="Button mt-3 d-flex align-items-center justify-content-center">
                  <button
                    className="Create ClosePermissionId"
                    data-dismiss="modal"
                  >
                    Request Confirmation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="ReminderModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#TradesDayDateSelection"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Reminder</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="Categories Categories2">
                    <h6>Date</h6>
                    <div className="Categories_all Categories_all2">
                      <span>Select aumont of time for reminder</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>-01</li>
                      <li>-02</li>
                      <li>-03</li>
                    </ul>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      data-toggle="modal"
                      data-target="#TradesDayDateSelection"
                      data-dismiss="modal"
                    >
                      Set Reminder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade MediumModal" id="TradesDayDateConfirmationPage">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              data-dismiss="modal"
              className="CloseModal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Trades Day Date Confirmation Page</h4>
              </div>
              <div className="FolderForm">
                <div className="ModalFilterArea">
                  <div className="ModalFilterAreaBox">
                    <h4>Client</h4>
                    <p>
                      {" "}
                      Status : <span className="Accepted">Accepted</span>
                    </p>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>Date Option 1</label>
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>From</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>To</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ModalFilterAreaBox">
                    <h4>Inspector</h4>
                    <p>
                      {" "}
                      Status : <span className="Rejected">Rejected</span>
                    </p>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>Date Option 1</label>
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>From</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>To</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ModalFilterAreaBox">
                    <h4>Select New Date</h4>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>Date Option 1</label>
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>From</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>To</label>
                          <input type="time" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="Button d-flex align-items-center justify-content-center mt-5">
                    <button className="Create mr-2" data-dismiss="modal">
                      New Date Confirmation
                    </button>
                    <button className="Create ml-2" data-dismiss="modal">
                      Update Work Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="CreateAdditionalWorkOrder">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Create Additional Work Order P.O.</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="Categories Categories2">
                    <h6>Account</h6>
                    <div className="Categories_all Categories_all2">
                      <span>Select an account</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>Categories loaded from the system</li>
                    </ul>
                  </div>
                  <div className="Button text-center mt-5">
                    <button className="Create mr-4" data-dismiss="modal">
                      Create P.O.
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="CreateAdditionalWorkOrder2">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#WorkOrderPurchaseOrderList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Create Additional Work Order P.O.</h4>
              </div>
              <div className="FolderForm">
                <form>
                  <div className="Categories Categories2">
                    <h6>Account</h6>
                    <div className="Categories_all Categories_all2">
                      <span>Select an account</span>
                      <img src="images/down.png" />
                    </div>
                    <ul>
                      <li>Categories loaded from the system</li>
                    </ul>
                  </div>
                  <div className="Button text-center mt-5">
                    <button
                      className="Create mr-4"
                      data-toggle="modal"
                      data-target="#WorkOrderPurchaseOrderList"
                      data-dismiss="modal"
                    >
                      Create P.O.
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal show" id="WorkOrderPurchaseOrderList">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Work Order's Purchase Order List</h4>
              </div>
              <div className="NotesArea mb-2">
                <h6>Work Order's Purchase Order List</h6>
                <div>
                  <a href="#">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="#">
                    <img src="images/download.png" />
                  </a>
                  <a href="#">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList TableListHeader ">
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th>#</th>
                      <th>Vendor</th>
                      <th>Pre-Tax Total</th>
                      <th>Date Created</th>
                      <th>Account Description</th>
                      <th>Accepted</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>1</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>2</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>3</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>4</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>5</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>6</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="CheckBox">
                          <input type="checkbox" />
                          <span className="checkmark" />
                        </label>
                      </td>
                      <td>7</td>
                      <td>Lux Windows</td>
                      <td>$0.00</td>
                      <td>04/20/20 23 3:27:45 PM</td>
                      <td>Overhead Garage Door - Labor</td>
                      <td>Warranty</td>
                      <td>Pending</td>
                      <td>
                        <div className="Actions">
                          <a href="">
                            <img src="images/three-dots.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="Button mt-5 text-center">
                <button
                  className="Create FolderPermissionId"
                  data-toggle="modal"
                  data-target="#CreateAdditionalWorkOrder2"
                  data-dismiss="modal"
                >
                  Create new P.O.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="NoteDetails">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#NotesList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Notes Details</h4>
              </div>
              <h6>Note #1</h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div>
                <h6>Type</h6>
                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input type="radio" name="Cuisine" defaultValue="text" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input type="radio" name="Cuisine" defaultValue="file" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                      />
                      <small className="form-text text-right">
                        985 characters remaining
                      </small>
                    </div>
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input type="file" />
                      </div>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" className="cross" />
                      </span>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-4">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#NotesList"
                      data-dismiss="modal"
                    >
                      Update Note
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#NotesList"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="NoteAdd">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-toggle="modal"
              data-target="#NotesList"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <h6>Note #1</h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div>
                <h6>Type</h6>
                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input type="radio" name="Cuisine" defaultValue="text" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input type="radio" name="Cuisine" defaultValue="file" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                      />
                      <small className="form-text text-right">
                        985 characters remaining
                      </small>
                    </div>
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input type="file" />
                      </div>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" className="cross" />
                      </span>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-4">
                    <button
                      className="Create"
                      data-toggle="modal"
                      data-target="#NotesList"
                      data-dismiss="modal"
                    >
                      Update Note
                    </button>
                    <button
                      className="Outline"
                      data-toggle="modal"
                      data-target="#NotesList"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade SmallModal" id="NoteAdd2">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Add New Note</h4>
              </div>
              <h6>Note #1</h6>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                </div>
                <div className="JobBox mb-3">
                  <h6>
                    <span>Origin:</span> #185
                  </h6>
                </div>
                <h6>Type</h6>
                <div className="FolderForm">
                  <div className="form-group mb-4">
                    <label className="Radio">
                      Text
                      <input type="radio" name="Cuisine" defaultValue="text" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="Radio">
                      File
                      <input type="radio" name="Cuisine" defaultValue="file" />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="text RadioText">
                    <div className="form-group">
                      <h6>Message (Max 1000 chars limit)</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write here ....."
                      />
                      <small className="form-text text-right">
                        985 characters remaining
                      </small>
                    </div>
                  </div>
                  <div className="file RadioText">
                    <div className="form-group">
                      <h6>Select Files</h6>
                      <div className="Upload">
                        <span>-Drag and Drop Files or click to select</span>
                        <input type="file" />
                      </div>
                    </div>
                    <div className="FilesAdded">
                      <label>File_name.docs</label>
                      <span>
                        <img src="images/fileview.png" />
                      </span>
                      <span>
                        <img src="images/Cross.png" className="cross" />
                      </span>
                    </div>
                  </div>
                  <div className="Button d-flex justify-content-between mt-4">
                    <button className="Create" data-dismiss="modal">
                      Update Note
                    </button>
                    <button className="Outline" data-dismiss="modal">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade LargeModal" id="NotesList">
        <div className="modal-dialog">
          <div className="modal-content">
            <a
              href="javascript:void(0);"
              className="CloseModal ClosePermissionId"
              data-toggle="modal"
              data-target="#NoteAdd"
              data-dismiss="modal"
            >
              ×
            </a>
            <div className="FormArea">
              <div className="ModalTitleBox">
                <h4>Notes List</h4>
              </div>
              <div className="JobTitleArea">
                <div className="JobBox">
                  <h6>
                    <span>Job:</span> UUnit3-CHI-2B-315
                  </h6>
                  <img
                    src="images/notebook.png"
                    className="CursorPointer FolderPermissionId"
                    data-toggle="modal"
                    data-target="#NoteAdd"
                    data-dismiss="modal"
                  />
                </div>
                <div className="JobBox">
                  <h6 className="FolderPermissionId" data-toggle="modal">
                    <span>Origin:</span> #185
                  </h6>
                </div>
              </div>
              <div className="NotesArea mb-2">
                <h6>Notes</h6>
                <div>
                  <a href="#">
                    <img src="images/pdf.png" />
                  </a>
                  <a href="#">
                    <img src="images/download.png" />
                  </a>
                  <a href="#">
                    <img src="images/setting.png" />
                  </a>
                </div>
              </div>
              <div className="TableList">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sent by</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td>
                        Material delay problems <img src="images/circle.png" />
                      </td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId"
                            data-toggle="modal"
                            data-target="#NoteDetails"
                            data-dismiss="modal"
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a>
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>John Doe</td>
                      <td>05/01/2023</td>
                      <td className="Inspection">Inspection notes doc</td>
                      <td>
                        <div className="Actions">
                          <a
                            href="javascript:void(0);"
                            className="FolderPermissionId "
                            data-toggle="modal"
                            data-target="#NoteDetails"
                            data-dismiss="modal"
                          >
                            <img src="images/Action-1.png" />
                          </a>
                          <a>
                            <img src="images/delete.png" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="Button mt-5">
                <button
                  className="Create FolderPermissionId"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#NoteAdd"
                >
                  Add New Note
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default WarrantyWorkOrder1