import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCommonBuildingFoundationStep1 } from "../../../redux/action/proformaAction";

const CommonBuildingFoundationInput = ({
  handleCommonBuildingUnitChange,
  handleShowComponent,
  handleShowComponents,
}) => {
  const dispatch = useDispatch([]);
  const [commonBuildingInput, setCommonBuildingInput] = useState([]);
  useEffect(() => {
    dispatch(getCommonBuildingFoundationStep1()).then((res) => {
      console.log({ res });
      setCommonBuildingInput(res?.data?.data);
    });
  }, []);
  return (
    <>
      <div className="ModalBigheading pl-4 mb-3">
        <h3 className="mb-0">
          <span>Common Buildings Foundation Inputss</span>
        </h3>
      </div>
      <div className="ProformaAccordion">
        <div className="accordion" id="accordionExample">
          {commonBuildingInput?.length > 0 &&
            commonBuildingInput?.map((data, i) => {
              return (
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {data.name}
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="card-body">
                      <div
                        className="EnterProjectTable"
                        style={{ overflowX: "scroll" }}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th />
                              <th>Site Office</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.FoundationPerimeters?.length > 0 &&
                              data?.FoundationPerimeters?.map((item, ind) => {
                                return (
                                  <tr key={ind}>
                                    <td>{item.name}</td>
                                    {item?.BlockFoundations?.length > 0 &&
                                      item?.BlockFoundations?.map(
                                        (blocks, index) => {
                                          return (
                                            <td>
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  className="form-control width-250"
                                                  placeholder="Insert cubic yards"
                                                  name="value"
                                                  value={blocks.value}
                                                  onChange={(e) =>
                                                    handleCommonBuildingUnitChange(
                                                      e,
                                                      i,
                                                      ind,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </td>
                                          );
                                        }
                                      )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <div className="PaginationArea pl-0">
                          <h6>Showing 1 to 10 of 27 entries</h6>
                          <h6>
                            <img src="images/leftarrow.png" /> 01{" "}
                            <img src="images/rightarrow.png" /> Out 10
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="Button d-flex  justify-content-between align-items-center px-5">
        <button
          className="Outline border text-success border-success"
          onClick={() => handleShowComponent("commonBuilding")}
        >
          Cancel
        </button>
        <button
          className="Create bg-success border border-success"
          onClick={() =>
            handleShowComponents("commonBuildingFoundationQuanties")
          }
        >
          Save Draft
        </button>
      </div>
    </>
  );
};

export default CommonBuildingFoundationInput;
