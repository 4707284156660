import React, { useState } from 'react'
import CommonAreaUnit from './commonAreaUnit'
import CommonAreaUnitcomp from './commonAreaUnit'
import FoundationSummaryPreviwe from './foundationSummaryPreviwe'

const CommonArea = ({handleMultiStorieschange,
  commonAreaUnit,
  commonAreaStep2List,
  handleShowComponent

}) => {


  const[show,setShow]=useState({
    commonUnit:true,
    commonSummeryPreview:false
  })
  
  const handleShowComponents=(component)=>{
  setShow({
    commonUnit:component==="commonUnit",
    commonSummeryPreview:component==="commonSummeryPreview",
  })
  }




  return (
    <div className="FolderFormCreateArea Proforma">
    <div className="StepBox Green" style={{ width: "30%" }}>
      <ul>
      <li className={show.commonUnit ? "active" : ""} onClick={() => handleShowComponents('commonUnit')}>
           <span className="Icon">
           <img src={require("../../../assets/images/block_input.png")} />
          </span>
          <span className="Text">
            Common <br /> Areas <br /> Units
          </span>
        </li>
        <li className={show.commonSummeryPreview ? "active" : ""} onClick={() => handleShowComponents('commonSummeryPreview')}>
        
          <span className="Icon">
           <img src={require("../../../assets/images/block_quantities.png")} />
          </span>
          <span className="Text">
            Foundation <br />
            Summary <br /> Preview
          </span>
        </li>
      </ul>
    </div>
    <div className="FieldsMandatory">
      <h6>
        Fields with <span className="required">*</span> are
        mandatory
      </h6>
    </div>
        {
          show.commonUnit && <CommonAreaUnitcomp
          commonAreaUnit={commonAreaUnit}
          handleMultiStorieschange={handleMultiStorieschange}
          handleShowComponent={handleShowComponent}
          handleShowComponents={handleShowComponents}
          />
          
        }
        {
          show.commonSummeryPreview && <FoundationSummaryPreviwe   
          handleShowComponent={handleShowComponent}
          commonAreaStep2List={commonAreaStep2List}
          handleShowComponents={handleShowComponents}
          />
        }
  </div>
  )
}

export default CommonArea