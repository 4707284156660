import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getlistAccessAction,
  itenaryRequestApproveAction,
  itenaryRequestDenyAction,
} from "../../../redux/action/IbuildAdminItineraryAction";
import moment from "moment/moment";

const ItineraryRequestModal = ({
  ItineraryRequestStatus,
  SetItineraryRequestStatus,
  SetItineraySettingState,
}) => {
  const dispatch = useDispatch();
  const { listAccessRequests } = useSelector((state) => state.itineraryReducer);
  const handleItineraySettingModalClose = () => {
    SetItineraySettingState(true);
    SetItineraryRequestStatus(false);
  };
  const handleItineraySettingModalShow = () => {
    SetItineraryRequestStatus(true);
  };

  useEffect(() => {
    dispatch(getlistAccessAction());
  }, []);

  const handleReject = (e, id) => {
    e.preventDefault();

    dispatch(itenaryRequestDenyAction(id))
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deny Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleApprove = (e, id) => {
    e.preventDefault();

    dispatch(itenaryRequestApproveAction(id))
      .then((res) => {
        if (res.status === 200) {
          toast.success("Deny Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Modal show={ItineraryRequestStatus} className="ModalBox MediumModal">
      <div class="modal-content BgCreate">
        <a
          onClick={handleItineraySettingModalClose}
          class="CloseModal"
          data-toggle="modal"
        >
          ×
        </a>

        <div class="FormArea">
          <div class="ModalTitleBox">
            <h4>Incoming Itinerary Access Requests</h4>
          </div>
          <div class="TableList TableHeightScroll">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listAccessRequests?.requests?.map((item) => (
                  <tr>
                    <td>{item?.User?.firstName}</td>
                    <td>Access Granted</td>
                    <td>{moment(item?.createdAt).format("lll")}</td>
                    <td>
                      <div class="Action">
                        <i
                          onClick={(e) => handleApprove(e, item?.id)}
                          class="fa fa-check-circle"
                          aria-hidden="true"
                        ></i>
                        <i
                          onClick={(e) => handleReject(e, item?.id)}
                          class="fa fa-times-circle"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {listAccessRequests?.requests?.length > 0 && (
            <div class="PaginationArea">
              <h6>
                Showing {listAccessRequests?.pagination?.currentPage} to{" "}
                {listAccessRequests?.pagination?.itemsPerPages} of 1 entries
              </h6>
              <h6>
                <img src="images/leftarrow.png" /> <span>01</span>{" "}
                <img src="images/rightarrow.png" /> Out 10
              </h6>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ItineraryRequestModal;
