import { AdminMenuSetupConstant } from "../actionTypes";
const initialState={
    sideMenuList:[],
    jobMenuList:[],
    loader:false,
}


export default function adminMenuSetupReducer(state = initialState, {type, payload}) {
    
    switch(type) {
      case AdminMenuSetupConstant.GET_SIDE_MENU_INITIATE: {
        return {
          ...state, loader:true, sideMenuList:[]
        }
      }
      case AdminMenuSetupConstant.GET_SIDE_MENU_SUCCESS: {
        return {
          ...state, loader:false, sideMenuList:payload?.generalSetting
        }
      }
      case AdminMenuSetupConstant.GET_SIDE_MENU_FAILURE: {
        return {
          ...state, loader:false,sideMenuList:[]
        }
      }


      case AdminMenuSetupConstant.GET_JOB_MENU_INITIATE: {
        return {
          ...state, loader:true, jobMenuList:[]
        }
      }
      case AdminMenuSetupConstant.GET_JOB_MENU_SUCCESS: {
        return {
          ...state, loader:false, jobMenuList:payload?.generalSetting
        }
      }
      case AdminMenuSetupConstant.GET_JOB_MENU_FAILURE: {
        return {
          ...state, loader:false,jobMenuList:[]
        }
      }
  

  
      
      default: 
      return state
  }
}