import { ClientDocumentationActionTypes,  } from "../actionTypes";
const initialState = {
  loader: false,
folderListData:[],
fileListData:[],
folderHistoryListData:[],
folderSearchListData:[],
pendingFileListData:[],
clientDOcumentationListData:[]

};

export default function clientReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ClientDocumentationActionTypes.FOLDER_LIST_INITIATE: {
        return {
          ...state,
          folderListData: [],
          loader:true
        };
      }
      case ClientDocumentationActionTypes.FOLDER_LIST_SUCCESS: {
        return {
          ...state,
          folderListData: payload,
          loader:false
        };
      }
      case ClientDocumentationActionTypes.FOLDER_LIST_FAILURE: {
        return {
          ...state,
          folderListData: payload.data,
          loader:false
        };
      }

      case ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_INITIATE: {
        return {
          ...state,
          folderHistoryListData: [],
          loader:true
        };
      }
      case ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_SUCCESS: {
        return {
          ...state,
          folderHistoryListData: payload,
          loader:false
        };
      }
      case ClientDocumentationActionTypes.FOLDER_HISTORY_LIST_FAILURE: {
        return {
          ...state,
          folderHistoryListData: payload.data,
          loader:false
        };
      }

      case ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_INITIATE: {
        return {
          ...state,
          folderSearchListData: [],
          loader:true
        };
      }
      case ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_SUCCESS: {
        return {
          ...state,
          folderSearchListData: payload,
          loader:false
        };
      }
      case ClientDocumentationActionTypes.SEARCH_FOLDER_LIST_FAILURE: {
        return {
          ...state,
          folderSearchListData: payload.data,
          loader:false
        };
      }

      case ClientDocumentationActionTypes.PENDING_FILE_LIST_INITIATE: {
        return {
          ...state,
          pendingFileListData: [],
          loader:true
        };
      }
      case ClientDocumentationActionTypes.PENDING_FILE_LIST_SUCCESS: {
        return {
          ...state,
          pendingFileListData: payload,
          loader:false
        };
      }
      case ClientDocumentationActionTypes.PENDING_FILE_LIST_FAILURE: {
        return {
          ...state,
          pendingFileListData: payload.data,
          loader:false
        };
      }

      case ClientDocumentationActionTypes.FILES_LIST_INITIATE: {
        return {
          ...state,
          fileListData: [],
          loader:true
        };
      }
      case ClientDocumentationActionTypes.FILES_LIST_SUCCESS: {
        return {
          ...state,
          fileListData: payload,
          loader:false
        };
      }
      case ClientDocumentationActionTypes.FILES_LIST_FAILURE: {
        return {
          ...state,
          fileListData: payload.data,
          loader:false
        };
      }

    

    default:
      return state;
  }
}
