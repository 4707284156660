

import { archiveAction,  } from "../actionTypes";
const initialState = {
    archiveList:[],
    archiveLoader:false,
    archiveEventLoader:[],
    archiveEventLoader:false,
};

export default function archiveReducer(state = initialState, { type, payload }) {
  switch (type) {
    case archiveAction.ARCHIVE_LIST_INITIATE: {
        return {  ...state,archiveLoader:true, archiveList: []};
      }
      case archiveAction.ARCHIVE_LIST_SUCCESS: {
        return {  ...state,archiveLoader:false, archiveList:payload};
      }
      case archiveAction.ARCHIVE_LIST_FAILURE: {
        return {  ...state,archiveLoader:false, archiveList: []};
      }

      case archiveAction.ARCHIVE_EVENT_LIST_INITIATE: {
        return {  ...state,archiveEventLoader:true, archiveEventList: []};
      }
      case archiveAction.ARCHIVE_EVENT_LIST_SUCCESS: {
        return {  ...state,archiveEventLoader:false, archiveEventList:payload};
      }
      case archiveAction.ARCHIVE_EVENT_LIST_FAILURE: {
        return {  ...state,archiveEventLoader:false, archiveEventList: []};
      }


    default:
      return state;
  }
}
