import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify"
import { useDispatch } from "react-redux";



const ReminderModal = ({ reminderModal, setReminderModal,setAssignStaffModal}) => {

  const dispatch = useDispatch()
  const handleOkaypayModalClose = () => {setReminderModal(false)
    setAssignStaffModal(true)
  }

 





  return (
    <>
      <Modal
        show={reminderModal}
        onHide={handleOkaypayModalClose}
        className="ModalBox ExtraSmallModal"
      >
        <a
          onClick={handleOkaypayModalClose}
          className="CloseModal"
          data-dismiss="modal"
        >
          ×
        </a>

        <div class="FormArea">
        <div class="ModalTitleBox">
          <h4>Reminder</h4>
        </div>
        <div class="FolderForm">
          <form>
            <div class="form-group">
              <h6>Weeks</h6>
              <div class="Categories Categories2">
                <div class="Categories_all Categories_all2">
                  <span>Select aumont of weeks for reminder</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>- Less than 1 week</li>
                  <li>- 01</li>
                  <li>- 02</li>

      
                </ul>
              </div>
            </div>

            <div class="form-group">
              <h6>Date</h6>
              <div class="Categories Categories3">
                <div class="Categories_all Categories_all3">
                  <span>Select aumont of days for reminder</span>
                  <img src="images/down.png" />
                </div>
                <ul>
                  <li>- 01</li>
                  <li>- 02</li>
                  <li>- 03</li>
                </ul>
              </div>
            </div>


            <div class="Button text-center mt-5">
              <button class="Create" data-toggle="modal" data-target="#AssignStaffMember6" data-dismiss="modal">Set Reminder</button>
            </div>
          </form>
        </div>
      </div>

   
      </Modal>


    </>
  );
};

export default ReminderModal;
