import axios from "axios";
import { multifamilyJobConstantDK } from "../actionTypes";
import { isLoggedIn } from "../../utils/tokenCheck";
import { Url } from "../../Config/Config";
import { multiPartData } from "../../utils/uploadFile";
const iBuildLocalData = JSON.parse(localStorage.getItem("iBuildAdminData"));

export const createMultiFamilyJobInitiate = () => ({
  type: multifamilyJobConstantDK.CREATE_MULTIFAMILY_INITIATE,
});

export const createMultiFamilyJobSuccess = (data) => ({
  type: multifamilyJobConstantDK.CREATE_MULTIFAMILY_SUCCESS,
  payload: data,
});

export const createMultiFamilyJobFailure = (data) => ({
  type: multifamilyJobConstantDK.CREATE_MULTIFAMILY_FAILURE,
  payload: data,
});

export const createMultiFamilyJobAction =
  (pId, payload) => async (dispatch) => {
    try {
      const token = isLoggedIn("adminData1");
      dispatch(createMultiFamilyJobInitiate(payload));
      const response = await axios.post(
        `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pId}/jobs/multi-family`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response;
      if (data && data.status == 201) {
        dispatch(createMultiFamilyJobSuccess(response.data));
      } else {
        dispatch(createMultiFamilyJobFailure(response.data));
      }
      return response;
    } catch (err) {
      dispatch(createMultiFamilyJobFailure(err));
      return err.response;
    }
  };

export const updateMultiFamilyJobInitate = () => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_INITIATE,
});

export const updateMultiFamilyJobSuccess = (data) => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_SUCCESS,
  payload: data,
});

export const updateMultiFamilyJobFailure = (data) => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_FAILURE,
  payload: data,
});

export function updateMultiFamilyJobAction(payload, params) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload });
  return (dispatch) => {
    dispatch(updateMultiFamilyJobInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .put(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${params}`,
          updatedData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;
          if ((data && data.status == 201) || 200) {
            dispatch(updateMultiFamilyJobSuccess(data));
            resolve(data);
          } else {
            dispatch(updateMultiFamilyJobFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(updateMultiFamilyJobFailure(err));
          reject(err);
        });
    });
  };
}

export const markCompleteMultiFamilyJobInitate = () => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_INITIATE,
});

export const markCompleteMultiFamilyJobSuccess = (data) => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_SUCCESS,
  payload: data,
});

export const markCompleteMultiFamilyJobFailure = (data) => ({
  type: multifamilyJobConstantDK.UPDATE_MULTIFAMILY_FAILURE,
  payload: data,
});

export function markCompleteMultiFamilyJobAction(pId,itemId,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload });
  return (dispatch) => {
    dispatch(updateMultiFamilyJobInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .patch(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pId}/jobs/multi-family/${itemId}/complete`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;

          
          if (data && data.status == 200) {
            dispatch(markCompleteMultiFamilyJobSuccess(data));
            resolve(data);
          } else {
            dispatch(markCompleteMultiFamilyJobFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(markCompleteMultiFamilyJobFailure(err));
          reject(err);
        });
    });
  };
}






export const CreateUnitBlockInitate = () => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_INITIATE,
});

export const CreateUnitBlockSuccess = (data) => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_SUCCESS,
  payload: data,
});

export const CreateUnitBlockFailure = (data) => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_FAILURE,
  payload: data,
});

export function CreateUnitBlockAction(pId,jobId,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload });
  return (dispatch) => {
    dispatch(CreateUnitBlockInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pId}/multi-family/${jobId}/blocks-and-units`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;

          
          if (data && data.status == 200) {
            dispatch(CreateUnitBlockSuccess(data));
            resolve(data);
          } else {
            dispatch(CreateUnitBlockFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(CreateUnitBlockFailure(err));
          reject(err);
        });
    });
  };
}




export const CreateBuildingInitate = () => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_INITIATE,
});

export const CreateBuildingSuccess = (data) => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_SUCCESS,
  payload: data,
});

export const CreateBuildingFailure = (data) => ({
  type: multifamilyJobConstantDK.CREATE_BLOCK_UNIT_FAILURE,
  payload: data,
});

export function CreateBuildingAction(pId,itemId,payload) {
  const token = isLoggedIn("adminData1");
  const updatedData = multiPartData({ image: payload.image, ...payload });
  return (dispatch) => {
    dispatch(CreateBuildingInitate());
    return new Promise((resolve, reject) => {
      // ${payload?payload:""}
      axios
        .post(
          `${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pId}/jobs/multi-family/${itemId}/buiding`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          const data = response.data;

          
          if (data && data.status == 200) {
            dispatch(CreateBuildingSuccess(data));
            resolve(data);
          } else {
            dispatch(CreateBuildingFailure(data));
            reject(data);
          }
        })
        .catch((err) => {
          dispatch(CreateBuildingFailure(err));
          reject(err);
        });
    });
  };
}






export const getMultifamilyJobInitate = () => ({
  type: multifamilyJobConstantDK.GET_MULTIFAMILY_INITIATE ,
});

export const getMultifamilyJobSuccess = (data) => ({
  type: multifamilyJobConstantDK.GET_MULTIFAMILY_SUCCESS,
  payload: data,
});

export const getMultifamilyJobFailure = (data) => ({
  type: multifamilyJobConstantDK.GET_MULTIFAMILY_SUCCESS,
  payload: data,
});

export function getMultifamilyJobAction( pid) {
  const token = isLoggedIn("adminData1");

  return (dispatch) => {
    dispatch(getMultifamilyJobInitate());
    return new Promise((resolve, reject) => {
      axios
        .get(`${Url}companies/${iBuildLocalData?.user?.CompanyId}/projects/${pid}/jobs/multi-family
`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const data = response;
          ////console.log("data", data);
          if (data && data.status == 200) {
            dispatch(getMultifamilyJobSuccess(data.data));
            resolve(data.data);
          } else {
            dispatch(getMultifamilyJobFailure(data.data));
            reject(data);
          }
        })
        .catch((err) => {
          if(err.response.status === 404){
            dispatch(getMultifamilyJobSuccess(err.data));
            resolve(err.data)
          }else {
            dispatch(getMultifamilyJobFailure(err));
            reject(err);
          }
         
        });
    }).then((err) => {
      console.log((err))
    })

  };
}
