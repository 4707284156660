import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "./Nodatafound";
import { checkArray } from "../utils/CheckType";
import Loader from "./Loader";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { errorToast, sucessToast } from "../utils/toast";
import {
  editEstimationAction,
  editUserLimitAction,
  getEstimationDetailsAction,
  getProjectEstimationAction,
  getUserLimitRoleAction,
  setBidForEmailAction,
  setDefaultSettingsAction,
  setRoleLimtiAction,
} from "../redux/action/costProfileAction";
import { constant, update } from "lodash";
import { getEstimation } from "../redux/action/vendorViewConfigAction";
import { getAdminRoleList, getUserList } from "../redux/action/adminUserAction";
import { toast } from "react-toastify";
const init = {
  mailStateToShow: true,
  individualLimitShow: true,
  userLimitToShow: true,
  generalSettingToShow: true,
  projectAdminData: [],
  bidEmail: "",
  bidEmailError: "",
  is_allow_warranty_users: true,
  is_auto_cancel: true,
  estimationValueShow: "",
  constructionValue: "",
  warrantyValue: "",
  purchasingManage: "",
  siteSuperValue: "",
  warrantyStaffValue: "",
  userId: "",
  userName: "",
  limit: "",
  estimationId: "",
  id:"",
};
function EstimationAdmin({ ProjectId }) {
  const [iLeadState, updateLeadState] = useState(init);
  const [userLimitData, setUserLimitData] = useState([]);
  const {
    mailStateToShow,
    individualLimitShow,
    userLimitToShow,
    generalSettingToShow,
    projectAdminData,
    bidEmailError,
    bidEmail,
    is_allow_warranty_users,
    is_auto_cancel,
    estimationValueShow,
    constructionValue,
    warrantyValue,
    purchasingManage,
    siteSuperValue,
    warrantyStaffValue,
    userId,
    userName,
    limit,
    estimationId,
    id
  } = iLeadState;
  const { roleList, userList, userLoader } = useSelector(
    (state) => state.adminUserReducers
  );
  const { userLimitDetailsData } = useSelector((state) => state.costReducer);
  console.log("userLimitDetailsData", userLimitDetailsData);

  const dispatch = useDispatch();
  const handleInputNumber = (e) => {
    const { name, value } = e.target;
    const result = e.target.value.replace(/\D/g, "");
    updateLeadState({ ...iLeadState, [name]: result });
  };
  const { adminEstimationDetailsData } = useSelector(
    (state) => state.costReducer
  );

  const handleInput = (e) => {
    const { name, value } = e.target;
    updateLeadState({ ...iLeadState, [name]: value });
  };

  useEffect(() => {
    if (ProjectId) {
      dispatch(getAdminRoleList());
      dispatch(getUserList());
      dispatch(getEstimationDetailsAction(ProjectId));
      dispatch(getProjectEstimationAction(ProjectId)).then((res) => {
        updateLeadState({ ...iLeadState, projectAdminData: res?.details });
      });
    }
  }, [ProjectId]);

  const saveBidEMail = () => {
    if (!bidEmail) {
      updateLeadState({
        ...iLeadState,
        bidEmailError: "Bid email can't be empty",
      });
    } else if (!ProjectId) {
      errorToast("Please select a project");
    } else {
      let data = {
        projectId: ProjectId,
        bidEmail,
      };

      dispatch(setBidForEmailAction(data)).then((res) => {
        sucessToast("Bid email updated successfully");
        dispatch(getProjectEstimationAction(ProjectId)).then((res) => {
          updateLeadState({
            ...iLeadState,
            projectAdminData: res?.details,
            mailStateToShow: true,
            bidEmail:""
          });
        });
      });
    }
  };
console.log("ProjectId",ProjectId);
  const saveDefaultSettingFunc = () => {
    let data = {
      projectId: ProjectId,
      is_allow_warranty_users,
      is_auto_cancel,
    };
    dispatch(setDefaultSettingsAction(data)).then((Res) => {
      sucessToast("Default setting updated successfully");
      dispatch(getProjectEstimationAction(ProjectId)).then((res) => {
        updateLeadState({
          ...iLeadState,
          projectAdminData: res?.details,
          mailStateToShow: true,
          bidEmailError: "",
          generalSettingToShow:true
        });
      });
    });
  };

  const createEstimationFunc = () => {
    let data = {
      projectId: ProjectId,
      roleLimit: [
        {
          estimateType: "Estimation",
          estimateValue: estimationValueShow,
        },
        {
          estimateType: "Construction Manager",
          estimateValue: constructionValue,
        },
        {
          estimateType: "Warranty Manager",
          estimateValue: warrantyValue,
        },
        {
          estimateType: "Estimation and Purchasing Manager",
          estimateValue: purchasingManage,
        },
        {
          estimateType: "Site Super",
          estimateValue: siteSuperValue,
        },
        {
          estimateType: "Warranty Staff",
          estimateValue: warrantyStaffValue,
        },
      ],
    };
    dispatch(editEstimationAction(data)).then((res) => {
      sucessToast("Estimation updated successfully");
      dispatch(getEstimationDetailsAction(ProjectId));
      updateLeadState({ ...iLeadState, individualLimitShow: true ,estimationValueShow:"",constructionValue:"",warrantyValue:"",purchasingManage:"",siteSuperValue:"",warrantyStaffValue:""});
    });
  };

  const createUserLimitFunc = () => {
    let data = {
      projectId: ProjectId,
      roleLimit: [
        {
          estimateType: "Estimation",
          estimateValue: estimationValueShow,
        },
        {
          estimateType: "Construction Manager",
          estimateValue: constructionValue,
        },
        {
          estimateType: "Warranty Manager",
          estimateValue: warrantyValue,
        },
        {
          estimateType: "Estimation and Purchasing Manager",
          estimateValue: purchasingManage,
        },
        {
          estimateType: "Site Super",
          estimateValue: siteSuperValue,
        },
        {
          estimateType: "Warranty Staff",
          estimateValue: warrantyStaffValue,
        },
      ],
    };
    dispatch(editUserLimitAction(data)).then((res) => {
      sucessToast("Estimation updated successfully");
      dispatch(getEstimationDetailsAction(ProjectId));
      updateLeadState({ ...iLeadState, individualLimitShow: true });
    });
  };
  const [isEditing, setIsEditing] = useState(false);
  const addUserLimitfunc = () => {
    // Create a copy of the current user limit data
    let userLimitDataStatic = [...userLimitData];

    // Create a data object with the new or updated entry
    const data = {
        userId,
        userName: userList?.users?.find((ele) => ele?.id == userId)?.name,
        limit,
        id: new Date().getTime().toString(),  // Use existing id or create a new one
        estimationId,
    };

    // Check if the form fields are filled out properly
    if (!userId || !limit || !estimationId) {
        errorToast('Please fill all fields!');
        return;
    }

    // Check if the id already exists in the list
    const existingIndex = userLimitDataStatic.findIndex((ele) => ele.id == id);

    if (existingIndex !== -1) {
        // Update the existing entry if the id is found
        userLimitDataStatic[existingIndex] = data;
        setIsEditing(false);
    } else {
        // Add a new entry if the id is not found
        userLimitDataStatic = [...userLimitDataStatic, data];
    }

    // Update the userLimitData state with the modified list
    setUserLimitData(userLimitDataStatic);

    // Reset the form inputs
    updateLeadState({ limit: '', userId: '', estimationId: '' });

    // Reset the editing flag
    setIsEditing(false);
};



  const saveUserPerRole = () => {
    let data = {
      projectId: ProjectId,
      estimationId: estimationId,
      userLimitData,
    };
    dispatch(editUserLimitAction(data))
      .then((res) => {
        sucessToast("User role updated successfully");
        dispatch(getUserLimitRoleAction(ProjectId, estimationId));
        updateLeadState({ ...iLeadState, userLimitToShow: true });
      })
      .catch((err) => {
        errorToast(err?.response?.data?.message);
      });
  };

  const removeLimt = (i) => {
    let userLimitDataStatic = [];
    userLimitDataStatic = [...userLimitData];
    userLimitDataStatic.splice(i, 1);
    setUserLimitData(userLimitDataStatic);
  };


  const handleEditLimit = (userId) => {
    const newEditItem = userLimitData.find((elem) => elem.id == userId);
      updateLeadState({
        limit: newEditItem.limit,
        userId: newEditItem.userId,
        estimationId: newEditItem.estimationId,
        id:newEditItem.id,
      });
      setIsEditing(true);
    
  };


  console.log("userLimitData",userLimitData);
  return (
    <>
      <div className="ResidentialJobArea ViewConfigArea">
        <div className="ModalAccordian">
          <div id="accordion8">
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#BiddingRequestMessagesTabs">
                  <span>
                    <img src="images/select_message.png" />
                  </span>{" "}
                  Bidding Request Messages
                </h4>
                <a
                  onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      mailStateToShow: !mailStateToShow,
                    })
                  }
                  className="Edit EditViewConfigBodyBtn"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="BiddingRequestMessagesTabs"
                className="collapse show"
                data-parent="#accordion8"
              >
                <div className="card-body">
                  {mailStateToShow ? (
                    <div className="ViewConfigBodyMain">
                      <div className="ViewConfigBody">
                        <aside>
                          <div className="form-group mb-0">
                            <h6>Bidding Request Email</h6>
                            <p>{projectAdminData?.bidEmail}</p>
                          </div>
                        </aside>
                      </div>
                    </div>
                  ) : (
                    <div className="ViewConfigBodyHideMain">
                      <div className="ViewConfigBodyHide">
                        <aside>
                          <div className="form-group">
                            <h6>Bid Email</h6>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="project1.estimation@builder.ca"
                              name="bidEmail"
                              value={bidEmail}
                              onChange={handleInput}
                            />
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {bidEmailError}
                            </span>
                          </div>
                        </aside>
                      </div>
                      <div className="Button d-flex justify-content-center">
                        <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn" onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      mailStateToShow: !mailStateToShow,
                    })
                  }>
                          Cancel
                        </button>
                        <button
                          onClick={saveBidEMail}
                          className="Respond ml-5 SaveViewConfigBodyBtn"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#IndividualLimitsTabs">
                  <span>
                    <img src="images/qoqr_1.png" />
                  </span>{" "}
                  Individual Limits QR, PO and FPO
                </h4>
                <a
                  onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      individualLimitShow: !individualLimitShow,
                    })
                  }
                  className="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="IndividualLimitsTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  {individualLimitShow ? (
                    <>
                      <div className="ViewConfigBodyMain">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Limits per Role</span>
                          </h3>
                        </div>
                        <div className="ViewConfigBody">
                          <aside>
                            {checkArray(adminEstimationDetailsData?.details) &&
                              adminEstimationDetailsData?.details?.map(
                                (ele, i) => {
                                  return (
                                    <>
                                      <div className="form-group">
                                        <h6>{ele?.estimateType}</h6>
                                        <h6>{ele?.estimateValue}</h6>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </aside>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ViewConfigBodyHideMain">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Limits per Role</span>
                          </h3>
                        </div>
                        <div className="ViewConfigBodyHide">
                          <aside>
                            <div className="form-group">
                              <h6>Estimation</h6>
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="$ 50,000.00"
                                name="estimationValueShow"
                                value={estimationValueShow}
                                onChange={handleInputNumber}
                              />
                            </div>
                            <div className="form-group">
                              <h6>Construction Manager</h6>
                              <input
                               type="number"
                               min={0}
                                className="form-control"
                                placeholder="$ 50,000.00"
                                name="constructionValue"
                                value={constructionValue}
                                onChange={handleInputNumber}
                              />
                            </div>
                            <div className="form-group">
                              <h6>Warranty Manager</h6>
                              <input
                               type="number"
                               min={0}
                                className="form-control"
                                placeholder="$ 50,000.00"
                                name="warrantyValue"
                                value={warrantyValue}
                                onChange={handleInputNumber}
                              />
                            </div>
                          </aside>
                          <aside>
                            <div className="form-group">
                              <h6>Estimation and Purchasing Manager</h6>
                              <input
                               type="number"
                               min={0}
                                className="form-control"
                                placeholder="$ 300,000.00"
                                name="purchasingManage"
                                value={purchasingManage}
                                onChange={handleInputNumber}
                              />
                            </div>
                            <div className="form-group">
                              <h6>Site Super</h6>
                              <input
                               type="number"
                               min={0}
                                className="form-control"
                                placeholder="$ 10,000.00"
                                name="siteSuperValue"
                                value={siteSuperValue}
                                onChange={handleInputNumber}
                              />
                            </div>
                            <div className="form-group">
                              <h6>Warranty Staff</h6>
                              <input
                                type="number"
                                min={0}
                                className="form-control"
                                placeholder="$ 50,000.00"
                                name="warrantyStaffValue"
                                value={warrantyStaffValue}
                                onChange={handleInputNumber}
                              />
                            </div>
                          </aside>
                        </div>
                        <div className="Button d-flex justify-content-center mt-4">
                          <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn"  onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      individualLimitShow: !individualLimitShow,
                    })
                  }>
                            Cancel
                          </button>
                          <button
                            onClick={createEstimationFunc}
                            className="Respond ml-3 SaveViewConfigBodyBtn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#UserLimitPerRole">
                  <span>
                    <img src="images/qoqr_1.png" />
                  </span>{" "}
                  User Limit Per Role
                </h4>
                <a
                  onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      userLimitToShow: !userLimitToShow,
                    })
                  }
                  className="Edit EditViewConfigBodyBtn"
                  href="javascript:void(0);"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="UserLimitPerRole"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  {userLimitToShow ? (
                    <>
                      <div className="ViewConfigBodyMain" style={{}}>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="ModalBigheading">
                              <h3>
                                <span>Limits per User</span>
                              </h3>
                            </div>
                            <div className="form-group">
                              <h6>Role</h6>
                              <div className="CommonSelectBox">
                                <select
                                  onChange={(e) =>
                                    dispatch(
                                      getUserLimitRoleAction(
                                        ProjectId,
                                        e.target.value
                                      )
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  {checkArray(
                                    adminEstimationDetailsData?.details
                                  ) &&
                                    adminEstimationDetailsData?.details?.map(
                                      (ele, i) => {
                                        return (
                                          <>
                                            <option value={ele?.id}>
                                              {ele?.estimateType}
                                            </option>
                                          </>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="Tableheader mb-2">
                              <h6 style={{ fontSize: "16px!important" }}>
                                User Limits
                              </h6>
                              <div className="NewActions">
                                <a href="javascript:void(0);" title="pdf">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="javascript:void(0);" title="download">
                                  <img src="images/download.png" />
                                </a>
                                <a href="javascript:void(0);" title="settings">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                            <div className="TableList TableListHeader">
                              <table>
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Limit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkArray(userLimitDetailsData?.data) ? (
                                    userLimitDetailsData?.data?.map(
                                      (ele, i) => {
                                        return (
                                          <tr>
                                            <td>{ele?.userName}</td>
                                            <td>$ {ele?.limit}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <NoDataFound />
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ViewConfigBodyHideMain">
                        <div className="ModalBigheading">
                          <h3>
                            <span>Limits per User</span>
                          </h3>
                        </div>
                        <div className="ViewConfigBodyHide">
                          <aside>
                            <div className="form-group">
                              <h6>Role</h6>
                              <div className="CommonSelectBox">
                                <select
                                  name="estimationId"
                                  value={estimationId}
                                  onChange={handleInput}
                                >
                                  <option value="">Select</option>
                                  {checkArray(
                                    adminEstimationDetailsData?.details
                                  ) &&
                                    adminEstimationDetailsData?.details?.map(
                                      (ele, i) => {
                                        return (
                                          <>
                                            <option value={ele?.id} >
                                              {ele?.estimateType}
                                            </option>
                                          </>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <h6>User</h6>
                              <div className="CommonSelectBox">
                                <select
                                  name="userId"
                                  value={userId}
                                  onChange={handleInput}
                                >
                                  <option value="">Select</option>
                                  {checkArray(userList?.users) &&
                                    userList?.users?.map((ele, i) => {
                                      return (
                                        <>
                                          <option value={ele?.id}>
                                            {ele?.name}
                                          </option>
                                        </>
                                      );
                                    })}
                                </select>
                              </div>
                              <div className="form-group">
                                <h6>Limit</h6>
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  placeholder="$"
                                  name="limit"
                                  value={limit}
                                  onChange={handleInputNumber}
                                />
                              </div>
                            </div>
                            <div className="mb-4">
                              <button
                                onClick={addUserLimitfunc}
                                className="SmallRedBtn"
                              >
                                {isEditing ? "Update" : "Add"} User Limit
                              </button>
                            </div>
                          </aside>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div className="Tableheader mb-2">
                              <h6 style={{ fontSize: "16px!important" }}>
                                User Limits
                              </h6>
                              <div className="NewActions">
                                <a href="javascript:void(0);" title="pdf">
                                  <img src="images/icon-53.png" />
                                </a>
                                <a href="javascript:void(0);" title="download">
                                  <img src="images/download.png" />
                                </a>
                                <a href="javascript:void(0);" title="settings">
                                  <img src="images/setting.png" />
                                </a>
                              </div>
                            </div>
                            <div className="TableList TableListHeader">
                              <table>
                                <thead>
                                  <tr>
                                    <th>User Name</th>
                                    <th>Limit</th>
                                    <th>
                                      <div className="text-center">Action</div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkArray(userLimitData) &&
                                    userLimitData?.map((ele, i) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{ele?.userName}</td>
                                            <td>{ele?.limit}</td>
                                            <td>
                                              <div className="TableThreeDots text-center">
                                                <ul className="">
                                                  <li className="dropdown">
                                                    <a
                                                      className=""
                                                      role="button"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img src="images/dots.png" />
                                                    </a>
                                                    <ol className="dropdown-menu dropdown-menuwidth">
                                                      <li>
                                                <a href="javascript:void(0);" onClick={()=>handleEditLimit(ele.id)}>
                                                  <img src="images/Action-1.png" />
                                                  Edit Limit
                                                </a>
                                              </li>
                                                      <li>
                                                        <a
                                                          onClick={() =>
                                                            removeLimt(i)
                                                          }
                                                          href="javascript:void(0);"
                                                        >
                                                          <img src="images/dropdown-delete.png" />
                                                          Remove Line
                                                        </a>
                                                      </li>
                                                    </ol>
                                                  </li>
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="Button d-flex justify-content-center mt-4">
                          <button className="OutlineBlack mr-3 SaveViewConfigBodyBtn">
                            Cancel
                          </button>
                          <button
                            onClick={saveUserPerRole}
                            className="Respond ml-3 SaveViewConfigBodyBtn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h4 data-toggle="collapse" href="#GeneralSettingsTabs">
                  <span>
                    <img src="images/select_message.png" />
                  </span>{" "}
                  General Settings
                </h4>
                <a
                  onClick={() =>
                    updateLeadState({
                      ...iLeadState,
                      generalSettingToShow: !generalSettingToShow,
                    })
                  }
                  className="Edit EditViewConfigBodyBtn"
                >
                  <img src="images/Action-1.png" />
                </a>
              </div>
              <div
                id="GeneralSettingsTabs"
                className="collapse show"
                data-parent="#accordion"
              >
                <div className="card-body">
                  {generalSettingToShow ? (
                    <>
                      <div className="ViewConfigBodyMain">
                        <div className="ViewConfigBody">
                          <aside style={{ width: "100%" }}>
                            <div className="form-group">
                              <h6>
                                Allow Warranty users to send FPOs from Work
                                Orders
                              </h6>
                              <p>
                                {projectAdminData?.is_allow_warranty_users
                                  ? "On"
                                  : "Off"}
                              </p>
                            </div>
                            <div className="form-group mb-0">
                              <h6>Auto Cancel Non-accepted Budged Quotes</h6>
                              <p>
                                {projectAdminData?.is_auto_cancel
                                  ? "On"
                                  : "Off"}
                              </p>
                            </div>
                          </aside>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ViewConfigBodyHideMain">
                        <div className="ViewConfigBodyHide">
                          <aside style={{ width: "100%" }}>
                            <div className="form-group mb-0">
                              <h6>
                                Allow Warranty users to send FPOs from Work
                                Orders
                              </h6>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="is_auto_cancel"
                                  onChange={(e) => {
                                    updateLeadState({
                                      ...iLeadState,
                                      is_allow_warranty_users: e.target.checked,
                                    });
                                  }}
                                />
                                <span className="slider" style={{ left: 0 }} />
                              </label>
                            </div>
                            <div className="form-group mb-0">
                              <h6>Auto Cancel Non-accepted Budged Quotes</h6>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  name="is_auto_cancel"
                                  onChange={(e) => {
                                    updateLeadState({
                                      ...iLeadState,
                                      is_auto_cancel: e.target.checked,
                                    });
                                  }}
                                />
                                <span className="slider" style={{ left: 0 }} />
                              </label>
                            </div>
                          </aside>
                        </div>
                        <div className="Button d-flex justify-content-center">
                          <button className="OutlineBlack mr-5 SaveViewConfigBodyBtn">
                            Cancel
                          </button>
                          <button
                            onClick={saveDefaultSettingFunc}
                            className="Respond ml-5 SaveViewConfigBodyBtn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EstimationAdmin;
